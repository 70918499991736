import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedMasterAppReducer = (state = initialState.selectedMasterAppReducer, action) => {
    switch(action.type) {

        case ActionType.GET_MASTERAPP_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                masterappdata: _.assign(action.masterappdata)
            };
        }

        default: { return state; }
    }
};


export default selectedMasterAppReducer;