import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SiteAction from '../action/SiteAction';
import AppLocationListTable from './AppLocationListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import constants from '../constants';
import RB, { userPermsStatus, getLogonUserPermissions } from './common/RBAC';
import { Redirect } from "react-router-dom";
import PopUpConfirm from './common/PopUpConfirm';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { Context } from '../components/Context';
import LoadingIndicator from './common/LoadingIndicator';

export class AppLocationListContainer extends React.Component {
    static contextType = Context;
    constructor() {
        super();
        this.sitesfull = [];
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            selectedSite: 0,
            open: undefined,
            loaded: false,
            popupOpen: undefined,
            siteIDforDelete: "",
            deletedSiteID: "",
            sitesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }

    async componentDidMount() {
        //sessionStorage.setItem("locationId", "");
        if (!userPermsStatus.done) {
            await getLogonUserPermissions();
        }

        this.props.action.getSitesAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.sitesdata && !this.state.loaded) {
            this.setState({ loaded: true });
        };
    }

    handleDelete(event, nro) {
        //event.preventDefault();
        //this.setState({ selectedSite: nro });
        //this.onOpen();
        this.setState({ popupOpen: true, siteIDforDelete: nro });
    }

    popupClose = () => this.setState({ popupOpen: undefined, deletedSiteID: "" });

    popupDelete = () => {
        var selectedSite = this.props.sitesdata.find(item => item.id == this.state.siteIDforDelete);
        selectedSite.deleted = true;
        this.props.action.saveSiteAction(selectedSite)
            .then(() => {
                this.props.action.getSitesAction()
                    .then(() => {
                    })
                    .catch(error => {
                        console.log('*DEBUG*- Sites List Error', error);
                    });
            }).catch(error => {
                this.setState({ disabledButton: false });
            });
        this.setState({ popupOpen: undefined, deletedSiteID: this.state.siteIDforDelete });
    }

    eventDelete(event) {
        const selectedId = this.state.selectedSite;

        if (selectedId) {
            this.setState({ selectedSite: 0, open: undefined });
            this.props.action.deleteSiteAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- Site List Delete Error', error);
                });
        }
    }

    onOpen = () => this.setState({ open: true });

    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        this.props.history.push(constants.getSiteLevelRoute() + '/configuration/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        sessionStorage.setItem("locationId", nro);
        const canread = RB.canRead(constants.PERMS.LOC_CONF_SITE);
        console.log('*Switch* started on ', nro, canread);
        if (!RB.canEdit(constants.PERMS.LOC_CONTENT) && constants.RELEASE_TYPE == "HPE-MTF")
            this.props.history.push(constants.getSiteLevelRoute() + '/overview');
        else
            this.props.history.push(constants.getSiteLevelRoute() + '/home');
        // if (canread) {
        //     //this.props.history.push('/configuration/'+nro);
        //     this.props.history.push('/home');
        // } else {
        //     //this.props.history.push('/home');
        // }
    }

    orgRedirect() {
        if (this.context.feature.find(f => f.name == "mobilClient").isEnabled) {
            this.props.history.push(constants.getOrgLevelRoute() + '/appanalytics');
        }
        else if (this.context.feature.find(f => f.name == "assetTracking").isEnabled) {
            this.props.history.push('/orgassets');
        }
        else {
            this.props.history.push(`${constants.getOrgLevelRoute()}/orgsettings`);
        }
    }

    render() {
        const { sitesdata } = this.props;
        const { open, selectedSite, loaded, sitesdataLoading } = this.state;

        //console.log('Render:', sitesdata)

        if (sitesdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }

        let sitesdataWithPerm = [];
        sitesdata.forEach(ev => {
            if (RB.canAccessLocation(ev.id)) {
                sitesdataWithPerm.push({
                    ...ev,
                })
            }
        })

        if (sitesdataWithPerm.length == 1 && sitesdataWithPerm[0].count == 0) {
            this.orgRedirect();
        }

        if (sitesdataWithPerm.length == 1 && sitesdataWithPerm[0].id) {
            sessionStorage.setItem("locationId", sitesdataWithPerm[0].id);
            if (!RB.canEdit(constants.PERMS.LOC_CONTENT) && constants.RELEASE_TYPE == "HPE-MTF")
                this.props.history.push(constants.getSiteLevelRoute() + '/overview');
            else
                this.props.history.push(constants.getSiteLevelRoute() + '/home');
        }

        //if (sitesdataWithPerm.length == 0) { //if there are no available sites returns to org level
        //    this.orgRedirect();
        //}

                //const sitesfull = sitesdata.filter(s => RB.canAccessLocation(s.id)).slice();
        //if (!RB.isSuperUser() && sitesfull.length == 0) {
        //    return (
        //        <Redirect
        //            to={{
        //                pathname: "/nouser"
        //            }}
        //        />
        //    );
        //}

        return (
            <Box flex justify='between' fill>
                {/*<PopUpConfirm open={this.state.popupOpen} text="Please confirm that you want to delete this site. This will delete all placemarks, events and content." onClose={() => this.popupClose()} onDelete={() => this.popupDelete()} />*/}
                <form>
                    {open && (
                        <Layer
                            position="center"
                            modal={true}
                            onClickOutside={this.onClose}
                            onEsc={this.onClose}
                        >
                            <Box pad="medium" gap="small" width="medium" >
                                <Heading level={3} margin="none">
                                    Confirm
                            </Heading>
                                <Text weight="bold">Please confirm that you want to delete this App Location.</Text>
                                <Text >Note any content associated with this App Location will need to be reconfigured. </Text>
                                <Box
                                    tag="footer"
                                    gap="small"
                                    direction="row"
                                    align="center"
                                    justify="end"
                                    pad={{ top: "medium", bottom: "small" }}

                                >
                                    <StyledButton typeCancel label="Cancel" onClick={this.onClose} />
                                    <StyledButton typeSave label="Confirm" onClick={this.eventDelete} />
                                </Box>
                            </Box>
                        </Layer>
                    )}
                    <Context.Consumer>
                        {value =>
                            <AppLocationListTable sitesdata={sitesdata} handleNew={this.handleNew} handleDelete={this.handleDelete} handleEdit={this.handleEdit} deletedSiteID={this.state.deletedSiteID} context={value} />
                        }
                    </Context.Consumer>
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    sitesdata: state.sitesReducer.sitesdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators(SiteAction, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(AppLocationListContainer);
