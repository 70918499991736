import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const timeZonesReducer = (state = initialState.timeZonesReducer, action) => {
    switch(action.type) {
        case ActionType.GET_TIME_ZONE_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                timezonesdata: _.assign(action.timezonesdata)
            };
        }
        default: { return state; }
    }
};

export default timeZonesReducer;