import React, { Component } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Chart, Meter, Diagram, Stack, Node } from 'grommet';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';

class NoAccess extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <Heading level='1' >Sorry you have no authorization to access this page</Heading>
                    <Heading level='2'>Please contact your administrator for more information</Heading>
                    <Box width="medium">
                        <StyledButton typeCancel label="Back to My Account" onClick={() => this.props.history.push('/account')} />
                    </Box>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid>
        );
    }
}


export default connect()(NoAccess);
