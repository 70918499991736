import { getApiToken, adalConfig, getToken } from '../adal/adalConfig';
import constants from '../constants';
import { pca } from '../../src/indexApp';
import AuthenticationService from '../msal/MsalConfig';
import { Return } from 'grommet-icons';

export function myHeaders() {
    let orgId = '';
    let locId = '';
    if (sessionStorage.getItem('organisationId')) {
        orgId = sessionStorage.getItem('organisationId')
    };
    if (sessionStorage.getItem('locationId')) {
        locId = sessionStorage.getItem('locationId')
    };
    let headers = new Headers;
    headers.append('Content-Type', 'application/json');
    headers.append('X-OrgID', orgId);
    headers.append('X-LocID', locId);
    //   for (var pair of headers.entries()) {
    //       console.log('FETCH HEADERS:', pair[0]+': '+pair[1]);
    //   }; 
    return headers;
}

export async function myHeadersWithToken(notOnlyLinked = false) {
    let orgId = '';
    let locId = '';
    //let apiToken = await getApiToken(adalConfig.endpoints.api);
    //let apiToken = getToken();
    //let apiToken = await getApiToken(constants.release().adalConfig.clientId);
    var request = {
        account: pca.getAllAccounts()[0],
        scopes: constants.scopes()
    };
    //let apiToken = await getApiToken(constants.release().adalConfig.clientId);
    //let aa = new AuthenticationService();
    //console.log("getting token", await aa.getAccessToken());
    //let respo = await aa.init();
    //let apiToken = respo.accessToken;
    //let apiToken = await pca.acquireTokenSilent(request).then(tokenResponse => {
    //    // Do something with the tokenResponse
    //    return tokenResponse.accessToken;
    //}).catch(error => {
    //    return pca.acquireTokenRedirect(request);
    //    //if (error instanceof InteractionRequiredAuthError) {
    //    //    // fallback to interaction when silent call fails
    //    //    return myMSALObj.acquireTokenRedirect(request)
    //    //}
    //    });
    let apiToken = "";
    if (pca.getAllAccounts().length > 0) {
        apiToken = await pca.acquireTokenSilent(request).then(tokenResponse => {
            // Do something with the tokenResponse
            return tokenResponse.accessToken;
        }).catch(error => {
            return pca.acquireTokenRedirect(request);
            //if (error instanceof InteractionRequiredAuthError) {
            //    // fallback to interaction when silent call fails
            //    return myMSALObj.acquireTokenRedirect(request)
            //}
        });
    } else {
        //pca.loginRedirect();
    }

    console.log("getting the token", apiToken);
    let bearer = `Bearer ${apiToken}`;
    if (sessionStorage.getItem('organisationId')) {
        orgId = sessionStorage.getItem('organisationId')
    };
    if (sessionStorage.getItem('locationId')) {
        locId = sessionStorage.getItem('locationId')
    };
    let headers = new Headers();
    headers.append('Authorization', bearer);
    headers.append('Content-Type', 'application/json');
    if (orgId) {
        headers.append('X-OrgID', orgId);
    }
    if (locId) {
        headers.append('X-LocID', locId);
    }
    if (notOnlyLinked) {
        headers.append('X-Manage', 1);
    }

    //   for (var pair of headers.entries()) {
    //       console.log('FETCH HEADERS:', pair[0]+': '+pair[1]);
    //   }; 
    return headers;
}

export async function myHeadersWithTokenNoIds() {
    //let apiToken = await getApiToken(adalConfig.endpoints.api);
    //let apiToken = getToken();
    //let apiToken = await getApiToken(constants.release().adalConfig.clientId);
    var request = {
        account: pca.getAllAccounts()[0],
        scopes: constants.scopes()
    };
    //let apiToken = await getApiToken(constants.release().adalConfig.clientId);
    //let aa = new AuthenticationService();
    //console.log("getting token", await aa.getAccessToken());
    //let respo = await aa.init();
    //let apiToken = respo.accessToken;
    //let apiToken = await pca.acquireTokenSilent(request).then(tokenResponse => {
    //     Do something with the tokenResponse
    //    return tokenResponse.accessToken;
    //}).catch(error => {
    //    return pca.acquireTokenRedirect(request);
    //    if (error instanceof InteractionRequiredAuthError) {
    //        // fallback to interaction when silent call fails
    //        return myMSALObj.acquireTokenRedirect(request)
    //    }
    //});
    let apiToken = "";
    if (pca.getAllAccounts().length > 0) {
        apiToken = await pca.acquireTokenSilent(request).then(tokenResponse => {
            // Do something with the tokenResponse
            return tokenResponse.accessToken;
        }).catch(error => {
            return pca.acquireTokenRedirect(request);
            //if (error instanceof InteractionRequiredAuthError) {
            //    // fallback to interaction when silent call fails
            //    return myMSALObj.acquireTokenRedirect(request)
            //}
        });
    } else {
        //pca.loginRedirect();
    }

    let bearer = `Bearer ${apiToken}`;
    let headers = new Headers();
    headers.append('Authorization', bearer);
    headers.append('Content-Type', 'application/json');
    return headers;
}

export async function myHeadersWithLocId(locId) {
    let orgId = '';
    var request = {
        account: pca.getAllAccounts()[0],
        scopes: constants.scopes()
    };
    let apiToken = "";
    if (pca.getAllAccounts().length > 0) {
        apiToken = await pca.acquireTokenSilent(request).then(tokenResponse => {
            // Do something with the tokenResponse
            return tokenResponse.accessToken;
        }).catch(error => {
            return pca.acquireTokenRedirect(request);
            //if (error instanceof InteractionRequiredAuthError) {
            //    // fallback to interaction when silent call fails
            //    return myMSALObj.acquireTokenRedirect(request)
            //}
        });
    } else {
        //pca.loginRedirect();
    }
    let bearer = `Bearer ${apiToken}`;
    if (sessionStorage.getItem('organisationId')) {
        orgId = sessionStorage.getItem('organisationId')
    };
    let headers = new Headers();
    headers.append('Authorization', bearer);
    headers.append('Content-Type', 'application/json');
    headers.append('X-OrgID', orgId);
    headers.append('X-LocID', locId);
    //   for (var pair of headers.entries()) {
    //       console.log('FETCH HEADERS:', pair[0]+': '+pair[1]);
    //   }; 
    return headers;
}

export const queueApiCall = (fnApiCall) => {
    const apiqueue = [];
    const dequeue = () => {
        apiqueue.splice(0, 1);
    };

    return (...args) => {
        let apiCall;

        const invokeApi = () => fnApiCall(...args);

        if (apiqueue.length === 0) {
            apiCall = invokeApi();
        } else {
            apiCall = apiqueue[apiqueue.length - 1].then(invokeApi, invokeApi);
        }
        apiqueue.push(apiCall.then(dequeue, dequeue));
        return apiCall;
    };
};

export function getAllRecords(url, options, recordCount = 0, skipCount = 0, allRaws = []) {
    let urlWithSkip = `${url}&$skip=${skipCount}`;
    console.log("kra", urlWithSkip, recordCount, skipCount, allRaws);
    //if (recordCount == 0) return [{count: 0}];
    return fetch(urlWithSkip, options)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else if (!response.ok) {
                throw { status: response.status, statusText: response.statusText, url: response.url };
            }
        })
        .then(data => {
            allRaws = allRaws.concat(data);
            skipCount += data.length;
            if (skipCount >= recordCount || data.length === 0) {
                return allRaws;
            }
            else {
                return getAllRecords(url, options, recordCount, skipCount, allRaws);
            }
        })
        .catch(error => {
            throw error;
        });
}

export async function getPortalToken() {
    let apiToken = "";
    var request = {
        account: pca.getAllAccounts()[0],
        scopes: constants.scopes()
    };
    if (pca.getAllAccounts().length > 0) {
        apiToken = await pca.acquireTokenSilent(request).then(tokenResponse => {
            // Do something with the tokenResponse
            console.log("na1", tokenResponse);
            return tokenResponse.accessToken;
        }).catch(error => {
            return pca.acquireTokenRedirect(request);
            //if (error instanceof InteractionRequiredAuthError) {
            //    // fallback to interaction when silent call fails
            //    return myMSALObj.acquireTokenRedirect(request)
            //}
        });
    } else {
        //pca.loginRedirect();
    }
    return `Bearer ${apiToken}`;
}