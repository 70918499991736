import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserAction from '../action/UserAction';
import * as RoleAction from '../action/RoleAction';
import * as SiteAction from '../action/SiteAction';
import * as OrganisationAction from '../action/OrganisationAction';
import validator from 'validator';
import constants from '../constants';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Trash } from 'grommet-icons';
import { Grid, Box, Heading, Text, FormField, TextInput, CheckBox, DataTable } from 'grommet';
import { error } from 'util';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import RB from './common/RBAC';
import { MsalContext } from "@azure/msal-react";
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import withPagination from './common/PaginatedDataTable';

var start = undefined;

const PaginatedDataTable = withPagination(DataTable);

class Option extends React.Component {
    render() {
        const { value, selected } = this.props;
        return (
            <Box direction="row" gap="small" align="center" pad="xsmall">
                <CheckBox tabIndex="-1" checked={selected} onChange={() => { }} />
                {value}
            </Box>
        );
    }
}

export class SuperUserEditContainer extends React.Component {
    static contextType = MsalContext;
    constructor() {
        super();
        this.allRoles = [];
        this.allRolesSites = [];
        this.rolefull = [];
        this.sitesfull = [];
        this.didupdate = false;
        this.orgId = sessionStorage.getItem("organisationId");
        //this.orgId = constants.organisation.id;
        //if (sessionStorage.getItem("organisationId")) {
        //    this.orgId = sessionStorage.getItem("organisationId");
        //}        
        this.state = {
            checked: false,
            selected: [],
            options: [],
            isSaved: false,
            loaded: false,
            locationroles: [],
            organisationroles: { roles: [] },
            disabledButton: false,
            open: false,
            cancel: false,
            isSaveSuccessful: false,
            search: "",
            userdataLoading: true,
            rolesdataLoading: true,
            sitesdataLoading: true,
            organisationsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Validating errors
    email_err = '';
    username_err = '';

    ClearErrorMessages() {
        this.email_err = '';
        this.username_err = '';
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.userId;

        this.props.action.getRolesAction()
            .then(response => this.setState({ rolesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-Role Error", error);
            });
        this.props.action.getUserAllRolesAction(this.props.match.params.userId)
            .then(response => this.setState({ userdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-Users Error", error);
            });
        this.props.action.getSitesActionNoId()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getOrganisationsAction()
            .then(response => this.setState({ organisationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Organisations List Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.userdata && !_.isEqual(start, this.props.userdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.rolesdata && this.props.rolesdata.length > 0 && this.rolefull.length === 0) {
            //this.rolefull = this.props.rolesdata.slice();
            this.rolefull = this.props.rolesdata.filter((item) => item.level == "Location");
            this.rolefull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            this.setState({ options: this.rolefull });
        }
        if (this.props.userdata && this.state.selected.length === 0 && !this.didupdate && this.sitesfull.length > 0) {
            this.setState({ selected: this.props.userdata.userRoles.map(r => r.role.name) });
            var sf = [...this.sitesfull];
            var lr = [];
            var orgrole = { roles: [] };
            this.props.userdata.userRoles.forEach(function (item, id) {
                if (item.role.level == "Organization") {
                    orgrole.organisationid = item.organizationId;
                    orgrole.roles.push(item.role.name);
                }
                else {

                    if (lr.length == 0) {
                        let loc = sf.find(l => l.id == item.locationId);
                        if (loc) {
                            let name = loc.name;
                            lr.push({ name: name, locationId: item.locationId, roles: [item.role.name] });
                        }
                    }
                    else {
                        var index = -1;
                        lr.forEach(function (element, i) {
                            if (element.locationId == item.locationId) {
                                index = i;
                            }
                            else {

                            }
                        })
                        if (index > -1) {
                            lr[index].roles.push(item.role.name);
                        }
                        else {
                            let loc = sf.find(l => l.id == item.locationId);
                            if (loc) {
                                let name = loc.name;
                                lr.push({ name: name, locationId: item.locationId, roles: [item.role.name] });
                            }
                        }

                    }
                }
            })

            orgroles = JSON.parse(JSON.stringify(orgrole));
            locroles = JSON.parse(JSON.stringify(lr));
            this.setState({ locationroles: lr, organisationroles: orgrole });

            this.didupdate = true;
        }
    }

    handleSave() {
        const userdefault = this.props.userdata;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ validating: false });
        if (userdefault.username.trim().length == 0) {
            errorsno++;
            this.username_err = "Username is required";
        }
        if (userdefault.email.trim().length == 0) {
            errorsno++;
            this.email_err = "E-mail is required"
        }
        if (userdefault.email.trim().length != 0 && !(validator.isEmail(userdefault.email))) {
            errorsno++;
            this.email_err = "E-mail is not correct";
        }

        if (errorsno == 0) {
            this.setState({ disabledButton: true })
            this.saveRoles();
            console.log(JSON.stringify(userdefault));
            console.log('Save: OK', userdefault);
            this.props.action.saveSuperUserAction(userdefault)
                //UserApi.saveSuperUser(userdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                    //this.props.history.push('/manageusers');
                }).catch(error => {
                    console.log('*DEBUG*-Save UserEdit Error', error);
                    this.setState({ disabledButton: false })
                });
        }
        else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        //this.saveRoles();
        if (!this.state.cancel && !_.isEqual(start, this.props.userdata)) {
            this.setState({ cancel: true });
        }
        else {
            const userId = this.props.userdata.id;
            this.props.action.getUserAction(userId, true)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push('/manageusers');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Super UserEdit Error', error);
                    this.props.history.push('/manageusers');
                });
            this.props.history.push('/manageusers');
        }
    }
    saveRoles() {
        const userdefault = this.props.userdata;
        const { selected, locationroles } = this.state;
        //updating roles
        userdefault.userRoles = [];
        this.props.rolesdata.map((item) => {
            locationroles.map((element) => {
                if (element.roles.indexOf(item.name) >= 0) {
                    const ur = { role: item, locationId: element.locationId, organizationId: null };
                    userdefault.userRoles.push(ur);
                }
            })
            if (this.state.organisationroles.roles.indexOf(item.name) >= 0) {
                const uor = { role: item, organizationId: this.state.organisationroles.organisationid, locationId: null };
                userdefault.userRoles.push(uor);
            }
        })
    }

    updateSuperUser(evt) {
        this.setState({ checked: !this.state.checked });
        this.props.userdata.superUser = evt.target.checked;
    }
    updateUsernameValue(evt) {
        this.props.userdata.username = evt.target.value;
    }
    updateEmailValue(evt) {
        this.props.userdata.email = evt.target.value;
    }
    updateActive(evt) {
        this.props.userdata.active = evt.target.checked;
        this.setState({ updated: true });
    }
    onClose() {
        this.setState({ open: false, disabledButton: false });
    }
    onConfirm() {
        this.setState({ open: false });
    }
    onCloseSaving() {
        this.setState({ isSaved: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push('/manageusers');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }

    renderRolesTable(props) {
        const COLUMNS = [
            {
                property: 'roleType',
                header: 'Role Type',
                render: rolesdata => <Text>{rolesdata.roleType}</Text>
            },
            {
                property: 'orgName',
                header: 'Organization Name',
                render: rolesdata => <Text>{rolesdata.orgName}</Text>
            },
            {
                property: 'siteName',
                header: 'Site Name',
                render: rolesdata => <Text>{rolesdata.siteName}</Text>
            },
            {
                property: 'rolesString',
                header: 'Role',
                render: rolesdata => <Text>{rolesdata.rolesString}</Text>
            },

        ];

        const { sitesdata, organisationsdata, userdata, rolesdata } = this.props;

        if (sitesdata && organisationsdata && userdata) {
            console.log("US_RL_TST1", sitesdata, organisationsdata, userdata);
            this.allRoles = [];
            userdata.userRoles.filter(r => r.active).forEach((ev, id) => {
                let oName = organisationsdata.find(s => s.id == ev.organizationId) ? organisationsdata.find(s => s.id == ev.organizationId).name : ev.organizationId;
                let sName = sitesdata.find(s => s.id == ev.locationId) ? sitesdata.find(s => s.id == ev.locationId).name : "";
                let type = "";
                if (ev.role) {
                    if (ev.role.level == "Organization") { type = "Organization" }
                    if (ev.role.level == "Location") { type = "Site" }
                }

                this.allRoles.push({
                    ...ev,
                    orgName: oName,
                    orgId: ev.organizationId,
                    siteName: sName,
                    siteId: ev.locationId,
                    roleType: type,
                    roleName: ev.role ? ev.role.name : ""
                });
            });

            this.allRoles.sort((p1, p2) => { if (p1.siteName.toLowerCase() < p2.siteName.toLowerCase()) return -1; return 1; });
            this.allRoles.sort((p1, p2) => { if (p1.orgName.toLowerCase() < p2.orgName.toLowerCase()) return -1; return 1; });

            this.allRolesSites = [];
            this.allRoles.forEach((ev, id) => {
                if (this.allRolesSites.filter(r => r.orgName == ev.orgName && r.siteName == ev.siteName && r.siteName != "").length == 0) {
                    this.allRolesSites.push({
                        orgName: ev.orgName,
                        orgId: ev.orgId,
                        siteName: ev.siteName,
                        siteId: ev.siteId,
                        roleType: ev.roleType,
                        roleName: [ev.roleName],
                    });
                }
                else {
                    this.allRolesSites.find(r => r.siteName == ev.siteName).roleName.push(ev.roleName);
                }
            });

            this.allRolesSites.forEach((ev, id) => { ev.roleName.sort((p1, p2) => { if (p1.toLowerCase() < p2.toLowerCase()) return -1; return 1; }); });

            this.orgsWithSites = [];
            organisationsdata.forEach((ev) => {
                if (this.allRolesSites.map(r => r.orgId).includes(ev.id)) {
                    this.orgsWithSites.push({
                        name: ev.name,
                        id: ev.id,
                        sites: sitesdata.filter(s => s.organizationId == ev.id).map(s => ({ name: s.name, id: s.id })),
                    });
                }
            })

            this.orgsWithSites.forEach((ev) => {
                let currentOrgRoles = this.allRolesSites.filter(r => r.orgId == ev.id);
                let siteRoles = rolesdata.filter(r => r.level == "Location").map(r => ({ name: r.name, count: 0, sites: [] }));
                siteRoles.forEach((ev) => {
                    ev.sites = currentOrgRoles.filter(r => r.roleName.includes(ev.name)).map(r => r.siteName);
                    ev.count = currentOrgRoles.filter(r => r.roleName.includes(ev.name)).map(r => r.siteName).length;
                })

                if (siteRoles.filter(r => r.count == ev.sites.length).map(r => r.name).length != 0) {
                    this.allRolesSites.unshift({
                        orgName: ev.name,
                        orgId: ev.id,
                        siteName: "All",
                        siteId: -1,
                        roleType: "Site",
                        roleName: siteRoles.filter(r => r.count == ev.sites.length).map(r => r.name),
                    });
                }
            })

            this.allRolesSites.forEach((ev) => {
                if (this.allRolesSites.find(r => r.siteId == -1 && r.orgId == ev.orgId)) {
                    let rolesAllSites = this.allRolesSites.find(r => r.siteId == -1 && r.orgId == ev.orgId).roleName.map(r => r);
                    if (ev.siteId != -1) {
                        ev.roleName = ev.roleName.filter(r => !rolesAllSites.includes(r))
                    }
                }
            })

            this.allRolesSites = this.allRolesSites.filter(r => r.roleName.length != 0);
            this.allRolesSites.sort((p1, p2) => { if (p1.roleType.toLowerCase() < p2.roleType.toLowerCase()) return -1; return 1; });
            this.allRolesSites.sort((p1, p2) => { if (p1.orgName.toLowerCase() < p2.orgName.toLowerCase()) return -1; return 1; });
        }

        let DATA = [];

        if (this.allRolesSites[0] && this.allRolesSites[0].count != 0) {
            this.allRolesSites.forEach(ev => {
                DATA.push({
                    ...ev,
                    rolesString: ev.roleName.join(", "),
                })
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.roleType.toLowerCase().includes(search) ||
                d.orgName.toLowerCase().includes(search) ||
                d.siteName.toLowerCase().includes(search) ||
                d.rolesString.toLowerCase().includes(search)
            );
        }

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcusername" || c.property === "lcemail" || c.property === "lcrole"
                }))}
                data={DATA}
                noLoadingMsg={this.props.userdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcusername', label: 'Username' },
                //    { col: 'lcemail', label: 'Email' },
                //    { col: 'lcactive', label: 'Active' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                noCounter

                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }

    render() {
        const { userdataLoading, rolesdataLoading, sitesdataLoading, organisationsdataLoading } = this.state;
        const { userdata, rolesdata, organisationsdata, sitesdata } = this.props;

        if (userdataLoading || rolesdataLoading || sitesdataLoading || organisationsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }

        const heading = userdata && (userdata.id != "0") ? 'Edit' : 'Create';
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}
                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },
                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2'>User - {heading}</Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onCloseSaving()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={() => this.onSave()} onDiscard={() => this.onDiscard()} />
                    <form>
                        <Box gap="large">
                            <Box gap="small">
                                <Box> <Text>All fields marked with an * are mandatory.</Text>
                                    <Text size="large">Description</Text>
                                </Box>
                                <StyledBox style1 pad={{ "horizontal": "small" }}>
                                    <FormField label="Email *" error={this.email_err}>
                                        <TextInput defaultValue={userdata.email} onChange={evt => this.updateEmailValue(evt)} readOnly={heading == "Edit"} disabled={heading == "Edit"} />
                                    </FormField>
                                    <FormField label="Username *" error={this.username_err}>
                                        <TextInput defaultValue={userdata.username} onChange={evt => this.updateUsernameValue(evt)} />
                                    </FormField>
                                    <Box width="70px">
                                        <FormField label="Active">
                                            <Box pad="small" align="center" justify="center">
                                                <CheckBox checked={userdata.active} onChange={evt => this.updateActive(evt)} />
                                            </Box>
                                        </FormField>
                                    </Box>
                                    <Box width="70px">
                                        <FormField label="Super User">
                                            <Box pad="small" align="center" justify="center">
                                                <CheckBox checked={userdata.superUser} onChange={evt => this.updateSuperUser(evt)} disabled={!RB.isSuperUser()} />
                                            </Box>
                                        </FormField>
                                    </Box>
                                </StyledBox>
                            </Box>
                            {userdata.id != "0" &&
                                <Box>
                                    <Text size="large">Roles</Text>
                                    {this.renderRolesTable(this.props)}
                                </Box>}
                        </Box>
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const userId = ownProps.match.params.userId || '';
    if (state.selectedUserReducer.userdata && userId == state.selectedUserReducer.userdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedUserReducer.userdata));
        return {
            userdata: state.selectedUserReducer.userdata,
            rolesdata: state.rolesReducer.rolesdata,
            sitesdata: state.sitesReducer.sitesdata,
            organisationsdata: state.organisationsReducer.organisationsdata
        };
    } else {
        return {
            rolesdata: state.rolesReducer.rolesdata,
            sitesdata: state.sitesReducer.sitesdata
        };
    }
};

const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...UserAction, ...RoleAction, ...SiteAction, ...OrganisationAction }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SuperUserEditContainer));