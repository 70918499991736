import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CategoryAction from '../action/CategoryAction';
import * as SiteAction from '../action/SiteAction';
import * as NotificationAction from '../action/NotificationAction';
import NotificationListTable from './NotificationListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';


export class NotificationListContainer extends React.Component {

    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            selectedNotification: 0,
            deletedNotificationIds: [],
            open: undefined,
            notificationsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }

    componentDidMount() {
        this.props.action.getNotificationsAction()
            .then(response => this.setState({ notificationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Notification List Error', error);
            });
    }


    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedNotification: nro });
        this.onOpen();
    }

    eventDelete(event) {
        const selectedId = this.state.selectedNotification;

        if (selectedId) {
            this.setState({ selectedNotification: 0, open: undefined });
            // this.setState({ deletedCategoryId: selectedId });
            this.setState({ deletedNotificationIds: this.state.deletedNotificationIds.concat([selectedId]) });
            this.props.action.deleteNotificationAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- Notification List Delete Error', error);
                });
        }
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        this.props.history.push(constants.getOrgLevelRoute() + '/notification/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push(constants.getOrgLevelRoute() + '/notification/' + nro);
    }

    render() {
        const { notificationsdata } = this.props;
        const { open, selectedNotification, deletedNotificationIds, notificationsdataLoading } = this.state;

        if (notificationsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }

        return (
            <Box flex justify='between' fill>
                <form>
                    {open && (
                        <Layer
                            position="center"
                            modal={true}
                            onClickOutside={this.onClose}
                            onEsc={this.onClose}
                        >
                            <Box pad="medium" gap="small" width="medium" >
                                <Heading level={3} margin="none">
                                    Confirm
                            </Heading>
                                <Text weight="bold">{constants.confirmMessage("notification")}</Text>
                                <Box
                                    tag="footer"
                                    gap="small"
                                    direction="row"
                                    align="center"
                                    justify="end"
                                    pad={{ top: "medium", bottom: "small" }}

                                >
                                    <StyledButton typeCancel label="Cancel" onClick={this.onClose} />
                                    <StyledButton typeSave label="Confirm" onClick={this.eventDelete} />
                                </Box>
                            </Box>
                        </Layer>
                    )}

                    <NotificationListTable handleNew={this.handleNew} notificationsdata={notificationsdata}
                        handleDelete={this.handleDelete} handleEdit={this.handleEdit} deletedNotificationIds={deletedNotificationIds} />
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    notificationsdata: state.notificationsReducer.notificationsdata,
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...NotificationAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(NotificationListContainer);
