import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const associationsReducer = (state = initialState.associationsReducer, action) => {
    switch(action.type) {
        case ActionType.GET_ASSOCIATIONS_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                associationsdata: _.assign(action.associationsdata)
            };
        }


        default: { return state; }
    }
};



export default associationsReducer;