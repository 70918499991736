import * as ActionType from './ActionType';
import MetaDataApi from '../api/MetaDataApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getMetaDatasResponse = metadatas => ({
    type: ActionType.GET_METADATAS_RESPONSE,
    metadatas
});

const queuedGetAllMetaDatas = queueApiCall(MetaDataApi.getAllMetadatas);

export function getMetadatasAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedGetAllMetaDatas()
            .then(metadatas => {
                dispatch(getMetaDatasResponse(metadatas));
                return metadatas;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewMetaDataResponse = () => ({
    type: ActionType.ADD_NEW_METADATA_RESPONSE
});

export const updateExistingMetaDataResponse = () => ({
    type: ActionType.UPDATE_EXISTING_METADATA_RESPONSE
});

export function addMetadataAction(metadatadraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the asset is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new asset that is being added, therefore add it
        return MetaDataApi.addMetadata(metadatadraft)
            .then((response) => {
                dispatch(addNewMetaDataResponse());
                return response;
            }).then((response) => {
                //if (assetdraft.id == "0") {
                //    dispatch(getAssetAction(assetdraft.id));
                //}
                //dispatch(getAssetAction("0"));
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export function saveMetadataAction(metadatadraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return MetaDataApi.saveMetadata(metadatadraft)
            .then((response) => {
                if (metadatadraft.id) {
                    dispatch(updateExistingMetaDataResponse());
                } else {
                    dispatch(addNewMetaDataResponse());
                }
                return response;
            }).then((response) => {
                if (metadatadraft.id == "0") {
                    dispatch(getMetadataAction(metadatadraft.id));
                }
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getMetaDataResponse = metadata => ({
    type: ActionType.GET_METADATA_RESPONSE,
    metadata
});

export function getMetadataAction(metadataId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return MetaDataApi.getMetadata(metadataId)
            .then(metadata => {
                dispatch(getMetaDataResponse(metadata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteMetaDataResponse = () => ({
    type: ActionType.DELETE_METADATA_RESPONSE
});

const queuedDeleteMetadata = queueApiCall(MetaDataApi.deleteMetadata);

export function deleteMetadataAction(metadataId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeleteMetadata(metadataId)
            .then((response) => {
                dispatch(deleteMetaDataResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getMetadatasAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}