import * as ActionType from './ActionType';
import AssetLabelApi from '../api/AssetLabelApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getAssetLabelsResponse = assetlabelsdata => ({
    type: ActionType.GET_ASSET_LABELS_RESPONSE,
    assetlabelsdata
});

export function getAssetLabelsAction(areCollectionsIncluded = false) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return AssetLabelApi.getAllAssetLabels(areCollectionsIncluded)
            .then(assetlabelsdata => {
                dispatch(getAssetLabelsResponse(assetlabelsdata));
                return assetlabelsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewLabelResponse = () => ({
    type: ActionType.ADD_NEW_ASSET_LABEL_RESPONSE
});

export const updateExistingLabelResponse = () => ({
    type: ActionType.UPDATE_EXISTING_ASSET_LABEL
});

export function saveLabelAction(labeldraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return AssetLabelApi.saveAssetLabel(labeldraft)
            .then((response) => {
                if (labeldraft.id) {
                    dispatch(updateExistingLabelResponse());
                } else {
                    dispatch(addNewLabelResponse());
                }
                return response;
            }).then((response) => {
                if (labeldraft.id == "0") {
                    dispatch(getLabelAction(labeldraft.id));
                }
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                console.log('Action error:', error);
                throw (error);
            });
    };
}

export const getLabelResponse = assetlabeldata => ({
    type: ActionType.GET_ASSET_LABEL_RESPONSE,
    assetlabeldata
});

export function getLabelAction(labelId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return AssetLabelApi.getAssetLabel(labelId)
            .then(labeldata => {
                dispatch(getLabelResponse(labeldata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteLabelResponse = () => ({
    type: ActionType.DELETE_ASSET_LABEL_RESPONSE
});

const queuedDeleteLabel = queueApiCall(AssetLabelApi.deleteAssetLabel);

export function deleteAssetLabelAction(labelId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeleteLabel(labelId)
            .then((response) => {
                dispatch(deleteLabelResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getAssetLabelsAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}