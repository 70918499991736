import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedAppReducer = (state = initialState.selectedAppReducer, action) => {
    switch(action.type) {

        case ActionType.GET_APP_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                appdata: _.assign(action.appdata)
            };
        }

        default: { return state; }
    }
};


export default selectedAppReducer;