import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as BeaconAction from '../action/BeaconAction';
import * as BuildingAction from '../action/BuildingAction';
import * as LevelAction from '../action/LevelAction';
import BeaconListTable from './BeaconListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';

export class BeaconListContainer extends React.Component {

    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            selectedCategory: 0,
            deletedCategoryId: 0,
            open: undefined,
            beaconsdataLoading: true,
            buildingsdataLoading: true,
            levelsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }

    componentDidMount() {
        this.props.action.getBeaconsAction()
            .then(response => this.setState({ beaconsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Categories List Error', error);
            });
        this.props.action.getBuildingsAction()
            .then(response => this.setState({ buildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
        this.props.action.getLevelsAction()
            .then(response => this.setState({ levelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
    }

    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedCategory: nro });
        this.onOpen();
    }

    eventDelete(event) {
        const selectedId = this.state.selectedCategory;

        if (selectedId) {
            this.setState({ selectedCategory: 0, open: undefined });
            this.setState({ deletedCategoryId: selectedId });
            this.props.action.deleteCategoryAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- Category List Delete Error', error);
                });
        }
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        //this.props.history.push('/category/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        //this.props.history.push('/category/' + nro);
    }

    render() {
        const { beaconsdata, buildingsdata, levelsdata } = this.props;
        const { open, selectedCategory, deletedCategoryId, beaconsdataLoading, buildingsdataLoading, levelsdataLoading } = this.state;

        if (beaconsdataLoading || buildingsdataLoading || levelsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }

        return (
            <Box flex justify='between' fill>
                <form>
                    <BeaconListTable beaconsdata={beaconsdata} buildingsdata={buildingsdata} levelsdata={levelsdata.filter(f => f.meridianId)} handleNew={this.handleNew}
                        handleDelete={this.handleDelete} handleEdit={this.handleEdit} deletedCategoryId={deletedCategoryId} history={this.props.history} />
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    beaconsdata: state.beaconsReducer.beaconsdata,
    levelsdata: state.levelsReducer.levelsdata,
    buildingsdata: state.buildingsReducer.buildingsdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...BeaconAction, ...BuildingAction, ...LevelAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(BeaconListContainer);
