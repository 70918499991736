import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SpaceAction from '../action/SpaceAction';
import * as LevelAction from '../action/LevelAction';
import * as BuildingAction from '../action/BuildingAction';
import LocMappingListTable from './LocMappingListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';


export class LocMappingListContainer extends React.Component {

    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            selectedRole: 0,
            open: undefined,
            spacesdataLoading: true,
            levelsdataLoading: true,
            buildingsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
        // mock data
        this.SPACES = [
            { id: 1, status: 2, type: "Room", name: "", placemark: "", space: "Portumna" },
            { id: 2, status: 2, type: "Room", name: "", placemark: "Portumnaa", space: "" },
            { id: 3, status: 2, type: "Room", name: "", placemark: "Croagh Patrick", space: "" },
            { id: 4, status: 2, type: "Room", name: "", placemark: "", space: "Croagh Patrick Room" },
            { id: 5, status: 1, type: "Desk", name: "", placemark: "Desk 1", space: "Desk 1" },
            { id: 6, status: 1, type: "Room", name: "", placemark: "Erris", space: "Erris" },
        ];
        this.LEVELS = [
            { id: 1, status: 2, building: "ILO 1", master: "", maplevel: "Floor 3", spacelevel: "" },
            { id: 2, status: 2, building: "ILO 1", master: "", maplevel: "", spacelevel: "Level 4" },
            { id: 3, status: 1, building: "ILO 2", master: "Level 1", maplevel: "Floor 1", spacelevel: "Level 1" },
            { id: 4, status: 1, building: "ILO 2", master: "Level 2", maplevel: "Floor 2", spacelevel: "Level 2" },
        ];
        // mock data
    }

    componentDidMount() {
        this.props.action.getSpacesAction()
            .then(response => this.setState({ spacesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Spaces List Error', error);
            });
        this.props.action.getLevelsAction()
            .then(response => this.setState({ levelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getBuildingsAction()
            .then(response => this.setState({ buildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
    }

    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedRole: nro });
        this.onOpen();
    }

    eventDelete(event) {
        const selectedId = this.state.selectedRole;

        if (selectedId) {
            this.setState({ selectedRole: 0, open: undefined });
            this.props.action.deleteRoleAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- Role List Delete Error', error);
                });
        }
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        //this.props.history.push('/role/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push('/role/' + nro);
    }

    render() {
        let { spacesdata, levelsdata, buildingsdata } = this.props;
        const { open, selectedRole, spacesdataLoading, levelsdataLoading, buildingsdataLoading } = this.state;

        //mock data
        //spacesdata = [];
        //this.SPACES.forEach(s => {
        // spacesdata.push({ ...s})
        //});
        //levelsdata = [];
        //this.LEVELS.forEach(l => {
        // levelsdata.push({ ...l})
        //});
        //mock data

        if (spacesdataLoading || levelsdataLoading || buildingsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }

        return (
            <Box flex justify='between' fill>
                <form>
                    <LocMappingListTable spacesdata={spacesdata} levelsdata={levelsdata} buildingsdata={buildingsdata} handleNew={this.handleNew} handleDelete={this.handleDelete} handleEdit={this.handleEdit} />
                </form>
            </Box>
        );
    }
}


const mapStateToProps = state => ({
    spacesdata: state.spacesReducer.spacesdata,
    levelsdata: state.levelsReducer.levelsdata,
    buildingsdata: state.buildingsReducer.buildingsdata,
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SpaceAction, ...LevelAction, ...BuildingAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(LocMappingListContainer);
