import * as ActionType from './ActionType';


export const ApiCallBeginAction = () => ({
    type: ActionType.API_CALL_BEGIN
});


export const ApiCallErrorAction = () => ({
    type: ActionType.API_CALL_ERROR
});