import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken, getAllRecords } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

const SERVER_URL = `${SERVER}/tables/quickaction`;
console.log('HOST:', SERVER_URL);
const apiqueue = [];

const dequeue = () => {
    apiqueue.splice(0, 1);
};

const queueApiCall = (fnApiCall) => {
    return (...args) => {
        let apicall;
        const invokeApi = () => fnApiCall(...args);

        if (apiqueue.length === 0) {
            apicall = invokeApi();
        } else {
            apicall = apiqueue[apiqueue.length - 1].then(invokeApi, invokeApi);
        }
        apiqueue.push(apicall.then(dequeue, dequeue));
        return apicall;
    }
}

const applyHocOnClass = classDef => {
    Object.getOwnPropertyNames(classDef).filter(prop => typeof QuickActionApi[prop] === 'function').forEach(method => {
        classDef[method] = queueApiCall(classDef[method]);
    });
};

class QuickActionApi {
    static async getAllQuickActions() {
        // const options = {
        //     method: 'get',
        //     headers: await myHeadersWithToken()
        // }
        // return fetch(`${SERVER_URL}/?${API_VERSION}`, options).then(response => response.json());
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}?$count=true&$top=0&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                return getAllRecords(`${SERVER_URL}/?${API_VERSION}`, options, data.count)
                    .catch(error => {
                        throw error;
                    });
            })
            .catch(error => {
                throw error;
            });
    }

    static async saveQuickAction(quickactiondata) {
        if (quickactiondata.id && quickactiondata.id != "0") {
            return fetch(`${SERVER_URL}/${quickactiondata.id}/?${API_VERSION}`, {
                method: 'put',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(quickactiondata)
            }).then(response => response);
        } else {
            return fetch(`${SERVER_URL}/?${API_VERSION}`, {
                method: 'post',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(quickactiondata)
            }).then(response => response);
        }
    }

    static async deleteQuickAction(quickactionId) {
        const options = {
            method: 'delete',
            headers: await myHeadersWithToken()
        }
        return fetch(`${SERVER_URL}/${quickactionId}/?${API_VERSION}`, options)
            .then(response => response);
    }


    static async getQuickAction(quickactionId) {
        console.log('API:', quickactionId);
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        }
        return fetch(`${SERVER_URL}/${quickactionId}/?${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }
}


applyHocOnClass(QuickActionApi);

export default QuickActionApi;
