import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const appsReducer = (state = initialState.appsReducer, action) => {
    switch(action.type) {
        case ActionType.GET_APPS_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                appsdata: _.assign(action.appsdata)
            };
        }


        default: { return state; }
    }
};



export default appsReducer;