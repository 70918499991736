import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, CheckBox, Tabs, Tab } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class UserListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.state = {
            search: "",
        };
    }

    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt,id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        let tid = elem.getElementsByTagName('th')[0].textContent;

                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }
    ArrayToString(arr) {
        var string = "";
        arr.forEach(function (item, idx) {
            if (idx == arr.length - 1) {
                string += item.role.name;
            }
            else {
                string += item.role.name;
                string += ", ";
            }

        })
        return string;
    }
    render() {
        return (
            <StyledBox ref={this.dtwrapper} style={{ "min-width": "790px" }}>
                {this.renderUsersTable(this.props)}
            </StyledBox>
        );
    }

    renderUsersTable(props) {
        const COLUMNS = [
            {
                property: 'lcusername',
                header: 'Username',
                //render: usersdata => usersdata.superUser? (<Text color="status-critical">{usersdata.username}</Text>):(<Text>{usersdata.username}</Text>)
                render: usersdata => <Text>{usersdata.username}</Text>
            },
            {
                property: 'lcemail',
                header: 'Email',
                render: usersdata => <Text>{usersdata.email}</Text>
            },
            //{
            //    property: 'lcrole',
            //    header: 'Role',
            //    render: usersdata => <Text>{usersdata.role}</Text>
            //},
            {
                property: 'orgadmin',
                header: <Box justify="center" height="100%">Org Admin</Box>,
                size: "75px",
                render: usersdata => <CheckBox readOnly checked={usersdata.orgadmin} disabled />
            },
            {
                property: 'lcactive',
                header: <Box justify="center" height="100%">Active</Box>,
                size: "50px",
                render: usersdata => <CheckBox readOnly checked={usersdata.lcactive} disabled />
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: usersdata => <Text hidden>{usersdata.id}</Text>,
            },
            {
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: usersdata => !RB.isSuperUser() ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, usersdata.id)} icon={<Blank />} /></Box>
                    :
                    <Box width="24px" direction='row' gap="xsmall">
                        {/*<Button plain disabled={props.deletedUserIds.includes(usersdata.id)} onClick={evt => props.handleEdit(evt, usersdata.id)} icon={<Edit />} />*/}
                        <Button plain disabled={props.deletedUserIds.includes(usersdata.id)} onClick={evt => props.handleDelete(evt, usersdata.id)} icon={<Trash />} />
                    </Box>,
            },
        ];

        let DATA = [];
        const locationid = sessionStorage.getItem("locationId");

        function isOrgAdmin(user) {
            var orgAdmin = false;
            if (!user.userRoles || user.userRoles.length == 0) {
                return orgAdmin;
            }
            user.userRoles.forEach(r => {
                if (r.role.name == "Organisation Admin") {
                    orgAdmin = true;
                }
            })
            return orgAdmin;
        }

        function isActive(user) {
            var active = false;
            if (!user.userRoles || user.userRoles.length == 0) {
                return active;
            }
            if (user.userRoles.filter(r => r.active).length != 0) {
                active = true;
            }
            return active;
        }

        if (props.usersdata[0] && props.usersdata[0].count != 0) {
            props.usersdata.forEach(ev => {
                const roles = ev.userRoles.filter(r => r.locationId == locationid).slice();
                if (true) {
                    DATA.push({
                        ...ev, lcusername: ev.username.toLowerCase(),
                        lcemail: ev.email.toLowerCase(),
                        //role: ev.roles.map(role => role.name).toString(),
                        //role: this.ArrayToString(ev.userRoles),
                        role: this.ArrayToString(roles),
                        //lcrole: ev.roles.map(role => role.name).toString().toLowerCase(),
                        //lcrole: this.ArrayToString(ev.userRoles).toLowerCase(),
                        lcrole: this.ArrayToString(roles).toLowerCase(),
                        lcactive: isActive(ev),
                        orgadmin: isOrgAdmin(ev)
                    })
                }
            });
        }
        let notFilteredData = [...DATA];

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.username.toLowerCase().includes(search) ||
                d.email.toLowerCase().includes(search) ||
                ("active".includes(search) && d.lcactive) ||
                ("org admin".includes(search) && d.orgadmin)
            );
        }

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcusername" || c.property === "lcemail" || c.property === "lcrole"
                }))}
                data={DATA}
                noLoadingMsg={this.props.usersdata.length != 0 && notFilteredData.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcusername', label: 'Username' },
                //    { col: 'lcemail', label: 'Email' },
                //    { col: 'lcactive', label: 'Active' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                onUpdated={this.onDTUpdated.bind(this)}
                styled
                line1="No users added yet."
                line2={<div>Add one now via the <b>Create New</b> button</div>}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }
}

export default UserListTable;
