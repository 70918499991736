import React, { Component } from 'react';


class UIErrorHandler extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        // console.log(error, errorInfo);
    }

    render() {
        if (this.state.errorInfo) {

            return (
                <div>
                    <h2>Something went wrong.&nbsp;Please, reload the page</h2>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }
        return this.props.children;
    }
}

export class SimulateError extends Component {

    state = { error: false }

    onClickHandler = () => {
        this.setState({ error: true });
    }

    render() {
        if (this.state.error) {
            throw new Error('An simulated runtime JS error...');
        }
        return (
            <button onClick={this.onClickHandler}>Press me to generate error</button>
        );
    }
}

export default UIErrorHandler;