import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SiteAction from '../action/SiteAction';
import * as OrganisationAction from '../action/OrganisationAction';
import * as BuildingAction from '../action/BuildingAction';
import * as LevelAction from '../action/LevelAction';
import * as MergedBuildingAction from '../action/MergedBuildingAction';
import * as MergedLevelAction from '../action/MergedLevelAction';
import * as TimeZoneAction from '../action/TimeZoneAction';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import { withRouter } from 'react-router';
// eslint-disable-next-line
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, CheckBox, TextArea, Stack, Tabs, Tab, MaskedInput } from 'grommet';
import { Trash, Upload, CircleInformation } from 'grommet-icons';
import { error } from 'util';
import TextInputWithState from './common/TextInputWithState';
import constants from '../constants';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { StyledFormField } from './common/StyledFormField';
import BuildingListTable from './BuildingListTable';
import FloorListTable from './FloorListTable';
import LoadingIndicator from './common/LoadingIndicator';
import { Context } from './Context';

var start = undefined;
var base64 = null;

export class ConfigurationEditContainer extends React.Component {
    static contextType = Context;
    constructor() {
        super();
        this.load = true;
        this.stamp = Date.now().toString();
        this.state = {
            file: "",
            newimage: false,
            imagechanged: false,
            removeIconOpen: false,
            location: "",
            isSaved: false,
            loaded: false,
            meridian: false,
            cancel: false,
            disabledButton: false,
            isSaveSuccessful: false,
            type: null,
            coordinates: "",
            regexp: "",
            sitedataLoading: true,
            organisationdataLoading: true,
            mergedlevelsdataLoading: true,
            mergedbuildingsdataLoading: true,
            levelsdataLoading: true,
            timezonesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }
    coordinates_err = "";
    timeZone_err = "";
    businessStarts_err = "";
    businessEnds_err = "";
    allDay_err = "";
    image_err = "";

    componentDidMount() {
        let index = parseInt(this.props.match.params.tab, 10) || 0;
        if (index >= 3) {
            index = 0;
        }
        //console.log('Tab:', index);
        this.setState({ index: index });
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        this.props.action.getSiteAction(this.props.match.params.locid)
            .then(response => this.setState({ sitedataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Site Edit Error', error);
            });
        //const id = constants.organisation.id;
        const id = sessionStorage.getItem("organisationId");
        this.props.action.getOrganisationAction(id)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
        this.props.action.getMergedLevelsAction()
            .then(response => this.setState({ mergedlevelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getMergedBuildingsAction()
            .then(response => this.setState({ mergedbuildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
        this.props.action.getLevelsAction()
            .then(response => this.setState({ levelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getTimeZonesAction()
            .then(response => this.setState({ timezonesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
    }

    componentWillReceiveProps(props) {

        //if (this.state.index != props.match.params.tab) {
        //    let index = parseInt(props.match.params.tab, 10) || 0;
        //    if (index >= 3) {
        //        index = 0;
        //    }
        //    //console.log('Tab:', index);
        //    this.setState({ index: index });
        //}
    }

    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }

        if (!this.state.cancel && this.props.sitedata && (!_.isEqual(start, this.props.sitedata) || this.state.imagechanged)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }
    ClearErrorMessages() {
        this.coordinates_err = "";
        this.timeZone_err = "";
        this.businessStarts_err = "";
        this.businessEnds_err = "";
        this.allDay_err = "";
        this.image_err = "";
    }

    handleCancel() {
        if (!this.state.cancel && (!_.isEqual(start, this.props.sitedata) || this.state.imagechanged)) {
            this.setState({ cancel: true });
        }
        else {
            const siteId = this.props.sitedata.id;
            this.props.action.getSiteAction(siteId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        if (!RB.canEdit(constants.PERMS.LOC_CONTENT) && constants.RELEASE_TYPE == "HPE-MTF")
                            this.props.history.push(constants.getSiteLevelRoute() + '/overview');
                        else
                            this.props.history.push(constants.getSiteLevelRoute() + '/home');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-ConfigurationEdit Error', error);
                    if (!RB.canEdit(constants.PERMS.LOC_CONTENT) && constants.RELEASE_TYPE == "HPE-MTF")
                        this.props.history.push(constants.getSiteLevelRoute() + '/overview');
                    else
                        this.props.history.push(constants.getSiteLevelRoute() + '/home');
                });
        }
    }

    readFile(f) {
        if (f) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const data = event.target.result;
                console.log('File', data.length);
                //const base64data = btoa(unescape(encodeURIComponent(data)));
                const base64data = btoa(data);
                //console.log('File Base64:', base64data);
                base64 = base64data;
                //ToDo call API with file.name, and content
                //this.BinaryData = data; 
            };
            reader.readAsBinaryString(f);
        }
    }
    fileChangedHandler = (event) => {
        //console.log(event.target.files[0]);
        const file = event.target.files[0];
        console.log(file);
        if (file != undefined) {
            if (file.size > 1024 * 1024 * 2) {
                this.image_err = "File too large: " + Math.round((file.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.image_err = "";
                this.readFile(file);
                this.setState({ file: file, newimage: true, imagechanged: true });
            }
        }
        else {

        }

    }
    removeIcon() {
        this.props.sitedata.logoUrl = null;
        this.props.sitedata.logo = null;
        base64 = null;
        this.setState({ removeIconOpen: false, newimage: false });
    }
    shortcut() {
        if (this.state.file == "") {
            return null;
        }
        else if (this.state.file != null) {
            var Data = [];
            Data.push(this.state.file);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/x-png,image/jpeg" }))
            return (
                <Box direction="row">
                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                    <Image style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("real");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return null;
            //return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }
    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    onActive(index) {
        this.setState({ index: index });
    }
    updateCoordinates(evt) {
        var coordinates = evt.target.value;
        if (coordinates != "" && !constants.REGEX.COORDINATES.test(coordinates)) {

            this.coordinates_err = "Please ensure the coordinates are properly formed (ie: 53.2926521, -9.007333)";
        }
        else {
            this.coordinates_err = "";
            this.props.sitedata.latitude = evt.target.value.substr(0, evt.target.value.indexOf(','));
            this.props.sitedata.longitude = evt.target.value.split(" ")[1];
        }

        this.setState({ coordinates: coordinates });
    }
    lookUpTimeZone(option) {
        if (this.props.timezonesdata.filter(tz => tz.name == option)[0]) {
            var TimeZone = this.props.timezonesdata.filter(tz => tz.name == option)[0]
            return TimeZone.name + " [UTC" + TimeZone.utcOffset + "]"
        }
    }
    updateBusinessStart(evt) {
        this.props.sitedata.businessStart = evt.target.value;
        this.setState({ update: !this.state.update });
    }
    updateBusinessEnd(evt) {
        this.props.sitedata.businessEnd = evt.target.value;
        this.setState({ update: !this.state.update });
    }
    setAllDay() {
        this.props.sitedata.businessAllDay = !this.props.sitedata.businessAllDay;
        this.setState({ update: !this.state.update });
    }
    handleEdit = (path, id) => {
        this.props.history.push(`${path}/${id}`);
    }
    syncSettings() {
        const { sitedata, organisationdata } = this.props;

        sitedata.mobileAppEnabled = organisationdata.mobileAppEnabled;
        sitedata.deskBookingEnabled = organisationdata.deskBookingEnabled;
        sitedata.roomBookingEnabled = organisationdata.roomBookingEnabled;
        sitedata.smartWirelessChargingEnabled = organisationdata.smartWirelessChargingEnabled;
        sitedata.contentEnabled = organisationdata.contentEnabled;
        sitedata.eventsEnabled = organisationdata.eventsEnabled;
        sitedata.meetingsEnabled = organisationdata.meetingsEnabled;
        sitedata.mapsEnabled = organisationdata.mapsEnabled;
        sitedata.assetTrackingEnabled = organisationdata.assetTrackingEnabled;
        sitedata.assetTrackingHistoryEnabled = organisationdata.assetTrackingHistoryEnabled;
        sitedata.personTrackingEnabled = organisationdata.personTrackingEnabled;
        sitedata.wayfindingEnabled = organisationdata.wayfindingEnabled;
        sitedata.findAFriendEnabled = organisationdata.findAFriendEnabled;
        sitedata.notificationsEnabled = organisationdata.notificationsEnabled;
        sitedata.venueManagementEnabled = organisationdata.venueManagementEnabled;
        sitedata.meetAndGreetEnabled = organisationdata.meetAndGreetEnabled;

        this.setState({ updated: true });
    }
    renderFeature({ name = "", property = "", level = 0, parent = "", children = [], showInfo = false, featureInfo = "" } = {}) {
        const sitedata = this.props.sitedata;
        const organisationdata = this.props.organisationdata;
        var marginLeft = level * 24 + "px";
        var paddingTop = level == 0 ? "small" : "xsmall";
        var paddingBottom = level == 0 && children.length == 0 ? "small" : "xsmall";

        function isDisabled() {
            if (!RB.isSuperUser()) {
                return true;
            }
            if (parent != "" && parent != "none" && !sitedata[parent]) {
                return true;
            }
            if (!organisationdata[property]) {
                return true;
            }
            else return false;
        }

        function changeBackground(set) {
            if (set) document.getElementById("box_id" + property).style.background = "#DADADA";
            else document.getElementById("box_id" + property).style.background = "transparent";
        }

        if ((parent != "" && parent != "none" && !sitedata[parent]) || !organisationdata[property]) {
            sitedata[property] = false;
        }

        function dataTipText() {
            if (!organisationdata[property]) return "This feature is disabled at org level."
            else if (isDisabled() && organisationdata[property]) return "Enable parent feature in order to use this feature."
            else return ""
        }

        return (
            <Box margin={{ "left": marginLeft }} direction="row" justify="between" pad={{ "top": paddingTop, "bottom": paddingBottom }} gap="large">
                <Box id={"box_id" + property} pad={{ "horizontal": "xsmall" }} round="5px" direction="row" gap="small" align="center">
                    <Text size="large">{name}</Text>
                    {showInfo &&
                        <Box data-for={"featureinfo" + property} data-tip={featureInfo} style={{ "height": "24px" }}>
                            <CircleInformation />
                            <ReactTooltip id={"featureinfo" + property} place="right" type="dark" effect="solid" disable={!showInfo} arrowColor="transparent" />
                        </Box>}
                </Box>
                <Box data-for={"disableinfo" + property} data-tip={dataTipText()} style={{ "height": "24px" }} onMouseEnter={() => changeBackground(true)} onMouseLeave={() => changeBackground(false)} >
                    <CheckBox toggle checked={sitedata[property] && organisationdata[property]} disabled={isDisabled()}
                        onChange={evt => (sitedata[property] = !sitedata[property], !sitedata[property] ? this.setState({ featureChanged: true }) : this.setState({ updated: true }))} />
                    <ReactTooltip id={"disableinfo" + property} place="right" type="dark" effect="solid" disable={!isDisabled()} arrowColor="transparent" />
                </Box>
            </Box>
        )
    }

    renderGeneral() {
        const organisationdefault = this.props.organisationdata;
        const sitedefault = this.props.sitedata;

        const { mergedbuildingsdata, mergedlevelsdata, levelsdata, timezonesdata } = this.props;
        //permissions

        const canread1 = RB.canRead(constants.PERMS.LOC_CONF_SITE);
        const canread2 = RB.canRead(constants.PERMS.LOC_CONF_INTEGR); // || true //only perms test
        const canread3 = RB.canRead(constants.PERMS.LOC_CONF_MAPS);
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONF_SITE);
        const canedit2 = RB.canEdit(constants.PERMS.LOC_CONF_INTEGR);
        const canedit3 = RB.canEdit(constants.PERMS.LOC_CONF_MAPS);
        const canedit4 = RB.canEdit(constants.PERMS.ORG_ADMINISTRATION);

        return (
            <Box pad={{ "bottom": "medium" }} gap="medium">
                <Box>
                    <Box pad={{ "bottom": "small" }}>
                        {/*<Text>All fields marked with an * are mandatory.</Text>*/}
                        <Text size="large">General Settings</Text>
                        <Text>All fields marked with an * are mandatory.</Text>
                    </Box>
                    <StyledBox style1 pad={{ "horizontal": "small" }} width="750px">
                        <FormField label="Site Name">
                            <TextInput defaultValue={sitedefault.name} readOnly disabled />
                        </FormField>
                        <FormField label="Site ID" >
                            <TextInput defaultValue={sitedefault.id} readOnly disabled />
                        </FormField>
                        <Box>
                            <a data-for="gps" data-tip>
                                <FormField noinsideborder label="GPS Coordinates" error={this.coordinates_err}>
                                    <TextInput onChange={evt => this.updateCoordinates(evt)}
                                        defaultValue={(sitedefault.latitude != null && sitedefault.longitude != null) ? (sitedefault.latitude + ", " + sitedefault.longitude) : ""}
                                        readOnly={!canedit1 || sitedefault.meridianEnabled} disabled={!canedit1 || sitedefault.meridianEnabled} />
                                </FormField>
                            </a>
                            <ReactTooltip id="gps" place="bottom" type="dark" effect="solid" arrowColor="transparent">
                                {(this.context.feature.find(f => f.name == "siteMap").isEnabled || this.context.feature.find(f => f.name == "siteWayFinding").isEnabled) ?
                                    <span>Please ensure GPS Enabled is checked at org level in config to allow the client app to locate the users nearest site. <br />
                            This field is disabled when Meridian is set to active at site level.</span> :
                                    <span>Please ensure GPS Enabled is checked at org level in config to allow the client app to locate the users nearest site. </span>}
                            </ReactTooltip>
                        </Box>
                        <FormField label="Time Zone *" error={this.timeZone_err}>
                            <Select
                                placeholder="Select a Time Zone"
                                value={this.lookUpTimeZone(sitedefault.timeZone)}
                                onChange={({ option }) => { sitedefault.timeZone = option.split("[")[0].trim(), this.setState({ update: true }), this.timeZone_err = "" }}
                                options={timezonesdata.filter(s => this.lookUpTimeZone(s.name).match(this.state.regexp)).map(tz => tz.name + " [UTC" + tz.utcOffset + "]")}
                                onClose={() => this.setState({ regexp: "" })}
                                onSearch={(searchText) => {
                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                    this.setState({ regexp: regexp });
                                }}
                                disabled={!canedit1}
                            />
                        </FormField>
                        <Box direction="row">
                            <a data-for="hours" data-tip>
                                <Box direction="row" gap="medium" fill={false}>
                                    <Box width="120px">
                                        <FormField label="Business Hours Start" error={this.businessStarts_err}>
                                            <Box width="100px">
                                                <TextInput plain type="time" value={sitedefault.businessAllDay ? "00:00" : sitedefault.businessStart} disabled={!canedit1 || sitedefault.businessAllDay} onChange={evt => this.updateBusinessStart(evt)} />
                                            </Box>
                                        </FormField>
                                    </Box>
                                    <Box width="120px">
                                        <FormField label="Business Hours End" error={this.businessEnds_err}>
                                            <Box width="100px">
                                                <TextInput plain type="time" value={sitedefault.businessAllDay ? "00:00" : sitedefault.businessEnd} disabled={!canedit1 || sitedefault.businessAllDay} onChange={evt => this.updateBusinessEnd(evt)} />
                                            </Box>
                                        </FormField>
                                    </Box>
                                    <Box>
                                        <FormField label="All Day" error={this.allDay_err}>
                                            <Box pad="small">
                                                <CheckBox checked={sitedefault.businessAllDay} onChange={() => this.setAllDay()} disabled={!canedit1} />
                                            </Box>
                                        </FormField>
                                    </Box>
                                </Box>
                            </a>
                        </Box>
                        <ReactTooltip id="hours" place="bottom" type="dark" effect="solid" multiline arrowColor="transparent">
                            <span>Site business hours are linked to cleaning schedules for your spaces.<br />
                            It is advised to set these if you intend to put cleaning schedules in place.</span>
                        </ReactTooltip>
                    </StyledBox>
                </Box>

                {this.context.feature.find(f => f.name == "siteMobilClient").isEnabled &&
                    <Box gap="small">
                        <Box direction="row" gap="small" align="center">
                            <Text size="large">Site Image</Text>
                            <Box data-for="img_info" data-tip>
                                <CircleInformation />
                                <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                    <span>Please select a brand approved image to display as your site image.<br />
                                        This will appear on your homepage and be used as a default image for placemarks if no other image is applied. <br />
                                        Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.</span>
                                </ReactTooltip>
                            </Box>
                        </Box>
                        {/*<Box width="750px">*/}
                        {/*    <Text>*/}
                        {/*        Please select a brand approved image to display as your site image. This will appear on your homepage and be used as a default image for placemarks if no other image is applied. <br />*/}
                        {/*        Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.*/}
                        {/*    </Text>*/}
                        {/*</Box>*/}
                        <Box direction="row" gap="small">
                            <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }} width={{ "min": "101px" }} fill={false} margin={{ "bottom": "small" }}>
                                <FormField label="" error={this.image_err}>
                                    <Box direction="row" justify="center" >
                                        {this.state.newimage ?
                                            <Box alignSelf="center">
                                                {this.shortcut()}
                                            </Box>
                                            :
                                            (sitedefault.logoUrl == "" || sitedefault.logoUrl == null) ?
                                                <Box height="75px" justify="center">
                                                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                    <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} disabled={!canedit1}
                                                        onClick={e => {
                                                            const c = document.getElementById("real");
                                                            c.click();
                                                        }} />
                                                </Box>
                                                :
                                                <Box justify="center">
                                                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                    <Image height="75" src={`${sitedefault.logoUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                        onClick={e => {
                                                            const c = document.getElementById("real");
                                                            c.click();
                                                        }} />
                                                </Box>
                                        }
                                    </Box>
                                </FormField>
                            </StyledBox>
                            <Box justify="center" margin={{ "bottom": "small" }}>
                                <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={!canedit1 || ((sitedefault.logoUrl == "" || sitedefault.logoUrl == null) && !this.state.newimage)}>
                                    <Trash />
                                </Button>
                            </Box>
                        </Box>
                        {/*<Box width="150px" margin={{ "top": "small" }}>*/}
                        {/*    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />*/}
                        {/*    <StyledButton typeAdd label="Choose a file" disabled={!canedit1} onClick={e => {*/}
                        {/*        const c = document.getElementById("real");*/}
                        {/*        c.click();*/}
                        {/*    }} />*/}
                        {/*</Box>*/}
                    </Box>
                }
                {RB.isSuperUser() &&
                    <Box gap="small">
                        <Box direction="row" gap="large">
                            <Box gap="xsmall">
                                {/*<Text size="large">Client Features</Text>*/}
                                <Box pad={{ "right": "small" }}>
                                    {/*{this.renderFeature({ name: "Client Features", property: "mobileAppEnabled", level: 0, parent: "none", children: ["notificationsEnabled", "contentEnabled", "roomBookingEnabled", "deskBookingEnabled", "venueManagementEnabled", "mapsEnabled"], showInfo: false, featureInfo: "Feature info" })}*/}
                                    <Box direction="row" justify="between" pad={{ "bottom": "small" }}>
                                        <Text size="large">Features</Text>
                                        <Box style={{ "height": "24px" }}>
                                            <CheckBox toggle checked={sitedefault.mobileAppEnabled} disabled={!RB.isSuperUser()}
                                                onChange={evt => (sitedefault.mobileAppEnabled = !sitedefault.mobileAppEnabled, !sitedefault.mobileAppEnabled ? this.setState({ featureChanged: true }) : this.setState({ updated: true }))} />
                                        </Box>
                                    </Box>
                                </Box>
                                <StyledBox style1 pad={{ "horizontal": "small", "bottom": "xsmall" }} width="600px">
                                    {this.renderFeature({ name: "Book a Space - Desks", property: "deskBookingEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                    {this.renderFeature({ name: "Book a Space - Rooms", property: "roomBookingEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                    {this.renderFeature({ name: "Chargifi", property: "smartWirelessChargingEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                    {this.renderFeature({ name: "Content", property: "contentEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                    {this.renderFeature({ name: "Meridian Maps (Maps & Placemarks)", property: "mapsEnabled", level: 0, parent: "mobileAppEnabled", children: ["assetTrackingEnabled", "personTrackingEnabled", "wayfindingEnabled"], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                    {this.renderFeature({ name: "Meridian Bluedot (Beacons & Wayfinding)", property: "wayfindingEnabled", level: 1, parent: "mapsEnabled", children: ["findAFriendEnabled"], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                    {this.renderFeature({ name: "Locate People", property: "findAFriendEnabled", level: 2, parent: "wayfindingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                    {this.renderFeature({ name: "Meridian Tags (Assets & Trackers)", property: "assetTrackingEnabled", level: 1, parent: "mapsEnabled", children: ["assetTrackingHistoryEnabled"], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                    {this.renderFeature({ name: "Meridian Tags (Asset History)", property: "assetTrackingHistoryEnabled", level: 2, parent: "assetTrackingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                    {this.renderFeature({ name: "Meridian Tags (People)", property: "personTrackingEnabled", level: 1, parent: "mapsEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                    {this.renderFeature({ name: "Push Notifications", property: "notificationsEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                    {this.renderFeature({ name: "Venue Management", property: "venueManagementEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                    {this.renderFeature({ name: "Visitor Notifications", property: "meetAndGreetEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                    {/*{this.renderFeature({ name: "Meeting Services - Rooms", property: "meetingsEnabled", level: 1, parent: "roomBookingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}*/}
                                    {/*{this.renderFeature({ name: "Meeting Services - Desks", property: "meetingsEnabled", level: 1, parent: "deskBookingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}*/}
                                </StyledBox>
                            </Box>
                            {false &&
                                <Box gap="xsmall">
                                    {/*<Text size="large">Portal Features</Text>*/}
                                    <Box pad={{ "right": "small" }}>
                                        {/*{this.renderFeature({ name: "Portal Features", property: "webAppEnabled", level: 0, parent: "none", children: ["meetingsEnabled", "deskBookingEnabled", "mapsEnabled"], showInfo: false, featureInfo: "Feature info" })}*/}
                                        <Box direction="row" justify="between" pad={{ "bottom": "small" }}>
                                            <Text size="large">Portal Features</Text>
                                            <Box style={{ "height": "24px" }}>
                                                <CheckBox toggle checked={sitedefault.webAppEnabled} disabled={!RB.isSuperUser()}
                                                    onChange={evt => (sitedefault.webAppEnabled = !sitedefault.webAppEnabled, !sitedefault.webAppEnabled ? this.setState({ featureChanged: true }) : this.setState({ updated: true }))} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <StyledBox style1 pad={{ "horizontal": "small", "bottom": "xsmall" }} width="600px">
                                        {/*{this.renderFeature({ name: "Book a Space - Rooms", property: "roomBookingEnabled", level: 0, parent: "webAppEnabled", children: ["meetingsEnabled"], showInfo: false, featureInfo: "Feature info", place: "portal" })}*/}
                                        {/*{this.renderFeature({ name: "Meeting Services - Rooms", property: "meetingsEnabled", level: 1, parent: "roomBookingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}*/}
                                        {/*{this.renderFeature({ name: "Book a Space - Desks", property: "deskBookingEnabled", level: 0, parent: "webAppEnabled", children: ["meetingsEnabled"], showInfo: false, featureInfo: "Feature info", place: "portal" })}*/}
                                        {/*{this.renderFeature({ name: "Meeting Services - Desks", property: "meetingsEnabled", level: 1, parent: "deskBookingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}*/}
                                        {this.renderFeature({ name: "Meridian Maps (Maps & Placemarks)", property: "mapsEnabled", level: 0, parent: "webAppEnabled", children: ["assetTrackingEnabled"], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                        {this.renderFeature({ name: "Meridian Tags (Assets & Trackers)", property: "assetTrackingEnabled", level: 1, parent: "mapsEnabled", children: ["assetTrackingHistoryEnabled"], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                        {this.renderFeature({ name: "Meridian Tags (Asset History)", property: "assetTrackingHistoryEnabled", level: 2, parent: "assetTrackingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                    </StyledBox>
                                </Box>}
                            <Box>
                                <a data-for="Sync_Button" data-tip="Restore all org features for this site." style={{ "height": "36px" }} align="center">
                                    <StyledButton typeSave label="Sync" onClick={evt => this.syncSettings()} disabled={!RB.isSuperUser()} />
                                </a>
                                <ReactTooltip id="Sync_Button" place="left" type="dark" effect="solid" arrowColor="transparent" />
                            </Box>
                        </Box>
                    </Box>}
            </Box>);
    }
    handleSave() {
        const sitedefault = this.props.sitedata;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ disabledButton: true });
        if (this.state.coordinates != "" && !constants.REGEX.COORDINATES.test(this.state.coordinates)) {
            errorsno++;
            this.coordinates_err = "Please ensure the coordinates are properly formed (ie: 53.2926521, -9.007333)";
        }
        if (sitedefault.timeZone.length === 0) {
            errorsno++;
            this.timeZone_err = "Time Zone is required";
        }
        //if (!sitedefault.timeZone || sitedefault.timeZone == null) {
        //    errorsno++;
        //    this.timeZone_err = constants.EMPTY_ERROR;
        //}
        //if (!sitedefault.businessAllDay && (!sitedefault.businessStart || sitedefault.businessStart == null)) {
        //    errorsno++;
        //    this.businessStarts_err = constants.EMPTY_ERROR;
        //}
        //if (!sitedefault.businessAllDay && (!sitedefault.businessEnd || sitedefault.businessEnd == null)) {
        //    errorsno++;
        //    this.businessEnds_err = constants.EMPTY_ERROR;
        //}

        if (errorsno == 0) {
            if (this.state.newimage) {
                this.props.sitedata.logo = base64;
                base64 = null;
            }
            //this.props.sitedata.latitude = this.state.coordinates.substr(0, this.state.coordinates.indexOf(','));
            //this.props.sitedata.longitude = this.state.coordinates.split(" ")[1];
            console.log('Save: OK', sitedefault);
            this.props.action.saveSiteAction(sitedefault)
                .then((response) => {
                    this.setState({ isSaved: true, imagechanged: false, isSaveSuccessful: response.ok });
                    //this.props.history.push('/home');
                }).catch(error => {
                    console.log('*DEBUG*-Save Site Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.setState({ disabledButton: false });
        }

    }

    render() {
        const organisationdefault = this.props.organisationdata;
        const sitedefault = this.props.sitedata;
        const { mergedbuildingsdata, mergedlevelsdata, levelsdata, timezonesdata } = this.props;
        const { sitedataLoading, organisationdataLoading, mergedlevelsdataLoading, mergedbuildingsdataLoading, levelsdataLoading, timezonesdataLoading } = this.state;

        if (sitedataLoading || organisationdataLoading || mergedlevelsdataLoading || mergedbuildingsdataLoading || levelsdataLoading || timezonesdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        //permissions
        const canread1 = RB.canRead(constants.PERMS.LOC_CONF_SITE);
        const canread2 = RB.canRead(constants.PERMS.LOC_CONF_INTEGR); // || true //only perms test
        const canread3 = RB.canRead(constants.PERMS.LOC_CONF_MAPS);
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONF_SITE);
        const canedit2 = RB.canEdit(constants.PERMS.LOC_CONF_INTEGR);
        const canedit3 = RB.canEdit(constants.PERMS.LOC_CONF_MAPS);
        const canedit4 = RB.canEdit(constants.PERMS.ORG_ADMINISTRATION);

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                <PopUpConfirm open={this.state.removeIconOpen} text={"Please confirm that you want to delete this image. The master image associated with this site will no longer be visible in the app."}
                    onClose={() => this.setState({ removeIconOpen: false })} onDelete={() => this.removeIcon()} />
                <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' > Configuration </Heading>
                    {this.state.index == 0 &&
                        <Box direction="row" /*pad={{ horizontal: "small", }}*/ gap="small" >
                            <StyledButton typeCancel label={constants.visible(canedit1 || canedit2 || canedit3) == "visible" ? "Cancel" : "Back"} onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                            {constants.visible(canedit1 || canedit2 || canedit3) == "visible" &&
                                <StyledButton typeSave label="Save All" style={{ visibility: constants.visible(canedit1 || canedit2 || canedit3) }} onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />}
                        </Box>
                    }
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium", "bottom": "medium" }}>
                    <form>
                        {this.context.feature.find(f => f.name == "siteMap").isEnabled && constants.release().Feature_citrix &&
                            <Tabs justify="start" activeIndex={this.state.index} onActive={this.onActive.bind(this)}>
                                <Tab title="Site">
                                    {this.renderGeneral()}
                                </Tab>
                                <Tab title="Buildings">
                                    <BuildingListTable levelsdata={levelsdata} buildingsdata={mergedbuildingsdata} handleEdit={this.handleEdit} />
                                </Tab>
                                <Tab title="Floors">
                                    <FloorListTable levelsdata={mergedlevelsdata} handleEdit={this.handleEdit} publishdata={levelsdata} sitedata={sitedefault} />
                                </Tab>
                            </Tabs>}
                        {((this.context.feature.find(f => f.name == "siteMap").isEnabled == false) || !constants.release().Feature_citrix) &&
                            <Box justify="start" activeIndex={this.state.index} onActive={this.onActive.bind(this)}>
                                {this.renderGeneral()}
                            </Box>}
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>
            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const siteId = ownProps.match.params.locid || ''; //from the path '/category/:categoryId'
    if (state.selectedSiteReducer.sitedata && siteId == state.selectedSiteReducer.sitedata.id) {
        start = JSON.parse(JSON.stringify(state.selectedSiteReducer.sitedata));
        //console.log('**Initial value', start);
        return {
            sitedata: state.selectedSiteReducer.sitedata,
            organisationdata: state.selectedOrganisationReducer.organisationdata,
            mergedlevelsdata: state.mergedLevelsReducer.mergedlevelsdata,
            mergedbuildingsdata: state.mergedBuildingsReducer.mergedbuildingsdata,
            levelsdata: state.levelsReducer.levelsdata,
            timezonesdata: state.timeZonesReducer.timezonesdata,
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SiteAction, ...OrganisationAction, ...MergedBuildingAction, ...MergedLevelAction, ...LevelAction, ...TimeZoneAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfigurationEditContainer));