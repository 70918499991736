import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { bindActionCreators } from 'redux';
import * as SiteAction from '../action/SiteAction';
import * as OrganisationAction from '../action/OrganisationAction';
import * as UserAction from '../action/UserAction';
import * as RoleAction from '../action/RoleAction';
import constants from '../constants';
import RB from '../components/common/RBAC';
// eslint-disable-next-line
import { Grid, Box, Heading, Button, Text, FormField, TextInput, CheckBox, DataTable } from 'grommet';
import App from '../App';
import _ from 'lodash';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import { MsalContext } from "@azure/msal-react";
import withPagination from './common/PaginatedDataTable';

const PaginatedDataTable = withPagination(DataTable);

class Account extends Component {
    static contextType = MsalContext;
    constructor() {
        super();
        this.allRoles = [];
        this.allRolesSites = [];
        this.state = {
            sitesdataLoading: true,
            organisationsdataLoading: true,
            userdataLoading: true,
            rolesdataLoading: true,
            search: "",
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }

    componentDidMount() {
        sessionStorage.setItem("locationId", "");
        sessionStorage.setItem("organisationId", "");
        sessionStorage.setItem("organisationCode", "");

        this.props.action.getSitesActionNoId()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getOrganisationsAction()
            .then(response => this.setState({ organisationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Organisations List Error', error);
            });
        this.props.action.getUserAllRolesAction(/*pca.getAllAccounts()[0].username*/ this.context.accounts[0].username)
            .then(response => this.setState({ userdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-Users Error", error);
            });
        this.props.action.getRolesAction()
            .then(response => this.setState({ rolesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Roles List Error', error);
            });
    }

    componentWillReceiveProps() {

    }

    ArrayToString(arr) {
        let string = "";
        arr.forEach(function (item, idx) {
            if (idx == arr.length - 1) {
                string += item.name;
            } else {
                string += item.name + ", ";
            }

        })
        return string;
    }
    RolesToString(arr) {
        let string = "";
        arr.forEach(function (item, idx) {
            if (idx == arr.length - 1) {
                string += item.role.name;
            } else {
                string += item.role.name + ", ";
            }
        })
        return string;
    }
    RolesToArray(arr) {
        const list = [];
        arr.forEach(function (item, idx) {
            list.push(item.role.name);
        })
        return list;
    }

    renderRolesTable(props) {
        const COLUMNS = [
            {
                property: 'lctype',
                header: 'Role Type',
                render: rolesdata => <Text>{rolesdata.roleType}</Text>
            },
            {
                property: 'lcorg',
                header: 'Organization Name',
                render: rolesdata => <Text>{rolesdata.orgName}</Text>
            },
            {
                property: 'lcsite',
                header: 'Site Name',
                render: rolesdata => <Text>{rolesdata.siteName}</Text>
            },
            {
                property: 'lcrole',
                header: 'Role',
                render: rolesdata => <Text>{rolesdata.rolesString}</Text>
            },

        ];

        const { sitesdata, organisationsdata, userdata, rolesdata } = this.props;

        if (sitesdata && organisationsdata && userdata) {
            this.allRoles = [];
            userdata.userRoles.filter(r => r.active).forEach((ev, id) => {
                let oName = organisationsdata.find(s => s.id == ev.organizationId) ? organisationsdata.find(s => s.id == ev.organizationId).name : ev.organizationId;
                let sName = sitesdata.find(s => s.id == ev.locationId) ? sitesdata.find(s => s.id == ev.locationId).name : "";
                let type = "";
                if (ev.role) {
                    if (ev.role.level == "Organization") { type = "Organization" }
                    if (ev.role.level == "Location") { type = "Site" }
                }

                this.allRoles.push({
                    ...ev,
                    orgName: oName,
                    orgId: ev.organizationId,
                    siteName: sName,
                    siteId: ev.locationId,
                    roleType: type,
                    roleName: ev.role ? ev.role.name : ""
                });
            });

            this.allRoles.sort((p1, p2) => { if (p1.siteName.toLowerCase() < p2.siteName.toLowerCase()) return -1; return 1; });
            this.allRoles.sort((p1, p2) => { if (p1.orgName.toLowerCase() < p2.orgName.toLowerCase()) return -1; return 1; });

            this.allRolesSites = [];
            this.allRoles.forEach((ev, id) => {
                if (this.allRolesSites.filter(r => r.orgName == ev.orgName && r.siteName == ev.siteName && r.siteName != "").length == 0) {
                    this.allRolesSites.push({
                        orgName: ev.orgName,
                        orgId: ev.orgId,
                        siteName: ev.siteName,
                        siteId: ev.siteId,
                        roleType: ev.roleType,
                        roleName: [ev.roleName],
                    });
                }
                else {
                    this.allRolesSites.find(r => r.siteName == ev.siteName).roleName.push(ev.roleName);
                }
            });

            this.allRolesSites.forEach((ev, id) => { ev.roleName.sort((p1, p2) => { if (p1.toLowerCase() < p2.toLowerCase()) return -1; return 1; }); });

            this.orgsWithSites = [];
            organisationsdata.forEach((ev) => {
                if (this.allRolesSites.map(r => r.orgId).includes(ev.id)) {
                    this.orgsWithSites.push({
                        name: ev.name,
                        id: ev.id,
                        sites: sitesdata.filter(s => s.organizationId == ev.id).map(s => ({ name: s.name, id: s.id })),
                    });
                }
            })

            this.orgsWithSites.forEach((ev) => {
                let currentOrgRoles = this.allRolesSites.filter(r => r.orgId == ev.id);
                let siteRoles = rolesdata.filter(r => r.level == "Location").map(r => ({ name: r.name, count: 0, sites: [] }));
                siteRoles.forEach((e) => {
                    e.sites = currentOrgRoles.filter(r => r.roleName.includes(e.name)).map(r => r.siteName);
                    e.count = currentOrgRoles.filter(r => r.roleName.includes(e.name)).map(r => r.siteName).length;
                })

                if (siteRoles.filter(r => r.count == ev.sites.length && r.count != 0).map(r => r.name).length != 0) {
                    this.allRolesSites.unshift({
                        orgName: ev.name,
                        orgId: ev.id,
                        siteName: "All",
                        siteId: -1,
                        roleType: "Site",
                        roleName: siteRoles.filter(r => r.count == ev.sites.length).map(r => r.name),
                    });
                }
            })

            this.allRolesSites.forEach((ev) => {
                if (this.allRolesSites.find(r => r.siteId == -1 && r.orgId == ev.orgId)) {
                    let rolesAllSites = this.allRolesSites.find(r => r.siteId == -1 && r.orgId == ev.orgId).roleName.map(r => r);
                    if (ev.siteId != -1) {
                        ev.roleName = ev.roleName.filter(r => !rolesAllSites.includes(r))
                    }
                }
            })

            this.allRolesSites = this.allRolesSites.filter(r => r.roleName.length != 0);
            this.allRolesSites.sort((p1, p2) => { if (p1.roleType.toLowerCase() < p2.roleType.toLowerCase()) return -1; return 1; });
            this.allRolesSites.sort((p1, p2) => { if (p1.orgName.toLowerCase() < p2.orgName.toLowerCase()) return -1; return 1; });
        }

        let DATA = [];

        if (this.allRolesSites[0] && this.allRolesSites[0].count != 0) {
            this.allRolesSites.forEach(ev => {
                DATA.push({
                    ...ev,
                    rolesString: ev.roleName.join(", "),
                    lctype: ev.roleType.toLowerCase(),
                    lcorg: ev.orgName.toLowerCase(),
                    lcsite: ev.siteName.toLowerCase(),
                    get lcrole() { return this.rolesString.toLowerCase()}
                })
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.roleType.toLowerCase().includes(search) ||
                d.orgName.toLowerCase().includes(search) ||
                d.siteName.toLowerCase().includes(search) ||
                d.rolesString.toLowerCase().includes(search)
            );
        }

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcusername" || c.property === "lcemail" || c.property === "lcrole"
                }))}
                data={DATA}
                noLoadingMsg={this.props.userdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcusername', label: 'Username' },
                //    { col: 'lcemail', label: 'Email' },
                //    { col: 'lcactive', label: 'Active' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                noCounter

                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }

    render() {
        const { sitesdata, organisationsdata, userdata } = this.props;
        const { sitesdataLoading, organisationsdataLoading, userdataLoading, rolesdataLoading } = this.state;

        if (sitesdataLoading || organisationsdataLoading || userdataLoading || rolesdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }

        let user = this.context.accounts[0];

        //const username = user.profile.name;
        //const email = user.profile.unique_name;
        const email = user.username;

        //const sitesorg = sitesdata.filter(s => s.organizationId == sessionStorage.getItem("organisationId")).slice();
        //sitesorg.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });

        //let appuser = [];
        //let temp = _.cloneDeep(userdata); //need to use temp, because of the delete in next line
        //if (userdata != null) {
        //    temp.userRoles = [];
        //    appuser[0] = temp;
        //    appuser[0].userRoles = userdata.userRoles.filter(r => r.organizationId == sessionStorage.getItem("organisationId")).slice();
        //}

        //if (!appuser[0] || (!appuser[0].superUser && (appuser.length == 0 || appuser[0].userRoles.length == 0 || !appuser[0].active))) {
        //    return (
        //        <Redirect
        //            to={{
        //                pathname: "/nouser"
        //            }}
        //        />
        //    );
        //}
        //let roles = [];
        //var ur = [];
        //var list = [];
        //sitesorg.forEach(e => {
        //    if (appuser.length > 0) {
        //        ur = appuser[0].userRoles.filter(s => s.locationId == e.id).slice();
        //        list = this.RolesToArray(ur);
        //    }
        //    const role = { name: e.name, roles: list };
        //    if (list.length != 0) {
        //        roles.push({ role })
        //    }

        //});
        //const username = userdata.username;
        const isadmin = RB.canEdit(constants.PERMS.ORG_ADMINISTRATION);
        const isSuperUser = RB.isSuperUser();

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >My Account</Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Logout" onClick={() => this.context.instance.logout()} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <form>
                        <Box gap="large">
                            <Box gap="small">
                                <Box>
                                    <Text size="large">My Account Details</Text>
                                </Box>
                                <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                    <FormField label="Email">
                                        <TextInput value={email} readOnly disabled />
                                    </FormField>
                                    <FormField label="Username">
                                        <TextInput value={userdata.username} readOnly disabled />
                                    </FormField>
                                    {isSuperUser && (
                                        <Box width="70px">
                                            <FormField label="Super User">
                                                <Box pad="small" align="center" justify="center">
                                                    <CheckBox checked={isSuperUser} disabled />
                                                </Box>
                                            </FormField>
                                        </Box>
                                    )}
                                    <Box width="70px">
                                        <FormField label="Active">
                                            <Box pad="small" align="center" justify="center">
                                                <CheckBox checked={userdata.active} disabled />
                                            </Box>
                                        </FormField>
                                    </Box>
                                </StyledBox>
                            </Box>
                            <Box>
                                <Text size="large">Roles</Text>
                                {this.renderRolesTable(this.props)}
                            </Box>
                        </Box>
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>
            </Grid >
        );
    }
}

const mapStateToProps = state => ({
    sitesdata: state.sitesReducer.sitesdata,
    organisationsdata: state.organisationsReducer.organisationsdata,
    userdata: state.selectedUserReducer.userdata,
    rolesdata: state.rolesReducer.rolesdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SiteAction, ...OrganisationAction, ...UserAction, ...RoleAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(Account);