import * as ActionType from './ActionType';
import BuildingApi from '../api/BuildingApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';


export const getBuildingsResponse = buildingsdata => ({
    type: ActionType.GET_BUILDINGS_RESPONSE,
    buildingsdata
});

export function getBuildingsAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return BuildingApi.getAllBuildings()
            .then(buildingsdata => {
                dispatch(getBuildingsResponse(buildingsdata));
                return buildingsdata;
            }).catch(error => {
                throw error;
            });
    };
}