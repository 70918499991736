import constants from '../../constants';

export function imageCheck(file) {
        // will be came from constants...
        const MAX_IMAGE_SIZE = 2*1024*1024;
        const MIN_IMAGE_WIDTH = 300;
        const MIN_IMAGE_HEIGHT = 200;
    
        let img = new window.Image();
    
        return new Promise((resolve, reject) => {
            img.onload = function () {
                let width = img.naturalWidth;
                let height = img.naturalHeight;
                URL.revokeObjectURL(img.src);
                console.log(`Image dimensions: ${width}X${height}, file size: ${file.size}`);
                if (/*width < MIN_IMAGE_WIDTH || height < MIN_IMAGE_HEIGHT ||*/ file.size > MAX_IMAGE_SIZE) {
                    console.log(`Image rejected -- dimensions: ${width}X${height}, file size: ${file.size}`);
                    //window.alert(`Image rejected -- dimensions: ${width}X${height}, file size: ${file.size}`);
                    //reject(`Image ${file.name} out of parameters...`);
                    reject(`Please ensure your image is under 2 mb and add it again.`);
                } else {
                    resolve(file);
                }
            };
            img.src = URL.createObjectURL(file);
        });
}
