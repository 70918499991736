import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PlacemarkAction from '../action/PlacemarkAction';
import * as LevelAction from '../action/LevelAction';
import * as BuildingAction from '../action/BuildingAction';
import PlacemarkListTable from './PlacemarkListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text, Grid } from 'grommet';
import PopUp from './common/PopUp';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';


export class PlacemarkListContainer extends React.Component {

    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.handleInfo = this.handleInfo.bind(this);
        this.state = {
            selectedPlacemark: 0,
            open: undefined,
            info: undefined,
            placemarksdataLoading: true,
            levelsdataLoading: true,
            buildingsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }

    componentDidMount() {
        this.props.action.getPlacemarksAction()
            .then(response => this.setState({ placemarksdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Placemarks List Error', error);
            });
        this.props.action.getLevelsAction()
            .then(response => this.setState({ levelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getBuildingsAction()
            .then(response => this.setState({ buildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
    }

    handleDelete(event, nro) {
        event.preventDefault();
        //console.log('*Delete* started on ', nro);
        this.setState({ selectedPlacemark: nro });
        this.onOpen();
    }

    eventDelete(event) {
        const selectedId = this.state.selectedPlacemark;

        if (selectedId) {
            this.setState({ selectedPlacemark: 0, open: undefined });
            this.props.action.deletePlacemarkAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- Placemark List Delete Error', error);
                });
        }
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleInfo(event) {
        this.setState({ info: true });
    }

    closeInfo() {
        this.setState({ info: undefined });
    }

    handleNew(event) {
        event.preventDefault();
        this.props.history.push(constants.getSiteLevelRoute() + '/placemark/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        //console.log('*Edit* started on ', nro);
        this.props.history.push(constants.getSiteLevelRoute() + '/placemark/' + nro);
    }

    render() {
        const { placemarksdata, levelsdata, buildingsdata } = this.props;
        const { open, selectedPlacemark, info, placemarksdataLoading, levelsdataLoading, buildingsdataLoading } = this.state;

        //if (!placemarksdata[0] || !levelsdata || !buildingsdata) {
        //    return (
        //        <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
        //    );
        //}

        return (
            <Box flex justify='between' fill>
                <form>
                    {open && (
                        <Layer
                            position="center"
                            modal={true}
                            onClickOutside={this.onClose}
                            onEsc={this.onClose}
                        >
                            <Box pad="medium" gap="small" width="medium" >
                                <Heading level={3} margin="none">
                                    Confirm
                            </Heading>
                                <Text>Please confirm that you want to delete this category.</Text>
                                <Box
                                    tag="footer"
                                    gap="small"
                                    direction="row"
                                    align="center"
                                    justify="end"
                                    pad={{ top: "medium", bottom: "small" }}

                                >
                                    <StyledButton typeCancel label="Cancel" onClick={this.onClose} />
                                    <StyledButton typeSave label="Confirm" onClick={this.eventDelete} />
                                </Box>
                            </Box>
                        </Layer>
                    )}
                    <PopUp open={info} header="Issue information" text="This placemark will not appear in the app until correctly configured. Please edit to assign a category from the drop down list." onClose={() => this.closeInfo()} />
                    <PlacemarkListTable placemarksdata={placemarksdata} levelsdata={levelsdata} buildingsdata={buildingsdata} loading={placemarksdataLoading || levelsdataLoading || buildingsdataLoading}
                        handleNew={this.handleNew} handleDelete={this.handleDelete} handleEdit={this.handleEdit} handleInfo={this.handleInfo} />
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    placemarksdata: state.placemarksReducer.placemarksdata,
    levelsdata: state.levelsReducer.levelsdata,
    buildingsdata: state.buildingsReducer.buildingsdata,
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...PlacemarkAction, ...LevelAction, ...BuildingAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(PlacemarkListContainer);
