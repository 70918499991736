import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image, CheckBox } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, CircleInformation } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import { CSVLink } from "react-csv";
import PrimaryFilter from "./common/PrimaryFilter";

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class LabelListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.Exp_Data = [];
        this.state = {
            search: "",
        };
    }

    componentDidMount() {
        this.onDTUpdated();
    }

    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (b) => {
        //console.log("add/remove", b);
        //let id = b.path[3].getElementsByTagName('th')[0].textContent; //Firefox does not support it
        //let id = b.target.parentElement.parentElement.parentElement.getElementsByTagName('th')[0].textContent; //working
        let element = b.target;

        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
                
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {                      
                        let tid = elem.getElementsByTagName('th')[0].textContent;

                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    handleEdit(trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    filterData(data) {
        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            data = data.filter(d =>
                d.id.toLowerCase().includes(search) ||
                d.lcName.includes(search) ||
                d.created.includes(search)
            );
        }
        return data;
    }

    renderTrackerTable(props) {
        const COLUMNS = [
            {
                property: 'lcName',
                header: 'Name',
                render: labeldata => <Text>{labeldata.name}</Text>
            },
            {
                property: 'created',
                header: 'Created',
                render: labeldata => <Text>{labeldata.created}</Text>
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: labeldata => <Text hidden>{labeldata.id}</Text>,
            },
            {
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: labeldata => (!RB.canEdit(constants.PERMS.LOC_CONTENT)) ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, labeldata.id)} icon={<Blank />} /></Box>
                    :
                    <Box width="24px" direction='row' gap="xsmall">
                        {/*<Button plain disabled={props.deletedLabelIds.includes(labeldata.id)} onClick={evt => props.handleEdit(labeldata.id)} icon={<Edit />} />*/}
                        <Button plain disabled={props.deletedLabelIds.includes(labeldata.id)} onClick={evt => props.handleDelete("label", labeldata.id)} icon={<Trash />} />
                    </Box>,
            },
        ];

        let DATA = [];
        this.Exp_Data = [];
        if (props.assetlabelsdata[0] && props.assetlabelsdata[0].count != 0) {
            props.assetlabelsdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            props.assetlabelsdata.forEach(ev => {
                DATA.push({
                    ...ev,
                    lcName: ev.name ? ev.name.toLowerCase() : '',
                    //created: ev.createdAt ? constants.formatDate(ev.createdAt) : ""
                    created: ev.modified ? constants.formatDate(ev.modified) : ""
                });
                this.Exp_Data.push({
                    "ID": ev.id,
                    "Label Name": ev.name,
                    //"Created At (UTC)": ev.createdAt
                    "Created At (UTC)": ev.modified

                });
            });
        }
        DATA = this.filterData(DATA);
        console.log('DATA:', DATA);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "lccategoryname" || c.property === "lccategoryparent" || c.property === "lccategorylocation" || c.property === "lcparentloc"
                }))}
                data={DATA}
                noLoadingMsg={this.props.assetlabelsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcName', label: 'Label Name' },
                //    { col: 'created', label: 'Created' }
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                //downloadable={true}
                //exportdata={this.Exp_Data}
                onUpdated={this.onDTUpdated.bind(this)}
                styled={true}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}
                //PFonClick={() => this.setState({ isFilterOpen: true })}
                //PFopen={this.state.isFilterOpen}
                //PFonClose={() => this.setState({ isFilterOpen: false })}
                //PFonResetFilter={() => this.setState({
                //    selectedBuildingId: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "", level: "", levelId: "",
                //    collection: "", collectionId: "", departmentId: "", department: "", status: "", statusId: ""
                //})}
                //PFisClearVisible={(
                //    this.state.selectedBuilding == "" &&
                //    this.state.level == "" &&
                //    this.state.collection == "" &&
                //    this.state.department == "" &&
                //    this.state.status == "") ? false : true}
                PFcsvdata={this.Exp_Data}
                /*PFFileters={this.renderFilters()}*/
                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={DATA.length > 0 ? 1 : 2}
            />
        );
    }

    render() {
        return (
            <div>
                {/*<PrimaryFilter*/}
                {/*    placeholder="Search by Label name"*/}
                {/*    search={this.state.search}*/}
                {/*    onChange={e => this.setState({ search: e.target.value })}*/}
                {/*    csvdata={this.Exp_Data}*/}
                {/*    downloadable*/}
                {/*>*/}
                {/*</PrimaryFilter>*/}
                <StyledBox ref={this.dtwrapper}>
                    {this.renderTrackerTable(this.props)}
                </StyledBox>
            </div>
        );
    }
}

export default LabelListTable;