import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SiteAction from '../action/SiteAction';
import * as PageAction from '../action/PageAction';
import * as PlacemarkAction from '../action/PlacemarkAction';
import * as CategoryAction from '../action/CategoryAction';
import * as OrganisationAction from '../action/OrganisationAction';
// eslint-disable-next-line
import { Trash, FormDown, FormUp } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, CheckBox, Table, TableHeader, TableBody, TableRow, TableCell, RadioButton, Image } from 'grommet';
import TextInputWithState from './common/TextInputWithState';
import constants from '../constants';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { Context } from '../components/Context';
import LoadingIndicator from './common/LoadingIndicator';

var start = undefined;

export class IntegrationEditContainer extends React.Component {
    static contextType = Context;
    constructor() {
        super();
        this.load = true;
        this.stamp = Date.now().toString();
        this.state = {
            isSaved: false,
            firstValidation: false,
            update: false,
            cancel: false,
            disabledButton: false,
            isSaveSuccessful: false,
            warningMeridian: false,
            warningRoomBooking: false,
            warningDeskBooking: false,
            warningReportIssue: false,
            warningChargifi: false,
            warningMicrosoft365: false,
            sitesdataLoading: true,
            organisationdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    ClearErrorMessages() {
    }

    handleEdit() {
    }

    handleNew() {
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        this.props.action.getSiteAction(this.props.match.params.locid)
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Site Edit Error', error);
            });
        const id = sessionStorage.getItem("organisationId");
        this.props.action.getOrganisationAction(id)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });

        if (this.props.location.state) {
            this.clickGoTo(this.props.location.state.detail);
        }
    }

    componentWillReceiveProps() {
        if (this.props.sitesdata) {
            this.validateSettings("All");
            this.setState({ firstValidation: true })
        }
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.sitesdata && (!_.isEqual(start, this.props.sitesdata))) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    componentDidUpdate(prevProps, prevState) {
    }

    validateSettings(type) {
        const sitedefault = this.props.sitesdata;
        let MeridianValidation = this.state.warningMeridian;
        let RoomBookingValidation = this.state.warningRoomBooking;
        let DeskBookingValidation = this.state.warningDeskBooking;
        let Microsoft365Validation = this.state.warningMicrosoft365;
        let ReportIssueValidation = this.state.warningReportIssue;
        let ChargifiValidation = this.state.warningChargifi;

        //Meridian
        if (sitedefault.appToken.trim().length == 0 || sitedefault.appID.trim().length == 0 || sitedefault.meridianOrgId.trim().length == 0) {
            MeridianValidation = true
        }
        else {
            MeridianValidation = false
        }

        //Room Booking
        if (sitedefault.campusId == null || sitedefault.campusId == "") {
            RoomBookingValidation = true
        }
        else {
            RoomBookingValidation = false
        }

        //Desk Booking
        if (false) {
            DeskBookingValidation = true
        }
        else {
            DeskBookingValidation = false
        }

        //Microsoft365
        if (false) {
            Microsoft365Validation = true
        }
        else {
            Microsoft365Validation = false
        }

        //Report Issue
        if (false) {
            ReportIssueValidation = true
        }
        else {
            ReportIssueValidation = false
        }

        //Chargifi
        if (false) {
            ChargifiValidation = true
        }
        else {
            ChargifiValidation = false
        }

        if (type == "Meridian") {
            this.setState({ warningMeridian: MeridianValidation });
        }
        else if (type == "RoomBooking") {
            this.setState({ warningRoomBooking: RoomBookingValidation });
        }
        else if (type == "DeskBooking") {
            this.setState({ warningDeskBooking: DeskBookingValidation });
        }
        else if (type == "Microsoft365") {
            this.setState({ warningMicrosoft365: Microsoft365Validation });
        }
        else if (type == "ReportIssue") {
            this.setState({ warningReportIssue: ReportIssueValidation });
        }
        else if (type == "Chargifi") {
            this.setState({ warningChargifi: ChargifiValidation });
        }
        else if (type == "All") {
            this.setState({
                warningMeridian: MeridianValidation, warningRoomBooking: RoomBookingValidation, warningDeskBooking: DeskBookingValidation,
                warningMicrosoft365: Microsoft365Validation, warningReportIssue: ReportIssueValidation, warningChargifi: ChargifiValidation
            });
        }
    }
    handleSave(context) {
        const sitedefault = this.props.sitesdata;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ disabledButton: true });

        if (errorsno == 0) {
            console.log('Save: OK', sitedefault);

            this.props.action.saveSiteAction(sitedefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                    if (response.ok) {
                        context.setPlacemarkEnable(sitedefault.meridianEnabled);
                    }
                    //this.props.history.push('/home');
                }).catch(error => {
                    console.log('*DEBUG*-Save Site Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.setState({ disabledButton: false });
        }

    }
    handleCancel() {
        if (!this.state.cancel && (!_.isEqual(start, this.props.sitesdata))) {
            this.setState({ cancel: true });
        }
        else {
            const siteId = this.props.sitesdata.id;
            this.props.action.getSiteAction(siteId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getSiteLevelRoute() + '/integrations');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-ConfigurationEdit Error', error);
                    this.props.history.push(constants.getSiteLevelRoute() + '/integrations');
                });
        }
    }
    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave(context) {
        this.setState({ cancel: false });
        this.handleSave(context);
    }
    onActive(index) {
        this.setState({ index: index });
    }

    clickSettingsMeridian() {
        this.props.history.push(constants.getSiteLevelRoute() + '/integrations/meridian');
    }
    clickSettingsRoomBooking() {
        this.props.history.push(constants.getSiteLevelRoute() + '/integrations/roombooking');
    }
    clickSettingsDeskBooking() {
        this.props.history.push(constants.getSiteLevelRoute() + '/integrations/deskbooking');
    }
    clickSettingsMicrosoft365() {
        this.props.history.push(constants.getSiteLevelRoute() + '/integrations/microsoft365');
    }
    clickSettingsReportIssue() {
        this.props.history.push(constants.getSiteLevelRoute() + '/integrations/reportissue');
    }
    clickSettingsChargifi() {
        this.props.history.push(constants.getSiteLevelRoute() + '/integrations/chargifi');
    }

    clickGoTo(location) {
        if (document.getElementById(location)) {
            document.getElementById(location).scrollIntoView();
            //document.getElementById(location).scrollIntoView({ behavior: "smooth" });
        }
    }

    renderCard(img_location, img_width, card_name, description, settings_onClick, active, warning) {
        return (
            <StyledBox style1 pad={{ "horizontal": "small", "top": "small", "bottom": "xsmall" }} direction="column" style={{ "min-width": "325px" }} height="270px" width="560px">
                {active &&
                    <Box direction="row" gap="xsmall" align="center" height="10%">
                        <Image width="30" height="30" src="./images/green.svg" title="Active" />
                        <Text size="large">ACTIVE</Text>
                    </Box>}
                {!active &&
                    <Box direction="row" gap="xsmall" align="center" height="10%">
                        <Image width="30" height="30" src="./images/yellow_grey.svg" style={{ "fill": "blue" }} title="Inactive" />
                        <Text size="large" color="#DADADA">INACTIVE</Text>
                    </Box>}
                <Box height="80%" direction="row">
                    <Box height="100%" width="40%" justify="center" align="center" pad={{ "left": "small", "right": "medium" }}>
                        <Image opacity={active ? "100%" : "100%"} src={img_location} width={img_width} />
                    </Box>
                    <Box height="100%" width="60%" direction="column" gap="small" >
                        <Box direction="column" height="60%">
                            <Text size="30px" color={active ? "" : ""} style={{ "font-weight": "600" }}>{card_name}</Text>
                            <Text size="medium" color={active ? "" : ""}>{description}</Text>
                        </Box>
                        <Box width="110px">
                            <StyledButton label="Settings" onClick={settings_onClick} typeAdd />
                        </Box>
                    </Box>
                </Box>
                {warning && active &&
                    <Box direction="row" height="10%" align="center">
                        <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />
                        <Text size="medium" color="status-critical">Not all settings are configured properly</Text>
                    </Box>}
            </StyledBox>
        )
    }

    render() {
        const organisationdefault = this.props.organisationdata;
        const sitedefault = this.props.sitesdata;
        const { sitesdataLoading, organisationdataLoading } = this.state;

        if (sitesdataLoading || organisationdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }

        const isThereActive = (
            (this.context.feature.find(f => f.name == "siteMap").isEnabled && organisationdefault.meridianEnabled) ||
            (this.context.feature.find(f => f.name == "siteWayFinding").isEnabled && organisationdefault.meridianEnabled) ||
            (this.context.feature.find(f => f.name == "roomBooking").isEnabled && (organisationdefault.teemEnabled || organisationdefault.graphEnabled)) ||
            (this.context.feature.find(f => f.name == "deskBooking").isEnabled && (organisationdefault.teemEnabled || organisationdefault.graphEnabled)) ||
            this.context.feature.find(f => f.name == "siteMobilClient").isEnabled ||
            (this.context.feature.find(f => f.name == "siteChargifi").isEnabled && constants.release().Feature_chargifi && organisationdefault.chargifiEnabled)
        );

        if (!this.state.firstValidation) {
            this.setState({ firstValidation: true })
            this.validateSettings("All")
        }
        //permissions
        const canread1 = RB.canRead(constants.PERMS.LOC_CONF_SITE);
        const canread2 = RB.canRead(constants.PERMS.LOC_CONF_INTEGR); // || true //only perms test
        const canread3 = RB.canRead(constants.PERMS.LOC_CONF_MAPS);
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONF_SITE);
        const canedit2 = RB.canEdit(constants.PERMS.LOC_CONF_INTEGR);
        const canedit3 = RB.canEdit(constants.PERMS.LOC_CONF_MAPS);
        const canedit4 = RB.canEdit(constants.PERMS.ORG_ADMINISTRATION);

        return (
            <Context.Consumer>
                {values => {
                    return (
                        <Grid fill={true}
                            rows={['xsmall', 'auto']}
                            columns={['100%', 'flex']}

                            areas={[
                                { name: 'header', start: [0, 0], end: [1, 0] },

                                { name: 'main', start: [0, 1], end: [0, 1] },
                                { name: 'side', start: [1, 0], end: [1, 1] },
                            ]}
                        >
                            <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                            <Context.Consumer>
                                {data =>
                                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(data)} onDiscard={() => this.onDiscard()} />
                                }
                            </Context.Consumer>
                            {/* Configuration Header Area */}
                            <Box gridArea='header' direction="row"
                                align="center"
                                pad="medium"
                                justify="between"
                                background={constants.BACKGROUND_COLOR}>
                                <Heading level='2'>Integrations</Heading>
                                {/*<Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" style={{ visibility: constants.visible(canedit1 || canedit2 || canedit3) }} onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <Context.Consumer>
                            {data =>
                                <StyledButton typeSave label="Save All" style={{ visibility: constants.visible(canedit1 || canedit2 || canedit3) }} onClick={() => { this.handleSave(data) }} disabled={this.state.disabledButton} />
                            }
                        </Context.Consumer>
                    </Box>*/}
                            </Box>
                            {/* Configuration Main Area */}
                            <Box background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium", "bottom": "medium" }}>
                                {canedit2 && (
                                    <form>
                                        <Box pad={{ "bottom": "medium" }}>
                                            <Box pad={{ "bottom": "small" }}>
                                                {/*<Text>All fields marked with an * are mandatory.</Text>*/}
                                                {isThereActive ?
                                                    <Text>Select to activate and setup third party applications and features for the location or click below to continue without activation.</Text> :
                                                    <Text>There are no integrations available for this site, please ensure the required features has been activated to configure your integrations.</Text>}
                                            </Box>

                                            <Box gap="medium">
                                                {(values.feature.find(f => f.name == "siteMap").isEnabled || values.feature.find(f => f.name == "siteWayFinding").isEnabled) && organisationdefault.meridianEnabled &&
                                                    <Box gap="xsmall" id="Location_Based_Services">
                                                        <Text size="30px">Location Based Services</Text>
                                                        <Box gap="medium" direction="row">
                                                            {
                                                                this.renderCard("./Images/org_integration/meridian.png", "90%", "Aruba Meridian",
                                                                    "",
                                                                    this.clickSettingsMeridian.bind(this), sitedefault.meridianEnabled, this.state.warningMeridian)
                                                            }
                                                        </Box>
                                                    </Box>}

                                                {(values.feature.find(f => f.name == "roomBooking").isEnabled || values.feature.find(f => f.name == "deskBooking").isEnabled) && (organisationdefault.teemEnabled || organisationdefault.graphEnabled) &&
                                                    <Box gap="xsmall" id="Space_Management">
                                                        <Text size="30px">Space Management</Text>
                                                        <Box gap="medium" direction="row">
                                                            {values.feature.find(f => f.name == "roomBooking").isEnabled && organisationdefault.teemEnabled &&
                                                                this.renderCard("./Images/org_integration/teem.png", "80%", "Room Booking",
                                                                    "",
                                                                    this.clickSettingsRoomBooking.bind(this), sitedefault.bookARoomEnabled, this.state.warningRoomBooking)
                                                            }
                                                            {values.feature.find(f => f.name == "deskBooking").isEnabled && organisationdefault.teemEnabled &&
                                                                this.renderCard("./Images/org_integration/teem.png", "80%", "Desk Booking",
                                                                    "",
                                                                    this.clickSettingsDeskBooking.bind(this), sitedefault.bookADeskEnabled, this.state.warningDeskBooking)
                                                            }
                                                            {values.feature.find(f => f.name == "deskBooking").isEnabled && organisationdefault.graphEnabled &&
                                                                this.renderCard("./Images/org_integration/microsoft365.png", "100%", "Microsoft 365",
                                                                    "",
                                                                    this.clickSettingsMicrosoft365.bind(this), sitedefault.graphEnabled, this.state.warningMicrosoft365)
                                                            }
                                                        </Box>
                                                    </Box>}

                                                {values.feature.find(f => f.name == "siteMobilClient").isEnabled &&
                                                    <Box gap="xsmall" id="Report_Issue">
                                                        <Text size="30px">Report Issue</Text>
                                                        <Box gap="medium" direction="row">
                                                            {
                                                                this.renderCard("./Images/org_integration/reportissue.png", "50%", "Report Issue",
                                                                    "",
                                                                    this.clickSettingsReportIssue.bind(this), sitedefault.reportIssueEnabled, this.state.warningReportIssue)
                                                            }
                                                        </Box>
                                                    </Box>}

                                                {values.feature.find(f => f.name == "siteChargifi").isEnabled && constants.release().Feature_chargifi && organisationdefault.chargifiEnabled &&
                                                    <Box gap="xsmall" id="Wireless_Charging">
                                                        <Text size="30px">Wireless Charging</Text>
                                                        <Box gap="medium" direction="row">
                                                            {
                                                                this.renderCard("./Images/org_integration/chargifi.png", "90%", "Chargifi",
                                                                    "",
                                                                    this.clickSettingsChargifi.bind(this), sitedefault.chargifiEnabled, this.state.warningChargifi)
                                                            }
                                                        </Box>
                                                    </Box>}
                                            </Box>
                                        </Box>
                                    </form>
                                )}
                            </Box>
                            <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                            </Box>
                        </Grid >
                    );
                }}
            </Context.Consumer>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const siteId = ownProps.match.params.locid || '';
    if (state.selectedSiteReducer.sitedata && siteId == state.selectedSiteReducer.sitedata.id) {
        start = JSON.parse(JSON.stringify(state.selectedSiteReducer.sitedata));
        return {
            sitesdata: state.selectedSiteReducer.sitedata,
            organisationdata: state.selectedOrganisationReducer.organisationdata
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SiteAction, ...OrganisationAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IntegrationEditContainer));