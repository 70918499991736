import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedManufacturerReducer = (state = initialState.selectedManufacturerReducer, action) => {
    switch(action.type) {

        case ActionType.GET_MANUFACTURER_RESPONSE: {
            // '...' spread operator clones the state
            if (action.userdata) {
                return {
                    ...state,
                    manufacturerdata: _.assign(action.manufacturerdata)
                };
            }
            else {
                return {
                    ...state,
                    manufacturerdata: initialState.selectedManufacturerReducer.manufacturerdata
                };
            }

        }

        default: { return state; }
    }
};


export default selectedManufacturerReducer;