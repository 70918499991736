import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken, getAllRecords } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

const SERVER_URL = `${SERVER}/tables/statusimagehistory`;
const API_NAME = "CalendarHistory";
console.log('HOST:', SERVER_URL);

class CalendarHistoryApi {
    static async getAllCalendarHistories() {
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}/?${API_VERSION}&$count=true&$top=0`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                return getAllRecords(`${SERVER_URL}/?${API_VERSION}`, options, data.count)
                    .catch(error => {
                        throw error;
                    });
            })
            .catch(error => {
                throw error;
            });
    }
}

//function handleFetchErrors(response) {
//    if (!response.ok) {
//        throw new Error(`${API_NAME}: ${response.statusText}`);
//    }
//    return response;
//}

export default CalendarHistoryApi;
