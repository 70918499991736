import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import * as UserAction from '../action/UserAction';
import * as RoleAction from '../action/RoleAction';
import * as SiteAction from '../action/SiteAction';
import validator from 'validator';
import constants from '../constants';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Trash } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, CheckBox, Tabs, Tab, Layer, DataTable } from 'grommet';
import { error } from 'util';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import withPagination from './common/PaginatedDataTable';

const PaginatedDataTable = withPagination(DataTable);

var start = undefined;
//var orgroles = undefined;
//var locroles = undefined;

class Option extends React.Component {
    render() {
        const { value, selected } = this.props;
        return (
            <Box direction="row" gap="small" align="center" pad="xsmall">
                <CheckBox tabIndex="-1" checked={selected} onChange={() => { }} />
                {value}
            </Box>
        );
    }
}

export class UserEditContainer extends React.Component {
    constructor() {
        super();
        this.dtwrapper = React.createRef();
        this.allRoles = [];
        this.allRolesSites = [];
        //this.rolefull = [];
        //this.sitesfull = [];
        //this.entryerrors = [];
        //this.didupdate = false;
        //this.orgId = sessionStorage.getItem("organisationId");
        //this.orgId = constants.organisation.id;
        //if (sessionStorage.getItem("organisationId")) {
        //    this.orgId = sessionStorage.getItem("organisationId");
        //}        
        this.state = {
            /*checked: false,*/
            //selected: [],
            //options: [],
            //hideSites: [],
            isSaved: false,
            //loaded: false,
            //locationroles: [
            //    //{ location: "HPE Galway", roles: [] },
            //    //{ location: "HPE London", roles: [] },
            //],
            //organisationroles: { roles: [] },
            //addAllRoles: "",
            //removeAllRoles: "",
            disabledButton: false,
            open: false,
            openManageRoles: false,
            openManageType: "",
            manageRoleType: "",
            manageRoleSites: [],
            manageRoleRoles: [],
            cancel: false,
            isSaveSuccessful: false,
            userdataLoading: true,
            rolesdataLoading: true,
            sitesdataLoading: true,
            organisationdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Validating errors
    /*group_err = '';*/
    email_err = '';
    username_err = '';
    showActiveMsg = false;

    ClearErrorMessages() {
        /*this.group_err = '';*/
        this.email_err = '';
        this.username_err = '';
        //this.entryerrors.map((item) => {
        //    item.locationId = "";
        //    item.role = "";
        //})
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.userId;
        this.props.action.getUserAction(this.props.match.params.userId)
            .then(response => this.setState({ userdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-UserEdit Error", error);
            });
        this.props.action.getRolesAction()
            .then(response => this.setState({ rolesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-Role Error", error);
            });
        this.props.action.getSitesAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getOrganisationAction(sessionStorage.getItem("organisationId"))
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray })
                console.log('*DEBUG*-Organisation Error', error);
            });
        this.onDTUpdated();
    }
    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.handleEdit.bind(this);
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node && node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            //let handleEdit = this.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        let tid = elem.getElementsByTagName('th')[0].textContent;

                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.userdata && !_.isEqual(start, this.props.userdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.onDTUpdated();
        //if (this.props.rolesdata && this.props.rolesdata.length > 0 && this.rolefull.length === 0) {
        //    //this.rolefull = this.props.rolesdata.slice();
        //    this.rolefull = this.props.rolesdata.filter((item) => item.level == "Location");
        //    this.rolefull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        //    this.setState({ options: this.rolefull });
        //}

        //if (this.props.sitesdata.length > 0 && !this.state.loaded) {
        //    //this.sitesfull = this.props.sitesdata.filter(s => s.organizationId == constants.organisation.id).slice();
        //    this.sitesfull = this.props.sitesdata.filter(s => s.organizationId == this.orgId).slice();
        //    //this.sitesfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        //    //if (this.state.locationId) {
        //    //    const locat = this.sitesfull.find((o) => o.id == this.state.locationId);
        //    //    if (locat) {
        //    //        this.setState({ location: locat.name });
        //    //    };
        //    //};
        //    this.sitesfull.map((info, idx) => {
        //        this.entryerrors.push({ locationId: "", role: "" });

        //    })
        //    this.setState({ loaded: true });
        //}

        //if (this.props.userdata && this.state.selected.length === 0 && !this.didupdate) {
        //    this.setState({ selected: this.props.userdata.userRoles.map(r => r.role.name) });
        //    var sf = [...this.sitesfull];
        //    var lr = [];
        //    var orgrole = { roles: [] };
        //    this.props.userdata.userRoles.forEach(function (item, id) {
        //        if (item.role.level == "Organization") {
        //            orgrole.organisationid = item.organizationId;
        //            orgrole.roles.push(item.role.name);
        //        }
        //        else {
        //            if (lr.length == 0) {
        //                let loc = sf.find(l => l.id == item.locationId);
        //                if (loc) {
        //                    let name = loc.name;
        //                    lr.push({ name: name, locationId: item.locationId, roles: [item.role.name] });
        //                }
        //            }
        //            else {
        //                var index = -1;
        //                lr.forEach(function (element, i) {
        //                    if (element.locationId == item.locationId) {
        //                        index = i;
        //                    }
        //                    else {

        //                    }
        //                })
        //                if (index > -1) {
        //                    lr[index].roles.push(item.role.name);
        //                }
        //                else {
        //                    let loc = sf.find(l => l.id == item.locationId);
        //                    if (loc) {
        //                        let name = loc.name;
        //                        lr.push({ name: name, locationId: item.locationId, roles: [item.role.name] });
        //                    }
        //                }

        //            }
        //        }
        //    })

        //    lr.forEach((ev, id) => { ev.roles.sort((p1, p2) => { if (p1.toLowerCase() < p2.toLowerCase()) return -1; return 1; }); });

        //    orgroles = JSON.parse(JSON.stringify(orgrole));
        //    locroles = JSON.parse(JSON.stringify(lr));
        //    this.setState({ locationroles: lr, organisationroles: orgrole });

        //    this.didupdate = true;
        //}
    }

    //renderSelected = (options) => {
    //    return (
    //        <Box pad={{ horizontal: "small" }} fill >
    //            {options.map((item, idx) =>
    //                <Box>
    //                    <TextInput key={idx} plain readOnly value={item} />
    //                </Box>
    //            )}
    //        </Box>
    //    );
    //}

    //renderSelected1 = (id) => {
    //    const { selected, locationroles } = this.state;
    //    return (
    //        <Box pad={{ horizontal: "small" }} /*direction="row" gap="xsmall" border="all" fill="vertical"*/>
    //            {/*locationroles[id].roles.map((item, idx) => <Box><Text>{item}</Text> </Box>)*/}
    //            {locationroles[id].roles.map((item, idx) => <Box><TextInput key={idx} plain readOnly value={item} /> </Box>)}
    //            {/*locationroles[id].roles.map((item, idx) => <Box ><Text>{item}</Text> </Box>)*/}
    //        </Box>
    //    );
    //}

    //renderSelected2 = (id) => {
    //    const { locationroles } = this.state;
    //    return (
    //        <Box pad={{ horizontal: "small" }}>
    //            {locationroles[id].roles.map((item, idx) => <Box><TextInput key={idx} plain readOnly value={item} /> </Box>)}
    //        </Box>
    //    );
    //}

    handleSave() {
        const userdefault = this.props.userdata;
        if (userdefault.id == "0") {
            userdefault.active = true;
        }

        const { selected, locationroles } = this.state;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ validating: false });
        if (userdefault.username.trim().length == 0) {
            errorsno++;
            this.username_err = "Username is required";
        }
        if (userdefault.email.trim().length == 0) {
            errorsno++;
            this.email_err = "E-mail is required"
        }
        if (userdefault.email.trim().length != 0 && !(validator.isEmail(userdefault.email))) {
            errorsno++;
            this.email_err = "E-mail is not correct";
        }
        //if (this.state.organisationroles.roles.length == 0) {
        //    if (locationroles.length != 0) {
        //        locationroles.map((item, i) => {
        //            if (item.locationId == "") {
        //                errorsno++;
        //                this.entryerrors[i].locationId = "Select Site";
        //                console.log("Select Site");
        //            }
        //            if (item.roles.length == 0) {
        //                errorsno++;
        //                this.entryerrors[i].role = "Select a Role";
        //                console.log("Select a Role");
        //            }
        //        })
        //    }
        //}
        if (errorsno == 0) {
            this.setState({ disabledButton: true })
            //this.saveRoles();
            console.log('Save: OK', userdefault);
            this.props.action.saveUserAction(userdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                    //this.props.history.push('/users');
                }).catch(error => {
                    console.log('*DEBUG*-Save UserEdit Error', error);
                    this.setState({ disabledButton: false })
                });
        }
        else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        //this.saveRoles();
        if (!this.state.cancel && !_.isEqual(start, this.props.userdata)) {
            this.setState({ cancel: true });
        }
        else {
            const userId = this.props.userdata.id;
            this.props.action.getUserAction(userId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getOrgLevelRoute() + '/users');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-UserEdit Error', error);
                    this.props.history.push(constants.getOrgLevelRoute() + '/users');
                });
            this.props.history.push(constants.getOrgLevelRoute() + '/users');
        }
    }
    //saveRoles() {
    //    const userdefault = this.props.userdata;
    //    const { selected, locationroles } = this.state;
    //    //updating roles
    //    //userdefault.userRoles = [];
    //    this.props.rolesdata.map((item) => {
    //        locationroles.map((element) => {
    //            if (element.roles.indexOf(item.name) >= 0) {
    //                const ur = { role: item, locationId: element.locationId, organizationId: sessionStorage.getItem("organisationId"), userid: userdefault.email, roleid: item.id };
    //                userdefault.userRoles.push(ur);
    //            }
    //        })
    //        if (this.state.organisationroles.roles.indexOf(item.name) >= 0) {
    //            const uor = { role: item, organizationId: this.state.organisationroles.organisationid, locationId: null, userid: userdefault.email, roleid: item.id };
    //            userdefault.userRoles.push(uor);
    //        }
    //    })
    //}

    //updateSuperUser(evt) {
    //    this.setState({ checked: !this.state.checked });
    //    this.props.userdata.superUser = evt.target.checked;
    //}
    //updateActiveValue(evt) {
    //    this.setState({ checked: !this.state.checked });
    //    this.props.userdata.active = evt.target.checked;
    //}
    updateUsernameValue(evt) {
        this.props.userdata.username = evt.target.value;
        this.setState({ updated: true });
    }
    updateEmailValue(evt) {
        this.props.userdata.email = evt.target.value;
        this.props.userdata.userRoles.map(r => r.userId = evt.target.value);
        this.setState({ updated: true });
    }
    //renderOptions(condition) {

    //    const loc = [];
    //    const idlist = [];
    //    let hideSites = [];
    //    this.state.locationroles.forEach(function (element, id) {
    //        idlist.push(element.locationId);
    //    })
    //    this.sitesfull.forEach(function (item, idx) {
    //        const isIncluded = idlist.includes(item.id);
    //        //if (!isIncluded) {
    //        //    loc.push(item.name);
    //        //}
    //        loc.push(item.name);
    //        if (isIncluded) {
    //            hideSites.push(idx)
    //        }
    //    })
    //    if (condition == "options") {
    //        return loc;
    //    }
    //    if (condition == "hide") {
    //        return hideSites;
    //    }
    //}
    //UpdateL(evt, id) {
    //    const entries = [...this.state.locationroles];
    //    var locid = this.sitesfull.find(l => l.name == evt.option).id;
    //    entries[id].locationId = locid;
    //    entries[id].name = evt.option;
    //    this.setState({ locationroles: entries });
    //    //console.log("Site_Role: 1", evt, id, locid, this.state.locationroles);
    //}
    //DeleteL(id) {
    //    const entries = [...this.state.locationroles];
    //    entries.splice(id, 1);
    //    this.setState({ locationroles: entries });
    //}
    Add() {
        this.setState({ openManageRoles: true, openManageType: "Add" });
        //if (this.state.locationroles.length < this.sitesfull.length) {
        //    const entries = [...this.state.locationroles];
        //    entries.push({ name: "", locationId: "", roles: [] });
        //    this.setState({ locationroles: entries });
        //}
    }
    //AddAll() {
    //    const { locationroles, addAllRoles } = this.state;
    //    const entries = [...this.state.locationroles];
    //    if (addAllRoles.length != 0) {
    //        if (locationroles.length < this.sitesfull.length) {
    //            this.sitesfull.forEach((ev, id) => {
    //                if (!locationroles.find(lr => lr.locationId == ev.id)) {
    //                    entries.push({ name: ev.name, locationId: ev.id, roles: [] });
    //                }
    //            });
    //        }
    //        addAllRoles.forEach((alr) => {
    //            entries.forEach((ent) => {
    //                if (!ent.roles.includes(alr)) {
    //                    ent.roles.push(alr)
    //                }
    //            });
    //        });
    //        this.setState({ locationroles: entries, addAllRoles: [] });
    //    }
    //    else {
    //        this.addAll_err = "Please select a role first";
    //        this.setState({ updated: true });
    //    }
    //}
    //RemoveAll() {
    //    const { removeAllRoles } = this.state;
    //    const entries = [...this.state.locationroles];

    //    if (removeAllRoles.length != 0) {
    //        removeAllRoles.forEach((ral) => {
    //            entries.forEach((ent) => {
    //                if (ent.roles.includes(ral)) {
    //                    ent.roles.splice(ent.roles.indexOf(ral), 1)
    //                }
    //            });
    //        });
    //        this.setState({ locationroles: entries.filter(ent => ent.roles.length != 0), removeAllRoles: [] });
    //    }
    //    else {
    //        this.removeAll_err = "Please select a role first";
    //        this.setState({ updated: true });
    //    }
    //}
    DeleteRole(siteId) {
        const { userdata } = this.props;

        userdata.userRoles = userdata.userRoles.filter(r => r.locationId != siteId);
        this.setState({ updated: true });
    }
    isOrgAdmin() {
        const { userdata } = this.props;
        if (userdata && userdata.userRoles) {
            if (userdata.userRoles.find(r => r.role.name == "Organisation Admin")) return true;
            else return false;

        }
    }
    updateOrgAdmin() {
        const { userdata, rolesdata, organisationdata } = this.props;
        const isOrgAdmin = this.isOrgAdmin();

        if (isOrgAdmin) {
            userdata.userRoles = userdata.userRoles.filter(r => r.roleId != "f028c85c16a148e8820da3537d74e9d9");
            this.setState({ updated: true });
        }
        else {
            const active = userdata.id == "0" ? true : this.isActive();
            const role = rolesdata.find(r => r.id == "f028c85c16a148e8820da3537d74e9d9");
            const orgAdminRole = { active: active, locationId: null, organizationId: organisationdata.id, role: role, roleId: role.id, userId: userdata.email };
            userdata.userRoles.push(orgAdminRole);
            this.setState({ updated: true });
        }
    }
    isActive() {
        const { userdata } = this.props;
        let activeRoles = userdata.userRoles.filter(r => r.active == true);

        if (activeRoles.length != 0) {
            this.showActiveMsg = false;
            return true;
        }
        else if (activeRoles.length == 0) {
            return false;
        }
    }
    SetActive() {
        const { userdata } = this.props;
        const active = this.isActive();
        userdata.userRoles.map(r => r.active = !active);

        if (!active) {
            this.showActiveMsg = true;
        }

        this.setState({ updated: true });
    }
    showActiveMsgFn() {
        const { userdata } = this.props;
        if (userdata.userRoles.map(r => r).length != 0 && this.showActiveMsg) {
            return false;
        }
        else if (userdata.userRoles.map(r => r).length == 0 && this.showActiveMsg) {
            return true;
        }
    }
    //LocValue(evt, info) {
    //    var loc = this.sitesfull.find(l => l.id == info.locationId);
    //    return loc ? loc.name : "";
    //}
    //AddOrg() {
    //    //var obj = { organisationid: constants.organisation.id, roles: [] };
    //    var obj = { organisationid: this.orgId, roles: [] };
    //    this.setState({ organisationroles: obj });
    //}
    //DeleteOrg() {
    //    var obj = { roles: [] };
    //    this.setState({ organisationroles: obj });
    //}
    //UpdateOrgAdmin(evt) {
    //    var obj = {};
    //    if (evt.target.checked) {
    //        obj = { organisationid: this.orgId, roles: ["Organisation Admin"] };
    //        this.setState({ organisationroles: obj });
    //    }
    //    else {
    //        this.setState({ open: true });
    //    }
    //}
    onClose() {
        this.setState({ disabledButton: false, openManageRoles: false, openManageType: "", manageRoleType: "", manageRoleSites: [], manageRoleRoles: [] });
    }
    //onConfirm() {
    //    this.setState({ open: false });
    //    /*this.DeleteOrg();*/
    //}
    onCloseSaving() {
        this.setState({ isSaved: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push(constants.getOrgLevelRoute() + '/users');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    //handleDelete(event, nro) {
    //    event.preventDefault();
    //    console.log('*Delete* started on ', nro);
    //    this.setState({ selectedRole: nro });
    //    this.onOpen();
    //}
    handleEdit(event, siteId) {
        event.preventDefault();

        var existingRoles = this.allRolesSites.find(r => r.siteId == siteId).roleId;

        this.setState({ openManageRoles: true, openManageType: "Update", manageRoleType: siteId == -1 ? "Organization" : "Site", manageRoleSites: [siteId], manageRoleRoles: existingRoles });
    }
    setmanageRoleSites(option) {
        this.setState({ manageRoleType: "Site" });
        if (!option.value.includes("allsitesid")) {
            this.setState({ manageRoleSites: option.value });
        }
        else if (option.value.includes("allsitesid")) {
            const allSiteIds = this.props.sitesdata.map(s => s.id);

            if (allSiteIds.length == this.state.manageRoleSites.length) { //deselect all
                this.setState({ manageRoleSites: [] });
            }
            else { //select all
                this.setState({ manageRoleSites: allSiteIds });
            }
        }
    }
    isSiteChecked(id) {
        if (id == "allsitesid") {
            return this.state.manageRoleSites.length == this.props.sitesdata.map(s => s.id).length;
        }
        else {
            return this.state.manageRoleSites.includes(id);
        }
    }
    setSite(evt, id) {
        if (evt.target.checked) {
            if (id == "allsitesid") {
                this.setState({ manageRoleSites: this.props.sitesdata.map(s => s.id) });
            }
            else {
                var siteList = this.state.manageRoleSites;
                siteList.push(id);
                this.setState({ manageRoleSites: siteList });
            }
        }
        else {
            if (id == "allsitesid") {
                this.setState({ manageRoleSites: [] });
            }
            else {
                var siteList = this.state.manageRoleSites.filter(r => r != id);
                this.setState({ manageRoleSites: siteList });
            }
        }
    }
    setRole(evt, id) {
        if (evt.target.checked) {
            var roleList = this.state.manageRoleRoles;
            roleList.push(id);
            this.setState({ manageRoleRoles: roleList });
        }
        else {
            var roleList = this.state.manageRoleRoles.filter(r => r != id);
            this.setState({ manageRoleRoles: roleList });
        }
    }
    addRoles(type) {
        const { manageRoleType, manageRoleSites, manageRoleRoles } = this.state;
        const { organisationdata, rolesdata, userdata } = this.props;
        const active = userdata.id == "0" ? true : this.isActive();
        if (type == "Add") {
            this.showActiveMsg = false;
            if (manageRoleType == "Organization") {
                manageRoleRoles.forEach((roleId) => {
                    const role = rolesdata.find(r => r.id == roleId);
                    const roleToAdd = { active: active, locationId: null, organizationId: organisationdata.id, role: role, roleId: role.id, userId: userdata.email };

                    if (!userdata.userRoles.find(r => r.roleId == roleToAdd.roleId)) {
                        userdata.userRoles.push(roleToAdd);
                    }
                })
            }
            else if (manageRoleType == "Site") {
                manageRoleRoles.forEach((roleId) => {
                    manageRoleSites.forEach((siteId) => {
                        const role = rolesdata.find(r => r.id == roleId);
                        const roleToAdd = { active: active, locationId: siteId, organizationId: organisationdata.id, role: role, roleId: role.id, userId: userdata.email };

                        if (!userdata.userRoles.find(r => r.roleId == roleToAdd.roleId && r.locationId == siteId)) {
                            userdata.userRoles.push(roleToAdd);
                        }
                    })
                })
            }
        }
        else if (type == "Update") {
            const siteId = manageRoleSites[0];
            userdata.userRoles = userdata.userRoles.filter(r => r.locationId != siteId);

            manageRoleRoles.forEach((roleId) => {
                const role = rolesdata.find(r => r.id == roleId);
                const roleToAdd = { active: active, locationId: siteId, organizationId: organisationdata.id, role: role, roleId: role.id, userId: userdata.email };

                if (!userdata.userRoles.find(r => r.roleId == roleToAdd.roleId && r.locationId == siteId)) {
                    userdata.userRoles.push(roleToAdd);
                }
            })
        }
        this.setState({ openManageRoles: false, openManageType: "", manageRoleType: "", manageRoleSites: [], manageRoleRoles: [] });
    }
    renderManage(type) {
        //type = Add or Update

        const { manageRoleType, manageRoleSites, manageRoleRoles } = this.state;
        const { organisationdata, sitesdata } = this.props;

        const disableAdd = () => {
            if (manageRoleSites.length == 0 || manageRoleRoles.length == 0) {
                return true;
            }
            else {
                return false;
            }
        }

        const returnSiteOptions = () => {
            var optionList = this.props.sitesdata.map(s => s).sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            optionList.unshift({ name: "All", id: "allsitesid" });
            return optionList;
        }

        const returnRolesOptions = (roleType) => {
            if (roleType == "Organization") {
                return this.props.rolesdata.filter(s => s.level == "Organization").sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            }
            else if (roleType == "Site") {
                return this.props.rolesdata.filter(s => s.level == "Location").sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            }
        }

        const disableSites = () => {
            if (manageRoleType == "Organization" || type == "Update") {
                return true;
            }
            if (manageRoleSites.includes("allsitesid")) {
                const number = returnSiteOptions().length;
                return [...Array(number).keys()].splice(1);
            }

            return []
        }

        return (
            <Layer
                position="center"
                modal={true}
                onClickOutside={() => this.onClose()}
                onEsc={() => this.onClose()}
            >
                <Box pad="medium" width="medium">
                    <Box tag="header" direction="row">
                        <Text weight="bold">Add new Role(s)</Text>
                    </Box>
                    <Box tag="body" direction="column">
                        {/*<FormField label="Role Type" error={this.email_err}>*/}
                        {/*    <Select*/}
                        {/*        placeholder="Role Type"*/}
                        {/*        options={["Organization", "Site"]}*/}
                        {/*        value={manageRoleType}*/}
                        {/*        onChange={option => this.setState({ manageRoleType: option.value })}*/}
                        {/*        disabled={type == "Update"}*/}
                        {/*    />*/}
                        {/*</FormField>*/}
                        {/*<FormField label="Organisation" error={this.email_err}>*/}
                        {/*    <Select*/}
                        {/*        placeholder="Organisation"*/}
                        {/*        options={[organisationdata.name]}*/}
                        {/*        value={organisationdata.name}*/}
                        {/*        disabled*/}
                        {/*    />*/}
                        {/*</FormField>*/}
                        <FormField label="Site(s)" error={this.email_err}>
                            <Select
                                placeholder="Location"
                                multiple
                                messages={{ multiple: "Multiple Sites Selected" }}
                                labelKey="name"
                                valueKey={{ key: 'id', reduce: true }}
                                options={returnSiteOptions()}
                                value={manageRoleSites}
                                onChange={option => this.setmanageRoleSites(option)}
                                disabled={disableSites()}
                            />
                        </FormField>
                        {/*{manageRoleType == "Site" &&*/}
                        {/*    <FormField label="Site(s)" error={this.email_err}>*/}
                        {/*        <Box gap="small" pad="small">*/}
                        {/*            {returnSiteOptions().map((info, idx) =>*/}
                        {/*                <Box>*/}
                        {/*                    <CheckBox label={info.name} checked={this.isSiteChecked(info.id)} onChange={evt => this.setSite(evt, info.id)} />*/}
                        {/*                </Box>*/}
                        {/*            )}*/}
                        {/*        </Box>*/}
                        {/*    </FormField>}*/}
                        {/*{manageRoleType != "" &&*/}
                        {/*    <FormField label="Role(s)" error={this.email_err}>*/}
                        {/*        <Select*/}
                        {/*            placeholder="Role"*/}
                        {/*            multiple*/}
                        {/*            messages={{ multiple: "Multiple Roles Selected" }}*/}
                        {/*            labelKey="name"*/}
                        {/*            valueKey={{ key: 'id', reduce: true }}*/}
                        {/*            options={returnRolesOptions(manageRoleType)}*/}
                        {/*            value={manageRoleRoles}*/}
                        {/*            onChange={option => this.setState({ manageRoleRoles: option.value })}*/}
                        {/*        />*/}
                        {/*    </FormField>}*/}
                        {manageRoleType != "" &&
                            <FormField label="Role(s)" error={this.email_err}>
                                <Box gap="small" pad="small">
                                    {returnRolesOptions(manageRoleType).map((info, idx) =>
                                        <Box>
                                            <CheckBox label={info.name} checked={manageRoleRoles.includes(info.id)} onChange={evt => this.setRole(evt, info.id)} />
                                        </Box>
                                    )}
                                </Box>
                            </FormField>}
                    </Box>
                    <Box tag="footer" direction="row" align="center" justify="end" gap="small">
                        <StyledButton typeSave label={type == "Add" ? "Add" : "Update"} onClick={() => this.addRoles(type)} disabled={disableAdd()} />
                        <StyledButton typeCancel label="Cancel" onClick={() => this.onClose()} />
                    </Box>
                </Box>
            </Layer>
        )
    }
    renderRolesTable(props) {
        const COLUMNS = [
            {
                property: 'lctype',
                header: 'Role Type',
                render: rolesdata => <Box pad={{ "right": "small" }}><Text>{rolesdata.roleType}</Text></Box>
            },
            {
                property: 'lcsite',
                header: 'Site Name',
                render: rolesdata => <Box pad={{ "right": "small" }}><Text>{rolesdata.siteName}</Text></Box>
            },
            {
                property: 'lcrole',
                header: 'Role',
                render: rolesdata => <Box pad={{ "right": "small" }}><Text>{rolesdata.rolesString}</Text></Box>
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: rolesdata => <Text hidden>{rolesdata.siteId}</Text>,
            },
            {
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: rolesdata =>
                    <Box width="24px">
                        <Button plain onClick={evt => this.DeleteRole(rolesdata.siteId)} icon={<Trash />} />
                    </Box>,
            },
        ];

        const { sitesdata, userdata, rolesdata } = this.props;

        if (sitesdata && userdata) {
            this.allRoles = [];
            userdata.userRoles.forEach((ev, id) => {
                let sName = sitesdata.find(s => s.id == ev.locationId) ? sitesdata.find(s => s.id == ev.locationId).name : "";
                let type = "";
                if (ev.role) {
                    if (ev.role.level == "Organization") type = "Organization"
                    if (ev.role.level == "Location") type = "Site"
                }

                this.allRoles.push({
                    ...ev,
                    orgId: ev.organizationId,
                    siteName: sName,
                    siteId: ev.locationId == null ? -1 : ev.locationId,
                    roleType: type,
                    roleName: ev.role ? ev.role.name : "",
                    id: ev.roleId
                });
            });

            this.allRoles.sort((p1, p2) => { if (p1.siteName.toLowerCase() < p2.siteName.toLowerCase()) return -1; return 1; });
            this.allRoles = this.allRoles.filter(r => r.roleId != "f028c85c16a148e8820da3537d74e9d9"); //Removing Org admin

            this.allRolesSites = [];
            this.allRoles.forEach((ev, id) => {
                if (this.allRolesSites.filter(r => r.siteName == ev.siteName && r.siteName != "").length == 0) {
                    this.allRolesSites.push({
                        orgId: ev.orgId,
                        siteName: ev.siteName,
                        siteId: ev.siteId,
                        roleType: ev.roleType,
                        roleId: [ev.roleId],
                        roleName: [ev.roleName]
                    });
                }
                else {
                    this.allRolesSites.find(r => r.siteName == ev.siteName).roleName.push(ev.roleName);
                    this.allRolesSites.find(r => r.siteName == ev.siteName).roleId.push(ev.roleId);
                }
            });

            this.allRolesSites.forEach((ev, id) => { ev.roleName.sort((p1, p2) => { if (p1.toLowerCase() < p2.toLowerCase()) return -1; return 1; }); });
            //this.orgsWithSites = [];
            //sitesdata.forEach((ev) => {
            //    this.orgsWithSites.push({
            //        name: ev.name,
            //        id: ev.organizationId,
            //        sites: sitesdata.map(s => ({ name: s.name, id: s.id })),
            //    });
            //})

            //this.orgsWithSites.forEach((ev) => {
            //    let currentOrgRoles = this.allRolesSites.filter(r => r.orgId == ev.id);
            //    let siteRoles = rolesdata.filter(r => r.level == "Location").map(r => ({ name: r.name, count: 0, sites: [] }));
            //    siteRoles.forEach((ev) => {
            //        ev.sites = currentOrgRoles.filter(r => r.roleName.includes(ev.name)).map(r => r.siteName);
            //        ev.count = currentOrgRoles.filter(r => r.roleName.includes(ev.name)).map(r => r.siteName).length;
            //    })

            //    if (siteRoles.filter(r => r.count == ev.sites.length).map(r => r.name).length != 0) {
            //        this.allRolesSites.unshift({
            //            orgId: ev.id,
            //            siteName: "All",
            //            siteId: -1,
            //            roleType: "Site",
            //            roleName: siteRoles.filter(r => r.count == ev.sites.length).map(r => r.name),
            //        });
            //    }
            //})

            //this.allRolesSites.forEach((ev) => {
            //    if (this.allRolesSites.find(r => r.siteId == -1 && r.orgId == ev.orgId)) {
            //        let rolesAllSites = this.allRolesSites.find(r => r.siteId == -1 && r.orgId == ev.orgId).roleName.map(r => r);
            //        if (ev.siteId != -1) {
            //            ev.roleName = ev.roleName.filter(r => !rolesAllSites.includes(r))
            //        }
            //    }
            //})

            this.allRolesSites = this.allRolesSites.filter(r => r.roleName.length != 0);
            this.allRolesSites.sort((p1, p2) => { if (p1.roleType.toLowerCase() < p2.roleType.toLowerCase()) return -1; return 1; });
        }

        let DATA = [];

        if (this.allRolesSites[0] && this.allRolesSites[0].count != 0) {
            this.allRolesSites.forEach(ev => {
                DATA.push({
                    ...ev,
                    rolesString: ev.roleName.join(", "),
                })
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.roleType.toLowerCase().includes(search) ||
                d.siteName.toLowerCase().includes(search) ||
                d.rolesString.toLowerCase().includes(search)
            );
        }

        if (this.allRolesSites.length == 0) return;

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcusername" || c.property === "lcemail" || c.property === "lcrole"
                }))}
                data={DATA}
                noLoadingMsg={this.props.userdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcusername', label: 'Username' },
                //    { col: 'lcemail', label: 'Email' },
                //    { col: 'lcactive', label: 'Active' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                noCounter

                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }
    render() {
        const { options, selected, checked, loaded, userdataLoading, rolesdataLoading, sitesdataLoading, organisationdataLoading } = this.state;
        const userdefault = this.props.userdata;
        /*const sitesdata = this.props.sitesdata;*/

        if (userdataLoading || rolesdataLoading || sitesdataLoading || organisationdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        const heading = userdefault && (userdefault.id != "0") ? 'Edit' : 'Create';
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >User - {heading} </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onCloseSaving()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={() => this.onSave()} onDiscard={() => this.onDiscard()} />
                    <form>
                        <Box gap="medium">
                            <Box gap="small">
                                <Box>
                                    <Text>All fields marked with an * are mandatory.</Text>
                                    <Text size="large">Description</Text>
                                </Box>
                                <StyledBox style1 pad={{ "horizontal": "small" }} width="700px">
                                    <FormField label="Email *" error={this.email_err}>
                                        <TextInput defaultValue={userdefault.email} onChange={evt => this.updateEmailValue(evt)} readOnly={userdefault.id != "0"} disabled={userdefault.id != "0"} />
                                    </FormField>
                                    <FormField label="Username *" error={this.username_err}>
                                        <TextInput defaultValue={userdefault.username} onChange={evt => this.updateUsernameValue(evt)} />
                                    </FormField>
                                    {/*<FormField label="Group" >
                                <Select
                                    multiple
                                    placeholder="Select Role"
                                    value={selected.length ? this.renderSelected() : undefined}
                                    options={options.map(o => o.name)}
                                    onChange={({ option }) => {
                                        const newSelected = [...selected];
                                        const selectedIndex = newSelected.indexOf(option);
                                        if (selectedIndex >= 0) {
                                            newSelected.splice(selectedIndex, 1);
                                        } else {
                                            newSelected.push(option);
                                        }
                                        this.setState({ selected: newSelected });
                                    }}
                                >
                                    {option => (
                                        <Option value={option} selected={selected.indexOf(option) >= 0} />
                                    )}
                                </Select>
                            </FormField>*/}
                                    {/*RB.isSuperUser() && (
                            <FormField label="Super User">
                                <Box pad={{ bottom: "small", horizontal: "small" }}>
                                    <CheckBox checked={userdefault.superUser} onChange={evt => this.updateSuperUser(evt)} disabled={!RB.isSuperUser()} />
                                </Box>
                            </FormField>
                            )*/}
                                    <Box width="60px">
                                        <FormField label="Org Admin">
                                            <Box pad="small" align="center" justify="center">
                                                <CheckBox checked={this.isOrgAdmin()} onChange={evt => this.updateOrgAdmin()} />
                                            </Box>
                                        </FormField>
                                    </Box>
                                    <Box direction="row" gap="small">
                                        <Box width="60px">
                                            <FormField label="Active">
                                                <Box pad="small" align="center" justify="center">
                                                    <CheckBox checked={this.isActive()} onChange={evt => this.SetActive(evt)} />
                                                </Box>
                                            </FormField>
                                        </Box>
                                        {this.showActiveMsgFn() &&
                                            <Box justify="center" pad={{ "top": "medium" }}>
                                                <Text color="red">Users without any roles can not be set to active</Text>
                                            </Box>}
                                    </Box>
                                </StyledBox>
                                {/*{(this.state.organisationroles.roles.length != 0) &&*/}
                                {/*    <Box>*/}
                                {/*        <Text>Org Admins have access to all locations and roles for their organisation. To apply location specific rights, remove Org Admin access and select the location/role required for this user</Text>*/}
                                {/*    </Box>}*/}
                            </Box>


                            <Box direction="row" gap="large">
                                {!this.isOrgAdmin() &&
                                    <Box gap="small" flex="shrink">
                                        <Box>
                                            <Text size="large">Roles</Text>
                                        </Box>
                                        <Box>
                                            <StyledButton typeAdd label="Add" alignSelf="start" onClick={() => this.Add()} />
                                        </Box>
                                        <Box ref={this.dtwrapper} width={{ min: "700px" }}>
                                            {this.renderRolesTable()}
                                        </Box>
                                        {/*<Box gap="medium">*/}
                                        {/*    {this.state.locationroles.map((info, idx) =>*/}
                                        {/*        <Box direction="row">*/}
                                        {/*            <StyledBox style1 direction="row" justify="start" gap="medium" pad={{ "left": "small" }} width="" flex="shrink">*/}
                                        {/*                <Box width="350px">*/}
                                        {/*                    <FormField label="Site" error={this.entryerrors[idx].locationId}>*/}
                                        {/*                        <Select*/}
                                        {/*                            placeholder="Site"*/}
                                        {/*                            options={this.renderOptions("options")}*/}
                                        {/*                            onChange={evt => this.UpdateL(evt, idx)}*/}
                                        {/*                            //value={this.sitesfull.find(l => l.id == info.locationId) ? this.sitesfull.find(l => l.id == info.locationId).name : ""}*/}
                                        {/*                            //value={evt => this.LocValue(evt,info)}*/}
                                        {/*                            value={info.name}*/}
                                        {/*                            disabled={this.renderOptions("hide")}*/}
                                        {/*                        />*/}
                                        {/*                    </FormField>*/}
                                        {/*                </Box>*/}

                                        {/*                <Box direction="row" width="250px">*/}
                                        {/*                    <FormField label="Role" error={this.entryerrors[idx].role}>*/}
                                        {/*                        <Select*/}
                                        {/*                            multiple*/}
                                        {/*                            placeholder="Select Role"*/}
                                        {/*                            * /}
                                        {/*                            value={this.state.locationroles[idx].roles.length ? this.renderSelected1(idx) : ""}*/}
                                        {/*                            options={options.map(o => o.name)}*/}
                                        {/*                            onChange={({ option }) => {*/}
                                        {/*                                const newSelected = [...this.state.locationroles];*/}
                                        {/*                                const selectedIndex = newSelected[idx].roles.indexOf(option);*/}
                                        {/*                                if (selectedIndex >= 0) {*/}
                                        {/*                                    newSelected[idx].roles.splice(selectedIndex, 1);*/}
                                        {/*                                } else {*/}
                                        {/*                                    newSelected[idx].roles.push(option);*/}
                                        {/*                                }*/}
                                        {/*                                this.setState({ selected: newSelected.sort() });*/}
                                        {/*                            }}*/}
                                        {/*                        >*/}
                                        {/*                            {option => (*/}
                                        {/*                                <Option value={option} selected={this.state.locationroles[idx].roles.indexOf(option) >= 0} />*/}
                                        {/*                            )}*/}
                                        {/*                        </Select>*/}
                                        {/*                    </FormField>*/}

                                        {/*                    <Button icon={<Trash />} onClick={() => this.DeleteL(idx)} />*/}
                                        {/*                </Box>*/}
                                        {/*            </StyledBox>*/}
                                        {/*        </Box>*/}
                                        {/*    )}*/}
                                        {/*</Box>*/}
                                        {/*<StyledButton typeAdd label="Add" alignSelf="start" onClick={() => this.Add()} disabled={(this.state.locationroles.length == this.sitesfull.length) ? true : false} />*/}
                                    </Box>}
                                {/*{false &&*/}
                                {/*    <Box>*/}
                                {/*        <Box gap="small" flex="shrink">*/}
                                {/*            <Box>*/}
                                {/*                <Text size="large">Manage Roles on All Sites</Text>*/}
                                {/*            </Box>*/}
                                {/*            <Box gap="medium">*/}
                                {/*                <StyledBox style1 pad={{ "horizontal": "small" }} gap="small" direction="row" width="390px">*/}
                                {/*                    <FormField label="Add Roles To All Sites" error={this.addAll_err}>*/}
                                {/*                        <Select*/}
                                {/*                            multiple*/}
                                {/*                            placeholder="Select Roles"*/}
                                {/*                            */}
                                {/*                            value={this.state.addAllRoles.length ? this.renderSelected(this.state.addAllRoles) : ""}*/}
                                {/*                            options={options.map(o => o.name)}*/}
                                {/*                            onChange={({ option }) => {*/}
                                {/*                                const newSelected = [...this.state.addAllRoles];*/}
                                {/*                                const selectedIndex = newSelected.indexOf(option);*/}
                                {/*                                if (selectedIndex >= 0) {*/}
                                {/*                                    newSelected.splice(selectedIndex, 1);*/}
                                {/*                                }*/}
                                {/*                                else {*/}
                                {/*                                    newSelected.push(option);*/}
                                {/*                                }*/}
                                {/*                                this.addAll_err = "";*/}
                                {/*                                this.setState({ addAllRoles: newSelected.sort() });*/}
                                {/*                            }}*/}
                                {/*                        >*/}
                                {/*                            {option =>*/}
                                {/*                                <Option value={option} selected={this.state.addAllRoles.indexOf(option) >= 0} />*/}
                                {/*                            }*/}
                                {/*                        </Select>*/}
                                {/*                    </FormField>*/}
                                {/*                    <Box justify="center">*/}
                                {/*                        <StyledButton typeAdd label="Add" alignSelf="start" onClick={() => this.AddAll()} />*/}
                                {/*                    </Box>*/}
                                {/*                </StyledBox>*/}
                                {/*                <StyledBox style1 pad={{ "horizontal": "small" }} gap="small" direction="row" width="390px">*/}
                                {/*                    <FormField label="Remove Roles From All Sites" error={this.removeAll_err}>*/}
                                {/*                        <Select*/}
                                {/*                            multiple*/}
                                {/*                            placeholder="Select Roles"*/}
                                {/*                            */}
                                {/*                            value={this.state.removeAllRoles.length ? this.renderSelected(this.state.removeAllRoles) : ""}*/}
                                {/*                            options={options.map(o => o.name)}*/}
                                {/*                            onChange={({ option }) => {*/}
                                {/*                                const newSelected = [...this.state.removeAllRoles];*/}
                                {/*                                const selectedIndex = newSelected.indexOf(option);*/}
                                {/*                                if (selectedIndex >= 0) {*/}
                                {/*                                    newSelected.splice(selectedIndex, 1);*/}
                                {/*                                }*/}
                                {/*                                else {*/}
                                {/*                                    newSelected.push(option);*/}
                                {/*                                }*/}
                                {/*                                this.removeAll_err = "";*/}
                                {/*                                this.setState({ removeAllRoles: newSelected.sort() });*/}
                                {/*                            }}*/}
                                {/*                        >*/}
                                {/*                            {option =>*/}
                                {/*                                <Option value={option} selected={this.state.removeAllRoles.indexOf(option) >= 0} />*/}
                                {/*                            }*/}
                                {/*                        </Select>*/}
                                {/*                    </FormField>*/}
                                {/*                    <Box justify="center">*/}
                                {/*                        <StyledButton typeCancel label="Remove" alignSelf="start" onClick={() => this.RemoveAll()} />*/}
                                {/*                    </Box>*/}
                                {/*                </StyledBox>*/}
                                {/*            </Box>*/}
                                {/*        </Box>*/}
                                {/*    </Box>}*/}
                            </Box>

                            {/*this.state.organisationroles.hasOwnProperty('organisationid') && (
                            <Box direction="row" justify="between">
                                <Box >
                                    <FormField label="Organization" style={{ background: "white" }} >
                                        <Select
                                            style={{ background: "white" }}
                                            placeholder="Organization"
                                            options={["Hewlett Packard Enterprise"]}
                                            //onChange={evt => this.UpdateL(evt, idx)}
                                            value="Hewlett Packard Enterprise"
                                        />
                                    </FormField>
                                </Box>
                                <Box >
                                    <FormField label="Role" style={{ background: "white" }} >
                                        <Select
                                            style={{ background: "white" }}
                                            multiple
                                            placeholder="Select Role"
                                            value={this.state.organisationroles.roles}
                                            options={["Organisation Admin"]}
                                            onChange={({ option }) => {
                                                const newSelected = { ...this.state.organisationroles };
                                                const selectedIndex = newSelected.roles.indexOf(option);
                                                if (selectedIndex >= 0) {
                                                    newSelected.roles.splice(selectedIndex, 1);
                                                } else {
                                                    newSelected.roles.push(option);
                                                }
                                                this.setState({ selected: newSelected });
                                                console.log(this.state.organisationroles);
                                            }}
                                        >
                                            {option => (
                                                <Option value={option} selected={this.state.organisationroles.roles.indexOf(option) >= 0} />
                                            )}
                                        </Select>
                                    </FormField>
                                </Box>
                                <Button icon={<Trash />} onClick={() => this.DeleteOrg()} />
                            </Box>
                        )*/}
                            {/*<StyledButton typeAdd label="Add"  onClick={() => this.AddOrg()} disabled={this.state.organisationroles.hasOwnProperty('organisationid')} />*/}
                        </Box>
                    </form>
                    {this.state.openManageRoles &&
                        <Box>
                            {this.renderManage(this.state.openManageType)}
                        </Box>
                    }

                    {/*{this.state.open &&*/}
                    {/*    <Layer*/}
                    {/*        position="center"*/}
                    {/*        modal={true}*/}
                    {/*        onClickOutside={() => this.onClose()}*/}
                    {/*        onEsc={() => this.onClose()}*/}
                    {/*    >*/}
                    {/*        <Box pad="medium" gap="small" width="medium" >*/}
                    {/*            <Heading level={3} margin="none">*/}
                    {/*                Warning*/}
                    {/*        </Heading>*/}
                    {/*            <Text>Current User will no longer be able to see this Organisation.</Text>*/}
                    {/*            <Box*/}
                    {/*                tag="footer"*/}
                    {/*                gap="small"*/}
                    {/*                direction="row"*/}
                    {/*                align="center"*/}
                    {/*                justify="end"*/}
                    {/*                pad={{ top: "medium", bottom: "small" }}*/}

                    {/*            >*/}
                    {/*                <StyledButton typeCancel label="Cancel" onClick={() => this.onClose()} />*/}
                    {/*                <StyledButton typeSave label="Confirm" onClick={() => this.onConfirm()} />*/}
                    {/*            </Box>*/}
                    {/*        </Box>*/}
                    {/*    </Layer>*/}
                    {/*}*/}
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>
            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const userId = ownProps.match.params.userId || '';
    if (state.selectedUserReducer.userdata && userId == state.selectedUserReducer.userdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedUserReducer.userdata));
        return {
            userdata: state.selectedUserReducer.userdata,
            rolesdata: state.rolesReducer.rolesdata,
            sitesdata: state.sitesReducer.sitesdata,
            organisationdata: state.selectedOrganisationReducer.organisationdata,
        };
    } else {
        return {
            rolesdata: state.rolesReducer.rolesdata,
            sitesdata: state.sitesReducer.sitesdata
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...UserAction, ...RoleAction, ...SiteAction, ...OrganisationAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserEditContainer));