import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssetHistoryListTable from './AssetHistoryListTable';
// eslint-disable-next-line
import { Box, Text, Select, Heading, Button, Layer, Grommet } from 'grommet';
import { Expand, Contract, Close, FormClose } from 'grommet-icons';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import { Download } from 'grommet-icons';
import { CSVLink } from "react-csv";
import ReactTooltip from "react-tooltip";
import constants from '../constants';
import * as MeridianSDK from "@meridian/web-sdk";
import { myHeadersWithToken } from '../api/ApiUtils';
import { SERVER } from '../api/serverUrl';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';

var moment = require('moment');
var mtz = require('moment-timezone');
var abbs = mtz.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z').toString();
const defaultImage = 'https://files.meridianapps.com/meridian-web-sdk/0.25.0/files/tags/generic.svg';
async function fetchRequest(url, method, next = null, locId = "") {
    const URL = SERVER + url;


    let headers = await myHeadersWithToken();
    if (locId)
        headers.append('X-LocID', locId);
    if (next) {
        Object.entries(next).forEach(itm => headers.append(itm[0], itm[1]));
    }
    var option = {
        method: method,
        headers: headers
    };
    return fetch(URL, option);
}


export class AssetHistoryListContainer extends React.Component {

    constructor() {
        super();
        this.rows = [];
        this.maps = [];
        this.zones = [];
        this.downloadData = [];;
        this.daysList = [
            { id: 0, name: "All", detail: "day", value: 1000, days: 1000 }, // ALL MAX 1000day
            { id: 1, name: "Last 24 hours", detail: "day", value: 1, days: 1 },
            { id: 2, name: "Last 7 days", detail: "day", value: 7, days: 7 },
            { id: 3, name: "Last 30 days", detail: "day", value: 30, days: 30 },
            { id: 4, name: "Last 90 days", detail: "day", value: 90, days: 90 },
            { id: 5, name: "Last 6 months", detail: "month", value: 6, days: 182 },
            { id: 6, name: "Last 12 months", detail: "month", value: 12, days: 365 }
        ];
        this.childDiv = React.createRef();
        this.state = {
            apiErrorCounter: 0,
            apiErrorList: [],
            mapId: "",
            x: "",
            y: "",
            mapVisible: false,
            isFullScreen: false,
            dayOption: this.daysList[1],
            isLoading: true
        };

    }
    headerHeight = 0;

    componentDidMount() {
        this.getMaps(this.props.locationId);
        this.getZones(this.props.locationId)
            .then(r => {
                console.log("nyai");
                this.getAssetHistory(null, this.daysList[/*this.state.days*/1].days)
                    .then(r => this.merge())
            }
            );

        //this.getAssetHistory(null,7)
        //.then(r=> this.merge());

    }

    getAssetHistory(next = null, days = 0) {
        let nexT = null;
        let url = days == 0 ?
            `/api/AssetTrackingHistory/${this.props.historydata.id}` :
            `/api/AssetTrackingHistory/${this.props.historydata.id}?days=${days}`
        return fetchRequest(url, "get", next, this.props.siteId)
            .then(r => {
                if (r.headers.get('x-ms-continuation-nextrowkey')) {
                    nexT = {
                        "x-ms-continuation-nextpartitionkey": r.headers.get('x-ms-continuation-nextpartitionkey'),
                        "x-ms-continuation-nextrowkey": r.headers.get('x-ms-continuation-nextrowkey')
                    };
                }
                if (r.ok)
                    return r.json();
                return [];
            })
            .then(r => {
                console.log("ne", r);
                if (r.value)
                    this.rows = this.rows.concat(r.value);
                if (nexT)
                    return this.getAssetHistory(nexT, days);
                return this.rows;
            })
    }

    getMaps(locationId, next = null) {
        let nexT = null;
        //let url = `/locations/${locationId}/maps`;
        let url = `/tables/map?$expand=Group($select=Name)`;
        return fetchRequest(url, "get", next, this.props.siteId)
            .then(r => {
                if (r.headers.get('x-ms-continuation-nextrowkey')) {
                    nexT = {
                        "x-ms-continuation-nextpartitionkey": r.headers.get('x-ms-continuation-nextpartitionkey'),
                        "x-ms-continuation-nextrowkey": r.headers.get('x-ms-continuation-nextrowkey')
                    };
                }
                console.log("nyai", r);
                if (r.ok)
                    return r.json();
                return [];
            })
            .then(r => {
                if (r)
                    this.maps = this.maps.concat(r);
                if (nexT)
                    return this.getMaps(locationId, nexT);
                return this.maps;
            })
    }

    getZones(locationId, next = null) {
        let nexT = null;
        //let url = `/locations/${locationId}/zones`;
        let url = `/tables/zone`;
        return fetchRequest(url, "get", next, this.props.siteId)
            .then(r => {
                if (r.headers.get('x-ms-continuation-nextrowkey')) {
                    nexT = {
                        "x-ms-continuation-nextpartitionkey": r.headers.get('x-ms-continuation-nextpartitionkey'),
                        "x-ms-continuation-nextrowkey": r.headers.get('x-ms-continuation-nextrowkey')
                    };
                }
                if (r.ok)
                    return r.json();
                return [];
            })
            .then(r => {
                //console.log("nyai", r, nextT);
                if (r)
                    this.zones = this.zones.concat(r);
                if (nexT)
                    return this.getZones(locationId, nexT);
                return this.zones;
            })
    }

    merge() {

        if (this.maps) {
            this.downloadData = [];
            this.rows.forEach((item, i) => {
                //item.time = item.time.substring(0,19).replace("T", " ");
                item.localTime = moment(item.time).format('DD MMMM YYYY H:mm');
                if (i == 0) {
                    let map = this.maps.find(m => m.id == item.mapId);
                    if (map) {
                        item.MeridianBuildingName = map.group ? map.group.name : "";
                        item.FloorNumber = map.name;
                    }
                    let zone = this.zones.find(zone => zone.id == item.zoneId);
                    if (zone) {
                        item.ZoneName = zone.name;
                    }
                }
                else if (i > 0 && item.mapId == this.rows[i - 1].mapId) {
                    item.MeridianBuildingName = this.rows[i - 1].MeridianBuildingName;
                    item.FloorNumber = this.rows[i - 1].FloorNumber;

                    let zone = this.zones.find(zone => zone.id == item.zoneId);
                    if (zone) {
                        item.ZoneName = zone.name;
                    }
                }
                else {
                    let map = this.maps.find(m => m.id == item.mapId);
                    if (map) {
                        item.MeridianBuildingName = map.group ? map.group.name : "";
                        item.FloorNumber = map.name;
                    }
                    let zone = this.zones.find(zone => zone.id == item.zoneId);
                    if (zone) {
                        item.ZoneName = zone.name;
                    }
                }
                this.downloadData.push(
                    {
                        //"TIMESTAMP (UTC)": item.time.toString(),
                        [`TIMESTAMP (${abbs})`]: item.localTime.toString().replace("", "  "),  //Replace needed, so Excel won't convert it
                        "MERIDIAN BUILDING NAME": item.MeridianBuildingName,
                        "FLOOR": item.FloorNumber,
                        "ZONE": item.ZoneName ? item.ZoneName : ""
                    }
                );
            });
            console.log("nah", this.maps, this.rows, this.zones);

        }
        this.setState({ isLoading: false });

    }

    getMergedData(days = 0) {
        this.rows = [];
        this.getAssetHistory(null, days)
            .then(r => {
                this.merge();
            });
    }

    componentWillUnmount() {
        if (this.map) {
            this.map.destroy();
        }
    }

    handleClick = (mapId, x, y) => this.setState({ mapId: mapId, x: x, y: y }, () => {
        this.renderMap(mapId, x, y);
        //if (Math.floor(Math.random() * 10) % 2 == 0)
        //    this.renderMap();
        //else
        //    this.renderMap("5653104741580800", "500.13293", "500.31995");
        //this.childDiv.current.scrollIntoView({
        //    behavior: "smooth",
        //    block: "start",
        //});
        //document.getElementById("up").scrollIntoView({
        //    behavior: "smooth",
        //    block: "start",
        //});
        document.getElementById("main").scrollTo({ top: 0, behavior: 'smooth' });
    })

    renderMap(floorid = this.state.mapId, x = this.state.x, y = this.state.y) {
        let point = [{
            type: "point",
            x: x,
            y: y,
            backgroundColor: /*"#c00"*/"none",
            size: 25,
            backgroundImage: this.props.image ? this.props.image : defaultImage,
            //title: "Last Known"
        }];
        let id = this.state.isFullScreen ? "meridian-map2" : "meridian-map1";
        console.log("what", id, this.map, x, y);
        if (this.map && !this.map.isDestroyed && false) {
            this.map.update({ floorID: floorid });
        }
        else {
            console.log("propi", floorid);
            MeridianSDK.init({
                api: new MeridianSDK.API({
                    environment: this.props.environment,
                    //token: "6c09d265b1413fc768f4e08447ea95c8923a9c78",
                    token: this.props.token
                })
            });
            var element = document.getElementById(id);
            if (element) {
                element.innerHTML = "";
            };
            //console.log("element", element);
            //console.log("mapelement", this.props.sitedata.appID, floorid);
            this.map = MeridianSDK.createMap(
                //document.getElementById("meridian-map"),
                element,
                {
                    //locationID: "5198682008846336",
                    //loorID: "5755685136498688"
                    locationID: this.props.locationId,
                    floorID: floorid,
                    shouldMapPanZoom: function restrictedPanZoom(event) {
                        if (event.type === "wheel" && !event.altKey) {
                            return false;
                        } else if (event.type === "touchstart") {
                            return event.touches.length >= 2;
                        }
                        return true;
                    },
                    showFloorsControl: false,
                    showTagsControl: false,
                    showSearchControl: false,
                    annotations: point,
                    //height: "650px", //650px for Keith's DEMO
                    height: this.state.isFullScreen ? (window.innerHeight - this.headerHeight - 50) + "px" : "450px",
                    placemarks: {
                        labelZoomLevel: 0.1,
                        filter: p => {
                            return false;
                        }
                    },
                    tags: {
                        filter: t => false/*t.mac == this.state.mac && point.length == 0*/
                    },
                    onPlacemarksUpdate: ({ allPlacemarks, filteredPlacemarks }) => {
                        this.map.update({
                            overlays: filteredPlacemarks
                                .filter(placemark => placemark.area)
                                .map((placemark, i) => {
                                    const hue = (i * (360 / 6)) % 360;
                                    return {
                                        type: "polygon",
                                        points: MeridianSDK.pointsFromArea(placemark.area),
                                        //points: [3032,629],
                                        fill: `hsla(${hue}, 100%, 60%, 0.3)`,
                                        stroke: `hsl(${hue}, 100%, 60%)`
                                    };
                                })
                        });
                    },
                }
            );
            element.style.visibility = "visible";
            this.setState({ mapVisible: true });
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }

        if (document.getElementById('header')) {
            this.headerHeight = document.getElementById('header').offsetHeight;
        }

        return (
            <Box /*ref={this.childDiv} */ id="up">
                {
                    this.state.isFullScreen && this.state.mapId &&
                    <Layer
                        margin={{ "top": this.headerHeight + "px" }}
                        full
                        modal
                        onClickOutside={() => this.setState({ isFullScreen: false })}
                        onEsc={() => this.setState({ isFullScreen: false })}
                    >
                        <Box>
                            <Box direction="row" justify="between" align="center">
                                <Text weight="bold" size="20px">MAC: {this.props.historydata.id}</Text>
                                <Box direction="row">
                                    <Box alignSelf="center" data-for="closefull" data-tip="Close Fullscreen">
                                        <Button icon={<Contract />}
                                            focusIndicator={this.state.isFullScreen}
                                            onClick={() => this.setState({ isFullScreen: false }, () => {
                                                //this.renderMap();
                                                if (this.map) {
                                                    this.map.destroy();
                                                    //this.renderMap();
                                                    if (true) {
                                                        this.setState({ isDestroyed: true }, () =>
                                                            this.renderMap());
                                                    }
                                                }
                                            })}
                                            plain />
                                        <ReactTooltip id="closefull" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                                    </Box>
                                    <Button icon={<FormClose />}
                                        onClick={() => {
                                            //this.map.destroy();
                                            this.setState({ mapId: null, isFullScreen: false });
                                        }} />
                                </Box>
                            </Box>
                            <Box width="100%">
                                <div id="meridian-map2" className="map" style={{ visibility: "hidden" }} />
                                {!this.state.mapVisible &&
                                    <Box background="#fafafa" /*height="450px"*/ align="center">
                                        <Heading>
                                            There's no map uploaded for this Device.
                                    </Heading>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Layer>
                }

                {this.state.mapId &&
                    <Box>
                        <Box direction="row" justify="between" align="center">
                            <Text weight="bold" size="20px">MAC: {this.props.historydata.id}</Text>
                            <Box direction="row">
                                <Box alignSelf="center" data-for="seefull" data-tip="See Fullscreen">
                                    <Button icon={<Expand />}
                                        focusIndicator={this.state.isFullScreen}
                                        onClick={() => this.setState({ isFullScreen: true }, () => {
                                            if (this.map) {
                                                this.map.destroy();
                                                //this.renderMap();
                                                if (true) {
                                                    this.setState({ isDestroyed: true }, () =>
                                                        this.renderMap());
                                                }
                                            }
                                        })}
                                        plain />
                                    <ReactTooltip id="seefull" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                                </Box>
                                <Button icon={<FormClose />}
                                    onClick={() => {
                                        //this.map.destroy();
                                        this.setState({ mapId: null });
                                    }} />
                            </Box>
                        </Box>
                        <Box width="100%">
                            <div id="meridian-map1" className="map" style={{ visibility: "hidden" }} />
                            {!this.state.mapVisible &&
                                <Box background="#fafafa" /*height="450px"*/ align="center">
                                    <Heading>
                                        There's no map uploaded for this Device.
                                    </Heading>
                                </Box>
                            }
                        </Box>
                    </Box>
                }
                <Heading level="3" >{this.props.historydata.name}</Heading>
                <Box direction="row" justify="between">
                    <Box>
                        <Select
                            placeholder="Days"
                            options={this.daysList}
                            value={this.state.dayOption}
                            labelKey="name"
                            onChange={evt => {
                                console.log("halo", evt.option);
                                this.setState({ dayOption: this.daysList[evt.option.id], isLoading: true });
                                this.getMergedData(evt.option.days);
                            }}
                        />
                    </Box>
                    <Box justify="center">
                        <CSVLink data={this.downloadData} filename={`${this.props.historydata.name}_history.csv`}>
                            <Button plain icon={<Download color={constants.HPE_GREEN} />} />
                        </CSVLink>
                    </Box>
                </Box>
                <AssetHistoryListTable /*historydata={this.props.historydata.history}*/ historydata={this.rows} handleClick={this.handleClick} />
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    //beaconsdata: state.beaconsReducer.beaconsdata,
    //levelsdata: state.levelsReducer.levelsdata,
    //buildingsdata: state.buildingsReducer.buildingsdata
});



const mapDispatchToProps = dispatch => ({
    //action: bindActionCreators({ ...BeaconAction, ...BuildingAction, ...LevelAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(AssetHistoryListContainer);
