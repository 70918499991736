import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AssetAction from '../action/AssetAction';
import * as AssetLabelsAction from '../action/AssetLabelsAction';
import * as DepartmentAction from '../action/DepartmentAction';
/*import * as CategoryAction from '../action/CategoryAction';*/
import * as LocationAction from '../action/LocationAction';
import * as SiteAction from '../action/SiteAction';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Add, OrderedList, FormClose, FormClock, Download, Dashboard, Upload, DocumentVerified, DocumentCsv, CircleInformation, Trash } from 'grommet-icons';
import { Grommet, Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, CheckBox, Meter } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import Notification from './common/Notification';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import ReactTooltip from "react-tooltip";
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import { CSVLink } from "react-csv";
import './TagCreateContainer.css';
import { hpe } from 'grommet-theme-hpe';

var start = undefined;
var base64 = null;

export class TagCreateContainer extends React.Component {
    constructor() {
        super();
        this.stamp = Date.now().toString();
        //this.parentfull = [];
        //this.categoryfull = [];
        //this.newname = "";
        //this.newparent = "";
        //this.newparentId = "";
        //this.newlocation = "";
        this.selectedLabels = [];

        this.state = {
            file: "",
            newimage: false,
            imagechanged: false,
            removeIconOpen: false,
            //options: [],
            //selected: "",
            location: "",
            isSaved: false,
            open: false,
            notification: '',
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            isNewLabel: false,
            /*isNewCategory: false,*/
            isNewDepartment: false,

            newLabel: "",
            addType: 0,
            data: [],
            progressValue: 0,
            assetdataLoading: true,
            assetlabelsdataLoading: true,
            departmentsdataLoading: true,
            /*categoriesdataLoading: true,*/
            locationsdataLoading: true,
            sitedataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Validating errors
    tagName_err = '';
    externalID_err = '';
    MAC_err = '';
    //categoryid_err = '';
    //newname_err = "";
    //parent_err = "";
    image_err = "";

    ClearErrorMessages() {
        this.tagName_err = '';
        this.externalID_err = '';
        this.MAC_err = '';
        //this.categoryid_err = '';
        //this.newname_err = "";
        //this.parent_err = "";
        this.image_err = "";
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.tagid;
        this.props.action.getAssetAction(id)
            .then(response => this.setState({ assetdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Asset Edit Error', error);
            });
        this.props.action.getAssetLabelsAction()
            .then(response => this.setState({ assetlabelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetLabelsAction List Error', error);
            });
        this.props.action.getDepartmentsAction()
            .then(response => this.setState({ departmentsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department List Error', error);
            });
        //this.props.action.getCategoriesAction()
        //    .then(response => this.setState({ categoriesdataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log('*DEBUG*-FormEdit/Category Error', error);
        //    });
        this.props.action.getLocationsAction()
            .then(response => this.setState({ locationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-FormEdit/Parent Error', error);
            });
        this.props.action.getSiteAction(sessionStorage.getItem("locationId"))
            .then(response => this.setState({ sitedataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Site Edit Error', error);
            });
    }
    componentDidUpdate(prevProps, prevState) {
        //Load categories
        //if (this.props.categoriesdata && this.props.sitedata && this.props.categoriesdata.length > 0 && this.categoryfull.length == 0) {
        //    //console.log(this.props.categoriesdata);
        //    this.categoryfull = this.props.categoriesdata.slice();
        //    //this.categoryfull = this.categoryfull.filter(item => item.level == 2);
        //    this.categoryfull.push({
        //        id: "0",
        //        name: "",
        //        parent: "",
        //        location: "",
        //        lpn: "",
        //        synced: false
        //    });
        //    var sp = this.categoryfull.find(d => d.name == "Spaces" && d.level == 0);
        //    var spi = this.categoryfull.indexOf(sp);
        //    this.categoryfull.splice(spi, 1);
        //    var ri = this.categoryfull.find(d => d.name == "Report Issue" && d.level == 0);
        //    var i = this.categoryfull.indexOf(ri);
        //    this.categoryfull.splice(i, 1);
        //    var info = this.categoryfull.find(d => d.name == "Info" && d.level == 0);
        //    var index = this.categoryfull.indexOf(info);
        //    this.categoryfull.splice(index, 1);
        //    var ri2 = this.categoryfull.find(d => d.name == "Report Issue" && d.level == 2);
        //    var i2 = this.categoryfull.indexOf(ri2);
        //    this.categoryfull.splice(i2, 1);
        //    var info2 = this.categoryfull.find(d => d.name == "Info" && d.level == 2);
        //    var index2 = this.categoryfull.indexOf(info2);
        //    this.categoryfull.splice(index2, 1);
        //    this.categoryfull.forEach(item => item.lpn = constants.APP_LPN(item.location, item.parent, item.name));
        //    this.categoryfull.sort((p1, p2) => { if (p1.lpn.toLowerCase() < p2.lpn.toLowerCase()) return -1; return 1; });
        //    if (!this.props.sitedata.meridianEnabled) {
        //        this.categoryfull = this.categoryfull.filter(c => c.synced == false);
        //    }
        //    //this.categoryfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        //    this.setState({ options: this.categoryfull });
        //    //console.log("DidUpdate:", this.categoryfull);
        //}
        //Load parent-location dictionary
        //if (this.props.locationsdata[0] && this.props.locationsdata[0].count != 0 && this.props.locationsdata.length > 0 && this.parentfull.length == 0) {
        //    //console.log(this.props.locationsdata);
        //    this.parentfull = this.props.locationsdata.slice();
        //    this.parentfull.push({
        //        parent: "",
        //        location: ""
        //    });
        //    this.parentfull.forEach(function (item) {
        //        if (item.parent == "" && item.location == "") {
        //            item.parentloc = "";
        //        }
        //        else if (item.parent != "" && item.location == "") {
        //            item.parentloc = item.parent;
        //        }
        //        else if (item.parent == item.location) {
        //            item.parentloc = item.location;
        //        }
        //        else {
        //            item.parentloc = item.location + " / " + item.parent;
        //        }
        //    })
        //    this.parentfull.sort((p1, p2) => { if (p1.parentloc.toLowerCase() < p2.parentloc.toLowerCase()) return -1; return 1; });
        //    this.setState({ parents: this.parentfull });
        //    //console.log("DidUpdate:", this.parentfull);
        //}
    }
    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && !_.isEqual(start, this.props.assetdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    handleSave() {
        const assetdata = this.props.assetdata;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ validating: false });
        if (assetdata.name.trim().length == 0) {
            errorsno++;
            this.tagName_err = "Tag Name is required";
        }
        if (assetdata.name.length > 30) {
            errorsno++;
            this.tagName_err = "Tag Name can't be longer than 30 character";
        }
        //if (categorydefault.name.length > 30) {
        //    errorsno++;
        //    this.categoryName_err = "Category Name can't be longer than 30 character";
        //}
        //if (categorydefault.parent.trim().length == 0) {
        //    errorsno++;
        //    this.categoryParent_err = "Select an option";
        //}
        //newCategory
        //if (this.state.isNewCategory) {
        //    if (this.newname.trim().length == 0) {
        //        errorsno++;
        //        this.newname_err = "Category Name is required";
        //    }
        //    if (this.props.categoriesdata.find(o => o.name.trim().toLowerCase() == this.newname.trim().toLowerCase())) {
        //        errorsno++;
        //        this.newname_err = "Category Name already exists";
        //    }
        //    if (this.newparent.trim().length == 0) {
        //        errorsno++;
        //        this.parent_err = "Select an Option";
        //    }
        //}
        //else {
        //    //if (assetdata.categoryid == 0 || !this.categoryfull.find((opt) => opt.id == assetdata.categoryid)) {
        //    //    errorsno++;
        //    //    this.categoryid_err = "Select an Option";
        //    //}
        //}
        if (errorsno == 0) {
            // Ok, saving data
            this.setState({ disabledButton: true });
            if (this.state.newimage) {
                assetdata.imageContent = base64;
                base64 = null;
            }
            /*this.saveNewCategory(true);*/

            //assetdata.categoryId = null;
            //assetdata.category = null;
            assetdata.departmentId = null;
            assetdata.department = null;
            console.log('Save: OK', assetdata);
            this.props.action.addAssetAction(assetdata)
                .then((response) => {
                    if (/*response.ok*/true) {
                        this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                    }
                    return response.json();
                })
                .then(data => { console.log("ojaj", data); })
                .catch(error => {
                    console.log('*DEBUG*-Save AssetEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        /*this.saveNewCategory(false);*/
        if (!this.state.cancel && !_.isEqual(start, this.props.assetdata)) {
            this.setState({ cancel: true });
        }
        else {
            const Id = this.props.assetdata.id;
            this.props.action.getAssetAction(Id)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        if (sessionStorage.getItem("organisationId")) {
                            if (sessionStorage.getItem("locationId"))
                                this.props.history.push({
                                    pathname: '/' + sessionStorage.getItem("organisationId") + '/' + sessionStorage.getItem("locationId") + '/tags',
                                    state: { index: 0 }
                                });
                            else { }
                        }
                        else
                            this.props.history.push({
                                pathname: '/tags',
                                state: { index: 0 }
                            });
                    }
                }).catch(error => {
                    console.log('*DEBUG*-AssetEdit Error', error);
                    if (sessionStorage.getItem("organisationId")) {
                        if (sessionStorage.getItem("locationId"))
                            this.props.history.push({
                                pathname: '/' + sessionStorage.getItem("organisationId") + '/' + sessionStorage.getItem("locationId") + '/tags',
                                state: { index: 0 }
                            });
                        else { }
                    }
                    else
                        this.props.history.push({
                            pathname: '/tags',
                            state: { index: 0 }
                        });
                });
        }

    }
    //saveNewCategory(isFromHandleSave) {
    //    const assetdata = this.props.assetdata;
    //    if (this.state.isNewCategory) {
    //        // new category
    //        assetdata.categoryid = "0";
    //        assetdata.category.id = null;
    //        assetdata.category.name = this.newname;
    //        assetdata.category.parent = this.newparent;
    //        assetdata.category.parentId = this.newparentId;
    //        assetdata.category.location = this.newlocation;
    //        assetdata.category.synced = false;
    //        assetdata.category.deleted = false;
    //        assetdata.category.locationId = sessionStorage.getItem("locationId");
    //        assetdata.category.organizationId = sessionStorage.getItem("organisationId");
    //    } else {
    //        // selected category
    //        if (isFromHandleSave) {
    //            assetdata.category = null;
    //        }

    //    }
    //}
    updateNameValue(evt) {

        this.props.assetdata.name = evt.target.value;
        if (evt.target.value.length > 30) {
            this.tagName_err = "Tag Name can't be longer than 30 character";
        }
        else {
            this.tagName_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }
    updateMACValue(evt) {

        this.props.assetdata.id = evt.target.value;
        if (evt.target.value.length > 30) {
            this.MAC_err = "MAC can't be longer than 30 character";
        }
        else {
            this.MAC_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }
    //updateCategoryName(event) {
    //    this.props.assetdata.categoryid = this.state.options[event.selected].id;
    //    this.setState({
    //        value: event.option,
    //        selected: event.selected,
    //        options: this.categoryfull
    //    });
    //    if (event.option == "No match found") {
    //        event.option = "";
    //    }
    //    if (this.props.assetdata.category) {
    //        this.props.assetdata.category.id = this.props.assetdata.categoryid;
    //    };
    //    this.categoryid_err = "";
    //    if (event.option.length == 0) {
    //        this.categoryid_err = "Select an Option";
    //    }
    //}
    //updateNewCategoryName(event) {
    //    this.newname = event.target.value;
    //    if (this.newname.trim().length == 0) {
    //        this.newname_err = "Category Name is required";
    //    } else {
    //        this.newname_err = "";
    //    }
    //    this.setState({ validating: !this.state.validating });
    //}

    //updateCategoryParent(event) {
    //    if (event.option == "No match found") {
    //        event.option = "";
    //    }
    //    this.newparent = this.parentfull[event.selected].parent;
    //    this.newparentId = this.parentfull[event.selected].id;
    //    this.newlocation = this.parentfull[event.selected].location;
    //    this.setState({
    //        parent: event.option,
    //        parents: this.parentfull
    //    });
    //    this.parent_err = "";
    //    if (event.option.trim().length == 0) {
    //        this.parent_err = "Select an Option";
    //    }
    //}
    readFile(f) {
        if (f) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const data = event.target.result;
                console.log('File', data.length);
                //const base64data = btoa(unescape(encodeURIComponent(data)));
                const base64data = btoa(data);
                //console.log('File Base64:', base64data);
                base64 = base64data;
                //ToDo call API with file.name, and content
                //this.BinaryData = data; 
            };
            reader.readAsBinaryString(f);
        }
    }
    fileChangedHandler = (event) => {
        //console.log(event.target.files[0]);
        const file = event.target.files[0];
        console.log(file);
        if (file != undefined) {
            if (file.size > 1024 * 1024 * 2) {
                this.image_err = "File too large: " + Math.round((file.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.image_err = "";
                this.readFile(file);
                this.setState({ file: file, newimage: true, imagechanged: true });
            }
        }
        else {

        }

    }
    removeIcon() {
        this.props.assetdata.imageUrl = null;
        this.props.assetdata.imageContent = null;
        base64 = null;
        this.setState({ removeIconOpen: false, newimage: false });
    }
    shortcut() {
        if (this.state.file == "") {
            return null;
        }
        else if (this.state.file != null) {
            var Data = [];
            Data.push(this.state.file);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/x-png,image/jpeg" }))
            return (
                <Box direction="row">
                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                    <Image style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("real");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return null;
            //return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }
    onClose() {
        this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            if (sessionStorage.getItem("organisationId")) {
                if (sessionStorage.getItem("locationId"))
                    this.props.history.push({
                        pathname: '/' + sessionStorage.getItem("organisationId") + '/' + sessionStorage.getItem("locationId") + '/tags',
                        state: { index: 0 }
                    });
                else { }
            }
            else
                this.props.history.push({
                    pathname: '/tags',
                    state: { index: 0 }
                });
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    renderValue() {
        if (this.selectedLabels.length == 0) return < Box pad="small" > Select Label</Box >;
        if (this.selectedLabels.length == 1) return < Box pad="small" > {this.selectedLabels[0].name}</Box >;
        if (this.selectedLabels.length > 1) return < Box pad="small" > Multiple Labels</Box >;
    }
    renderTable(data) {
        if (data.length != 0) {
            return (
                <Box>
                    <table>
                        <thead>
                            <tr>
                                {Object.keys(data[0]).map((k, index) => (
                                    <th>
                                        {k}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((info, idx) => (
                                <tr>
                                    {Object.keys(info).map((key, index) => (
                                        <td class="upload">
                                            {info[key]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            );
        }
        return;
    }
    handleSaveBulk() {
        if (this.state.data.length > 0) {
            // Ok, saving data
            this.setState({ disabledButton: true });
            //this.saveNewCategory(true);
            this.state.data.forEach((element, idx) => {
                let assetdata = { ... this.props.assetdata };

                //assetdata.categoryId = null;
                //assetdata.category = null;
                assetdata.departmentId = null;
                assetdata.department = null;
                assetdata.id = element["MAC"];
                assetdata.externalId = element["External Ref ID"];
                assetdata.name = element["Name"];
                console.log('Save: OK', assetdata);
                this.props.action.addAssetAction(assetdata)
                    .then((response) => {
                        if (idx == this.state.data.length - 1) {
                            this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                        }
                        return response.json();
                    })
                    .then(data => { console.log("ojaj", data); })
                    .catch(error => {
                        console.log('*DEBUG*-Save AssetEdit Error', error);
                        this.setState({ disabledButton: false });
                    });
            });
        }
        else {
            this.setState({ validating: true });
        }
    }

    render() {
        const { assetdata, assetlabelsdata, departmentsdata } = this.props;
        const { isNewLabel, isNewDepartment, assetdataLoading, assetlabelsdataLoading, departmentsdataLoading, locationsdataLoading, sitedataLoading } = this.state;

        if (assetdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        const heading = this.state.addType != 2 ? 'Asset Tag - Add' : 'Bulk Upload Tags';
        //const vcategory = this.categoryfull.find((opt) => opt.id == assetdata.categoryid);
        //const vcategoryname = vcategory ? vcategory.name : "";
        //const vcategoryparent = vcategory ? vcategory.parent : "";
        //const vcategorylocation = vcategory ? vcategory.location : "";
        //const vcategoryparentpluslocation = constants.APP_LPN(vcategorylocation, vcategoryparent, vcategoryname);
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    {this.state.addType == 2 ? <Box direction="row" gap="small">
                        <Heading level='2' >{heading}</Heading>
                        <CircleInformation style={{ alignSelf: "center" }} data-for="bulk" data-tip="Add tags in bulk using the template provided." />
                        <ReactTooltip id="bulk" place="right" type="dark" effect="solid" arrowColor="transparent" />
                    </Box> :
                        <Heading level='2' >{heading}</Heading>
                    }
                    {/*<Heading level='2' >{heading}</Heading>*/}
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={() => this.state.addType != 2 ? this.handleSave() : this.handleSaveBulk()} disabled={this.state.disabledButton || this.state.addType == 0 || (this.state.addType == 2 && this.state.data.length == 0)} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" style={{ minHeight: "500px" }}>
                    <form>
                        <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Warning, unable to complete link due to a configuration issue, please contact your admin."} error={!this.state.isSaveSuccessful} />
                        <PopUpConfirm open={this.state.removeIconOpen} text={"Please confirm that you want to delete this image. The master image associated with this site will no longer be visible in the app."}
                            onClose={() => this.setState({ removeIconOpen: false })} onDelete={() => this.removeIcon()} />
                        <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                        {/* <form> */}
                        {this.state.addType == 0 &&
                            <Box>
                                {/*Add Type*/}
                                <StyledBox style1 direction="row" gap="xlarge" pad={{ "vertical": "small", "horizontal": "small" }}>
                                    <Box data-for="tag" data-tip="Add a single tag">
                                        <CheckBox
                                            label="Add Tag"
                                            checked={this.state.addType == 1 ? true : false}
                                            onChange={() => this.setState({ addType: 1 })} />
                                    </Box>
                                    <Box data-for="tags" data-tip="Add multiple tags in bulk">
                                        <CheckBox
                                            label="Add Tags"
                                            checked={this.state.addType == 2 ? true : false}
                                            onChange={() => this.setState({ addType: 2 })} />
                                    </Box>
                                    <ReactTooltip id="tag" place="top" type="dark" effect="solid" arrowColor="transparent" />
                                    <ReactTooltip id="tags" place="top" type="dark" effect="solid" arrowColor="transparent" />
                                </StyledBox>
                            </Box>
                        }
                        {this.state.addType == 1 && <Box>
                            <Box pad={{ vertical: "small" }}> <Text>All fields marked with an * are mandatory.</Text>
                                <Text size="large">Description</Text>
                            </Box>
                            {/* <form> */}
                            <StyledBox style1 pad={{ "horizontal": "small" }}>
                                <FormField label="Tag Name *" error={this.tagName_err}>
                                    <TextInput defaultValue={assetdata.name} placeholder="Tag Name" onChange={evt => this.updateNameValue(evt)} />
                                </FormField>
                                <FormField label="External ID" error={this.externalID_err}>
                                    <TextInput defaultValue={assetdata.externalId} placeholder="External ID" onChange={evt => this.updateNameValue(evt)} />
                                </FormField>
                                <FormField label="MAC *" error={this.MAC_err}>
                                    <TextInput defaultValue={assetdata.id} placeholder="MAC" onChange={evt => this.updateMACValue(evt)} />
                                </FormField>
                            </StyledBox>
                            {/*{false && <Box>*/}
                            {/*    <Box pad={{ vertical: "small" }}>*/}
                            {/*        <Text size="large">Categorise</Text>*/}
                            {/*    </Box>*/}
                            {/*    <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">*/}
                            {/*        {!isNewCategory ? (*/}
                            {/*            <Box direction="row" gap="small">*/}
                            {/*                <Box fill>*/}
                            {/*                    <FormField label="Category Name *" error={this.categoryid_err}>*/}
                            {/*                        <Select width="large"*/}
                            {/*                            placeholder="Select a Category"*/}
                            {/*                            onClose={() => this.setState({ options: this.categoryfull })}*/}
                            {/*                            onSearch={(searchText) => {*/}
                            {/*                                const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');*/}
                            {/*                                const filtered = this.categoryfull.filter(o => o.lpn.match(regexp));*/}
                            {/*                                if (filtered.length > 0) {*/}
                            {/*                                    this.setState({ options: filtered });*/}
                            {/*                                }*/}
                            {/*                                else {*/}
                            {/*                                    this.setState({ options: [{ id: "0", name: "No match found", parent: "", location: "", lpn: "No match found" }] });*/}
                            {/*                                }*/}
                            {/*                            }}*/}
                            {/*                            value={vcategoryparentpluslocation}*/}
                            {/*                            onChange={event => this.updateCategoryName(event)}*/}
                            {/*                            options={options.map((option) => option.lpn)}*/}
                            {/*                            replace={false}*/}
                            {/*                        />*/}
                            {/*                    </FormField>*/}
                            {/*                </Box>*/}
                            {/*                <Box justify="start" pad={{ "top": "45px" }} height="95px">*/}
                            {/*                    <Button plain icon={<Add />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} />*/}
                            {/*                </Box>*/}
                            {/*            </Box>*/}
                            {/*        ) : (*/}
                            {/*                //New Category*/}
                            {/*                <Box>*/}
                            {/*                    <Box fill direction="row" border="bottom" gap="small">*/}
                            {/*                        <Box fill>*/}
                            {/*                            <FormField label="Category Name *" error={this.newname_err}>*/}
                            {/*                                <TextInput placeholder="New Category Name" onChange={evt => this.updateNewCategoryName(evt)} />*/}
                            {/*                            </FormField>*/}
                            {/*                        </Box>*/}
                            {/*                        <Box justify="start" pad={{ "top": "45px" }} height="95px">*/}
                            {/*                            <Button plain icon={<OrderedList />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} />*/}
                            {/*                        </Box>*/}
                            {/*                    </Box>*/}
                            {/*                    <Box>*/}
                            {/*                        <FormField label="App Location" error={this.parent_err}>*/}
                            {/*                            <Select*/}
                            {/*                                placeholder="App Location *"*/}
                            {/*                                onClose={() => this.setState({ parents: this.parentfull })}*/}
                            {/*                                onSearch={(searchText) => {*/}
                            {/*                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');*/}
                            {/*                                    const filtered = this.parentfull.filter(o => o.parentloc.match(regexp))*/}
                            {/*                                    if (filtered.length > 0) {*/}
                            {/*                                        this.setState({ parents: filtered });*/}
                            {/*                                    }*/}
                            {/*                                    else {*/}
                            {/*                                        this.setState({ parents: [{ parent: "No match found", location: "", parentloc: "No match found" }] });*/}
                            {/*                                    }*/}
                            {/*                                }}*/}
                            {/*                                value={parent}*/}
                            {/*                                onChange={event => this.updateCategoryParent(event)}*/}
                            {/*                                options={parents.map((option) => option.parentloc)}*/}
                            {/*                                replace={false}*/}
                            {/*                            />*/}
                            {/*                        </FormField>*/}
                            {/*                    </Box>*/}
                            {/*                </Box>*/}
                            {/*            )}*/}
                            {/*    </StyledBox>*/}
                            {/*</Box>*/}
                            {/*}*/}
                            {/*}
                        <Box>
                            <Box pad={{ vertical: "small" }}>
                                <Text size="large">Department</Text>
                            </Box>
                            <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                {!isNewDepartment ? (
                                    <Box direction="row" gap="small">
                                        <Box fill>
                                            <FormField label="Department Name" >
                                                <Select width="large"
                                                    placeholder="Select a Department"
                                                    //onClose={() => this.setState({ options: this.categoryfull })}
                                                    //onSearch={(searchText) => {
                                                    //    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                    //    const filtered = this.categoryfull.filter(o => o.lpn.match(regexp));
                                                    //    if (filtered.length > 0) {
                                                    //        this.setState({ options: filtered });
                                                    //    }
                                                    //    else {
                                                    //        this.setState({ options: [{ id: "0", name: "No match found", parent: "", location: "", lpn: "No match found" }] });
                                                    //    }
                                                    //}}
                                                    //onChange={event => this.updateCategoryName(event)}
                                                    onChange={o => assetdata.departmentId = o.option.id}
                                                    options={departmentsdata.sort(constants.byPropertyCalled("name"))}
                                                    labelKey="name">
                                                    {option => {
                                                        return (
                                                            <Box pad="small">
                                                                {option.name}
                                                            </Box>);
                                                    }}
                                                </Select>
                                            </FormField>
                                        </Box>
                                        <Box justify="start" pad={{ "top": "45px" }} height="95px">
                                            <Button plain icon={<Add />} onClick={() => { this.setState({ isNewDepartment: !isNewDepartment }) }} />
                                        </Box>
                                    </Box>
                                ) : (
                                        //New Department
                                        <Box>
                                            <Box fill direction="row" border="bottom" gap="small">
                                                <Box fill>
                                                    <FormField label="Department Name" >
                                                        <TextInput placeholder="New Department Name" />
                                                    </FormField>
                                                </Box>
                                                <Box justify="start" pad={{ "top": "45px" }} height="95px">
                                                    <Button plain icon={<OrderedList />} onClick={() => { this.setState({ isNewDepartment: !isNewDepartment }) }} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                            </StyledBox>
                        </Box> */}
                            <Box>
                                <Box pad={{ vertical: "small" }}>
                                    <Text size="large">Label</Text>
                                </Box>
                                <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                    {!isNewLabel ? (
                                        <Box direction="row" gap="small">
                                            <Box fill>
                                                <FormField label="Label Name" >
                                                    <Select width="large"
                                                        placeholder="Select a Label"
                                                        //onClose={() => this.setState({ options: this.categoryfull })}
                                                        //onSearch={(searchText) => {
                                                        //    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                        //    const filtered = this.categoryfull.filter(o => o.lpn.match(regexp));
                                                        //    if (filtered.length > 0) {
                                                        //        this.setState({ options: filtered });
                                                        //    }
                                                        //    else {
                                                        //        this.setState({ options: [{ id: "0", name: "No match found", parent: "", location: "", lpn: "No match found" }] });
                                                        //    }
                                                        //}}
                                                        value=""
                                                        multiple
                                                        onChange={event => {
                                                            if (assetdata.assetBeaconTags) {
                                                                if (assetdata.assetBeaconTags.map(l => l.assetLabelId).includes(event.option.id)) {
                                                                    assetdata.assetBeaconTags = assetdata.assetBeaconTags.filter(l => l.assetLabelId != event.option.id);
                                                                    this.selectedLabels = this.selectedLabels.filter(l => l.id != event.option.id);
                                                                } else {
                                                                    assetdata.assetBeaconTags.push({ "assetLabelId": event.option.id });
                                                                    this.selectedLabels.push(event.option);
                                                                }
                                                            } else {
                                                                assetdata.assetBeaconTags = [{ "assetLabelId": event.option.id }];
                                                                this.selectedLabels.push(event.option);
                                                            }
                                                            this.setState({ label: event.option });
                                                        }}
                                                        options={assetlabelsdata.sort(constants.byPropertyCalled("name"))}
                                                        labelKey="name"
                                                    />
                                                </FormField>
                                            </Box>
                                            <Box justify="end" /*pad={{ "top": "45px" }} height="95px"*/ margin={{ "top": "32px", "bottom": "12px" }} width="100px" direction="row" >
                                                <Button style={{ alignSelf: "center" }} plain icon={<Add />} onClick={() => { this.setState({ isNewLabel: !isNewLabel }) }} />
                                            </Box>
                                        </Box>
                                    ) : (
                                            //New Label
                                            <Box>
                                                <Box fill direction="row" gap="small">
                                                    <Box fill>
                                                        <FormField label="Label Name">
                                                            <TextInput placeholder="New Label Name" onChange={evt => this.setState({ newLabel: evt.target.value.trim() })} />
                                                        </FormField>
                                                    </Box>
                                                    <Box /*height="50px"*/ margin={{ "top": "32px", "bottom": "12px" }} width="100px" direction="row" align="center" >
                                                        <Button plain icon={<FormClose />} onClick={() => { this.setState({ isNewLabel: !isNewLabel }) }} />
                                                        <StyledButton disabled={this.selectedLabels.some(l => l.name.toLowerCase() == this.state.newLabel.toLowerCase()) || this.state.newLabel.trim() == ""} typeAdd label="Add"
                                                            onClick={() => {
                                                                this.selectedLabels.push({ id: "0", name: this.state.newLabel });
                                                                if (assetdata.assetBeaconTags) {
                                                                    assetdata.assetBeaconTags.push({ "assetLabelId": "0", "tag": { "name": this.state.newLabel } });
                                                                } else {
                                                                    assetdata.assetBeaconTags = [{ "assetLabelId": "0", "tag": { "name": this.state.newLabel } }];
                                                                }
                                                                this.setState({ label: event.target.value });
                                                            }} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                </StyledBox>
                                <Box id="tags" direction="column-responsive" pad={{ top: "small" }}>
                                    {this.selectedLabels.map((c, idx) =>
                                        <Button style={{ width: "fit-content" }} id={idx}
                                            onClick={evt => {
                                                assetdata.assetBeaconTags = assetdata.assetBeaconTags.filter(l => l.assetLabelId != c.id);
                                                this.selectedLabels = this.selectedLabels.filter(l => l.id != c.id);
                                                this.setState({ label: event.option });
                                            }}>
                                            <Box
                                                align="center"
                                                background="brand"
                                                pad={{ horizontal: '2px', vertical: '1px' }}
                                                margin="2px"
                                                round="medium"
                                                direction="row"
                                            >
                                                <Text size="xsmall" margin={{ right: 'xxsmall' }} truncate>
                                                    {c.name}
                                                </Text>
                                                <FormClose size="small" color="white" />
                                            </Box>
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                            <Box gap="small">
                                <Box direction="row" gap="small" align="center">
                                    <Text size="large">Image</Text>
                                    <Box data-for="img_info" data-tip>
                                        <CircleInformation />
                                        <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                            <span>Please select a brand approved image to display as your site image.<br />
                                        This will appear on your homepage and be used as a default image for placemarks if no other image is applied. <br />
                                        Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.</span>
                                        </ReactTooltip>
                                    </Box>
                                </Box>
                                <Box direction="row" gap="small">
                                    <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }} width={{ "min": "101px" }} fill={false} margin={{ "bottom": "small" }}>
                                        <FormField label="" error={this.image_err}>
                                            <Box direction="row" justify="center" >
                                                {this.state.newimage ?
                                                    <Box alignSelf="center">
                                                        {this.shortcut()}
                                                    </Box>
                                                    :
                                                    (assetdata.imageUrl == "" || assetdata.imageUrl == null) ?
                                                        <Box height="75px" justify="center">
                                                            <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                            <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />}
                                                                onClick={e => {
                                                                    const c = document.getElementById("real");
                                                                    c.click();
                                                                }} />
                                                        </Box>
                                                        :
                                                        <Box justify="center">
                                                            <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                            <Image height="75" src={`${assetdata.imageUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                onClick={e => {
                                                                    const c = document.getElementById("real");
                                                                    c.click();
                                                                }} />
                                                        </Box>
                                                }
                                            </Box>
                                        </FormField>
                                    </StyledBox>
                                    <Box justify="center" margin={{ "bottom": "small" }}>
                                        <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={((assetdata.imageUrl == "" || assetdata.imageUrl == null) && !this.state.newimage)}>
                                            <Trash />
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>}

                        {this.state.addType == 2 &&
                            <Box gap="small">
                                <Text> Bulk upload tags by browsing to a file on your device.<br />
                                    Download the template file, add your tags information and upload it.</Text>
                                <Box>
                                    <Text size="large">File Upload</Text>
                                    <Box /*border="all"*/ style={{ maxWidth: "600px" }}>
                                        {!this.state.file &&
                                            <Dropzone /*accept=".csv"*/ maxFiles={1} onDrop={acceptedFiles => {
                                                console.log(acceptedFiles);
                                                ///reader start
                                                acceptedFiles.forEach((file) => {
                                                    const reader = new FileReader();
                                                    reader.onprogress = (evt) => {
                                                        this.setState({ progressValue: parseInt(((evt.loaded / evt.total) * 100), 10) });
                                                    };
                                                    reader.onabort = () => console.log('file reading was aborted');
                                                    reader.onerror = () => console.log('file reading has failed');
                                                    reader.onload = () => {
                                                        // Do whatever you want with the file contents
                                                        const binaryStr = reader.result;
                                                        console.log(binaryStr);
                                                    }
                                                    reader.readAsArrayBuffer(file);
                                                })
                                                ///reader end

                                                this.setState({ file: acceptedFiles[0] });
                                                if (acceptedFiles[0].type == "application/vnd.ms-excel"  || acceptedFiles[0].type == "text/csv") {
                                                    Papa.parse(acceptedFiles[0], {
                                                        delimiter: "",
                                                        header: true,
                                                        skipEmptyLines: "greedy",
                                                        //step: (row, parser) => {
                                                        //    console.log("Row data:", row.data);
                                                        //    console.log("Row errors:", row.errors);
                                                        //    this.setState(prevState => ({
                                                        //        data: [...prevState.data, row.data]
                                                        //    }));
                                                        //},
                                                        complete: (results) => {
                                                            console.log(results);
                                                            this.setState({ data: results.data });
                                                        }
                                                    });
                                                }
                                            }}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="upload-container">
                                                        <div {...getRootProps()} className="drop-zone">
                                                            <Box align="center" margin={{ top: "10px" }}>
                                                                <Upload />
                                                            </Box>
                                                            <input {...getInputProps()} />
                                                            <p>Drag and drop or browse to your files</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        }
                                        {this.state.file &&
                                            <Box style={{ maxWidth: "600px" }} /*align="center" border="all"*/ className="upload-container">
                                                {this.state.progressValue == 100 ?
                                                    <DocumentCsv size="medium" />
                                                    :
                                                    <LoadingIndicator />
                                                }
                                                <Text>{this.state.file.name}</Text>
                                                <Box>
                                                    <Meter type="bar" round background="light-3" max={100} values={[{ value: this.state.progressValue, color: "brand" }]} />
                                                    <Box justify="end" direction="row">
                                                        {/*<Text>30byte of 60byte</Text>*/}
                                                        <Text size="small">Upload{this.state.progressValue == 100 ? "ed" : "ing"} {this.state.progressValue}%</Text>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                                {/*<a href='/Images/1.jpg' download>
                                    <Box direction="row" gap="small">
                                        <Download />
                                        Download Template                                   
                                    </Box>
                                    </a>*/}
                                <Box style={{ maxWidth: "600px" }} direction="row" align="center" gap="small">
                                    <CSVLink data={[["Name", "External Ref ID", "MAC"],]} filename="sitetags-template">
                                        <Button plain icon={<Download color={constants.HPE_GREEN} />} data-for="Download Info" data-tip="Export to CSV" />
                                        {/*Download Template*/}
                                    </CSVLink>
                                    Download Template
                                </Box>
                                {this.state.data.length > 0 &&
                                    <Box>
                                        <Text>Select the labels to be assigned to all tags.</Text>
                                        <Box pad={{ vertical: "small" }}>
                                            <Text size="large">Label</Text>
                                        </Box>
                                        <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                            {!isNewLabel ? (
                                                <Box direction="row" gap="small">
                                                    <Box fill>
                                                        <FormField label="Label Name" >
                                                            <Select width="large"
                                                                placeholder="Select a Label"
                                                                //onClose={() => this.setState({ options: this.categoryfull })}
                                                                //onSearch={(searchText) => {
                                                                //    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                //    const filtered = this.categoryfull.filter(o => o.lpn.match(regexp));
                                                                //    if (filtered.length > 0) {
                                                                //        this.setState({ options: filtered });
                                                                //    }
                                                                //    else {
                                                                //        this.setState({ options: [{ id: "0", name: "No match found", parent: "", location: "", lpn: "No match found" }] });
                                                                //    }
                                                                //}}
                                                                value=""
                                                                multiple
                                                                onChange={event => {
                                                                    if (assetdata.assetBeaconTags) {
                                                                        if (assetdata.assetBeaconTags.map(l => l.assetLabelId).includes(event.option.id)) {
                                                                            assetdata.assetBeaconTags = assetdata.assetBeaconTags.filter(l => l.assetLabelId != event.option.id);
                                                                            this.selectedLabels = this.selectedLabels.filter(l => l.id != event.option.id);
                                                                        } else {
                                                                            assetdata.assetBeaconTags.push({ "assetLabelId": event.option.id });
                                                                            this.selectedLabels.push(event.option);
                                                                        }
                                                                    } else {
                                                                        assetdata.assetBeaconTags = [{ "assetLabelId": event.option.id }];
                                                                        this.selectedLabels.push(event.option);
                                                                    }
                                                                    this.setState({ label: event.option });
                                                                }}
                                                                options={assetlabelsdata.sort(constants.byPropertyCalled("name"))}
                                                                labelKey="name"
                                                            />
                                                        </FormField>
                                                    </Box>
                                                    <Box justify="end" /*pad={{ "top": "45px" }} height="95px"*/ margin={{ "top": "32px", "bottom": "12px" }} width="100px" direction="row" >
                                                        <Button style={{ alignSelf: "center" }} plain icon={<Add />} onClick={() => { this.setState({ isNewLabel: !isNewLabel }) }} />
                                                    </Box>
                                                </Box>
                                            ) : (
                                                    //New Label
                                                    <Box>
                                                        <Box fill direction="row" gap="small">
                                                            <Box fill>
                                                                <FormField label="Label Name">
                                                                    <TextInput placeholder="New Label Name" onChange={evt => this.setState({ newLabel: evt.target.value.trim() })} />
                                                                </FormField>
                                                            </Box>
                                                            <Box /*height="50px"*/ margin={{ "top": "32px", "bottom": "12px" }} width="100px" direction="row" align="center" >
                                                                <Button plain icon={<FormClose />} onClick={() => { this.setState({ isNewLabel: !isNewLabel }) }} />
                                                                <StyledButton disabled={this.selectedLabels.some(l => l.name.toLowerCase() == this.state.newLabel.toLowerCase()) || this.state.newLabel.trim() == ""} typeAdd label="Add"
                                                                    onClick={() => {
                                                                        this.selectedLabels.push({ id: "0", name: this.state.newLabel });
                                                                        if (assetdata.assetBeaconTags) {
                                                                            assetdata.assetBeaconTags.push({ "assetLabelId": "0", "tag": { "name": this.state.newLabel } });
                                                                        } else {
                                                                            assetdata.assetBeaconTags = [{ "assetLabelId": "0", "tag": { "name": this.state.newLabel } }];
                                                                        }
                                                                        this.setState({ label: event.target.value });
                                                                    }} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )}
                                        </StyledBox>
                                        <Box id="tags" direction="column-responsive" pad={{ top: "small" }}>
                                            {this.selectedLabels.map((c, idx) =>
                                                <Button style={{ width: "fit-content" }} id={idx}
                                                    onClick={evt => {
                                                        assetdata.assetBeaconTags = assetdata.assetBeaconTags.filter(l => l.assetLabelId != c.id);
                                                        this.selectedLabels = this.selectedLabels.filter(l => l.id != c.id);
                                                        this.setState({ label: event.option });
                                                    }}>
                                                    <Box
                                                        align="center"
                                                        background="brand"
                                                        pad={{ horizontal: '2px', vertical: '1px' }}
                                                        margin="2px"
                                                        round="medium"
                                                        direction="row"
                                                    >
                                                        <Text size="xsmall" margin={{ right: 'xxsmall' }} truncate>
                                                            {c.name}
                                                        </Text>
                                                        <FormClose size="small" color="white" />
                                                    </Box>
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                }
                                {this.renderTable(this.state.data)}
                            </Box>}
                    </form>
                </Box >
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const Id = ownProps.match.params.tagid || '';
    if (state.selectedAssetReducer.assetdata && Id == state.selectedAssetReducer.assetdata.id) {
        start = { ...state.selectedAssetReducer.assetdata };
        //return {
        //    assetdata: state.selectedAssetReducer.assetdata,
        //    assetlabelsdata: state.assetLabelsReducer.assetlabelsdata,
        //    departmentsdata: state.departmentsReducer.departmentsdata,
        //    categoriesdata: state.categoriesReducer.categoriesdata,
        //    locationsdata: state.locationsReducer.locationsdata,
        //    sitedata: state.selectedSiteReducer.sitedata
        //};
    }
    return {
        assetdata: state.selectedAssetReducer.assetdata,
        assetlabelsdata: state.assetLabelsReducer.assetlabelsdata,
        departmentsdata: state.departmentsReducer.departmentsdata,
        /*categoriesdata: state.categoriesReducer.categoriesdata,*/
        locationsdata: state.locationsReducer.locationsdata,
        sitedata: state.selectedSiteReducer.sitedata
    };
    //else {
    //    return {
    //        categoriesdata: state.categoriesReducer.categoriesdata,
    //        locationsdata: state.locationsReducer.locationsdata,
    //        sitedata: state.selectedSiteReducer.sitedata
    //    };
    //}
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...AssetAction, ...AssetLabelsAction, ...DepartmentAction, ...LocationAction, ...SiteAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TagCreateContainer));