import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as BuildingAction from '../action/BuildingAction';
import * as LevelAction from '../action/LevelAction';
import * as AssetAction from '../action/AssetAction';
import * as AssetsOverviewAction from '../action/AssetsOverviewAction';
import * as AssetLabelsAction from '../action/AssetLabelsAction';
import * as DepartmentAction from '../action/DepartmentAction';
import * as TrackerAction from '../action/TrackerAction';
import * as AssociationAction from '../action/AssociationAction';
import * as CollectionAction from '../action/CollectionAction';
import * as ManufacturerAction from '../action/ManufacturerAction';
import * as ModelAction from '../action/ModelAction';
import * as TypeAction from '../action/TypeAction';
import * as MetaDataAction from '../action/MetaDataAction';
import TrackerListTable from './TrackerListTable';
import MetadataListTable from './MetadataListTable';
import AssociationListTable from './AssociationListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text, Tab, Tabs, Grid, Select, RadioButton, TextInput } from 'grommet';
import { CircleInformation, Edit, Trash, Blank } from 'grommet-icons';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import constants from '../constants';
import PopUpConfirm from './common/PopUpConfirm';
import PopUp from './common/PopUp';
import ReactTooltip from "react-tooltip";
import RB from './common/RBAC';
import { Context } from './Context';

export class TrackerListContainer extends React.Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEditTracker = this.handleEditTracker.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleBulkEdit = this.handleBulkEdit.bind(this);
        this.filter = "";
        this.metadata_err = {
            manufacturer: '',
            model: '',
            deviceType: ''
        };
        this.state = {
            selectedTracker: 0,
            openTracker: undefined,
            deletedAssociationId: [],
            deletedTrackerIds: [],
            selectedAssociation: "",
            openAssociation: undefined,
            open: undefined,
            tabindex: props.location.state ? props.location.state.index : 0,

            selectedTrackers: [],
            openTrackers: undefined,
            openEdit: undefined,
            isDisabled: false,
            class: "",
            updateC: false,
            type: "",
            updateT: false,
            department: "",
            updateD: false,
            collection: "",
            updateCl: false,

            isSaved: false,
            isSaveSuccessful: false,

            select: [],
            buildingsdataLoading: true,
            levelsdataLoading: true,
            assetsdataLoading: true,
            assetsoverviewdataLoading: true,
            departmentsdataLoading: true,
            trackersdataLoading: true,
            metadatasdataLoading: true,
            associationsdataLoading: true,
            collectionsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }
    componentWillReceiveProps(props) {
        console.log("filter", props);
        if (props.location.state && !this.filter) {
            this.filter = props.location.state.filter;
        }
    }

    componentWillMount() {
        var index = this.props.location.state ? this.props.location.state.index : 0;
        this.setState({ tabindex: index });
    }

    componentDidMount() {
        this.props.action.getBuildingsAction()
            .then(response => this.setState({ buildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
        this.props.action.getLevelsAction()
            .then(response => this.setState({ levelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getAssetsOverviewAction()
            .then(response => this.setState({ assetsoverviewdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetsOverviewAction List Error', error);
            });
        this.props.action.getDepartmentsAction()
            .then(response => this.setState({ departmentsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department List Error', error);
            });
        this.props.action.getTrackersAction()
            .then(response => this.setState({ trackersdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Tracker List Error', error);
            });
        this.props.action.getMetadatasAction()
            .then(response => this.setState({ metadatasdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Metadatas List Error', error);
            });
        this.props.action.getAssociationsAction()
            .then(response => this.setState({ associationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Tracker List Error', error);
            });
        this.props.action.getCollectionsAction()
            .then(response => this.setState({ collectionsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Collection List Error', error);
            });
        this.props.action.getManufacturersAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Manufacturer List Error', error);
            });
        this.props.action.getModelsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Model List Error', error);
            });
        this.props.action.getTypesAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- DeviceType List Error', error);
            });
    }
    handleBulkEdit(ids) {
        this.setState({ selectedTrackers: ids, openEdit: true });
    }

    handleDelete(type, nro) {
        console.log(`*Delete* started on ${type}`, nro);
        switch (type) {
            case 'tracker':
                this.setState({ selectedTracker: nro, openTracker: true });
                break;
            case 'trackers':
                this.setState({ selectedTrackers: nro, openTrackers: true });
                break;
            case 'association':
                this.setState({ selectedAssociation: nro, openAssociation: true });
                break;
            default:
                break;
        }
    }

    trackerDelete() {
        const selectedId = this.state.selectedTracker;

        if (selectedId) {
            this.setState({ selectedTracker: 0, openTracker: undefined });
            this.setState({ deletedTrackerIds: this.state.deletedTrackerIds.concat([selectedId]) });
            this.props.action.deleteTrackerAction(selectedId)
                .then(response => {
                    this.props.action.getAssociationsAction()
                        .then(response => this.setState({ associationsdataLoading: false }))
                        .catch(error => {
                            var errorArray = this.state.apiErrorList.concat(error);
                            this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                            console.log('*DEBUG*- Tracker List Error', error);
                        });
                })
                .catch(error => {
                    console.log('*DEBUG*- Asset List Delete Error', error);
                });
            this.count--;
            this.setState({ select: this.state.select.filter(id => id != selectedId) });
        }
    }

    associationDelete() {
        const selected = this.state.selectedAssociation;

        if (selected) {
            this.setState({ selectedAssociation: "", openAssociation: undefined });
            const selectedId = selected.assetId;
            this.setState({ deletedAssociationId: this.state.deletedAssociationId.concat([selectedId]) });
            this.handleSave(selected);
        }
    }

    trackersDelete() {
        const selected = this.state.selectedTrackers;

        if (selected) {
            this.setState({ selectedTrackers: [], openTrackers: undefined, select: [] });
            selected.forEach((i, index) => {
                this.props.action.deleteTrackerAction(i)
                    .then(r => {
                        if (index == selected.length - 1) {
                            this.props.action.getAssociationsAction()
                                .then(response => this.setState({ associationsdataLoading: false }))
                                .catch(error => {
                                    var errorArray = this.state.apiErrorList.concat(error);
                                    this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                                    console.log('*DEBUG*- Tracker List Error', error);
                                });
                        }
                    })
                    .catch(error => {
                        console.log('*DEBUG*- Tracker List Delete Error', error);
                    });
            });
        }
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });
    onCloseTracker = () => this.setState({ openTracker: undefined });
    onCloseAssociation = () => this.setState({ openAssociation: undefined });

    onCloseTrackers = () => this.setState({ openTrackers: undefined });
    onCloseBulkEdit = () => this.setState({
        class: "",
        updateC: false,
        type: "",
        updateT: false,
        department: "",
        updateD: false,
        collection: "",
        updateCl: false,
        openEdit: false
    });
    handleNew() {
        if (this.state.tabindex == 0) {
            if (sessionStorage.getItem("organisationId")) {
                if (sessionStorage.getItem("locationId"))
                    this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/' + sessionStorage.getItem("locationId") + '/tracker/0');
                else { }
            }
            else
                this.props.history.push('/tracker/0');
        }
        if (this.state.tabindex == 2) {
            if (sessionStorage.getItem("organisationId")) {
                if (sessionStorage.getItem("locationId"))
                    this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/' + sessionStorage.getItem("locationId") + '/link/0');
                else { }
            }
            else
                this.props.history.push('/link/0');
        }
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push('/asset/' + nro);
    }
    handleEditTracker(nro = 0) {
        if (sessionStorage.getItem("organisationId")) {
            if (sessionStorage.getItem("locationId"))
                this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/' + sessionStorage.getItem("locationId") + '/tracker/' + nro);
            else { }
        }
        else
            this.props.history.push('/tracker/' + nro);
    }
    //handleEditLabel(nro = 0) {
    //    this.props.history.push('/label/' + nro);
    //}
    //handleEditAsset(nro = 0) {
    //    this.props.history.push('/asset/' + nro);
    //}
    onActive(idx) {
        this.setState({ tabindex: idx });
    }
    handleSave(association) {
        this.props.action.saveAssociationAction(association)
            .then((response) => {
                if (association.assetId != null) {
                    //it means we are creating a link, not deleting
                    this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                }
            })
            .catch(error => {
                console.log('*DEBUG*-Save Association Error', error);
            });
    }
    onChangeSelect = (select, count) => {
        this.count = count;
        this.setState({ select: select });
    }
    updateMetadata(value, name, nameId = "name") {
        if (value.length > 0) {
            let list = [];
            let exist = null;
            switch (name) {
                case "manufacturer":
                    list = this.props.manufacturersdata;
                    exist = list.length > 0 ? list.find(item => item.name.toLowerCase() == value.toLowerCase()) : null;
                    if (exist) {
                        //this.props.trackerdata[nameId] = exist.id;
                        //this.props.trackerdata[name] = exist;
                        this.setState({ manufacturerId: exist.id, manufacturer: exist });
                    } else {
                        //this.props.trackerdata[nameId] = 0;
                        //this.props.trackerdata[name] = {
                        //    id: 0,
                        //    name: value
                        //};
                        this.setState({ manufacturerId: 0, manufacturer: {id: 0, name: value} });
                    }
                    break;
                case "model":
                    list = this.props.modelsdata;
                    exist = list.length > 0 ? list.find(item => item.name.toLowerCase() == value.toLowerCase()) : null;
                    if (exist) {
                        this.setState({ modelId: exist.id, model: exist });
                    } else {
                        this.setState({ modelId: 0, model: { id: 0, name: value } });
                    }
                    break;
                case "deviceType":
                    list = this.props.typesdata;
                    exist = list.length > 0 ? list.find(item => item.name.toLowerCase() == value.toLowerCase()) : null;
                    if (exist) {
                        this.setState({ deviceTypeId: exist.id, deviceType: exist });
                    } else {
                        this.setState({ deviceTypeId: 0, deviceType: { id: 0, name: value } });
                    }
                    break;
                default:
                    break;
            }
        } else {
            //TODO
            //this.props.trackerdata[name] = null;
            //this.props.trackerdata[nameId] = null;
            if (name == "manufacturer") this.setState({ manufacturerId: null, manufacturer: null});
            if (name = "mode") this.setState({ modelId: null, model: null});
            if (name = "deviceType") this.setState({ deviceTypeId: null, deviceType: null});
        }

        if (value.length > 30) {
            let displayName = "";
            if (name == "deviceType")
                displayName = "Model Type";
            else {
                displayName = name.charAt(0).toUpperCase() + name.slice(1);
            }
            this.metadata_err[name] = `${displayName} can't be longer than 30 character`;
        }
        else {
            this.metadata_err[name] = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    getSuggestionOf(name, list) {
        if (list.length == 0)
            return [];
        else {
            list.sort(constants.byPropertyCalled("name"));
            if (this.state[name]) {
                return list.filter(d => d.name.toLowerCase().includes(this.state[name].name.toLowerCase())).map(l => l.name);
            }
            //return list;
            return list.map(item => item.name);
        }
    }

    resetMetaData(property) {
        this.metadata_err[property] = "";
        if (property == "manufacturer") this.setState({ manufacturerId: null, manufacturer: null });
        if (property = "mode") this.setState({ modelId: null, model: null });
        if (property = "deviceType") this.setState({ deviceTypeId: null, deviceType: null });
    }

    render() {
        const { levelsdata, assetsdata, buildingsdata, assetsoverviewdata, departmentsdata, trackersdata, associationsdata, collectionsdata, manufacturersdata, modelsdata, typesdata, metadatas } = this.props;
        const { tabindex, openTracker, deletedTrackerIds, openAssociation, openTrackers, deletedAssociationId,
            buildingsdataLoading, levelsdataLoading, assetsdataLoading, assetsoverviewdataLoading, departmentsdataLoading, trackersdataLoading, metadatasdataLoading, associationsdataLoading, collectionsdataLoading } = this.state;

        if (assetsoverviewdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        console.log("propi:", this.props);
        levelsdata.sort((p1, p2) => { if (p1.meridianName.toLowerCase() < p2.meridianName.toLowerCase()) return -1; return 1; });
        this.props.buildingsdata.sort((p1, p2) => { if (p1.meridianName.toLowerCase() < p2.meridianName.toLowerCase()) return -1; return 1; });

        return (
            <Grid
                fill={true}
                rows={['xsmall', 'flex', this.state.tabindex == 0 && this.state.select.length > 0 ? '60px' : '5px']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                    { name: 'footer', start: [0, 2], end: [1, 2] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    {tabindex == 0 && <Box direction="row" gap="small">
                        <Heading level='2' >Trackers</Heading>
                        <Box justify="center" alignSelf="center" pad={{ "top": "small" }} fill="false">
                            <a data-for="trackers" data-tip="Items to be tracked." >
                                <CircleInformation />
                            </a>
                            <ReactTooltip id="trackers" place="right" type="dark" effect="solid" arrowColor="transparent" />
                        </Box>
                    </Box>}
                    {tabindex == 1 && <Box direction="row" gap="small" justify="center">
                        <Heading level='2' >Metadata</Heading>
                    </Box>}
                    {tabindex == 2 && <Box direction="row" gap="small">
                        <Heading level='2' >Links</Heading>
                        <Box justify="center" alignSelf="center" pad={{ "top": "small" }} fill="false">
                            <a data-for="links" data-tip="Link an unassigned Tracker to a Tag." >
                                <CircleInformation />
                            </a>
                            <ReactTooltip id="links" place="right" type="dark" effect="solid" arrowColor="transparent" />
                        </Box>
                    </Box>}
                    {/*<StyledButton typeSave label='Export to CSV' alignSelf="center" onClick={evt => this.handleExport(evt)} />*/}
                    {/*<Text>
                        Last updated at: {sync}
                    </Text>*/}
                    {(tabindex == 0 || tabindex == 2) && <Box /*width="140px"*/>
                        <StyledButton typeSave label="Create New"
                            onClick={() => this.handleNew()} />
                    </Box>}
                </Box>
                <PopUp open={this.state.isSaved} onClose={() => this.setState({ isSaved: false })} text={this.state.isSaveSuccessful ? "Save was successful." : "Warning, unable to complete link due to a configuration issue, please contact your admin."} error={!this.state.isSaveSuccessful} />
                <PopUpConfirm open={openTracker} text={constants.confirmMessage("tracker")} onClose={() => this.onCloseTracker()} onDelete={() => this.trackerDelete()} />
                <PopUpConfirm open={openAssociation} text={constants.confirmMessage("link")} onClose={() => this.onCloseAssociation()} onDelete={() => this.associationDelete()} />

                <PopUpConfirm open={openTrackers} text={constants.confirmMessage("trackers")} onClose={() => this.onCloseTrackers()} onDelete={() => this.trackersDelete()} />

                <Box gridArea='main' overflow="auto" background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium" }} /*fill="vertical"*/>
                    <Box flex justify='between' fill pad={{ "top": "medium" }}>
                        <Tabs flex justify="start" activeIndex={this.state.tabindex} onActive={(i) => this.onActive(i)}>
                            <Tab title="Trackers">
                                <TrackerListTable
                                    trackersdata={trackersdata} handleEdit={this.handleEditTracker} departmentsdata={departmentsdata} collectionsdata={collectionsdata}
                                    associationsdata={associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "")}
                                    manufacturersdata={manufacturersdata} modelsdata={modelsdata} typesdata={typesdata}
                                    deletedTrackerIds={deletedTrackerIds} handleDelete={this.handleDelete} handleBulkEdit={this.handleBulkEdit}
                                    onChangeSelect={this.onChangeSelect} select={this.state.select} />


                                {this.state.openEdit &&
                                    <Layer
                                        position="center"
                                        modal={true}
                                        onClickOutside={this.onCloseBulkEdit}
                                        onEsc={this.onCloseBulkEdit}
                                    >
                                        <Box pad="medium" gap="small" width="500px" >
                                            <Box direction="row" gap="small">
                                                <Heading level={3} margin="none">
                                                    Edit Tracker
                                            </Heading>
                                                <Box justify="end">
                                                    <a data-for="bulkedit" data-tip="Existing value is kept if no change is made" >
                                                        <CircleInformation />
                                                    </a>
                                                    <ReactTooltip id="bulkedit" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                                                </Box>
                                            </Box>
                                            <Box gap="small">
                                                <Box>
                                                    <Box justify="between" direction="row" /*gap="small"*/>
                                                        <Select style={{ width: "350px" }} placeholder="Class" options={this.context.feature.find(f => f.name == "sitePersonTracking").isEnabled ? ["Person", "Asset"] : ["Asset"]} value={this.state.class} onChange={evt => this.setState({ class: evt.option, updateC: true })} />
                                                        <Box data-tip="Select to reset this value" data-for="classid" alignSelf="center">
                                                            <RadioButton checked={this.state.class == "" && this.state.updateC} onChange={() => this.setState({ class: "" })} />
                                                            <ReactTooltip id="classid" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                                        </Box>

                                                    </Box>
                                                    {this.state.class == "" && this.state.updateC &&
                                                        <Text size="xsmall"> {constants.removeText("class")}</Text>
                                                    }
                                                </Box>
                                                {this.state.class == "Asset" && <Box gap="small">
                                                <Box>
                                                    <Box justify="between" direction="row" /*gap="small"*/>
                                                        <TextInput
                                                            style={{ width: "400px" }}
                                                            placeholder="Manufacturer"
                                                            value={this.state.manufacturer ? this.state.manufacturer.name : ""}
                                                            onChange={evt => this.updateMetadata(evt.target.value, "manufacturer", "manufacturerId")}
                                                            onSuggestionSelect={evt => this.updateMetadata(evt.suggestion, "manufacturer", "manufacturerId")}
                                                            suggestions={this.getSuggestionOf("manufacturer", this.props.manufacturersdata)}/>
                                                        <Box data-tip="Select to reset this value" data-for="manufacturerid" alignSelf="center">
                                                            <RadioButton checked={this.state.manufacturer == "" && this.state.updateM} onChange={() => { this.resetMetaData("manufacturer"); /*this.setState({ manufacturer: "" });*/ } } />
                                                            <ReactTooltip id="manufacturerid" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                                        </Box>

                                                    </Box>
                                                    {this.state.manufacturer == "" && this.state.updateM &&
                                                        <Text size="xsmall"> {constants.removeText("manufacturer")}</Text>
                                                    }
                                                    <Text color="#FF4040" size="xsmall"> {this.metadata_err.manufacturer}</Text>
                                                </Box>
                                                <Box>
                                                    <Box justify="between" direction="row" /*gap="small"*/>
                                                        <TextInput
                                                            style={{ width: "400px" }}
                                                            placeholder="Model"
                                                            value={this.state.model ? this.state.model.name : ""}
                                                            onChange={evt => this.updateMetadata(evt.target.value, "model", "modelId")}
                                                            onSuggestionSelect={evt => this.updateMetadata(evt.suggestion, "model", "modelId")}
                                                            suggestions={this.getSuggestionOf("model", this.props.modelsdata)} />
                                                        <Box data-tip="Select to reset this value" data-for="modelid" alignSelf="center">
                                                            <RadioButton checked={this.state.model == "" && this.state.updateC} onChange={() => this.resetMetaData("model")} />
                                                            <ReactTooltip id="modelid" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                                        </Box>

                                                    </Box>
                                                    {this.state.model == "" && this.state.updateC &&
                                                        <Text size="xsmall"> {constants.removeText("model")}</Text>
                                                    }
                                                    <Text color="#FF4040" size="xsmall"> {this.metadata_err.model}</Text>
                                                </Box>
                                                <Box>
                                                    <Box justify="between" direction="row" /*gap="small"*/>
                                                        <TextInput
                                                            style={{ width: "400px" }}
                                                            placeholder="Model Type"
                                                            value={this.state.deviceType ? this.state.deviceType.name : ""}
                                                            onChange={evt => this.updateMetadata(evt.target.value, "deviceType", "deviceTypeId")}
                                                            onSuggestionSelect={evt => this.updateMetadata(evt.suggestion, "deviceType", "deviceTypeId")}
                                                            suggestions={this.getSuggestionOf("deviceType", this.props.typesdata)} />
                                                        <Box data-tip="Select to reset this value" data-for="modeltypeid" alignSelf="center">
                                                            <RadioButton checked={this.state.deviceType == "" && this.state.updateC} onChange={() => this.setState({ deviceType: "" })} />
                                                            <ReactTooltip id="modeltypeid" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                                        </Box>

                                                    </Box>
                                                    {this.state.deviceType == "" && this.state.updateC &&
                                                        <Text size="xsmall"> {constants.removeText("model type")}</Text>
                                                    }
                                                    <Text color="#FF4040" size="xsmall"> {this.metadata_err.deviceType}</Text>
                                                </Box>
                                                </Box>}
                                                <Box>
                                                    <Box justify="between" direction="row">
                                                        <Select style={{ width: "350px" }} placeholder="Type" options={this.state.class == "" ? [] : this.state.class == "Person" ? ["Employee", "Visitor", "Contractor", "Other"] : ["Critical"]} value={this.state.type} onChange={evt => this.setState({ type: evt.option, updateT: false })} />
                                                        <Box data-tip="Select to reset this value" data-for="typeid" alignSelf="center">
                                                            <RadioButton checked={this.state.type == "" && this.state.updateT} onChange={() => this.setState({ type: "", updateT: true })} />
                                                            <ReactTooltip id="typeid" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                                        </Box>
                                                    </Box>
                                                    {this.state.type == "" && this.state.updateT &&
                                                        <Text size="xsmall"> {constants.removeText("type")}</Text>
                                                    }
                                                </Box>
                                                <Box>
                                                    <Box justify="between" direction="row">
                                                        <Select style={{ width: "350px" }} placeholder="Collection" options={collectionsdata} labelKey="name" value={this.state.collection} onChange={evt => this.setState({ collection: evt.option, updateCl: false })} />
                                                        <Box data-tip="Select to reset this value" data-for="collectionid" alignSelf="center">
                                                            <RadioButton checked={this.state.collection == "" && this.state.updateCl} onChange={() => this.setState({ collection: "", updateCl: true })} />
                                                            <ReactTooltip id="collectionid" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                                        </Box>

                                                    </Box>
                                                    {this.state.collection == "" && this.state.updateCl &&
                                                        <Text size="xsmall"> {constants.removeText("collection")}</Text>
                                                    }
                                                </Box>
                                                <Box>
                                                    <Box justify="between" direction="row">
                                                        <Select style={{ width: "350px" }} placeholder="Department" options={departmentsdata} labelKey="name" value={this.state.department} onChange={evt => this.setState({ department: evt.option, updateD: false })} />
                                                        <Box data-tip="Select to reset this value" data-for="departmentid" alignSelf="center">
                                                            <RadioButton checked={this.state.department == "" && this.state.updateD} onChange={() => this.setState({ department: "", updateD: true })} />
                                                            <ReactTooltip id="departmentid" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                                        </Box>

                                                    </Box>
                                                    {this.state.department == "" && this.state.updateD &&
                                                        <Text size="xsmall"> {constants.removeText("department")}</Text>
                                                    }
                                                </Box>
                                            </Box>
                                            <Box
                                                tag="footer"
                                                gap="small"
                                                direction="row"
                                                align="center"
                                                justify="end"
                                                pad={{ top: "medium", bottom: "small" }}

                                            >
                                                <StyledButton
                                                    onClick={() => {
                                                        this.setState({ isDisabled: true });
                                                        var trackers = trackersdata.filter(t => this.state.selectedTrackers.includes(t.id));
                                                        trackers.forEach((tracker, i) => {
                                                            if (this.state.class)
                                                                tracker.class = this.state.class;
                                                            //Set Metadata only if class == Asset
                                                            if (this.state.class && this.state.class == "Asset") {
                                                                if (this.state.manufacturer || this.state.manufacturer == null/* && this.state.manufacturer.id != 0*/) {
                                                                    tracker.manufacturer = this.state.manufacturer;
                                                                    tracker.manufacturerId = this.state.manufacturerId;
                                                                }
                                                                //else if (this.state.manufacturer && this.state.manufacturer.id == 0) {
                                                                //}
                                                                //else {
                                                                //}
                                                                if (this.state.model || this.state.model == null) {
                                                                    tracker.model = this.state.model;
                                                                    tracker.modelId = this.state.modelId;
                                                                }
                                                                if (this.state.deviceType || this.state.deviceType == null) {
                                                                    tracker.deviceType = this.state.deviceType;
                                                                    tracker.deviceTypeId = this.state.deviceTypeId;
                                                                }
                                                            }
                                                            else if (this.state.class && this.state.class == "Person") {
                                                                tracker.manufacturer = null;
                                                                tracker.manufacturerId = null;
                                                                tracker.model = null;
                                                                tracker.modelId = null;
                                                                tracker.deviceType = null;
                                                                tracker.deviceTypeId = null;
                                                            }


                                                            if (this.state.updateT) {
                                                                tracker.type = null;
                                                            }
                                                            else if (this.state.type) {
                                                                tracker.type = this.state.type;
                                                            }

                                                            if (this.state.updateD) {
                                                                tracker.departmentId = null;
                                                            }
                                                            else if (this.state.department) {
                                                                tracker.departmentId = this.state.department.id;
                                                            } else {
                                                                tracker.departmentId = tracker.departmentId != 0 ? tracker.departmentId : null;
                                                            }
                                                            tracker.department = null;

                                                            if (this.state.updateCl) {
                                                                tracker.collectionId = null;
                                                            }
                                                            else if (this.state.collection) {
                                                                tracker.collectionId = this.state.collection.id;
                                                            }
                                                            else {
                                                                tracker.collectionId = tracker.collectionId != 0 ? tracker.collectionId : null;
                                                            }
                                                            tracker.collection = null;

                                                            tracker.category = null;
                                                            tracker.categoryId = null;

                                                            console.log("Saving", tracker);
                                                            this.props.action.saveTrackerAction(tracker)
                                                                .then((response) => {
                                                                    if (i == trackers.length - 1) {
                                                                        this.setState({
                                                                            openEdit: !response.ok,
                                                                            isDisabled: false,
                                                                            class: "",
                                                                            updateC: false,
                                                                            type: "",
                                                                            updateT: false,
                                                                            department: "",
                                                                            updateD: false,
                                                                            collection: "",
                                                                            updateCl: false,
                                                                        });
                                                                        this.props.action.getTrackersAction()
                                                                            .then(reponse => this.setState({ done: true }))
                                                                            .catch(error => {
                                                                                console.log('*DEBUG*- Tracker List Error', error);
                                                                            });
                                                                    }
                                                                }).catch(error => {
                                                                    console.log('*DEBUG*-Save TrackerEdit Error', error);
                                                                    //this.setState({ disabledButton: false });
                                                                });
                                                        });
                                                    }}
                                                    typeSave
                                                    label="Apply"
                                                    disabled={this.state.isDisabled}
                                                //    onClick={() => {
                                                //        if (this.props.clickonce) {
                                                //            this.setState({ isDisabled: true });
                                                //        }
                                                //        this.props.onDelete();
                                                //}}

                                                />
                                                <StyledButton typeCancel label="Cancel" onClick={this.onCloseBulkEdit} />
                                            </Box>
                                        </Box>
                                    </Layer>
                                }
                            </Tab>
                            <Tab title="Metadata">
                                <MetadataListTable metadatas={metadatas} trackersdata={trackersdata} />
                            </Tab>
                            <Tab title="Links">
                                <AssociationListTable handleSave={this.handleSave} associationsdata={associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "")} assetsdata={assetsdata} trackersdata={trackersdata} levelsdata={levelsdata.filter(f => f.meridianId)} buildingsdata={buildingsdata}
                                    handleDelete={this.handleDelete} handleEdit={this.handleEdit} deletedAssociationId={deletedAssociationId} />
                            </Tab>
                        </Tabs>
                    </Box>
                </Box>
                <Box gridArea='footer' background={constants.BACKGROUND_COLOR} /*pad={{ "horizontal": "medium" }}*/>
                    {this.state.tabindex == 0 && this.state.select.length > 0 && <Box background="light-4" width="100%" justify="between" direction="row" pad="small" /*style={{ borderRadius: "5px 5px 5px 5px", boxShadow: "2px 2px 4px 2px rgba(100, 100, 100, 0.35)" }}*/>
                        <Box direction="row" gap="medium">
                            <Box>
                                {this.state.select.length} of {this.count} selected
                    </Box>
                            <Box border={{ side: "bottom", size: "small", color: "black" }} onClick={() => this.setState({ select: [] })}>
                                Clear
                    </Box>
                        </Box>
                        {!RB.canEdit(constants.PERMS.LOC_CONTENT) ?
                            <Box direction='row'>
                                <Button plain style={{ cursor: 'inherit' }} icon={<Blank />} />
                            </Box>
                            : <Box direction='row' gap="xsmall">
                                <ReactTooltip id="edit" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                <ReactTooltip id="delete" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                <Button plain /*disabled={props.deletedTrackerIds.includes(t.id)}*/ onClick={() => this.handleBulkEdit(this.state.select)} icon={<Edit />} data-for="edit" data-tip="Edit" />
                                <Button plain /*disabled={props.deletedTrackerIds.includes(t.id)}*/ onClick={() => this.handleDelete("trackers", this.state.select)} icon={<Trash />} data-for="delete" data-tip="Delete" />
                            </Box>
                        }
                    </Box>}
                </Box>
            </Grid>
        );
    }
}



const mapStateToProps = state => ({
    buildingsdata: state.buildingsReducer.buildingsdata,
    levelsdata: state.levelsReducer.levelsdata,
    assetsdata: state.assetsReducer.assetsdata,
    assetsoverviewdata: state.assetsOverviewReducer.assetsoverviewdata,
    departmentsdata: state.departmentsReducer.departmentsdata,
    trackersdata: state.trackersReducer.trackersdata,
    associationsdata: state.associationsReducer.associationsdata,
    collectionsdata: state.collectionsReducer.collectionsdata,
    manufacturersdata: state.manufacturersReducer.manufacturersdata,
    modelsdata: state.modelsReducer.modelsdata,
    typesdata: state.typesReducer.typesdata,
    metadatas: state.metadatasReducer.metadatas
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...LevelAction, ...AssetAction, ...BuildingAction, ...AssetsOverviewAction, ...DepartmentAction, ...TrackerAction, ...AssociationAction, ...CollectionAction, ...ManufacturerAction, ...ModelAction, ...TypeAction, ...MetaDataAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(TrackerListContainer);
