import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PageAction from '../action/PageAction';
import * as PlacemarkAction from '../action/PlacemarkAction';
import * as AppAction from '../action/AppAction';
import * as FormAction from '../action/FormAction';
import * as SiteAction from '../action/SiteAction';
import * as LevelAction from '../action/LevelAction';
import * as BuildingAction from '../action/BuildingAction';
import PageListTable from './PageListTable';
import PlacemarkListTable from './PlacemarkListTable';
import AppListTable from './AppListTable';
import FormListTable from './FormListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text, Tabs, Tab, Grid } from 'grommet';
import constants from '../constants';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpConfirm from './common/PopUpConfirm';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';


export class PageListContainer extends React.Component {

    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteApp = this.handleDeleteApp.bind(this);
        this.handleDeleteFrm = this.handleDeleteFrm.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.handleInfo = this.handleInfo.bind(this);
        this.handleEditPl = this.handleEditPl.bind(this);
        this.handleInfoPl = this.handleInfoPl.bind(this);
        this.handleEditApp = this.handleEditApp.bind(this);
        this.handleInfoApp = this.handleInfoApp.bind(this);
        this.handleEditFrm = this.handleEditFrm.bind(this);
        this.handleInfoFrm = this.handleInfoFrm.bind(this);
        this.state = {
            selectedPage: 0,
            selectedApp: 0,
            selectedForm: 0,
            deletedPageIds: [],
            deletedAppIds: [],
            deletedFormIds: [],
            open: undefined,
            openApp: undefined,
            openForm: undefined,
            info: undefined,
            infoPl: undefined,
            infoApp: undefined,
            infoFrm: undefined,
            tabindex: 0,
            pagesdataLoading: true,
            placemarksdataLoading: true,
            appsdataLoading: true,
            formsdataLoading: true,
            sitesdataLoading: true,
            levelsdataLoading: true,
            buildingsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }

    componentDidMount() {
        //console.log('Tab:', this.props.match.params.tab);
        let index = parseInt(this.props.match.params.tab, 10) || 0;
        if (index >= 4) {
            index = 0
        };
        this.setState({ tabindex: index });
        this.props.action.getPagesAction()
            .then(response => this.setState({ pagesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Pages List Error', error);
            });
        this.props.action.getPlacemarksAction()
            .then(response => this.setState({ placemarksdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Placemarks List Error', error);
            });
        this.props.action.getAppsAction()
            .then(response => this.setState({ appsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Apps List Error', error);
            });
        this.props.action.getFormsAction()
            .then(response => this.setState({ formsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Forms List Error', error);
            });
        this.props.action.getSiteAction(sessionStorage.getItem("locationId"))
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getLevelsAction()
            .then(response => this.setState({ levelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getBuildingsAction()
            .then(response => this.setState({ buildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
    }

    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedPage: nro, open: true });
        //this.onOpen();
    }
    handleDeleteApp(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on App ', nro);
        this.setState({ selectedApp: nro, openApp: true });
        //this.onOpen();
    }
    handleDeleteFrm(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on Form ', nro);
        this.setState({ selectedForm: nro, openForm: true });
        //this.onOpen();
    }

    eventDelete(event) {
        const selectedId = this.state.selectedPage;

        if (selectedId) {
            this.setState({ selectedPage: 0, open: undefined });
            //this.setState({ deletedPageId: selectedId });
            this.setState({ deletedPageIds: this.state.deletedPageIds.concat([selectedId]) });
            this.props.action.deletePageAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- Page List Delete Error', error);
                });
        }
    }
    eventDeleteApp(event) {
        const selectedId = this.state.selectedApp;

        if (selectedId) {
            this.setState({ selectedApp: 0, openApp: undefined });
            //this.setState({ deletedPageId: selectedId });
            this.setState({ deletedAppIds: this.state.deletedAppIds.concat([selectedId]) });
            this.props.action.deleteAppAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- App List Delete Error', error);
                });
        }
    }
    eventDeleteFrm(event) {
        const selectedId = this.state.selectedForm;

        if (selectedId) {
            this.setState({ selectedForm: 0, openForm: undefined });
            //this.setState({ deletedPageId: selectedId });
            this.setState({ deletedFormIds: this.state.deletedFormIds.concat([selectedId]) });
            this.props.action.deleteFormAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- Forms List Delete Error', error);
                });
        }
    }

    handleInfo(event) {
        this.setState({ info: true });
    }
    handleInfoPl(event) {
        this.setState({ infoPl: true });
    }
    handleInfoApp(event) {
        this.setState({ infoApp: true });
    }
    handleInfoFrm(event) {
        this.setState({ infoFrm: true });
    }

    closeInfo() {
        this.setState({ info: undefined });
    }
    closeInfoPl() {
        this.setState({ infoPl: undefined });
    }
    closeInfoApp() {
        this.setState({ infoApp: undefined });
    }
    closeInfoFrm() {
        this.setState({ infoFrm: undefined });
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });
    onCloseApp = () => this.setState({ openApp: undefined });
    onCloseFrm = () => this.setState({ openForm: undefined });

    handleNew(event) {
        event.preventDefault();
        const { tabindex } = this.state;
        if (tabindex == 0) {
            this.props.history.push(constants.getSiteLevelRoute() + '/page/0');
        };
        if (tabindex == 1) {
            this.props.history.push(constants.getSiteLevelRoute() + '/app/0');
        };
        if (tabindex == 2) {
            this.props.history.push(constants.getSiteLevelRoute() + '/form/0');
        };
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push(constants.getSiteLevelRoute() + '/page/' + nro);
    }
    handleEditPl(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push('/placemark/' + nro);
    }
    handleEditApp(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push(constants.getSiteLevelRoute() + '/app/' + nro);
    }
    handleEditFrm(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push(constants.getSiteLevelRoute() + '/form/' + nro);
    }
    onActive(idx) {
        this.setState({ tabindex: idx });
    }

    render() {
        const { pagesdata, placemarksdata, appsdata, formsdata, sitesdata, levelsdata, buildingsdata } = this.props;
        const { open, openApp, openForm, deletedPageIds, deletedAppIds, deletedFormIds, info, infoPl, infoApp, infoFrm, tabindex, 
            pagesdataLoading, placemarksdataLoading, appsdataLoading, formsdataLoading, sitesdataLoading, levelsdataLoading, buildingsdataLoading } = this.state;

        if (pagesdataLoading || placemarksdataLoading || appsdataLoading || formsdataLoading || sitesdataLoading || levelsdataLoading || buildingsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }

        return (
            <Box flex justify='between' fill>
                <form>
                    {/*open && (
                    <Layer
                        position="center"
                        modal={true}
                        onClickOutside={this.onClose}
                        onEsc={this.onClose}
                    >
                        <Box pad="medium" gap="small" width="medium" >
                            <Heading level={3} margin="none">
                                Confirm
                            </Heading>
                            <Text>Please confirm that you want to delete this page.</Text>
                            <Box
                                tag="footer"
                                gap="small"
                                direction="row"
                                align="center"
                                justify="end"
                                pad={{ top: "medium", bottom: "small" }}
                                
                            >
                                <StyledButton typeCancel label="Cancel" onClick={this.onClose} />
                                <StyledButton typeSave label="Confirm" onClick={this.eventDelete} />
                            </Box>
                        </Box>
                    </Layer>
                )*/}
                    <PopUpConfirm open={open} text={constants.confirmMessage("page")} info={constants.quickActionWarning("page")} onClose={() => this.onClose()} onDelete={() => this.eventDelete()} />
                    <PopUpConfirm open={openApp} text="Please confirm that you want to delete this app. This will delete the app from this site and users will no longer see it as an option in the client app." info={constants.quickActionWarning("app")} onClose={() => this.onCloseApp()} onDelete={() => this.eventDeleteApp()} />
                    <PopUpConfirm open={openForm} text={constants.confirmMessage("form")} onClose={() => this.onCloseFrm()} onDelete={() => this.eventDeleteFrm()} />
                    <PopUp open={info} header="Issue information" text="Parent Category for this page has been removed, please edit to assign a new location." onClose={() => this.closeInfo()} />
                    <PopUp open={infoPl} header="Issue information" text="This placemark will not appear in the app until correctly configured. Please edit to assign a category from the drop down list." onClose={() => this.closeInfoPl()} />
                    <PopUp open={infoApp} header="Issue information" text="Parent Category for this application has been removed, please edit to assign a new location." onClose={() => this.closeInfoApp()} />
                    <PopUp open={infoFrm} header="Issue information" text="Parent Category for this form has been removed, please edit to assign a new location." onClose={() => this.closeInfoFrm()} />
                    {/* Content tabs*/}
                    <Grid
                        fill={true}
                        rows={['xsmall', 'auto']}
                        columns={['100%', 'flex']}

                        areas={[
                            { name: 'header', start: [0, 0], end: [1, 0] },

                            { name: 'main', start: [0, 1], end: [0, 1] },
                            { name: 'side', start: [1, 0], end: [1, 1] },
                        ]}
                    >
                        <Box gridArea='header' direction="row"
                            align="center"
                            //pad={{ vertical: "medium" }}
                            pad="medium"
                            justify="between"
                            background={constants.BACKGROUND_COLOR}>

                            {tabindex == 0 && (<Heading level='2' >Pages</Heading>)}
                            {tabindex == 1 && (<Heading level='2' >Apps</Heading>)}
                            {tabindex == 2 && (<Heading level='2' >Forms</Heading>)}
                            <Box direction="row" gap="small" >
                                <StyledButton typeSave label='Create New' style={{ visibility: constants.visible(RB.canEdit(constants.PERMS.LOC_CONTENT)) }} alignSelf="center" onClick={this.handleNew} />
                            </Box>
                        </Box>

                        <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium" }} fill="vertical">
                            {/* Content tabs*/}
                            <Tabs flex justify="start" activeIndex={tabindex} onActive={(i) => this.onActive(i)}>
                                <Tab title="Pages">
                                    <PageListTable pagesdata={pagesdata} sitesdata={sitesdata} handleNew={this.handleNew} handleDelete={this.handleDelete} handleEdit={this.handleEdit} handleInfo={this.handleInfo} deletedPageIds={deletedPageIds} />
                                </Tab>
                                <Tab title="Apps">
                                    <AppListTable appsdata={appsdata} sitesdata={sitesdata} handleNew={this.handleNew} handleDelete={this.handleDeleteApp} handleEdit={this.handleEditApp} handleInfo={this.handleInfoApp} deletedAppIds={deletedAppIds} />
                                </Tab>
                                {!constants.release().Prod &&
                                    <Tab title="Forms">
                                        <FormListTable formsdata={formsdata} sitesdata={sitesdata} handleNew={this.handleNew} handleDelete={this.handleDeleteFrm} handleEdit={this.handleEditFrm} handleInfo={this.handleInfoFrm} deletedFormIds={deletedFormIds} />
                                    </Tab>}
                            </Tabs>
                        </Box>
                        <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
                    </Grid>
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    pagesdata: state.pagesReducer.pagesdata,
    placemarksdata: state.placemarksReducer.placemarksdata,
    appsdata: state.appsReducer.appsdata,
    formsdata: state.formsReducer.formsdata,
    sitesdata: state.selectedSiteReducer.sitedata,
    levelsdata: state.levelsReducer.levelsdata,
    buildingsdata: state.buildingsReducer.buildingsdata,
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...PageAction, ...PlacemarkAction, ...AppAction, ...FormAction, ...SiteAction, ...LevelAction, ...BuildingAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(PageListContainer);
