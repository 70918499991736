import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as DepartmentAction from '../action/DepartmentAction';
import DepartmentListTable from './DepartmentListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import PopUpConfirm from './common/PopUpConfirm';

export class DepartmentListContainer extends React.Component {

    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            selectedDepartment: 0,
            deletedDepartmentIds: [],
            open: undefined,
            departmentsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }

    componentDidMount() {
        this.props.action.getDepartmentsAction()
            .then(response => this.setState({ departmentsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department List Error', error);
            });
    }

    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedDepartment: nro });
        this.onOpen();
    }

    eventDelete(event) {
        const selectedId = this.state.selectedDepartment;

        if (selectedId) {
            this.setState({ selectedDepartment: 0, open: undefined });
            this.setState({ deletedDepartmentIds: this.state.deletedDepartmentIds.concat([selectedId]) });
            this.props.action.deleteDepartmentAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- Department List Delete Error', error);
                });
        }
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        this.props.history.push('/department/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push('/department/' + nro);
    }

    render() {
        const { departmentsdata } = this.props;
        const { open, selectedDepartment, deletedDepartmentIds, departmentsdataLoading } = this.state;

        if (departmentsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }

        return (
            <Box flex justify='between' fill>
                <form>
                    <PopUpConfirm open={open} text={constants.confirmMessage("department")} onClose={() => this.onClose()} onDelete={() => this.eventDelete()} />
                    <DepartmentListTable
                        departmentsdata={departmentsdata}
                        handleNew={this.handleNew}
                        handleDelete={this.handleDelete}
                        handleEdit={this.handleEdit}
                        deletedDepartmentIds={deletedDepartmentIds} />
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    departmentsdata: state.departmentsReducer.departmentsdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...DepartmentAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(DepartmentListContainer);
