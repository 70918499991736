import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line
import { Grid, Box, Text, Select, Layer, Image, Grommet, Stack, Button } from 'grommet';
import { User, StatusGoodSmall, Search, Refresh } from 'grommet-icons';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import * as MeridianSDK from "@meridian/web-sdk";
import * as LevelAction from '../action/LevelAction';
import * as BuildingAction from '../action/BuildingAction';
import * as MergedBuildingAction from '../action/MergedBuildingAction';
import * as MergedLevelAction from '../action/MergedLevelAction';
import * as SiteAction from '../action/SiteAction';
import { isNumber } from 'util';
import { useMediaQuery } from 'react-responsive';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import LoadingIndicator from './common/LoadingIndicator';


const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        }
    }
});

const customDateFormatter = new Intl.DateTimeFormat('en-US', {
    //weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    //second: 'numeric'
});
class Map extends Component {
    constructor(props) {
        super(props);
        this.rotate = false;
        this.token = "";
        this.appID = "";
        this.lastUpdate = "";
        this.state = {
            open: true,
            floors: [],
            selectedFloor: "",
            //small width, landscape mobile : dekstop, large screen : portrait, mobile
            width: window.innerHeight < window.innerWidth ? window.innerWidth < 1024 ? (Math.round(window.innerWidth * 0.7) - 10 - 5 + "px") : (window.innerWidth - 10 - 5 - 400 + "px") : window.innerWidth - 10 + "px",
            //small width, landscape mobile : dekstop, large screen : portrait, mobile
            height: window.innerHeight < window.innerWidth ? window.innerWidth < 1024 ? (window.innerHeight - 10 - 40 + "px") : (window.innerHeight - 10 - 70 + "px") : window.innerHeight - 10 - 5 - 140 - 55 + "px",
            sitesdataLoading: true,
            mergedlevelsdataLoading: true,
            mergedbuildingsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 1025 })
        const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })
        if (isDesktop || isLandscape) {
            const floorid = this.props.match.params.floorId;
            const locid = this.props.match.params.locId;
            var x = document.getElementsByClassName("css-qs9yr6-watermark");
            if (floorid && locid && document.getElementById("meridian-map") && x.length == 0 && this.props.sitesdata && this.props.mergedlevelsdata) {
                var location = this.props.sitesdata.find(s => s.id == locid);
                if (location && location.appToken && location.appID) {
                    this.token = location.appToken;
                    this.appID = location.appID;
                    if (this.lastUpdate == "") {
                        const floor = this.props.mergedlevelsdata.find(l => l.meridianId == floorid);
                        this.lastUpdate = floor ? floor.updatedAt : "";
                    }
                    this.renderMap(location.appID, floorid, location.appToken, "meridian-map");
                }
            }
        }
        return isDesktop || isLandscape ? children : null
    }

    Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 1024 })
        const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
        if (isMobile && isPortrait) {
            const floorid = this.props.match.params.floorId;
            const locid = this.props.match.params.locId;
            var x = document.getElementsByClassName("css-qs9yr6-watermark");
            if (floorid && locid && document.getElementById("meridian-map-mobile") && x.length == 0 && this.props.sitesdata && this.props.mergedlevelsdata) {
                var location = this.props.sitesdata.find(s => s.id == locid);
                if (location && location.appToken && location.appID) {
                    this.token = location.appToken;
                    this.appID = location.appID;
                    if (this.lastUpdate == "") {
                        const floor = this.props.mergedlevelsdata.find(l => l.meridianId == floorid);
                        this.lastUpdate = floor ? floor.updatedAt : "";
                    }
                    this.renderMap(location.appID, floorid, location.appToken, "meridian-map-mobile");
                }
            }
        }
        return isMobile && isPortrait ? children : null
    }
    componentWillMount() {
        sessionStorage.setItem("organisationId", "");
        sessionStorage.setItem("organisationCode", "");
    }
    componentDidMount() {
        const locid = this.props.match.params.locId;
        sessionStorage.setItem("locationId", locid);
        var delayInMilliseconds = 250;

        window.addEventListener("resize", evt => {
            if (evt.returnValue) {
                this.rotate = !this.rotate;
                const locid = this.props.match.params.locId;

                //small width, landscape mobile : dekstop, large screen : portrait, mobile
                var width = window.innerHeight < window.innerWidth ? window.innerWidth < 1024 ? (Math.round(window.innerWidth * 0.7) - 10 - 5 + "px") : (window.innerWidth - 10 - 5 - 400 + "px") : window.innerWidth - 10 + "px";
                //small width, landscape mobile : dekstop, large screen : portrait, mobile
                var height = window.innerHeight < window.innerWidth ? window.innerWidth < 1024 ? (window.innerHeight - 10 - 40 + "px") : (window.innerHeight - 10 - 70 + "px") : window.innerHeight - 10 - 5 - 140 - 55 + "px";
                const floorid = this.props.match.params.floorId;

                this.setState({ height: height, width: width });

                if (floorid && locid && (document.getElementById("meridian-map") || document.getElementById("meridian-map-mobile")) && this.props.sitesdata && this.props.mergedlevelsdata) {
                    var location = this.props.sitesdata.find(s => s.id == locid);
                    if (location && location.appToken && location.appID) {
                        this.token = location.appToken;
                        this.appID = location.appID;
                        if (this.lastUpdate == "") {
                            const floor = this.props.mergedlevelsdata.find(l => l.meridianId == floorid);
                            this.lastUpdate = floor ? floor.updatedAt : "";
                        }

                        const self = this;
                        setTimeout(function () {
                            return self.renderMap(location.appID, floorid, location.appToken, self.state.height > self.state.width ? "meridian-map-mobile" : "meridian-map");
                        }, delayInMilliseconds);
                        //this.renderMap(location.appID, floorid, location.appToken, this.state.height > this.state.width ? "meridian-map-mobile" : "meridian-map");
                    }
                }
            }
        }, false);

        this.props.action.getSitesAction(this.props.match.params.locId)
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Site List Error', error);
            });
        this.props.action.getMergedLevelsAction()
            .then(response => this.setState({ mergedlevelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getMergedBuildingsAction()
            .then(response => this.setState({ mergedbuildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
    }
    componentWillUnmount() {
        if (this.map) {
            this.map.destroy();
        }
    }
    forceReloadMap() {
        window.dispatchEvent(new Event("resize"));
    }
    renderMap(locid, floorid, token, elementName) {
        if (locid == "" || floorid == "" || token == "") {
        }
        else {
            const macid = ["CC78ABC6CB63", "CC78AB9D9845"];
            const api = new MeridianSDK.API({
                environment: "production",
                token: token
            });
            var element = document.getElementById(elementName);
            if (element) {
                element.innerHTML = "";
                this.map = MeridianSDK.createMap(
                    element,
                    {
                        api,
                        locationID: locid,
                        floorID: floorid,
                        shouldMapPanZoom: function restrictedPanZoom(event) {
                            if (event.type === "wheel" && !event.altKey) {
                                return false;
                            } else if (event.type === "touchstart") {
                                return event.touches.length >= 2;
                            }
                            return true;
                        },
                        showFloorsControl: false,
                        showTagsControl: false,

                        width: this.state.width,
                        height: this.state.height,
                        placemarks: {
                            filter: p => {
                                return true;
                            },
                        },
                        tags: {
                            filter: t => {
                                return true;
                            },
                            showControlTags: false
                        },
                    },
                );
                element.style.visibility = "visible";
            }
            var x = document.getElementsByClassName("meridian--private--qs9yr6-watermark");
            if (x) {
                x[0].style.visibility = "hidden";
            }
            var y = document.getElementsByClassName("meridian-map-background");
            if (y) {
                y[0].style.background = "#ffffff";
            }
        }
    }

    componentWillReceiveProps(props) {

        if (props.mergedlevelsdata && props.mergedlevelsdata.length > 0 && props.mergedbuildingsdata && props.mergedbuildingsdata.length > 0 && this.state.selectedFloor == "" && !props.mergedlevelsdata[0].hasOwnProperty("count") && !props.mergedbuildingsdata[0].hasOwnProperty("count")) {
            let level = props.mergedlevelsdata.find(l => l.meridianId == props.match.params.floorId);
            this.lastUpdate = level.updatedAt;
            let levelp = level.percentage;
            levelp = levelp != null ? Math.floor(levelp * 100) : "-";
            let building = props.mergedbuildingsdata.find(b => b.id == level.buildingId);
            let buildingp = building.percentage;
            buildingp = buildingp != null ? Math.floor(buildingp * 100) : "-";
            const floors = props.mergedlevelsdata.filter(f => f.buildingId == building.id && f.meridianId != "").sort(constants.byPropertyCalled("name", "asc"));
            this.setState({ selectedBuilding: building.name, selectedFloor: level.name, floors: floors, buildingPercentage: buildingp, floorPercentage: levelp });
        }

        //const floorid = props.match.params.floorId;
        //const locid = props.match.params.locId;
        //if (floorid && locid && document.getElementById("meridian-map") && document.getElementById("meridian-map")) {
        //    var location = props.sitesdata.find(s => s.id == locid);
        //    if (location && location.appToken && location.appID) {
        //        this.token = location.appToken;
        //        this.appID = location.appID;
        //    }
        //}
    }
    //calculateIcon(capacity, occupancy) {
    //    var percentage = Math.floor(occupancy / capacity * 100);
    //    if (percentage <= 75) {
    //        return <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
    //    }
    //    if (percentage > 75 && percentage < 100) {
    //        return <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />;
    //    }
    //    if (percentage >= 100) {
    //        return <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />;
    //    }
    //}
    //calculateCircleIcon(capacity, occupancy) {
    //    var percentage = Math.floor(occupancy / capacity * 100);
    //    if (percentage <= 75) {
    //        return <StatusGoodSmall width="30" height="30" color="#01A982" />;
    //    }
    //    if (percentage > 75 && percentage < 100) {
    //        return <StatusGoodSmall width="30" height="30" color="#FFD144" />;
    //    }
    //    if (percentage >= 100) {
    //        return <StatusGoodSmall width="30" height="30" color="#F04953" />;
    //    }
    //}
    calculateColor(percentage) {
        if (isNumber(percentage)) {
            if (percentage >= 100) return "#F04953"; //red
            if (percentage < 100 && percentage > 75) return "#ffeb59"; //yellow
            if (percentage <= 75) return "#01A982"; //green
        }
        return "#F04953"; //red
    }

    render() {
        var Desktop = this.Desktop;
        var Mobile = this.Mobile;
        console.log("prop", this.props);

        var smallWidth = window.innerWidth < 1024 ? true : false;
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        const { mergedbuildingsdata, mergedlevelsdata, sitesdata } = this.props;
        const { sitesdataLoading, mergedlevelsdataLoading, mergedbuildingsdataLoading } = this.state;

        if (sitesdataLoading || mergedlevelsdataLoading || mergedbuildingsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}
                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },
                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row" height="0px"
                    align="center"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                </Box>
                <Desktop>
                    <Box gridArea='main' fill="vertical" id="box12" height={this.windowHeight}>
                        <Layer id="layer1" position="center" full>
                            <Box id="full" direction="column" height={this.windowHeight} justify="start" pad="5px">
                                <Box id="boxin" direction="row" justify="start" height={this.state.height} gap="5px">
                                    <Box id="controls" width={smallWidth ? (Math.round(windowWidth * 0.3) + "px") : "400px"}
                                        height={this.state.height} gap="small" >
                                        <Box direction="row" justify="between">
                                            <Text weight="bold" size="large">
                                                Site Occupancy
                                            </Text>
                                        </Box>
                                        <Box direction={smallWidth ? "column" : "row"} margin={{ "bottom": smallWidth ? "small" : "none" }} width="100%" justify="between" gap="small">
                                            <Box width={smallWidth ? "100%" : "300px"}>
                                                <Select placeholder="Select Building" value={this.state.selectedBuilding} options={mergedbuildingsdata.map(b => b.name)}
                                                    onChange={o => {
                                                        const buildingid = mergedbuildingsdata[o.selected].id;
                                                        const floors = mergedlevelsdata.filter(f => f.buildingId == buildingid && f.meridianId != "").sort(constants.byPropertyCalled("name", "asc"));
                                                        let percentage = mergedbuildingsdata[o.selected].percentage;
                                                        percentage = percentage != null ? Math.floor(percentage * 100) : "-";
                                                        this.setState({ selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "", buildingPercentage: percentage });
                                                    }} />
                                            </Box>
                                            {this.state.selectedBuilding &&
                                                <Box direction="row" gap="small" align="center">
                                                    <User />
                                                    <Box background={this.calculateColor(this.state.buildingPercentage)} width="45px" align="center">
                                                        <Text weight="bold" color="white">{isNumber(this.state.buildingPercentage) ? this.state.buildingPercentage + "%" : "-"}</Text>
                                                    </Box>
                                                </Box>}
                                        </Box>
                                        <Box direction={smallWidth ? "column" : "row"} margin={{ "bottom": smallWidth ? "small" : "none" }} width="100%" justify="between" gap="small">
                                            <Box width={smallWidth ? "100%" : "300px"}>
                                                <Select disabled={this.state.floors.length == 0} placeholder="Select Floor" value={this.state.selectedFloor} options={this.state.floors.filter(d => d.meridianId).map(n => n.name)}
                                                    onChange={o => {
                                                        const floor = this.state.floors.filter(d => d.meridianId)[o.selected];
                                                        const floorid = floor.meridianId;
                                                        let percentage = floor.percentage;
                                                        console.log("asd1", percentage);
                                                        percentage = percentage != null ? Math.floor(percentage * 100) : "-";
                                                        console.log("asd2", percentage);
                                                        this.lastUpdate = floor.updatedAt;
                                                        this.setState({ selectedFloor: o.option, floorPercentage: percentage }, () => {
                                                            this.renderMap(this.appID, floorid, this.token, "meridian-map");
                                                        });
                                                    }} />
                                            </Box>
                                            {this.state.selectedFloor &&
                                                <Box direction="row" gap="small" align="center">
                                                    <User />
                                                    <Box background={this.calculateColor(this.state.floorPercentage)} width="45px" align="center">
                                                        <Text weight="bold" color="white">{isNumber(this.state.floorPercentage) ? this.state.floorPercentage + "%" : "-"}</Text>
                                                    </Box>
                                                </Box>}
                                        </Box>
                                    </Box>
                                    <Grommet theme={customTheme}>
                                        <Box align="center" alignContent="center" justify="center" onClick={() => this.forceReloadMap()}
                                            height={this.state.height}
                                            width={this.state.width}>
                                            <Stack anchor="center" fill>
                                                <Box height="100%" width="100%" align="center" justify="center">
                                                    <Button icon={<Refresh />} plain onClick={() => this.forceReloadMap()} />
                                                </Box>
                                                <div id="meridian-map" />
                                            </Stack>
                                        </Box>
                                    </Grommet>
                                </Box>
                                <Box height={smallWidth ? "40px" : "70px"} width="100%" direction="row" justify="between">
                                    <Box justify="end" alignSelf="end">
                                        <Image height={smallWidth ? "35px" : "65px"} src="https://hpepngdemostor.blob.core.windows.net/images/hpeCitrixLogo.png" />
                                    </Box>
                                    <Box direction="column" align="end" justify="end" alignSelf="end">
                                        <Text size="small">Last Updated: {this.lastUpdate == "" ? "Unavailable" : customDateFormatter.format(new Date(this.lastUpdate))}</Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Layer>
                    </Box>
                </Desktop>

                <Mobile>
                    <Box gridArea='main' fill="vertical" id="box12" height={this.windowHeight}>
                        <Layer id="layer1" position="center" full>
                            <Box id="full" direction="column" height={this.windowHeight} justify="start" pad="5px">
                                <Box id="boxin" direction="column" gap="5px" height="100%">
                                    <Box id="controls" direction="column" justify="between" height="140px" width="100%">
                                        <Box direction="row" justify="between">
                                            <Text weight="bold" size="large">
                                                Site Occupancy
                                            </Text>
                                        </Box>
                                        <Box width="100%" direction="column" gap="small">
                                            <Box direction="row" gap="medium" justify="between" width="100%">
                                                <Box width={windowWidth - 120 + "px"}>
                                                    <Select placeholder="Select Building" value={this.state.selectedBuilding} options={mergedbuildingsdata.map(b => b.name)}
                                                        onChange={o => {
                                                            const buildingid = mergedbuildingsdata[o.selected].id;
                                                            const floors = mergedlevelsdata.filter(f => f.buildingId == buildingid && f.meridianId != "").sort(constants.byPropertyCalled("name", "asc"));
                                                            let percentage = mergedbuildingsdata[o.selected].percentage;
                                                            percentage = percentage != null ? Math.floor(percentage * 100) : "-";
                                                            this.setState({ selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "", buildingPercentage: percentage });
                                                        }} />
                                                </Box>

                                                {this.state.selectedBuilding &&
                                                    <Box direction="row" gap="small" align="center">
                                                        <User />
                                                        <Box background={this.calculateColor(this.state.buildingPercentage)} width="45px" align="center">
                                                            <Text weight="bold" color="white">{isNumber(this.state.buildingPercentage) ? this.state.buildingPercentage + "%" : "-"}</Text>
                                                        </Box>
                                                    </Box>}
                                            </Box>

                                            <Box direction="row" gap="medium" justify="between" width="100%">
                                                <Box width={windowWidth - 120 + "px"}>
                                                    <Select disabled={this.state.floors.length == 0} placeholder="Select Floor" value={this.state.selectedFloor} options={this.state.floors.filter(d => d.meridianId).map(n => n.name)}
                                                        onChange={o => {
                                                            const floor = this.state.floors.filter(d => d.meridianId)[o.selected];
                                                            const floorid = floor.meridianId;
                                                            let percentage = floor.percentage;
                                                            percentage = percentage != null ? Math.floor(percentage * 100) : "-";
                                                            this.lastUpdate = floor.updatedAt;
                                                            this.setState({ selectedFloor: o.option, floorPercentage: percentage });
                                                            this.renderMap(this.appID, floorid, this.token, "meridian-map-mobile");
                                                        }} />
                                                </Box>
                                                {this.state.selectedFloor &&
                                                    <Box direction="row" gap="small" align="center">
                                                        <User />
                                                        <Box background={this.calculateColor(this.state.floorPercentage)} width="45px" align="center">
                                                            <Text weight="bold" color="white">{isNumber(this.state.floorPercentage) ? this.state.floorPercentage + "%" : "-"}</Text>
                                                        </Box>
                                                    </Box>}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Grommet theme={customTheme}>
                                        <Box alignSelf="center" align="center" alignContent="center" width="100%" height={this.state.height} onClick={() => this.forceReloadMap()}>
                                            <Stack anchor="center" fill>
                                                <Box height="100%" width="100%" align="center" justify="center">
                                                    <Button icon={<Refresh />} plain onClick={() => this.forceReloadMap()} />
                                                </Box>
                                                <div id="meridian-map-mobile" />
                                            </Stack>
                                        </Box>
                                    </Grommet>
                                </Box>
                                <Box height="55px" direction="row" justify="between">
                                    <Box justify="end">
                                        <Image height="45px" src="https://hpepngdemostor.blob.core.windows.net/images/hpeCitrixLogo.png" />
                                    </Box>
                                    <Box direction="column" justify="end" align="start" gap="xsmall">
                                        <Text size="small">Last Updated:</Text>
                                        <Text size="small">{this.lastUpdate == "" ? "Unavailable" : customDateFormatter.format(new Date(this.lastUpdate))}</Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Layer>
                    </Box>
                </Mobile>

                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        //levelsdata: state.levelsReducer.levelsdata,
        //buildingsdata: state.buildingsReducer.buildingsdata,
        sitesdata: state.sitesReducer.sitesdata,
        mergedlevelsdata: state.mergedLevelsReducer.mergedlevelsdata,
        mergedbuildingsdata: state.mergedBuildingsReducer.mergedbuildingsdata
    };
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...LevelAction, ...BuildingAction, ...MergedBuildingAction, ...MergedLevelAction, ...SiteAction }, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Map);