import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import RB from './components/common/RBAC';
import Layout from './components/Layout';
import Home from './components/Home';
import EventEditContainer from './components/EventEditContainer';
import EventListTableContainer from './components/EventListTableContainer';
import CategoryListContainer from './components/CategoryListContainer';
import CategoryEditContainer from './components/CategoryEditContainer';
import NotificationListContainer from './components/NotificationListContainer';
import NotificationEditContainer from './components/NotificationEditContainer';
import PageListContainer from './components/PageListContainer';
import PageEditContainer from './components/PageEditContainer';
import PlacemarkListContainer from './components/PlacemarkListContainer';
import PlacemarkEditContainer from './components/PlacemarkEditContainer';
import ToDo from './components/ToDo';
import LocUserListContainer from './components/LocUserListContainer';
import LocUserEditContainer from './components/LocUserEditContainer';
import UserListContainer from './components/UserListContainer';
import UserEditContainer from './components/UserEditContainer';
import ProfileListContainer from './components/ProfileListContainer';
//import LocRoleListContainer from './components/LocRoleListContainer';
//import RoleListContainer from './components/RoleListContainer';
import OrgSettings from './components/OrgSettings';
import OrgIntegrationEditContainer from './components/OrgIntegrationEditContainer';
import OrgIntegrationOauthEditContainer from './components/OrgIntegrationOauthEditContainer';
import OrgIntegrationClearpassEditContainer from './components/OrgIntegrationClearpassEditContainer';
import OrgIntegrationMeridianEditContainer from './components/OrgIntegrationMeridianEditContainer';
import OrgIntegrationTeemEditContainer from './components/OrgIntegrationTeemEditContainer';
import OrgIntegrationBuildingManagementEditContainer from './components/OrgIntegrationBuildingManagementEditContainer';
import OrgIntegrationChargifiEditContainer from './components/OrgIntegrationChargifiEditContainer';
import OrgIntegrationMicrosoft365EditContainer from './components/OrgIntegrationMicrosoft365EditContainer';
import AppDetails from './components/AppDetails';
import AppLocationListContainer from './components/AppLocationListContainer';
//import AppIntegration from './components/AppIntegration';
import AppTheme from './components/AppTheme';
import Account from './components/Account';
import ConfigurationEditContainer from './components/ConfigurationEditContainer';
import IntegrationEditContainer from './components/IntegrationEditContainer';
import IntegrationMeridianEditContainer from './components/IntegrationMeridianEditContainer';
import IntegrationRoomBookingEditContainer from './components/IntegrationRoomBookingEditContainer';
import IntegrationDeskBookingEditContainer from './components/IntegrationDeskBookingEditContainer';
import IntegrationMicrosoft365EditContainer from './components/IntegrationMicrosoft365EditContainer';
import IntegrationReportIssueEditContainer from './components/IntegrationReportIssueEditContainer';
import IntegrationChargifiEditContainer from './components/IntegrationChargifiEditContainer';
import OrgSiteListContainer from './components/OrgSiteListContainer';
import OrgSiteEditContainer from './components/OrgSiteEditContainer';
import Landing from './components/Landing';
import LocMappingListContainer from './components/LocMappingListContainer';
import ProtectedRoute from './components/common/ProtectedRoute';
import ProtectedRouteW from './components/common/ProtectedRouteW';
import NoAccess from './components/NoAccess';
import NoUser from './components/NoUser';
import NoPermission from './components/NoPermission';
import constants from './constants';
import BeaconListContainer from './components/BeaconListContainer';
import SpotsListContainer from './components/SpotsListContainer';
import OrgListContainer from './components/OrgListContainer';
import ManageOrgListContainer from './components/ManageOrgListContainer';
import ManageOrgSettings from './components/ManageOrgSettings';
import ManageSiteListContainer from './components/ManageSiteListContainer'
import ManageSiteSettings from './components/ManageSiteSettings'
import SuperUserListContainer from './components/ManageUserListContainer';
import SuperUserEditContainer from './components/ManageUserEditContainer';
import { withOnlyAdalLogin, withAdalLoginApi } from './adal/adalConfig';
import ChartPage from './components/ChartPage';
//import Privacy from './components/Privacy';
import SingleChart from './components/SingleChart';
import ActionCentre from './components/ActionCentre';
import QuickActionEditContainer from './components/QuickActionEditContainer';
import PromotedActionEditContainer from './components/PromotedActionEditContainer';
import AppEditContainer from './components/AppEditContainer';
import FormEditContainer from './components/FormEditContainer';
//import AppListContainer from './components/AppListContainer';
//import AppListTable from './components/AppListTable';
//import MasterAppListTable from './components/MasterAppListTable';
import MasterAppListContainer from './components/MasterAppListContainer';
import MasterAppEditContainer from './components/MasterAppEditContainer';
import MeridianMap from './components/MeridianMap';
import AssetListContainer from './components/AssetListContainer';
import AssetEditContainer from './components/AssetEditContainer';
import BuildingEditContainer from './components/BuildingEditContainer';
import FloorEditContainer from './components/FloorEditContainer';
import Map from './components/MeridianCitrix';
import MapCategory from './components/MeridianCitrixCategory';
import HealthPass from './components/HealthPass';
import LocHealthPass from './components/LocHealthPass';
import Report from './components/Report';
import { msalLogin } from './msal/msalHelper';
import CacheBuster from './CacheBuster';
import TagListContainer from './components/TagListContainer';
import TagEditContainer from './components/TagEditContainer';
import TagCreateContainer from './components/TagCreateContainer';
import TrackerEditContainer from './components/TrackerEditContainer';
import LabelEditContainer from './components/LabelEditContainer';
import DepartmentListContainer from './components/DepartmentListContainer';
import DepartmentEditContainer from './components/DepartmentEditContainer';
import OrgTagListContainer from './components/OrgTagListContainer';
import TrackerListContainer from './components/TrackerListContainer';
import CollectionListContainer from './components/CollectionListContainer';
import OrgCollectionListContainer from './components/OrgCollectionListContainer';
import OrgCollectionEditContainer from './components/OrgCollectionEditContainer';
import OrgDepartmentListContainer from './components/OrgDepartmentListContainer';
import OrgDepartmentEditContainer from './components/OrgDepartmentEditContainer';
import OrgTrackerListContainer from './components/OrgTrackerListContainer';
import OrgAssetListContainer from './components/OrgAssetListContainer';
import OrgAssetEditContainer from './components/OrgAssetEditContainer';
import LinkEditContainer from './components/LinkEditContainer';
import OrgTrackerEditContainer from './components/OrgTrackerEditContainer';
import OrgTagEditContainer from './components/OrgTagEditContainer';
import TagBulkEditContainer from './components/TagBulkEditContainer';
import OrgTagCreateContainer from './components/OrgTagCreateContainer';
import BeaconBulkEditContainer from './components/BeaconBulkEditContainer';
import MeridianMapBookSpace from './components/MeridianMapBookSpace';
import BookingListContainer from './components/BookingListContainer';
import Overview from './components/Overview';
import OrgLinkEditContainer from './components/OrgLinkEditContainer';
import OrgTagBulkEditContainer from './components/OrgTagBulkEditContainer';
import OrgMetadataCreateContainer from './components/OrgMetadataCreateContainer';
import OrgMetadataEditContainer from './components/OrgMetadataEditContainer';
import Error404 from './components/Error404';

export default () => (
    <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
                refreshCacheAndReload();
            }
            return (
                <Layout>
                    <Switch>
                        <Route exact path='/' component={Landing} />
                        <Route path='/account' component={msalLogin(Account)} />
                        <ProtectedRoute path='/:orgid?/:locid?/home' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(Home)} />
                        <ProtectedRoute path='/:orgid?/:locid?/overview' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(Overview)} />
                        <Route path='/noaccess' component={NoAccess} />
                        <Route path='/nouser' component={NoUser} />
                        <Route path='/nopermission' component={NoPermission} />
                        <ProtectedRoute path='/:orgid?/:locid?/label/:labelid' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(LabelEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/trackers' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(TrackerListContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/tracker/:trackerid' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(TrackerEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/link/:linkid' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(LinkEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/tags' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(TagListContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/tag/:tagid' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(TagEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/edittags' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(TagBulkEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/createtag/:tagid' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(TagCreateContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/beacons' objprinc={constants.PERMS.LOC_SERVICES} component={msalLogin(BeaconListContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/editbeacons' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(BeaconBulkEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/departments' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(DepartmentListContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/collections' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(CollectionListContainer)} />
                        {/*<ProtectedRoute path='/:orgid?/:locid?/department/:id' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(DepartmentEditContainer)} />*/}
                        <ProtectedRoute path='/:orgid?/:locid?/spots' objprinc={constants.PERMS.LOC_SERVICES} component={msalLogin(SpotsListContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/event/:eventId?' objprinc={constants.PERMS.LOC_EVENTS} component={msalLogin(EventEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/eventstable' objprinc={constants.PERMS.LOC_EVENTS} component={msalLogin(EventListTableContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/actioncentre/:id' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(QuickActionEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/actioncentre' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(ActionCentre)} />
                        {/*<ProtectedRoute path='/:orgid?/:locid?/quickaction/:id?' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(QuickActionEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/promotedaction/:id?' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(PromotedActionEditContainer)} />*/}
                        <ProtectedRouteW path='/:orgid?/:locid?/categories' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(CategoryListContainer)} />
                        <ProtectedRouteW path='/:orgid?/:locid?/category/:categoryId?' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(CategoryEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/pages/:tab?' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(PageListContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/page/:pageId?' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(PageEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/placemarks' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(PlacemarkListContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/placemark/:placemarkId?' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(PlacemarkEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/app/:appId?' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(AppEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/form/:formId?' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(FormEditContainer)} />
                        {/*<Route path='/notifications' component={msalLogin(ToDo)} />*/}
                        {/*<ProtectedRoute path='/users' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(UserListContainer)} />
            <ProtectedRoute path='/user/:userId?' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(UserEditContainer)} />
            <ProtectedRoute path='/profiles' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(ProfileListContainer)} />
            <ProtectedRoute path='/roles' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(RoleListContainer)} />
            */}
                        <ProtectedRouteW path='/:orgid?/:locid?/locusers' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(LocUserListContainer)} />
                        <ProtectedRouteW path='/:orgid?/:locid?/locuser/:userId?' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(LocUserEditContainer)} />
                        {/*<ProtectedRouteW path='/locroles' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(LocRoleListContainer)} />*/}
                        <ProtectedRouteW path='/:orgid?/:locid?/mapping' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(LocMappingListContainer)} />
                        <ProtectedRouteW path='/:orgid?/profiles' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(ProfileListContainer)} />
                        {/*<ProtectedRouteW path='/roles' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(RoleListContainer)} />*/}
                        <ProtectedRouteW path='/:orgid?/users' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(UserListContainer)} />
                        <ProtectedRouteW path='/:orgid?/user/:userId?' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(UserEditContainer)} />


                        <ProtectedRoute path='/:orgid?/:locid?/bookspacemap' objprinc={constants.PERMS.LOC_SERVICES} component={msalLogin(MeridianMapBookSpace)} />
                        <ProtectedRoute path='/:orgid?/:locid?/mybookings' objprinc={constants.PERMS.LOC_SERVICES} component={msalLogin(BookingListContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/meridianmap' objprinc={constants.PERMS.LOC_SERVICES} component={msalLogin(MeridianMap)} />
                        <ProtectedRoute path='/:orgid?/:locid?/assets' objprinc={constants.PERMS.LOC_SERVICES} component={msalLogin(AssetListContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/asset/:assetid' objprinc={constants.PERMS.LOC_SERVICES} component={msalLogin(AssetEditContainer)} />

                        <ProtectedRoute path='/:orgid?/:locid?/level/:levelId?' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(FloorEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/building/:buildingId?' objprinc={constants.PERMS.LOC_ADMINISTRATION} component={msalLogin(BuildingEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/configuration/:tab?' objprinc={constants.PERMS.LOC_CONTENT} component={msalLogin(ConfigurationEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/integrations' exact objprinc={constants.PERMS.LOC_CONF_SITE} component={msalLogin(IntegrationEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/integrations/meridian' objprinc={constants.PERMS.LOC_CONF_SITE} component={msalLogin(IntegrationMeridianEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/integrations/roombooking' objprinc={constants.PERMS.LOC_CONF_SITE} component={msalLogin(IntegrationRoomBookingEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/integrations/deskbooking' objprinc={constants.PERMS.LOC_CONF_SITE} component={msalLogin(IntegrationDeskBookingEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/integrations/microsoft365' objprinc={constants.PERMS.LOC_CONF_SITE} component={msalLogin(IntegrationMicrosoft365EditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/integrations/reportissue' objprinc={constants.PERMS.LOC_CONF_SITE} component={msalLogin(IntegrationReportIssueEditContainer)} />
                        <ProtectedRoute path='/:orgid?/:locid?/integrations/chargifi' objprinc={constants.PERMS.LOC_CONF_SITE} component={msalLogin(IntegrationChargifiEditContainer)} />
                        <Route path='/:orgid?/sites' component={msalLogin(AppLocationListContainer)} />
                        {/*Organisation Menu*/}
                        <ProtectedRouteW path='/masterapp/:appId?' objprinc={constants.PERMS.SUP_SUPERUSER} component={msalLogin(MasterAppEditContainer)} />
                        <ProtectedRouteW path='/masterapps' objprinc={constants.PERMS.SUP_SUPERUSER} component={msalLogin(MasterAppListContainer)} />
                        <Route path='/orglist' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={/*msalLogin(OrgListContainer)*/msalLogin(OrgListContainer)} />
                        <ProtectedRouteW path='/manageorgs' exact objprinc={constants.PERMS.SUP_SUPERUSER} component={msalLogin(ManageOrgListContainer)} />
                        <ProtectedRouteW path='/manageorg/:id?' objprinc={constants.PERMS.SUP_SUPERUSER} component={msalLogin(ManageOrgSettings)} />
                        <ProtectedRouteW path='/managesites' exact objprinc={constants.PERMS.SUP_SUPERUSER} component={msalLogin(ManageSiteListContainer)} />
                        <ProtectedRouteW path='/managesite/:id?' objprinc={constants.PERMS.SUP_SUPERUSER} component={msalLogin(ManageSiteSettings)} />
                        <ProtectedRouteW path='/manageusers' objprinc={constants.PERMS.SUP_SUPERUSER} component={msalLogin(SuperUserListContainer)} />
                        <ProtectedRouteW path='/manageuser/:userId?' objprinc={constants.PERMS.SUP_SUPERUSER} component={msalLogin(SuperUserEditContainer)} />
                        <ProtectedRoute path='/:orgid?/notifications' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(NotificationListContainer)} />
                        <ProtectedRouteW path='/:orgid?/notification/:notificationId?' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(NotificationEditContainer)} />
                        <ProtectedRouteW path='/:orgid?/orgsettings' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgSettings)} />
                        <ProtectedRouteW path='/:orgid?/orgintegrations' exact objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgIntegrationEditContainer)} />
                        <ProtectedRouteW path='/:orgid?/orgintegrations/oauth2' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgIntegrationOauthEditContainer)} />
                        <ProtectedRouteW path='/:orgid?/orgintegrations/clearpass' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgIntegrationClearpassEditContainer)} />
                        <ProtectedRouteW path='/:orgid?/orgintegrations/meridian' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgIntegrationMeridianEditContainer)} />
                        <ProtectedRouteW path='/:orgid?/orgintegrations/teem' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgIntegrationTeemEditContainer)} />
                        <ProtectedRouteW path='/:orgid?/orgintegrations/buildingmanagement' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgIntegrationBuildingManagementEditContainer)} />
                        <ProtectedRouteW path='/:orgid?/orgintegrations/chargifi' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgIntegrationChargifiEditContainer)} />
                        <ProtectedRouteW path='/:orgid?/orgintegrations/microsoft365' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgIntegrationMicrosoft365EditContainer)} />
                        <ProtectedRouteW path='/:orgid?/appdetails' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(AppDetails)} />
                        <ProtectedRouteW path='/:orgid?/apptheme' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(AppTheme)} />
                        <ProtectedRouteW path='/:orgid?/orgsites' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgSiteListContainer)} />
                        <ProtectedRouteW path='/:orgid?/orgsite/:siteId?' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgSiteEditContainer)} />
                        {/*<ProtectedRouteW path='/orgappintegration' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(AppIntegration)} /> */}
                        <ProtectedRouteW path='/:orgid?/orgasset/:assetid' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgAssetEditContainer)} />
                        <ProtectedRouteW path='/:orgid?/orgassets' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgAssetListContainer)} />
                        <ProtectedRouteW path='/:orgid?/appanalytics' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(ChartPage)} />
                        <ProtectedRouteW path='/:orgid?/singlechart/:feature?' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(SingleChart)} />
                        <ProtectedRouteW path='/:orgid?/healthpass' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(HealthPass)} />
                        <ProtectedRouteW path='/:orgid?/:locid?/lochealthpass' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(LocHealthPass)} />
                        <ProtectedRouteW path='/:orgid?/orgreport' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(Report)} />
                        <ProtectedRoute path='/:orgid?/orgtags' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgTagListContainer)} />
                        <ProtectedRoute path='/:orgid?/orgtag/:tagid' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgTagEditContainer)} />
                        <ProtectedRoute path='/:orgid?/orglabel/:labelid' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(LabelEditContainer)} />
                        <ProtectedRoute path='/:orgid?/orgdepartment/:departmentid' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgDepartmentEditContainer)} />
                        <ProtectedRoute path='/:orgid?/orgdepartments' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgDepartmentListContainer)} />
                        <ProtectedRoute path='/:orgid?/orgcollections' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgCollectionListContainer)} />
                        <ProtectedRoute path='/:orgid?/orgcollection/:collectionid' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgCollectionEditContainer)} />
                        <ProtectedRoute path='/:orgid?/orgtracker/:trackerid' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgTrackerEditContainer)} />
                        <ProtectedRoute path='/:orgid?/orgtrackers' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgTrackerListContainer)} />
                        <ProtectedRoute path='/:orgid?/orgcreatetag/:tagid' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgTagCreateContainer)} />
                        <ProtectedRoute path='/:orgid?/orgcreatemetadata/:metaid' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgMetadataCreateContainer)} />
                        <ProtectedRoute path='/:orgid?/orgmetadata/:metaid' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgMetadataEditContainer)} />
                        <ProtectedRoute path='/:orgid?/orglink/:linkid' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgLinkEditContainer)} />
                        <ProtectedRoute path='/:orgid?/orgedittags' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(OrgTagBulkEditContainer)} />
                        {/*<ProtectedRouteW path='/privacy' objprinc={constants.PERMS.ORG_ADMINISTRATION} component={msalLogin(Privacy)} />*/}
                        <Route path='/map/:locId/floor/:floorId' component={msalLogin(Map)} />
                        <Route path='/mapsearch/:locId/floor/:floorId' component={msalLogin(MapCategory)} />
                        <Route path="*" component={Error404} />
                    </Switch>
                </Layout>
            );
        }}
    </CacheBuster>
);
