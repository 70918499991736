import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedQuickActionReducer = (state = initialState.selectedQuickActionReducer, action) => {
    switch(action.type) {

        case ActionType.GET_QUICKACTION_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                quickactiondata: _.assign(action.quickactiondata)
            };
        }

        default: { return state; }
    }
};


export default selectedQuickActionReducer;