import React, { Component } from 'react';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
//import { Glyphicon, Nav, Navbar, NavItem } from 'react-bootstrap';
//import { LinkContainer } from 'react-router-bootstrap';
//import './NavMenu.css';
// eslint-disable-next-line
import { withRouter } from 'react-router-dom';
import { Box, Grid, RoutedAnchor, Anchor, Text } from 'grommet';
import { Filter, Edit, Close, Table, Calendar, Analytics, Iteration, Blank, Overview, Configure, Notification, SettingsOption, Location } from 'grommet-icons';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';

class SensitiveRoutedAnchor extends Component {
    render() {
        const { match, location, history, path, label, icon, disabled } = this.props;
        let isBold = this.props.checkpath(path, location.pathname) ? "bold" : "normal";
        return (
            <RoutedAnchor path={path}
                label={<Text weight={isBold}>{label}</Text>}
                icon={icon}
                disabled={disabled} />
        );
    }
};

class SensitiveAnchor extends Component {
    render() {
        const { location, label, boldpathes, ...rest } = this.props;
        let isBold = boldpathes.some(pe => location.pathname.startsWith(pe)) ? "bold" : "normal";
        return (
            <Anchor
                label={<Text weight={isBold}>{label}</Text>}
                {...rest}
            />
        )
    }
}

const LocationSensitiveRoutedAnchor = withRouter(SensitiveRoutedAnchor);
const LocationSensitiveAnchor = withRouter(SensitiveAnchor);

class NavMenuAcc extends Component {
    state = { openContent: false, openAdmin: false, openServices: false, open: false }
    ClickContent = () => this.setState({ openContent: !this.state.openContent });
    ClickAdmin = () => this.setState({ openAdmin: !this.state.openAdmin });
    ClickServices = () => this.setState({ openServices: !this.state.openServices });
    render() {
        const { open, openContent, openAdmin, openServices } = this.state;
        return (
            <Box fill={true} pad='none' background="transparent" >
                <Grid columns='small' gap='small'>
                    <Box pad="medium" gap="small">
                        <Box>
                            {/*<LocationSensitiveAnchor
                                boldpathes={['/']}
                            label='Logout' icon={<Blank />} onClick={this.ClickLogout} />*/}
                        </Box>
                    </Box>
                </Grid>
            </Box>
        );
    }
}
export default NavMenuAcc;
