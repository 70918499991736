import * as ActionType from './ActionType';
import EventApi from '../api/EventApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getEventsResponse = eventsdata => ({
    type: ActionType.GET_EVENTS_RESPONSE,
    eventsdata
});

const queuedGetAllEvents = queueApiCall(EventApi.getAllEvents);

export function getEventsAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return EventApi.getAllEvents()
        return queuedGetAllEvents()
            .then(eventsdata => {
                dispatch(getEventsResponse(eventsdata));
                return eventsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewEventResponse = () => ({
    type: ActionType.ADD_NEW_EVENT_RESPONSE
});

export const updateExistingEventResponse = () => ({
    type: ActionType.UPDATE_EXISTING_EVENT_RESPONSE
});

export function saveEventAction(eventdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the event is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new event that is being added, therefore add it
        return EventApi.saveEvent(eventdraft)
            .then((response) => {
                if (eventdraft.id) {
                    dispatch(updateExistingEventResponse());
                } else {
                    dispatch(addNewEventResponse());
                }
                return response;
            }).then((response) => {
                //if (eventdraft.id == "0") {
                //    dispatch(getEventAction(eventdraft.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getEventResponse = eventdata => ({
    type: ActionType.GET_EVENT_RESPONSE,
    eventdata
});

export function getEventAction(eventId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return EventApi.getEvent(eventId)
            .then(eventdata => {
                dispatch(getEventResponse(eventdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteEventResponse = () => ({
    type: ActionType.DELETE_EVENT_RESPONSE
});

const queuedDeleteEvent = queueApiCall(EventApi.deleteEvent);

export function deleteEventAction(eventId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return EventApi.deleteEvent(eventId)
        return queuedDeleteEvent(eventId)
            .then((response) => {
                dispatch(deleteEventResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getEventsAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}