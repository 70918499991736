import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken, getAllRecords } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

//const SERVER_URL = `${SERVER}/apiproxy/simple/tables/category`;
const SERVER_URL = `${SERVER}/tables/category`;
//const SERVER_URL = `${SERVER}/api/category`;
console.log('HOST:', SERVER_URL);

const apiqueue = [];
const dequeue = () => {
    apiqueue.splice(0, 1);
};

const queueApiCall = (fnApiCall) => {
    return (...args) => {
        let apicall;
        const invokeApi = () => fnApiCall(...args);

        if (apiqueue.length === 0) {
            apicall = invokeApi();
        } else {
            apicall = apiqueue[apiqueue.length - 1].then(invokeApi, invokeApi);
        }
        apiqueue.push(apicall.then(dequeue, dequeue));
        return apicall;
    }
}

const applyHocOnClass = classDef => {
    Object.getOwnPropertyNames(classDef).filter(prop => typeof CategoryApi[prop] === 'function').forEach(method => {
        classDef[method] = queueApiCall(classDef[method]);
    });
};

class CategoryApi {
    static async getAllCategories() {
        // const options = {
        //     method: 'get',
        //     headers: await myHeadersWithToken()
        // }
        // return fetch(`${SERVER_URL}/?$filter=Level%20eq%202&${API_VERSION}`, options).then(response => response.json());
        let headers = await myHeadersWithToken();
        headers.append('X-Content', 1)
        const options = {
            method: 'get',
            headers: headers
        };
        return fetch(`${SERVER_URL}/?$filter=Level%20eq%200%20or%20Level%20eq%202%20or%20Level%20eq%201&$count=true&$top=0&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                return getAllRecords(`${SERVER_URL}/?$filter=Level%20eq%200%20or%20Level%20eq%202%20or%20Level%20eq%201&${API_VERSION}`, options, data.count)
                    .catch(error => {
                        throw error;
                    });
            })
            .catch(error => {
                throw error;
            });
    };

    static async saveCategory(categorydata) {
        if (categorydata.id && categorydata.id != "0") {
            return fetch(`${SERVER_URL}/${categorydata.id}/?${API_VERSION}`, {
                method: 'put',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(categorydata)
            }).then(response => response);
        } else {
            return fetch(`${SERVER_URL}/?${API_VERSION}`, {
                method: 'post',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(categorydata)
            }).then(response => response);
        }
    }

    static async deleteCategory(categoryId) {
        const options = {
            method: 'delete',
            headers: await myHeadersWithToken()
        }
        return fetch(`${SERVER_URL}/${categoryId}/?${API_VERSION}`, options)
            .then(response => response);
    }

    // static deleteCategoryQueued = (function (fnApiCall) {
    //     const apiqueue = [];
    //     const dequeue = () => {
    //         apiqueue.splice(0, 1);
    //     };

    //     return (categoryId) => {
    //         let apicall;

    //         const invokeApi = () => fnApiCall(categoryId);

    //         if (apiqueue.length === 0) {
    //             apicall = invokeApi();
    //         } else {
    //             apicall = apiqueue[apiqueue.length - 1].then(invokeApi, invokeApi);
    //         }
    //         apiqueue.push(apicall.then(dequeue, dequeue));
    //         return apicall;
    //     }
    // })(CategoryApi.deleteCategory);

    static async getCategory(categoryId) {
        console.log('API:', categoryId);
        let headers = await myHeadersWithToken();
        headers.append('X-Content', 1)
        const options = {
            method: 'get',
            headers: headers
        }
        return fetch(`${SERVER_URL}/${categoryId}/?${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }
}


applyHocOnClass(CategoryApi);

export default CategoryApi;
