import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import * as CalendarHistoryAction from '../action/CalendarHistoryAction';
import * as CalendarAction from '../action/CalendarAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Layer, Heading, Text, Grommet, Calendar, Tabs, Tab, Stack, DataTable, TextInput, FormField } from 'grommet';
import { Plan, StatusGoodSmall } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import { grommet } from 'grommet/themes';
import { SketchPicker } from 'react-color';
import _ from 'lodash';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import ReactTooltip from "react-tooltip";
import LoadingIndicator from './common/LoadingIndicator';
import { element } from 'prop-types';


const PaginatedDataTable = withPagination(DataTable);

var d1 = new Date();
d1.setDate(d1.getDate() - 1);
d1.setHours(11, 11, 11, 0);
var d2 = new Date();
d2.setDate(d1.getMonth() - 1);
d2.setHours(17, 30, 59, 0);

var start = undefined;
var dict = [
    //{
    //    color: "#ff0000",
    //    date: d1
    //},
    //{
    //    color: "#ffa500",
    //    date: d2
    //}
];


const customDateFormatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    //hour: 'numeric',
    //minute: 'numeric',
    //second: 'numeric'
});
function DateFormatter(withTime) {
    if (withTime) {
        return new Intl.DateTimeFormat('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    }
    return new Intl.DateTimeFormat('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        //hour: 'numeric',
        //minute: 'numeric',
        //second: 'numeric'
    });
}
//const customTheme = deepMerge(hpe, {
//    //global: {
//    //    font: {
//    //        family: `-apple-system,
//    //   BlinkMacSystemFont, 
//    //   "Segoe UI"`,
//    //    },
//    //},
//    calendar: {
//        //extend: (prop) => console.log("data", prop),
//        day: {
//            extend: (day) => `
//        border-radius: 100px;
//        background-color: ${(dict.find(e => e.date.getDate() == day.children) && !day.otherMonth) ? dict.find(e => e.date.getDate() == day.children).color : 'undefined'}`,
//            //extend:  (prop) => console.log("data", prop)
//        },
//    },
//});
class HealthPass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPickColor: false,
            color: "",
            cancel: false,
            isSaved: false,
            isSaveSuccessful: false,
            disabledButton: false,
            index: 0,
            search: "",
            organisationdataLoading: true,
            calendarhistoriesdataLoading: true,
            calendarsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
        this.selectedDate = new Date().toISOString();
    }
    retention_err = "";

    ClearErrorMessages() {
        this.retention_err = "";
    }
    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        //const orgid = constants.organisation.id;
        const orgid = sessionStorage.getItem("organisationId");
        this.props.action.getOrganisationAction(orgid)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
        this.props.action.getCalendarHistoriesAction()
            .then(response => this.setState({ calendarhistoriesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-CalendarHistory Error', error);
            });
        this.props.action.getCalendarsAction()
            .then(response => this.setState({ calendarsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Calendars Error', error);
            });
        var index = this.props.location.state ? this.props.location.state.index : 0;
        this.setState({ index: index });
    }
    componentWillUnmount() {
        this.historyUnblock();
    }
    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.organisationdata && !_.isEqual(start, this.props.organisationdata) || dict.length != 0) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }
    handleSave() {
        const organisationdefault = this.props.organisationdata;
        let errorsno = 0;
        var errortab = -1;
        this.ClearErrorMessages();
        //validation
        if (!constants.REGEX.NUMBER.test(organisationdefault.formRetentionDays)) {
            errorsno++;
            this.retention_err = "Value must be a number between 0 and 21";
            errortab = 1;
        }
        if (organisationdefault.formRetentionDays < 0 || organisationdefault.formRetentionDays > 21) {
            errorsno++;
            this.retention_err = "Value must be a number between 0 and 21";
            errortab = 1;
        }
        if (errorsno === 0) {
            this.setState({ disabledButton: true });
            console.log('Saving: ', organisationdefault);
            dict.forEach((element, id) => {
                console.log('KALI', element, id);
                this.props.action.saveCalendarAction(element)
                    .then((response) => {
                        if (id == dict.length - 1) {
                            this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                            this.props.action.getOrganisationAction(sessionStorage.getItem("organisationId"))
                                .catch(error => {
                                    console.log('*DEBUG*-Organisation Error', error);
                                });
                            this.props.action.getCalendarHistoriesAction()
                                .catch(error => {
                                    console.log('*DEBUG*-CalendarHistory Error', error);
                                });
                            this.props.action.getCalendarsAction()
                                .catch(error => {
                                    console.log('*DEBUG*-Calendars Error', error);
                                });
                            dict = [];
                        }
                    }).catch(error => {
                        console.log('*DEBUG*-Save AppDetails Error', error);
                        this.setState({ disabledButton: false });
                    });
            });

        } else {
            this.onActive(errortab);
            this.setState({ validating: true });
        }
    }
    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.organisationdata) || dict.length != 0) {
            this.setState({ cancel: true });
        }
        else {
            this.props.action.getOrganisationAction(sessionStorage.getItem("organisationId"))
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getOrgLevelRoute() + '/healthpass');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Organisation Error', error);
                    this.props.history.push(constants.getOrgLevelRoute() + '/healthpass');
                });
        }
    }
    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            //this.props.history.push('/orgsites');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        dict = [];
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    onActive(index) {
        console.log("INDEX NO:", index);
        this.setState({ index: index });
    }
    handlePicker = (type, close) => {
        if (close) {
            this.setState({ showPickColor: false });
        }
        else {
            this.setState({ showPickColor: !this.state.showPickColor, type: type });
        }

    }
    handleChangeSComplete = (color) => {
        var d = new Date(this.selectedDate);
        var now = new Date();
        d.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), 0);
        var getEntryIfExists = this.props.calendarsdata.find(e => {
            var toDate = new Date(e.day);
            return toDate.getFullYear() == d.getFullYear() && toDate.getMonth() == d.getMonth() && toDate.getDate() == d.getDate();
        });
        if (getEntryIfExists /*&& !this.isToday(d)*/) {
            //entry.color = color.hex;
            //PUT
            getEntryIfExists.hexColor = color.hex;
            dict.push(getEntryIfExists);
        }
        else {
            //POST
            dict.push({
                day: d.toISOString(),
                hexColor: color.hex
            });
            //dict.push({
            //    id: "0",
            //    organizationId: sessionStorage.getItem("organisationId"),
            //    locationId: null,
            //    hexColor: color.hex,
            //    day: d.toISOString()
            //});
        }
        this.setState({ color: color.hex });
    }
    isToday(someDate) {
        if (someDate == null)
            return false;
        const today = new Date();
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear();
    }
    updateRetention(evt) { this.props.organisationdata.formRetentionDays = evt.target.value; }
    renderColorTable(list) {

        const COLUMNS = [
            {
                property: 'id',
                header: '',
                primary: true,
                render: entry => <StatusGoodSmall color={entry.hexColor} />
            },
            {
                property: 'date',
                header: 'Date',
                render: entry => <Text>{customDateFormatter.format(entry.date)}</Text>
            },
            /*{
                property: 'time',
                header: 'Time',
                render: entry => <Text>{entry.time}</Text>
            },*/
            {
                property: 'time',
                header: 'Created/Updated',
                render: entry => <Text>{DateFormatter(true).format(new Date(entry.timestamp))}</Text>
            },
            {
                property: 'color',
                header: 'HEX Value',
                render: entry => <Text>{entry.hexColor}</Text>
            },
        ];

        let DATA = [];

        if (list[0] && list[0].count != 0) {
            list.forEach((ev, index) => {
                var d = new Date(ev.day);
                var coru = new Date(ev.timestamp);
                DATA.push({
                    ...ev,
                    date: d,
                    createOrupdate: coru,
                    no: index
                    //time: d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds()
                });
            });
        }

        //console.log('DATA:', DATA);
        DATA.sort((p1, p2) => {
            let d1 = new Date(p1.day);
            let d2 = new Date(p2.day);
            let coru1 = new Date(p1.timestamp);
            let coru2 = new Date(p2.timestamp);
            if (coru1 > coru2) return -1;
            if (coru1 < coru2) return 1;
            if (d1 > d2) return -1;
            if (d1 < d2) return 1;
            return 0;
        });

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                customDateFormatter.format(d.date).toString().toLowerCase().includes(search) ||
                DateFormatter(true).format(new Date(d.timestamp)).toString().toLowerCase().includes(search) ||
                d.hexColor.toLowerCase().includes(search)
            );
        }

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                }))}
                data={DATA}
                noLoadingMsg={this.props.calendarhistoriesdata.length != 0}
                //sortoptions={[
                //    { col: 'date', label: 'Date' },
                //    { col: 'createOrupdate', label: 'Created/Updated' }
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }
    render() {
        const { organisationdata, calendarhistoriesdata, calendarsdata } = this.props;
        const { organisationdataLoading, calendarhistoriesdataLoading, calendarsdataLoading } = this.state;

        if (organisationdataLoading || calendarhistoriesdataLoading || calendarsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        const canEdit = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        var todaydate = new Date();
        todaydate.setHours(0, 0, 0, 0);
        //var today = dict.find(e => e.date.toISOString() == todaydate.toISOString());
        var today = calendarsdata.filter(e => this.isToday(new Date(e.day))/*e.date.getFullYear() == todaydate.getFullYear() && e.date.getMonth() == todaydate.getMonth() && e.date.getDate() == todaydate.getDate()*/);
        if (today)
            today.sort((p1, p2) => { if (p1.date < p2.date) return -1; return 1; });
        today = today[today.length - 1];
        today = today ? today : { hexColor: "white", day: "No colour selected for today" };
        console.log("dict", dict, todaydate, today, calendarsdata);
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2'>Health Pass</Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={() => this.handleCancel()} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={() => this.handleSave()} disabled={this.state.disabledButton} />
                    </Box>
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <Tabs justify="start">
                        <Tab title="Health Pass">
                            <Box pad={{ "vertical": "medium" }} gap="small">
                                <Text>Today's health pass. Only employees who present this upon entry can proceed</Text>
                                <Box width="small" height="small" background={today.hexColor} round="10px" />
                                <Text weight="bold">{today.hexColor != "white" ? customDateFormatter.format(new Date(today.day)) : today.day.toString()}</Text>
                            </Box>
                        </Tab>
                        <Tab title="Configure">
                            <Box pad={{ "vertical": "medium" }}>
                                <Text>Apply health report of the day colour to ensure the effective management of return to work protocols. Each employee will need to present their approved return to work pass to gain entry to the building.</Text>
                                <Text>A different colour should be applied for each day to ensure strict compliance and enforcement</Text>

                                <Box>
                                    <Heading level="4" margin={{ bottom: "none" }}>Return to work form retention</Heading>
                                    <Text>Select the number of days the wellness forms should be retained for your records. Maximum allowed is 21 days</Text>
                                    <Box width="350px">
                                        <FormField error={this.retention_err}>
                                            <StyledBox style1 width="350px">
                                                <TextInput plain type="number" min="0" max="21" defaultValue={organisationdata.formRetentionDays} onChange={evt => this.updateRetention(evt)} />
                                            </StyledBox>
                                        </FormField>
                                    </Box>

                                </Box>

                                <Heading level="4" margin={{ bottom: "none" }}>Return to work Pass Colour</Heading>
                                <Text>Select day to apply a new colour</Text>
                                <Box align="start" gap="small" border="all" style={{ width: "fit-content" }} background="white">
                                    <Calendar
                                        date={this.selectedDate}
                                        daysOfWeek
                                        firstDayOfWeek={1} >
                                        {({ date, day, isSelected }) => {
                                            var d = new Date(date);
                                            d.setHours(0, 0, 0, 0);
                                            var now = new Date();
                                            now.setHours(0, 0, 0, 0);
                                            var getEntryIfExists = this.props.calendarsdata.find(e => {
                                                var toDate = new Date(e.day);
                                                return toDate.getFullYear() == d.getFullYear() && toDate.getMonth() == d.getMonth() && toDate.getDate() == d.getDate();
                                            });
                                            //if (getEntryIfExists)
                                            //    getEntryIfExists.sort((p1, p2) => { if (p1.day < p2.day) return -1; return 1; });
                                            //getEntryIfExists = getEntryIfExists[getEntryIfExists.length - 1];
                                            if (!getEntryIfExists) {
                                                getEntryIfExists = dict.find(e => {
                                                    var toDate = new Date(e.day);
                                                    return toDate.getFullYear() == d.getFullYear() && toDate.getMonth() == d.getMonth() && toDate.getDate() == d.getDate();
                                                });
                                            }
                                            return (
                                                <Box
                                                    data-event={d < now ? 'click' : ''} data-tip={d < now ? getEntryIfExists ? getEntryIfExists.hexColor : "Can not be modified" : ""}
                                                    background={getEntryIfExists ? getEntryIfExists.hexColor : this.isToday(d) ? '#dadada' : 'undefined'}
                                                    width="55px"
                                                    height="55px"
                                                    round='100px'
                                                    fill
                                                    onClick={() => {
                                                        this.selectedDate = date.toISOString();
                                                        if (d < now) {
                                                            //cant modify past date
                                                        }
                                                        else {
                                                            //open picker
                                                            if (getEntryIfExists) {
                                                                this.setState({ color: getEntryIfExists.hexColor });
                                                            }
                                                            this.setState({ showPickColor: !this.state.showPickColor });
                                                        }
                                                    }
                                                    }

                                                >

                                                    <Box align="center" justify="center" width="55px" height="55px">
                                                        <Text size="medium">{day}</Text>
                                                    </Box>

                                                    <ReactTooltip globalEventOff='click' />

                                                </Box>
                                            );
                                        }}
                                    </Calendar>

                                    <Box>
                                        {this.state.showPickColor && (
                                            <Layer
                                                onClickOutside={() => this.handlePicker(null, true)} onEsc={() => this.handlePicker(null, true)} plain={false}>
                                                <SketchPicker
                                                    color={this.state.color}
                                                    disableAlpha={true}
                                                    onChangeComplete={this.handleChangeSComplete} />
                                            </Layer>)}
                                    </Box>
                                </Box>
                            </Box>
                        </Tab>
                        <Tab title="History">
                            <Box pad={{ "vertical": "medium" }}>
                                <StyledBox>
                                    {this.renderColorTable(calendarhistoriesdata)}
                                </StyledBox>
                            </Box>
                        </Tab>
                    </Tabs>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    //const organisationId = constants.organisation.id;
    const organisationId = sessionStorage.getItem("organisationId");
    if (state.selectedOrganisationReducer.organisationdata && organisationId === state.selectedOrganisationReducer.organisationdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedOrganisationReducer.organisationdata));
        return {
            organisationdata: state.selectedOrganisationReducer.organisationdata,
            calendarhistoriesdata: state.calendarHistoryReducer.calendarhistoriesdata,
            calendarsdata: state.calendarsReducer.calendarsdata
        };
    } else {
        return {};
    }
};


const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OrganisationAction, ...CalendarHistoryAction, ...CalendarAction }, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HealthPass));
