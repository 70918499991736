import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserPreferencesAction from '../action/UserPreferencesAction';
import * as ProfileAction from '../action/ProfileAction';
import { Grid, Box, Heading, Tab, Tabs } from 'grommet';
import ProfileListTable from './ProfileListTable';
import UserPreferencesListTable from './UserPreferencesListTable';
import SharingProfileListTable from './SharingProfileListTable';
import PopUpConfirm from './common/PopUpConfirm';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import { Context } from './Context';


//https://myworkplace-itg.intelligentspaces.int.hpe.com/api/tables/LocationSharingUser

export class ProfileListContainer extends React.Component {

    constructor() {
        super();
        this.handleNewSharing = this.handleNewSharing.bind(this);
        this.handleNewUser = this.handleNewUser.bind(this);
        this.handleEditSharing = this.handleEditSharing.bind(this);
        this.handleEditUser = this.handleEditUser.bind(this);
        this.handleDeleteSharing = this.handleDeleteSharing.bind(this);
        this.handleDeleteUser = this.handleDeleteUser.bind(this);
        this.eventDeleteSharing = this.eventDeleteSharing.bind(this);
        this.eventDeleteUser = this.eventDeleteUser.bind(this);
        this.state = {
            selectedProfileSharing: 0,
            selectedProfileUser: 0,
            deletedProfileIdsSharing: [],
            deletedProfileIdsUser: [],
            popupOpenSharing: undefined,
            popupOpenUser: undefined,
            index: 0,
            profilesdataLoading: true,
            userspreferencesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
        //mock data
        this.DATA = [
            { id: 1, username: "Janos Matefi", email: "jm@hpe.com", role: "User", active: true },
            { id: 2, username: "Claire Murray", email: "cm@hpe.com", role: "Event Moderator, Notification Moderator", active: false },
            { id: 3, username: "Kevin Hall", email: "kh@hpe.com", role: "Content Admin, Location Admin", active: true },
            { id: 4, username: "Ruth McEllin Kelly", email: "rmk@hpe.com", role: "Content Admin, Event Moderator", active: true },
            { id: 5, username: "Keith Holland", email: "kh@hpe.com", role: "Admin", active: true },
            { id: 6, username: "Bence Koppany", email: "bk@hpe.com", role: "User", active: false }
        ];
        //mock data    
    }

    componentDidMount() {
        this.props.action.getProfilesAction()
            .then(response => this.setState({ profilesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Users List Error', error);
            });
        this.props.action.getUsersPreferencesAction()
            .then(response => this.setState({ userspreferencesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Users List Error', error);
            });
    }

    onActive(index) {
        this.setState({ index: index });
    }

    handleDeleteSharing(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedProfileSharing: nro });
        this.onOpenSharing();
    }

    handleDeleteUser(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedProfileUser: nro });
        this.onOpenUser();
    }

    eventDeleteSharing(event) {
        const selectedId = this.state.selectedProfileSharing;
        if (selectedId) {
            this.setState({ selectedProfileSharing: 0, popupOpenSharing: undefined, deletedProfileIdsSharing: this.state.deletedProfileIdsSharing.concat([selectedId]) });
            this.props.action.deleteProfileAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- User List Delete Error', error);
                });
        }
        this.onCloseSharing();
    }

    eventDeleteUser(event) {
        const selectedId = this.state.selectedProfileUser;
        this.props.action.getProfileAction(selectedId)
            .then(response => {
                if (this.props.profiledata) {
                    this.props.action.deleteProfileAction(this.props.profiledata.id)
                        .catch(error => {
                            console.log('*DEBUG*- User List Delete Error', error);
                        });
                    this.setState({ selectedProfileSharing: 0, deletedProfileIdsSharing: this.state.deletedProfileIdsSharing.concat([selectedId]) });
                }
            })
        if (selectedId) {
            this.setState({ selectedProfileUser: 0, popupOpenUser: undefined, deletedProfileIdsUser: this.state.deletedProfileIdsUser.concat([selectedId]) });
            this.props.action.deleteUserPreferencesAction(selectedId)
                .catch(error => {
                    console.log('DEBUG2', error);
                });
        }
        this.onCloseUser();
    }

    onOpenSharing = () => this.setState({ popupOpenSharing: true });
    onCloseSharing = () => this.setState({ popupOpenSharing: undefined });

    onOpenUser = () => this.setState({ popupOpenUser: true });
    onCloseUser = () => this.setState({ popupOpenUser: undefined });

    handleNewSharing(event) {
        event.preventDefault();
        this.props.history.push(constants.getOrgLevelRoute() + '/user/0');
    }

    handleNewUser(event) {
        event.preventDefault();
        this.props.history.push(constants.getOrgLevelRoute() + '/user/0');
    }

    handleEditSharing(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push(constants.getOrgLevelRoute() + '/user/' + nro);
    }

    handleEditUser(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push(constants.getOrgLevelRoute() + '/user/' + nro);
    }

    render() {
        const { profilesdata, userspreferencesdata } = this.props;
        const { deletedProfileIdsSharing, deletedProfileIdsUser, profilesdataLoading, userspreferencesdataLoading } = this.state;

        /*//mock data
        const usersdata = [];
        this.DATA.forEach(ev => {
          usersdata.push({ ...ev})
        });
        //mock data*/

        if (profilesdataLoading || userspreferencesdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }

        return (
            <Context.Consumer>
                {values => {
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >App Profiles</Heading>

                </Box>

                <PopUpConfirm open={this.state.popupOpenSharing} text={constants.confirmMessage("sharing profile") + " The user will no longer be able to use the Locate People, find any people on the map or share their location in My Workplace."} onClose={() => this.onCloseSharing()} onDelete={() => this.eventDeleteSharing()} />
                <PopUpConfirm open={this.state.popupOpenUser} text={constants.confirmMessage("profile") + " This will delete the user profile from the Client app and delete all preferences for selected user."} onClose={() => this.onCloseUser()} onDelete={() => this.eventDeleteUser()} />
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <Tabs justify="start" activeIndex={this.state.index} onActive={this.onActive.bind(this)}>
                            <Tab title="User Profiles">
                                <Box>
                                    <IconThemeContext.Extend value={galwayIconTheme}>
                                        <Box>
                                            The following user profiles are created when a user creates a new profile in the My Workplace app.
                                            Once deleted from the table below the user will no longer have any saved preferences in the client and all user data will be deleted.
                                        <UserPreferencesListTable profilesdata={userspreferencesdata} handleNew={this.handleNewUser} handleDelete={this.handleDeleteUser} handleEdit={this.handleEditUser} deletedProfileIds={deletedProfileIdsUser} />
                                        </Box>
                                    </IconThemeContext.Extend>
                                </Box>
                            </Tab>
                        {values.feature.find(f => f.name == "findAFriend").isEnabled && <Tab title="Sharing Profiles">
                            <Box>
                                <IconThemeContext.Extend value={galwayIconTheme}>
                                    <Box>
                                        The following sharing profiles are created when using Locate People.
                                        Once deleted from the table below the user will no longer have any user data stored in the back end.
                                        <SharingProfileListTable profilesdata={profilesdata} handleNew={this.handleNewSharing} handleDelete={this.handleDeleteSharing} handleEdit={this.handleEditSharing} deletedProfileIds={deletedProfileIdsSharing} />
                                    </Box>
                                </IconThemeContext.Extend>
                            </Box>
                        </Tab>
                        }
                    </Tabs>
                </Box>
                <form>


                </form>
            </Grid>
                    );
                }}
            </Context.Consumer>
        );
    }
}



const mapStateToProps = state => ({
    profilesdata: state.profilesReducer.profilesdata,
    userspreferencesdata: state.usersPreferencesReducer.userspreferencesdata,
    profiledata: state.selectedProfileReducer.profiledata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...ProfileAction, ...UserPreferencesAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(ProfileListContainer);
