import * as ActionType from './ActionType';
import CalendarApi from '../api/CalendarApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getCalendarsResponse = calendarsdata => ({
    type: ActionType.GET_CALENDARS_RESPONSE,
    calendarsdata
});

const queuedGetAllCalendars = queueApiCall(CalendarApi.getAllCalendars);

export function getCalendarsAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedGetAllCalendars()
            .then(calendarsdata => {
                dispatch(getCalendarsResponse(calendarsdata));
                return calendarsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewCalendarResponse = () => ({
    type: ActionType.ADD_NEW_CALENDAR_RESPONSE
});

export const updateExistingCalendarResponse = () => ({
    type: ActionType.UPDATE_EXISTING_CALENDAR_RESPONSE
});

export function saveCalendarAction(calendardraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return CalendarApi.saveCalendar(calendardraft)
            .then((response) => {
                if (calendardraft.id) {
                    dispatch(updateExistingCalendarResponse());
                } else {
                    dispatch(addNewCalendarResponse());
                }
                return response;
            }).then((response) => {
                //if (calendardraft.id == "0") {
                //    dispatch(getCalendarAction(calendardraft.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getCalendarResponse = calendardata => ({
    type: ActionType.GET_CALENDAR_RESPONSE,
    calendardata
});

export function getCalendarAction(calendarId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return CalendarApi.getCalendar(calendarId)
            .then(calendardata => {
                dispatch(getCalendarResponse(calendardata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteCalendarResponse = () => ({
    type: ActionType.DELETE_CALENDAR_RESPONSE
});

const queuedDeleteCalendar = queueApiCall(CalendarApi.deleteCalendar);

export function deleteCalendarAction(calendarId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeleteCalendar(calendarId)
            .then((response) => {
                dispatch(deleteCalendarResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getCalendarsAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}