import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SiteAction from '../action/SiteAction';
import OrgSiteListTable from './OrgSiteListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import constants from '../constants';
import RB, { userPermsStatus, getLogonUserPermissions } from './common/RBAC';
import { Redirect } from "react-router-dom";
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';


export class OrgSiteListContainer extends React.Component {

    constructor() {
        super();
        this.sitesfull = [];
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            selectedSite: 0,
            open: undefined,
            loaded: false,
            sitesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    componentDidMount() {
        this.props.action.getSitesQAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.sitesdata && !this.state.loaded) {
            this.setState({ loaded: true });
        };
    }

    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedSite: nro });
        this.onOpen();
    }

    eventDelete(event) {
        var selectedSite = this.props.sitesdata.find(item => item.id == this.state.selectedSite);
        console.log("asdff",selectedSite);
        selectedSite.deleted  = true;
        this.props.action.deleteSiteAction(this.state.selectedSite)
            .catch(error => {
                this.setState({ disabledButton: false });
            });
        //this.props.action.saveSiteAction(selectedSite)
        //.then(() => {
        //    this.props.action.getSitesQAction()
        //        .then(() => {
        //        })
        //        .catch(error => {
        //            console.log('*DEBUG*- Sites List Error', error);
        //        });
        //        }).catch(error => {
        //            this.setState({ disabledButton: false });
        //});
        this.setState({ selectedSite: 0, open: undefined });
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        this.props.history.push(constants.getOrgLevelRoute() + '/orgsite/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        sessionStorage.setItem("locationId", nro);
        const canread = RB.canRead(constants.PERMS.LOC_CONF_SITE);
        console.log('*Switch* started on ', nro, canread);
        this.props.history.push(constants.getOrgLevelRoute() + '/orgsite/' + nro);
    }

    render() {
        const { sitesdata } = this.props;
        const { open, selectedSite, loaded, sitesdataLoading } = this.state;

        //console.log('Render:', sitesdata)

        if (sitesdataLoading || !loaded) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }
        const sitesfull = sitesdata.filter(s => s.organizationId == sessionStorage.getItem("organisationId") && RB.canAccessLocation(s.id)).slice();
        if (!RB.isSuperUser() && sitesfull.length == 0) {
            return (
                <Redirect
                    to={{
                        pathname: "/nouser"
                    }}
                />
            );
        }
        return (
            <Box flex justify='between' fill>
                <form>
                    {open && (
                        <Layer
                            position="center"
                            modal={true}
                            onClickOutside={this.onClose}
                            onEsc={this.onClose}
                        >
                            <Box pad="medium" gap="small" width="medium" >
                                <Heading level={3} margin="none">
                                    Confirm
                                </Heading>
                                <Text weight="bold">Please confirm that you want to delete this Site.</Text>
                                <Text >Note any content associated with this Site will need to be reconfigured. </Text>
                                <Box
                                    tag="footer"
                                    gap="small"
                                    direction="row"
                                    align="center"
                                    justify="end"
                                    pad={{ top: "medium", bottom: "small" }}

                                >
                                    <StyledButton typeCancel label="Cancel" onClick={this.onClose} />
                                    <StyledButton typeSave label="Confirm" onClick={this.eventDelete} />
                                </Box>
                            </Box>
                        </Layer>
                    )}

                    <OrgSiteListTable sitesdata={sitesdata} handleNew={this.handleNew} handleDelete={this.handleDelete} handleEdit={this.handleEdit} />
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    sitesdata: state.sitesReducer.sitesdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators(SiteAction, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(OrgSiteListContainer);
