import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken, getAllRecords } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

const SERVER_URL = `${SERVER}/api/AssetBeaconOverview?`;
console.log('HOST:', SERVER_URL);

class AssetOverviewApi {
    static async getAllAssets(DAYS, BUILDINGID, LABELID) {
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        }

        //return fetch(`${SERVER_URL}/?$count=true&$top=0&${API_VERSION}`, options)
        //    .then(response => response.json())
        //    .then(data => {
        //        return getAllRecords(`${SERVER_URL}days=${DAYS}&buildingId=${BUILDINGID}&labelId=${LABELID}&${API_VERSION}`, options, data.count);
        //    });
        return fetch(`${SERVER_URL}days=${DAYS}&buildingId=${BUILDINGID}&labelId=${LABELID}&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }
}

export default AssetOverviewApi;
