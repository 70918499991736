import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SiteAction from '../action/SiteAction';
// eslint-disable-next-line
import { Trash } from 'grommet-icons';
import { Grid, Box, Heading, Tabs, Tab, Button, Text, FormField, TextInput, Select, CheckBox } from 'grommet';
import TextInputWithState from './common/TextInputWithState';
import constants from '../constants';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { Context } from '../components/Context';
import LoadingIndicator from './common/LoadingIndicator';

var start = undefined;

export class IntegrationMeridianEditContainer extends React.Component {
    static contextType = Context;
    constructor() {
        super();
        this.load = true;
        this.stamp = Date.now().toString();
        this.state = {
            isSaved: false,
            update: false,
            cancel: false,
            disabledButton: false,
            isSaveSuccessful: false,
            viewAppToken: false,
            sitesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    meridianID_err = "";
    appToken_err = "";
    meridianOrgID_err = "";

    ClearErrorMessages() {
        this.meridianID_err = "";
        this.appToken_err = "";
        this.meridianOrgID_err = "";
    }

    handleEdit() {
    }

    handleNew() {
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        this.props.action.getSiteAction(this.props.match.params.locid)
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Site Edit Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.sitesdata && (!_.isEqual(start, this.props.sitesdata))) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    componentDidUpdate(prevProps, prevState) {
    }

    handleSave(context) {
        const sitedefault = this.props.sitesdata;
        let errorsno = 0;
        var errortab = -1;
        this.ClearErrorMessages();
        this.setState({ disabledButton: true });

        if (sitedefault.appToken.trim().length == 0) {
            errorsno++;
            this.appToken_err = constants.EMPTY_ERROR;
            errortab = 0;
        }
        if (sitedefault.appToken.trim().length > 255) {
            errorsno++;
            this.appToken_err = "App Token can't be longer than 255 character";
            errortab = 0;
        }

        if (sitedefault.appID.trim().length == 0) {
            errorsno++;
            this.meridianID_err = constants.EMPTY_ERROR;
            errortab = 0;
        }
        if (sitedefault.appID.trim().length > 16) {
            errorsno++;
            this.meridianID_err = "Organization ID can't be longer than 16 character";
            errortab = 0;
        }

        if (sitedefault.meridianOrgId.trim().length == 0) {
            errorsno++;
            this.meridianOrgID_err = constants.EMPTY_ERROR;
            errortab = 0;
        }
        if (sitedefault.meridianOrgId.trim().length > 16) {
            errorsno++;
            this.meridianOrgID_err = "App Token can't be longer than 255 character";
            errortab = 0;
        }

        if (errorsno == 0) {
            console.log('Save: OK', sitedefault);

            this.props.action.saveSiteAction(sitedefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                    if (response.ok) {
                        context.setPlacemarkEnable(sitedefault.meridianEnabled);
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Save Site Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.onActive(errortab);
            this.setState({ disabledButton: false });
        }

    }
    handleCancel() {
        if (!this.state.cancel && (!_.isEqual(start, this.props.sitesdata))) {
            this.setState({ cancel: true });
        }
        else {
            const siteId = this.props.sitesdata.id;
            this.props.action.getSiteAction(siteId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push({
                            pathname: constants.getSiteLevelRoute() + '/integrations',
                            state: { detail: "Location_Based_Services" }
                        });
                    }
                }).catch(error => {
                    console.log('*DEBUG*-ConfigurationEdit Error', error);
                    this.props.history.push({
                        pathname: constants.getSiteLevelRoute() + '/integrations',
                        state: { detail: "Location_Based_Services" }
                    });
                });
        }
    }
    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        const siteId = this.props.sitesdata.id;
        this.props.history.push({
            pathname: constants.getSiteLevelRoute() + '/integrations',
            state: { detail: "Location_Based_Services" }
        });
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave(context) {
        this.setState({ cancel: false });
        this.handleSave(context);
    }
    onActive(index) {
        this.setState({ index: index });
    }
    updateMeridian() {
        this.props.sitesdata.meridianEnabled = !this.props.sitesdata.meridianEnabled;
        if (this.props.sitesdata.meridianEnabled == true) {
            //this.props.sitesdata.trackAssetsEnabled = true;
            this.ActivateQA("MAP");
            this.ActivateQA("LOCATEPEOPLE");
        }
        else if (this.props.sitesdata.meridianEnabled == false) {
            this.props.sitesdata.trackAssetsEnabled = false;
            this.ResetQA("LOCATEPEOPLE");
            this.ResetQA("MAP");
        }
        this.setState({ update: !this.state.update });
    }
    ResetQA(type) {
        this.props.sitesdata.quickActions.map((item, i) => {
            if (item.defaultType == type) {
                item.active = false;
                //console.log('Reset ', item.defaultType, item.name);
            }
        })
    }
    ActivateQA(type) {
        this.props.sitesdata.quickActions.map((item, i) => {
            if (item.defaultType == type) {
                item.active = true;
                //console.log('Activate ', type, item.name, item.active);
            }
        })
    }
    updateAppTokenView(evt) {
        this.setState({ viewAppToken: evt.target.checked });
    }
    updateAppTokenValue = (e) => {
        this.props.sitesdata.appToken = e.target.value;
    }
    updateAppId(e) {
        e.preventDefault();
        this.props.sitesdata.appID = e.target.value;
        if (this.props.sitesdata.appID.length < 17) {
            this.meridianID_err = "";
        }
        else {
            this.meridianID_err = "Location ID can't be longer than 16 character";
        }
        this.setState({ update: !this.state.update });
    }
    updateOrgId(e) {
        this.props.sitesdata.meridianOrgId = e.target.value;
        if (this.props.sitesdata.meridianOrgId.length < 17) {
            this.meridianOrgID_err = "";
        }
        else {
            this.meridianOrgID_err = "Organization ID can't be longer than 16 character";
        }
        this.setState({ update: !this.state.update });
    }
    updateEUorNon(e) {
        if (e.value == "EU") {
            this.props.sitesdata.eUorNon = true;
        }
        else {
            this.props.sitesdata.eUorNon = false;
        }
        this.setState({ update: true });
    }
    updateUrl = (e) => {
        this.props.sitesdata.url = e.target.value;
    }
    EnableAssetTracking() {
        this.props.sitesdata.trackAssetsEnabled = !this.props.sitesdata.trackAssetsEnabled;
        this.setState({ update: !this.state.update });
    }

    render() {
        const sitedefault = this.props.sitesdata;
        const { sitesdataLoading } = this.state;

        if (sitesdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        //permissions
        const canread1 = RB.canRead(constants.PERMS.LOC_CONF_SITE);
        const canread2 = RB.canRead(constants.PERMS.LOC_CONF_INTEGR); // || true //only perms test
        const canread3 = RB.canRead(constants.PERMS.LOC_CONF_MAPS);
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONF_SITE);
        const canedit2 = RB.canEdit(constants.PERMS.LOC_CONF_INTEGR);
        const canedit3 = RB.canEdit(constants.PERMS.LOC_CONF_MAPS);
        const canedit4 = RB.canEdit(constants.PERMS.ORG_ADMINISTRATION);

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}
                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },
                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                <Context.Consumer>
                    {data =>
                        <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(data)} onDiscard={() => this.onDiscard()} />
                    }
                </Context.Consumer>
                {/* Configuration Header Area */}
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2'>Aruba Meridian</Heading>
                    <Box direction="row" /*pad={{ horizontal: "small", }}*/ gap="small" >
                        <StyledButton typeCancel label="Cancel" style={{ visibility: constants.visible(canedit1 || canedit2 || canedit3) }} onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <Context.Consumer>
                            {data =>
                                <StyledButton typeSave label="Save" style={{ visibility: constants.visible(canedit1 || canedit2 || canedit3) }} onClick={() => { this.handleSave(data) }} disabled={this.state.disabledButton} />
                            }
                        </Context.Consumer>
                    </Box>
                </Box>
                {/* Configuration Main Area */}
                <Box background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium", "bottom": "medium" }}>
                    {canedit2 && (
                        <form>
                            <Tabs flex justify="start" activeIndex={this.state.index} onActive={this.onActive.bind(this)}>
                                <Tab title="Configure">
                                    <StyledBox style1 pad={{ "horizontal": "small" }} margin={{ "top": "small", "bottom": "medium" }}>
                                        <Box width="50px">
                                            <FormField label="Active">
                                                <Box pad="small">
                                                    <CheckBox checked={sitedefault.meridianEnabled} onChange={() => this.updateMeridian()} />
                                                </Box>
                                            </FormField>
                                        </Box>
                                        <FormField label="App Token" error={this.appToken_err}>
                                            <Box direction="row" pad={{ "right": "small" }}>
                                                <TextInputWithState value={this.state.viewAppToken ? sitedefault.appToken : sitedefault.appToken != "" ? "********" : ""} onInput={this.updateAppTokenValue} plain disabled={!canedit4 || !this.state.viewAppToken} />
                                                <CheckBox label="View" checked={this.state.viewAppToken} onChange={evt => this.updateAppTokenView(evt)} />
                                            </Box>
                                        </FormField>

                                        <FormField label="Meridian Location ID" error={this.meridianID_err} >
                                            <TextInput defaultValue={sitedefault.appID} onChange={evt => this.updateAppId(evt)} disabled={!canedit4} />
                                        </FormField>

                                        <FormField label="Meridian Org ID" error={this.meridianOrgID_err} >
                                            <TextInput defaultValue={sitedefault.meridianOrgId} onChange={evt => this.updateOrgId(evt)} disabled={!canedit4} />
                                        </FormField>

                                        <FormField label="EU or Non-EU" >
                                            <Select options={["EU", "Non-EU"]} value={sitedefault.eUorNon ? "EU" : "Non-EU"} onChange={evt => this.updateEUorNon(evt)} disabled={!canedit4} />
                                        </FormField>

                                        <FormField label="Meridian URL (Automatically Generated)" >
                                            <TextInputWithState plain value={sitedefault.url = sitedefault.eUorNon ? "https://edit-eu.meridianapps.com/w/location/" + sitedefault.appID : "https://edit.meridianapps.com/w/location/" + sitedefault.appID} onInput={this.updateUrl} disabled readOnly />
                                        </FormField>

                                        {this.context.feature.find(f => f.name == "trackSiteAssetTracking").isEnabled &&
                                            <Box width="120px">
                                                <FormField label="Enable Asset Tracking">
                                                    <Box pad="small" align="center">
                                                        <CheckBox checked={sitedefault.trackAssetsEnabled} onChange={() => this.EnableAssetTracking()} disabled={!canedit4 || !sitedefault.meridianEnabled} />
                                                    </Box>
                                                </FormField>
                                            </Box>}

                                    </StyledBox>
                                </Tab>
                                <Tab title="Documentation">
                                    <StyledBox pad={{ "horizontal": "small" }} margin={{ "top": "small", "bottom": "medium" }}>
                                        <Text>
                                            Nothing to see here yet, Documentation coming soon
                                        </Text>
                                    </StyledBox>
                                </Tab>
                            </Tabs>
                        </form>
                    )}
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>
            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const siteId = ownProps.match.params.locid || '';
    if (state.selectedSiteReducer.sitedata && siteId == state.selectedSiteReducer.sitedata.id) {
        start = JSON.parse(JSON.stringify(state.selectedSiteReducer.sitedata));
        return {
            sitesdata: state.selectedSiteReducer.sitedata
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SiteAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IntegrationMeridianEditContainer));