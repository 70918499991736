import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as BuildingAction from '../action/BuildingAction';
import * as LevelAction from '../action/LevelAction';
import * as AssetAction from '../action/AssetAction';
import * as AssetsOverviewAction from '../action/AssetsOverviewAction';
import * as DepartmentAction from '../action/DepartmentAction';
import * as CollectionAction from '../action/CollectionAction';
import * as TrackerAction from '../action/TrackerAction';
import * as AssociationAction from '../action/AssociationAction';
import * as SiteAction from '../action/SiteAction';
import * as ManufacturerAction from '../action/ManufacturerAction';
import * as ModelAction from '../action/ModelAction';
import * as TypeAction from '../action/TypeAction';
import OrgAssetListTable from './OrgAssetListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import * as MergedBuildingAction from '../action/MergedBuildingAction';
import * as MergedLevelAction from '../action/MergedLevelAction';

export class OrgAssetListContainer extends React.Component {

    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.filter = "";
        this.state = {
            selectedCategory: 0,
            deletedCategoryId: 0,
            open: undefined,
            mergedbuildingsdataLoading: true,
            mergedlevelsdataLoading: true,
            assetsdataLoading: true,
            assetsoverviewdataLoading: true,
            departmentsdataLoading: true,
            collectionsdataLoading: true,
            associationsdataLoading: true,
            sitesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }
    componentWillReceiveProps(props) {
        console.log("filter", props);
        if (props.location.state && !this.filter) {
            this.filter = props.location.state.filter;
        }
    }

    componentDidMount() {
        /////
        this.props.action.getMergedBuildingsAction()
            .then(response => this.setState({ mergedbuildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
        this.props.action.getMergedLevelsAction()
            .then(response => this.setState({ mergedlevelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        /////
        //this.props.action.getBuildingsAction()
        //    .then(response => this.setState({ buildingsdataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log('*DEBUG*- Buildings List Error', error);
        //    });
        //this.props.action.getLevelsAction()
        //    .then(response => this.setState({ levelsdataLoading: false }))
        //    .catch(error => {
        //        var errorArray = this.state.apiErrorList.concat(error);
        //        this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log('*DEBUG*- Categories List Error', error);
        //    });
        this.props.action.getAssetsAction(false)
            .then(response => this.setState({ assetsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Categories List Error', error);
            });
        this.props.action.getAssetsOverviewAction()
            .then(response => this.setState({ assetsoverviewdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetsOverviewAction List Error', error);
            });
        this.props.action.getDepartmentsAction()
            .then(response => this.setState({ departmentsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department List Error', error);
            });
        this.props.action.getCollectionsAction()
            .then(response => this.setState({ collectionsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Collection List Error', error);
            });
        this.props.action.getAssociationsAction()
            .then(response => this.setState({ associationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Tracker List Error', error);
            });
        this.props.action.getTrackersAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Tracker List Error', error);
            });
        this.props.action.getManufacturersAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Manufacturer List Error', error);
            });
        this.props.action.getModelsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Model List Error', error);
            });
        this.props.action.getTypesAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- DeviceType List Error', error);
            });
    }

    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedCategory: nro });
        this.onOpen();
    }

    eventDelete(event) {
        const selectedId = this.state.selectedCategory;

        if (selectedId) {
            this.setState({ selectedCategory: 0, open: undefined });
            this.setState({ deletedCategoryId: selectedId });
            this.props.action.deleteAssetAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- Asset List Delete Error', error);
                });
        }
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        //this.props.history.push('/category/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        if (sessionStorage.getItem("organisationId"))
            this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/orgasset/' + nro);
        else
            this.props.history.push('/orgasset/' + nro);
    }
    handleBack() {
        this.props.history.push({
            pathname: constants.getSiteLevelRoute() + '/home',
            state: { index: 1 }
        });
    }

    render() {
        const { levelsdata, assetsdata, buildingsdata, assetsoverviewdata, departmentsdata, collectionsdata, associationsdata, trackersdata, sitesdata, mergedbuildingsdata, mergedlevelsdata, manufacturersdata, modelsdata, typesdata } = this.props;
        const { open, selectedCategory, deletedCategoryId, mergedbuildingsdataLoading, mergedlevelsdataLoading, assetsdataLoading, assetsoverviewdataLoading, departmentsdataLoading, collectionsdataLoading, associationsdataLoading, sitesdataLoading } = this.state;

        if (assetsdataLoading || assetsoverviewdataLoading || sitesdataLoading || mergedlevelsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }
        console.log("assetsdata:", assetsdata);
        mergedlevelsdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        this.props.mergedbuildingsdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        return (
            <Box flex justify='between' fill>
                <form>
                    <OrgAssetListTable handleNew={this.handleNew} assetsdata={assetsdata} departmentsdata={departmentsdata} levelsdata={mergedlevelsdata.filter(f => f.meridianId)} buildingsdata={mergedbuildingsdata} syncTime={assetsoverviewdata.assetSyncTimestamp}
                        associationsdata={associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "")}
                        trackersdata={trackersdata} sitesdata={sitesdata}
                        manufacturersdata={manufacturersdata} modelsdata={modelsdata} typesdata={typesdata}
                        handleDelete={this.handleDelete} handleEdit={this.handleEdit} deletedCategoryId={deletedCategoryId} flag={this.filter} handleBack={this.handleBack} collectionsdata={collectionsdata} />
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    buildingsdata: state.buildingsReducer.buildingsdata,
    levelsdata: state.levelsReducer.levelsdata,
    assetsdata: state.assetsReducer.assetsdata,
    assetsoverviewdata: state.assetsOverviewReducer.assetsoverviewdata,
    departmentsdata: state.departmentsReducer.departmentsdata,
    collectionsdata: state.collectionsReducer.collectionsdata,
    associationsdata: state.associationsReducer.associationsdata,
    trackersdata: state.trackersReducer.trackersdata,
    sitesdata: state.sitesReducer.sitesdata,
    mergedlevelsdata: state.mergedLevelsReducer.mergedlevelsdata,
    mergedbuildingsdata: state.mergedBuildingsReducer.mergedbuildingsdata,
    manufacturersdata: state.manufacturersReducer.manufacturersdata,
    modelsdata: state.modelsReducer.modelsdata,
    typesdata: state.typesReducer.typesdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...LevelAction, ...AssetAction, ...BuildingAction, ...AssetsOverviewAction, ...DepartmentAction, ...CollectionAction, ...TrackerAction, ...AssociationAction, ...SiteAction, ...MergedBuildingAction, ...MergedLevelAction, ...ManufacturerAction, ...ModelAction, ...TypeAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(OrgAssetListContainer);
