import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedPlacemarkReducer = (state = initialState.selectedPlacemarkReducer, action) => {
    switch(action.type) {

        case ActionType.GET_PLACEMARK_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                placemarkdata: _.assign(action.placemarkdata)
            };
        }

        default: { return state; }
    }
};


export default selectedPlacemarkReducer;