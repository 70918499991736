import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserAction from '../action/UserAction';
import SuperUserListTable from './ManageUserListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';


export class SuperUserListContainer extends React.Component {

    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            selectedUser: 0,
            deletedUserIds: [],
            open: undefined,
            usersdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    componentDidMount() {
        sessionStorage.setItem("locationId", "");
        sessionStorage.setItem("organisationId", "");
        this.props.action.getUsersAction(true)
            .then(response => this.setState({ usersdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Users List Error', error);
            });
    }

    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedUser: nro });
        this.onOpen();
    }

    eventDelete(event) {
        const selectedId = this.state.selectedUser;

        if (selectedId) {
            this.setState({ selectedUser: 0, open: undefined, deletedUserIds: this.state.deletedUserIds.concat([selectedId]) });
            const user = this.props.usersdata.find(r => r.id == selectedId);
            
            if (user) {
                console.log('Deleting:', user);
                //user.superUser = false;
                //this.props.action.saveSuperUserAction(user, true)
                //    .catch(error => {
                //        console.log('*DEBUG*- Super User List Delete Error', error);
                //    });
                this.props.action.deleteUserAction(user.id, true)
                    .catch(error => {
                        console.log('*DEBUG*- Super User List Delete Error', error);
                    });
            }
        }
        this.onClose();
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        this.props.history.push('/manageuser/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push('/manageuser/' + nro);
    }

    render() {
        const { usersdata } = this.props;
        const { open, selectedUser, deletedUserIds, usersdataLoading } = this.state;

        if (usersdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        return (
            <Box flex justify='between' fill>
                <form>
                    {open && (
                        <Layer
                            position="center"
                            modal={true}
                            onClickOutside={this.onClose}
                            onEsc={this.onClose}
                        >
                            <Box pad="medium" gap="small" width="medium" >
                                <Heading level={3} margin="none">
                                    Warning
                            </Heading>
                                <Text>Deleting a user will revoke their access to all organisations and sites</Text>
                                <Box
                                    tag="footer"
                                    gap="small"
                                    direction="row"
                                    align="center"
                                    justify="end"
                                    pad={{ top: "medium", bottom: "small" }}

                                >
                                    <StyledButton typeCancel label="Cancel" onClick={this.onClose} />
                                    <StyledButton typeSave label="Confirm" onClick={this.eventDelete} />
                                </Box>
                            </Box>
                        </Layer>
                    )}

                    <SuperUserListTable usersdata={usersdata} handleNew={this.handleNew} handleDelete={this.handleDelete} handleEdit={this.handleEdit} deletedUserIds={deletedUserIds} />
                </form>
            </Box>
        );
    }
}

const mapStateToProps = state => ({
    usersdata: state.usersReducer.usersdata
});

const mapDispatchToProps = dispatch => ({
    action: bindActionCreators(UserAction, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperUserListContainer);
