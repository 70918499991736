import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken, getAllRecords } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

const SERVER_URL = `${SERVER}/tables/assetbeacon`;
//const SERVER_URL = `${SERVER}/api/pagedata`;
console.log('HOST:', SERVER_URL);

const apiqueue = [];
const dequeue = () => {
    apiqueue.splice(0, 1);
};

const queueApiCall = (fnApiCall) => {
    return (...args) => {
        let apicall;
        const invokeApi = () => fnApiCall(...args);

        if (apiqueue.length === 0) {
            apicall = invokeApi();
        } else {
            apicall = apiqueue[apiqueue.length - 1].then(invokeApi, invokeApi);
        }
        apiqueue.push(apicall.then(dequeue, dequeue));
        return apicall;
    }
}

const applyHocOnClass = classDef => {
    Object.getOwnPropertyNames(classDef).filter(prop => typeof AssetApi[prop] === 'function').forEach(method => {
        classDef[method] = queueApiCall(classDef[method]);
    });
};

class AssetApi {
    static async getAllAssets(param = "", notOnlyLinked = false) {
        const options = {
            method: 'get',
            headers: await myHeadersWithToken(notOnlyLinked)
        };
        var filter = "";
        if (param === true) {
            filter = `$filter=isControlTag eq true`;
        }
        else if (param === false) {
            filter = `$filter= isControlTag eq false`;
        }
        else {
            //get all
        }
        return fetch(`${SERVER_URL}/?$expand=Map($select=Name;$expand=Group($select=Name))&$count=true&$top=0&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                return getAllRecords(`${SERVER_URL}/?$expand=AssetBeaconTags($expand=Tag),Department,Map($select=Name;$expand=Group($select=Name,Id)),Zone&${filter}&${API_VERSION}`, options, data.count)
                    .catch(error => {
                        throw error;
                    });
            })
            .catch(error => {
                throw error;
            });
    }

    static async getAllMappedAssets(param = "", notOnlyLinked = false) {
        console.log("param", param);
        //var filter = ` and isControlTag eq false`;
        var filter = "";
        if (param === true) {
            filter = ` and isControlTag eq true`;
        }
        else if (param === false) {
            filter = ` and isControlTag eq false`;
        }
        else {

        }
        //function getAllRecords(url, options, recordCount = 0, skipCount = 0, allRaws = []) {
        //    let urlWithSkip = `${url}&$skip=${skipCount}`;
        //    console.log(urlWithSkip, recordCount, skipCount);
        //    return fetch(urlWithSkip, options)
        //        .then(response => response.json())
        //        .then(data => {
        //            allRaws = allRaws.concat(data);
        //            skipCount += data.length;
        //            if (skipCount >= recordCount || data.length === 0) {
        //                return allRaws;
        //            }
        //            else {
        //                return getAllRecords(url, options, recordCount, skipCount, allRaws);
        //            }
        //        });
        //}

        const options = {
            method: 'get',
            headers: await myHeadersWithToken(notOnlyLinked)
        }
        return fetch(`${SERVER_URL}/?$expand=Map($select=Name;$expand=Group($select=Name))&$count=true&$top=0&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                return getAllRecords(`${SERVER_URL}/?$expand=AssetBeaconTags($expand=Tag),Map($select=Name,Id;$expand=Group($select=Name,Id)),Zone&$filter=mapId ne null${filter}&${API_VERSION}`, options, data.count)
                    .catch(error => {
                        throw error;
                    });
            })
            .catch(error => {
                throw error;
            });
    }

    static async saveAsset(pagedata) {
        if (pagedata.id && pagedata.id != "0") {
            return fetch(`${SERVER_URL}/${pagedata.id}/?${API_VERSION}`, {
                method: 'put',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(pagedata)
            }).then(response => response);
        } else {
            return fetch(`${SERVER_URL}/?${API_VERSION}`, {
                method: 'post',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(pagedata)
            }).then(response => response);
        }
    }

    static async addAsset(pagedata) {
        return fetch(`${SERVER_URL}/?${API_VERSION}`, {
            method: 'post',
            headers: await myHeadersWithToken(),
            body: JSON.stringify(pagedata)
        }).then(response => response);
    }

    static async deleteAsset(pageId) {
        const options = {
            method: 'delete',
            headers: await myHeadersWithToken()
        }
        return fetch(`${SERVER_URL}/${pageId}/?${API_VERSION}`, options)
            .then(response => response);
    }


    static async getAsset(pageId) {
        console.log('API:', pageId);
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        }
        var expand = pageId != 0 ? '$expand=AssetBeaconTags($expand=Tag),Department,Map($select=Name;$expand=Group($select=Name)),Zone&' : "";
        return fetch(`${SERVER_URL}/${pageId}/?${expand}${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }
}


applyHocOnClass(AssetApi);

export default AssetApi;
