import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedFormReducer = (state = initialState.selectedFormReducer, action) => {
    switch(action.type) {

        case ActionType.GET_FORM_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                formdata: _.assign(action.formdata)
            };
        }

        default: { return state; }
    }
};


export default selectedFormReducer;