import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SiteAction from '../action/SiteAction';
import * as AppAction from '../action/AppAction';
import * as PageAction from '../action/PageAction';
import * as PlacemarkAction from '../action/PlacemarkAction';
import * as CategoryAction from '../action/CategoryAction';
import * as QuickActionAction from '../action/QuickActionAction';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
// eslint-disable-next-line
import {
    Achievement, Action, Actions, Aed, Aid, Alarm, Alert, Analytics, Announce, Apps, Archive, Article, Atm, Attachment, Attraction, Baby, Bar, BarChart, Basket, Bike, Book, Bookmark, Briefcase, Bug, Bus, Cafeteria,
    Calendar, Camera, Car, Cart, Catalog, Chat, ChatOption, CircleInformation, CircleQuestion, Clear, Clipboard, Clock, Cloud, Compliance, CoatCheck, Configure, Connect, Connectivity, ContactInfo, Contact, CreditCard, Currency,
    Dashboard, Deliver, Deploy, Desktop, Directions, DocumentCloud, DocumentText, Download, Edit, Emergency, Elevator, Escalator, Favorite, Filter, Gallery, Gamepad, Gift, Group, Grow, Help, Home, Image,
    InProgress, Inbox, Info, Java, Launch, Layer, Like, Link, List, Location, Login, Logout, Lounge, MailOption, Map, MapLocation, Microphone, Money, Monitor, More, Multimedia, Music, Navigate, Note, Notification,
    Organization, Overview, Package, PersonalComputer, Phone, PieChart, Pin, Plan, Print, Projects, Refresh, Restaurant, Restroom, RestroomMen, RestroomWomen, Run, Schedule, Scorecard, Secure, Shield, StatusWarning, Steps, Sun,
    Sync, Tag, Task, Template, Train, Trash, TreeOption, Trophy, User, Validate, Video, Vend, View, Waypoint, Wheelchair, Wifi, Workshop, Yoga, Blank, FormUp, FormDown, Mail, FormClose
} from 'grommet-icons';
import { MapCustom } from '../grommet-extras/MapCustomIcon';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, InfiniteScroll, Image as ImageComponent, Tab, Tabs, CheckBox, TextArea, Grommet, RadioButton } from 'grommet';
import constants from '../constants';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as Icons from 'grommet-icons';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import LoadingIndicator from './common/LoadingIndicator';
import ReactTooltip from "react-tooltip";
import { Context } from './Context';
import { ImageHandler } from './common/ImageHandler';

var start = undefined;
var quickactionstart = undefined;

var base64 = null;
const customTheme2 = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        },
    },
    formField: {
        border: {
            color: "transparent",
        },
        pad: "none",
    }
});

var icondata = [
    "Achievement", "Action", "Actions", "Aed", "Aid", "Alarm", "Alert", "Analytics", "Announce", "Apps", "Archive", "Article", "Atm", "Attachment", "Attraction", "Baby", "Bar", "BarChart", "Basket", "Bike", "Book",
    "Bookmark", "Briefcase", "Bug", "Bus", "Cafeteria", "Calendar", "Camera", "Car", "Cart", "Catalog", "Chat", "ChatOption", "CircleInformation", "CircleQuestion", "Clear", "Clipboard", "Clock", "Cloud",
    "Compliance", "CoatCheck", "Configure", "Connect", "Connectivity", "ContactInfo", "Contact", "CreditCard", "Currency", "Dashboard", "Deliver", "Deploy", "Desktop", "Directions", "DocumentCloud", "DocumentText",
    "Download", "Edit", "Emergency", "Elevator", "Escalator", "Favorite", "Filter", "Gallery", "Gamepad", "Gift", "Group", "Grow", "Help", "Home", "Image", "InProgress", "Inbox", "Info", "Java", "Launch", "Layer", "Like",
    "Link", "List", "Location", "Login", "Logout", "Lounge", "MailOption", "Map", "MapCustom", "MapLocation", "Microphone", "Money", "Monitor", "More", "Multimedia", "Music", "Navigate", "Note", "Notification", "Organization",
    "Overview", "Package", "PersonalComputer", "Phone", "PieChart", "Pin", "Plan", "Print", "Projects", "Refresh", "Restaurant", "Restroom", "RestroomMen", "RestroomWomen", "Run", "Schedule", "Scorecard", "Secure", "Shield",
    "StatusWarning", "Steps", "Sun", "Sync", "Tag", "Task", "Template", "Train", "Trash", "TreeOption", "Trophy", "User", "Validate", "Video", "Vend", "View", "Waypoint", "Wheelchair", "Wifi", "Workshop", "Yoga"]

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        },
    }
});

const noBorderTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    formField: {
        border: {
            color: "transparent",
        }
    }
});

export class QuickActionEditContainer extends React.Component {
    static contextType = Context;
    constructor() {
        super();
        this.categoryfull = [];
        this.pagefull = [];
        this.state = {
            isSaved: false,
            options: [],
            pageoptions: [],
            update: false,
            cancel: false,
            disabledButton: false,
            isSaveSuccessful: false,
            quickactions: [],
            type: null,
            typeid: null,
            selectedTile: "",
            contentList: [],
            disableName: false,
            TileList: icondata,
            openIconlist: false,
            search: "",
            sitedataLoading: true,
            pagesdataLoading: true,
            categoriesdataLoading: true,
            placemarksdataLoading: true,
            appsdataLoading: true,
            quickactiondataLoading: true,
            quickactionsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }
    type_err = "";
    name_err = "";
    nickname_err = "";
    description_err = "";
    tile_err = "";

    handleSave() {
        const quickactiondefault = this.props.quickactiondata;
        const sitedefault = this.props.sitedata;
        let errorsno = 0;
        this.ClearErrorMessages();
        //Quick Action Checks
        console.log("qa save:", quickactiondefault);
        if (quickactiondefault.id === "0") {
            if (this.state.contentName === "") {
                errorsno++;
                this.name_err = "Select a Name";
            }
            if (this.state.type == null) {
                errorsno++;
                this.type_err = "Select a Type";
            }
            if (quickactiondefault.name.length > 13) {
                errorsno++;
                this.nickname_err = "Name can't be longer than 13 character";
            }
            if (quickactiondefault.name.length === 0) {
                errorsno++;
                this.nickname_err = "Name is required";
            }
        }
        if (quickactiondefault.name.length > 13) {
            errorsno++;
            this.nickname_err = "Name can't be longer than 13 character";
        }
        if (quickactiondefault.name.length === 0) {
            errorsno++;
            this.nickname_err = "Name is required";
        }
        var actionNames = this.props.quickactionsdata.filter(pa =>/* pa.promoted == 0 &&*/ pa.id != this.props.quickactiondata.id && pa.deleted == 0).map(pa => pa.name.toLowerCase());
        if (actionNames.includes(quickactiondefault.name.toLowerCase().trim())) {
            errorsno++;
            this.nickname_err = "Name is already taken";
        }
        if (quickactiondefault.description.length > 200) {
            errorsno++;
            this.description_err = "Description can't be longer than 200 character";
        }
        if (quickactiondefault.description.length === 0) {
            errorsno++;
            this.description_err = "Description is required";
        }
        //if (typeof (quickactiondefault.tileName) === 'undefined' || quickactiondefault.tileName.length === 0) {
        //    if (quickactiondefault.type != "APPLICATION" && this.state.type != "Application") {
        //        errorsno++;
        //        this.tile_err = "Select an Icon";
        //    }
        //}
        if (quickactiondefault.type != "APPLICATION" && this.state.type != "Application") {
            if (this.validationDisplayMode()) {
                errorsno++;
            }
        }
        this.setState({ update: true });

        if (errorsno == 0) {
            //set priority if new
            if (this.props.match.params.id == 0) {
                let qas = this.props.quickactionsdata.filter(pa => pa.promoted == 0);
                if (qas.length > 0) {
                    let max = qas.reduce((a, b) => a.priority < b.priority ? b : a);
                    quickactiondefault.priority = max.priority + 1;
                }
                else
                    quickactiondefault.priority = 1;
            }
            this.props.action.saveQuickActionAction(quickactiondefault)
                .then((response) => {
                    this.setState({ isSaveSuccessful: response.ok, isSaved: true });
                    //this.props.history.push("/eventstable");
                }).catch(error => {
                    console.log("*DEBUG*-Save QuickActionEdit Error", error);
                    this.setState({ disabledButton: false, notification: constants.DATA_ERROR });
                });
        }

    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        this.props.action.getQuickActionAction(this.props.match.params.id)
            .then(response => this.setState({ quickactiondataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Pages List Error', error);
            });
        this.props.action.getQuickActionsAction()
            .then(response => this.setState({ quickactionsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Pages List Error', error);
            });
        this.props.action.getSiteAction(sessionStorage.getItem("locationId"))
            .then(response => this.setState({ sitedataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Site Edit Error', error);
            });
        this.props.action.getPagesAction()
            .then(response => this.setState({ pagesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Pages List Error', error);
            });
        this.props.action.getCategoriesAction()
            .then(response => this.setState({ categoriesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-PageEdit/Category Error', error);
            });
        this.props.action.getPlacemarksAction()
            .then(response => this.setState({ placemarksdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-PageEdit/Category Error', error);
            });
        this.props.action.getAppsAction()
            .then(response => this.setState({ appsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-AppCentreEdit/App Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && (!_.isEqual(start, this.props.quickactiondata))) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }


    }
    componentWillReceiveProps(props) {
        if (this.props.quickactiondata) {
            if (this.props.quickactiondata.id === "0") {
                this.props.quickactiondata.active = true;
            }
            quickactionstart = this.props.quickactiondata;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.categoriesdata[0] && this.props.categoriesdata[0].count != 0 && this.props.categoriesdata.length > 0 && this.categoryfull.length == 0) {
            this.categoryfull = this.props.categoriesdata.slice();

            var sp = this.categoryfull.find(d => d.name == "Spaces" && d.level == 0);
            var spi = this.categoryfull.indexOf(sp);
            this.categoryfull.splice(spi, 1);
            var ri = this.categoryfull.find(d => d.name == "Report Issue" && d.level == 0);
            var i = this.categoryfull.indexOf(ri);
            this.categoryfull.splice(i, 1);
            var info = this.categoryfull.find(d => d.name == "Info" && d.level == 0);
            var index = this.categoryfull.indexOf(info);
            this.categoryfull.splice(index, 1);
            var ri2 = this.categoryfull.find(d => d.name == "Report Issue" && d.level == 2);
            var i2 = this.categoryfull.indexOf(ri2);
            this.categoryfull.splice(i2, 1);
            var info2 = this.categoryfull.find(d => d.name == "Info" && d.level == 2);
            var index2 = this.categoryfull.indexOf(info2);
            this.categoryfull.splice(index2, 1);
            this.categoryfull.forEach(item => item.name = constants.APP_LPN(item.location, item.parent, item.name));
            this.categoryfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            this.setState({ options: this.categoryfull });
        }
        if (this.props.pagesdata[0] && this.props.pagesdata[0].count != 0 && this.props.pagesdata.length > 0 && this.pagefull.length == 0) {
            this.pagefull = this.props.pagesdata.slice();
            this.pagefull.push({
                id: null,
                name: "",
            });
            this.pagefull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            this.setState({ pageoptions: this.pagefull });
        }
    }

    ClearErrorMessages() {
        this.name_err = "";
        this.type_err = "";
        this.nickname_err = "";
        this.description_err = "";
        this.tile_err = "";
    }
    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.quickactiondata)) {
            this.setState({ cancel: true });
        }
        else {
            const siteId = this.props.sitedata.id;
            this.props.action.getSiteAction(siteId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push({ pathname: constants.getSiteLevelRoute() + '/actioncentre', state: { tabindex: 0 } });
                    }
                }).catch(error => {
                    console.log('*DEBUG*-QuickActionEdit Error', error);
                    this.props.history.push({ pathname: constants.getSiteLevelRoute() + '/actioncentre', state: { tabindex: 0 } });
                });
        }
    }

    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push({ pathname: constants.getSiteLevelRoute() + '/actioncentre', state: { tabindex: 0 } });
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }

    onActive(index) {
        this.setState({ index: index });
    }
    selectContent(type) {
        if (type === "Category") {
            return this.categoryfull.map(item => item).sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        }
        if (type === "Page") {
            return this.props.pagesdata.map(item => item).sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        }
        if (type === "Placemark") {
            return this.props.placemarksdata.map(item => item).sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        }
        if (type == "Application") {
            return this.props.appsdata.filter(item => !this.props.quickactionsdata.map(qa => qa.name).includes(item.masterApp.name)).map(item => item).sort((p1, p2) => { if (p1.masterApp.name.toLowerCase() < p2.masterApp.name.toLowerCase()) return -1; return 1; });
        }
    }
    selectName(option) {
        this.name_err = "";
        var full = this.selectContent(this.state.type);
        this.props.quickactiondata.foreignId = this.state.contentList[option.selected].id;
        this.setState(prevState => ({
            typeid: this.state.contentList[option.selected].id,
            isNameSelected: true,
            contentName: option.option
        }));
        if (this.props.quickactiondata.type == "Application") {
            this.props.quickactiondata.name = option.option;
        }
        else {
            this.props.quickactiondata.name = "";
        }
    }
    clearSearch() {
        this.setState({ search: "" });
        this.setState({ TileList: icondata });
    }
    selectedIcon(name, iconColor = "") {
        //Map is treated differently as its not part of the grommet-icons
        if (name == "MapCustom")
            return <MapCustom color={iconColor}/>
        var IconName = Icons[name];
        //Check if that icon exists. If grommet-icons remove icon(s) that we are using, make sure it would not crash the page
        if (IconName)
            return <IconName color={iconColor} />
        if (Blank)
            return <Blank color={iconColor} />
        return <div />
    }
    //selectIcon(name) {
    //    if (name === "Achievement") {
    //        return (<Achievement />);
    //    }
    //    if (name === "Action") {
    //        return (<Action />);
    //    }
    //    if (name === "Actions") {
    //        return (<Actions />);
    //    }
    //    if (name === "Aed") {
    //        return (<Aed />);
    //    }
    //    if (name === "Aid") {
    //        return (<Aid />);
    //    }
    //    if (name === "Alarm") {
    //        return (<Alarm />);
    //    }
    //    if (name === "Alert") {
    //        return (<Alert />);
    //    }
    //    if (name === "Analytics") {
    //        return (<Analytics />);
    //    }
    //    if (name === "Announce") {
    //        return (<Announce />);
    //    }
    //    if (name === "Apps") {
    //        return (<Apps />);
    //    }
    //    if (name === "Archive") {
    //        return (<Archive />);
    //    }
    //    if (name === "Article") {
    //        return (<Article />);
    //    }
    //    if (name === "Atm") {
    //        return (<Atm />);
    //    }
    //    if (name === "Attachment") {
    //        return (<Attachment />);
    //    }
    //    if (name === "Attraction") {
    //        return (<Attraction />);
    //    }
    //    if (name === "Baby") {
    //        return (<Baby />);
    //    }
    //    if (name === "Bar") {
    //        return (<Bar />);
    //    }
    //    if (name === "BarChart") {
    //        return (<BarChart />);
    //    }
    //    if (name === "Basket") {
    //        return (<Basket />);
    //    }
    //    if (name === "Bike") {
    //        return (<Bike />);
    //    }
    //    if (name === "Book") {
    //        return (<Book />);
    //    }
    //    if (name === "Bookmark") {
    //        return (<Bookmark />);
    //    }
    //    if (name === "Briefcase") {
    //        return (<Briefcase />);
    //    }
    //    if (name === "Bus") {
    //        return (<Bus />);
    //    }
    //    if (name === "Bug") {
    //        return (<Bug />);
    //    }
    //    if (name === "Cafeteria") {
    //        return (<Cafeteria />);
    //    }
    //    if (name === "Calendar") {
    //        return (<Calendar />);
    //    }
    //    if (name === "Camera") {
    //        return (<Camera />);
    //    }
    //    if (name === "Car") {
    //        return (<Car />);
    //    }
    //    if (name === "Cart") {
    //        return (<Cart />);
    //    }
    //    if (name === "Catalog") {
    //        return (<Catalog />);
    //    }
    //    if (name === "Chat") {
    //        return (<Chat />);
    //    }
    //    if (name === "ChatOption") {
    //        return (<ChatOption />);
    //    }
    //    if (name === "CircleInformation") {
    //        return (<CircleInformation />);
    //    }
    //    if (name === "CircleQuestion") {
    //        return (<CircleQuestion />);
    //    }
    //    if (name === "Clear") {
    //        return (<Clear />);
    //    }
    //    if (name === "Clipboard") {
    //        return (<Clipboard />);
    //    }
    //    if (name === "Clock") {
    //        return (<Clock />);
    //    }
    //    if (name === "Cloud") {
    //        return (<Cloud />);
    //    }
    //    if (name === "Compliance") {
    //        return (<Compliance />);
    //    }
    //    if (name === "CoatCheck") {
    //        return (<CoatCheck />);
    //    }
    //    if (name === "Configure") {
    //        return (<Configure />);
    //    }
    //    if (name === "Connect") {
    //        return (<Connect />);
    //    }
    //    if (name === "Connectivity") {
    //        return (<Connectivity />);
    //    }
    //    if (name === "ContactInfo") {
    //        return (<ContactInfo />);
    //    }
    //    if (name === "Contact") {
    //        return (<Contact />);
    //    }
    //    if (name === "CreditCard") {
    //        return (<CreditCard />);
    //    }
    //    if (name === "Currency") {
    //        return (<Currency />);
    //    }
    //    if (name === "Dashboard") {
    //        return (<Dashboard />);
    //    }
    //    if (name === "Deliver") {
    //        return (<Deliver />);
    //    }
    //    if (name === "Deploy") {
    //        return (<Deploy />);
    //    }
    //    if (name === "Desktop") {
    //        return (<Desktop />);
    //    }
    //    if (name === "Directions") {
    //        return (<Directions />);
    //    }
    //    if (name === "DocumentCloud") {
    //        return (<DocumentCloud />);
    //    }
    //    if (name === "DocumentText") {
    //        return (<DocumentText />);
    //    }
    //    if (name === "Download") {
    //        return (<Download />);
    //    }
    //    if (name === "Edit") {
    //        return (<Edit />);
    //    }
    //    if (name === "Emergency") {
    //        return (<Emergency />);
    //    }
    //    if (name === "Elevator") {
    //        return (<Elevator />);
    //    }
    //    if (name === "Escalator") {
    //        return (<Escalator />);
    //    }
    //    if (name === "Favorite") {
    //        return (<Favorite />);
    //    }
    //    if (name === "Filter") {
    //        return (<Filter />);
    //    }
    //    if (name === "Gallery") {
    //        return (<Gallery />);
    //    }
    //    if (name === "Gamepad") {
    //        return (<Gamepad />);
    //    }
    //    if (name === "Gift") {
    //        return (<Gift />);
    //    }
    //    if (name === "Group") {
    //        return (<Group />);
    //    }
    //    if (name === "Grow") {
    //        return (<Grow />);
    //    }
    //    if (name === "Help") {
    //        return (<Help />);
    //    }
    //    if (name === "Home") {
    //        return (<Home />);
    //    }
    //    if (name === "Image") {
    //        return (<Image />);
    //    }
    //    if (name === "InProgress") {
    //        return (<InProgress />);
    //    }
    //    if (name === "Inbox") {
    //        return (<Inbox />);
    //    }
    //    if (name === "Info") {
    //        return (<Info />);
    //    }
    //    if (name === "Java") {
    //        return (<Java />);
    //    }
    //    if (name === "Launch") {
    //        return (<Launch />);
    //    }
    //    if (name === "Layer") {
    //        return (<Layer />);
    //    }
    //    if (name === "Like") {
    //        return (<Like />);
    //    }
    //    if (name === "Link") {
    //        return (<Link />);
    //    }
    //    if (name === "List") {
    //        return (<List />);
    //    }
    //    if (name === "Location") {
    //        return (<Location />);
    //    }
    //    if (name === "Login") {
    //        return (<Login />);
    //    }
    //    if (name === "Logout") {
    //        return (<Logout />);
    //    }
    //    if (name === "Lounge") {
    //        return (<Lounge />);
    //    }
    //    if (name === "Mail") {
    //        return (<Mail />);
    //    }
    //    if (name === "MailOption") {
    //        return (<MailOption />);
    //    }
    //    if (name === "Map") {
    //        return (<Map />);
    //    }
    //    if (name === "MapCustom") {
    //        return (<MapCustom />);
    //    }
    //    if (name === "MapLocation") {
    //        return (<MapLocation />);
    //    }
    //    if (name === "Microphone") {
    //        return (<Microphone />);
    //    }
    //    if (name === "Money") {
    //        return (<Money />);
    //    }
    //    if (name === "Monitor") {
    //        return (<Monitor />);
    //    }
    //    if (name === "More") {
    //        return (<More />);
    //    }
    //    if (name === "Multimedia") {
    //        return (<Multimedia />);
    //    }
    //    if (name === "Music") {
    //        return (<Music />);
    //    }
    //    if (name === "Navigate") {
    //        return (<Navigate />);
    //    }
    //    if (name === "Note") {
    //        return (<Note />);
    //    }
    //    if (name === "Notification") {
    //        return (<Notification />);
    //    }
    //    if (name === "Organization") {
    //        return (<Organization />);
    //    }
    //    if (name === "Overview") {
    //        return (<Overview />);
    //    }
    //    if (name === "PersonalComputer") {
    //        return (<PersonalComputer />);
    //    }
    //    if (name === "Phone") {
    //        return (<Phone />);
    //    }
    //    if (name === "PieChart") {
    //        return (<PieChart />);
    //    }
    //    if (name === "Pin") {
    //        return (<Pin />);
    //    }
    //    if (name === "Plan") {
    //        return (<Plan />);
    //    }
    //    if (name === "Print") {
    //        return (<Print />);
    //    }
    //    if (name === "Projects") {
    //        return (<Projects />);
    //    }
    //    if (name === "Refresh") {
    //        return (<Refresh />);
    //    }
    //    if (name === "Restaurant") {
    //        return (<Restaurant />);
    //    }
    //    if (name === "Restroom") {
    //        return (<Restroom />);
    //    }
    //    if (name === "RestroomMen") {
    //        return (<RestroomMen />);
    //    }
    //    if (name === "RestroomWomen") {
    //        return (<RestroomWomen />);
    //    }
    //    if (name === "Run") {
    //        return (<Run />);
    //    }
    //    if (name === "Schedule") {
    //        return (<Schedule />);
    //    }
    //    if (name === "Scorecard") {
    //        return (<Scorecard />);
    //    }
    //    if (name === "Secure") {
    //        return (<Secure />);
    //    }
    //    if (name === "Shield") {
    //        return (<Shield />);
    //    }
    //    if (name === "StatusWarning") {
    //        return (<StatusWarning />);
    //    }
    //    if (name === "Steps") {
    //        return (<Steps />);
    //    }
    //    if (name === "Sync") {
    //        return (<Sync />);
    //    }
    //    if (name === "Tag") {
    //        return (<Tag />);
    //    }
    //    if (name === "Task") {
    //        return (<Task />);
    //    }
    //    if (name === "Template") {
    //        return (<Template />);
    //    }
    //    if (name === "Train") {
    //        return (<Train />);
    //    }
    //    if (name === "Trash") {
    //        return (<Trash />);
    //    }
    //    if (name === "Trophy") {
    //        return (<Trophy />);
    //    }
    //    if (name === "TreeOption") {
    //        return (<TreeOption />);
    //    }
    //    if (name === "User") {
    //        return (<User />);
    //    }
    //    if (name === "Validate") {
    //        return (<Validate />);
    //    }
    //    if (name === "Video") {
    //        return (<Video />);
    //    }
    //    if (name === "Vend") {
    //        return (<Vend />);
    //    }
    //    if (name === "View") {
    //        return (<View />);
    //    }
    //    if (name === "Waypoint") {
    //        return (<Waypoint />);
    //    }
    //    if (name === "Wheelchair") {
    //        return (<Wheelchair />);
    //    }
    //    if (name === "Wifi") {
    //        return (<Wifi />);
    //    }
    //    if (name === "Workshop") {
    //        return (<Workshop />);
    //    }
    //    if (name === "Yoga") {
    //        return (<Yoga />);
    //    }
    //    else {
    //        return (<Blank />);
    //    }
    //}
    renderValue(name) {
        //console.log(name);
        var Ico = Icons[name];
        if (name == "MapCustom")
            Ico = MapCustom;
        return (
            <Box gap="small" align="center" pad="small" border={this.props.quickactiondata.tileName == name ? { color: "brand", size: "medium" } : { color: "white", size: "medium" }} onClick={evt => this.onSelectIcons(name)}>
                {/*this.selectIcon(name)*/}
                <Ico color="#444444"/*{this.props.quickactiondata.tileName == name ? "#01a982" : "#444444"}*/ />
                <Text color="#444444"/*{this.props.quickactiondata.tileName == name ? "#01a982" : "#444444"}*/>
                    {name}
                </Text>
            </Box>);
    }
    onSelectIcons(name) {
        this.props.quickactiondata.tileName = name;
        this.validationDisplayMode();
        this.setState({ openIconlist: false });
    }
    updateDescription(evt) {
        evt.persist();
        if (evt.target.value.length < 201) {
            this.description_err = "";
        }
        else {
            this.description_err = "Description can't be longer than 200 character";
        }
        this.props.quickactiondata.description = evt.target.value;
        this.setState({ updated: true });
    }
    lookUpName() {
        if (this.props.quickactiondata.type == "CATEGORY" && this.props.categoriesdata.find(c => c.id == this.props.quickactiondata.foreignId)) {
            return this.props.categoriesdata.find(c => c.id == this.props.quickactiondata.foreignId).name
        }
        else if (this.props.quickactiondata.type == "PAGE" && this.props.pagesdata.find(c => c.id == this.props.quickactiondata.foreignId)) {
            return this.props.pagesdata.find(c => c.id == this.props.quickactiondata.foreignId).name
        }
        else if (this.props.quickactiondata.type == "PLACEMARK" && this.props.placemarksdata.find(c => c.id == this.props.quickactiondata.foreignId)) {
            return this.props.placemarksdata.find(c => c.id == this.props.quickactiondata.foreignId).name
        }
        else if (this.props.quickactiondata.type == "APPLICATION" && this.props.appsdata.find(a => a.id == this.props.quickactiondata.foreignId)) {
            return this.props.appsdata.find(a => a.id == this.props.quickactiondata.foreignId).masterApp.name
        }
        else {
            return ""
        }
    }
    updateNickName(evt) {
        evt.persist();
        if (evt.target.value.length < 14) {
            this.nickname_err = "";
        }
        else {
            this.nickname_err = "Name can't be longer than 13 character";
        }

        var actionNames = this.props.quickactionsdata.filter(pa => /*pa.promoted == 0 &&*/ pa.id != this.props.quickactiondata.id && pa.deleted == 0).map(pa => pa.name.toLowerCase());

        if (actionNames.includes(evt.target.value.toLowerCase().trim())) {
            this.nickname_err = "Name is already taken";
        }

        this.props.quickactiondata.name = evt.target.value;
        this.setState({ updated: true });
    }
    removeOption(array, item) {
        var index = array.indexOf(item);

        if (index > -1) {
            array.splice(index, 1);
        }

        return array
    }
    QATypeOptions() {
        var options = ["Category", "Page", "Placemark", "Application"]

        if (!this.context.feature.find(f => f.name == "siteMap").isEnabled || !this.props.sitedata.meridianEnabled) {
            options = this.removeOption(options, "Placemark");
        }

        return options
    }
    updateQAType(option) {
        this.type_err = "";

        if (this.state.placemarksdataLoading && option.option == "Placemark") {
            this.setState({ disableName: true })
        }
        else {
            this.setState({ disableName: false })
        }

        this.setState(prevState => ({
            contentName: "",
            isNameSelected: false,
            contentList: this.selectContent(option.option),
            type: option.option,
        }));
        this.props.quickactiondata.type = option.option;
        this.setState({ updated: true });
    }
    namePlaceholder() {
        var type = this.state.type;

        if (type == "Category") {
            return "Select a Category"
        }
        else if (type == "Page") {
            return "Select a Page"
        }
        else if (type == "Placemark") {
            if (this.state.disableName) {
                return "Loading"
            }
            else {
                return "Select a Placemark"
            }
        }
        else if (type == "Application") {
            return "Select an Application"
        }
    }
    nameTooltip() {
        var type = this.state.type;

        if (type == "Category") {
            return "There are currently no Categories in this site. You must create them before you create a Quick Action."
        }
        else if (type == "Page") {
            return "There are currently no Pages in this site. You must create them before you create a Quick Action."
        }
        else if (type == "Placemark") {
            return "There are currently no placemarks in this site. Ensure these are in place before you create a Quick Action."
        }
        else if (type == "Application") {
            return "There are currently no Applications in this site. You must create them before you create a Quick Action."
        }
    }
    updateDisplayMode() {
        const { quickactiondata } = this.props;
        quickactiondata.useImage = !quickactiondata.useImage;
        this.validationDisplayMode();
        this.setState({ updated: true });
    }
    validationDisplayMode() {
        const { quickactiondata } = this.props;
        const actionType = quickactiondata.promoted ? "Promoted" : "Quick";
        let error = true;
        if (quickactiondata.useImage && ((quickactiondata.imageUrl == "" || quickactiondata.imageUrl == null) && !quickactiondata.imageContent)) { //Display Mode == Image
            this.displaytype_err = `An Image has not been added for this ${actionType} Action, please upload one now`;
            error = true;
        }
        else if (!quickactiondata.useImage && (quickactiondata.tileName == "" || quickactiondata.tileName == null)) { //Display Mode == Icon
            this.displaytype_err = `An Icon has not been added for this ${actionType} Action, please choose one from the available icons`;
            error = true;
        }
        else {
            this.displaytype_err = "";
            error = false;
        }
        return error;
    }
    setImageContent = (content) => {
        if (content == null)
            this.props.quickactiondata.imageUrl = content;
        this.props.quickactiondata.imageContent = content;
        this.validationDisplayMode();
        this.setState({ updated: true });
    }

    render() {
        const sitedefault = this.props.sitedata;
        const placemarksdata = this.props.placemarksdata;
        const quickactiondata = this.props.quickactiondata;
        const quickactionsdata = this.props.quickactionsdata;

        const { sitedataLoading, pagesdataLoading, categoriesdataLoading, placemarksdataLoading, appsdataLoading, quickactiondataLoading, quickactionsdataLoading } = this.state;

        if (sitedataLoading || pagesdataLoading || categoriesdataLoading || placemarksdataLoading || appsdataLoading || quickactiondataLoading || quickactionsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        const actionType = quickactiondata.promoted ? "Promoted" : "Quick";

        const canread1 = RB.canRead(constants.PERMS.LOC_CONF_SITE);
        const canread2 = RB.canRead(constants.PERMS.LOC_CONF_INTEGR); // || true //only perms test
        const canread3 = RB.canRead(constants.PERMS.LOC_CONF_MAPS);
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONF_SITE);
        const canedit2 = RB.canEdit(constants.PERMS.LOC_CONF_INTEGR);
        const canedit3 = RB.canEdit(constants.PERMS.LOC_CONTENT);

        if (quickactiondata && quickactiondata.type == "Placemark" && placemarksdata[0] && placemarksdata[0].count != 0) {
            if (this.state.disableName) {
                this.updateQAType({ option: "Placemark" });
                this.setState({ disableName: false })
            }
        }

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />

                <Box gridArea='header' direction="row"
                    align="center"
                    pad={{ "left": "medium", "right": "small" }}
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >{actionType} Action - {quickactiondata.id === "0" ? "Create" : "Edit"} </Heading>
                    <Box direction="row" pad={{ horizontal: "small" }} gap="small" >
                        {!(canedit1 || canedit2 || canedit3) && (
                            <StyledButton typeCancel style={{ visibility: constants.visible(!(canedit1 || canedit2 || canedit3)) }} label="Back" onClick={() => { this.handleCancel() }} />
                        )}
                        {(canedit1 || canedit2 || canedit3) && (
                            <Box gap="small" direction="row">
                                <StyledButton typeCancel style={{ visibility: constants.visible(canedit1 || canedit2 || canedit3) }} label="Cancel" onClick={() => { this.handleCancel() }} disabled={this.state.disabledButton} />
                                <StyledButton typeSave label="Save" style={{ visibility: constants.visible(canedit1 || canedit2 || canedit3) }} onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" margin={{ "bottom": "large" }}>
                    <form>
                        <Box pad={{ vertical: "small" }}> <Text>All fields marked with an * are mandatory.</Text>
                            <Text size="large">Description</Text>
                        </Box>
                        <StyledBox style1 pad={{ "horizontal": "small" }} margin={{ "bottom": "medium" }}>
                            <Box width="50px">
                                <FormField label="Active">
                                    <Box pad="small">
                                        <CheckBox checked={quickactiondata.active} disabled={!(canedit1 || canedit2 || canedit3)}
                                            onChange={(option) => { this.setState({ updated: true }); quickactiondata.active = !quickactiondata.active }} />
                                    </Box>
                                </FormField>
                            </Box>
                            {quickactiondata.id === "0" ?
                                <Box>
                                    <FormField label="Quick Action Type *" error={this.type_err}>
                                        <Select placeholder="Select Content Type" value={quickactiondata.type} options={this.QATypeOptions()} onChange={(option) => this.updateQAType(option)} />
                                    </FormField>
                                    {this.state.type &&
                                        <FormField label="Name *" error={this.name_err} disabled={this.state.disableName}>
                                            <a data-for="QA_name" data-tip={this.nameTooltip()} width="100%">
                                                <Box>
                                                    <Select plain placeholder={this.namePlaceholder()} disabled={this.state.disableName} value={this.state.contentName}
                                                        options={this.state.type != "Application" ? this.state.contentList.map(item => item.name) : this.state.contentList.map(item => item.masterApp.name)}
                                                        onChange={(option) => this.selectName(option)}
                                                        onClose={() => this.setState({ contentList: this.selectContent(this.state.type) })}
                                                        onSearch={(searchText) => {
                                                            const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                            var filtered = [];
                                                            if (this.state.type == "Application") {
                                                                filtered = this.selectContent(this.state.type).filter(o => o.masterApp.name.match(regexp));
                                                            }
                                                            else {
                                                                filtered = this.selectContent(this.state.type).filter(o => o.name.match(regexp));
                                                            }
                                                            this.setState({ contentList: filtered });
                                                        }} />
                                                    <ReactTooltip disable={this.state.contentList.length != 0 || placemarksdataLoading} id="QA_name" place="bottom" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                </Box>
                                            </a>
                                        </FormField>
                                    }
                                    {this.state.isNameSelected && (
                                        <FormField label="ShortName *" error={this.nickname_err}>
                                            <TextInput value={quickactiondata.name} onChange={evt => this.updateNickName(evt)} disabled={quickactiondata.type == "Application"} />
                                        </FormField>
                                    )}
                                </Box>
                                :
                                <Box>
                                    <FormField label="Type">
                                        <TextInput value={quickactiondata.type.charAt(0) + quickactiondata.type.slice(1).toLowerCase()} readOnly disabled />
                                    </FormField>
                                    {quickactiondata.type != "DEFAULT" &&
                                        <FormField label="Name" /*error={this.nickname_err}*/>
                                            <TextInput value={this.lookUpName()} readOnly disabled />
                                        </FormField>}
                                    <FormField label="ShortName" error={this.nickname_err}>
                                        <TextInput value={quickactiondata.name} onChange={evt => this.updateNickName(evt)}
                                            disabled={!(canedit1 || canedit2 || canedit3) || constants.DEFAULT_QUICK_ACTIONS.includes(quickactiondata.defaultType) || quickactiondata.type == "APPLICATION"} />
                                    </FormField>
                                </Box>}

                            <FormField label="Description *" error={this.description_err}>
                                <TextArea value={quickactiondata.description} onChange={evt => this.updateDescription(evt)} disabled={!(canedit1 || canedit2 || canedit3) || constants.DEFAULT_QUICK_ACTIONS.includes(quickactiondata.defaultType)} />
                            </FormField>

                            {quickactiondata.type != "APPLICATION" && this.state.type != "Application" &&
                                <Box>
                                <FormField label="Icon *" error={this.tile_err}>
                                        <Box justify="between" height="50px" direction="row" align="center" onClick={() => this.setState({ openIconlist: !this.state.openIconlist })}>
                                            {quickactiondata.tileName != "" &&
                                                <Box margin="small" align="center" justify="center" direction="row" gap="small">
                                                    {this.selectedIcon(quickactiondata.tileName)}
                                                    <Text>
                                                        {quickactiondata.tileName}
                                                    </Text>
                                                </Box>}
                                            {quickactiondata.tileName == "" &&
                                                <Box margin="small" align="center" justify="center" direction="row" gap="small">
                                                    <Text color="#999999">Select an Icon</Text>
                                                </Box>}

                                            <Box pad={{ "right": "small" }} align="center" justify="center">
                                                {!this.state.openIconlist && <FormDown />}
                                                {this.state.openIconlist && <FormUp />}
                                            </Box>
                                        </Box>

                                    {this.state.openIconlist &&
                                        <Box>
                                            <Box margin="small" direction="row" gap="small">
                                                <TextInput placeholder="Search" value={this.state.search} onChange={evt => {
                                                    this.setState({ search: evt.target.value })
                                                    const regexp = new RegExp(evt.target.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                    const filtered = icondata.filter(o => o.match(regexp));
                                                    this.setState({ TileList: filtered });
                                                }}
                                                />
                                                <Button icon={<FormClose />} plain onClick={() => this.clearSearch()} />
                                            </Box>

                                            <Box style={{ minHeight: '80vh' }}>
                                                <Grommet theme={customTheme}>
                                                    <Grid columns="small" rows="xsmall">
                                                        <InfiniteScroll step={this.state.TileList.length} items={this.state.TileList} >
                                                            {icon => this.renderValue(icon)}
                                                        </InfiniteScroll>
                                                    </Grid>
                                                </Grommet>
                                            </Box>
                                        </Box>}
                                </FormField>
                                <ImageHandler setImageContent={this.setImageContent} imageUrl={quickactiondata.imageUrl} type={actionType} />
                                <Grommet theme={customTheme2}>
                                    <Box direction="row">
                                        <Box flex="shrink">
                                            <FormField label={
                                                <Box direction="row" gap="xsmall">
                                                    <Box>Display Mode</Box>
                                                    <Box justify="center" data-for="img_info" data-tip={`Choose a display mode to show either an icon or image with this ${actionType} Action in the client.`}>
                                                        <CircleInformation size="small" />
                                                        <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" />
                                                    </Box>
                                                </Box>} error={this.displaytype_err}>
                                                <Box direction="row" gap="medium" pad={{ "bottom": "xsmall" }}>
                                                    <Box gap="xsmall" align="center">
                                                        Icon
                                                            <RadioButton checked={!quickactiondata.useImage} disabled={!canedit1} onChange={() => this.updateDisplayMode()} />
                                                    </Box>
                                                    <Box gap="xsmall" align="center">
                                                        Image
                                                            <RadioButton checked={quickactiondata.useImage} disabled={!canedit1} onChange={() => this.updateDisplayMode()} />
                                                    </Box>
                                                </Box>
                                            </FormField>
                                        </Box>
                                    </Box>
                                </Grommet>
                                </Box>}
                        </StyledBox>
                    </form>
                </Box>
            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const QuickActionId = ownProps.match.params.id || '';
    if (state.selectedQuickActionReducer.quickactiondata && QuickActionId == state.selectedQuickActionReducer.quickactiondata.id) {
        start = JSON.parse(JSON.stringify(state.selectedQuickActionReducer.quickactiondata));
        return {
            sitedata: state.selectedSiteReducer.sitedata,
            organisationdata: state.selectedOrganisationReducer.organisationdata,
            pagesdata: state.pagesReducer.pagesdata,
            categoriesdata: state.categoriesReducer.categoriesdata,
            placemarksdata: state.placemarksReducer.placemarksdata,
            appsdata: state.appsReducer.appsdata,
            quickactiondata: state.selectedQuickActionReducer.quickactiondata,
            quickactionsdata: state.quickActionsReducer.quickactionsdata
        };
    } else {
        return {
            pagesdata: state.pagesReducer.pagesdata,
            categoriesdata: state.categoriesReducer.categoriesdata,
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SiteAction, ...CategoryAction, ...PageAction, ...PlacemarkAction, ...AppAction, ...QuickActionAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuickActionEditContainer));