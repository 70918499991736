import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image, CheckBox, Select } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, CircleInformation, Download, FormClose } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import { CSVLink } from "react-csv";
import PrimaryFilter from "./common/PrimaryFilter";
import { Context } from './Context';

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class TrackerListTable extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.count = 0;
        this.dtwrapper = React.createRef();
        this.Exp_Data = [];
        this.state = {
            select: [],
            search: "",
            isFilterOpen: false,
            collection: "",
            collectionId: "",
            department: "",
            departmentId: "",
            link: "",
            class: "",
            type: "",
            manufacturerId: "",
            manufacturer: "",
            modelId: "",
            model: "",
            deviceTypeId: "",
            deviceType: "",
            firstLoad: true
        };
    }
    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }
    clicked = false;

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(id);
        }
    }

    onDTUpdated() {
        let self = this;
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (nro != 0) { //this is for the select function
                        if (elem.getElementsByTagName('th')[0]) {
                            let tid = elem.getElementsByTagName('th')[0].textContent;
                            //element.addEventListener("click", function (evt) {
                            //    if (self.clicked)
                            //        return
                            //    else
                            //        self.clicked = true;
                            //    evt.preventDefault();
                            //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                            //    handleEdit(tid);
                            //});
                            element.removeEventListener("click", this.externalFunc);
                            element.addEventListener("click", this.externalFunc);
                        }
                        element.addEventListener("mouseover", function (evt) {
                            evt.preventDefault();
                            elem.style.background = "#E6E6E6";
                            elem.style.cursor = 'pointer';
                        });
                        element.addEventListener("mouseout", function (evt) {
                            evt.preventDefault();
                            elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                            elem.style.cursor = 'default';
                        });
                    }
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }
    renderFilters() {
        //const typeList = this.state.class == "" ? ["Employee", "Visitor", "Contractor", "Other", "Critical"] : this.state.class == "Person" ? ["Employee", "Visitor", "Contractor", "Other"] : ["Critical"];
        const typeList = this.state.class == "" ? [] : this.state.class == "Person" ? ["Employee", "Visitor", "Contractor", "Other"] : ["Critical"];
        return (
            <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="small">
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Class" value={this.state.class} options={this.context.feature.find(f => f.name == "sitePersonTracking").isEnabled ? ["Person", "Asset"] : ["Asset"]} onChange={o => { this.setState({ class: o.option }); }} />
                    </StyledBox>
                    {this.state.class && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ class: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Manufacturer" value={this.state.manufacturer} options={this.props.manufacturersdata} labelKey="name"
                            onChange={o => {
                                this.setState({ manufacturerId: o.option.id, manufacturer: o.option });
                            }} />
                    </StyledBox>
                    {this.state.manufacturerId && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ manufacturerId: "", manufacturer: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Model" value={this.state.model} options={this.props.modelsdata} labelKey="name"
                            onChange={o => {
                                this.setState({ modelId: o.option.id, model: o.option });
                            }} />
                    </StyledBox>
                    {this.state.modelId && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ modelId: "", model: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Model Type" value={this.state.deviceType} options={this.props.typesdata} labelKey="name"
                            onChange={o => {
                                this.setState({ deviceTypeId: o.option.id, deviceType: o.option });
                            }} />
                    </StyledBox>
                    {this.state.deviceTypeId && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ deviceTypeId: "", deviceType: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Type" value={this.state.type} options={typeList} onChange={o => { this.setState({ type: o.option }); }} />
                    </StyledBox>
                    {this.state.type && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ type: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Collection" value={this.state.collection} options={this.props.collectionsdata} onChange={evt => this.setState({ collection: evt.option, collectionId: evt.option.id })} labelKey="name" />
                    </StyledBox>
                    {this.state.collection && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ collection: "", collectionId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Department" value={this.state.department} options={this.props.departmentsdata} onChange={evt => this.setState({ department: evt.option, departmentId: evt.option.id })} labelKey="name" />
                    </StyledBox>
                    {this.state.department && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ department: "", departmentId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select" value={this.state.link} options={["Linked", "Unlinked"]} onChange={o => { this.setState({ link: o.option }); }} />
                    </StyledBox>
                    {this.state.link != "" && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ link: "" })}
                    />}
                </Box>
            </Box>
        )
    }
    filterData(data) {
        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            data = data.filter(d =>
                d.id.toLowerCase().includes(search) ||
                d.lcName.includes(search) ||
                d.lcClass.includes(search) ||
                d.lcType.includes(search) ||
                d.lcCollection.includes(search) ||
                d.lcDepartment.includes(search) ||
                d.tagName.toLowerCase().includes(search) ||
                ("linked".includes(search) && d.isAssigned) ||
                constants.formatDate(d.updatedAt).toLowerCase().includes(search)
            );
        }
        if (this.state.collectionId) {
            data = data.filter(d => d.collectionId == this.state.collectionId);
        }
        if (this.state.departmentId) {
            data = data.filter(d => d.departmentId == this.state.departmentId);
        }
        if (this.state.class == "Asset") {
            data = data.filter(d => d.class == this.state.class);
        }
        if (this.state.class == "Person") {
            data = data.filter(d => d.class == this.state.class);
        }
        if (this.state.manufacturerId) {
            data = data.filter(d => d.manufacturerId == this.state.manufacturerId);
        }
        if (this.state.modelId) {
            data = data.filter(d => d.modelId == this.state.modelId);
        }
        if (this.state.deviceTypeId) {
            data = data.filter(d => d.deviceTypeId == this.state.deviceTypeId);
        }
        if (this.state.type) {
            data = data.filter(d => d.type == this.state.type);
        }
        if (this.state.link === "Linked") {
            data = data.filter(d => d.isAssigned == true);
        }
        else if (this.state.link === "Unlinked") {
            data = data.filter(d => d.isAssigned == false);
        }
        return data;
    }
    updateFilteredColumns = (data) => this.setState({ filteredColumns: data });

    renderTrackerTable(props) {
        let COLUMNS = [
            {
                property: 'id',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: s => <Text hidden>{s.id}</Text>
            },
            {
                property: 'lcName',
                header: 'Name',
                render: t => <Text>{t.name}</Text>,
                isFixed: true
            },
            {
                property: 'lcClass',
                header: 'Class',
                render: t => <Text>{t.class}</Text>
            },
            {
                property: 'lcManufacturer',
                header: 'Manufacturer',
                render: t => <Text>{t.manufacturerName}</Text>
            },
            {
                property: 'lcModel',
                header: 'Model',
                render: t => <Text>{t.modelName}</Text>
            },
            {
                property: 'lcDeviceType',
                header: 'Model Type',
                render: t => <Text>{t.deviceType}</Text>
            },
            {
                property: 'lcType',
                header: 'Type',
                render: t => <Text>{t.type}</Text>
            },
            {
                property: 'lcCollection',
                header: 'Collection',
                render: t => <Text>{t.collectionName}</Text>
            },
            {
                property: 'lcDepartment',
                header: 'Department',
                render: t => <Text>{t.departmentName}</Text>
            },
            {
                property: 'isAssigned',
                header: 'Linked',
                size: "55px",
                render: t => <CheckBox checked={t.isAssigned} disabled />
            },
            {
                property: 'lcTagName',
                header: 'Tag Name',
                render: t => <Text> {t.tagName}</Text>,
                isFixed: true
            },
            {
                property: 'updatedAt',
                header: 'Last Modified',
                render: t => <Text>{constants.formatDate(t.updatedAt)}</Text>
            },
            {
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: t => (!RB.canEdit(constants.PERMS.LOC_CONTENT)) ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, t.id)} icon={<Blank />} /></Box>
                    :
                    <Box width="24px" direction='row' gap="xsmall">
                        {/*<Button plain disabled={props.deletedTrackerIds.includes(t.id)} onClick={evt => props.handleEdit(t.id)} icon={<Edit />} />*/}
                        <Button plain disabled={props.deletedTrackerIds.includes(t.id)} onClick={evt => { this.setState({ select: this.state.select.filter(id => id != t.id) }); props.handleDelete("tracker", t.id); }} icon={<Trash />} />
                    </Box>
            },
        ];

        if (this.state.firstLoad) {
            let newCols = constants.createFilters(COLUMNS);
            newCols.map(e => {
                e.header == "Manufacturer" || e.header == "Model" || e.header == "Model Type" ? e.isActive = !e.isActive : e.isActive;
            });
            this.setState({ firstLoad: false, filteredColumns: newCols });
        }

        let DATA = [];
        this.Exp_Data = [];
        if (props.trackersdata[0] && props.trackersdata[0].count != 0) {
            props.trackersdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            props.trackersdata.forEach(ev => {

                let collectionName = ev.collectionId && props.collectionsdata && props.collectionsdata[0] && props.collectionsdata[0].count != 0 ? props.collectionsdata.find(d => d.id == ev.collectionId) : '';
                collectionName = collectionName ? collectionName.name : collectionName;
                let departmentName = ev.departmentId && props.departmentsdata && props.departmentsdata[0] && props.departmentsdata[0].count != 0 ? props.departmentsdata.find(d => d.id == ev.departmentId) : '';
                departmentName = departmentName ? departmentName.name : departmentName;
                let tag = props.associationsdata && props.associationsdata[0] && props.associationsdata[0].count != 0 ? props.associationsdata.find(a => a.assetId == ev.id) : false;
                let isAssigned = tag ? true : false;
                let manufacturerName = ev.manufacturerId && props.manufacturersdata[0] && props.manufacturersdata[0].count != 0 ? props.manufacturersdata.find(d => d.id == ev.manufacturerId) : '';
                manufacturerName = manufacturerName ? manufacturerName.name : manufacturerName;
                let modelName = ev.modelId && props.modelsdata[0] && props.modelsdata[0].count != 0 ? props.modelsdata.find(d => d.id == ev.modelId) : '';
                modelName = modelName ? modelName.name : modelName;
                let deviceTypeName = ev.deviceTypeId && props.typesdata && props.typesdata[0] && props.typesdata[0].count != 0 ? props.typesdata.find(d => d.id == ev.deviceTypeId) : '';
                deviceTypeName = deviceTypeName ? deviceTypeName.name : deviceTypeName;
                tag = tag ? tag.beaconName : "";
                DATA.push({
                    ...ev,
                    lcName: ev.name ? ev.name.toLowerCase() : '',
                    lcClass: ev.class ? ev.class.toLowerCase() : '',
                    lcType: ev.type ? ev.type.toLowerCase() : '',
                    departmentName: departmentName,
                    collectionName: collectionName,
                    lcDepartment: departmentName ? departmentName.toLowerCase() : '',
                    lcCollection: collectionName ? collectionName.toLowerCase() : '',
                    isAssigned: isAssigned,
                    manufacturerName: manufacturerName,
                    modelName: modelName,
                    deviceType: deviceTypeName,
                    lcManufacturer: manufacturerName ? manufacturerName.toLowerCase() : '',
                    lcModel: modelName ? modelName.toLowerCase() : '',
                    lcDeviceType: deviceTypeName ? deviceTypeName.toLowerCase() : '',
                    tagName: tag,
                    lcTagName: tag.toLowerCase()
                });
                this.Exp_Data.push({
                    "ID": ev.id,
                    "Tracker Name": ev.name,
                    "Class": ev.class,
                    "Type": ev.type,
                    "Collection Name": collectionName,
                    "Department Name": departmentName,
                    "Linked": isAssigned,
                    "Manufacturer": manufacturerName,
                    "Model": modelName,
                    "Model Type": deviceTypeName,
                    "Tag Name": tag,
                    "Last Modified At (UTC)": ev.updatedAt
                });
            });
        }
        //FILTER
        DATA = this.filterData(DATA);
        this.count = DATA.length;
        console.log('DATA:', DATA);

        if (this.state.filteredColumns)
            COLUMNS = constants.filterActiveColumns(COLUMNS, this.state.filteredColumns);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcName" || c.property === "lcClass" || c.property === "lcType" || c.property === "lcDepartment"
                }))}
                data={DATA}
                noLoadingMsg={this.props.trackersdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcName', label: 'Tracker Name' },
                //    { col: 'lcClass', label: 'Class' },
                //    { col: 'lcType', label: 'Type' },
                //    { col: 'lcDepartment', label: 'Department' },
                //    { col: 'updatedAt', label: 'Last Modified' }
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                select={this.props.select}
                onSelect={list => { this.props.onChangeSelect(list, this.count), this.setState({ select: list }); }}
                onUpdated={this.onDTUpdated.bind(this)}
                styled={true}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}
                PFonClick={() => this.setState({ isFilterOpen: true })}
                PFopen={this.state.isFilterOpen}
                PFonClose={() => this.setState({ isFilterOpen: false })}
                PFonResetFilter={() => this.setState({ collection: "", collectionId: "", department: "", departmentId: "", link: "", type: "", class: "", manufacturerId: "", manufacturer: "", modelId: "", model: "", deviceTypeId: "", deviceType: "" })}
                PFisClearVisible={(
                    this.state.collection == "" &&
                    this.state.collectionId == "" &&
                    this.state.department == "" &&
                    this.state.departmentId == "" &&
                    this.state.link == "" &&
                    this.state.class == "" &&
                    this.state.type == "" &&
                    this.state.manufacturer == "" &&
                    this.state.model == "" &&
                    this.state.deviceType == ""
                ) ? false : true}
                PFcsvdata={this.Exp_Data}
                PFcsvfilename="sitetrackers-export"
                PFFileters={this.renderFilters()}
                PFsearchable={true}
                PFfilterable={true}
                PFdownloadable={this.count > 0 ? 1 : 2}
                PFfilterablecolumns={true}
                PFcolumns={this.state.filteredColumns ? this.state.filteredColumns : constants.createFilters(COLUMNS)}
                PfonChangeColumns={this.updateFilteredColumns}
            />
        );
    }

    render() {
        const typeList = this.state.class == "" ? ["Employee", "Visitor", "Contractor", "Other", "Critical"] : this.state.class == "Person" ? ["Employee", "Visitor", "Contractor", "Other"] : ["Critical"];
        let measure = "1340px";
        let activeOnes = this.state.filteredColumns ? this.state.filteredColumns.filter(f => f.isActive == false) : null;
        if (activeOnes) {
            measure = activeOnes.length <= 1 ? "1760px" : measure;
        }
        return (
            <Box /*gap="small"*/>
                {/*<Box width="100%" direction="row" justify="between" pad={{ "top": "small" }}>
                    <StyledBox style1 width="80%">
                        <TextInput placeholder="Search by Tracker name, ID or classification" value={this.state.search} onChange={e => this.setState({ search: e.target.value })} />
                    </StyledBox>
                    <Box width="100px" alignSelf="center" align="center">
                        <CSVLink data={this.Exp_Data} filename="Exported Datatable.csv">
                            <a data-for="Download Info" data-tip="Export to CSV" >
                                <Button plain icon={<Download color={constants.HPE_GREEN} />} alignSelf="center" />
                            </a>
                            <ReactTooltip id="Download Info" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        </CSVLink>
                    </Box>
                </Box>*/}
                {/*<PrimaryFilter*/}
                {/*    placeholder="Search by Tracker name, ID or classification"*/}
                {/*    search={this.state.search}*/}
                {/*    onChange={e => this.setState({ search: e.target.value })}*/}
                {/*    onClick={() => this.setState({ isFilterOpen: true })}*/}
                {/*    open={this.state.isFilterOpen}*/}
                {/*    onClose={() => this.setState({ isFilterOpen: false })}*/}
                {/*    onResetFilter={() => this.setState({ collection: "", collectionId: "", department: "", departmentId: "", link: "", type: "", class: "" })}*/}
                {/*    isClearVisible={(*/}
                {/*        this.state.collection == "" &&*/}
                {/*        this.state.collectionId == "" &&*/}
                {/*        this.state.department == "" &&*/}
                {/*        this.state.departmentId == "" &&*/}
                {/*        this.state.link == "" &&*/}
                {/*        this.state.class == "" &&*/}
                {/*        this.state.type == ""*/}
                {/*    ) ? false : true}*/}
                {/*    csvdata={this.Exp_Data}*/}
                {/*    downloadable*/}
                {/*>*/}
                {/*    <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="medium">*/}

                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Class" value={this.state.class} options={["Person", "Asset"]} onChange={o => { this.setState({ class: o.option }); }} />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.class && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ class: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Type" value={this.state.type} options={typeList} onChange={o => { this.setState({ type: o.option }); }} />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.type && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ type: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Collection" value={this.state.collection} options={this.props.collectionsdata} onChange={evt => this.setState({ collection: evt.option, collectionId: evt.option.id })} labelKey="name" />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.collection && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ collection: "", collectionId: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Department" value={this.state.department} options={this.props.departmentsdata} onChange={evt => this.setState({ department: evt.option, departmentId: evt.option.id })} labelKey="name" />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.department && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ department: "", departmentId: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select" value={this.state.link} options={["Linked", "Unlinked"]} onChange={o => { this.setState({ link: o.option }); }} />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.link != "" && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ link: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*</PrimaryFilter>*/}
                <StyledBox ref={this.dtwrapper} style={{ "min-width": measure }}>
                    {this.renderTrackerTable(this.props)}
                </StyledBox>
                {/*this.state.select.length > 0*/false && <Box /*style1gray*/ background="light-4" width="100%" justify="between" direction="row" pad="small" /*style={{ borderRadius: "5px 5px 5px 5px", boxShadow: "2px 2px 4px 2px rgba(100, 100, 100, 0.35)" }}*/>
                    <Box direction="row" gap="medium">
                        <Box>
                            {this.state.select.length} of {this.count} selected
                    </Box>
                        <Box border={{ side: "bottom", size: "small", color: "black" }} onClick={() => this.setState({ select: [] })}>
                            Clear
                    </Box>
                    </Box>
                    {!RB.canEdit(constants.PERMS.LOC_CONTENT) ?
                        <Box direction='row'>
                            <Button plain style={{ cursor: 'inherit' }} icon={<Blank />} />
                        </Box>
                        : <Box direction='row' gap="xsmall">
                            <Button plain /*disabled={props.deletedTrackerIds.includes(t.id)}*/ onClick={() => this.props.handleBulkEdit(this.state.select)} icon={<Edit />} />
                            <Button plain /*disabled={props.deletedTrackerIds.includes(t.id)}*/ onClick={() => this.props.handleDelete("trackers", this.state.select)} icon={<Trash />} />
                        </Box>
                    }
                </Box>}
            </Box>
        );
    }
}

export default TrackerListTable;
