import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import * as SiteAction from '../action/SiteAction';
import validator from 'validator';
import constants from '../constants';
//eslint-disable-next-line
//eslint-disable-next-line
import { Grid, Box, Heading, FormField, DataTable, CheckBox, Text, Tab, Tabs } from 'grommet';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import withPagination from './common/PaginatedDataTable';
import TextInputWithState from './common/TextInputWithState';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { isNumber } from 'util';
import LoadingIndicator from './common/LoadingIndicator';


const PaginatedDataTable = withPagination(DataTable);

var start = undefined;

export class OrgIntegrationTeemEditContainer extends React.Component {


    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            validating: false, selimage: 0, value: "", isSaved: false, cancel: false, disabledButton: false, isSaveSuccessful: false,
            index: 0,
            tabindex: 0,
            viewTeenClientId: false,
            viewTeemClientSecret: false,
            viewTeemCallbackURL: false,
            viewTeemAuthGrantType: false,
            viewTeemOrgId: false,
            viewTeemUserName: false,
            viewTeemPassword: false,
            organisationdataLoading: true,
            sitesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Error handling
    //TAB 1
    teemClientId_err = "";
    teemClientSecret_err = "";
    teemCallbackURL_err = "";
    teemGrantType_err = "";
    teemCompanyId_err = "";

    //TAB 2

    eventDelete(event) {
        //const selectedId = this.state.selectedSite;

        //if (selectedId) {
        //    this.setState({ selectedSite: 0, open: undefined });
        //    this.props.action.deleteSiteAction(selectedId)
        //        .catch(error => {
        //            console.log('*DEBUG*- Site List Delete Error', error);
        //        });
        //}
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => {
        this.setState({ isSaved: false, disabledButton: false })
        const id = this.props.organisationdata.id;
        this.props.history.push({
            pathname: constants.getOrgLevelRoute() + '/orgintegrations',
            state: { detail: "Space Management" }
        });
    }

    handleNew(event) {

    }

    handleEdit(event, nro) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.orgid;
        this.props.action.getOrganisationAction(id)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
        this.props.action.getSitesQAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.organisationdata && !_.isEqual(start, this.props.organisationdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    clearErrorMessages() {
        //TAB 1
        this.teemClientId_err = "";
        this.teemClientSecret_err = "";
        this.teemCallbackURL_err = "";
        this.teemGrantType_err = "";
        this.teemCompanyId_err = "";

        //TAB 2
    }

    handleSave() {
        const organisationdefault = this.props.organisationdata;
        //checking data

        console.log('*DEBUG*-Started saving:', organisationdefault);
        //Validating fields
        let errorsno = 0;
        var errortab = -1;
        this.clearErrorMessages();
        this.setState({ validating: false });

        if (organisationdefault.teemClientId.trim().length == 0) {
            errorsno++;
            this.teemClientId_err = constants.EMPTY_ERROR;
            errortab = 0;
        }
        if (organisationdefault.teemClientId.trim().length > 100) {
            errorsno++;
            this.teemClientId_err = "Client ID can't be longer than 100 character";
            errortab = 0;
        }
        if (organisationdefault.teemClientSecret.trim().length == 0) {
            errorsno++;
            this.teemClientSecret_err = constants.EMPTY_ERROR;
            errortab = 0;
        }
        if (organisationdefault.teemClientSecret.trim().length > 300) {
            errorsno++;
            this.teemClientSecret_err = "Client Secret can't be longer than 300 character";
            errortab = 0;
        }
        //if (organisationdefault.teemCallbackURL.trim().length == 0) {
        //    errorsno++;
        //    this.teemCallbackURL_err = constants.EMPTY_ERROR;
        //    errortab = 0;
        //}
        //if (organisationdefault.teemCallbackURL.trim().length > 511) {
        //    errorsno++;
        //    this.teemCallbackURL_err = "Callback URL can't be longer than 511 character";
        //    errortab = 0;
        //}
        //if (organisationdefault.teemAuthGrantType.trim().length == 0) {
        //    errorsno++;
        //    this.teemGrantType_err = constants.EMPTY_ERROR;
        //    errortab = 0;
        //}
        //if (organisationdefault.teemAuthGrantType.trim().length > 255) {
        //    errorsno++;
        //    this.teemGrantType_err = "Client ID can't be longer than 255 character";
        //    errortab = 0;
        //}
        if (organisationdefault.teemOrgId == null || organisationdefault.teemOrgId == "") {
            errorsno++;
            errortab = 0;
            this.teemCompanyId_err = constants.EMPTY_ERROR;
        }
        if (!Number.isInteger(organisationdefault.teemOrgId) && organisationdefault.teemOrgId != null && organisationdefault.teemOrgId != "") {
            errorsno++;
            errortab = 0;
            this.teemCompanyId_err = constants.NUMBER_ONLY_ERROR;
        }
        if (organisationdefault.teemOrgId < -2147483648 || organisationdefault.teemOrgId > 2147483647) {
            errorsno++;
            errortab = 0;
            this.teemCompanyId_err = constants.INT_ERROR;
        }
        //if (organisationdefault.teemUserName.trim().length == 0) {
        //    errorsno++;
        //    this.teemUserName_err = constants.EMPTY_ERROR;
        //    errortab = 0;
        //}
        if (organisationdefault.teemUserName.trim().length > 100) {
            errorsno++;
            this.teemUserName_err = "Username can't be longer than 100 character";
            errortab = 0;
        }
        //if (organisationdefault.teemPassword.trim().length == 0) {
        //    errorsno++;
        //    this.teemPassword_err = constants.EMPTY_ERROR;
        //    errortab = 0;
        //}
        if (organisationdefault.teemPassword.trim().length > 100) {
            errorsno++;
            this.teemPassword_err = "Password can't be longer than 100 character";
            errortab = 0;
        }

        if (errorsno == 0) {
            //Ok, saving data

            this.setState({ disabledButton: true });
            console.log('Saving:', organisationdefault);
            this.props.action.saveOrganisationAction(organisationdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                }).catch(error => {
                    console.log('*DEBUG*-Save OrgSettings Edit Error', error);
                    this.setState({ disabledButton: false });
                });
            this.props.sitesdata.map((location) => {
                if (location.organizationId == sessionStorage.getItem("organisationId")) {
                    this.props.action.saveSiteAction(location)
                        .then(() => {
                        }).catch(error => {
                            console.log('*DEBUG*-Save Sites Error', error);
                            this.setState({ disabledButton: false });
                        });
                }

            });
        } else {
            this.onActive(errortab);
            this.setState({});
        }
    }
    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.organisationdata)) {
            this.setState({ cancel: true });
        }
        else {
            const id = this.props.organisationdata.id;
            this.props.action.getOrganisationAction(id)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push({
                            pathname: constants.getOrgLevelRoute() + '/orgintegrations',
                            state: { detail: "Space Management" }
                        });
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Org Settings Edit Error', error);
                    this.props.history.push({
                        pathname: constants.getOrgLevelRoute() + '/orgintegrations',
                        state: { detail: "Space Management" }
                    });;
                });
        }
    }

    handleBrowserBack(location, action) {
        console.log("handleBrowserBack props...: ", this.props);
        if (!_.isEqual(start, this.props.organisationdata)) {
            return 'Are you sure you want to leave this page? Unsaved data on the form.';
        }
    }

    DisableBookARoom(location) {
        if (location.bookARoomEnabled) {
            location.bookARoomEnabled = false;
            this.ResetQA(location, "BOOKSPACE"); //Book a Room
        }
    }

    ResetQA(location, type) {
        location.quickActions.map((item, i) => {
            if (item.defaultType == type) {
                item.active = false;
            }
        });
    }

    //Handling data
    updateTeem = (e) => {
        this.props.organisationdata.teemEnabled = !this.props.organisationdata.teemEnabled;
        if (this.props.organisationdata.teemEnabled) { //if Teem is turned on, Microsoft 365 is turned off
            this.props.organisationdata.graphEnabled = false;
        }
        if (!this.props.organisationdata.teemEnabled) {
            this.props.sitesdata.map((location, i) => {
                //this.DisableBookADesk(location); //Book a Desk removed from the portal
                //this.DisableBookARoom(location);
            });
        }
        this.setState({ update: !this.state.update });
    }
    updateClientId = (e) => {
        this.props.organisationdata.teemClientId = e.target.value;
    }
    updateClientSecret = (e) => {
        this.props.organisationdata.teemClientSecret = e.target.value;
    }
    updateCallbackUrl = (e) => {
        this.props.organisationdata.teemCallbackURL = e.target.value;
    }
    updateGrantType = (e) => {
        this.props.organisationdata.teemAuthGrantType = e.target.value;
    }
    updateCompanyId = (e) => {
        if (e.target.value == "") {
            this.props.organisationdata.teemOrgId = null;
        } else {
            this.props.organisationdata.teemOrgId = isNaN(+e.target.value) ? e.target.value : +e.target.value;
        }
    }
    updateUserName = (e) => {
        this.props.organisationdata.teemUserName = e.target.value;
    }
    updatePassword = (e) => {
        this.props.organisationdata.teemPassword = e.target.value;
    }

    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    onActive(index) {
        console.log("INDEX NO:", index);
        this.setState({ index: index });
    }

    render() {
        const organisationdefault = this.props.organisationdata;
        const sitesdefault = this.props.sitesdata;
        const { sitesdataLoading, organisationdataLoading } = this.state;

        if (organisationdataLoading || sitesdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        let date = "";
        if (this.props.match.params.orgid != 0) {
            date = organisationdefault.createdAt.substr(0, 10);
        }

        return (

            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad={{ "top": "medium", "left": "medium", "bottom": "medium", "right": "medium" }}
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Teem by iOFFICE</Heading>
                    <Box direction="row" gap="small">
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" height="1200px">
                    <PopUp
                        header=""
                        open={this.state.isSaved}
                        text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."}
                        onClose={() => this.onClose()}
                        createOrg={false}
                        orgName={organisationdefault.name}
                        error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <form>
                        <Tabs flex justify="start" activeIndex={this.state.index} onActive={this.onActive.bind(this)}>
                            <Tab title="Configure">
                                <StyledBox style1 pad={{ "horizontal": "small" }} margin={{ "top": "small", "bottom": "medium" }}>
                                    <Box width="50px">
                                        <FormField label="Active">
                                            <Box pad="small">
                                                <CheckBox checked={organisationdefault.teemEnabled} onChange={() => this.updateTeem()} />
                                            </Box>
                                        </FormField>
                                    </Box>
                                    <FormField label='Client ID' error={this.teemClientId_err}>
                                        <Box direction="row" justify="between" pad={{ right: "small" }}>
                                            <TextInputWithState onInput={this.updateClientId} value={this.state.viewTeemClientId ? organisationdefault.teemClientId : organisationdefault.teemClientId != "" ? "********" : ""} disabled={!this.state.viewTeemClientId} plain />
                                            <CheckBox label="View" checked={this.state.viewTeemClientId} onChange={() => this.setState({ viewTeemClientId: !this.state.viewTeemClientId })} />
                                        </Box>
                                    </FormField>
                                    <FormField label='Client Secret' error={this.teemClientSecret_err}>
                                        <Box direction="row" justify="between" pad={{ right: "small" }}>
                                            <TextInputWithState onInput={this.updateClientSecret} value={this.state.viewTeemClientSecret ? organisationdefault.teemClientSecret : organisationdefault.teemClientSecret != "" ? "********" : ""} disabled={!this.state.viewTeemClientSecret} plain />
                                            <CheckBox label="View" checked={this.state.viewTeemClientSecret} onChange={() => this.setState({ viewTeemClientSecret: !this.state.viewTeemClientSecret })} />
                                        </Box>
                                    </FormField>
                                    {/*<FormField label='Callback URL' error={this.teemCallbackURL_err}>
                                        <Box direction="row" justify="between" pad={{ right: "small" }}>
                                            <TextInputWithState onInput={this.updateCallbackUrl} value={this.state.viewTeemCallbackURL ? organisationdefault.teemCallbackURL : organisationdefault.teemCallbackURL != "" ? "********" : ""} disabled={!this.state.viewTeemCallbackURL} plain/>
                                            <CheckBox label="View" checked={this.state.viewTeemCallbackURL} onChange={() => this.setState({ viewTeemCallbackURL: !this.state.viewTeemCallbackURL })}/>
                                        </Box>
                                    </FormField>
                                    <FormField label='Authorization Grant Type' error={this.teemGrantType_err}>
                                        <Box direction="row" justify="between" pad={{ right: "small" }}>
                                            <TextInputWithState onInput={this.updateGrantType} value={this.state.viewTeemAuthGrantType ? organisationdefault.teemAuthGrantType : organisationdefault.teemAuthGrantType != "" ? "********" : ""} disabled={!this.state.viewTeemAuthGrantType} plain/>
                                            <CheckBox label="View" checked={this.state.viewTeemAuthGrantType} onChange={() => this.setState({ viewTeemAuthGrantType: !this.state.viewTeemAuthGrantType })}/>
                                        </Box>
                                    </FormField>*/}
                                    <FormField label='Company ID' error={this.teemCompanyId_err}>
                                        <Box direction="row" justify="between" pad={{ right: "small" }}>
                                            <TextInputWithState onInput={this.updateCompanyId} value={this.state.viewTeemOrgId ? organisationdefault.teemOrgId : organisationdefault.teemOrgId != null ? "********" : ""} disabled={!this.state.viewTeemOrgId} plain />
                                            <CheckBox label="View" checked={this.state.viewTeemOrgId} onChange={() => this.setState({ viewTeemOrgId: !this.state.viewTeemOrgId })} />
                                        </Box>
                                    </FormField>
                                    <FormField label='Resource Owner Username' error={this.teemUserName_err}>
                                        <Box direction="row" justify="between" pad={{ right: "small" }}>
                                            <TextInputWithState onInput={this.updateUserName} value={this.state.viewTeemUserName ? organisationdefault.teemUserName : organisationdefault.teemUserName != "" ? "********" : ""} disabled={!this.state.viewTeemUserName} plain />
                                            <CheckBox label="View" checked={this.state.viewTeemUserName} onChange={() => this.setState({ viewTeemUserName: !this.state.viewTeemUserName })} />
                                        </Box>
                                    </FormField>
                                    <FormField label='Resource Owner Password' error={this.teemPassword_err}>
                                        <Box direction="row" justify="between" pad={{ right: "small" }}>
                                            <TextInputWithState onInput={this.updatePassword} value={this.state.viewTeemPassword ? organisationdefault.teemPassword : organisationdefault.teemPassword != "" ? "********" : ""} disabled={!this.state.viewTeemPassword} plain />
                                            <CheckBox label="View" checked={this.state.viewTeemPassword} onChange={() => this.setState({ viewTeemPassword: !this.state.viewTeemPassword })} />
                                        </Box>
                                    </FormField>
                                </StyledBox>
                            </Tab>
                            <Tab title="Documentation">
                                <StyledBox pad={{ "horizontal": "small" }} margin={{ "top": "small", "bottom": "medium" }}>
                                    <Text>
                                        Nothing to see here yet, Documentation coming soon
                                    </Text>
                                </StyledBox>
                            </Tab>
                        </Tabs>
                    </form>
                </Box>
            </Grid>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const organisationId = ownProps.match.params.orgid || '';
    if (state.selectedOrganisationReducer.organisationdata && organisationId == state.selectedOrganisationReducer.organisationdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedOrganisationReducer.organisationdata));
        return {
            organisationdata: state.selectedOrganisationReducer.organisationdata,
            sitesdata: state.sitesReducer.sitesdata
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OrganisationAction, ...SiteAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgIntegrationTeemEditContainer));
