import * as ActionType from './ActionType';
import SiteApi from '../api/SiteApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';


export const getSitesResponse = sitesdata => ({
    type: ActionType.GET_SITES_RESPONSE,
    sitesdata
});

export function getSitesAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return SiteApi.getAllSites()
            .then(sitesdata => {
                dispatch(getSitesResponse(sitesdata));
                return sitesdata;
            }).catch(error => {
                throw error;
            });
    };
}

export function getSitesActionNoId(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return SiteApi.getAllSitesNoId()
            .then(sitesdata => {
                dispatch(getSitesResponse(sitesdata));
                return sitesdata;
            }).catch(error => {
                throw error;
            });
    };
}

export function getSitesQAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return SiteApi.getAllSitesWithQuickAction()
            .then(sitesdata => {
                dispatch(getSitesResponse(sitesdata));
                return sitesdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewSiteResponse = () => ({
    type: ActionType.ADD_NEW_SITE_RESPONSE
});

export const updateExistingSiteResponse = () => ({
    type: ActionType.UPDATE_EXISTING_SITE_RESPONSE
});

export function saveSiteAction(sitedraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the site is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new site that is being added, therefore add it
        return SiteApi.saveSite(sitedraft)
            .then((response) => {
                if (sitedraft.id) {
                    dispatch(updateExistingSiteResponse());
                } else {
                    dispatch(addNewSiteResponse());
                }
                return response.ok ? response : response.json();
            }).then((response) => {
                //dispatch(getSiteAction(sitedraft.id));
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getSiteResponse = sitedata => ({
    type: ActionType.GET_SITE_RESPONSE,
    sitedata
});

export function getSiteAction(siteId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return SiteApi.getSite(siteId)
            .then(sitedata => {
                dispatch(getSiteResponse(sitedata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteSiteResponse = () => ({
    type: ActionType.DELETE_SITE_RESPONSE
});

export function deleteSiteAction(siteId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return SiteApi.deleteSite(siteId)
            .then((response) => {
                dispatch(deleteSiteResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getSitesActionNoId(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}