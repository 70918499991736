import React from 'react';

import { Blank } from 'grommet-icons';

export const Yammer = props => (
    <Blank viewBox='0 0 24 24' a11yTitle='Yammer' {...props}>
        {/* <path d='m23.774 0.20508v23.569h-23.569v-23.569h23.569m0.25-0.25h-24.069v24.069h24.069v-24.069z' /> */}
        {/* <path d='m22 2v14h-9.6056l-6.3944 4.263v-4.263h-4v-14h20m2-2h-24v18h4v6l9-6h11v-18z' /> */}
        {/* <path d='m3.868 10.183c0.50832 0.34547 1.2279 0.61394 1.8897 0.61394 0.48907 0 0.91154-0.10562 0.91154-0.47034 0-0.28772-0.18262-0.38345-0.49895-0.49843l-0.89229-0.2591c-0.98802-0.28772-1.5348-0.77731-1.5348-1.7554 0-1.1706 0.98802-1.8132 2.2258-1.8132 0.79604 0 1.3621 0.17273 1.8512 0.4412v1.4199c-0.52757-0.36472-1.1124-0.58532-1.7841-0.58532-0.4412 0-0.77731 0.12487-0.77731 0.42195 0 0.26899 0.18262 0.37461 0.48959 0.47034l0.89177 0.26847c1.0744 0.33558 1.564 0.90165 1.564 1.8033 0 1.2763-1.0843 1.8418-2.4079 1.8418-0.78667 0-1.4963-0.2206-1.9282-0.4797v-1.4193z' /> */}
        {/* <path d='m11.858 10.72-1.5156-2.1103v3.3767h-1.5062v-5.8902h1.4292l1.6118 2.3985 1.6217-2.3985h1.4193v5.8902h-1.5161v-3.3767l-1.5057 2.1103h-0.038501z' /> */}
        {/* <path d='m15.663 10.183c0.50884 0.34547 1.2279 0.61394 1.8897 0.61394 0.49011 0 0.91154-0.10562 0.91154-0.47034 0-0.28772-0.1821-0.38345-0.49843-0.49843l-0.89177-0.2591c-0.98854-0.28772-1.5359-0.77731-1.5359-1.7554 0-1.1706 0.98854-1.8132 2.2268-1.8132 0.795 0 1.3611 0.17273 1.8501 0.4412v1.4199c-0.52653-0.36472-1.1124-0.58532-1.7835-0.58532-0.4412 0-0.77731 0.12487-0.77731 0.42195 0 0.26899 0.1821 0.37461 0.49011 0.47034l0.89073 0.26847c1.0749 0.33558 1.565 0.90165 1.565 1.8033 0 1.2763-1.0843 1.8418-2.4079 1.8418-0.78667 0-1.4963-0.2206-1.9292-0.4797v-1.4193z' /> */}
        <path d='m20.683 6.3943c0.48076-0.38449 0.60311-1.0778 0.26459-1.6065-0.2996-0.46522-0.8604-0.65757-1.366-0.51094-0.044002 0.022612-0.089973 0.047439-0.13886 0.073686-0.80447 0.44333-6.2418 4.2531-5.6281 4.683 0.60968 0.4263 4.3277-1.2471 6.8683-2.6393zm0 11.456c-2.5406-1.3917-6.2586-3.0651-6.8683-2.6388-0.61379 0.42946 4.8236 4.2399 5.6281 4.6825 0.048882 0.026745 0.094852 0.05105 0.13886 0.074408 0.50559 0.14737 1.0664-0.046192 1.366-0.51117 0.33852-0.52917 0.21618-1.2225-0.26459-1.607zm2.2383-6.9806c-0.050102-0.0024405-0.10262-0.0029135-0.15758-0.0029135-0.9178 0.0016937-7.5181 0.72568-7.1869 1.3983 0.33 0.66561 4.3941 0.98857 7.2909 0.98976 0.60699-0.10529 1.0467-0.65391 1.0049-1.2811-0.038923-0.55056-0.43848-0.99074-0.95134-1.1041z' />
        <path d='m5.4474 16.594c-0.75606 2.1347-1.4557 3.1342-3.0634 3.1342-0.15539 0-0.69622-0.031849-0.73125-0.035011-0.51871-0.026747-1.0029 0.3108-1.1427 0.83218-0.16027 0.5924 0.18749 1.2028 0.77673 1.3716 0.42605 0.074905 0.86356 0.10262 1.256 0.10262 2.9635 0 3.943-1.706 4.9214-4.1969l5.7814-14.422c0.20843-0.65356-0.15464-1.352-0.80397-1.5599-0.63824-0.20305-1.3143 0.13447-1.5429 0.75436l-4.0622 10.737h-0.0637l-4.2733-10.817c-0.28914-0.61135-1.0044-0.90975-1.6495-0.66803-0.66195 0.2483-1.0019 0.97263-0.78452 1.6385l5.3819 13.129z' />
    </Blank>
); 