import * as ActionType from './ActionType';
import MergedLevelApi from '../api/MergedLevelApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';

export const getLevelsResponse = mergedlevelsdata => ({
    type: ActionType.GET_MERGEDLEVELS_RESPONSE,
    mergedlevelsdata
});

export function getMergedLevelsAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return MergedLevelApi.getAllLevels()
            .then(mergedlevelsdata => {
                dispatch(getLevelsResponse(mergedlevelsdata));
                return mergedlevelsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const getLevelResponse = mergedleveldata => ({
    type: ActionType.GET_MERGEDLEVEL_RESPONSE,
    mergedleveldata
});

export function getMergedLevelAction(id) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return MergedLevelApi.getLevel(id)
            .then(mergedleveldata => {
                dispatch(getLevelResponse(mergedleveldata));
            }).catch(error => {
                throw error;
            });
    };
}
export const updateExistingMergedLevelResponse = () => ({
    type: ActionType.UPDATE_EXISTING_MERGEDLEVEL_RESPONSE
});

export function saveMergedLevelAction(mergedlevel) {
    return function (dispatch) {
        dispatch(ApiCallBeginAction());
        return MergedLevelApi.saveMergedLevel(mergedlevel)
            .then((response) => {
                if (mergedlevel.id) {
                    dispatch(updateExistingMergedLevelResponse());
                } else {
                    //dispatch(addNewPageResponse());
                }
                return response;
            }).then((response) => {
                //if (mergedlevel.id == "0") {
                //    dispatch(getMergedLevelAction(mergedlevel.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}