import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AssetAction from '../action/AssetAction';
import * as AssetLabelsAction from '../action/AssetLabelsAction';
import AssetApi from '../api/AssetApi';
import * as BeaconAction from '../action/BeaconAction';
import BeaconApi from '../api/BeaconApi';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Add, OrderedList, FormClose, FormClock, Download, Dashboard, Upload, DocumentVerified, DocumentCsv, CircleInformation } from 'grommet-icons';
import { Grommet, Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, CheckBox, Meter } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import ReactTooltip from "react-tooltip";
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import { CSVLink } from "react-csv";
import './TagCreateContainer.css';

export class BeaconBulkEditContainer extends React.Component {
    constructor() {
        super();
        this.selectedLabels = [];
        this.assetBeaconTags = [];
        this.state = {
            options: [],
            selected: "",
            location: "",
            isSaved: false,
            open: false,
            disabledButton: true,
            cancel: false,
            isSaveSuccessful: false,

            data: [],
            progressValue: 0,
            assetlabelsdataLoading: false,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        //this.props.action.getAssetLabelsAction()
        //    .then(response => this.setState({ assetlabelsdataLoading: false }))
        //    .catch(error => {
        //    var errorArray = this.state.apiErrorList.concat(error);
        //    this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
        //        console.log('*DEBUG*- AssetLabelsAction List Error', error);
        //    });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.state.data.length > 0) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }
    handleCancel() {
        if (!this.state.cancel && this.state.data > 0) {
            this.setState({ cancel: true });
        }
        else {
            if (this.nextLoc) {
                this.props.history.push(this.nextLoc);
            } else {
                this.props.history.push({
                    pathname: constants.getSiteLevelRoute() + '/beacons',
                    state: { index: 0 }
                });
            }
        }
    }

    onClose() {
        //this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push({
                pathname: '/beacons',
                state: { index: 0 }
            });
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    renderTable(data) {
        if (data.length != 0) {
            return (
                <Box>
                    <table>
                        <thead>
                            <tr>
                                {Object.keys(data[0]).map((k, index) => (
                                    <th>
                                        {k}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((info, idx) => (
                                <tr>
                                    {Object.keys(info).map((key, index) => (
                                        <td class="upload">
                                            {info[key]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            );
        }
        return;
    }
    handleSave() {
        this.setState({ disabledButton: true });
        this.state.data.forEach((row, idx) => {
            //let tag = assestdata.find(a => a.id == row["MAC"]);
            BeaconApi.getBeacon(row["BLE MAC"])
                .then(beacon => {
                    if (beacon) {
                        beacon.name = row["Beacon Name"];
                        this.props.action.saveBeaconAction(beacon)
                            .then((response) => {
                                if (idx == this.state.data.length - 1)
                                    this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                            }).catch(error => {
                                console.log('*DEBUG*-Save AssetEdit Error', error);
                                //this.setState({ disabledButton: false });
                                this.setState({ isSaved: true, isSaveSuccessful: false });
                            });
                    } else {
                        this.setState({ isSaved: true, isSaveSuccessful: false });
                    }
                })
                .catch(error => {
                    console.log('*DEBUG*-Save Get Asset Error', error);
                    //this.setState({ disabledButton: false });
                    this.setState({ isSaved: true, isSaveSuccessful: false });
                });
        });
    }

    render() {
        const { assetlabelsdataLoading } = this.state;

        if (assetlabelsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }
        const heading = 'Bulk Edit Beacons';
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Box direction="row" gap="small">
                        <Heading level='2' >{heading}</Heading>
                        <Box alignSelf="center" data-for="bulk" data-tip="Edit beacons in bulk using downloaded files with completed beacons information.">
                            <CircleInformation style={{ alignSelf: "center" }} data-for="bulk" data-tip="Edit beacons in bulk using downloaded files with completed beacons information." />
                        </Box>
                        <ReactTooltip id="bulk" place="right" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>
                    <Box direction="row" pad={{ horizontal: "small", }} gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} /*disabled={this.state.disabledButton}*/ />
                        <StyledButton typeSave label="Save" onClick={() => this.handleSave()} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" style={{ minHeight: "500px" }}>
                    <form>
                        <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : ""} error={!this.state.isSaveSuccessful} />
                        <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                        <Box>
                            <Box pad={{ bottom: "small" }}>
                                <Text>To bulk edit beacons, upload a local CSV file and select "Save".</Text>
                                <br />
                                <Text>A CSV file can be generated by selecting beacons in the Beacons table, and selecting the download icon on the action bar that appears at the foot of the table.  Once downloaded the CSV can be edited in bulk before being uploaded here.</Text>
                            </Box>
                            <Text size="large">File Upload</Text>
                            <Box /*border="all"*/ style={{ maxWidth: "600px" }}>
                                {!this.state.file &&
                                    <Dropzone /*accept=".csv"*/ maxFiles={1} onDrop={acceptedFiles => {
                                        console.log(acceptedFiles);
                                        ///reader start
                                        acceptedFiles.forEach((file) => {
                                            const reader = new FileReader();
                                            reader.onprogress = (evt) => {
                                                this.setState({ progressValue: parseInt(((evt.loaded / evt.total) * 100), 10) });
                                            };
                                            reader.onabort = () => console.log('file reading was aborted');
                                            reader.onerror = () => console.log('file reading has failed');
                                            reader.onload = () => {
                                                // Do whatever you want with the file contents
                                                const binaryStr = reader.result;
                                                console.log(binaryStr);
                                            }
                                            reader.readAsArrayBuffer(file);
                                        })
                                        ///reader end


                                        this.setState({ file: acceptedFiles[0] });
                                        if (acceptedFiles[0].type == "application/vnd.ms-excel" || acceptedFiles[0].type == "text/csv") {
                                            Papa.parse(acceptedFiles[0], {
                                                delimiter: "",
                                                header: true,
                                                skipEmptyLines: "greedy",
                                                //step: (row, parser) => {
                                                //    console.log("Row data:", row.data);
                                                //    console.log("Row errors:", row.errors);
                                                //    this.setState(prevState => ({
                                                //        data: [...prevState.data, row.data]
                                                //    }));
                                                //},
                                                complete: (results) => {
                                                    console.log(results);
                                                    this.setState({ data: results.data, disabledButton: false });
                                                }
                                            });
                                        }
                                    }}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="upload-container">
                                                <div {...getRootProps()} className="drop-zone">
                                                    <Box align="center" margin={{ top: "10px" }}>
                                                        <Upload />
                                                    </Box>
                                                    <input {...getInputProps()} />
                                                    <p>Drag and drop or browse to your files</p>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                }
                                {this.state.file &&
                                    <Box style={{ maxWidth: "600px" }} /*align="center" border="all"*/ className="upload-container">
                                        {this.state.progressValue == 100 ?
                                            <DocumentCsv size="medium" />
                                            :
                                            <LoadingIndicator />
                                        }
                                        <Text>{this.state.file.name}</Text>
                                        <Box>
                                            <Meter type="bar" round background="light-3" max={100} values={[{ value: this.state.progressValue, color: "brand" }]} />
                                            <Box justify="end" direction="row">
                                                {/*<Text>30byte of 60byte</Text>*/}
                                                <Text size="small">Upload{this.state.progressValue == 100 ? "ed" : "ing"} {this.state.progressValue}%</Text>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                            {this.renderTable(this.state.data)}
                        </Box>
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}

const mapStateToProps = (state) => ({
    assetlabelsdata: state.assetLabelsReducer.assetlabelsdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...AssetLabelsAction, ...AssetAction, ...BeaconAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BeaconBulkEditContainer));