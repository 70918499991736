import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, CheckBox, Image, Tabs, Tab } from 'grommet';
import { Filter, Edit, Close, Search, Trash } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import { StyledTabs, StyledTab } from './common/StyledTabs';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import { Context } from './Context';

const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTableS = withPagination(DataTable);
const PaginatedDataTableL = withPagination(DataTable);
const PaginatedDataTableB = withPagination(DataTable);


class LocMappingListTable extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            searchSpaces: "",
            searchLevels: "",
            searchBuildings: "",
        }
    }


    render() {
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Location Mapping </Heading>
                    {/*<Box direction="row" gap="small" >
                        <StyledButton typeSave label='Sync' disabled={true} alignSelf="center" onClick={this.props.handleNew} />
                    </Box>*/}

                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ horizontal: "medium" }} fill="vertical">
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <Tabs flex justify="start">
                            <Tab title="Spaces">
                                {constants.release().Tenants == "Single" &&
                                    <Box>
                                        Ensure correct mapping of all spaces in My Workplace from external sources. <br />
                                For any space where the status is not green, review the external source system and configure as required.
                            </Box>}
                                {constants.release().Tenants == "Multi" &&
                                    <Box>
                                        Ensure correct mapping of all spaces from external sources. <br />
                                For any space where the status is not green, review the external source system and configure as required.
                            </Box>}
                                <StyledBox style={{ "min-width": "865px" }}>
                                    {this.renderSpacesTable(this.props.spacesdata)}
                                </StyledBox>
                            </Tab>

                            <Tab title="Levels">
                                {constants.release().Tenants == "Single" &&
                                    <Box>
                                        Ensure correct mapping of all levels in My Workplace from external sources. <br />
                                 For any levels where the status is not green, review the external source system and configure as required.
                            </Box>}
                                {constants.release().Tenants == "Multi" &&
                                    <Box>
                                        Ensure correct mapping of all levels from external sources. <br />
                                 For any levels where the status is not green, review the external source system and configure as required.
                            </Box>}
                                <StyledBox style={{ "min-width": "835px" }}>
                                    {this.renderLevelsTable(this.props.levelsdata)}
                                </StyledBox>
                            </Tab>

                            <Tab title="Buildings">
                                {constants.release().Tenants == "Single" &&
                                    <Box>
                                        Ensure correct mapping of all buildings in My Workplace from external sources. <br />
                                For any building where the status is not green, review the external source system and configure as required.
                            </Box>}
                                {constants.release().Tenants == "Multi" &&
                                    <Box>
                                        Ensure correct mapping of all buildings from external sources. <br />
                                For any building where the status is not green, review the external source system and configure as required.
                            </Box>}
                                <StyledBox style={{ "min-width": "700px" }}>
                                    {this.renderBuildingsTable(this.props.buildingsdata)}
                                </StyledBox>
                            </Tab>
                        </Tabs>
                    </IconThemeContext.Extend>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }

    ArrayToString(arr) {
        var string = "";
        arr.forEach(function (item, idx) {
            if (idx == arr.length - 1) {
                string += item.name;
            }
            else {
                string += item.name;
                string += ", ";
            }

        })
        return string;
    }
    renderSpacesTable(spaces) {
        const COLUMNS = [
            {
                property: 'id',
                header: <Box justify="center" height="100%">Status</Box>,
                render: s =>
                    <Box alignContent="center">
                        {(s.status == 'G' && s.published &&
                            <a data-for="green" data-tip="Items are valid">
                                <Image width="30" height="30" src="./images/green.svg" />
                            </a>
                        )}

                        {(s.status == 'G' && !s.published &&
                            <a data-for="green_unpublished" data-tip="This space is located on an unpublished map. Please publish the map to ensure correct mapping.">
                                <Image width="30" height="30" src="./images/green.svg" />
                            </a>
                        )}

                        {(s.status == 'A' && s.published &&
                            <a data-for="yellow" data-tip="Item is missing">
                                <Image width="30" height="30" src="./images/yellow.svg" />
                            </a>
                        )}

                        {(s.status == 'A' && !s.published &&
                            <a data-for="yellow_unpublished" data-tip="This space is located on an unpublished map. Please publish the map to ensure correct mapping.">
                                <Image width="30" height="30" src="./images/yellow.svg" />
                            </a>
                        )}

                        {(s.status == 'R' && s.published &&
                            <a data-for="red" data-tip="Items don't match">
                                <Image width="30" height="30" src="./images/red.svg" />
                            </a>
                        )}

                        {(s.status == 'R' && !s.published &&
                            <a data-for="red_unpublished" data-tip="This space is located on an unpublished map. Please publish the map to ensure correct mapping.">
                                <Image width="30" height="30" src="./images/red.svg" />
                            </a>
                        )}

                        <ReactTooltip id="green" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="yellow" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="red" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="green_unpublished" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="yellow_unpublished" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="red_unpublished" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>
            },
            {
                property: 'lctype',
                header: 'Type',
                render: s => <Text>{s.type}</Text>
            },
            {
                property: 'lcplacemark',
                header: <Box>
                    <a data-for="Placemark Name" data-tip="Placemark Names are configured in Meridian">
                        Placemark Name
                        </a>
                    <ReactTooltip id="Placemark Name" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.meridianName}</Text>
            },
            {
                property: 'lcspace',
                header: <Box>
                    <a data-for="Space Name" data-tip="Space Names are configured in Teem">
                        Space Name
                        </a>
                    <ReactTooltip id="Space Name" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.teemName}</Text>
            },
            {
                property: 'lcspotname',
                header: <Box>
                    <a data-for="Smart Space Name" data-tip="Smart Space Names are configured in Chargifi">
                        Spot Name
                        </a>
                    <ReactTooltip id="Smart Space Name" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.chargifiName}</Text>
            },
            {
                property: 'lclevel',
                header: "Level",
                render: s => <Text>{s.mergedLevelName}</Text>
            },
            {
                property: 'lcpublished',
                header: "Published",
                render: s => <CheckBox checked={s.published} disabled />
            }
        ];

        if (!(this.context.feature.find(f => f.name == "siteChargifi").isEnabled) || !constants.release().Feature_chargifi) { //if chargifi is not a function on the release type, than the column is removed
            COLUMNS.splice(4, 1);
        }

        let SPACES = [];
        console.log(spaces);

        if (spaces[0] && spaces[0].count != 0) {
            spaces.forEach(s => {
                //if (s.type=="room") {
                SPACES.push({
                    ...s,
                    lctype: s.type !== null ? s.type.toLowerCase() : "",
                    lcplacemark: s.meridianName !== null ? s.meridianName.toLowerCase() : "",
                    lcspace: s.teemName !== null ? s.teemName.toLowerCase() : "",
                    lcspotname: s.chargifiName ? s.chargifiName !== null ? s.chargifiName.toLowerCase() : "" : "",
                    lclevel: s.mergedLevelName !== null ? s.mergedLevelName.toLowerCase() : "",
                    lcpublished: s.published,
                    statusRAG: this.convertRAG(s.status)
                })
                //}
            });
            SPACES.sort((p1, p2) => { if (p1.lcname < p2.lcname) return -1; return 1; });
        }

        if (this.state.searchSpaces) {
            var search = this.state.searchSpaces.toLowerCase();
            SPACES = SPACES.filter(d =>
                d.type.toLowerCase().includes(search) ||
                d.meridianName.toLowerCase().includes(search) ||
                d.teemName.toLowerCase().includes(search) ||
                d.chargifiName.toLowerCase().includes(search) ||
                d.mergedLevelName.toLowerCase().includes(search) ||
                ("published".includes(search) && d.published)
            );
        }

        console.log('SPACES: ', SPACES);

        let SORT_SPACES = [
            { col: 'statusRAG', label: 'Status' },
            { col: 'lcplacemark', label: 'Placemark Name' },
            { col: 'lcspace', label: 'Space Name' },
            { col: 'lcspotname', label: 'Spot Name' },
            { col: 'lclevel', label: 'Level' },
            { col: 'lcpublished', label: 'Published' }
        ]

        if (!constants.release().Feature_chargifi) { //if chargifi is not a function on the release type, than the column is removed
            SORT_SPACES.splice(3, 1)
        }

        return (
            <PaginatedDataTableS
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lctype" || c.property === "lcname" || c.property === "lcplacemark" || c.property === "lcspace" || c.property === "lclevel" || c.property === "lcspotname"
                }))}
                data={SPACES}
                noLoadingMsg={spaces.length != 0}
                sortable
                //sortoptions={SORT_SPACES}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.searchSpaces}
                PFonChange={e => this.setState({ searchSpaces: e.target.value })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }

    renderLevelsTable(levels) {
        const COLUMNS = [
            {
                property: 'id',
                header: <Box justify="center" height="100%">Status</Box>,
                render: s =>
                    <Box alignContent="center">
                        {(s.status == 'G' && s.published &&
                            <a data-for="green" data-tip="Items are valid">
                                <Image width="30" height="30" src="./images/green.svg" />
                            </a>
                        )}

                        {(s.status == 'G' && !s.published &&
                            <a data-for="green_unpublished" data-tip="This level is located on an unpublished map. Please publish the map to ensure correct mapping.">
                                <Image width="30" height="30" src="./images/green.svg" />
                            </a>
                        )}

                        {(s.status == 'A' && s.published &&
                            <a data-for="yellow" data-tip="Item is missing">
                                <Image width="30" height="30" src="./images/yellow.svg" />
                            </a>
                        )}

                        {(s.status == 'A' && !s.published &&
                            <a data-for="yellow_unpublished" data-tip="This level is located on an unpublished map. Please publish the map to ensure correct mapping.">
                                <Image width="30" height="30" src="./images/yellow.svg" />
                            </a>
                        )}

                        {(s.status == 'R' && s.published &&
                            <a data-for="red" data-tip="Items don't match">
                                <Image width="30" height="30" src="./images/red.svg" />
                            </a>
                        )}

                        {(s.status == 'R' && !s.published &&
                            <a data-for="red_unpublished" data-tip="This level is located on an unpublished map. Please publish the map to ensure correct mapping.">
                                <Image width="30" height="30" src="./images/red.svg" />
                            </a>
                        )}

                        <ReactTooltip id="green" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="yellow" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="red" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="green_unpublished" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="yellow_unpublished" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="red_unpublished" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>
            },
            {
                property: 'lcbuilding',
                header: 'Building',
                render: s => <Text>{s.mergedBuildingName}</Text>
            },
            {
                property: 'lcmaster',
                header: <Box>
                    <a data-for="Master Level" data-tip="Master levels are configured in HPE My Workplace">
                        Master Level
                        </a>
                    <ReactTooltip id="Master Level" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.mergedName}</Text>
            },
            {
                property: 'lcmaplevel',
                header: <Box>
                    <a data-for="Map Level" data-tip="Map levels are configured in Meridian">
                        Map Level
                        </a>
                    <ReactTooltip id="Map Level" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.meridianName}</Text>
            },
            {
                property: 'lcspacelevel',
                header: <Box>
                    <a data-for="Space Level" data-tip="Space levels are configured in Teem">
                        Space Level
                        </a>
                    <ReactTooltip id="Space Level" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.teemName}</Text>
            },
            {
                property: 'lcspotlevel',
                header: <Box>
                    <a data-for="Smart Zone Level" data-tip="Smart zone leves are configured in Chargifi">
                        Spot Level
                        </a>
                    <ReactTooltip id="Smart Zone Level" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.chargifiName}</Text>
            },
            {
                property: 'lcpublished',
                header: "Published",
                render: s => <CheckBox checked={s.published} disabled />
            }
        ];

        if (!(this.context.feature.find(f => f.name == "siteChargifi").isEnabled) || !constants.release().Feature_chargifi) { //if chargifi is not a function on the release type, than the column is removed
            COLUMNS.splice(5, 1);
        }

        let LEVELS = [];
        console.log(levels);

        if (levels[0] && levels[0].count != 0) {
            levels.forEach(s => {
                //if (sessionStorage.getItem('locationId') == s.locationId) {
                LEVELS.push({
                    ...s,
                    lcbuilding: s.mergedBuildingName !== null ? s.mergedBuildingName.toLowerCase() : "",
                    lcmaster: s.mergedName !== null ? s.mergedName.toLowerCase() : "",
                    lcmaplevel: s.meridianName !== null ? s.meridianName.toLowerCase() : "",
                    lcspacelevel: s.teemName !== null ? s.teemName.toLowerCase() : "",
                    lcspotlevel: s.chargifiName ? s.chargifiName !== null ? s.chargifiName.toLowerCase() : "" : "",
                    lcpublished: s.published,
                    statusRAG: this.convertRAG(s.status)
                })
                //}
            });
            LEVELS.sort((p1, p2) => { if (p1.lcbuilding < p2.lcbuilding) return -1; return 1; });
        }

        if (this.state.searchLevels) {
            var search = this.state.searchLevels.toLowerCase();
            LEVELS = LEVELS.filter(d =>
                d.mergedBuildingName.toLowerCase().includes(search) ||
                d.mergedName.toLowerCase().includes(search) ||
                d.meridianName.toLowerCase().includes(search) ||
                d.teemName.toLowerCase().includes(search) ||
                d.chargifiName.toLowerCase().includes(search) ||
                ("published".includes(search) && d.published)
            );
        }

        console.log('LEVELS: ', LEVELS);

        let SORT_LEVELS = [
            { col: 'statusRAG', label: 'Status' },
            { col: 'lcbuilding', label: 'Building' },
            { col: 'lcmaster', label: 'Master Level' },
            { col: 'lcmaplevel', label: 'Map Level' },
            { col: 'lcspacelevel', label: 'Space Level' },
            { col: 'lcspotlevel', label: 'Spot Level' },
            { col: 'lcpublished', label: 'Published' },
        ]

        if (!constants.release().Feature_chargifi) { //if chargifi is not a function on the release type, than the column is removed
            SORT_LEVELS.splice(5, 1)
        }

        return (
            <PaginatedDataTableL
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcbuilding" || c.property === "lcmaster" || c.property === "lcmaplevel" || c.property === "lcspacelevel" || c.property === "lcspotlevel"
                }))}
                data={LEVELS}
                noLoadingMsg={levels.length != 0}
                sortable
                //sortoptions={SORT_LEVELS}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.searchLevels}
                PFonChange={e => this.setState({ searchLevels: e.target.value })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }

    renderBuildingsTable(buildings) {
        const COLUMNS = [
            {
                property: 'id',
                header: <Box justify="center" height="100%">Status</Box>,
                render: s =>
                    <Box alignContent="center">
                        {(s.status == 'G' &&
                            <a data-for="green" data-tip="Items are valid">
                                <Image width="30" height="30" src="./images/green.svg" />
                            </a>
                        )}

                        {(s.status == 'A' &&
                            <a data-for="green" data-tip="Item is missing">
                                <Image width="30" height="30" src="./images/yellow.svg" />
                            </a>
                        )}

                        {(s.status == 'R' &&
                            <a data-for="green" data-tip="Items don't match">
                                <Image width="30" height="30" src="./images/red.svg" />
                            </a>
                        )}

                        <ReactTooltip id="green" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="yellow" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="red" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>
            },
            {
                property: 'lcmasterbuilding',
                header: <Box>
                    <a data-for="Master Building" data-tip="Master buildings are configured in HPE My Workplace">
                        Master Building
                        </a>
                    <ReactTooltip id="Master Building" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.mergedName}</Text>
            },
            {
                property: 'lcmaster',
                header: <Box>
                    <a data-for="Map Building" data-tip="Map buildings are configured in Meridian">
                        Map Building
                        </a>
                    <ReactTooltip id="Map Building" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.meridianName}</Text>
            },
            {
                property: 'lcspacelevel',
                header: <Box>
                    <a data-for="Space Building" data-tip="Space buildings are configured in Teem">
                        Space Building
                        </a>
                    <ReactTooltip id="Space Building" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.teemName}</Text>
            },
            {
                property: 'lcspotvenue',
                header: <Box>
                    <a data-for="Smart Venue" data-tip="Smart venues are configured in Chargifi">
                        Spot Venue
                        </a>
                    <ReactTooltip id="Smart Venue" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                </Box>,
                render: s => <Text>{s.chargifiName}</Text>
            },
        ];

        function removeColumn(propertyName) {
            if (COLUMNS.findIndex(c => c.property == propertyName) != -1) {
                COLUMNS.splice(COLUMNS.findIndex(c => c.property == propertyName), 1);
            }
        }

        if (!(this.context.feature.find(f => f.name == "siteChargifi").isEnabled) || !constants.release().Feature_chargifi) { //if chargifi is not a function on the release type, than the column is removed
            removeColumn("lcspotvenue");
        }

        let BUILDINGS = [];
        console.log(buildings);

        if (buildings[0] && buildings[0].count != 0) {
            buildings.forEach(s => {
                //if (sessionStorage.getItem('locationId') == s.locationId) {
                BUILDINGS.push({
                    ...s,
                    lcmasterbuilding: s.mergedName !== null ? s.mergedName.toLowerCase() : "",
                    lcmaster: s.meridianName !== null ? s.meridianName.toLowerCase() : "",
                    lcspacelevel: s.teemName !== null ? s.teemName.toLowerCase() : "",
                    lcspotvenue: s.chargifiName ? s.chargifiName !== null ? s.chargifiName.toLowerCase() : "" : "",
                    statusRAG: this.convertRAG(s.status)
                })
                //}
            });
            BUILDINGS.sort((p1, p2) => { if (p1.lcmasterbuilding < p2.lcmasterbuilding) return -1; return 1; });
        }

        if (this.state.searchBuildings) {
            var search = this.state.searchBuildings.toLowerCase();
            BUILDINGS = BUILDINGS.filter(d =>
                d.mergedName.toLowerCase().includes(search) ||
                d.meridianName.toLowerCase().includes(search) ||
                d.teemName.toLowerCase().includes(search) ||
                d.chargifiName.toLowerCase().includes(search)
            );
        }

        console.log('BUILDINGS: ', BUILDINGS);

        let SORT_BUILDNGS = [
            { col: 'statusRAG', label: 'Status' },
            { col: 'lcmasterbuilding', label: 'Master Building' },
            { col: 'lcmaster', label: 'Map Building' },
            { col: 'lcspacelevel', label: 'Space Building' },
            { col: 'lcspotvenue', label: 'Spot Venue' },
        ]

        if (!constants.release().Feature_chargifi) { //if chargifi is not a function on the release type, than the column is removed
            SORT_BUILDNGS.splice(4, 1)
        }

        return (
            <PaginatedDataTableB
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcmasterbuilding" || c.property === "lcmaster" || c.property === "lcspacelevel" || c.property === "lcspotvenue"
                }))}
                data={BUILDINGS}
                noLoadingMsg={buildings.length != 0}
                sortable
                //sortoptions={SORT_BUILDNGS}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.searchBuildings}
                PFonChange={e => this.setState({ searchBuildings: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }

    convertRAG(char) {
        if (char == "R") return 3;
        if (char == "A") return 2;
        if (char == "G") return 1;
    }
}
export default LocMappingListTable;
