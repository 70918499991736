import * as ActionType from './ActionType';
import UserApi from '../api/UserApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getUsersResponse = usersdata => ({
    type: ActionType.GET_USERS_RESPONSE,
    usersdata
});

const queuedGetAllUsers = queueApiCall(UserApi.getAllUsers);

export function getUsersAction(noId = false, deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return UserApi.getAllUsers(noId)
            //return queuedGetAllUsers()
            .then(usersdata => {
                dispatch(getUsersResponse(usersdata));
                return usersdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewUserResponse = () => ({
    type: ActionType.ADD_NEW_USER_RESPONSE
});

export const updateExistingUserResponse = () => ({
    type: ActionType.UPDATE_EXISTING_USER_RESPONSE
});

export function saveUserAction(userdraft, noId = false) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the user is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new user that is being added, therefore add it
        return UserApi.saveUser(userdraft, noId)
            .then((response) => {
                if (userdraft.id) {
                    dispatch(updateExistingUserResponse());
                } else {
                    dispatch(addNewUserResponse());
                }
                return response;
            }).then((response) => {
                //if (userdraft.id == "0") {
                //    dispatch(getUserAction(userdraft.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                console.log('Action error:', error);
                throw (error);
            });
    };
}

export const updateSuperUserResponse = () => ({
    type: ActionType.UPDATE_SUPER_USER_RESPONSE
});

export function saveSuperUserAction(userdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return UserApi.saveSuperUser(userdraft)
            .then((response) => {
                dispatch(updateSuperUserResponse());
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                console.log('Action error:', error);
                throw (error);
            });
    };
}

export const getUserResponse = userdata => ({
    type: ActionType.GET_USER_RESPONSE,
    userdata
});

export function getUserAction(userId, noId = false) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return UserApi.getUser(userId, noId)
            .then(userdata => {
                dispatch(getUserResponse(userdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export function getUserAllRolesAction(userId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return UserApi.getUserAllRoles(userId)
            .then(userdata => {
                dispatch(getUserResponse(userdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteUserResponse = () => ({
    type: ActionType.DELETE_USER_RESPONSE
});

const queuedDeleteUser = queueApiCall(UserApi.deleteUser);

export function deleteUserAction(userId, noId = false) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return UserApi.deleteUser(userId)
        return queuedDeleteUser(userId, noId)
            .then((response) => {
                dispatch(deleteUserResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getUsersAction(false, false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}