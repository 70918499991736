import React, { Component } from 'react';
import { Box, Button, Text, Heading } from 'grommet';
import { MailOption, Refresh, Alert } from 'grommet-icons';
import { Spinning } from 'grommet-controls';
import { StyledBox } from './StyledBox';
import { StyledButton } from './StyledButton';
import { PopUp } from './PopUp';
import constants from '../../constants';

//plain = no message, just indicator
//message = Lodaing message to show, deafault = "Loading"
//size = the size of the indicator and text, default = "medium"

class LoadingIndicator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMoreInfo: false,
        };
    }

    render() {
        const { showMoreInfo } = this.state;

        let msg = "Loading"; //default message

        let error = 0;
        error = this.props.error;

        let errorList = [{}];
        errorList = this.props.errorList;

        let errorSize = "100px";
        if (this.props.errorSize) {
            errorSize = this.props.errorSize;
        }

        let showErrorMsg = true;
        if (this.props.showErrorMsg != null) {
            showErrorMsg = this.props.showErrorMsg;
        }

        if (this.props.message) {
            msg = this.props.message;
        }

        let size = "medium" //default size
        if (this.props.size) {
            size = this.props.size;
        }

        let txtsize = "small"
        if (this.props.size) {
            if (this.props.size == "xsmall") {
                txtsize = "xsmall";
            }
            else if (this.props.size == "small") {
                txtsize = "xsmall";
            }
            else if (this.props.size == "medium") {
                txtsize = "small";
            }
            else if (this.props.size == "large") {
                txtsize = "medium";
            }
            else if (this.props.size == "xlarge") {
                txtsize = "large";
            }
        }

        if (error > 0) { //Error
            return (
                <StyledBox fill justify="top" align="center" gap="small" direction="column">
                    <StyledBox pad={showErrorMsg ? "medium" : "none"} gap="small">
                        <Box align="center">
                            <Alert color="#DADADA" size={errorSize} />
                        </Box>
                        {showErrorMsg &&
                            <Text textAlign="center">
                                ERROR: {errorList[0].status}<br />
                                {errorList[0].statusText} <br />
                            Please try refreshing or contact support.
                        </Text>}
                    </StyledBox>
                    {/*{errorList.length > 0 &&*/}
                    {/*    <StyledButton typeAdd label={showMoreInfo ? "Show Less" : "Show More"} onClick={() => this.setState({ showMoreInfo: !showMoreInfo })} />}*/}
                    {/*{showMoreInfo &&*/}
                    {/*    <Box gap="medium" pad={{ "top": "medium" }} direction="column">*/}
                    {/*        {errorList.map((item, idx) =>*/}
                    {/*            <StyledBox style1 direction="column" pad="small">*/}
                    {/*                <Text><b>ERROR #{idx + 1}</b></Text>*/}
                    {/*                <Text><b>URL:</b> {item.url}</Text>*/}
                    {/*                <Text><b>Status Code:</b> {item.status}</Text>*/}
                    {/*                <Text><b>Error Message:</b> {item.statusText}</Text>*/}
                    {/*            </StyledBox>*/}
                    {/*        )}*/}
                    {/*    </Box>*/}
                    {/*}*/}
                </StyledBox>
            )
        }
        else { // Loading
            return (
                <StyledBox fill justify="center" align="center" gap="small" direction="column">
                    <Spinning color={constants.BUTTON_LABEL_COLOR_3} size={size} />
                    {!this.props.plain &&
                        <Box>
                            <Text size={txtsize}>{msg}</Text>
                        </Box>}
                </StyledBox>
            )
        }
    }
}
export default LoadingIndicator;
