import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Paragraph, CheckBox, Grommet } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, FormSearch, CircleInformation } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import ReactiveCheckBoxes from './common/CheckBoxFilters';
import LoadingIndicator from './common/LoadingIndicator';
//import { PagingTable } from 'grommet-controls';
import { Context } from './Context';

const PaginatedDataTable = withPagination(DataTable);
//const CorrectedDataTable = withCorrectedSearch(DataTable);
const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    checkBox: {
        color: {
            light: 'status-critical',
            dark: 'status-critical',
        }
    }
});
const customDateFormatter = new Intl.DateTimeFormat('en-US', {
    //weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
});

class PlacemarkListTable extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.categoryTypes = {
            boxes: ['Spaces', 'Services', 'Facilities', 'Info', /*'Report Issue'*/], //Report Issue?
            default: ['Spaces', 'Services', 'Facilities', 'Info', /*'Report Issue'*/], //Reposrt Issue?
        };
        this.state = {
            filter: false,
            categoryFilters: this.categoryTypes.default,
            search: ""
        };
    }
    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        let tid = elem.getElementsByTagName('th')[0].textContent;
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    //console.log("CORRECT ID", tid, trs[idx].getElementsByTagName('th')[nro].textContent)
                        //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }
    onCheckBoxUpdated = filters => {
        if (this.state.categoryFilters.length != filters.length || !filters.every(e => this.state.categoryFilters.includes(e))) {
            this.setState({ categoryFilters: filters });
        }
    }
    categoryFilterEvent = event => {
        if (this.state.categoryFilters.length === this.categoryTypes.boxes.length) {
            return true;
        }
        const filters = this.state.categoryFilters;
        let validDate = false;
        if (filters.includes("Spaces")) {
            validDate = validDate || (event.category && event.category.location && event.category.location == "Spaces");
        }
        if (filters.includes("Services")) {
            validDate = validDate || (event.category && event.category.location && event.category.location == "Services");
        }
        if (filters.includes("Facilities")) {
            validDate = validDate || (event.category && event.category.location && event.category.location == "Facilities");
        }
        if (filters.includes("Info")) {
            validDate = validDate || (event.category && event.category.location && event.category.location == "Info");
        }
        if (filters.includes("Report Issue")) {
            validDate = validDate || (event.category && event.category.location && event.category.location == "Report Issue");
        }
        return validDate;
    }


    render() {
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Placemarks </Heading>
                    <Box direction="row" gap="small" >
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium" }} fill="vertical">
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <StyledBox ref={this.dtwrapper} style={{ "min-width": "1110px" }}>
                            {this.renderPlacemarksTable(this.props, this.categoryTypes, this.onCheckBoxUpdated, this.categoryFilterEvent)}
                        </StyledBox>
                    </IconThemeContext.Extend>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>
        );
    }

    handleFilter() {
        this.setState({ filter: !this.state.filter });
        //console.log('New:', this.state.filter);
    }
    renderToolTipText(orphaned, published, isOverDue) {
        if (orphaned)
            return "This placemark is not fully configured.<br />Please edit to assign a category.";
        if (!published)
            return "This placemark has not been published yet";
        if (isOverDue)
            return "Next Cleaning time is overdue";
        return "";
    }
    updateFilteredColumns = (data) => this.setState({ filteredColumns: data })

    renderPlacemarksTable(props, categoryTypes, onCheckBoxUpdated, categoryFilterEvent) {
        let COLUMNS = [
            // {
            //     property: 'lcname',
            //     header: 'Name',
            //     render: placemarkdata => <Text>{placemarkdata.name}</Text>
            // },
            {
                property: 'lcname',
                header: 'Name',
                render: placemarkdata => <Text>{placemarkdata.name}</Text>,
                isFixed: true
            },
            //{
            //  property: 'lccategory',
            //  header: 'Category Name',
            //  render: placemarkdata => <Text>{placemarkdata.categoryname}</Text>
            //},
            //{
            //  property: 'lcparent',
            //  header: 'Category Parent',
            //  render: placemarkdata => <Text>{placemarkdata.categoryparent}</Text>
            //},
            //{
            //  property: 'lclocation',
            //  header: 'App Location',
            //  render: placemarkdata => <Text>{placemarkdata.categorylocation}</Text>
            //},
            // {
            //     property: 'lcparentloc',
            //     header: 'App Location',
            //     render: placemarkdata => <Text>{constants.APP_LPN(placemarkdata.categorylocation, placemarkdata.categoryparent, placemarkdata.categoryname)}</Text>
            // },
            {
                property: 'lcparentloc',
                header: 'App Location',
                render: placemarkdata => < Text >
                    {constants.APP_LPN(placemarkdata.categorylocation, placemarkdata.categoryparent, placemarkdata.categoryname)}</Text>
            },
            {
                property: 'lcbuilding',
                header: 'Building',
                render: placemarkdata => <Text >{placemarkdata.buildingName}</Text>,
                isFixed: true
            },
            {
                property: 'lclevel',
                header: 'Level',
                render: placemarkdata => <Text >{placemarkdata.levelName}</Text>,
                isFixed: true
            },
            //{
            //    property: 'lcdepartment',
            //    header: 'Department',
            //    render: placemarkdata => <Text >{placemarkdata.department}</Text>
            //},
            {
                property: 'status',
                header: 'Status',
                render: placemarkdata => <Text >{placemarkdata.cleaningStts}</Text>
            },
            {
                property: 'nextCleaning',
                header: 'Next Cleaning Due',
                render: placemarkdata => <Text >{placemarkdata.nxtClnD}</Text>
            },
            {
                property: 'published',
                header: <Box justify="center" height="100%">Published</Box>,
                render: placemarkdata => placemarkdata.hasIssue ? (
                    <Box direction="row" /*border="all"*/ /*pad={{ vertical: "small" }}*/ alignContent="center">
                        {/*<Grommet theme={customTheme} >*/}
                        {/*    <CheckBox checked={placemarkdata.published} disabled />*/}
                        {/*</Grommet>*/}
                        <CheckBox checked={placemarkdata.published} disabled />
                        <Box fill={true} align="end" alignContent="center" alignSelf="center">
                            {/*<Button alignSelf="end" plain icon={<CircleInformation color="#F04953"/>} onClick={evt => props.handleInfo(evt)}/>*/}
                            <a data-for={placemarkdata.id} style={{ "height": "24px" }}
                                //data-tip="This placemark will not appear in the app until correctly configured.<br />Please edit to assign a category from the drop down list."
                                data-tip={this.renderToolTipText(placemarkdata.orphaned, placemarkdata.published, placemarkdata.isOverDue)}>
                                <CircleInformation size="medium" color="#F04953" style={{ height: "24px" }} />
                            </a>
                            <ReactTooltip id={placemarkdata.id} place="left" type="dark" effect="solid" arrowColor="transparent" multiline />
                        </Box>
                    </Box >) :
                    <CheckBox checked={placemarkdata.published} disabled />
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: placemarkdata => <Text hidden>{placemarkdata.id}</Text>,
            },
            //{
            //    property: 'id',
            //    header: '',
            //    primary: true,
            //    render: placemarkdata => (placemarkdata.synced || !RB.canEdit(constants.PERMS.LOC_CONTENT)) ?
            //        <Box direction='row'><Button plain style={{ cursor: 'inherit' }} icon={<Blank />} /></Box> :
            //        placemarkdata.hasIssue ?
            //            <Box direction='row'><Button plain onClick={evt => props.handleEdit(evt, placemarkdata.id)} icon={<Edit color="#f04953" />} /></Box> :
            //            <Box direction='row'><Button plain onClick={evt => props.handleEdit(evt, placemarkdata.id)} icon={<Edit color="#333333" />} /></Box>
            //},
        ];

        function removeColumn(propertyName) {
            if (COLUMNS.findIndex(c => c.property == propertyName) != -1) {
                COLUMNS.splice(COLUMNS.findIndex(c => c.property == propertyName), 1);
            }
        }

        if (!(this.context.feature.find(f => f.name == "siteWellness").isEnabled) || !constants.release().Feature_citrix) {
            removeColumn("status");
            removeColumn("nextCleaning");
        }

        let DATA = [];

        if (props.placemarksdata[0] && props.placemarksdata[0].count != 0) {
            props.placemarksdata.filter(categoryFilterEvent).forEach(ev => {
                let p = (ev.category && ev.category.parent && !ev.category.deleted) ? ev.category.parent : '';
                let l = (ev.category && ev.category.location && !ev.category.deleted) ? ev.category.location : '';
                let n = (ev.category && ev.category.name && !ev.category.deleted) ? ev.category.name : '';
                let lpn = constants.APP_LPN(l, p, n);

                let levelObj = props.levelsdata.find(l => l.mergedId == ev.levelId);
                let buildingObj = levelObj ? props.buildingsdata.find(b => b.mergedId == levelObj.mergedBuildingId) : '';

                let isRoomOrDesk = (ev.teemType == "room" || ev.placemarktype == "Conference Room" || ev.teemType == "desk" || ev.placemarktype == "Desk");
                let isValidDate = (isRoomOrDesk && !isNaN(Date.parse(ev.nextCleaningDue)));
                let isOverDue = isValidDate ? new Date(ev.nextCleaningDue) < new Date() : false;
                let orphaned = !ev.categoryid || (ev.category && ev.category.deleted);
                let published = levelObj ? levelObj.published : false;
                DATA.push({
                    ...ev,
                    lcname: ev.name ? ev.name.toLowerCase() : '',
                    lcdepartment: ev.department ? ev.department.toLowerCase() : '',
                    categoryname: n,
                    lccategory: (ev.category && ev.category.name && !ev.category.deleted) ? ev.category.name.toLowerCase() : '',
                    categoryparent: (ev.category && ev.category.parent && !ev.category.deleted) ? ev.category.parent : '',
                    lcparent: p,
                    categorylocation: (ev.category && ev.category.location && !ev.category.deleted) ? ev.category.location : '',
                    lclocation: l,
                    lcparentloc: lpn,
                    orphaned: orphaned,

                    //building, level, ispublished
                    lcbuilding: buildingObj ? buildingObj.mergedName.toLowerCase() : '',
                    buildingName: buildingObj ? buildingObj.mergedName : '',
                    lclevel: levelObj ? levelObj.meridianName.toLowerCase() : '',
                    levelName: levelObj ? levelObj.meridianName : '',
                    published: published,
                    status: isRoomOrDesk && ev.cleaningStatus ? ev.cleaningStatus.toLowerCase() : '',
                    nextCleaningDueInt: ev.nextCleaningDue && isValidDate ? new Date(ev.nextCleaningDue).getTime() : "",
                    isValidDate: isValidDate,
                    nxtClnD: isValidDate ? customDateFormatter.format(new Date(ev.nextCleaningDue)) : null,
                    isOverDue: isOverDue,
                    hasIssue: orphaned || isOverDue || !published,
                    cleaningStts: isRoomOrDesk && ev.cleaningStatus ? ev.cleaningStatus.toLowerCase() : ''
                });
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.name.toLowerCase().includes(search) ||
                constants.APP_LPN(d.categorylocation, d.categoryparent, d.categoryname).toLowerCase().includes(search) ||
                d.buildingName.toLowerCase().includes(search) ||
                d.levelName.toLowerCase().includes(search) ||
                d.department.toLowerCase().includes(search) ||
                d.cleaningStts.toLowerCase().includes(search) ||
                (d.nxtClnD != null && d.nxtClnD.toString().toLowerCase().includes(search)) ||
                ("published".includes(search) && d.published) ||
                (("error".includes(search) || "info".includes(search)) && d.hasIssue)
            );
        }

        console.log('DATA:', DATA);
        // const pColumns = [
        //     {
        //         Header: p => (<Button plain icon={<FormSearch />} onClick={() => this.handleFilter()} />),
        //         accessor: 'id',
        //         filterable: false,
        //         sortable: false,
        //         minWidth: 60,
        //         decorations: {
        //             header: {
        //                 align: 'start',
        //             },
        //             cell: {
        //                 size: 'small',
        //                 align: 'start'
        //             },
        //         },
        //         Cell: p => (
        //             <Box direction='row'><Button plain onClick={evt => props.handleEdit(evt, p.original.id)} icon={<Edit />} /></Box>
        //         ),
        //     }, {
        //         Header: 'Name',
        //         accessor: 'name',
        //         minWidth: 200,
        //         Cell: p => (
        //             <Box direction='row' style={{ wordBreak: 'break-word' }}>{p.original.name}</Box>
        //         ),
        //     }, {
        //         Header: 'App Location',
        //         accessor: 'lcparentloc',
        //         minWidth: 325,
        //         decorations: {
        //             cell: {
        //                 align: 'start',
        //             },
        //         },
        //         Cell: p => (
        //             <Box direction='row' align='center' fill>{p.original.lcparentloc}</Box>
        //         ),
        //     }
        // ];  

        if (props.loading) {
            return (
                <LoadingIndicator />
            );
        }

        if (this.state.filteredColumns)
            COLUMNS = constants.filterActiveColumns(COLUMNS, this.state.filteredColumns);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                renderExtraHeader={() => <ReactiveCheckBoxes checkboxes={categoryTypes} onUpdate={onCheckBoxUpdated} />}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "lcparentloc" || c.property === "lcbuilding" || c.property === "lclevel"
                    //    || c.property === "lcdepartment" || c.property === "nextCleaning" || c.property === "status"
                }))}
                data={DATA}
                noLoadingMsg={this.props.placemarksdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcname', label: 'Name' },
                //    { col: 'lcparentloc', label: 'App Location' },
                //    { col: 'lcbuilding', label: 'Building' },
                //    { col: 'lclevel', label: 'Level' },
                //    { col: 'lcdepartment', label: 'Department' },
                //    { col: 'published', label: 'Published' },
                //    { col: 'status', label: 'Status' },
                //    { col: 'nextCleaningDueInt', label: 'Next Cleaning Due' }
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                onUpdated={this.onDTUpdated.bind(this)}
                styled

                //PFplaceholder="Search by Name, App Location, Building, Level, Department or Status"
                //PFplaceholder={this.context.feature.find(f => f.name == "siteWellness").isEnabled ? "Search by Name, App Location, Building, Level or Status" : "Search by Name, App Location, Building, Level"}
                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
                PFfilterablecolumns={true}
                PFcolumns={this.state.filteredColumns ? this.state.filteredColumns : constants.createFilters(COLUMNS)}
                PfonChangeColumns={this.updateFilteredColumns}
            //alignSelf="stretch"
            //size="large"
            //onMore={() => console.log('!!! onMore!!!')}
            />
            // <PagingTable
            //     data={DATA}
            //     columns={pColumns}
            //     defaultPageSize={25}
            //     filterable={this.state.filter}
            //     sortable={true}
            //     showPagination={true}
            //     resizable={false}
            //     loading={false}
            //     pageSizeOptions={[5, 10, 25, 50, 100]}
            //     showPageJump={false}
            //     decorations={{
            //         table: { elevation: 'none', /*border: 'all'*/ },
            //         /*headerGroup: {
            //             background: 'brand', border: 'horizontal', size: 'large', align: 'center',
            //         }, */
            //         header: { border: 'bottom', /*align: 'center'*/ },
            //         //filter: { /*background: 'light-2',*/ border: 'all' },
            //         filterInput: { size: 'medium', placeholder: 'Filter' },
            //         body: { animation: { type: 'fadeIn', duration: 1000, size: 'large' } },
            //         /*rowOdd: {
            //           background: { color: {constants.BACKGROUND_COLOR}, opacity: 'medium' },
            //         },*/
            //         row: { align: 'center' },
            //         pagination: { pad: { vertical: 'small' } },
            //     }}
            // />
        );
    }
}
export default PlacemarkListTable;
