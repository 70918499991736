import React from "react";
import { Box, Layer, Button, Text, Heading } from 'grommet';
import { Close, FormClose } from 'grommet-icons';
import constants from '../../constants';
import { StyledBox } from './StyledBox';
import { StyledButton } from './StyledButton';
import LoadingIndicator from './LoadingIndicator';

export default class PopUpCancel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: this.props.open };
    }
    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({ open: this.props.open });
        }
    }
    //onClose() {
    //    this.setState({ open: false });
    //    //this.props.history.push(this.props.to);
    //    if (this.props.Push) {
    //        this.props.Push();
    //    }

    //}

    render() {
        let { open } = this.state;
        if (open) {
            return (
                <Layer
                    responsive={false}
                    style={{ width: `${this.props.width ? this.props.width: "inherit"}` }}
                    position="center"
                    modal={true}
                    onClickOutside={() => this.props.onCancel()}
                    onEsc={() => this.props.onCancel()}
                >
                    {this.props.loading?
                        (<Box pad="small"><LoadingIndicator message="Booking in progress" /></Box>)
                        :
                        (<Box>

                    <Box flex={false} direction="row" justify="end" alignSelf="end" pad="none">
                        <Button icon={<FormClose />} title="Close" onClick={() => this.props.onCancel()} />
                    </Box>

                            <Box pad={{ horizontal: "medium", bottom: "medium" }} gap="small" width="large">
                                <Heading level={3} margin="none">
                                    {this.props.header || "Warning"}
                                </Heading>
                                {this.props.confirmCancel &&
                                    <Text>{"By cancelling this notification your changes will be discarded!"}</Text>
                                }
                                {!this.props.confirmCancel &&
                                    <Text>{this.props.text || "Updates have not been saved. Save or Discard your changes before leaving the page."}</Text>
                                }
                                {this.props.children}
                                <Box
                                    tag="footer"
                                    gap="small"
                                    direction="row"
                                    align="center"
                                    justify="end"
                                    pad={{ top: "medium", bottom: "small" }}

                                >
                                    <StyledButton typeSave label={this.props.SaveText || "Save all changes"} onClick={() => this.props.onSave()} disabled={this.props.isDisabled} />
                                    <StyledButton typeCancel label={this.props.CancelText || "Discard all changes"} onClick={() => this.props.onDiscard()} />
                                    {/*<StyledButton typeCancel label="Cancel" onClick={() => this.props.onCancel()} />*/}
                                </Box>
                            </Box>
                            
                        </Box>)}
                </Layer>
            );
        }
        else {
            return null;
        }
    }
}
