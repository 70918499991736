import React from 'react';
import { connect } from 'react-redux';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Grommet, InfiniteScroll, RadioButtonGroup, RadioButton, CheckBox } from 'grommet';
import { CircleInformation, Upload, Trash } from 'grommet-icons';
import constants from '../../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './StyledBox';
import { StyledButton } from './StyledButton';
import PopUpConfirm from './PopUpConfirm';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import ReactTooltip from "react-tooltip";
import { Image as ImageComp } from 'grommet';

const customTheme2 = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        },
    },
    formField: {
        border: {
            color: "transparent",
        },
        pad: "none",
    }
});
const customTheme3 = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        },
    },
});

export class ImageHandler extends React.Component {
    constructor(props) {
        super();
        this.stamp = Date.now().toString();
        this.state = {
            newimage: false,
            removeIconOpen: false
        };
    };
    readFile(f) {
        if (f) {
            const reader = new FileReader();
            const self = this;
            reader.onload = function () {
                const data = reader.result;
                console.log('File', data.length);
                const base64data = btoa(data);
                //TODO
                //self.props.categorydata.imageContent = base64data;
                self.props.setImageContent(base64data);
            };
            reader.readAsBinaryString(f);
        }
    }
    fileChangedHandler = (event) => {
        //console.log(event.target.files[0]);
        const file = event.target.files[0];
        console.log(file);
        if (file != undefined) {
            if (file.size > 1024 * 1024 * 2) {
                this.image_err = "File too large: " + Math.round((file.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.image_err = "";
                this.readFile(file);
                this.setState({ file: file, newimage: true, imagechanged: true, letValidate: true });
            }
        }
        else {

        }
    }
    shortcut() {
        if (this.state.file == "") {
            return null;
        }
        else if (this.state.file != null) {
            var Data = [];
            Data.push(this.state.file);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/x-png,image/jpeg" }))
            return (
                <Box direction="row">
                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                    <ImageComp style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("real");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return null;
        }
    }
    removeIcon() {
        this.props.setImageContent(null);
        this.setState({ removeIconOpen: false, newimage: false });
    }
    render() {
        return (
            <Box>
                <PopUpConfirm open={this.state.removeIconOpen} text={`Please confirm that you want to delete this image. The image associated with this ${this.props.type} Action will no longer be visible in the app.`}
                    onClose={() => this.setState({ removeIconOpen: false })} onDelete={() => this.removeIcon()} />
                <Box flex="shrink">
                    <Grommet theme={customTheme2}>
                        <FormField
                            label="Image"
                            error={this.image_err}
                            label={
                                <Box direction="row" gap="xsmall">
                                    <Box>Image</Box>
                                    <Box
                                        justify="center"
                                        data-for="upload_img_info"
                                        data-tip="">
                                        <CircleInformation size="small" />
                                        <ReactTooltip
                                            id="upload_img_info"
                                            place="right"
                                            type="dark"
                                            effect="solid"
                                            arrowColor="transparent"
                                            multiline>
                                            Please select a brand approved image to display as your {this.props.type} Action image.
                                            <br />
                                            Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.
                                        </ReactTooltip>
                                    </Box>
                                </Box>
                            }>
                            <Grommet theme={customTheme3}>
                                <Box direction="row" gap="small">
                                    <StyledBox
                                        style1
                                        pad={{ horizontal: "small", top: "small" }}
                                        width={{ min: "101px" }}
                                        fill={false}>
                                        <FormField label="">
                                            <Box direction="row" justify="center">
                                                {this.state.newimage ? (
                                                    <Box alignSelf="center">
                                                        {this.shortcut()}
                                                    </Box>
                                                ) : this.props.imageUrl == "" ||
                                                    this.props.imageUrl == null ? (
                                                            <Box
                                                                height="75px"
                                                                justify="center">
                                                                <input
                                                                    type="file"
                                                                    id="real"
                                                                    hidden="hidden"
                                                                    accept="image/x-png,image/jpeg"
                                                                    onChange={this.fileChangedHandler}
                                                                />
                                                                <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1}/>}
                                                                    onClick={(e) => {
                                                                        const c = document.getElementById("real");
                                                                        c.click();
                                                                    }}
                                                                />
                                                            </Box>
                                                        ) : (
                                                            <Box justify="center">
                                                                <input
                                                                    type="file"
                                                                    id="real"
                                                                    hidden="hidden"
                                                                    accept="image/x-png,image/jpeg"
                                                                    onChange={this.fileChangedHandler}
                                                                />
                                                                <ImageComp
                                                                    height="75"
                                                                    src={`${this.props.imageUrl}?${this.stamp}`}
                                                                    style={{cursor: "pointer"}}
                                                                    onClick={(e) => {
                                                                        const c = document.getElementById("real");
                                                                        c.click();
                                                                    }}
                                                                />
                                                            </Box>
                                                        )}
                                            </Box>
                                        </FormField>
                                    </StyledBox>
                                    <Box justify="center">
                                        <Button
                                            onClick={() => this.setState({ removeIconOpen: true })}
                                            disabled={(this.props.imageUrl == "" || this.props.imageUrl == null) && !this.state.newimage}>
                                            <Trash />
                                        </Button>
                                    </Box>
                                </Box>
                            </Grommet>
                        </FormField>
                    </Grommet>
                </Box>
            </Box>
            );
    }
}

export default connect()(ImageHandler);