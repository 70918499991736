import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AssetAction from '../action/AssetAction';
import * as SiteAction from '../action/SiteAction';
import * as AssetHistoryAction from '../action/AssetHistoryAction';
import TrackerApi from '../api/TrackerApi';
import AssociationApi from '../api/AssociationApi';
import * as DepartmentAction from '../action/DepartmentAction';
import * as CollectionAction from '../action/CollectionAction';
import constants from '../constants';
// eslint-disable-next-line
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, Tabs, Tab } from 'grommet';
import { Android, Apple, Tag, Sync, Refresh } from 'grommet-icons';
import * as MeridianSDK from "@meridian/web-sdk";
import './Meridian.css';
import { Battery, BatteryAmber, BatteryEmpty, BatteryRed, BatteryUnknown } from '../grommet-extras/Battery';
import AssetStatusHistoryListTable from './AssetStatusHistoryListTable';
import AssetHistoryListContainer from './AssetHistoryListContainer';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import { Context } from './Context';
//var date = new Date().toLocaleDateString("en-US");

class AssetEditContainer extends Component {
    static contextType = Context;
    constructor() {
        super();
        this.done = false;
        this.mapRenderDone = false;
        this.state = {
            validating: false, value: "", loaded: false, status: "Available", tabIndex: 0, disabledButton: false, meridianApiFailed: false,
            meridianData: {
                map: {
                    name: "",
                    group: {
                        name: ""
                    }
                },
                id: "",
                tags: []
            },
            syncTime: "",
            assetdataLoading: true,
            sitedataLoading: true,
            historydataLoading: true,
            meridianDataLoading: true,
            showCurrent: true,
            showLastKnown: false,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }
    statusCode_err = '';

    ClearErrorMessages() {
        this.statusCode_err = '';
    }
    componentWillReceiveProps(props) {
        //console.log("troubleC",props.sitedata , props.assetdata , props.assetdata.mapId , this.state.tabIndex == 0 , !this.mapRenderDone);
        if (props.sitedata && props.assetdata && props.assetdata.mapId && this.state.tabIndex == 0 && !this.mapRenderDone) {
            this.mapRenderDone = true;
            //setTimeout(() => this.renderMap(props.assetdata.mapId, props.sitedata.appID, props.sitedata.appToken), 1000);
        }
        //Fecth meridian
        console.log("MERIDIAN: asd", props, sessionStorage.getItem("locationId"))
        if (props.sitedata && props.sitedata.appID && props.sitedata.id == sessionStorage.getItem("locationId") && props.assetdata && props.assetdata.id && this.state.tabIndex == 0 && this.state.meridianData.id == "" && !this.done) {
            this.done = true;
            fetch(`https://edit.meridianapps.com/api/locations/${props.sitedata.appID}/asset-beacons/${props.assetdata.id}`,
                this.getOption(props.sitedata.appToken)).then(res => res.json()).then(data => {
                    var strDate = "";
                    if (data && data.last_heard != null) {
                        var date = new Date(data.last_heard * 1000);
                        strDate = props.assetdata.mapId ? this.formatDate(date) : "";
                    }
                    if (data.detail != "Not found.") {
                        this.setState({ meridianData: data, syncTime: strDate }, () => {

                            if (this.state.meridianData.map && this.state.meridianData.map.id) {
                                setTimeout(() => this.renderMap(this.state.meridianData.map.id), 2000);
                            }

                        });
                        //GET ZONE
                        if (data.map.id) {
                            this.fetchMeridianZone(props.sitedata.appID, data.map.id, props.assetdata.id, props.sitedata.appToken);
                        }
                    }
                    else if (data.detail == "Not found.") {
                        this.setState({ meridianApiFailed: true });
                    }
                });
        }
        //if (!this.state.tracker) {
        //    AssociationApi.getAllAssociations()
        //        .then(data => {
        //            let assoc = data.find(a => a.beaconId == this.props.match.params.assetid);
        //            if (assoc) {
        //                TrackerApi.getTracker(assoc.assetId)
        //                    .then(tracker => this.setState({ tracker: tracker }))
        //                    .catch(error => console.log('*DEBUG*- Get Tracker error', error));
        //            } else {
        //                this.setState({ tracker: {department: "Not available", colection: "Not availbale"} })
        //            }
        //        })
        //        .catch(error => console.log('*DEBUG*-  Get All Association error', error));
        //}
    }
    componentDidMount() {
        const id = this.props.match.params.assetid;
        this.props.action.getAssetAction(id)
            .then(response => this.setState({ assetdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Assets List Error', error);
            });
        this.props.action.getSiteAction(sessionStorage.getItem("locationId"))
            .then(response => this.setState({ sitedataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getAssetHistoryAction(id)
            .then(response => this.setState({ historydataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetHistory List Error', error);
            });
        this.props.action.getDepartmentsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department List Error', error);
            });
        this.props.action.getCollectionsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Collection List Error', error);
            });
        if (!this.state.tracker) {
            AssociationApi.getAllAssociations()
                .then(data => {
                    let assoc = data.find(a => a.beaconId == this.props.match.params.assetid);
                    if (assoc) {
                        TrackerApi.getTracker(assoc.assetId)
                            .then(tracker => this.setState({ tracker: tracker }))
                            .catch(error => {
                                var errorArray = this.state.apiErrorList.concat(error);
                                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                                console.log('*DEBUG*- Get Tracker error', error)
                            });
                    } else {
                        this.setState({ tracker: { departmentId: null, collectionId: null } });
                    }
                })
                .catch(error => {
                    var errorArray = this.state.apiErrorList.concat(error);
                    this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                    console.log('*DEBUG*- Get All Association error', error)
                });
        }
    }
    componentWillUnmount() {
        let cs = document.querySelector("[id='coloured']");
        if (cs)
            document.querySelector("[id='coloured']").remove();
        if (this.map) {
            this.map.destroy();
        }
    }
    renderMap(floorid, locid = this.props.sitedata.appID, token = this.props.sitedata.appToken) {
        let point = this.state.showLastKnown ? [{
            type: "point",
            x: this.props.assetdata.x,
            y: this.props.assetdata.y,
            backgroundColor: /*"#c00"*/"none",
            //size: 100,
            //backgroundImage: 'https://hpepngdemostor.blob.core.windows.net/images/locationmarker.gif',
            size: 50,
            backgroundImage: 'https://hpepngdemostor.blob.core.windows.net/images/locationm.gif',
            title: "Last Known"
        }] : [];
        if (!floorid || !locid || !token || this.state.tabIndex != 0) {
            //Invalid parameters to show the map
            //Do nothing
        }
        //else if (this.map && !this.map.isDestroyed) {
        //    this.map.update({ floorID: floorid });
        //}
        else if (document.getElementById("meridian-map")) {
            MeridianSDK.init({
                api: new MeridianSDK.API({
                    environment: this.props.sitedata.eUorNon ? "eu" : "production",
                    //token: "6c09d265b1413fc768f4e08447ea95c8923a9c78",
                    token: token
                })
            });
            var element = document.getElementById("meridian-map");
            if (element) {
                element.innerHTML = "";
            }
            console.log("element", element);
            this.map = MeridianSDK.createMap(
                //document.getElementById("meridian-map"),
                element,
                {
                    //locationID: "5198682008846336",
                    locationID: locid,
                    floorID: floorid,
                    shouldMapPanZoom: function restrictedPanZoom(event) {
                        if (event.type === "wheel" && !event.altKey) {
                            return false;
                        } else if (event.type === "touchstart") {
                            return event.touches.length >= 2;
                        }
                        return true;
                    },
                    //showFloorsControl: true, //this.state.showMapControls,
                    //showTagsControl: true, //this.state.showMapControls,

                    annotations: point,
                    height: "500px",
                    placemarks: {
                        filter: p => {
                            return false;
                        }
                    },

                    tags: {
                        filter: t => {
                            //return this.state.tags.includes(t.mac);
                            //no real-time
                            return this.state.showCurrent && this.props.assetdata.id === t.mac;
                        },
                        //showControlTags: true
                    },
                    showTagsControl: false,
                    showSearchControl: false
                }
            );
        }
        //return (<div id="meridian-map" className="map" />);
    }
    renderBatteryIcon(level) {
        if (level < 20) {
            return (
                <Box /*direction="row" */ gap="xsmall">
                    <BatteryRed color="#FF4040" style={{ width: "34.365", height: "18" }} />
                    <Text>{level}%</Text>
                </Box>);
        }
        else if (20 <= level && level <= 60) {
            return (
                <Box /*direction="row"*/ gap="xsmall">
                    <BatteryAmber color="#FFAA15" style={{ width: "34.365", height: "18" }} />
                    <Text>{level}%</Text>
                </Box>);
        }
        else if (60 < level && level <= 100) {
            return (
                <Box /*direction="row"*/ gap="xsmall">
                    <Battery color="#01a982" style={{ width: "34.365", height: "18" }} />
                    <Text>{level}%</Text>
                </Box>);
        }
        else {
            return (
                <Box direction="row">
                    <BatteryUnknown color="#CCCCCC" style={{ width: "34.365", height: "18" }} />
                </Box>);
        }

    }
    updateStatus(o) {
        this.statusCode_err = "";
        var option = o.option;
        //this.props.assetdata.serviceStateId = o.selected;
        this.props.historydata.serviceStateId = option.id;
        //this.props.assetdata.serviceState = o.option;
        this.setState({ status: option.name });
    }
    handleSave() {
        let errorsno = 0;
        if (this.context.feature.find(f => f.name == "siteWellness").isEnabled && (this.props.historydata.serviceStateId == null || this.props.historydata.serviceStateId === "")) {
            errorsno++;
            this.statusCode_err = "Select a Status";
        }
        if (errorsno == 0) {
            this.setState({ disabledButton: true });
            this.props.historydata.history = [];
            console.log('Save: OK', this.props.historydata);
            this.props.action.saveAssetHistoryAction(this.props.historydata)
                .then((response) => {
                    this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                    //this.props.history.push('/categories');
                }).catch(error => {
                    console.log('*DEBUG*-Save AssetEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.setState({ disabledButton: false });
        }

    }
    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            //this.props.history.push('/eventstable');
        }
    }
    DeviceFlag() {
        const data = this.props.assetdata;
        //A0E6F83810BB - Holly's cart id
        if (data.mapId == "" || data.mapId == null) {
            return "Untraceable";
        }
        if (data.mapId) {
            return "Healthy";
        }
        if (data.assetBeaconTags.length == 0) {
            return "Orphans";
        }
        if (data.batteryLevel < 20) {
            return "Low Charge";
        }
    }
    getOption(token, method = 'get') {
        //let token = "6c09d265b1413fc768f4e08447ea95c8923a9c78"
        let bearer = `Token ${token}`;
        let headers = new Headers();
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        var option = {
            method: method,
            headers: headers
        };
        return option;
    }
    fetchMeridian() {
        fetch(`https://edit.meridianapps.com/api/locations/${this.props.sitedata.appID}/asset-beacons/${this.props.assetdata.id}`,
            this.getOption(this.props.sitedata.appToken)).then(res => res.json()).then(data => {
                var strDate = "";
                if (data && data.last_heard != null) {
                    var date = new Date(data.last_heard * 1000);
                    strDate = this.props.assetdata.mapId ? this.formatDate(date) : "";
                }
                this.setState({ meridianData: data, syncTime: strDate }, () => { if (data.map.id) this.renderMap(data.map.id); });
                console.log("helo", data, strDate);
                //GET ZONE
                if (data.map.id) {
                    this.fetchMeridianZone(this.props.sitedata.appID, data.map.id, this.props.assetdata.id, this.props.sitedata.appToken);
                }
            });
    }
    fetchMeridianZone(locationId, mapId, assetId, token) {
        let zoneUrl = `https://edit.meridianapps.com/api/locations/${locationId}/tag-zones?map_id=${mapId}`;
        fetch(zoneUrl, this.getOption(token))
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //NO ZONE
                    return { results: [] };
                }
            })
            .then(data => {
                let isZoneFound = false;
                data.results.forEach(zone => {
                    if (zone.tag_ids.includes(assetId)) {
                        this.setState({ currentZone: zone.name });
                        isZoneFound = true;
                        return;
                    }
                });
                if (!isZoneFound) {
                    this.setState({ currentZone: "No Zone" });
                }
            })
            .catch(error => this.setState({ currentZone: "Zone Error" }))
    }
    formatDate(utc) {
        var date = new Date(utc);
        var strDate =
            date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2)
            + "-" + ("00" + date.getDate()).slice(-2)
            + " "
            + ("00" + date.getHours()).slice(-2) + ":"
            + ("00" + date.getMinutes()).slice(-2)
            + ":" + ("00" + date.getSeconds()).slice(-2);
        return strDate;
    }

    render() {
        const { assetdata, sitedata, historydata, collectionsdata, departmentsdata } = this.props;
        const { loaded, assetdataLoading, sitedataLoading, historydataLoading, meridianDataLoading } = this.state;

        if (assetdataLoading || sitedataLoading || historydataLoading || this.state.meridianData.id == "" && !this.state.meridianApiFailed || !this.state.meridianData.id && !this.state.meridianApiFailed || !this.state.tracker) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        console.log("Asset:", assetdata, historydata, this.state.meridianData);
        console.log("traki", this.state);
        const battery = assetdata.batteryLevel + "%";
        var stat = constants.ASSET_STATUS.find(s => s.id == historydata.serviceStateId);
        stat = stat ? stat : {};
        var groupName = assetdata.id == "546C0E032A87" ? "Radiology" : assetdata.id == "A0E6F83810BB" ? "Maternity" : assetdata.id == "546C0E082AFB" ? "Emergency " : "";
        let collection = this.state.tracker && this.state.tracker.collectionId && collectionsdata ? collectionsdata.find(c => c.id == this.state.tracker.collectionId) : "";
        if (collection && !document.querySelector("[id='coloured']")) {
            let selector = `.meridian-tag-label-${collection.labelName.replace(" ", "-")}`;
            let rule = `background-color:${collection.hexColor};background-image:none`;
            var style = document.createElement("style");
            style.setAttribute('id', "coloured");
            style.innerHTML = `${selector} {
                ${rule}
                }`;
            document.head.appendChild(style);
        }
        let collectionName = collection ? collection.name : "No collection available";
        let departmentName = this.state.tracker && this.state.tracker.departmentId && departmentsdata ? departmentsdata.find(d => d.id == this.state.tracker.departmentId) : "";
        departmentName = departmentName ? departmentName.name : "No department available";
        return (
            <Context.Consumer>
                {values => {
                    return (
                        <Grid fill={true}
                            rows={['xsmall', 'auto']}
                            columns={['100%', 'flex']}

                            areas={[
                                { name: 'header', start: [0, 0], end: [1, 0] },

                                { name: 'main', start: [0, 1], end: [0, 1] },
                                { name: 'side', start: [1, 0], end: [1, 1] },
                            ]}
                        >
                            <Box gridArea='header' /*background='brand'*/ direction="row"
                                align="center"
                                //pad={{ vertical: "medium" }}
                                pad="medium"
                                justify="between"
                                background={constants.BACKGROUND_COLOR}>
                                <Heading level='2' >{this.state.tabIndex == 0 ? "Asset Details" : this.state.tabIndex == 1 ? "Asset History" : "Asset Status"}</Heading>
                                <Box direction="row" /*pad={{ horizontal: "small", }}*/ gap="small" >
                                    <Box width="100px">
                                        <StyledButton typeCancel label="Back"
                                            onClick={() => {
                                                if (sessionStorage.getItem("organisationId")) {
                                                    if (sessionStorage.getItem("locationId"))
                                                        this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/' + sessionStorage.getItem("locationId") + '/assets');
                                                    else { }
                                                }
                                                else
                                                    this.props.history.push('/assets');
                                            }}
                                            disabled={this.state.disabledButton} />
                                    </Box>
                                    {this.state.tabIndex == 0 && this.context.feature.find(f => f.name == "siteWellness").isEnabled &&
                                        <Box width="100px">
                                            <StyledButton typeSave label="Save" disabled={this.state.disabledButton} onClick={() => this.handleSave()} />
                                        </Box>
                                    }
                                </Box>
                            </Box>

                            <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                                <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                                <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                                <Tabs flex justify="start" activeIndex={this.state.tabIndex} onActive={(idx) => {
                                    this.setState({ tabIndex: idx }, () => setTimeout(() => this.renderMap(this.props.assetdata.mapId, this.props.sitedata.appID, this.props.sitedata.appToken), 1000));

                                }}>
                                    <Tab title="Details">

                                        <Box gap="small" margin={{ "vertical": "small" }}>
                                            <StyledBox style1 direction="column" pad={{ "horizontal": "small" }}>
                                                <Box direction="row">
                                                    <Box width="28%" pad={{ right: "medium" }}>
                                                        <FormField label="Name">
                                                            <TextInput value={assetdata.name} readOnly disabled />
                                                        </FormField>
                                                    </Box>
                                                    <Box width="28%" pad={{ right: "medium" }}>
                                                        <FormField label="External ID">
                                                            <TextInput value={assetdata.externalId} readOnly disabled />
                                                        </FormField>
                                                    </Box>
                                                    <Box width="28%" >
                                                        <FormField label="MAC">
                                                            <TextInput value={assetdata.id} readOnly disabled />
                                                        </FormField>
                                                    </Box>
                                                    {/*<Box width="16%" alignSelf="center" align="center">*/}
                                                    {/*    {assetdata.imageUrl ? (*/}
                                                    {/*        <Image width="40" height="40" src={`${assetdata.imageUrl}`} />*/}
                                                    {/*    ) :*/}
                                                    {/*        <Tag width="40" height="40" />*/}
                                                    {/*    }*/}
                                                    {/*</Box>*/}
                                                    <Box width="16%" alignSelf="center" align="center">
                                                        <Box direction="row">
                                                            {this.renderBatteryIcon(assetdata.batteryLevel)}
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Box direction="row">
                                                    <Box width="28%" pad={{ right: "medium" }}>
                                                        <FormField label="Type" >
                                                            <TextInput value={assetdata.isControlTag ? "Control Tag" : "Tag"} readOnly disabled />
                                                        </FormField>
                                                    </Box>
                                                    <Box width="28%" pad={{ right: "medium" }}>
                                                        <FormField label="Device Flag" >
                                                            <TextInput value={this.DeviceFlag()} readOnly disabled />
                                                        </FormField>
                                                    </Box>
                                                    <Box width="28%">
                                                        {this.context.feature.find(f => f.name == "siteWellness").isEnabled &&
                                                            <FormField label="Status" error={this.statusCode_err}>
                                                                <Select value={/*status[assetdata.serviceStateId]*/stat} options={constants.ASSET_STATUS} onChange={evt => this.updateStatus(evt)} labelKey="name">
                                                                    {option =>
                                                                        <Box pad="small">
                                                                            {option.name}
                                                                        </Box>
                                                                    }
                                                                </Select>
                                                            </FormField>
                                                        }
                                                    </Box>
                                                    {/*<Box width="16%" alignSelf="center" align="center">*/}
                                                    {/*    <Box direction="row">*/}
                                                    {/*        {this.renderBatteryIcon(assetdata.batteryLevel)}*/}
                                                    {/*    </Box>*/}
                                                    {/*</Box>*/}
                                                </Box>
                                            </StyledBox>

                                            <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                {this.state.tracker && this.state.tracker.class == "Asset" &&
                                                    <Box direction="row" gap="medium">
                                                        <Box basis="1/3">
                                                            <FormField label="Manufacturer">
                                                                <TextInput value={this.state.tracker.manufacturerId ? this.state.tracker.manufacturer.name : ""} readOnly disabled />
                                                            </FormField>
                                                        </Box>
                                                        <Box basis="1/3">
                                                            <FormField label="Model">
                                                                <TextInput value={this.state.tracker.modelId ? this.state.tracker.model.name : ""} readOnly disabled />
                                                            </FormField>
                                                        </Box>
                                                        <Box basis="1/3">
                                                            <FormField label="Model Type">
                                                                <TextInput value={this.state.tracker.deviceType ? this.state.tracker.deviceType.name : ""} readOnly disabled />
                                                            </FormField>
                                                        </Box>
                                                    </Box>
                                                }
                                                <Box direction="row" gap="medium">
                                                    <Box basis="1/2">
                                                        <FormField label="Collection">
                                                            <TextInput value={collectionName}
                                                                icon={collection ? < Box round height="23px" background={collection.hexColor ? collection.hexColor : "white"} width="23px" border style={{ "border-width": "2px" }} /> : ""}
                                                                reverse
                                                                readOnly
                                                                disabled />
                                                        </FormField>
                                                    </Box>
                                                    <Box basis="1/2">
                                                        <FormField label="Department">
                                                            <TextInput value={departmentName} readOnly disabled />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                                <Box width="100%">
                                                    <FormField label="Labels" /*height="100%"*/>
                                                        {assetdata.assetBeaconTags && assetdata.assetBeaconTags.length > 0 ?
                                                            <Box wrap direction="row" style={{ gap: "10px" }} pad={{ "left": "small", "right": "small", "top": "small", "bottom": "xsmall" }} align="end" height="100%">
                                                                {assetdata.assetBeaconTags.map(t =>
                                                                    <Box background="#CCCCCC" pad={{ "left": "small", "right": "small" }} justify="center" align="center" round="5px">
                                                                        {t.tag.name}
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                            :
                                                            <TextInput value="No label available" readOnly disabled />
                                                        }
                                                    </FormField>
                                                </Box>
                                                {/*<Box basis="1/3">
                                                    <FormField label="Labels" height="100%">
                                                        {assetdata.assetBeaconTags && assetdata.assetBeaconTags.length > 0 ?
                                                            <Box direction="row" gap="small" pad={{ "left": "small", "right": "small", "top": "medium", "bottom": "xsmall" }} align="end" height="100%">
                                                                {assetdata.assetBeaconTags.map(t =>
                                                                    <Box background="#CCCCCC" pad={{ "left": "small", "right": "small" }} justify="center" align="center" round="5px">
                                                                        {t.tag.name}
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                            :
                                                            <TextInput value="No label available" readOnly disabled />
                                                        }
                                                    </FormField>
                                                </Box>*/}
                                            </StyledBox>
                                            <Box direction="row">
                                                <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }} width={{ "min": "101px" }} fill={false} margin={{ "bottom": "small" }}>
                                                    <FormField label="" error={this.image_err}>
                                                        <Box direction="row" justify="center" >
                                                            {
                                                                (assetdata.imageUrl == "" || assetdata.imageUrl == null) ?
                                                                    <Box height="75px" justify="center">
                                                                        <Tag />
                                                                    </Box>
                                                                    :
                                                                    <Box justify="center">
                                                                        <Image height="75" src={`${assetdata.imageUrl}?${this.stamp}`} />
                                                                    </Box>
                                                            }
                                                        </Box>
                                                    </FormField>
                                                </StyledBox>
                                            </Box>
                                            {(values.feature.find(f => f.name == "siteMap").isEnabled || true) &&
                                                <StyledBox style1 margin={{ bottom: "small" }}>
                                                    <Tabs justify="start"
                                                        activeIndex={this.state.showCurrent ? 0 : 1}
                                                        onActive={idx => {
                                                            let isCurrent = idx == 0 ? true : false;
                                                            let mapID = isCurrent ? this.state.meridianData.map.id : assetdata.mapId;
                                                            this.setState({ showCurrent: isCurrent, showLastKnown: !isCurrent }, () => setTimeout(() => this.renderMap(/*assetdata.mapId*/mapID), 500));
                                                        }}>
                                                        <Tab title="Current location">
                                                            <Box /*gap="small"*/ pad="small">
                                                                <Box direction="row" height="90px" /*pad={{ "horizontal": "small" }}*/>
                                                                    <Box width="23%" pad={{ right: "medium" }}>
                                                                        <FormField label="Building" >
                                                                            <TextInput value={this.state.meridianData.map.group_name ? this.state.meridianData.map.group_name : "No Building"} readOnly disabled />
                                                                        </FormField>
                                                                    </Box>
                                                                    <Box width="23%" pad={{ right: "medium" }}>
                                                                        <FormField label="Floor">
                                                                            <TextInput value={this.state.meridianData.map.name ? this.state.meridianData.map.name : "No Floor"} readOnly disabled />
                                                                        </FormField>
                                                                    </Box>
                                                                    <Box width="23%" pad={{ right: "medium" }}>
                                                                        <FormField label="Zone">
                                                                            <TextInput value={this.state.currentZone} readOnly disabled />
                                                                        </FormField>
                                                                    </Box>
                                                                    <Box width="23%" >
                                                                        <FormField label="Last Heard">
                                                                            <TextInput /*value={assetdata.timestamp? assetdata.timestamp.replace("T", " ").replace("Z", ""): ""}*/ value={this.state.syncTime ? this.state.syncTime : "No Data"} readOnly disabled />
                                                                        </FormField>
                                                                    </Box>
                                                                    <Box width="8%" align="center" alignSelf="center">
                                                                        <Button plain icon={<Refresh color="#01a982" />} onClick={() => this.fetchMeridian()} />
                                                                    </Box>
                                                                </Box>
                                                                {this.state.meridianData && this.state.meridianData.map && this.state.meridianData.map.id ?
                                                                    (<Box border="all" pad="xsmall" background="#fafafa" style={{ borderRadius: "5px 5px 5px 5px"/*, boxShadow: "2px 2px 4px 2px rgba(100, 100, 100, 0.35)"*/ }}>
                                                                        <div id="meridian-map" className="map" />
                                                                    </Box>)
                                                                    :
                                                                    <Box align="center" alignContent="center" alignSelf="center">
                                                                        <Heading level='2' >No location available for this asset</Heading>
                                                                    </Box>
                                                                }
                                                            </Box>

                                                        </Tab>
                                                        <Tab title="Last known location">
                                                            <Box /*gap="small"*/ pad="small">
                                                                <Box direction="row" height="90px" /*pad={{ "horizontal": "small" }}*/>
                                                                    <Box width="23%" pad={{ right: "medium" }}>
                                                                        <FormField label="Building" >
                                                                            <TextInput value={assetdata.map && assetdata.map.group ? assetdata.map.group.name : "No Building"} readOnly disabled />
                                                                        </FormField>
                                                                    </Box>
                                                                    <Box width="23%" pad={{ right: "medium" }}>
                                                                        <FormField label="Floor">
                                                                            <TextInput value={assetdata.map && assetdata.map.name ? assetdata.map.name : "No Floor"} readOnly disabled />
                                                                        </FormField>
                                                                    </Box>
                                                                    <Box width="23%" pad={{ right: "medium" }}>
                                                                        <FormField label="Zone">
                                                                            <TextInput value={assetdata.zoneId && assetdata.zone ? assetdata.zone.name : "No Zone"} readOnly disabled />
                                                                        </FormField>
                                                                    </Box>
                                                                    <Box width="23%" >
                                                                        <FormField label="Last Heard">
                                                                            <TextInput value={assetdata.timestamp ? this.formatDate(assetdata.timestamp) : ""} readOnly disabled />
                                                                        </FormField>
                                                                    </Box>
                                                                    <Box width="8%" align="center" alignSelf="center">

                                                                    </Box>
                                                                </Box>
                                                                {assetdata.mapId ?
                                                                    (<Box border="all" pad="xsmall" background="#fafafa" style={{ borderRadius: "5px 5px 5px 5px"/*, boxShadow: "2px 2px 4px 2px rgba(100, 100, 100, 0.35)"*/ }}>
                                                                        <div id="meridian-map" className="map" />
                                                                    </Box>)
                                                                    :
                                                                    <Box align="center" alignContent="center" alignSelf="center">
                                                                        <Heading level='2' >No location available for this asset</Heading>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Tab>
                                                    </Tabs>
                                                </StyledBox>}

                                            {/*<StyledBox style1 direction="row" margin={{ vertical: "small" }} height="90px">
                                <FormField label="Labels" width="100%" >
                                        {this.state.meridianData.tags.length > 0 ?
                                            <Box direction="row" gap="small" pad={{ "left": "small", "right": "small", "top": "medium", "bottom": "xsmall" }} align="end" height="100%">
                                                {this.state.meridianData.tags.map(t =>
                                                    <Box background="#CCCCCC" pad={{ "left": "small", "right": "small" }} justify="center" align="center" round="5px">
                                                        {t.name}
                                                    </Box>
                                                )}
                                            </Box>
                                            :
                                            <TextInput value="No label available" />
                                        }
                                    </FormField>

                            </StyledBox>*/}

                                            {/*<StyledBox style1 height="90px" pad={{ "horizontal": "small" }}>
                                        <FormField label="Description" >
                                            <TextInput value={this.state.meridianData.map.group ? this.state.meridianData.map.group_name : "No Building"} readOnly disabled />
                                        </FormField>
                                    </StyledBox>*/}
                                        </Box>

                                    </Tab>
                                    {this.context.feature.find(f => f.name == "siteAssetTrackingHistory").isEnabled && <Tab title="History">
                                        <AssetHistoryListContainer
                                            historydata={historydata}
                                            locationId={this.props.sitedata.appID}
                                            token={this.props.sitedata.appToken}
                                            image={assetdata.imageUrl}
                                            environment={this.props.sitedata.eUorNon ? "eu" : "production"}
                                        />
                                    </Tab>}
                                    {this.context.feature.find(f => f.name == "siteWellness").isEnabled && <Tab title="Status">
                                        <AssetStatusHistoryListTable historydata={historydata.history} />
                                    </Tab>}
                                </Tabs>
                            </Box>
                            <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                            </Box>

                        </Grid >
                    );
                }}
            </Context.Consumer>
        );
    }
}
const mapStateToProps = state => ({
    assetdata: state.selectedAssetReducer.assetdata,
    sitedata: state.selectedSiteReducer.sitedata,
    historydata: state.selectedAssetHistoryReducer.historydata,
    departmentsdata: state.departmentsReducer.departmentsdata,
    collectionsdata: state.collectionsReducer.collectionsdata,
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...AssetAction, ...SiteAction, ...AssetHistoryAction, ...DepartmentAction, ...CollectionAction, }, dispatch)

});

export default connect(mapStateToProps, mapDispatchToProps)(AssetEditContainer);