import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AppAction from '../action/AppAction';
import * as CategoryAction from '../action/CategoryAction';
import * as LocationAction from '../action/LocationAction';
import * as MasterAppAction from '../action/MasterAppAction';
import * as SiteAction from '../action/SiteAction';
import validator from 'validator';
import constants from '../constants';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Apps, OrderedList, Android, Apple, Add } from 'grommet-icons';
import { Phone } from '../grommet-extras/PhoneIcon';
import { Yammer } from '../grommet-extras/YammerIcon';
// eslint-disable-next-line
import { Grid, Box, Heading, List, Paragraph, Split, Button, Form, FormField, TextInput, Select, CheckBox, Text, Calendar, Image, TextArea } from 'grommet';
import TextInputWithState from './common/TextInputWithState';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import CustomOption from './common/CustomOption';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ImageApi from '../api/ImageApi';
import { imageCheck } from './common/ImageCheck';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';

var start = undefined;
export class AppEditContainer extends React.Component {


    constructor() {
        super();
        this.masterdata = [];
        this.parentfull = [];
        this.categoryfull = [];
        this.newname = "";
        this.newparent = "";
        this.newparentId = "";
        this.newlocation = "";

        this.state = {
            options: [],
            parents: [],
            value: "",
            parent: "",
            isNewCategory: false,
            isSaved: false,
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            editorState: EditorState.createEmpty(),
            editorloaded: false,
            appSelected: false,
            masterdata: [],
            appname: "",
            masterappsdataLoading: true,
            categoriesdataLoading: true,
            locationsdataLoading: true,
            appdataLoading: true,
            appsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
            isMasterDataLoaded: false
        };
    }

    //Validating fields error handling
    app_err = "";
    newname_err = "";
    parent_err = "";
    categoryid_err = "";



    clearErrorMessages() {
        this.newname_err = "";
        this.parent_err = "";
        this.categoryid_err = "";
        this.app_err = "";
    }

    uploadImageCallBack(file) {
        return imageCheck(file)
            .then(cf => ImageApi.uploadImage(cf))
            .catch(err => {
                if (typeof err === 'string') {
                    this.uploadErrorText = err;
                } else {
                    this.uploadErrorText = err.message;
                }
                this.setState({ isUploadError: true });
            });
    }

    componentDidUpdate(prevProps, prevState) {
        //Load categories
        if (this.props.categoriesdata && this.props.sitesdata && this.props.categoriesdata.length > 0 && this.categoryfull.length == 0) {
            //console.log(this.props.categoriesdata);
            this.categoryfull = this.props.categoriesdata.slice();
            //this.categoryfull = this.categoryfull.filter(item => item.level == 2);
            this.categoryfull.push({
                id: "0",
                name: "",
                parent: "",
                location: "",
                lpn: "",
                synced: false
            });
            var sp = this.categoryfull.find(d => d.name == "Spaces" && d.level == 0);
            var spi = this.categoryfull.indexOf(sp);
            this.categoryfull.splice(spi, 1);
            var ri = this.categoryfull.find(d => d.name == "Report Issue" && d.level == 0);
            var i = this.categoryfull.indexOf(ri);
            this.categoryfull.splice(i, 1);
            var info = this.categoryfull.find(d => d.name == "Info" && d.level == 0);
            var index = this.categoryfull.indexOf(info);
            this.categoryfull.splice(index, 1);
            var ri2 = this.categoryfull.find(d => d.name == "Report Issue" && d.level == 2);
            var i2 = this.categoryfull.indexOf(ri2);
            this.categoryfull.splice(i2, 1);
            var info2 = this.categoryfull.find(d => d.name == "Info" && d.level == 2);
            var index2 = this.categoryfull.indexOf(info2);
            this.categoryfull.splice(index2, 1);
            this.categoryfull.forEach(item => item.lpn = constants.APP_LPN(item.location, item.parent, item.name));
            this.categoryfull.sort((p1, p2) => { if (p1.lpn.toLowerCase() < p2.lpn.toLowerCase()) return -1; return 1; });
            if (!this.props.sitesdata.meridianEnabled) {
                this.categoryfull = this.categoryfull.filter(c => c.synced == false);
            }
            //this.categoryfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            this.setState({ options: this.categoryfull });
        }
        //Load parent-location dictionary
        if (this.props.locationsdata[0] && this.props.locationsdata[0].count != 0 && this.props.locationsdata.length > 0 && this.parentfull.length == 0) {
            this.parentfull = this.props.locationsdata.filter((item) => item.location != "").slice();
            this.parentfull.push({
                parent: "",
                location: ""
            });
            this.parentfull.forEach(function (item) {
                if (item.parent == "" && item.location == "") {
                    item.parentloc = "";
                }
                else if (item.parent != "" && item.location == "") {
                    item.parentloc = item.parent;
                }
                else if (item.parent == item.location) {
                    item.parentloc = item.location;
                }
                else {
                    item.parentloc = item.location + " / " + item.parent;
                }
            })
            this.parentfull.sort((p1, p2) => { if (p1.parentloc.toLowerCase() < p2.parentloc.toLowerCase()) return -1; return 1; });
            this.setState({ parents: this.parentfull });
            //console.log("DidUpdate:", this.parentfull);
        }
        // draft
        if (this.props.appdata && !this.state.editorloaded) {
            if (!this.props.appdata.detail) { this.props.appdata.detail = '' };
            // html to draft
            let html = this.props.appdata.detail;
            html = constants.html_sanitize(html);
            this.props.appdata.detail = html;
            const contentBlock = htmlToDraft(this.props.appdata.detail.replace(/<br>/g, constants.CUSTOM_TOOLBAR));
            console.log('Draft: ', contentBlock);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const neweditorState = EditorState.createWithContent(contentState);
                //console.log('Draft: ', contentBlock, contentState, neweditorState);
                this.setState({
                    editorState: neweditorState,
                    editorloaded: true,
                })
            }
        }
        if (this.props.masterappsdata && this.props.masterappsdata.length != 0 && this.masterdata.length == 0 && !this.state.isMasterDataLoaded) {
            this.masterdata = this.props.masterappsdata.filter(item => item.status == true);
            console.log('asd', this.masterdata);
            this.setState({ masterdata: this.masterdata, isMasterDataLoaded: true });
        }
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.appId;
        this.props.action.getMasterAppsAction()
            .then(response => this.setState({ masterappsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Categories List Error', error);
            });
        this.props.action.getCategoriesAction()
            .then(response => this.setState({ categoriesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Categories List Error', error);
            });
        this.props.action.getLocationsAction()
            .then(response => this.setState({ locationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Categories List Error', error);
            });
        this.props.action.getAppAction(id)
            .then(response => this.setState({ appdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-App Error', error);
            });
        this.props.action.getAppsAction()
            .then(response => this.setState({ appsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Categories List Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    editorToolbar = Object.assign({}, constants.HTML_EDITOR_TOOLBAR, { image: { uploadCallback: this.uploadImageCallBack.bind(this), alt: { present: true, mandatory: false }, previewImage: true } });

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.appdata && !_.isEqual(start, this.props.appdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }
    filterMasterApps() {
        let usedApps = []
        if (this.props.appsdata && this.props.appsdata.length != 0 && this.props.appsdata[0].masterApp) {
            let filteredApps = this.props.appsdata.filter(item => item.id != this.props.appdata.id)
            usedApps = filteredApps.map(item => item.masterApp.id)
        }

        let masterApps = this.state.masterdata.filter(item => !usedApps.includes(item.id));
        return (
            masterApps.map(item => item.name)
        )
    }
    updateSelectedApp(event) {
        if (event.option && event.option != "No match found") {
            var master = this.props.masterappsdata.find(i => i.name == event.option);
            this.props.appdata.masterappid = master.id;
        }
        this.setState({ appSelected: true, appname: event.option });
        this.app_err = "";
    }

    updateCategoryName(event) {
        this.props.appdata.categoryid = this.state.options[event.selected].id;
        this.setState({
            value: event.option,
            selected: event.selected,
            options: this.categoryfull
        });
        if (event.option == "No match found") {
            event.option = "";
        }
        //this.props.placemarkdata.categoryid = (this.categoryfull.find((option) => option.name == event.option)).id;
        if (this.props.appdata.category) {
            this.props.appdata.category.id = this.props.appdata.categoryid;
        };
        this.categoryid_err = "";
        //if (event.option.length == 0) {
        //    this.categoryid_err = "Category must be selected";
        //}
    }
    updateNewCategoryName(event) {
        this.newname = event.target.value;
        if (this.newname.trim().length == 0) {
            this.newname_err = "Category Name is required";
        } else {
            this.newname_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    updateCategoryParent(event) {
        if (event.option == "No match found") {
            event.option = "";
        }
        this.newparent = this.parentfull[event.selected].parent;
        this.newparentId = this.parentfull[event.selected].id;
        this.newlocation = this.parentfull[event.selected].location;
        this.setState({
            parent: event.option,
            parents: this.parentfull
        });
        //this.newparent = (this.parentfull.find((option) => option.parentloc == event.option)).parent;
        //this.newparentId = (this.parentfull.find((option) => option.parentloc == event.option)).id;
        //this.newlocation = (this.parentfull.find((option) => option.parentloc == event.option)).location;
        this.parent_err = "";
        //if (event.option.trim().length == 0) {
        //    this.parent_err = "Category must be selected";
        //}
    }


    handleCancel() {
        this.saveNewCategory(false);
        if (!this.state.cancel && !_.isEqual(start, this.props.appdata)) {
            this.setState({ cancel: true });
        }
        else {
            const appId = this.props.appdata.id;
            this.props.action.getAppAction(appId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getSiteLevelRoute() + '/pages/1');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-AppEdit Error', error);
                    this.props.history.push(constants.getSiteLevelRoute() + '/pages/1');
                });
        }

    }

    handleSave() {
        const appdefault = this.props.appdata;
        //console.log('*DEBUG*-Started saving:', appdefault);
        //Validating fields
        let errorsno = 0;
        this.clearErrorMessages();
        this.setState({ validating: false });

        //newCategory
        if (this.state.isNewCategory) {
            if (this.newname.trim().length == 0) {
                errorsno++;
                this.newname_err = "Category Name is required";
            }
            if (this.props.categoriesdata.find(o => o.name.trim().toLowerCase() == this.newname.trim().toLowerCase())) {
                errorsno++;
                this.newname_err = "Category Name already exists";
            }
            //if (this.newparent.trim().length == 0) {
            //    errorsno++;
            //    this.parent_err = "Category must be selected";
            //}
        }
        else {
            //if (appdefault.categoryid == 0 || !this.categoryfull.find((opt) => opt.id == appdefault.categoryid)) {
            //    errorsno++;
            //    this.categoryid_err = "Category must be selected";
            //}
        }
        if (appdefault.masterappid == 0 || appdefault.masterappid == null) {
            this.app_err = "App must be selected";
            errorsno++;
        }
        if (errorsno == 0) {
            //Ok, saving data
            this.setState({ disabledButton: true });
            this.saveNewCategory(true);
            let locId = '';
            if (sessionStorage.getItem('locationId')) {
                locId = sessionStorage.getItem('locationId');
            }
            appdefault.locationId = locId;
            console.log('Saving:', appdefault);
            this.props.action.saveAppAction(appdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                    this.categoryfull = [];
                    //this.props.action.getCategoriesAction().then(() => { /*this.props.history.push('/placemarks')*/ });
                }).catch(error => {
                    console.log('*DEBUG*-Save AppEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        } else {
            this.setState({ validating: true });
        }
    }
    saveNewCategory(isFromHandleSave) {
        const appdefault = this.props.appdata;
        if (this.state.isNewCategory) {
            // new category
            appdefault.category = {};
            appdefault.categoryid = "0";
            appdefault.category.id = null;
            appdefault.category.name = this.newname;
            appdefault.category.parent = this.newparent;
            appdefault.category.parentId = this.newparentId;
            appdefault.category.location = this.newlocation;
            appdefault.category.synced = false;
            appdefault.category.deleted = false;
            appdefault.category.locationId = sessionStorage.getItem("locationId");
            appdefault.category.organizationId = sessionStorage.getItem("organisationId");
        } else {
            // selected category
            if (isFromHandleSave) {
                appdefault.category = null;
            }

        }
    }

    // Draft
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
        let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        //console.log('Before html:', html);
        html = constants.html_sanitize(html);
        this.props.appdata.detail = html.replace(/↲/g, "<br>");
        //console.log('Update html:', this.props.appdata.detail);
    };
    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        }
        else if (!this.state.isSaveSuccessful) {
        }
        else {
            this.props.history.push(constants.getSiteLevelRoute() + '/pages/1');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }

    render() {
        const { parent, parents, options, isNewCategory, masterappsdataLoading, categoriesdataLoading, locationsdataLoading, appdataLoading, appsdataLoading } = this.state;
        const appdefault = this.props.appdata;
        const masterappsdata = this.props.masterappsdata;
        const categoriesdefault = this.props.categoriesdata;
        const sitesdata = this.props.sitesdata;
        const { editorState } = this.state;

        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONTENT);

        if (masterappsdataLoading || categoriesdataLoading || locationsdataLoading || appdataLoading || appsdataLoading || !sitesdata) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        console.log("APP_DT_CHK", appdefault, masterappsdata);
        const heading = appdefault && (appdefault.id != "0") ? 'Edit' : 'Create';
        const vcategory = this.categoryfull.find((opt) => opt.id == appdefault.categoryid);
        const vcategoryname = vcategory ? vcategory.name : "";
        const vcategoryparent = vcategory ? vcategory.parent : "";
        const vcategorylocation = vcategory ? vcategory.location : "";
        const vcategoryparentpluslocation = constants.APP_LPN(vcategorylocation, vcategoryparent, vcategoryname);
        //console.log('EDIT:', pagedefault.categoryid, JSON.stringify(vcategory));

        var selectedMasterApp = appdefault.masterappid ?
            masterappsdata.find(i => i.id == appdefault.masterappid) :
            {
                appIconURL: "",
                appStoreUri: "",
                appStoreUrl: "",
                name: "",
                playStoreUri: "",
                playStoreUrl: "",
                status: false,
            };

        return (

            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2'>App - {heading} </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" label={constants.visible(canedit1) == "visible" ? "Cancel" : "Back"} onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        {constants.visible(canedit1) == "visible" &&
                            <StyledButton typeSave label="Save" style={{ visibility: constants.visible(canedit1) }} onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />}
                    </Box>
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <form>
                        <Box gap="small">
                            {selectedMasterApp.status ?
                                <Box gap="small">
                                    <Box>
                                        <Text>All fields marked with an * are mandatory.</Text>
                                    </Box>

                                    <StyledBox style1 width="600px" pad={{ "horizontal": "small" }}>
                                        <FormField label="Available Apps *" error={this.app_err}>
                                            <Select
                                                placeholder="Select an App"
                                                value={selectedMasterApp.name}
                                                onChange={(evt) => this.updateSelectedApp(evt)}
                                                onSearch={(searchText) => {
                                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                    const filtered = this.masterdata.filter(i => i.name.match(regexp));
                                                    if (filtered.length > 0) {
                                                        this.setState({ masterdata: filtered });
                                                    }
                                                    else {
                                                        this.setState({ masterdata: [{ id: "0", name: "No match found" }] });
                                                    }
                                                }}
                                                onClose={() => this.setState({ masterdata: this.masterdata })}
                                                options={this.filterMasterApps()}
                                                disabled={!canedit1}
                                            />
                                        </FormField>
                                    </StyledBox>

                                    <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                        {!isNewCategory ? (
                                            <Box direction="row" gap="small">
                                                <Box fill>
                                                    <FormField label="Category Name" error={this.categoryid_err}>
                                                        <Select width="large"
                                                            placeholder="Select a Category"
                                                            onClose={() => this.setState({ options: this.categoryfull })}
                                                            onSearch={(searchText) => {
                                                                const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                const filtered = this.categoryfull.filter(o => o.lpn.match(regexp));
                                                                if (filtered.length > 0) {
                                                                    this.setState({ options: filtered });
                                                                }
                                                                else {
                                                                    this.setState({ options: [{ id: "0", name: "No match found", parent: "", location: "", lpn: "No match found" }] });
                                                                }
                                                            }}
                                                            value={vcategoryparentpluslocation}
                                                            onChange={event => this.updateCategoryName(event)}
                                                            options={options.map((option) => option.lpn)}
                                                            replace={false}
                                                            disabled={!canedit1}
                                                        />
                                                    </FormField>
                                                </Box>
                                                <Box justify="start" pad={{ "top": "45px" }} height="95px">
                                                    <Button plain icon={<Add />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} disabled={!canedit1} />
                                                </Box>
                                            </Box>
                                        ) : (
                                                //New Category
                                                <Box>
                                                    <Box fill direction="row" border="bottom" gap="small">
                                                        <Box fill>
                                                            <FormField label="Category Name *" error={this.newname_err}>
                                                                <TextInput placeholder="New Category Name" onChange={evt => this.updateNewCategoryName(evt)} />
                                                            </FormField>
                                                        </Box>
                                                        <Box justify="start" pad={{ "top": "45px" }} height="95px">
                                                            <Button plain icon={<OrderedList />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} />
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <FormField label="App Location" error={this.parent_err}>
                                                            <Select
                                                                placeholder="App Location"
                                                                onClose={() => this.setState({ parents: this.parentfull })}
                                                                onSearch={(searchText) => {
                                                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                    const filtered = this.parentfull.filter(o => o.parentloc.match(regexp))
                                                                    if (filtered.length > 0) {
                                                                        this.setState({ parents: filtered });
                                                                    }
                                                                    else {
                                                                        this.setState({ parents: [{ parent: "No match found", location: "", parentloc: "No match found" }] });
                                                                    }
                                                                }}
                                                                value={parent}
                                                                onChange={event => this.updateCategoryParent(event)}
                                                                options={parents.map((option) => option.parentloc)}
                                                                replace={false}
                                                            />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                            )}
                                    </StyledBox>
                                </Box>
                                :
                                <Box>
                                    <Text /*color="red"*/>{selectedMasterApp.name + " is not currently active. Please contact support to resolve."}</Text>
                                </Box>}
                        </Box>
                        {appdefault.masterappid ? (
                            <Box margin={{ "top": "small" }}>
                                <StyledBox style1 pad={{ "horizontal": "small" }} width="300px">
                                    <FormField label="Name">
                                        <TextInput value={selectedMasterApp.name} readOnly disabled />
                                    </FormField>
                                </StyledBox>

                                <Box margin={{ "bottom": "small", "top": "medium" }}>
                                    <Text size="large">App Parameters</Text>
                                </Box>

                                <Box>
                                    <Box direction="row" margin={{ "bottom": "xsmall" }}>
                                        <Box margin={{ "right": "xsmall" }}>
                                            <Android size="35px" color="plain" />
                                        </Box>
                                        <Text alignSelf="center" >Android</Text>
                                    </Box>
                                    <Box background="transparent" direction="row" justify="between" gap="large">
                                        <StyledBox style1 width="25%" pad={{ "horizontal": "small" }}>
                                            <FormField label="URI">
                                                <TextInput value={selectedMasterApp.playStoreUri} readOnly disabled />
                                            </FormField>
                                        </StyledBox>
                                        <StyledBox style1 width="70%" pad={{ "horizontal": "small" }} >
                                            <FormField label="Play Store URL">
                                                <TextInput value={selectedMasterApp.playStoreUrl} readOnly disabled />
                                            </FormField>
                                        </StyledBox>
                                    </Box>
                                </Box>

                                <Box>
                                    <Box direction="row" margin={{ "bottom": "xsmall", "top": "small" }}>
                                        <Box margin={{ "right": "xsmall" }}>
                                            <Apple size="35px" color="plain" />
                                        </Box>
                                        <Text alignSelf="center" >Apple</Text>
                                    </Box>
                                    <Box background="transparent" direction="row" justify="between" width="100%">
                                        <StyledBox style1 width="25%" pad={{ "horizontal": "small" }}>
                                            <FormField label="URI">
                                                <TextInput value={selectedMasterApp.appStoreUri} readOnly disabled />
                                            </FormField>
                                        </StyledBox>
                                        <StyledBox style1 width="70%" pad={{ "horizontal": "small" }}>
                                            <FormField label="App Store URL">
                                                <TextInput value={selectedMasterApp.appStoreUrl} readOnly disabled />
                                            </FormField>
                                        </StyledBox>
                                    </Box>
                                </Box>

                                <Box margin={{ "bottom": "small", "top": "medium" }}>
                                    <Text size="large">App Icon</Text>
                                </Box>

                                <Box align="start" margin={{ "bottom": "medium" }}>
                                    {selectedMasterApp.appIconURL != "" ? (
                                        <StyledBox style1>
                                            <Image height="100px" src={selectedMasterApp.appIconURL} />
                                        </StyledBox>
                                    ) : (
                                            <StyledBox style1 pad="small">
                                                <Apps size="large" />
                                            </StyledBox>
                                        )}
                                </Box>
                            </Box>
                        ) : (
                                <Box />
                            )}

                    </form>
                </Box>
            </Grid >
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    const appId = ownProps.match.params.appId || ''; //from the path '/event/:eventId'
    if (state.selectedAppReducer.appdata && appId == state.selectedAppReducer.appdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedAppReducer.appdata));
        return {
            masterappsdata: state.masterAppsReducer.masterappsdata,
            categoriesdata: state.categoriesReducer.categoriesdata,
            locationsdata: state.locationsReducer.locationsdata,
            appdata: state.selectedAppReducer.appdata,
            appsdata: state.appsReducer.appsdata,
            sitesdata: state.selectedSiteReducer.sitedata
        };
    } else {
        return {
            /*only create test*/
            //appdata: state.selectedAppReducer.appdata,
            /*only create test*/
            categoriesdata: state.categoriesReducer.categoriesdata,
            locationsdata: state.locationsReducer.locationsdata,
            sitesdata: state.selectedSiteReducer.sitedata
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...CategoryAction, ...AppAction, ...LocationAction, ...MasterAppAction, ...SiteAction }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppEditContainer));