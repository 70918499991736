import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image, Select, CheckBox } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, Connect, Tag, FormClose } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import RB from './common/RBAC';
import { CSVLink } from "react-csv";
import { Battery, BatteryAmber, BatteryEmpty, BatteryRed, BatteryUnknown } from '../grommet-extras/Battery';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import PrimaryFilter from "./common/PrimaryFilter";


const PaginatedDataTable = withPagination(DataTable);
class MetadataListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.DATA = [];
        this.Exp_Data = [];
        this.state = {
            search: "",
            isFilterOpen: false,
            selectedTag: "",
            selectedTracker: "",
            filterManufacturer: null,
            filterModel: null,
            filterModelType: null,
        };
    }
    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }
    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        let tid = elem.getElementsByTagName('th')[0].textContent;

                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }
    renderFilters() {
        return (
            <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="small">
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Manufacturer" value={this.state.filterManufacturer} options={[... new Set(this.filterBySiteTrackers().map(m => m.manufacturer ? m.manufacturer.name : " "))].sort((p1, p2) => { if (p1.toLowerCase() < p2.toLowerCase()) return -1; return 1; })} onChange={o => { this.setState({ filterManufacturer: o.option }); }} />
                    </StyledBox>
                    {this.state.filterManufacturer != null &&
                        <Button
                            icon={<FormClose />}
                            onClick={() => this.setState({ filterManufacturer: null })}
                        />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Model" value={this.state.filterModel} options={[... new Set(this.filterBySiteTrackers().map(m => m.model ? m.model.name : " "))].sort((p1, p2) => { if (p1.toLowerCase() < p2.toLowerCase()) return -1; return 1; })} onChange={o => { this.setState({ filterModel: o.option }); }} />
                    </StyledBox>
                    {this.state.filterModel != null &&
                        <Button
                            icon={<FormClose />}
                            onClick={() => this.setState({ filterModel: null })}
                        />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Model Type" value={this.state.filterModelType} options={[... new Set(this.filterBySiteTrackers().map(m => m.deviceType ? m.deviceType.name : " "))].sort((p1, p2) => { if (p1.toLowerCase() < p2.toLowerCase()) return -1; return 1; })} onChange={o => { this.setState({ filterModelType: o.option }); }} />
                    </StyledBox>
                    {this.state.filterModelType != null &&
                        <Button
                            icon={<FormClose />}
                            onClick={() => this.setState({ filterModelType: null })}
                        />}
                </Box>
            </Box>
        )
    }
    filterData(data) {
        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            data = data.filter(d =>
                d.manufacturerName.includes(search) ||
                d.modelName.includes(search) ||
                d.typeName.includes(search) ||
                d.dateSearch.includes(search)
            );
        }

        if (this.state.filterManufacturer != null) {
            data = data.filter(d => d.manufacturerName == this.state.filterManufacturer.trim().toLowerCase());
        }
        if (this.state.filterModel != null) {
            data = data.filter(d => d.modelName == this.state.filterModel.trim().toLowerCase());
        }
        if (this.state.filterModelType != null) {
            data = data.filter(d => d.typeName == this.state.filterModelType.trim().toLowerCase());
        }
        return data;
    }
    filterBySiteTrackers() {
        let filteredData = [];
        var foundSomething;
        this.props.trackersdata.forEach(e => {
            foundSomething = this.props.metadatas.find(f => (e.manufacturerId == f.manufacturerId) && (e.modelId == f.modelId) && (e.deviceTypeId == f.deviceTypeId));
            if (foundSomething) {
                filteredData.some(s => s.manufacturerId == foundSomething.manufacturerId) &&
                    filteredData.some(s => s.modelId == foundSomething.modelId) &&
                    filteredData.some(s => s.deviceTypeId == foundSomething.deviceTypeId) ?
                    null : filteredData.push(foundSomething);
            }
        });
        return filteredData;
    }
    render() {
        const canEdit = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        this.DATA = [];
        this.Exp_Data = [];
        if (this.props.metadatas[0] && this.props.metadatas[0].count != 0) {
            this.filterBySiteTrackers().forEach(ev => {
                this.DATA.push({
                    ...ev,
                    manufacturerName: ev.manufacturer ? ev.manufacturer.name.toLowerCase() : "",
                    modelName: ev.model ? ev.model.name.toLowerCase() : "",
                    typeName: ev.deviceType ? ev.deviceType.name.toLowerCase() : "",
                    dateSearch: constants.formatDate(ev.updatedAt)
                });
                this.Exp_Data.push({
                    "Manufacturer": ev.manufacturer ? ev.manufacturer.name : "",
                    "Model": ev.model ? ev.model.name : "",
                    "Type": ev.deviceType ? ev.deviceType.name : "",
                    "Last Modified": constants.formatDate(ev.updatedAt)
                });
            });
        }
        this.DATA = this.filterData(this.DATA);
        this.DATA.sort(constants.byPropertyCalled("manufacturerName"));
        this.Exp_Data.sort(constants.byPropertyCalled("Manufacturer"));

        return (
            <Box /*gap="medium" pad={{ "top": "small" }}*/>
                <StyledBox ref={this.dtwrapper} style={{ "min-width": "900px" }}>
                    {this.renderMetadataTable(this.props, canEdit, "")}
                </StyledBox>
            </Box>
        );
    }

    handleExport(evt) {
        console.log('Export to CSV', this.csvLink);
        this.csvLink.link.click();
    }
    renderMetadataTable(props, canEdit, sync) {
        const COLUMNS = [
            {
                property: 'id',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: metadata => <Text hidden>{metadata.id}</Text>
            },
            {
                property: 'manufacturerName',
                header: 'Manufacturer',
                render: metadata => <Text>{metadata.manufacturer ? metadata.manufacturer.name : ""}</Text>
            },
            {
                property: 'modelName',
                header: 'Model',
                render: metadata => <Text>{metadata.model ? metadata.model.name : ""}</Text>
            },
            {
                property: 'typeName',
                header: 'Model Type',
                render: metadata => <Text>{metadata.deviceType ? metadata.deviceType.name : ""}</Text>
            },
            {
                property: 'updatedAt',
                header: 'Last Modified',
                render: metadata => <Text>{constants.formatDate(metadata.updatedAt)}</Text>
            },
            {
                property: 'id',
                header: '',
                primary: true,
                header: '',
                plain: true,
                render: metadata => {
                    let deleteItem = {
                        deviceType: metadata.deviceType,
                        deviceTypeId: metadata.deviceTypeId,
                        id: metadata.id,
                        manufacturer: metadata.manufacturer,
                        manufacturerId: metadata.manufacturerId,
                        model: metadata.model,
                        modelId: metadata.modelId,
                        organizationId: metadata.organizationId,
                    };
                },
            },

        ];

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "assetId" || c.property === "lcAssetName" || c.property === "beaconId" || c.property === "lcBeaconName"
                }))}
                data={this.DATA}
                sortable
                noLoadingMsg={this.props.metadatas.length != 0}
                //sortoptions={[
                //    { col: 'assetId', label: 'Tracker ID' },
                //    { col: 'lcAssetName', label: 'Tracker Name' },
                //    { col: 'lcBeaconName', label: 'Tag Name' },
                //    { col: 'beaconId', label: 'Tag ID' },
                //    { col: 'mergedAt', label: 'Date Created' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                //renderExtraHeader={() => <Text>Last updated at: {sync}</Text>}
                //downloadable={true}
                //exportdata={this.Exp_Data}
                styled={true}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}
                PFonClick={() => this.setState({ isFilterOpen: true })}
                PFopen={this.state.isFilterOpen}
                PFonClose={() => this.setState({ isFilterOpen: false })}
                PFonResetFilter={() => this.setState({
                    filterManufacturer: null, filterModel: null, filterModelType: null
                })}
                PFisClearVisible={(
                    this.state.filterManufacturer == null &&
                    this.state.filterModel == null &&
                    this.state.filterModelType == null
                ) ? false : true}
                PFcsvdata={this.Exp_Data}
                PFFileters={this.renderFilters()}
                PFsearchable={true}
                PFfilterable={true}
                PFdownloadable={this.DATA.length > 0 ? 1 : 2}
            />
        );
    }

}

export default MetadataListTable;