import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as EventAction from '../action/EventAction';
import * as PlacemarkAction from '../action/PlacemarkAction';
import * as SiteAction from '../action/SiteAction';
import * as TimeZoneAction from '../action/TimeZoneAction';
import validator from 'validator';
import constants from '../constants';
//eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Video, Youtube, CircleInformation, Upload, Trash, PhoneVertical, Secure } from 'grommet-icons';
import { Phone } from '../grommet-extras/PhoneIcon';
import { Yammer } from '../grommet-extras/YammerIcon';
//eslint-disable-next-line
import { Grid, Box, Heading, FormField, TextInput, DateInput, Select, CheckBox, Text, Image, Grommet, Button, RadioButton } from 'grommet';
import TextInputWithState from './common/TextInputWithState';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import Notification from './common/Notification';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import ImageApi from '../api/ImageApi';
import { imageCheck } from './common/ImageCheck';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
Quill.register(Quill.import('attributors/style/align'), true);
import ReactTooltip from "react-tooltip";

var start = undefined;
var base64 = null;

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={customTheme}> 
    formField: {
        border: {
            color: "transparent",
        },
        pad: "none"
    }
});

export class EventEditContainer extends React.Component {

    constructor() {
        super();
        this.moreinfo_err = [];
        for (let i = 0; i <= constants.MOREINFO_LINES; i += 1) this.moreinfo_err.push({ display: "", url: "" });
        this.d = new Date();
        this.lnow = this.d.toISOString();
        this.now = this.convertDateFromUTC(this.lnow);
        // this.now = this.lnow;
        this.placemarkfull = [];
        this.uploadErrorText = "";
        this.state = {
            cbfeatured: false,
            cbemployee: false,
            validating: false,
            selimage: 0,
            value: "",
            isSaved: false,
            placemarks: [],
            utcdate: false,
            notification: "",
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            isUploadError: false,
            recurringEvent: false,
            recMonday: false,
            recTuesday: false,
            recWednesday: false,
            recThursday: false,
            recFriday: false,
            recSaturday: false,
            recSunday: false,
            displayStart: false,
            displayEnd: false,
            displayStartError: false,
            startTimeUpdate: false,
            endTimeUpdate: false,
            file: "",
            newimage: false,
            imagechanged: false,
            removeIconOpen: false,
            HTMLTextLenght: 0,
            eventdataLoading: true,
            placemarksdataLoading: true,
            sitedataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
            isTimeZoneNull: false,
            isFirstLoad: true,
        };
    }

    launchEnumOptions = [
        { label: 'External Browser', value: "External" },
        { label: 'Hosted In App', value: "InApp" },
        { label: 'In App Browser', value: "InAppBrowser" },
    ];

    editorURLs = [];
    //Validating fields error handling
    title_err = "";
    subtitle_err = "";
    organiser_err = "";
    detail_err = "";
    speakers_err = "";
    email_err = "";
    phone_err = "";
    phone_help = "";
    twitter_err = "";
    linkedin_err = "";
    yammer_err = "";
    facebook_err = "";
    url_err = "";
    instagram_err = "";
    video_err = "";
    youtube_err = "";
    startdate_err = "";
    starttime_err = "";
    enddate_err = "";
    endtime_err = "";
    placemarkid_err = "";
    displayend_err = "";
    displaystart_err = "";
    image_err = "";

    uploadImageCallBack(file) {
        return imageCheck(file)
            .then(cf => ImageApi.uploadImage(cf))
            .catch(err => {
                if (typeof err === "string") {
                    this.uploadErrorText = err;
                } else {
                    this.uploadErrorText = err.message;
                }
                this.setState({ isUploadError: true });
            });
    }

    onUploadClose() {
        this.setState({ isUploadError: false });
    }

    onTimeZoneClose() {
        this.setState({ isTimeZoneNull: false });
    }

    componentWillReceiveProps(props) {
        if (this.props.eventdata) {
            if (this.props.eventdata.recurringDays.charAt(0) === "1") {
                this.setState({ recMonday: true })
            }
            if (this.props.eventdata.recurringDays.charAt(1) === "1") {
                this.setState({ recTuesday: true })
            }
            if (this.props.eventdata.recurringDays.charAt(2) === "1") {
                this.setState({ recWednesday: true })
            }
            if (this.props.eventdata.recurringDays.charAt(3) === "1") {
                this.setState({ recThursday: true })
            }
            if (this.props.eventdata.recurringDays.charAt(4) === "1") {
                this.setState({ recFriday: true })
            }
            if (this.props.eventdata.recurringDays.charAt(5) === "1") {
                this.setState({ recSaturday: true })
            }
            if (this.props.eventdata.recurringDays.charAt(6) === "1") {
                this.setState({ recSunday: true })
            }
        }
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.eventId;
        this.props.action.getEventAction(this.props.match.params.eventId)
            .then(response => this.setState({ eventdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-EventEdit Error", error);
            });
        this.props.action.getPlacemarksAction()
            .then(response => this.setState({ placemarksdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-PageEdit/Placemark Error", error);
            });
        this.props.action.getSiteAction(sessionStorage.getItem("locationId"))
            .then(response => this.setState({ sitedataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Categories List Error', error);
            });
        this.props.action.getTimeZonesAction()
            .then(response => this.setState({ timezonesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === "POP" || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.eventdata && !_.isEqual(start, this.props.eventdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.sitedata && this.props.sitedata.meridianEnabled && this.props.placemarksdata[0] && this.props.placemarksdata[0].count != 0 && this.props.placemarksdata.length > 0 && this.placemarkfull.length == 0) {
            //console.log(this.props.placemarksdata);
            this.placemarkfull = this.props.placemarksdata.slice();
            this.placemarkfull.push({
                id: null,
                name: ""
            });
            this.placemarkfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            this.setState({ placemarks: this.placemarkfull });
        }
        if (this.props.eventdata && !this.state.utcdate) {
            // convert date from UTC
            this.setState({ utcdate: true });
        }
        if (this.props.eventdata && this.props.sitedata && this.state.isFirstLoad) {
            if (this.props.sitedata && !this.props.sitedata.timeZone) {
                this.setState({ isTimeZoneNull: true, isFirstLoad: false });
            }
        }
    }

    clearErrorMessages() {
        this.title_err = "";
        this.subtitle_err = "";
        this.organiser_err = "";
        this.detail_err = "";
        this.speakers_err = "";
        this.email_err = "";
        this.phone_err = "";
        this.phone_help = "";
        this.twitter_err = "";
        this.linkedin_err = "";
        this.yammer_err = "";
        this.facebook_err = "";
        this.url_err = "";
        this.instagram_err = "";
        this.video_err = "";
        this.youtube_err = "";
        this.startdate_err = "";
        this.starttime_err = "";
        this.enddate_err = "";
        this.endtime_err = "";
        this.placemarkid_err = "";
        this.displaystart_err = "";
        this.displayend_err = "";
        this.image_err = "";

        this.setState({ displayStartError: false });
        for (let i = 0; i <= this.moreinfo_err.length - 1; i += 1) {
            this.moreinfo_err[i].display = "";
            this.moreinfo_err[i].url = "";
        }
    }

    updateType(v, type) {
        this.clearErrorMessages();
        if (v.target.checked) {
            this.props.eventdata.type = type;

        }
        else {
            this.props.eventdata.type = "None";
        }
        this.setState({ updated: true });
    }

    convertDateToUTC(date) {
        const cdate = new Date(date);
        const ucdate = new Date(cdate.toUTCString());
        const milli = new Date(ucdate.getTime() + (ucdate.getTimezoneOffset() * 60000));
        const udate = milli.toISOString();
        //console.log("To UTC F:", date, "T:", udate);
        return udate;
    };

    convertDateFromUTC(date) {
        const cdate = new Date(date);
        const ucdate = new Date(cdate.toUTCString());
        const milli = new Date(ucdate.getTime() - (ucdate.getTimezoneOffset() * 60000));
        const udate = milli.toISOString();
        //console.log("From UTC F:", date, "T:", udate);
        return udate;
    };

    localeConversion(date) {
        const locDate = this.convertDateFromUTC(date);
        const originalDate = new Date(locDate.replace('Z', '')).toLocaleString('en-GB', { timeZone: this.props.sitedata.ianaTimeZone });
        let modifiedDate = originalDate.substring(0, 10);
        modifiedDate = modifiedDate.split('/')[2] + "-" + modifiedDate.split('/')[1] + "-" + modifiedDate.split('/')[0];
        const modifiedTime = originalDate.substring(12);
        return modifiedDate + "T" + modifiedTime + ".000";
    }

    handleSave() {
        const eventdefault = this.props.eventdata;
        const sitedata = this.props.sitedata;
        //console.log("*DEBUG*-Started saving:", eventdefault);
        //Validating fields
        let errorsno = 0;
        this.clearErrorMessages();
        this.setState({ validating: false, notification: "" });
        if (eventdefault.title.trim().length == 0) {
            errorsno++;
            this.title_err = "Title is required";
        }
        if (eventdefault.title.length > 50) {
            errorsno++;
            this.title_err = "Title can't be longer than 50 characters";
        }
        if (eventdefault.subtitle) {
            if (eventdefault.subtitle.length > 50) {
                errorsno++;
                this.subtitle_err = "Subtitle can't be longer than 50 characters";
            }
        }
        if (eventdefault.organiser) {
            if (eventdefault.organiser.length > 30) {
                errorsno++;
                this.organiser_err = "Organiser can't be longer than 30 characters";
            }
        }
        if (eventdefault.detail.trim().length == 0) {
            errorsno++;
            this.detail_err = "Detail is required";
        }
        if (eventdefault.detail.includes("http://")) {
            errorsno++;
            this.detail_err = constants.DETAIL_HTTP_ERROR;
        }
        if (this.state.HTMLTextLenght > 5000) {
            errorsno++;
            this.detail_err = "Detail can't be longer than 5000 characters";
        }
        for (var i = 0; i < this.editorURLs.length; i++) {
            if (!validator.isURL(this.editorURLs[i], constants.URL_HTTPS_ONLY)) {
                errorsno++;
                this.detail_err = constants.DETAIL_HTTP_ERROR;
            }
        }
        // if (eventdefault.speaker) {
        // if (eventdefault.speaker.length > 25) {
        //     errorsno++;
        //     this.speakers_err = "Speakers can't be longer than 25 characters";
        // }
        // }
        if (eventdefault.email) {
            if (eventdefault.email.trim().length != 0 && !(validator.isEmail(eventdefault.email))) {
                errorsno++;
                this.email_err = "E-mail is not correct";
            }
        }
        if (!eventdefault.phone) {
            eventdefault.phone = "";
        }
        if (eventdefault.phone.trim().length != 0 && !(constants.REGEX.PHONE.test(eventdefault.phone))) {
            errorsno++;
            this.phone_err = constants.PHONE_ERROR;
            this.phone_help = constants.PHONE_HELP;
        }
        if (eventdefault.twitter) {
            if (eventdefault.twitter.trim().length != 0 && !(validator.isURL(eventdefault.twitter, constants.URL_OPTIONS))) {
                errorsno++;
                this.twitter_err = constants.URL_ERROR;
            }
        }
        if (eventdefault.linkedin) {
            if (eventdefault.linkedin.trim().length != 0 && !(validator.isURL(eventdefault.linkedin, constants.URL_OPTIONS))) {
                errorsno++;
                this.linkedin_err = constants.URL_ERROR;
            }
        }
        if (eventdefault.yammer) {
            if (eventdefault.yammer.trim().length != 0 && !(validator.isURL(eventdefault.yammer, constants.URL_OPTIONS))) {
                errorsno++;
                this.yammer_err = constants.URL_ERROR;
            }
        }
        if (eventdefault.facebook) {
            if (eventdefault.facebook.trim().length != 0 && !(validator.isURL(eventdefault.facebook, constants.URL_OPTIONS))) {
                errorsno++;
                this.facebook_err = constants.URL_ERROR;
            }
        }
        if (eventdefault.url) {
            if (eventdefault.url.trim().length != 0 && !(validator.isURL(eventdefault.url, constants.URL_OPTIONS))) {
                errorsno++;
                this.url_err = constants.URL_ERROR;
            }
        }
        if (eventdefault.instagram) {
            if (eventdefault.instagram.trim().length != 0 && !(validator.isURL(eventdefault.instagram, constants.URL_OPTIONS))) {
                errorsno++;
                this.instagram_err = constants.URL_ERROR;
            }
        }
        if (eventdefault.video) {
            if (eventdefault.video.trim().length != 0 && !(validator.isURL(eventdefault.video, constants.URL_OPTIONS))) {
                errorsno++;
                this.video_err = constants.URL_ERROR;
            }
        }
        if (eventdefault.youtube) {
            if (eventdefault.youtube.trim().length != 0 && !(validator.isURL(eventdefault.youtube, constants.URL_OPTIONS))) {
                errorsno++;
                this.youtube_err = constants.URL_ERROR;
            }
        }
        if (eventdefault.startdate.substr(0, 10) === "aaaaaaaaaa") {
            errorsno++;
            this.startdate_err = "Start Date is required";
        }
        /*no past date check
        if (eventdefault.id == "0") { //create new event
        if (eventdefault.startdate.substr(0, 10) < this.now.substr(0, 10)) {
           errorsno++;
           this.startdate_err = "Can't select date from past";
        }
        }*/
        //if (eventdefault.startdate.substr(11, 5) === "aaaaa") {
        //    errorsno++;
        //    this.starttime_err = "Start Time is required";
        //}
        if (eventdefault.enddate.substr(0, 10) === "aaaaaaaaaa") {
            errorsno++;
            this.enddate_err = "End Date is required";
        }
        /*no past date check
        if (eventdefault.enddate.substr(0, 10) < this.now.substr(0, 10)) {
            errorsno++;
            this.enddate_err = "Can't select date from past";
        }*/
        //if (eventdefault.enddate.substr(11, 5) === "aaaaa") {
        //    errorsno++;
        //    this.endtime_err = "End Time is required";
        //}
        if (eventdefault.startdate >= eventdefault.enddate) {
            if (eventdefault.startdate.substr(0, 10) == eventdefault.enddate.substr(0, 10)) {
                errorsno++;
                this.endtime_err = "End Time must be greater than Start Time in case of same Date";
            }
            else {
                /*if (eventdefault.enddate.substr(0, 10) < this.now.substr(0, 10)) {
                    errorsno++;
                    this.enddate_err = "Can't select date from past & End date must be greater than Start date";
                }
                else {*/
                errorsno++;
                this.enddate_err = "End date must be greater than Start date";
                //}
            }
        }
        // more info validating
        if (eventdefault.moreinfo) {
            eventdefault.moreinfo.map((info, idx) => {
                // soft-deleted lines not validating
                if (!info.deleted) {
                    if (info.display.trim().length == 0 && info.url.trim().length == 0) {
                        //OK, Save
                    }
                    else {
                        if (info.display.trim().length == 0) {
                            errorsno++;
                            this.moreinfo_err[idx].display = "Display Text is required";
                        }
                        if (info.display.length > 30) {
                            errorsno++;
                            this.moreinfo_err[idx].display = "Dispay text can't be longer than 30 characters";
                        }

                        if (info.url.substr(info.url.length - 4).toLowerCase() != ".pdf" && !constants.REGEX.URL.test(info.url)) {
                            errorsno++;
                            this.moreinfo_err[idx].url = constants.URL_ERROR;
                        }
                        if (info.url.substr(info.url.length - 4).toLowerCase() == ".pdf" && !constants.REGEX.URL_HTTPS.test(info.url)) {
                            errorsno++;
                            this.moreinfo_err[idx].url = constants.URL_ERROR_HTTPS_ONLY;
                        }
                        if (info.url.length > 511) {
                            errorsno++;
                            this.moreinfo_err[idx].url = "URL can't be longer than 511 characters";
                        }
                    }
                }
            });
            eventdefault.moreinfo = eventdefault.moreinfo.filter(info => info.display.trim().length != 0 || info.url.trim().length != 0);
        }
        if (!eventdefault.displayStart && eventdefault.displayEnd) {
            errorsno++;
            this.displaystart_err = " ";
            this.setState({ displayStartError: true });
        }
        if (!sitedata.timeZone) {
            errorsno++;
            this.setState({ isTimeZoneNull: true });
        }
        if (errorsno == 0) {
            //Ok, saving data
            if (this.state.newimage) {
                this.props.eventdata.imageContent = base64;
                base64 = null;
            }

            this.setState({ disabledButton: true });
            let locId = "";
            if (sessionStorage.getItem("locationId")) {
                locId = sessionStorage.getItem("locationId");
            }
            eventdefault.locationId = locId;
            // eventdefault.startdate = this.convertDateToUTC(eventdefault.startdate);
            // eventdefault.enddate = this.convertDateToUTC(eventdefault.enddate);
            console.log("Saving:", eventdefault);
            this.props.action.saveEventAction(eventdefault)
                .then((response) => {
                    this.setState({ isSaveSuccessful: response.ok, isSaved: true });
                    //this.props.history.push("/eventstable");
                }).catch(error => {
                    console.log("*DEBUG*-Save EventEdit Error", error);
                    this.setState({ disabledButton: false, notification: constants.DATA_ERROR });
                });
            var recDays = "0000000";
            if (this.state.recMonday) {
                recDays = this.stringReplace(recDays, 1, "1");
            }
            if (this.state.recTuesday) {
                recDays = this.stringReplace(recDays, 2, "1");
            }
            if (this.state.recWednesday) {
                recDays = this.stringReplace(recDays, 3, "1");
            }
            if (this.state.recThursday) {
                recDays = this.stringReplace(recDays, 4, "1");
            }
            if (this.state.recFriday) {
                recDays = this.stringReplace(recDays, 5, "1");
            }
            if (this.state.recSaturday) {
                recDays = this.stringReplace(recDays, 6, "1");
            }
            if (this.state.recSunday) {
                recDays = this.stringReplace(recDays, 7, "1");
            }
            eventdefault.recurringDays = recDays;
        } else {
            this.setState({ validating: true, notification: constants.FORM_ERROR });
        }
    }

    createDateTime(date, time) {
        if (date.length != 10) {
            date = "aaaaaaaaaa";
        }
        if (time.length != 5) {
            time = "aaaaa";
        }
        // const newDateTime = date + "T" + time + ":00.000Z";
        const newDateTime = date + "T" + time + ":00.000";
        return newDateTime;
    }

    handleCancel() {
        if (this.props.eventdata.moreinfo) {
            this.props.eventdata.moreinfo = this.props.eventdata.moreinfo.filter(info => info.display.trim().length != 0 || info.url.trim().length != 0);
        }
        if (!this.state.cancel && !_.isEqual(start, this.props.eventdata)) {
            this.setState({ cancel: true });
        }
        else {
            //this.props.history.push("/events");
            const eventId = this.props.eventdata.id;
            //console.log("Restoring:", eventId);
            this.props.action.getEventAction(eventId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getSiteLevelRoute() + '/eventstable');
                    }
                }).catch(error => {
                    console.log("*DEBUG*-EventEdit Error", error);
                    this.props.history.push('/events');
                });
        }
    }

    //dealing with data
    updateTitleValue(evt) {
        this.props.eventdata.title = evt.target.value;
        if (evt.target.value.length > 50) {
            this.title_err = "Title can't be longer than 50 characters";
        }
        else {
            this.title_err = "";
        }
        this.setState({ validating: !this.state.validating });

    };
    updateSubtitleValue(evt) {
        this.props.eventdata.subtitle = evt.target.value;
        if (evt.target.value.length > 50) {
            this.subtitle_err = "Subtitle can't be longer than 50 characters";
        }
        else {
            this.subtitle_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateOrganiserValue(evt) {
        this.props.eventdata.organiser = evt.target.value;
        if (evt.target.value.length > 30) {
            this.organiser_err = "Organiser can't be longer than 30 characters";
        }
        else {
            this.organiser_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateSpeakersValue(evt) {
        this.props.eventdata.speaker = evt.target.value;
        if (evt.target.value.length > 25) {
            this.speakers_err = "Speakers can't be longer than 25 characters";
        }
        else {
            this.speakers_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    //updateLocationValue(evt) {
    //    this.props.eventdata.location = evt.target.value
    //};
    updatePlacemark(event) {
        this.props.eventdata.placemarkid = this.state.placemarks[event.selected].id;
        this.setState({
            placemarks: this.placemarkfull
        });
        if (event.option == "No match found") {
            event.option = "";
        }
        //this.props.eventdata.placemarkid = this.placemarkfull.find((o) => o.name == event.option).id;

        this.props.eventdata.location = event.option;

        this.placemarkid_err = "";
        if (event.option.trim().length == 0) {
            this.placemarkid_err = "Select an Option";
        }
    }

    updateRecurringEvent(evt) {
        this.props.eventdata.recurring = evt.target.checked;
        this.setState({ validating: !this.state.validating });
    }
    stringReplace(string, index, replacement) { //String where you wanna change the letter, index (starts from 1), the character you want to put in the string
        index = index - 1;
        return string.substr(0, index) + replacement + string.substr(index + replacement.length);
    }
    updateRecurringMonday(evt) {
        this.setState({ recMonday: evt.target.checked });
    }
    updateRecurringTuesday(evt) {
        this.setState({ recTuesday: evt.target.checked });
    }
    updateRecurringWednesday(evt) {
        this.setState({ recWednesday: evt.target.checked });
    }
    updateRecurringThursday(evt) {
        this.setState({ recThursday: evt.target.checked });
    }
    updateRecurringFriday(evt) {
        this.setState({ recFriday: evt.target.checked });
    }
    updateRecurringSaturday(evt) {
        this.setState({ recSaturday: evt.target.checked });
    }
    updateRecurringSunday(evt) {
        this.setState({ recSunday: evt.target.checked });
    }
    updateStartDateValue(evt) {
        this.props.eventdata.startdate = this.createDateTime(evt.value.substr(0, 10), this.props.eventdata.startdate.substr(11, 5));
        /*no past date check
        if (this.props.eventdata.id == "0") { //create new event
        if (this.props.eventdata.startdate.substr(0, 10) < this.now.substr(0, 10)) {

            //console.log("Can't select date from past");
            this.startdate_err = "Can't select date from past";
        }
        else {
           this.startdate_err = "";
        }
        }*/
        this.startdate_err = "";
        this.setState({ validating: !this.state.validating });

    }
    updateStartTimeValue(evt) {
        /*this.props.eventdata.startdate = this.createDateTime(this.props.eventdata.startdate.substr(0, 10), evt.target.value)*/
        if (evt.target.value != "") {
            this.props.eventdata.starttime = evt.target.value;
            this.props.eventdata.startdate = this.createDateTime(this.props.eventdata.startdate.substr(0, 10), evt.target.value);
            /*this.props.eventdata.enddate = this.createDateTime(this.props.eventdata.enddate, evt.target.value);*/
        }
        else {
            this.props.eventdata.starttime = null;
            this.props.eventdata.startdate = this.createDateTime(this.props.eventdata.startdate.substr(0, 10), "00:00");
        }
        this.setState({ startTimeUpdate: true });
    }
    updateEndDateValue(evt) {
        this.props.eventdata.enddate = this.createDateTime(evt.value.substr(0, 10), this.props.eventdata.enddate.substr(11, 5));
        /*this.props.eventdata.enddate = this.createDateTime(evt.value.substr(0, 10), this.props.eventdata.enddate.substr(11, 5));*/
        /*no past date check
        if (this.props.eventdata.enddate.substr(0, 10) < this.now.substr(0, 10)) {

            //console.log("Can't select date from past");
            this.enddate_err = "Can't select date from past";
        }
        else {

            this.enddate_err = "";
        }*/
        this.enddate_err = "";
        this.setState({ validating: !this.state.validating });
    }
    updateEndTimeValue(evt) {
        /*this.props.eventdata.enddate = this.createDateTime(this.props.eventdata.enddate.substr(0, 10), evt.target.value)*/
        if (evt.target.value != "") {
            this.props.eventdata.endtime = evt.target.value;
            this.props.eventdata.enddate = this.createDateTime(this.props.eventdata.enddate.substr(0, 10), evt.target.value);
        }
        else {
            this.props.eventdata.endtime = null;
            this.props.eventdata.enddate = this.createDateTime(this.props.eventdata.enddate.substr(0, 10), "23:59");
        }
        this.setState({ endTimeUpdate: true });
    }
    updateDisplayStart(evt) {
        this.props.eventdata.displayStart = evt.target.checked;
        if (this.props.eventdata.displayEnd && !this.props.eventdata.displayStart) {
            this.setState({ displayStartError: true });
            this.displaystart_err = " ";
        }
        else {
            this.setState({ displayStartError: false });
            this.displaystart_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }
    updateDisplayEnd(evt) {
        this.props.eventdata.displayEnd = evt.target.checked;
        if (this.props.eventdata.displayEnd && !this.props.eventdata.displayStart) {
            this.setState({ displayStartError: true });
            this.displaystart_err = " ";
        }
        else {
            this.setState({ displayStartError: false });
            this.displaystart_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    readFile(f) {
        if (f) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const data = event.target.result;
                console.log('File', data.length);
                //const base64data = btoa(unescape(encodeURIComponent(data)));
                const base64data = btoa(data);
                //console.log('File Base64:', base64data);
                base64 = base64data;
                //ToDo call API with file.name, and content
                //this.BinaryData = data; 
            };
            reader.readAsBinaryString(f);
        }
    }
    fileChangedHandler = (event) => {
        //console.log(event.target.files[0]);
        const file = event.target.files[0];
        //console.log(file);
        if (file != undefined) {
            if (file.size > 1024 * 1024 * 2) {
                this.image_err = "File too large: " + Math.round((file.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.image_err = "";
                this.readFile(file);
                this.setState({ file: file, newimage: true, imagechanged: true });
            }
        }
        else {

        }

    }
    removeIcon() {
        this.props.eventdata.imageUrl = null;
        this.props.eventdata.imageContent = null;
        base64 = null;
        this.setState({ removeIconOpen: false, newimage: false });
    }
    shortcut() {
        if (this.state.file == "") {
            return null;
        }
        else if (this.state.file != null) {
            var Data = [];
            Data.push(this.state.file);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/x-png,image/jpeg" }))
            return (
                <Box direction="row">
                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                    <Image style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("real");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }

    updateEmailValue(evt) { this.props.eventdata.email = evt.target.value };
    updatePhoneValue(evt) { this.props.eventdata.phone = evt.target.value };
    updateTwitterValue(evt) { this.props.eventdata.twitter = evt.target.value };
    updateLinkedinValue(evt) { this.props.eventdata.linkedin = evt.target.value };
    updateYammerValue(evt) { this.props.eventdata.yammer = evt.target.value };
    updateFacebookValue(evt) { this.props.eventdata.facebook = evt.target.value };
    updateUrlValue(evt) { this.props.eventdata.url = evt.target.value };
    updateInstagramValue(evt) { this.props.eventdata.instagram = evt.target.value };
    updateVideoValue(evt) { this.props.eventdata.video = evt.target.value };
    updateYoutubeValue(evt) { this.props.eventdata.youtube = evt.target.value };
    updateFeaturedValue(evt) {
        this.setState({ cbfeatured: evt.target.checked });
        this.props.eventdata.tagfeatured = evt.target.checked
    };
    updateEmployeeValue(evt) {
        this.setState({ cbemployee: evt.target.checked });
        this.props.eventdata.tagemployee = evt.target.checked
    };
    updateImageValue(evt, nro) {
        evt.preventDefault();
        this.props.eventdata.image = nro;
        this.setState({ selimage: nro })
    };
    updateMoreInfoDisplayValue(evt, idx) {
        this.props.eventdata.moreinfo[idx].display = evt.target.value;
        if (evt.target.value.length > 30) {
            this.moreinfo_err[idx].display = "Display text can't be longer than 30 characters";
        }
        else {
            this.moreinfo_err[idx].display = "";
        }
        this.setState({ validating: !this.state.validating });
    }
    updateMoreInfoUrlValue(evt, idx) {
        this.props.eventdata.moreinfo[idx].url = evt.target.value;
        if (this.props.eventdata.moreinfo[idx].url.length > 511) {
            this.moreinfo_err[idx].url = "URL can't be longer than 511 characters";
        }
        else this.moreinfo_err[idx].url = "";
        this.setState({ updated: true });
    }
    handleAddItem(event) {
        event.preventDefault();
        this.setState({ validating: false });
        //this.props.eventdata.moreinfo.push({ id: 0, display: "", url: "", deleted: false });
        if (!this.props.eventdata.moreinfo) {
            this.props.eventdata.moreinfo = [];
        }
        this.props.eventdata.moreinfo.push({ display: "", url: "" });
        this.setState({ validating: true });
    }

    handleDelete(event, id) {
        this.props.eventdata.moreinfo.splice(id, 1);
        this.setState({ validating: !this.state.validating });
    }

    updateAuthStatus(evt, idx) {
        this.props.eventdata.moreinfo[idx].urlAuth = !this.props.eventdata.moreinfo[idx].urlAuth;
        this.setState({ updated: true });
    };

    updateLaunchMode(evt, idx) {
        this.props.eventdata.moreinfo[idx].urlType = this.launchEnumOptions.find(e => e.label == evt.option).value;
        this.setState({ updated: true });
    };

    renderMoreInfo(infos) {
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONTENT);
        return (
            <Box gap="medium">
                {infos && infos.map((info, idx) => (
                    <Box direction="row" gap="small">
                        <StyledBox style1 key={idx} direction="row" gap="medium" justify="start" pad={{ "horizontal": "small" }}>
                            <Box width="250px">
                                <Grommet theme={customTheme} >
                                    <FormField error={this.moreinfo_err[idx].display} label={<Box direction="row" gap="xsmall" align="center"><DocumentText />Display Text</Box>}>
                                        <TextInputWithState id={`Info${idx}`} value={info.display} onInput={evt => this.updateMoreInfoDisplayValue(evt, idx)} />
                                    </FormField>
                                </Grommet>
                            </Box>
                            <Box width="400px">
                                <Grommet theme={customTheme} >
                                    <FormField error={this.moreinfo_err[idx].url} label={<Box direction="row" gap="xsmall" align="center"><Link />URL</Box>}>
                                        <TextInputWithState id={`Url${idx}`} value={info.url} onInput={evt => this.updateMoreInfoUrlValue(evt, idx)} />
                                    </FormField>
                                </Grommet>
                            </Box>
                            {info.url.trim().substr(info.url.trim().length - 4).toLowerCase() == ".pdf" ?
                                <Box direction="row" width="200px">
                                    <FormField label={
                                        <Box direction="row" gap="xsmall" justify="between" height="24px">
                                            <Box direction="row" gap="xsmall" justify="between">
                                                <Secure />
                                                Authentication Status:
                                            </Box>
                                            <Box>
                                                <a data-for="auth_info" data-tip="Authenticated PDF's are handled differently in the client app.<br />
                                                                                To ensure this content opens correctly, ensure you specify if <br />
                                                                                this PDF is authenticated or not authenticated here.">
                                                    <CircleInformation />
                                                </a>
                                                <ReactTooltip id="auth_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                            </Box>
                                        </Box>}>
                                        <Select
                                            options={["Authenticated", "Not Authenticated"]}
                                            value={info.urlAuth ? "Authenticated" : "Not Authenticated"}
                                            onChange={evt => this.updateAuthStatus(evt, idx)} />
                                    </FormField>
                                </Box>
                                :
                                <Box direction="row" width="200px">
                                    <FormField label={
                                        <Box direction="row" gap="xsmall" justify="between" height="24px">
                                            <Box direction="row" gap="xsmall" justify="between">
                                                <PhoneVertical />
                                                Launch Mode:
                                            </Box>
                                            <Box>
                                                <a data-for="launch_info" data-tip="In app browser is recommended for displaying web content that has it's own navigation.<br />
                                                                                External browser is recommended for opening web content that is external to your app.<br />
                                                                                Hosted in app is recommended for displaying basic web and HTML content in your app and doesn't have it's own navigation.">
                                                    <CircleInformation />
                                                </a>
                                                <ReactTooltip id="launch_info" place="top" type="dark" effect="solid" arrowColor="transparent" multiline />
                                            </Box>
                                        </Box>}>
                                        <Select
                                            options={this.launchEnumOptions.map(e => e.label)}
                                            defaultValue={!info.urlType ? info.urlType = "InAppBrowser" : ""}
                                            value={this.launchEnumOptions.find(e => e.value == info.urlType) ? this.launchEnumOptions.find(e => e.value == info.urlType).label : ""}
                                            onChange={evt => this.updateLaunchMode(evt, idx)} />
                                    </FormField>
                                </Box>}
                            <Box alignSelf="center" margin={{ "right": "small" }} >
                                {canedit1 &&
                                    <StyledButton icon={<Trash />} plain onClick={evt => this.handleDelete(evt, idx)} disabled={!canedit1} />}
                            </Box>
                        </StyledBox>
                    </Box>
                ))}
                <Box direction="row" gap="small">
                    <Box width="medium">
                        {canedit1 &&
                            <StyledButton typeAdd label="Add Item" alignSelf="start" onClick={this.handleAddItem.bind(this)} disabled={!canedit1} />}
                    </Box>
                </Box>
            </Box>
        )
    }

    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push(constants.getSiteLevelRoute() + '/eventstable');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    handleEditorChange = (content, delta, source, editor) => {
        this.props.eventdata.detail = editor.getHTML();
        if (editor.getLength() - 1 > 5000) {
            this.detail_err = "Detail can't be longer than 5000 characters";
        }
        else {
            this.detail_err = "";
        }
        this.setState({ HTMLTextLenght: editor.getLength() - 1 });
        //console.log("HTML_EDITOR11", content, delta, source, editor.getHTML()); // rich text
        //console.log("HTML_EDITOR22", editor.getHTML()); // rich text
        //console.log("HTML_EDITOR33", editor.getText()); // plain text
        //console.log("HTML_EDITOR44", editor.getLength()); // number of characters

        var str = editor.getHTML();
        var searchKeyword = "<a href=";

        var indexes = [];
        this.editorURLs = [];
        var firstIndex = str.toLowerCase().indexOf(searchKeyword, 0);

        while (firstIndex >= 0) {
            if (!str.substr(firstIndex).match(/href=(["'])(.*?)\1/)[2].startsWith("mailto:")) { //skip if it is an e-mail address
                this.editorURLs.push(str.substr(firstIndex).match(/href=(["'])(.*?)\1/)[2]);
            }

            indexes.push(firstIndex);
            firstIndex = str.toLowerCase().indexOf(searchKeyword, firstIndex + 1);
        }
    }

    render() {
        if (this.props.eventdata) {
            this.props.eventdata.moreinfo = this.props.eventdata.moreinfo ? this.props.eventdata.moreinfo : [{ id: 1, display: "", url: "" }];
        };
        const eventdefault = this.props.eventdata;
        const sitedata = this.props.sitedata;
        const { eventdataLoading, placemarksdataLoading, sitedataLoading, timezonesdataLoading } = this.state;

        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONTENT);

        if (eventdataLoading || placemarksdataLoading || sitedataLoading || timezonesdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        if (eventdefault.id == "0" && !this.state.startTimeUpdate) {
            eventdefault.starttime = eventdefault.startdate.substr(11, 5);
        }
        if (eventdefault.id == "0" && !this.state.endTimeUpdate) {
            eventdefault.endtime = eventdefault.enddate.substr(11, 5);
        }
        const { utcdate } = this.state;
        const heading = eventdefault && (eventdefault.id != "0") ? "Edit" : "Create";
        const sdate = eventdefault.startdate.substring(0, 10);
        const stime = eventdefault.startdate.substring(11, 16);
        const edate = eventdefault.enddate.substring(0, 10);
        const etime = eventdefault.enddate.substring(11, 16);

        const vplacemark = this.placemarkfull.find((o) => o.id == eventdefault.placemarkid);
        const vplacemarkname = vplacemark ? vplacemark.name : "";
        const { placemarks } = this.state;
        //console.log("Render props:", JSON.stringify(this.props));
        const { notification } = this.state;

        let offset = 0;
        let timeZoneName = "UTC";
        let timeZone = this.props.timezonesdata.find(tz => tz.name == sitedata.timeZone);
        if (timeZone) {
            timeZoneName = timeZone.name;
            offset = parseInt(timeZone.utcOffset);
        }

        return (

            <Grid fill={true}
                rows={["xsmall", "auto"]}
                columns={["100%", "flex"]}

                areas={[
                    { name: "header", start: [0, 0], end: [1, 0] },

                    { name: "main", start: [0, 1], end: [0, 1] },
                    { name: "side", start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea="header" /*background="brand"*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level="2" >Event - {`${heading}`}</Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label={constants.visible(canedit1) == "visible" ? "Cancel" : "Back"} onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        {constants.visible(canedit1) == "visible" &&
                            <StyledButton typeSave label="Save" style={{ visibility: constants.visible(canedit1) }} onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />}
                    </Box>
                </Box>
                <Box gridArea="main" background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    {/*<Notification message={notification} />*/}
                    <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUp open={this.state.isUploadError} onClose={() => this.onUploadClose()} text={this.uploadErrorText} header="File Upload Error" error={this.state.isUploadError} />
                    <PopUp open={this.state.isTimeZoneNull} onClose={() => this.onTimeZoneClose()} text={"This site has no time zone selected. Please ensure the site is configured correctly."} header="Time Zone Required" error={this.state.isTimeZoneNull} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <PopUpConfirm open={this.state.removeIconOpen} text={"Please confirm that you want to delete this image. The image associated with this event will no longer be visible in the app."}
                        onClose={() => this.setState({ removeIconOpen: false })} onDelete={() => this.removeIcon()} />
                    {/* <form> */}
                    <form>
                        <Box margin={{ "bottom": "xsmall" }}>
                            <Text size="large">Page Type</Text>
                        </Box>
                        {/* <form> */}
                        <Box margin={{ "bottom": "medium" }}>
                            {/*Page type*/}
                            <Box direction="row">
                                <StyledBox style1 flex="shrink" direction="row" gap="large" pad={{ "vertical": "medium", "horizontal": "small" }}>
                                    {(eventdefault.id == "0" || eventdefault.type == "Detail") &&
                                        <RadioButton
                                            label="Detail Page"
                                            checked={eventdefault.type == "Detail" ? true : false}
                                            onChange={v => this.updateType(v, "Detail")}
                                            disabled={eventdefault.id != "0"} />}
                                    {/*{(eventdefault.id == "0" || eventdefault.type == "Web") &&*/}
                                    {/*    <RadioButton*/}
                                    {/*        label="Web Page"*/}
                                    {/*        checked={eventdefault.type == "Web" ? true : false}*/}
                                    {/*        onChange={v => this.updateType(v, "Web")}*/}
                                    {/*        disabled={eventdefault.id != "0"} />}*/}
                                    {(eventdefault.id == "0" || eventdefault.type == "Html") &&
                                        <RadioButton
                                            label="HTML Page"
                                            checked={eventdefault.type == "Html" ? true : false}
                                            onChange={v => this.updateType(v, "Html")}
                                            disabled={eventdefault.id != "0"} />}
                                </StyledBox>
                            </Box>
                            {this.pageType_err != "" && <Text color="red">{this.pageType_err}</Text>}
                        </Box>

                        {eventdefault.type == "Detail" &&
                            <Box gap="medium">
                                {/*<Box direction="row">*/}
                                {/*    */}{/*Page Type*/}
                                {/*    <StyledBox style1 flex="shrink" direction="row" gap="xlarge" pad={{ "vertical": "medium", "horizontal": "small" }}>*/}
                                {/*        <CheckBox*/}
                                {/*            label="Detail Page"*/}
                                {/*            checked*/}
                                {/*            disabled={heading == "Edit"}*/}
                                {/*            onChange={v => this.updateType(v, "Detail")}*/}
                                {/*        />*/}
                                {/*    </StyledBox>*/}
                                {/*</Box>*/}

                                {/*Description*/}
                                <Box>
                                    <Box>
                                        <Text size="large">Description</Text>
                                        <Text>All fields marked with an * are mandatory.</Text>
                                    </Box>
                                    {/* <form onSubmit={event => event.preventDefault()}> */}
                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                        <FormField label="Title *" error={this.title_err}>
                                            <TextInput defaultValue={eventdefault.title} onChange={evt => this.updateTitleValue(evt)} disabled={!canedit1} />
                                        </FormField>
                                        <FormField label="Subtitle" error={this.subtitle_err}>
                                            <TextInput defaultValue={eventdefault.subtitle} onChange={evt => this.updateSubtitleValue(evt)} disabled={!canedit1} />
                                        </FormField>
                                        <FormField label="Organiser" error={this.organiser_err}>
                                            {/*<Select selected={selected} placeHolder="" options={["Sport and Social Events", "IWN Events"]} />*/}
                                            <TextInput defaultValue={eventdefault.organiser} onChange={evt => this.updateOrganiserValue(evt)} disabled={!canedit1} />
                                        </FormField>
                                        <Box width="400px">
                                            <FormField label="Location" error={this.placemarkid_err}>
                                                {/* <TextInput defaultValue={eventdefault.location} onChange={evt => this.updateLocationValue(evt)} />*/}
                                                <Select
                                                    disabled={!this.props.sitedata.meridianEnabled}
                                                    placeholder={!this.props.sitedata.meridianEnabled ? "Placemarks are disabled on this site" : "Placemark Name"}
                                                    onClose={() => this.setState({ placemarks: this.placemarkfull })}
                                                    onSearch={(searchText) => {
                                                        const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "i");
                                                        const filtered = this.placemarkfull.filter(o => o.name.match(regexp));
                                                        if (filtered.length > 0) {
                                                            this.setState({ placemarks: filtered });
                                                        }
                                                        else {
                                                            this.setState({ placemarks: [{ id: null, name: "No match found" }] });
                                                        }
                                                    }}
                                                    value={vplacemarkname}
                                                    onChange={event => this.updatePlacemark(event)}
                                                    options={placemarks.map((option) => option.name)}
                                                    replace={false}
                                                    disabled={!canedit1}
                                                />
                                            </FormField>
                                        </Box>
                                        <Box width="135px">
                                            <FormField label="Event tag">
                                                <Box direction="row" pad="small" gap="xlarge">
                                                    <CheckBox label="Featured" checked={eventdefault.tagfeatured} onChange={evt => this.updateFeaturedValue(evt)} disabled={!canedit1} />
                                                    {/*<CheckBox label="Employee Only" checked={eventdefault.tagemployee} onChange={evt => this.updateEmployeeValue(evt)} />*/}
                                                </Box>
                                            </FormField>
                                        </Box>
                                        <Grommet theme={customTheme}>
                                            <FormField label="Details *" error={this.detail_err} data-bounds="html_editor" >
                                                <ReactQuill
                                                    theme="snow"
                                                    bounds={`[data-bounds="html_editor"]`}
                                                    scrollingContainer="html"
                                                    modules={{
                                                        toolbar: [
                                                            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                                            ['blockquote', 'code-block'],
                                                            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                            [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
                                                            [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
                                                            [{ 'direction': 'rtl' }],                         // text direction
                                                            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                                            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                                                            ['link'],
                                                            ['clean']                                         // remove formatting button
                                                        ],
                                                        clipboard: {
                                                            matchVisual: false,
                                                        }
                                                    }}
                                                    onChange={this.handleEditorChange}
                                                    value={eventdefault.detail}
                                                    readOnly={!canedit1} />
                                            </FormField>
                                        </Grommet>
                                        {/* <FormField label="Speakers" error={this.speakers_err} > */}
                                        {/*<Select placeHolder="" options={["one", "two", "three", "four"]} />*/}
                                        {/* <TextInput defaultValue={eventdefault.speaker} onChange={evt => this.updateSpeakersValue(evt)} />
                                    </FormField> */}
                                    </StyledBox>
                                </Box>
                                {/*Dat / Time */}
                                <Box>
                                    <Box>
                                        <Text size="large">Date/Time</Text>
                                        <Text> The date and times chosen below will be broadcast in the client app until the End Date/End time has passed.
                                        If you do not want the date/time displayed in the client app, you can deselect the Display in App checkbox below
                                        </Text>
                                    </Box>
                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                        <Box width="100px">
                                            <FormField label="Recurring Event" justify="center">
                                                <Box pad="small" align="center">
                                                    <CheckBox checked={this.props.eventdata.recurring} onChange={evt => this.updateRecurringEvent(evt)} disabled={!canedit1} />
                                                </Box>
                                            </FormField>
                                        </Box>
                                        {this.props.eventdata.recurring &&
                                            <Box margin={{ "bottom": "small" }}>
                                                <Box width="840px">
                                                    <FormField label="Recurs On" justify="center" pad="small">
                                                        <Box direction="row" gap="medium">
                                                            <Box direction="row" align="center">
                                                                <CheckBox checked={this.state.recMonday} onChange={evt => this.updateRecurringMonday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Monday</Text>
                                                            </Box>
                                                            <Box direction="row" justify="center">
                                                                <CheckBox checked={this.state.recTuesday} onChange={evt => this.updateRecurringTuesday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Tuesday</Text>
                                                            </Box>
                                                            <Box direction="row" justify="center">
                                                                <CheckBox checked={this.state.recWednesday} onChange={evt => this.updateRecurringWednesday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Wednesday</Text>
                                                            </Box>
                                                            <Box direction="row" justify="center">
                                                                <CheckBox checked={this.state.recThursday} onChange={evt => this.updateRecurringThursday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Thursday</Text>
                                                            </Box>
                                                            <Box direction="row" justify="center">
                                                                <CheckBox checked={this.state.recFriday} onChange={evt => this.updateRecurringFriday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Friday</Text>
                                                            </Box>
                                                            <Box direction="row" justify="center">
                                                                <CheckBox checked={this.state.recSaturday} onChange={evt => this.updateRecurringSaturday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Saturday</Text>
                                                            </Box>
                                                            <Box direction="row" justify="center">
                                                                <CheckBox checked={this.state.recSunday} onChange={evt => this.updateRecurringSunday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Sunday</Text>
                                                            </Box>
                                                        </Box>
                                                    </FormField>
                                                </Box>
                                            </Box>}
                                        <Box direction="row" gap="small">
                                            {utcdate &&
                                                <FormField label={`Start Date*`} error={this.startdate_err}>
                                                    <DateInput calendarProps={{ "daysOfWeek": true, "firstDayOfWeek": 1 }} value={sdate} onChange={evt => this.updateStartDateValue(evt)} format="dd/mm/yyyy" readOnly disabled={!canedit1} />
                                                    {/*<TextInput type="date" defaultValue={sdate} onChange={evt => this.updateStartDateValue(evt)} readOnly={(heading == "Edit" && eventdefault.startdate.substr(0, 10) < this.now.substr(0, 10))} />*/}
                                                </FormField>}
                                            {utcdate &&
                                                <FormField label="Start Time" error={this.starttime_err}>
                                                    <TextInput type="time" defaultValue={eventdefault.id == "0" ? stime : eventdefault.starttime} value={stime} onChange={evt => this.updateStartTimeValue(evt)} disabled={!canedit1} />
                                                    {/*<TextInput type="time" defaultValue={stime} onChange={evt => this.updateStartTimeValue(evt)} />*/}
                                                    {/*<TextInput type="time" defaultValue={stime} onChange={evt => this.updateStartTimeValue(evt)} readOnly={(heading == "Edit" && eventdefault.startdate.substr(0, 10) < this.now.substr(0, 10))} />*/}
                                                </FormField>}
                                            {utcdate &&
                                                <FormField label="Time Zone">
                                                    <TextInput value={sitedata.timeZone ? sitedata.timeZone : "No time zone selected"} readOnly disabled={!canedit1} />
                                                </FormField>}
                                            <Box width="90px">
                                                <FormField label="Display in App" justify="center" error={this.displaystart_err}>
                                                    <Box pad="small" align="center">
                                                        <CheckBox checked={this.props.eventdata.displayStart} onChange={evt => this.updateDisplayStart(evt)} disabled={!canedit1} />
                                                    </Box>
                                                </FormField>
                                            </Box>
                                            {this.state.displayStartError &&
                                                <Box justify="end" pad={{ "bottom": "medium" }}>
                                                    <Text size="small" color="red">All events must display a start date with an end date displayed</Text>
                                                </Box>}
                                        </Box>
                                        <Box direction="row" gap="small">
                                            {utcdate &&
                                                <FormField label={`End Date*`} error={this.enddate_err}>
                                                    <DateInput calendarProps={{ "daysOfWeek": true, "firstDayOfWeek": 1 }} value={edate} onChange={evt => this.updateEndDateValue(evt)} format="dd/mm/yyyy" readOnly disabled={!canedit1} />
                                                </FormField>}
                                            {utcdate &&
                                                <FormField label="End Time" error={this.endtime_err}>
                                                    <TextInput type="time" defaultValue={eventdefault.id == "0" ? etime : eventdefault.endtime} value={etime} onChange={evt => this.updateEndTimeValue(evt)} disabled={!canedit1} />
                                                </FormField>}
                                            {utcdate &&
                                                <FormField label="Time Zone">
                                                    <TextInput value={sitedata.timeZone ? sitedata.timeZone : "No time zone selected"} readOnly disabled={!canedit1} />
                                                </FormField>}
                                            <Box width="90px">
                                                <FormField label="Display in App" justify="center" error={this.displayend_err}>
                                                    <Box pad="small" align="center">
                                                        <CheckBox checked={this.props.eventdata.displayEnd} onChange={evt => this.updateDisplayEnd(evt)} disabled={!canedit1} />
                                                    </Box>
                                                </FormField>
                                            </Box>
                                        </Box>
                                    </StyledBox>
                                </Box>

                                <Box gap="small">
                                    <Box direction="row" gap="small" align="center">
                                        <Text size="large">Image</Text>
                                        <Box data-for="img_info" data-tip>
                                            <CircleInformation />
                                            <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                <span>Please select a brand approved image to display with your event. <br />
                                                        Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.</span>
                                            </ReactTooltip>
                                        </Box>
                                    </Box>
                                    {/*<Box width="750px">*/}
                                    {/*    <Text>*/}
                                    {/*        Please select a brand approved image to display with your content. <br />*/}
                                    {/*    Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.*/}
                                    {/*    </Text>*/}
                                    {/*</Box>*/}
                                    <Box direction="row" gap="small">
                                        <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }} width={{ "min": "101px" }} fill={false} margin={{ "bottom": "small" }}>
                                            <FormField label="" error={this.image_err}>
                                                <Box direction="row" justify="center">
                                                    {this.state.newimage ?
                                                        <Box alignSelf="center">
                                                            {this.shortcut()}
                                                        </Box>
                                                        :
                                                        (eventdefault.imageUrl == "" || eventdefault.imageUrl == null) ?
                                                            <Box height="75px" justify="center">
                                                                <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} disabled={!canedit1} onClick={e => {
                                                                    const c = document.getElementById("real");
                                                                    c.click();
                                                                }} />
                                                            </Box>
                                                            :
                                                            <Box justify="center">
                                                                <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                <Image height="75" src={`${eventdefault.imageUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                    onClick={e => {
                                                                        const c = document.getElementById("real");
                                                                        c.click();
                                                                    }} />
                                                            </Box>
                                                    }
                                                </Box>
                                            </FormField>
                                        </StyledBox>
                                        <Box justify="center" margin={{ "bottom": "small" }}>
                                            <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={!canedit1 || ((eventdefault.imageUrl == "" || eventdefault.imageUrl == null) && !this.state.newimage)}>
                                                <Trash />
                                            </Button>
                                        </Box>
                                    </Box>
                                    {/*<Box width="150px" margin={{ "top": "small" }}>*/}
                                    {/*    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />*/}
                                    {/*    <StyledButton typeAdd label="Choose a file" disabled={!canedit1} onClick={e => {*/}
                                    {/*        const c = document.getElementById("real");*/}
                                    {/*        c.click();*/}
                                    {/*    }} />*/}
                                    {/*</Box>*/}
                                </Box>

                                {/*Connect Now*/}
                                <IconThemeContext.Extend value={galwayIconTheme}>
                                    <Box>
                                        <Box>
                                            <Text size="large">Connect Now</Text>
                                        </Box>
                                        <StyledBox style1 gap="small" pad={{ "horizontal": "small" }} justify="between" direction="row">
                                            <Box direction="column" width="20%">
                                                <Box direction="row" align="start">
                                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                        <Mail />
                                                    </Box>
                                                    <Box width="100%">
                                                        <FormField error={this.email_err} fill="horizontal" label="Email Address">
                                                            <TextInput defaultValue={eventdefault.email} onChange={evt => this.updateEmailValue(evt)} disabled={!canedit1} />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                                <Box direction="row" align="start" >
                                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                        {/* <Image src="./Images/Phone_black.png" width="24px" height="24px" /> */}
                                                        <Phone />
                                                    </Box>
                                                    <Box width="100%">
                                                        <FormField help={this.phone_help} error={this.phone_err} fill="horizontal" label="Phone Number">
                                                            <TextInput defaultValue={eventdefault.phone} onChange={evt => this.updatePhoneValue(evt)} disabled={!canedit1} />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box direction="column" width="20%">
                                                <Box direction="row" align="start" >
                                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                        <Twitter />
                                                    </Box>
                                                    <Box width="100%">
                                                        <FormField error={this.twitter_err} fill="horizontal" label="Twitter">
                                                            <TextInput defaultValue={eventdefault.twitter} onChange={evt => this.updateTwitterValue(evt)} disabled={!canedit1} />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                                <Box direction="row" align="start">
                                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                        <LinkedinOption />
                                                    </Box>
                                                    <Box width="100%">
                                                        <FormField error={this.linkedin_err} fill="horizontal" label="LinkedIn">
                                                            <TextInput defaultValue={eventdefault.linkedin} onChange={evt => this.updateLinkedinValue(evt)} disabled={!canedit1} />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box direction="column" width="20%">
                                                <Box direction="row" align="start">
                                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                        {/*<Image src="./Images/Yammer_black.png" width="24px" height="24px" />*/}
                                                        <Yammer />
                                                    </Box>
                                                    <Box width="100%">
                                                        <FormField error={this.yammer_err} fill="horizontal" label="Yammer">
                                                            <TextInput defaultValue={eventdefault.yammer} onChange={evt => this.updateYammerValue(evt)} disabled={!canedit1} />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                                <Box direction="row" align="start">
                                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                        <FacebookOption />
                                                    </Box>
                                                    <Box width="100%">
                                                        <FormField error={this.facebook_err} fill="horizontal" label="Facebook">
                                                            <TextInput defaultValue={eventdefault.facebook} onChange={evt => this.updateFacebookValue(evt)} disabled={!canedit1} />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box direction="column" width="20%">
                                                <Box direction="row" align="start">
                                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                        <Link />
                                                    </Box>
                                                    <Box width="100%">
                                                        <FormField error={this.url_err} fill="horizontal" label="URL">
                                                            <TextInput defaultValue={eventdefault.url} onChange={evt => this.updateUrlValue(evt)} disabled={!canedit1} />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                                <Box direction="row" align="start">
                                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                        <Instagram />
                                                    </Box>
                                                    <Box width="100%">
                                                        <FormField error={this.instagram_err} fill="horizontal" label="Instagram">
                                                            <TextInput defaultValue={eventdefault.instagram} onChange={evt => this.updateInstagramValue(evt)} disabled={!canedit1} />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box direction="column" width="20%">
                                                <Box direction="row" align="start">
                                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                        <Video />
                                                    </Box>
                                                    <Box width="100%">
                                                        <FormField error={this.video_err} fill="horizontal" label="Video">
                                                            <TextInput defaultValue={eventdefault.video} onChange={evt => this.updateVideoValue(evt)} disabled={!canedit1} />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                                <Box direction="row" align="start">
                                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                                        <Youtube />
                                                    </Box>
                                                    <Box width="100%">
                                                        <FormField error={this.youtube_err} fill="horizontal" label="YouTube">
                                                            <TextInput defaultValue={eventdefault.youtube} onChange={evt => this.updateYoutubeValue(evt)} disabled={!canedit1} />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </StyledBox>
                                    </Box>

                                    {/*More Information*/}
                                    <Box pad={{ "vertical": "medium" }}>
                                        <Box>
                                            <Text size="large">More Information</Text>
                                            {this.renderMoreInfo(this.props.eventdata.moreinfo)}
                                        </Box>
                                    </Box>
                                </IconThemeContext.Extend>
                            </Box>}

                        {eventdefault.type == "Html" &&
                            <Box gap="medium" pad={{ "bottom": "medium" }}>
                                {/*<Box direction="row">*/}
                                {/*    */}{/*Page Type*/}
                                {/*    <StyledBox style1 flex="shrink" direction="row" gap="xlarge" pad={{ "vertical": "medium", "horizontal": "small" }}>*/}
                                {/*        <CheckBox*/}
                                {/*            label="HTML Page"*/}
                                {/*            checked*/}
                                {/*            disabled={heading == "Edit"}*/}
                                {/*            onChange={v => this.updateType(v, "Detail")}*/}
                                {/*        />*/}
                                {/*    </StyledBox>*/}
                                {/*</Box>*/}

                                {/*Description*/}
                                <Box>
                                    <Box>
                                        <Text size="large">Description</Text>
                                        <Text>All fields marked with an * are mandatory.</Text>
                                    </Box>

                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                        <FormField label="Title *" error={this.title_err}>
                                            <TextInput defaultValue={eventdefault.title} onChange={evt => this.updateTitleValue(evt)} disabled={!canedit1} />
                                        </FormField>
                                        <FormField label="Subtitle" error={this.subtitle_err}>
                                            <TextInput defaultValue={eventdefault.subtitle} onChange={evt => this.updateSubtitleValue(evt)} disabled={!canedit1} />
                                        </FormField>
                                        <FormField label="Organiser" error={this.organiser_err}>
                                            <TextInput defaultValue={eventdefault.organiser} onChange={evt => this.updateOrganiserValue(evt)} disabled={!canedit1} />
                                        </FormField>

                                        <Box width="135px">
                                            <FormField label="Event tag">
                                                <Box direction="row" pad="small" gap="xlarge">
                                                    <CheckBox label="Featured" checked={eventdefault.tagfeatured} onChange={evt => this.updateFeaturedValue(evt)} disabled={!canedit1} />
                                                </Box>
                                            </FormField>
                                        </Box>

                                        <Grommet theme={customTheme}>
                                            <FormField label="Details *" error={this.detail_err} data-bounds="html_editor" >
                                                <ReactQuill
                                                    theme="snow"
                                                    bounds={`[data-bounds="html_editor"]`}
                                                    scrollingContainer="html"
                                                    modules={{
                                                        toolbar: [
                                                            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                                            ['blockquote', 'code-block'],
                                                            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                            [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
                                                            [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
                                                            [{ 'direction': 'rtl' }],                         // text direction
                                                            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                                            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                                                            ['link'],
                                                            ['clean']                                         // remove formatting button
                                                        ],
                                                        clipboard: {
                                                            matchVisual: false,
                                                        }
                                                    }}
                                                    onChange={this.handleEditorChange}
                                                    value={eventdefault.detail}
                                                    readOnly={!canedit1} />
                                            </FormField>
                                        </Grommet>
                                    </StyledBox>
                                </Box>

                                {/*Date / Time */}
                                <Box>
                                    <Box>
                                        <Text size="large">Date/Time</Text>
                                        <Text> The date and times chosen below will be broadcast in the client app until the End Date/End time has passed.
                                        If you do not want the date/time displayed in the client app, you can deselect the Display in App checkbox below
                                        </Text>
                                    </Box>
                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                        <Box width="100px">
                                            <FormField label="Recurring Event" justify="center">
                                                <Box pad="small" align="center">
                                                    <CheckBox checked={this.props.eventdata.recurring} onChange={evt => this.updateRecurringEvent(evt)} disabled={!canedit1} />
                                                </Box>
                                            </FormField>
                                        </Box>
                                        {this.props.eventdata.recurring &&
                                            <Box margin={{ "bottom": "small" }}>
                                                <Box width="840px">
                                                    <FormField label="Recurs On" justify="center" pad="small">
                                                        <Box direction="row" gap="medium">
                                                            <Box direction="row" align="center">
                                                                <CheckBox checked={this.state.recMonday} onChange={evt => this.updateRecurringMonday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Monday</Text>
                                                            </Box>
                                                            <Box direction="row" justify="center">
                                                                <CheckBox checked={this.state.recTuesday} onChange={evt => this.updateRecurringTuesday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Tuesday</Text>
                                                            </Box>
                                                            <Box direction="row" justify="center">
                                                                <CheckBox checked={this.state.recWednesday} onChange={evt => this.updateRecurringWednesday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Wednesday</Text>
                                                            </Box>
                                                            <Box direction="row" justify="center">
                                                                <CheckBox checked={this.state.recThursday} onChange={evt => this.updateRecurringThursday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Thursday</Text>
                                                            </Box>
                                                            <Box direction="row" justify="center">
                                                                <CheckBox checked={this.state.recFriday} onChange={evt => this.updateRecurringFriday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Friday</Text>
                                                            </Box>
                                                            <Box direction="row" justify="center">
                                                                <CheckBox checked={this.state.recSaturday} onChange={evt => this.updateRecurringSaturday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Saturday</Text>
                                                            </Box>
                                                            <Box direction="row" justify="center">
                                                                <CheckBox checked={this.state.recSunday} onChange={evt => this.updateRecurringSunday(evt)} disabled={!canedit1} />
                                                                <Text margin={{ "left": "small" }}>Sunday</Text>
                                                            </Box>
                                                        </Box>
                                                    </FormField>
                                                </Box>
                                            </Box>}
                                        <Box direction="row" gap="small">
                                            {utcdate &&
                                                <FormField label={`Start Date*`} error={this.startdate_err}>
                                                    <DateInput calendarProps={{ "daysOfWeek": true, "firstDayOfWeek": 1 }} value={sdate} onChange={evt => this.updateStartDateValue(evt)} format="dd/mm/yyyy" disabled={!canedit1} />
                                                    {/*<TextInput type="date" defaultValue={sdate} onChange={evt => this.updateStartDateValue(evt)} readOnly={(heading == "Edit" && eventdefault.startdate.substr(0, 10) < this.now.substr(0, 10))} />*/}
                                                </FormField>}
                                            {utcdate &&
                                                <FormField label="Start Time" error={this.starttime_err}>
                                                    <TextInput type="time" defaultValue={eventdefault.id == "0" ? eventdefault.startdate.substr(11, 5) : eventdefault.starttime} value={stime} onChange={evt => this.updateStartTimeValue(evt)} disabled={!canedit1} />
                                                    {/*<TextInput type="time" defaultValue={stime} onChange={evt => this.updateStartTimeValue(evt)} />*/}
                                                    {/*<TextInput type="time" defaultValue={stime} onChange={evt => this.updateStartTimeValue(evt)} readOnly={(heading == "Edit" && eventdefault.startdate.substr(0, 10) < this.now.substr(0, 10))} />*/}
                                                </FormField>}
                                            {utcdate &&
                                                <FormField label="Time Zone">
                                                    <TextInput value={sitedata.timeZone ? sitedata.timeZone : "No time zone selected"} readOnly disabled={!canedit1} />
                                                </FormField>}
                                            <Box width="90px">
                                                <FormField label="Display in App" justify="center" error={this.displaystart_err}>
                                                    <Box pad="small" align="center">
                                                        <CheckBox checked={this.props.eventdata.displayStart} onChange={evt => this.updateDisplayStart(evt)} disabled={!canedit1} />
                                                    </Box>
                                                </FormField>
                                            </Box>
                                            {this.state.displayStartError &&
                                                <Box justify="end" pad={{ "bottom": "medium" }}>
                                                    <Text size="small" color="red">All events must display a start date with an end date displayed</Text>
                                                </Box>}
                                        </Box>
                                        <Box direction="row" gap="small">
                                            {utcdate &&
                                                <FormField label={`End Date*`} error={this.enddate_err}>
                                                    <DateInput calendarProps={{ "daysOfWeek": true, "firstDayOfWeek": 1 }} value={edate} onChange={evt => this.updateEndDateValue(evt)} format="dd/mm/yyyy" disabled={!canedit1} />
                                                </FormField>}
                                            {utcdate &&
                                                <FormField label="End Time" error={this.endtime_err}>
                                                    <TextInput type="time" defaultValue={eventdefault.id == "0" ? eventdefault.enddate.substr(11, 5) : eventdefault.endtime} value={etime} onChange={evt => this.updateEndTimeValue(evt)} disabled={!canedit1} />
                                                </FormField>}
                                            {utcdate &&
                                                <FormField label="Time Zone">
                                                    <TextInput value={sitedata.timeZone ? sitedata.timeZone : "No time zone selected"} readOnly disabled={!canedit1} />
                                                </FormField>}
                                            <Box width="90px">
                                                <FormField label="Display in App" justify="center" error={this.displayend_err}>
                                                    <Box pad="small" align="center">
                                                        <CheckBox checked={this.props.eventdata.displayEnd} onChange={evt => this.updateDisplayEnd(evt)} disabled={!canedit1} />
                                                    </Box>
                                                </FormField>
                                            </Box>
                                        </Box>
                                    </StyledBox>
                                </Box>

                                <Box gap="small">
                                    <Box direction="row" gap="small" align="center">
                                        <Text size="large">Image</Text>
                                        <Box data-for="img_info" data-tip>
                                            <CircleInformation />
                                            <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                <span>Please select a brand approved image to display with your event. <br />
                                                    This image will be displayed on the content list item in the client app.</span>
                                            </ReactTooltip>
                                        </Box>
                                    </Box>
                                    {/*<Box width="750px">*/}
                                    {/*    <Text>*/}
                                    {/*        Please select a brand approved image to display with your content. <br />*/}
                                    {/*    Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.*/}
                                    {/*    </Text>*/}
                                    {/*</Box>*/}
                                    <Box direction="row" gap="small">
                                        <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }} width={{ "min": "101px" }} fill={false} margin={{ "bottom": "small" }}>
                                            <FormField label="" error={this.image_err}>
                                                <Box direction="row" justify="center">
                                                    {this.state.newimage ?
                                                        <Box alignSelf="center">
                                                            {this.shortcut()}
                                                        </Box>
                                                        :
                                                        (eventdefault.imageUrl == "" || eventdefault.imageUrl == null) ?
                                                            <Box height="75px" justify="center">
                                                                <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} disabled={!canedit1} onClick={e => {
                                                                    const c = document.getElementById("real");
                                                                    c.click();
                                                                }} />
                                                            </Box>
                                                            :
                                                            <Box justify="center">
                                                                <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                <Image height="75" src={`${eventdefault.imageUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                    onClick={e => {
                                                                        const c = document.getElementById("real");
                                                                        c.click();
                                                                    }} />
                                                            </Box>
                                                    }
                                                </Box>
                                            </FormField>
                                        </StyledBox>
                                        <Box justify="center" margin={{ "bottom": "small" }}>
                                            <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={!canedit1 || ((eventdefault.imageUrl == "" || eventdefault.imageUrl == null) && !this.state.newimage)}>
                                                <Trash />
                                            </Button>
                                        </Box>
                                    </Box>
                                    {/*<Box width="150px" margin={{ "top": "small" }}>*/}
                                    {/*    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />*/}
                                    {/*    <StyledButton typeAdd label="Choose a file" disabled={!canedit1} onClick={e => {*/}
                                    {/*        const c = document.getElementById("real");*/}
                                    {/*        c.click();*/}
                                    {/*    }} />*/}
                                    {/*</Box>*/}
                                </Box>
                            </Box>}
                    </form>
                </Box>

            </Grid>

        );
    }
}


const mapStateToProps = (state, ownProps) => {
    //const eventId = parseInt(ownProps.match.params.eventId, 10) || 0; //from the path "/event/:eventId"
    const eventId = ownProps.match.params.eventId || ''; //from the path "/event/:eventId"
    //console.log("*DEBUG*-EV1- MapStatetoProps",JSON.stringify(state));
    //console.log("*DEBUG*-EV2- MapStatetoProps",JSON.stringify(ownProps));
    if (state.selectedEventReducer.eventdata && eventId == state.selectedEventReducer.eventdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedEventReducer.eventdata));
        return {
            eventdata: state.selectedEventReducer.eventdata,
            placemarksdata: state.placemarksReducer.placemarksdata,
            sitedata: state.selectedSiteReducer.sitedata,
            timezonesdata: state.timeZonesReducer.timezonesdata
        };
        /*} else if (state.selectedEventReducer.eventdata && eventId == 0) {
            let evt =  state.selectedEventReducer.eventdata;
            evt.id = 0;
            return {
                eventdata: evt,
            };*/
    }

    else {
        return {
            placemarksdata: state.placemarksReducer.placemarksdata,
            sitedata: state.selectedSiteReducer.sitedata
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...EventAction, ...PlacemarkAction, ...SiteAction, ...TimeZoneAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventEditContainer));
