import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Layer, Heading, Text, Grommet, Calendar, Tabs, Tab, Stack, DataTable, Anchor, RoutedAnchor } from 'grommet';
import { Plan, StatusGoodSmall, Workshop } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import { grommet } from 'grommet/themes';
import { SketchPicker } from 'react-color';
import _ from 'lodash';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import ReactTooltip from "react-tooltip";
import LoadingIndicator from './common/LoadingIndicator';
import { MapCustom } from '../grommet-extras/MapCustomIcon';
import { MsalContext } from "@azure/msal-react";


const PaginatedDataTable = withPagination(DataTable);

var start = undefined;

class Overview extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            showPickColor: false,
            color: "",
            cancel: false,
            isSaved: false,
            isSaveSuccessful: false,
            disabledButton: false
        };
        this.selectedDate = new Date().toISOString();

    }
    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        //const orgid = constants.organisation.id;
        //const orgid = sessionStorage.getItem("organisationId");
        //this.props.action.getOrganisationAction(orgid)
        //    .catch(error => {
        //        console.log('*DEBUG*-Organisation Error', error);
        //    });
    }
    componentWillUnmount() {
        this.historyUnblock();
    }
    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.organisationdata && !_.isEqual(start, this.props.organisationdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }
    handleSave() {
        const organisationdefault = this.props.organisationdata;
        let errorsno = 0;
        //validation
        if (errorsno === 0) {
            this.setState({ disabledButton: true });
            console.log('Saving: ', organisationdefault);
            this.props.action.saveOrganisationAction(organisationdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok});
                    //this.props.history.push('/orgsites');
                }).catch(error => {
                    console.log('*DEBUG*-Save AppDetails Error', error);
                    this.setState({ disabledButton: false });
                });

        } else {
            //this.onActive(error);
            this.setState({ validating: true });
        }
    }
    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.organisationdata)) {
            this.setState({ cancel: true });
        }
        else {
            this.props.action.getOrganisationAction(sessionStorage.getItem("organisationId"))
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push('/calendar');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Organisation Error', error);
                    this.props.history.push('/calendar');
                });
        }
    }
    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            //this.props.history.push('/orgsites');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    render() {
        if (false) {
            return (
                <LoadingIndicator/>
            );
        }
        return (
            <Grid
                fill={true}
                rows={['small', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header'
                    //direction="row"
                    align="center"
                    pad="medium"
                    justify="center"
                    background="#fafafa"
                >

                    <Heading level='2' >Hi, {this.context.accounts[0].name}</Heading>
                    <Text>Select the quick links below to get started, or get some <Text weight="bold" color={constants.HPE_GREEN}>Help</Text> using HPE Intelligent Spaces.</Text>
                    <Box direction="row" gap="small" >
                        {/*}
                        <StyledButton typeCancel label="Cancel" onClick={() => this.handleCancel()} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={()=>this.handleSave()} disabled={this.state.disabledButton} />
                        */}
                    </Box>
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <Box direction="row" justify="around" border="all" pad="medium">
                        <RoutedAnchor path={constants.getSiteLevelRoute() + "/bookspacemap"}>
                            <Box align="center">
                                <Workshop size="large" />
                                Book a Space
                        </Box>
                        </RoutedAnchor>

                        <RoutedAnchor path={constants.getSiteLevelRoute() + "/mybookings"}>
                            <Box align="center">
                                <Plan size="large"/>
                            My Bookings
                        </Box>
                        </RoutedAnchor>

                        <RoutedAnchor path={constants.getSiteLevelRoute() + "/meridianmap"}>
                            <Box align="center">
                                <MapCustom size="large"/>
                            Live Map
                        </Box>
                        </RoutedAnchor>
                    </Box>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    //const organisationId = constants.organisation.id;
    const organisationId = sessionStorage.getItem("organisationId");
    if (state.selectedOrganisationReducer.organisationdata && organisationId === state.selectedOrganisationReducer.organisationdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedOrganisationReducer.organisationdata));
        return {
            organisationdata: state.selectedOrganisationReducer.organisationdata,
        };
    } else {
        return {};
    }
};


const mapDispatchToProps = dispatch => ({
    action: bindActionCreators(OrganisationAction, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Overview));
