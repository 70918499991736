import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CategoryAction from '../action/CategoryAction';
import * as SiteAction from '../action/SiteAction';
import CategoryListTable from './CategoryListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';


export class CategoryListContainer extends React.Component {
    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        // this.state = { selectedCategory: 0, deletedCategoryId: 0, open: undefined };
        this.state = {
            selectedCategory: 0,
            deletedCategoryIds: [],
            open: undefined,
            categoriesdataLoading: true,
            sitesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    componentDidMount() {
        this.props.action.getCategoriesAction()
            .then(response => this.setState({ categoriesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Categories List Error', error);
            });
        this.props.action.getSiteAction(sessionStorage.getItem("locationId"))
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
    }

    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedCategory: nro });
        this.onOpen();
    }

    eventDelete(event) {
        const selectedId = this.state.selectedCategory;

        if (selectedId) {
            this.setState({ selectedCategory: 0, open: undefined, deletedCategoryIds: this.state.deletedCategoryIds.concat([selectedId]) });
            // this.setState({ deletedCategoryId: selectedId });
            this.props.action.deleteCategoryAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- Category List Delete Error', error);
                });
        }
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        this.props.history.push(constants.getSiteLevelRoute() + '/category/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push(constants.getSiteLevelRoute() + '/category/' + nro);
    }

    render() {
        const { categoriesdata, sitesdata } = this.props;
        // const { open, selectedCategory, deletedCategoryId } = this.state;
        const { open, selectedCategory, deletedCategoryIds, categoriesdataLoading, sitesdataLoading } = this.state;

        if (sitesdataLoading || categoriesdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }

        return (
            <Box flex justify='between' fill>
                <form>
                    {open && (
                        <Layer
                            position="center"
                            modal={true}
                            onClickOutside={this.onClose}
                            onEsc={this.onClose}
                        >
                            <Box pad="medium" gap="small" width="medium" >
                                <Heading level={3} margin="none">
                                    Confirm
                            </Heading>
                                <Text weight="bold">{constants.confirmMessage("category")}</Text>
                                <Text >Note any content associated with this category will need to be reconfigured. Pages, Quick Actions associated with this category will no longer be visible in the app.</Text>
                                <Box
                                    tag="footer"
                                    gap="small"
                                    direction="row"
                                    align="center"
                                    justify="end"
                                    pad={{ top: "medium", bottom: "small" }}

                                >
                                    <StyledButton typeCancel label="Cancel" onClick={this.onClose} />
                                    <StyledButton typeSave label="Confirm" onClick={this.eventDelete} />
                                </Box>
                            </Box>
                        </Layer>
                    )}

                    <CategoryListTable categoriesdata={categoriesdata} sitesdata={sitesdata} handleNew={this.handleNew} loading={categoriesdataLoading || sitesdataLoading}
                        handleDelete={this.handleDelete} handleEdit={this.handleEdit} deletedCategoryIds={deletedCategoryIds} />
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    categoriesdata: state.categoriesReducer.categoriesdata,
    sitesdata: state.selectedSiteReducer.sitedata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...CategoryAction, ...SiteAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(CategoryListContainer);
