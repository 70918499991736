import * as ActionType from './ActionType';
import AssetHistoryApi from '../api/AssetHistoryApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getPagesResponse = historiesdata => ({
    type: ActionType.GET_ASSETS_HISTORY_RESPONSE,
    historiesdata
});

const queuedGetAllPages = queueApiCall(AssetHistoryApi.getAllAssetHistories);
//const queuedGetAllMappedPages = queueApiCall(AssetHistoryApi.getAllMappedAssets);

export function getAssetHistoriesAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());
        return queuedGetAllPages()
            .then(pagesdata => {
                dispatch(getPagesResponse(pagesdata));
                return pagesdata;
            }).catch(error => {
                throw error;
            });
    };
}
//export function getMappedAssetsAction() {
//    return (dispatch) => {

//        dispatch(ApiCallBeginAction());

//        //return PageApi.getAllPages()
//        return queuedGetAllMappedPages()
//            .then(pagesdata => {
//                dispatch(getPagesResponse(pagesdata));
//            }).catch(error => {
//                throw error;
//            });
//    };
//}

export const addNewPageResponse = () => ({
    type: ActionType.ADD_NEW_ASSET_HISTORY_RESPONSE
});

export const updateExistingPageResponse = () => ({
    type: ActionType.UPDATE_EXISTING_ASSET_HISTORY_RESPONSE
});

export function saveAssetHistoryAction(pagedraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the page is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new page that is being added, therefore add it
        return AssetHistoryApi.saveAssetHistory(pagedraft)
            .then((response) => {
                if (pagedraft.id) {
                    dispatch(updateExistingPageResponse());
                } else {
                    dispatch(addNewPageResponse());
                }
                return response;
            }).then((response) => {
                //if (pagedraft.id != "0") {
                //    dispatch(getAssetHistoryAction(pagedraft.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getPageResponse = historydata => ({
    type: ActionType.GET_ASSET_HISTORY_RESPONSE,
    historydata
});

export function getAssetHistoryAction(pageId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return AssetHistoryApi.getAssetHistory(pageId)
            .then(pagedata => {
                dispatch(getPageResponse(pagedata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deletePageResponse = () => ({
    type: ActionType.DELETE_ASSET_HISTORY_RESPONSE
});

const queuedDeletePage = queueApiCall(AssetHistoryApi.deleteAsset);

export function deleteAssetAction(pageId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return PageApi.deletePage(pageId)
        return queuedDeletePage(pageId)
            .then((response) => {
                dispatch(deletePageResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getAssetHistoriesAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}