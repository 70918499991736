import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedCalendarReducer = (state = initialState.selectedCalendarReducer, action) => {
    switch(action.type) {

        case ActionType.GET_CALENDAR_RESPONSE: {
            return {
                ...state,
                calendardata: _.assign(action.calendardata)
            };
        }

        default: { return state; }
    }
};


export default selectedCalendarReducer;