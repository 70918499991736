import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken, getAllRecords } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

const SERVER_URL = `${SERVER}/tables/ChargifiSpot`;
/*const API_NAME = "SpotsApi";*/

class SpotsApi {
    static async getAllSpots() {
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}/?&$count=true&$top=0&${API_VERSION}?$expand=State,Surface($expand=Zone($expand=Venue))`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                return getAllRecords(`${SERVER_URL}/?$expand=State,Surface($expand=Zone($expand=Venue))&${API_VERSION}`, options, data.count)
                    .catch(error => {
                        throw error;
                    });
            })
            .catch(error => {
                throw error;
            });
    }
}

//function handleFetchErrors(response) {
//    if (!response.ok) {
//        throw new Error(`${API_NAME}: ${response.statusText}`);
//    }
//    return response;
//}

export default SpotsApi;
