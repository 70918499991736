import React from "react";
import { Box, Layer, Button, Text, Heading } from 'grommet';
import { StyledBox } from './StyledBox';
import { StyledButton } from './StyledButton';

//const PopUp = ({
//    open: Open,
//    //component: Component,
//    //objprinc: permtype,
//    ...rest
//}) => {
//    if (Open) {
//        return (
//            <Layer
//                position="center"
//                modal={true}
//                onClickOutside={() => this.onClose()}
//                onEsc={() => this.onClose()}
//            >
//                <Box pad="medium" gap="small" width="medium" >
//                    <Heading level={3} margin="none">
//                        Saving
//                            </Heading>
//                    <Text>Save was successful.</Text>
//                    <Box
//                        tag="footer"
//                        gap="small"
//                        direction="row"
//                        align="center"
//                        justify="end"
//                        pad={{ top: "medium", bottom: "small" }}

//                    >
//                        <StyledButton typeSave label="Ok" onClick={() => this.onClose()} />
//                    </Box>
//                </Box>
//            </Layer>
//        );
//    }
//    else {
//        return null;
//    }

//};
//export default PopUp;
export default class PopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: this.props.open };
    }
    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({ open: this.props.open });
        }
    }
    //onClose() {
    //    this.setState({ open: false });
    //    //this.props.history.push(this.props.to);
    //    if (this.props.Push) {
    //        this.props.Push();
    //    }
    //}
    renderText() {
        let message = "Save was successful.";
        if (this.props.text) {
            message = this.props.text;
        }
        else if (this.props.error) {
            message = "Something went wrong.";
        }
        return message;
    }

    render() {
        let { open } = this.state;
        //console.log("Message from Pop Up:",this.renderText());
        if (open) {
            return (
                <Layer
                    responsive={false}
                    position="center"
                    modal={true}
                    onClickOutside={() => this.props.onClose()}
                    onEsc={() => this.props.onClose()}
                >
                    <Box pad="medium" gap="small" width={this.props.createOrg? "large" : "medium"} >
                        <Heading level={3} margin="none">
                            {this.props.header || "Saving"}
                        </Heading>
                        <Text>
                            {this.props.createOrg && !this.props.error ? <Box>
                                You have chosen to create a new organization called {this.props.orgName}.
                                <br/>
                                Once confirmed, please ensure you complete the Org set up by:
                            < ul >
                                    <li> Configuring Settings, Integrations and Sites</li>
                                    <li> Adding users </li>
                                    <li> Completing App details such as App Settings, Theme and Legal Information</li>
                                </ul>
                            </Box>
                                :
                                this.renderText()
                            }
                        </Text>
                        <Box
                            tag="footer"
                            gap="small"
                            direction="row"
                            align="center"
                            justify="end"
                            pad={{ top: "medium", bottom: "small" }}

                        >
                            {this.props.error ? (
                                <StyledButton typeCancel label="Ok" onClick={() => this.props.onClose()} />) : (
                                    <StyledButton typeSave label={this.props.createOrg? "Confirm":"Ok"} onClick={() => this.props.onClose()} />)}

                        </Box>
                    </Box>
                </Layer>
            );
        }
        else {
            return null;
        }
    }
}
