export const GET_EVENTS_RESPONSE = 'GET_EVENTS_RESPONSE';
export const GET_EVENT_RESPONSE = 'GET_EVENT_RESPONSE';
export const ADD_NEW_EVENT_RESPONSE = 'ADD_NEW_EVENT_RESPONSE';
export const UPDATE_EXISTING_EVENT_RESPONSE = 'UPDATE_EXISTING_EVENT_RESPONSE';
export const DELETE_EVENT_RESPONSE = 'DELETE_EVENT_RESPONSE';

export const GET_QUICKACTIONS_RESPONSE = 'GET_QUICKACTIONS_RESPONSE';
export const GET_QUICKACTION_RESPONSE = 'GET_QUICKACTION_RESPONSE';
export const ADD_NEW_QUICKACTION_RESPONSE = 'ADD_NEW_QUICKACTION_RESPONSE';
export const UPDATE_EXISTING_QUICKACTION_RESPONSE = 'UPDATE_EXISTING_QUICKACTION_RESPONSE';
export const DELETE_QUICKACTION_RESPONSE = 'DELETE_QUICKACTION_RESPONSE';

export const GET_CATEGORIES_RESPONSE = 'GET_CATEGORIES_RESPONSE';
export const GET_CATEGORY_RESPONSE = 'GET_CATEGORY_RESPONSE';
export const ADD_NEW_CATEGORY_RESPONSE = 'ADD_NEW_CATEGORY_RESPONSE';
export const UPDATE_EXISTING_CATEGORY_RESPONSE = 'UPDATE_EXISTING_CATEGORY_RESPONSE';
export const DELETE_CATEGORY_RESPONSE = 'DELETE_CATEGORY_RESPONSE';

export const GET_PAGES_RESPONSE = 'GET_PAGES_RESPONSE';
export const GET_PAGE_RESPONSE = 'GET_PAGE_RESPONSE';
export const ADD_NEW_PAGE_RESPONSE = 'ADD_NEW_PAGE_RESPONSE';
export const UPDATE_EXISTING_PAGE_RESPONSE = 'UPDATE_EXISTING_PAGE_RESPONSE';
export const DELETE_PAGE_RESPONSE = 'DELETE_PAGE_RESPONSE';

export const GET_USERS_RESPONSE = 'GET_USERS_RESPONSE';
export const GET_USER_RESPONSE = 'GET_USER_RESPONSE';
export const ADD_NEW_USER_RESPONSE = 'ADD_NEW_USER_RESPONSE';
export const UPDATE_EXISTING_USER_RESPONSE = 'UPDATE_EXISTING_USER_RESPONSE';
export const UPDATE_SUPER_USER_RESPONSE = 'UPDATE_SUPER_USER_RESPONSE';
export const DELETE_USER_RESPONSE = 'DELETE_USER_RESPONSE';

export const GET_ROLES_RESPONSE = 'GET_ROLES_RESPONSE';
export const GET_ROLE_RESPONSE = 'GET_ROLE_RESPONSE';
export const ADD_NEW_ROLE_RESPONSE = 'ADD_NEW_ROLE_RESPONSE';
export const UPDATE_EXISTING_ROLE_RESPONSE = 'UPDATE_EXISTING_ROLE_RESPONSE';
export const DELETE_ROLE_RESPONSE = 'DELETE_ROLE_RESPONSE';

export const GET_USERS_PREFERENCES_RESPONSE = 'GET_USERS_PREFERENCES_RESPONSE';
export const GET_USER__PREFERENCES_RESPONSE = 'GET_USER__PREFERENCES_RESPONSE';
export const ADD_NEW_USER_PREFERENCES_RESPONSE = 'ADD_NEW_USER_PREFERENCES_RESPONSE';
export const UPDATE_EXISTING_USER_PREFERENCES_RESPONSE = 'UPDATE_EXISTING_USER_PREFERENCES_RESPONSE';
export const DELETE_USER_PREFERENCES_RESPONSE = 'DELETE_USER_PREFERENCES_RESPONSE';

export const GET_PLACEMARKS_RESPONSE = 'GET_PLACEMARKS_RESPONSE';
export const GET_PLACEMARK_RESPONSE = 'GET_PLACEMARK_RESPONSE';
export const ADD_NEW_PLACEMARK_RESPONSE = 'ADD_NEW_PLACEMARK_RESPONSE';
export const UPDATE_EXISTING_PLACEMARK_RESPONSE = 'UPDATE_EXISTING_PLACEMARK_RESPONSE';
export const DELETE_PLACEMARK_RESPONSE = 'DELETE_PLACEMARK_RESPONSE';

export const GET_LOCATIONS_RESPONSE = 'GET_LOCATIONS_RESPONSE';

export const GET_ORGANISATIONS_RESPONSE = 'GET_ORGANISATIONS_RESPONSE';
export const GET_ORGANISATION_RESPONSE = 'GET_ORGANISATION_RESPONSE';
export const ADD_NEW_ORGANISATION_RESPONSE = 'ADD_NEW_ORGANISATION_RESPONSE';
export const UPDATE_EXISTING_ORGANISATION_RESPONSE = 'UPDATE_EXISTING_ORGANISATION_RESPONSE';
export const DELETE_ORGANISATION_RESPONSE = 'DELETE_ORGANISATION_RESPONSE';

export const GET_SITES_RESPONSE = 'GET_SITES_RESPONSE';
export const GET_SITE_RESPONSE = 'GET_SITE_RESPONSE';
export const ADD_NEW_SITE_RESPONSE = 'ADD_NEW_SITE_RESPONSE';
export const UPDATE_EXISTING_SITE_RESPONSE = 'UPDATE_EXISTING_SITE_RESPONSE';
export const DELETE_SITE_RESPONSE = 'DELETE_SITE_RESPONSE';

export const GET_BUILDINGS_RESPONSE = 'GET_BUILDINGS_RESPONSE';

export const GET_SPACES_RESPONSE = 'GET_SPACES_RESPONSE';

export const GET_LEVELS_RESPONSE = 'GET_LEVELS_RESPONSE';

export const GET_BEACONS_RESPONSE = 'GET_BEACONS_RESPONSE';
export const GET_BEACON_RESPONSE = 'GET_BEACON_RESPONSE';
export const GET_BEACONS_SEARCH_RESPONSE = 'GET_BEACONS_SEARCH_RESPONSE';
export const UPDATE_EXISTING_BEACON_RESPONSE = 'UPDATE_BEACON_SITE_RESPONSE';

export const GET_SPOTS_RESPONSE = 'GET_SPOTS_RESPONSE';

export const GET_OVERVIEWS_RESPONSE = 'GET_OVERVIEWS_RESPONSE';

export const GET_TIME_ZONE_RESPONSE = 'GET_TIME_ZONE_RESPONSE';

export const GET_PROFILES_RESPONSE = 'GET_PROFILES_RESPONSE';
export const GET_PROFILE_RESPONSE = 'GET_PROFILE_RESPONSE';
export const ADD_NEW_PROFILE_RESPONSE = 'ADD_NEW_PROFILE_RESPONSE';
export const UPDATE_EXISTING_PROFILE_RESPONSE = 'UPDATE_EXISTING_PROFILE_RESPONSE';
export const DELETE_PROFILE_RESPONSE = 'DELETE_PROFILE_RESPONSE';

export const GET_APPS_RESPONSE = 'GET_APPS_RESPONSE';
export const GET_APP_RESPONSE = 'GET_APP_RESPONSE';
export const ADD_NEW_APP_RESPONSE = 'ADD_NEW_APP_RESPONSE';
export const UPDATE_EXISTING_APP_RESPONSE = 'UPDATE_EXISTING_APP_RESPONSE';
export const DELETE_APP_RESPONSE = 'DELETE_APP_RESPONSE';

export const GET_MASTERAPPS_RESPONSE = 'GET_MASTERAPPS_RESPONSE';
export const GET_MASTERAPP_RESPONSE = 'GET_MASTERAPP_RESPONSE';
export const ADD_NEW_MASTERAPP_RESPONSE = 'ADD_NEW_MASTERAPP_RESPONSE';
export const UPDATE_EXISTING_MASTERAPP_RESPONSE = 'UPDATE_EXISTING_MASTERAPP_RESPONSE';
export const DELETE_MASTERAPP_RESPONSE = 'DELETE_MASTERAPP_RESPONSE';

export const GET_FORMS_RESPONSE = 'GET_FORMS_RESPONSE';
export const GET_FORM_RESPONSE = 'GET_FORM_RESPONSE';
export const ADD_NEW_FORM_RESPONSE = 'ADD_NEW_FORM_RESPONSE';
export const UPDATE_EXISTING_FORM_RESPONSE = 'UPDATE_EXISTING_FORM_RESPONSE';
export const DELETE_FORM_RESPONSE = 'DELETE_FORM_RESPONSE';

export const GET_ASSETS_RESPONSE = 'GET_ASSETS_RESPONSE';
export const GET_ASSET_RESPONSE = 'GET_ASSET_RESPONSE';
export const ADD_NEW_ASSET_RESPONSE = 'ADD_NEW_ASSET_RESPONSE';
export const UPDATE_EXISTING_ASSET_RESPONSE = 'UPDATE_EXISTING_ASSET_RESPONSE';
export const DELETE_ASSET_RESPONSE = 'DELETE_ASSET_RESPONSE';

export const GET_ASSETS_HISTORY_RESPONSE = 'GET_ASSETS_HISTORY_RESPONSE';
export const GET_ASSET_HISTORY_RESPONSE = 'GET_ASSET_HISTORY_RESPONSE';
export const ADD_NEW_ASSET_HISTORY_RESPONSE = 'ADD_NEW_ASSET_HISTORY_RESPONSE';
export const UPDATE_EXISTING_ASSET_HISTORY_RESPONSE = 'UPDATE_EXISTING_ASSET_HISTORY_RESPONSE';
export const DELETE_ASSET_HISTORY_RESPONSE = 'DELETE_ASSET_HISTORY_RESPONSE';

export const GET_ASSETS_OVERVIEW_RESPONSE = 'GET_ASSETS_OVERVIEW_RESPONSE';

export const GET_ASSET_LABELS_RESPONSE = 'GET_ASSET_LABELS_RESPONSE';
export const GET_ASSET_LABEL_RESPONSE = 'GET_ASSET_LABEL_RESPONSE';
export const ADD_NEW_ASSET_LABEL_RESPONSE = 'ADD_NEW_ASSET_LABEL_RESPONSE';
export const UPDATE_EXISTING_ASSET_LABEL = 'UPDATE_EXISTING_ASSET_LABEL';
export const DELETE_ASSET_LABEL_RESPONSE = 'DELETE_ASSET_LABEL_RESPONSE';

export const GET_NOTIFICATIONS_RESPONSE = 'GET_NOTIFICATIONS_RESPONSE';
export const GET_NOTIFICATION_RESPONSE = 'GET_NOTIFICATION_RESPONSE';
export const ADD_NEW_NOTIFICATION_RESPONSE = 'ADD_NEW_NOTIFICATION_RESPONSE';
export const UPDATE_EXISTING_NOTIFICATION_RESPONSE = 'UPDATE_EXISTING_NOTIFICATION_RESPONSE';
export const DELETE_NOTIFICATION_RESPONSE = 'DELETE_NOTIFICATION_RESPONSE';

export const GET_MERGEDLEVELS_RESPONSE = 'GET_MERGEDLEVELS_RESPONSE';
export const GET_MERGEDLEVEL_RESPONSE = 'GET_MERGEDLEVEL_RESPONSE';
export const UPDATE_EXISTING_MERGEDLEVEL_RESPONSE = 'UPDATE_EXISTING_MERGEDLEVEL_RESPONSE';

export const GET_MERGEDBUILDINGS_RESPONSE = 'GET_MERGEDBUILDINGS_RESPONSE';
export const GET_MERGEDBUILDING_RESPONSE = 'GET_MERGEDBUILDING_RESPONSE';
export const UPDATE_EXISTING_MERGEDBUILDING_RESPONSE = 'UPDATE_EXISTING_MERGEDBUILDING_RESPONSE';

export const GET_PLACEMARKS_HISTORY_RESPONSE = 'GET_PLACEMARKS_HISTORY_RESPONSE';
export const GET_PLACEMARK_HISTORY_RESPONSE = 'GET_PLACEMARK_HISTORY_RESPONSE';

export const GET_CALENDARHISTORIES_RESPONSE = 'GET_CALENDARHISTORIES_RESPONSE';

export const GET_DEPARTMENTS_RESPONSE = 'GET_DEPARTMENTS_RESPONSE';
export const GET_DEPARTMENT_RESPONSE = 'GET_DEPARTMENT_RESPONSE';
export const ADD_NEW_DEPARTMENT_RESPONSE = 'ADD_NEW_DEPARTMENT_RESPONSE';
export const UPDATE_EXISTING_DEPARTMENT_RESPONSE = 'UPDATE_EXISTING_DEPARTMENT_RESPONSE';
export const DELETE_DEPARTMENT_RESPONSE = 'DELETE_DEPARTMENT_RESPONSE';

export const GET_CALENDARS_RESPONSE = 'GET_CALENDARS_RESPONSE';
export const GET_CALENDAR_RESPONSE = 'GET_CALENDAR_RESPONSE';
export const ADD_NEW_CALENDAR_RESPONSE = 'ADD_NEW_CALENDAR_RESPONSE';
export const UPDATE_EXISTING_CALENDAR_RESPONSE = 'UPDATE_EXISTING_CALENDAR_RESPONSE';
export const DELETE_CALENDAR_RESPONSE = 'DELETE_CALENDAR_RESPONSE';
export const GET_TRACKERS_RESPONSE = 'GET_TRACKERS_RESPONSE';
export const GET_TRACKER_RESPONSE = 'GET_TRACKER_RESPONSE';
export const ADD_NEW_TRACKER_RESPONSE = 'ADD_NEW_TRACKER_RESPONSE';
export const UPDATE_EXISTING_TRACKER_RESPONSE = 'UPDATE_EXISTING_TRACKER_RESPONSE';
export const DELETE_TRACKER_RESPONSE = 'DELETE_TRACKER_RESPONSE';


export const GET_COLLECTIONS_RESPONSE = 'GET_COLLECTIONS_RESPONSE';
export const GET_COLLECTION_RESPONSE = 'GET_COLLECTION_RESPONSE';
export const ADD_NEW_COLLECTION_RESPONSE = 'ADD_NEW_COLLECTION_RESPONSE';
export const UPDATE_EXISTING_COLLECTION_RESPONSE = 'UPDATE_EXISTING_COLLECTION_RESPONSE';
export const DELETE_COLLECTION_RESPONSE = 'DELETE_COLLECTION_RESPONSE';

export const GET_ASSOCIATIONS_RESPONSE = 'GET_ASSOCIATIONS_RESPONSE';
export const GET_ASSOCIATION_RESPONSE = 'GET_ASSOCIATION_RESPONSE';
export const ADD_NEW_ASSOCIATION_RESPONSE = 'ADD_NEW_ASSOCIATION_RESPONSE';
export const UPDATE_EXISTING_ASSOCIATION_RESPONSE = 'UPDATE_EXISTING_ASSOCIATION_RESPONSE';
export const DELETE_ASSOCIATION_RESPONSE = 'DELETE_ASSOCIATION_RESPONSE';

export const GET_MANUFACTURERS_RESPONSE = 'GET_MANUFACTURERS_RESPONSE';
export const GET_MANUFACTURER_RESPONSE = 'GET_MANUFACTURER_RESPONSE';
export const ADD_NEW_MANUFACTURER_RESPONSE = 'ADD_NEW_MANUFACTURER_RESPONSE';
export const UPDATE_EXISTING_MANUFACTURER_RESPONSE = 'UPDATE_EXISTING_MANUFACTURER_RESPONSE';
export const DELETE_MANUFACTURER_RESPONSE = 'DELETE_MANUFACTURER_RESPONSE';

export const GET_MODELS_RESPONSE = 'GET_MODELS_RESPONSE';
export const GET_MODEL_RESPONSE = 'GET_MODEL_RESPONSE';
export const ADD_NEW_MODEL_RESPONSE = 'ADD_NEW_MODEL_RESPONSE';
export const UPDATE_EXISTING_MODEL_RESPONSE = 'UPDATE_EXISTING_MODEL_RESPONSE';
export const DELETE_MODEL_RESPONSE = 'DELETE_MODEL_RESPONSE';

export const GET_TYPES_RESPONSE = 'GET_TYPES_RESPONSE';
export const GET_TYPE_RESPONSE = 'GET_TYPE_RESPONSE';
export const ADD_NEW_TYPE_RESPONSE = 'ADD_NEW_TYPE_RESPONSE';
export const UPDATE_EXISTING_TYPE_RESPONSE = 'UPDATE_EXISTING_TYPE_RESPONSE';
export const DELETE_TYPE_RESPONSE = 'DELETE_TYPE_RESPONSE';

export const GET_METADATAS_RESPONSE = 'GET_METADATAS_RESPONSE';
export const GET_METADATA_RESPONSE = 'GET_METADATA_RESPONSE';
export const ADD_NEW_METADATA_RESPONSE = 'ADD_NEW_METADATA_RESPONSE';
export const UPDATE_EXISTING_METADATA_RESPONSE = 'UPDATE_EXISTING_METADATA_RESPONSE';
export const DELETE_METADATA_RESPONSE = 'DELETE_METADATA_RESPONSE';

export const API_CALL_BEGIN = 'API_CALL_BEGIN';
export const API_CALL_ERROR = 'API_CALL_ERROR';
