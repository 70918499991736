import React, { Component } from 'react';
import { Box, CheckBox } from 'grommet';
class ReactiveCheckBoxes extends Component {
    constructor(props) {
        super(props);
        this.state = { checked: this.props.checkboxes.default };
    }
    componentDidUpdate() {
        this.props.onUpdate(this.state.checked);
    }

    onCheckAll = event => {
        if (event.target.checked) {
            this.setState({ checked: [...this.props.checkboxes.boxes] });
        } else {
            this.setState({ checked: [] });
        }
    };

    onCheck = (event, value) => {
        if (event.target.checked) {
            this.setState({ checked: [...this.state.checked, value] });
        } else {
            this.setState({ checked: this.state.checked.filter(item => item !== value) });
        }
    };

    render() {
        const checkboxes = this.props.checkboxes.boxes;
        return (
            <Box align="center" pad="none">
                <Box direction="row" gap="medium">
                    <CheckBox
                        checked={this.state.checked.length === this.props.checkboxes.boxes.length}
                        indeterminate={this.state.checked.length > 0 && this.state.checked.length < this.props.checkboxes.boxes.length} //interminate
                        label="All"
                        onChange={this.onCheckAll}
                    />
                    {checkboxes.map(item => (
                        <CheckBox
                            key={item}
                            checked={this.state.checked.includes(item)}
                            label={item}
                            onChange={e => this.onCheck(e, item)}
                        />
                    ))}
                </Box>
            </Box>
        );
    }
}
export default ReactiveCheckBoxes;