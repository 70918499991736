import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as BeaconAction from '../action/BeaconAction';
import * as SpotsAction from '../action/SpotsAction';
import SpotsListTable from './SpotsListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text } from 'grommet';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';


export class SpotsListContainer extends React.Component {

    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            open: undefined,
            spotsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }

    componentDidMount() {
        this.props.action.getSpotsAction()
            .then(response => this.setState({ spotsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Spots List Error', error);
            });
    }

    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.onOpen();
    }

    eventDelete(event) {
        console.log('*Delete* started on ');
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
    }

    render() {
        const { spotsdata } = this.props;
        const { spotsdataLoading } = this.state;

        if (spotsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }
        console.log("Spots:", spotsdata);

        return (
            <Box flex justify='between' fill>
                <form>

                    <SpotsListTable spotsdata={spotsdata} handleNew={this.handleNew}
                        handleDelete={this.handleDelete} handleEdit={this.handleEdit} />
                </form>
            </Box>
        );
    }
}



const mapStateToProps = state => ({
    spotsdata: state.spotsReducer.spotsdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SpotsAction }, dispatch),
});



export default connect(mapStateToProps, mapDispatchToProps)(SpotsListContainer);
