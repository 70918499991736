import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeaders, myHeadersWithToken, getAllRecords } from './ApiUtils';
const API_VERSION = constants.API_VERSION;

const SERVER_URL = `${SERVER}/tables/AssetLabel`;
console.log('HOST:', SERVER_URL);

class AssetLabelApi {
    static async getAllAssetLabels(areCollectionsIncluded = false) {
        const options = {
            method: 'get',
            headers: await myHeadersWithToken(areCollectionsIncluded)
        };
        return fetch(`${SERVER_URL}/?$count=true&$top=0&${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .then(data => {
                return getAllRecords(`${SERVER_URL}/?${API_VERSION}`, options, data.count)
                    .catch(error => {
                        throw error;
                    });
            })
            .catch(error => {
                throw error;
            });
    }

    static async saveAssetLabel(labeldata) {
        if (labeldata.id && labeldata.id != "0") {
            return fetch(`${SERVER_URL}/${labeldata.id}/?${API_VERSION}`, {
                method: 'put',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(labeldata)
            }).then(response => response);
        } else {
            return fetch(`${SERVER_URL}/?${API_VERSION}`, {
                method: 'post',
                headers: await myHeadersWithToken(),
                body: JSON.stringify(labeldata)
            }).then(response => response);
        }
    }

    static async deleteAssetLabel(labelId) {
        const options = {
            method: 'delete',
            headers: await myHeadersWithToken()
        };
        return fetch(`${SERVER_URL}/${labelId}/?${API_VERSION}`, options)
            .then(response => response);
    }

    static async getAssetLabel(labelId) {
        console.log('API:', labelId);
        const options = {
            method: 'get',
            headers: await myHeadersWithToken()
        }
        return fetch(`${SERVER_URL}/${labelId}/?${API_VERSION}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }
}

export default AssetLabelApi;
