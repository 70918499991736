import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CollectionAction from '../action/CollectionAction';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Search, StatusGoodSmall } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, TextArea, Layer } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import { SketchPicker } from 'react-color';
import ReactTooltip from 'react-tooltip';

var start = undefined;

export class CollectionEditContainer extends React.Component {
    constructor() {
        super();
        this.parentfull = [];
        this.state = {
            options: [],
            selected: "",
            location: "",
            isSaved: false,
            open: false,
            notification: '',
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            color: "#dadada",
            collectiondataLoading: true,
            collectionsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Validating errors
    collectionName_err = '';
    description_err = '';

    ClearErrorMessages() {
        this.collectionName_err = '';
        this.description_err = '';
    }
    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.collectionid;
        this.props.action.getCollectionAction(id)
            .then(response => this.setState({ collectiondataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Collection Edit Error', error);
            });
        this.props.action.getCollectionsAction()
            .then(response => this.setState({ collectionsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Collection List Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && !_.isEqual(start, this.props.collectiondata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    handleSave() {
        const collectiondefault = this.props.collectiondata;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ validating: false });
        if (collectiondefault.name.trim().length == 0) {
            errorsno++;
            this.collectionName_err = "Collection Name is required";
        }
        if (collectiondefault.name.length > 30) {
            errorsno++;
            this.collectionName_err = "Collection Name can't be longer than 30 character";
        }
        if (this.props.collectionsdata[0] && this.props.collectionsdata[0].count != 0 && this.props.collectionsdata.filter(item => item.id != collectiondefault.id).map(t => t.name.toLowerCase()).includes(collectiondefault.name.toLowerCase().trim())) {
            errorsno++;
            this.collectionName_err = "Collection Name already exists";
        }
        if (collectiondefault.name != "" && !constants.REGEX.COLLECTION.test(collectiondefault.name)) {
            errorsno++;
            this.collectionName_err = "Collection Name is invalid";
        }
        if (this.isCssAlreadyExist(collectiondefault.labelName)) {
            errorsno++;
            this.collectionName_err = "Collection Name CSS already exists";
        }
        if (errorsno == 0) {
            // Ok, saving data
            this.setState({ disabledButton: true });
            console.log('Save: OK', collectiondefault);
            this.props.action.saveCollectionAction(collectiondefault)
                .then((response) => {
                    this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                    //this.props.history.push('/categories');
                }).catch(error => {
                    console.log('*DEBUG*-Save CollectionEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.collectiondata)) {
            this.setState({ cancel: true });
        }
        else {
            const Id = this.props.collectiondata.id;
            this.props.action.getCollectionAction(Id)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        //this.props.history.push('/orgcollections');
                        if (sessionStorage.getItem("organisationId"))
                            this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/orgcollections');
                        else
                            this.props.history.push('/orgcollections');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-CollectiontEdit Error', error);
                    //this.props.history.push('/orgcollections');
                    if (sessionStorage.getItem("organisationId"))
                        this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/orgcollections');
                    else
                        this.props.history.push('/orgcollections');
                });
        }
    }
    updateNameValue(value) {
        this.props.collectiondata.name = value;
        if (value.length > 30) {
            this.collectionName_err = "Collection Name can't be longer than 30 character";
        }
        if (this.props.collectionsdata[0] && this.props.collectionsdata[0].count != 0 && this.props.collectionsdata.filter(item => item.id != this.props.collectiondata.id).map(t => t.name.toLowerCase()).includes(value.toLowerCase().trim())) {
            this.collectionName_err = "Collection Name already exists";
        }
        else {
            this.collectionName_err = "";
        }

        /// Generate labelName  ///
        this.props.collectiondata.labelName = "";
        if (value != "") {
            [...value].forEach((item, i) => {
                if (constants.REGEX.NONWORD.test(item)) {
                    this.props.collectiondata.labelName += "-";
                } else {
                    this.props.collectiondata.labelName += item;
                }
            });
            this.props.collectiondata.labelName += "_sys";
        }
        /// Generate labelName end ///

        if (this.isCssAlreadyExist(this.props.collectiondata.labelName)) {
            this.collectionName_err = "Collection Name CSS already exists";
        }

        if (value != "" && !constants.REGEX.COLLECTION.test(value)) {
            this.collectionName_err = "Collection Name is invalid";
        }

        this.setState({ validating: !this.state.validating });
    }
    updateDescription(evt) {
        this.props.collectiondata.description = evt.target.value;
    }
    handlePicker = () => {
        this.setState({ showPickColor: !this.state.showPickColor });
    }
    handleChangeSComplete = (color) => {
        this.props.collectiondata.hexColor = color.hex;
        //this.primaryColor_err = "";
        this.setState({ color: color.hex });
    }
    onClose() {
        this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            if (sessionStorage.getItem("organisationId"))
                this.props.history.push('/' + sessionStorage.getItem("organisationId") + '/orgcollections');
            else
                this.props.history.push('/orgcollections');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }

    isCssAlreadyExist(labelName) {
        return this.props.collectionsdata.filter(item => item.id != this.props.collectiondata.id).map(t => t.labelName.toLowerCase()).includes(labelName.toLowerCase().trim())
    }

    render() {
        const { collectiondata } = this.props;
        const { collectiondataLoading, collectionsdataLoading } = this.state;

        if (collectiondataLoading || collectionsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        const heading = collectiondata && /*(categorydefault.id != "0")*/ this.props.match.params.collectionid != 0 ? 'Edit' : 'Create';
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Collection - {heading} </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" style={{ minHeight: "500px" }}>
                    <form>
                        <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Warning, unable to complete link due to a configuration issue, please contact your admin."} error={!this.state.isSaveSuccessful} />
                        <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                        {/* <form> */}
                        <Box pad={{ vertical: "small" }}> <Text>All fields marked with an * are mandatory.</Text>
                            <Text size="large">Description</Text>
                        </Box>
                        {/* <form> */}
                        <StyledBox style1 pad={{ "horizontal": "small" }}>
                            <FormField label="Name *" error={this.collectionName_err}>
                                <TextInput defaultValue={collectiondata.name} placeholder="Collection Name" onChange={evt => this.updateNameValue(evt.target.value)}
                                    suggestions={this.props.collectionsdata.filter(d => d.name.toLowerCase().includes(this.props.collectiondata.name.toLowerCase())).map(c => c.name)}
                                    onSuggestionSelect={evt => this.updateNameValue(evt.suggestion)} />
                            </FormField>

                            <FormField label="Name CSS" /*error={this.collectionName_err}*/ >
                                <TextInput value={collectiondata.labelName} placeholder="Collection Name CSS" disabled />
                            </FormField>

                            <FormField label="Description" error={this.description_err}>
                                <TextArea defaultValue={collectiondata.description} onChange={v => this.updateDescription(v)} />
                            </FormField>
                            <FormField label="Color" error={this.secondaryColor_err} width="300px">
                                <TextInput data-for={collectiondata.id} data-tip icon={< Box round height="23px" background={collectiondata.hexColor ? collectiondata.hexColor : "white"} width="23px" border style={{ "border-width": "2px" }} />} reverse readOnly={true} value={collectiondata.hexColor} onClick={() => this.handlePicker()} style={{ cursor: "pointer" }} />
                            </FormField>
                            <ReactTooltip id={collectiondata.id} place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                Collections will be grouped by colour for easy identification on the map
                            </ReactTooltip>
                            <Box>
                                {this.state.showPickColor && (
                                    <Layer
                                        onClickOutside={this.handlePicker} onEsc={this.handlePicker} plain={false}>
                                        <SketchPicker
                                            color={collectiondata.hexColor}
                                            disableAlpha={true}
                                            onChangeComplete={this.handleChangeSComplete} />
                                    </Layer>)}
                            </Box>
                        </StyledBox>

                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const Id = ownProps.match.params.collectionid || '';
    if (state.selectedCollectionReducer.collectiondata && Id == state.selectedCollectionReducer.collectiondata.id) {
        start = { ...state.selectedCollectionReducer.collectiondata };
        return {
            collectiondata: state.selectedCollectionReducer.collectiondata,
            collectionsdata: state.collectionsReducer.collectionsdata
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...CollectionAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CollectionEditContainer));