import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SiteAction from '../action/SiteAction';
import * as OrganisationAction from '../action/OrganisationAction';
import * as TimeZoneAction from '../action/TimeZoneAction';
// eslint-disable-next-line
import { CircleInformation } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, CheckBox } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import ReactTooltip from "react-tooltip";
import * as update from 'react-addons-update';


var start = undefined;
var base64 = null;

export class ManageSiteSettings extends React.Component {
    constructor() {
        super();
        this.stamp = Date.now().toString();
        this.state = {
            newimage: false,
            imagechanged: false,
            location: "",
            isSaved: false,
            open: false,
            notification: '',
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            sitedataLoading: true,
            sitesdataLoading: true,
            organisationdataLoading: true,
            organisationsdataLoading: true,
            timezonesdataLoading: true,
            orgSelected: false,
            openFeautresPopUp: false,
            openSaveWarning: false,
            featureChanged: false,
            selectedOrganization: "",
            regexp: "",
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Validating errors
    siteName_err = '';
    siteCode_err = '';
    timeZone_err = '';

    ClearErrorMessages() {
        this.siteName_err = '';
        this.siteCode_err = '';
        this.timeZone_err = '';
    }

    componentDidMount() {
        //this.setState({ sitedataLoading: true, sitesdataLoading: true, organisationdataLoading: true, organisationsdataLoading: true })
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        if (this.props.match.params.id != "0") {
            this.props.action.getSiteAction(this.props.match.params.id)
                .then(response => this.setState({ sitedataLoading: false }))
                .catch(error => {
                    var errorArray = this.state.apiErrorList.concat(error);
                    this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                    console.log('*DEBUG*- Site Edit Error', error);
                });
            this.props.action.getOrganisationAction(sessionStorage.getItem("organisationId"))
                .then(response => this.setState({ organisationdataLoading: false }))
                .catch(error => {
                    var errorArray = this.state.apiErrorList.concat(error);
                    this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                    console.log('*DEBUG*-Organisation Error', error);
                });
        }
        this.props.action.getSitesAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Site Edit Error', error);
            });
        this.props.action.getOrganisationsAction()
            .then(response => this.setState({ organisationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
        this.props.action.getTimeZonesAction()
            .then(response => this.setState({ timezonesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.setState({ updated: true });
    }

    componentWillReceiveProps(props) {
        const sitedefault = props.sitedata;
        const organisationdefault = props.organisationdata;

        if (sitedefault && organisationdefault && sitedefault.id == "0") {
            sitedefault.mobileAppEnabled = organisationdefault.mobileAppEnabled;
            sitedefault.deskBookingEnabled = organisationdefault.deskBookingEnabled;
            sitedefault.roomBookingEnabled = organisationdefault.roomBookingEnabled;
            sitedefault.smartWirelessChargingEnabled = organisationdefault.smartWirelessChargingEnabled;
            sitedefault.contentEnabled = organisationdefault.contentEnabled;
            sitedefault.eventsEnabled = organisationdefault.eventsEnabled;
            sitedefault.meetingsEnabled = organisationdefault.meetingsEnabled;
            sitedefault.mapsEnabled = organisationdefault.mapsEnabled;
            sitedefault.assetTrackingEnabled = organisationdefault.assetTrackingEnabled;
            sitedefault.assetTrackingHistoryEnabled = organisationdefault.assetTrackingHistoryEnabled;
            sitedefault.personTrackingEnabled = organisationdefault.personTrackingEnabled;
            sitedefault.wayfindingEnabled = organisationdefault.wayfindingEnabled;
            sitedefault.findAFriendEnabled = organisationdefault.findAFriendEnabled;
            sitedefault.notificationsEnabled = organisationdefault.notificationsEnabled;
            sitedefault.venueManagementEnabled = organisationdefault.venueManagementEnabled;
            sitedefault.meetAndGreetEnabled = organisationdefault.meetAndGreetEnabled;

            this.setState({ update: true });
        }
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.sitedata && (!_.isEqual(start, this.props.sitedata) || this.state.imagechanged)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }
    updateSiteName(evt) {
        this.props.sitedata.name = evt.target.value;

        var sitenames = []
        if (this.props.sitesdata && this.props.sitesdata[0] && this.props.sitesdata[0].count != 0) {
            sitenames = this.props.sitesdata.map(site => {
                if (this.props.sitedata.id !== site.id)
                    return site.name.toLowerCase();
            });
        }

        if (evt.target.value.length > 50) {
            this.siteName_err = "Name can't be longer than 50 character";
        }
        else if (sitenames.length > 0 && sitenames.includes(evt.target.value.toLowerCase().trim())) {
            this.siteName_err = "Name already exists";
        }
        else {
            this.siteName_err = "";
        }
        this.setState({ validating: true });
    }
    updateSiteCode(evt) {
        this.props.sitedata.code = evt.target.value;

        var sitecodes = []
        if (this.props.sitesdata && this.props.sitesdata[0] && this.props.sitesdata[0].count != 0) {
            sitecodes = this.props.sitesdata.map(site => {
                if (this.props.sitedata.id !== site.id)
                    return site.code.toLowerCase();
            });
        }

        if (!constants.REGEX.CODE.test(evt.target.value)) {
            this.siteCode_err = "Code must contain alphanumeric or ‘_’ , ‘@’ , ‘#’ , ‘.’ , ‘:’ , ‘-’ characters, but not spaces";
        }
        else if (sitecodes.length > 0 && sitecodes.includes(evt.target.value.toLowerCase())) {
            this.siteCode_err = "Code already exists";
        }
        else {
            this.siteCode_err = "";
        }
        this.setState({ validating: true });
    }
    lookUpTimeZone(option) {
        if (this.props.timezonesdata.filter(tz => tz.name == option)[0]) {
            var TimeZone = this.props.timezonesdata.filter(tz => tz.name == option)[0];
            return TimeZone.name + " [UTC" + TimeZone.utcOffset + "]";
        }
    }
    timeZoneErrCheck() {

    }
    updateTest(evt) {
        this.setState({ checked: !this.state.checked });
        this.props.sitedata.test = evt.target.checked;
    }
    updateProdcution(evt) {
        this.setState({ checked: !this.state.checked });
        this.props.sitedata.production = evt.target.checked;
    }
    updateActive(evt) {
        this.setState({ checked: !this.state.checked });
        this.props.sitedata.active = evt.target.checked;
    }
    readFile(f) {
        if (f) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const data = event.target.result;
                console.log('File', data.length);
                //const base64data = btoa(unescape(encodeURIComponent(data)));
                const base64data = btoa(data);
                //console.log('File Base64:', base64data);
                base64 = base64data;
                //ToDo call API with file.name, and content
                //this.BinaryData = data; 
            };
            reader.readAsBinaryString(f);
        }
    }
    //fileChangedHandler = (event) => {
    //    //console.log(event.target.files[0]);
    //    const file = event.target.files[0];
    //    console.log(file);
    //    if (file != undefined) {
    //        if (file.size > 1024 * 1024 * 2) {
    //            this.setState({ file: null });
    //        }
    //        else {
    //            this.readFile(file);
    //            this.setState({ file: file, newimage: true, imagechanged: true });
    //        }
    //    }
    //    else {

    //    }

    //}
    shortcut() {
        if (this.state.file == "") {
            return null;
        }
        else if (this.state.file != null) {
            var Data = [];
            Data.push(this.state.file);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/*" }))
            return (<Box direction="row"><Image width="112" height="75" src={url} /></Box>);
        }
        else {
            return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }

    updateSelectedOrganization(evt) {
        let selectedOrg = this.props.organisationsdata.find(o => o.name === evt.value);

        var orgID = selectedOrg.id;
        var orgCODE = selectedOrg.code;
        sessionStorage.setItem("organisationId", orgID);
        sessionStorage.setItem("organisationCode", orgCODE);
        sessionStorage.setItem("locationId", "");
        this.setState({ selectedOrganization: evt.value, orgSelected: true, sitedataLoading: true, organisationdataLoading: true }, () => {
            this.props.action.getSiteAction(this.props.match.params.id) //this.props.match.params.id is always "0"
                .then(response => this.setState({ sitedataLoading: false }))
                .catch(error => {
                    var errorArray = this.state.apiErrorList.concat(error);
                    this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                    console.log('*DEBUG*- Site Edit Error', error);
                });
            this.props.action.getOrganisationAction(sessionStorage.getItem("organisationId"))
                .then(response => this.setState({ organisationdataLoading: false }))
                .catch(error => {
                    var errorArray = this.state.apiErrorList.concat(error);
                    this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                    console.log('*DEBUG*-Organisation Error', error);
                });
        });
    };

    onCancelFeature() {
        this.setState({ openFeautresPopUp: false, openSaveWarning: false });
    }
    onDiscardFeature() {
        this.setState({ openFeautresPopUp: false, openSaveWarning: false });
    }
    //onSaveFeature() {
    //    this.setState({ openFeautresPopUp: false });

    //    if (this.state.updateFeature == "deskBookingEnabled") this.updateBookADesk();
    //    if (this.state.updateFeature == "roomBookingEnabled") this.updateBookARoom();
    //    if (this.state.updateFeature == "smartWirelessChargingEnabled") this.updateChargifi();
    //    if (this.state.updateFeature == "contentEnabled") this.updateContent();
    //    if (this.state.updateFeature == "eventsEnabled") this.updateEvents();
    //    if (this.state.updateFeature == "findAFriendEnabled") this.updateFindAFriend();
    //    if (this.state.updateFeature == "meetingsEnabled") this.updateMeetings();
    //    if (this.state.updateFeature == "wayfindingEnabled") this.updateWayfinding();
    //    if (this.state.updateFeature == "mapsEnabled") this.updateMaps();
    //    if (this.state.updateFeature == "assetTrackingEnabled") this.updateAssetTracking();
    //    if (this.state.updateFeature == "personTrackingEnabled") this.updatePersonTracking();
    //    if (this.state.updateFeature == "mobileAppEnabled") this.updateMobileApp();
    //    if (this.state.updateFeature == "notificationsEnabled") this.updateNotifications();
    //    if (this.state.updateFeature == "venueManagementEnabled") this.updateVenueManagement();
    //}

    //updateBookADesk() {
    //    this.props.sitedata.deskBookingEnabled = false;
    //    this.setState({ updated: true });
    //}

    //updateBookARoom() {
    //    this.props.sitedata.roomBookingEnabled = false;
    //    this.setState({ updated: true });
    //}

    //updateChargifi() {
    //    this.props.sitedata.smartWirelessChargingEnabled = false;
    //    this.setState({ updated: true });
    //}

    //updateContent() {
    //    this.props.sitedata.contentEnabled = false;
    //    this.setState({ updated: true });
    //}

    //updateEvents() {
    //    this.props.sitedata.eventsEnabled = false;
    //    this.setState({ updated: true });
    //}

    //updateFindAFriend() {
    //    this.props.sitedata.findAFriendEnabled = false;
    //    this.setState({ updated: true });
    //}

    //updateMeetings() {
    //    this.props.sitedata.meetingsEnabled = false;
    //    this.setState({ updated: true });
    //}

    //updateWayfinding() {
    //    this.props.sitedata.wayfindingEnabled = false;
    //    this.setState({ updated: true });
    //}

    //updateMaps() {
    //    this.props.sitedata.mapsEnabled = false;
    //    this.setState({ updated: true });
    //}

    //updateAssetTracking() {
    //    this.props.sitedata.assetTrackingEnabled = false;
    //    this.setState({ updated: true });
    //}

    //updatePersonTracking() {
    //    this.props.sitedata.personTrackingEnabled = false;
    //    this.setState({ updated: true });
    //}

    //updateMobileApp() {
    //    this.props.sitedata.mobileAppEnabled = false;
    //    this.setState({ updated: true });
    //}

    //updateNotifications() {
    //    this.props.sitedata.notificationsEnabled = false;
    //    this.setState({ updated: true });
    //}

    //updateVenueManagement() {
    //    this.props.sitedata.venueManagementEnabled = false;
    //    this.setState({ updated: true });
    //}

    syncSettings() {
        const { sitedata, organisationdata } = this.props;

        sitedata.mobileAppEnabled = organisationdata.mobileAppEnabled;
        sitedata.deskBookingEnabled = organisationdata.deskBookingEnabled;
        sitedata.roomBookingEnabled = organisationdata.roomBookingEnabled;
        sitedata.smartWirelessChargingEnabled = organisationdata.smartWirelessChargingEnabled;
        sitedata.contentEnabled = organisationdata.contentEnabled;
        sitedata.eventsEnabled = organisationdata.eventsEnabled;
        sitedata.meetingsEnabled = organisationdata.meetingsEnabled;
        sitedata.mapsEnabled = organisationdata.mapsEnabled;
        sitedata.assetTrackingEnabled = organisationdata.assetTrackingEnabled;
        sitedata.assetTrackingHistoryEnabled = organisationdata.assetTrackingHistoryEnabled;
        sitedata.personTrackingEnabled = organisationdata.personTrackingEnabled;
        sitedata.wayfindingEnabled = organisationdata.wayfindingEnabled;
        sitedata.findAFriendEnabled = organisationdata.findAFriendEnabled;
        sitedata.notificationsEnabled = organisationdata.notificationsEnabled;
        sitedata.venueManagementEnabled = organisationdata.venueManagementEnabled;
        sitedata.meetAndGreetEnabled = organisationdata.meetAndGreetEnabled;

        this.setState({ updated: true });
    }

    openWarning() {
        if (this.state.openSaveWarning && this.state.featureChanged && (!_.isEqual(start, this.props.sitedata) || this.state.imagechanged)) {
            return true;
        }
        return false;
    }

    handleSave() {
        this.setState({ openSaveWarning: false });
        var sitenames = []
        var sitecodes = []
        if (this.props.sitesdata && this.props.sitesdata[0] && this.props.sitesdata[0].count != 0) {
            var sitenames = this.props.sitesdata.map(site => {
                if (this.props.sitedata.id !== site.id)
                    return site.name.toLowerCase();
            });
            var sitecodes = this.props.sitesdata.map(site => {
                if (this.props.sitedata.id !== site.id)
                    return site.code.toLowerCase();
            });
        }

        const sitedefault = this.props.sitedata;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ validating: false });

        if (sitedefault.name.trim().length === 0) {
            errorsno++;
            this.siteName_err = "Name is required";
        }
        if (sitedefault.name.length > 50) {
            errorsno++;
            this.siteName_err = "Name can't be longer than 50 character";
        }
        if (sitenames.length > 0 && sitenames.includes(sitedefault.name.toLowerCase().trim())) {
            errorsno++;
            this.siteName_err = "Name already exists";
        }
        if (sitedefault.code.length === 0) {
            errorsno++;
            this.siteCode_err = "Code is required";
        }
        if (sitedefault.code.length != 0 && !constants.REGEX.CODE.test(sitedefault.code.toLowerCase())) {
            errorsno++;
            this.siteCode_err = "Code must be alphanumeric or ‘_’ , ‘@’ , ‘#’ , ‘.’ , ‘:’ , ‘-’ characters";
        }
        if (sitecodes.length > 0 && sitecodes.includes(sitedefault.code)) {
            errorsno++;
            this.siteCode_err = "Code already exists";
        }
        if (sitedefault.timeZone.length === 0) {
            errorsno++;
            this.timeZone_err = "Time Zone is required";
        }
        if (errorsno == 0) {
            if (this.props.sitedata.id == "0") {
                this.props.sitedata.reportIssueEnabled = true;
            }
            this.setState({ disabledButton: true });
            this.props.sitedata.logo = base64;
            console.log('Save: OK', sitedefault);
            this.props.action.saveSiteAction(sitedefault)
                .then((response) => {
                    this.setState({ isSaved: true, imagechanged: false, isSaveSuccessful: response.ok });
                    //this.props.history.push('/managesites');
                }).catch(error => {
                    console.log('*DEBUG*-Save Site Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        if (!this.state.cancel && (!_.isEqual(start, this.props.sitedata) || this.state.imagechanged)) {
            this.setState({ cancel: true });
        }
        else {
            const siteId = this.props.sitedata.id;
            this.props.action.getSiteAction(siteId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push('/managesites');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-ConfigurationEdit Error', error);
                    this.props.history.push('/managesites');
                });
        }
    }

    onClose() {
        //console.log("helllo");
        this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push({
                pathname: '/managesites'
            });
        }

    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    renderFeature({ name = "", property = "", level = 0, parent = "", children = [], showInfo = false, featureInfo = "" } = {}) {
        const sitedata = this.props.sitedata;
        const organisationdata = this.props.organisationdata;
        var marginLeft = level * 24 + "px";
        var paddingTop = level == 0 ? "small" : "xsmall";
        var paddingBottom = level == 0 && children.length == 0 ? "small" : "xsmall";

        function isDisabled() {
            if (!RB.isSuperUser()) {
                return true;
            }
            if (parent != "" && parent != "none" && !sitedata[parent]) {
                return true;
            }
            if (!organisationdata[property]) {
                return true;
            }
            else return false;
        }

        function changeBackground(set) {
            if (set) document.getElementById("box_id" + property).style.background = "#DADADA";
            else document.getElementById("box_id" + property).style.background = "transparent";
        }

        if ((parent != "" && parent != "none" && !sitedata[parent]) || !organisationdata[property]) {
            sitedata[property] = false;
        }

        function dataTipText() {
            if (!organisationdata[property]) return "This feature is disabled at org level."
            else if (isDisabled() && organisationdata[property]) return "Enable parent feature in order to use this feature."
            else return ""
        }

        return (
            <Box margin={{ "left": marginLeft }} direction="row" justify="between" pad={{ "top": paddingTop, "bottom": paddingBottom }} gap="large">
                <Box id={"box_id" + property} pad={{ "horizontal": "xsmall" }} round="5px" direction="row" gap="small" align="center">
                    <Text size="large">{name}</Text>
                    {showInfo &&
                        <Box data-for={"featureinfo" + property} data-tip={featureInfo} style={{ "height": "24px" }}>
                            <CircleInformation />
                            <ReactTooltip id={"featureinfo" + property} place="right" type="dark" effect="solid" disable={!showInfo} arrowColor="transparent" />
                        </Box>}
                </Box>
                <Box data-for={"disableinfo" + property} data-tip={dataTipText()} style={{ "height": "24px" }} onMouseEnter={() => changeBackground(true)} onMouseLeave={() => changeBackground(false)} >
                    <CheckBox toggle checked={sitedata[property] && organisationdata[property]} disabled={isDisabled()}
                        onChange={evt => (sitedata[property] = !sitedata[property], !sitedata[property] ? this.setState({ featureChanged: true }) : this.setState({ updated: true }))} />
                    <ReactTooltip id={"disableinfo" + property} place="right" type="dark" effect="solid" disable={!isDisabled()} arrowColor="transparent" />
                </Box>
            </Box>
        )
    }

    render() {
        const sitedefault = this.props.sitedata;
        const organisationdefault = this.props.organisationdata;
        const organisationsdata = this.props.organisationsdata;
        const timezonesdata = this.props.timezonesdata;

        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONF_SITE);

        const { sitedataLoading, sitesdataLoading, organisationdataLoading, organisationsdataLoading, timezonesdataLoading } = this.state;

        if ((this.state.selectedOrganization && sitedataLoading) || (this.props.match.params.id != "0" && sitedataLoading) || sitesdataLoading ||
            (this.state.selectedOrganization && organisationdataLoading) || (this.props.match.params.id != "0" && organisationdataLoading) || organisationsdataLoading ||
            timezonesdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        const heading = sitedefault && (sitedefault.id != "0") ? 'Edit' : 'Create';
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}
                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' > Site - {heading} </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={evt => this.state.featureChanged && (!_.isEqual(start, this.props.sitedata) || this.state.imagechanged) ? this.setState({ openSaveWarning: true }) : this.handleSave()} disabled={this.state.disabledButton || !RB.isSuperUser()} />
                    </Box>
                </Box>
                <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                {/*<PopUpCancel text="Disabling this feature will remove all feature related items from the portal and client for this site." SaveText="Disable Feature" CancelText="Cancel"*/}
                {/*    open={this.state.openFeautresPopUp} onCancel={() => this.onCancelFeature()} onSave={evt => this.onSaveFeature(evt)} onDiscard={() => this.onDiscardFeature()} />*/}
                <PopUpCancel text="Disabling features will remove all related items from the portal and client for this site." SaveText="Save" CancelText="Cancel"
                    open={this.openWarning()} onCancel={() => this.onCancelFeature()} onSave={evt => this.handleSave()} onDiscard={() => this.onDiscardFeature()} />
                <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <form>
                        <Box pad={{ "bottom": "medium" }} gap="medium">
                            {this.props.match.params.id == "0" &&
                                <Box gap="small">
                                    <Text>You must choose an organization from the list below to link to your site.</Text>
                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                        <FormField label="Organization Name *" >
                                            <Select
                                                placeholder="Select an Organization"
                                                options={organisationsdata.filter(o => o.name.match(this.state.regexp)).map(org => org.name).sort((p1, p2) => { if (p1.toLowerCase() < p2.toLowerCase()) return -1; return 1; })}
                                                value={this.state.selectedOrganization}
                                                onSearch={(searchText) => {
                                                    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                    this.setState({ regexp: regexp });
                                                }}
                                                onChange={(evt) => this.updateSelectedOrganization(evt)}
                                                onClose={() => this.setState({ selectedOrganization: "", regexp: "" })}
                                                disabled={!RB.isSuperUser()}
                                            />
                                        </FormField>
                                    </StyledBox>
                                </Box>}
                            {(this.props.match.params.id != "0" || this.state.orgSelected) &&
                                <Box gap="medium">
                                    <Box gap="small">
                                        <Box>
                                            <Text>All fields marked with an * are mandatory.</Text>
                                            <Text size="large">General Settings</Text>
                                        </Box>
                                        <StyledBox style1 pad={{ "horizontal": "small" }}>
                                            <FormField label="Organization Name" >
                                                <TextInput value={organisationdefault.name} disabled />
                                            </FormField>
                                            <FormField label="Site Name *" error={this.siteName_err}>
                                                <TextInput value={sitedefault.name} onChange={evt => this.updateSiteName(evt)} disabled={!RB.isSuperUser()} />
                                            </FormField>
                                            <FormField label="Site Code *" error={this.siteCode_err}>
                                                <TextInput value={sitedefault.code} onChange={evt => this.updateSiteCode(evt)} readOnly={heading == "Edit"} disabled={heading == "Edit" || !RB.isSuperUser()} />
                                            </FormField>
                                            <FormField label="Time Zone *" error={this.timeZone_err}>
                                                <Select
                                                    placeholder="Select a Time Zone"
                                                    value={this.lookUpTimeZone(sitedefault.timeZone)}
                                                    onChange={({ option }) => { sitedefault.timeZone = option.split("[")[0].trim(), this.setState({ update: true }), this.timeZone_err = "" }}
                                                    options={timezonesdata.filter(s => this.lookUpTimeZone(s.name).match(this.state.regexp)).map(tz => tz.name + " [UTC" + tz.utcOffset + "]")}
                                                    onClose={() => this.setState({ regexp: "" })}
                                                    onSearch={(searchText) => {
                                                        const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                        this.setState({ regexp: regexp });
                                                    }}
                                                    disabled={!canedit1}
                                                />
                                            </FormField>
                                            <FormField label="Test">
                                                <Box pad="small">
                                                    <CheckBox checked={sitedefault.test} onChange={evt => this.updateTest(evt)} disabled={!RB.isSuperUser()} />
                                                </Box>
                                            </FormField>
                                            <FormField label="Production">
                                                <Box pad="small">
                                                    <CheckBox checked={sitedefault.production} onChange={evt => this.updateProdcution(evt)} disabled={!RB.isSuperUser()} />
                                                </Box>
                                            </FormField>
                                            <FormField label="Active">
                                                <Box pad="small">
                                                    <CheckBox checked={sitedefault.active} onChange={evt => this.updateActive(evt)} disabled={!RB.isSuperUser()} />
                                                </Box>
                                            </FormField>
                                        </StyledBox>
                                    </Box>

                                    <Box gap="small">
                                        <Box direction="row" gap="large">
                                            <Box gap="xsmall">
                                                {/*<Text size="large">Client Features</Text>*/}
                                                <Box pad={{ "right": "small" }}>
                                                    {/*{this.renderFeature({ name: "Client Features", property: "mobileAppEnabled", level: 0, parent: "none", children: ["notificationsEnabled", "contentEnabled", "roomBookingEnabled", "deskBookingEnabled", "venueManagementEnabled", "mapsEnabled"], showInfo: false, featureInfo: "Feature info" })}*/}
                                                    <Box direction="row" justify="between" pad={{ "bottom": "small" }}>
                                                        <Text size="large">Features</Text>
                                                        <Box style={{ "height": "24px" }}>
                                                            <CheckBox toggle checked={sitedefault.mobileAppEnabled} disabled={!RB.isSuperUser()}
                                                                onChange={evt => (sitedefault.mobileAppEnabled = !sitedefault.mobileAppEnabled, !sitedefault.mobileAppEnabled ? this.setState({ featureChanged: true }) : this.setState({ updated: true }))} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <StyledBox style1 pad={{ "horizontal": "small", "bottom": "xsmall" }} width="600px">
                                                    {this.renderFeature({ name: "Book a Space - Desks", property: "deskBookingEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                                    {this.renderFeature({ name: "Book a Space - Rooms", property: "roomBookingEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                                    {this.renderFeature({ name: "Chargifi", property: "smartWirelessChargingEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                                    {this.renderFeature({ name: "Content", property: "contentEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                                    {this.renderFeature({ name: "Meridian Maps (Maps & Placemarks)", property: "mapsEnabled", level: 0, parent: "mobileAppEnabled", children: ["assetTrackingEnabled", "personTrackingEnabled", "wayfindingEnabled"], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                                    {this.renderFeature({ name: "Meridian Tags (Assets & Trackers)", property: "assetTrackingEnabled", level: 1, parent: "mapsEnabled", children: ["assetTrackingHistoryEnabled"], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                                    {this.renderFeature({ name: "Meridian Tags (Asset History)", property: "assetTrackingHistoryEnabled", level: 2, parent: "assetTrackingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                                    {this.renderFeature({ name: "Meridian Tags (People)", property: "personTrackingEnabled", level: 1, parent: "mapsEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                                    {this.renderFeature({ name: "Meridian Bluedot (Beacons & Wayfinding)", property: "wayfindingEnabled", level: 1, parent: "mapsEnabled", children: ["findAFriendEnabled"], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                                    {this.renderFeature({ name: "Locate People", property: "findAFriendEnabled", level: 2, parent: "wayfindingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                                    {this.renderFeature({ name: "Push Notifications", property: "notificationsEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                                    {this.renderFeature({ name: "Venue Management", property: "venueManagementEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                                    {this.renderFeature({ name: "Visitor Notifications", property: "meetAndGreetEnabled", level: 0, parent: "mobileAppEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}
                                                    {/*{this.renderFeature({ name: "Meeting Services - Rooms", property: "meetingsEnabled", level: 1, parent: "roomBookingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}*/}
                                                    {/*{this.renderFeature({ name: "Meeting Services - Desks", property: "meetingsEnabled", level: 1, parent: "deskBookingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "client" })}*/}
                                                </StyledBox>
                                            </Box>
                                            {false &&
                                                <Box gap="xsmall">
                                                    {/*<Text size="large">Portal Features</Text>*/}
                                                    <Box pad={{ "right": "small" }}>
                                                        {/*{this.renderFeature({ name: "Portal Features", property: "webAppEnabled", level: 0, parent: "none", children: ["meetingsEnabled", "deskBookingEnabled", "mapsEnabled"], showInfo: false, featureInfo: "Feature info" })}*/}
                                                        <Box direction="row" justify="between" pad={{ "bottom": "small" }}>
                                                            <Text size="large">Portal Features</Text>
                                                            <Box style={{ "height": "24px" }}>
                                                                <CheckBox toggle checked={sitedefault.webAppEnabled} disabled={!RB.isSuperUser()}
                                                                    onChange={evt => (sitedefault.webAppEnabled = !sitedefault.webAppEnabled, !sitedefault.webAppEnabled ? this.setState({ featureChanged: true }) : this.setState({ updated: true }))} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <StyledBox style1 pad={{ "horizontal": "small", "bottom": "xsmall" }} width="600px">
                                                        {/*{this.renderFeature({ name: "Book a Space - Rooms", property: "roomBookingEnabled", level: 0, parent: "webAppEnabled", children: ["meetingsEnabled"], showInfo: false, featureInfo: "Feature info", place: "portal" })}*/}
                                                        {/*{this.renderFeature({ name: "Meeting Services - Rooms", property: "meetingsEnabled", level: 1, parent: "roomBookingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}*/}
                                                        {/*{this.renderFeature({ name: "Book a Space - Desks", property: "deskBookingEnabled", level: 0, parent: "webAppEnabled", children: ["meetingsEnabled"], showInfo: false, featureInfo: "Feature info", place: "portal" })}*/}
                                                        {/*{this.renderFeature({ name: "Meeting Services - Desks", property: "meetingsEnabled", level: 1, parent: "deskBookingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}*/}
                                                        {this.renderFeature({ name: "Meridian Maps (Maps & Placemarks)", property: "mapsEnabled", level: 0, parent: "mobileAppEnabled", children: ["assetTrackingEnabled"], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                                        {this.renderFeature({ name: "Meridian Tags (Assets & Trackers)", property: "assetTrackingEnabled", level: 1, parent: "mapsEnabled", children: ["assetTrackingHistoryEnabled"], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                                        {this.renderFeature({ name: "Meridian Tags (Asset History)", property: "assetTrackingHistoryEnabled", level: 2, parent: "assetTrackingEnabled", children: [], showInfo: false, featureInfo: "Feature info", place: "portal" })}
                                                    </StyledBox>
                                                </Box>}
                                            <Box>
                                                <a data-for="Sync_Button" data-tip="Restore all org features for this site." style={{ "height": "36px" }} align="center">
                                                    <StyledButton typeSave label="Sync" onClick={evt => this.syncSettings()} disabled={!RB.isSuperUser()} />
                                                </a>
                                                <ReactTooltip id="Sync_Button" place="left" type="dark" effect="solid" arrowColor="transparent" />
                                            </Box>
                                        </Box>
                                    </Box>

                                    {/*{false && <Box gap="small">*/}
                                    {/*    <Box direction="row" justify="between" align="center">*/}
                                    {/*        <Box direction="row" gap="small" align="center">*/}
                                    {/*            <Text size="large">Site Features</Text>*/}
                                    {/*            <a data-for="Features info" data-tip="Feauture settings are inherited from the organization. Changing the settings below will override the current configuration." style={{ "height": "24px" }}>*/}
                                    {/*                <CircleInformation />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="Features info" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box alignContent="center">*/}
                                    {/*            <a data-for="Sync_Button" data-tip="Restore all org features for this site." style={{ "height": "36px" }} align="center">*/}
                                    {/*                <StyledButton typeSave label="Sync" onClick={evt => this.syncSettings()} disabled={!RB.isSuperUser()} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="Sync_Button" place="left" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*    </Box>*/}
                                    {/*    <StyledBox style1 pad={{ "horizontal": "small" }}>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Book a Space - Desks</Text>*/}
                                    {/*            <a data-for="deskBookingEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.deskBookingEnabled}>*/}
                                    {/*                */}{/*This part works with the commented out PopUpCancel*/}
                                    {/*                */}{/*<CheckBox toggle checked={sitedefault.deskBookingEnabled && organisationdefault.deskBookingEnabled}*/}
                                    {/*                */}{/*    onChange={evt => sitedefault.deskBookingEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "deskBookingEnabled" }) :*/}
                                    {/*                */}{/*        (sitedefault.deskBookingEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.deskBookingEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.deskBookingEnabled && organisationdefault.deskBookingEnabled} disabled={!RB.isSuperUser() || !organisationdefault.deskBookingEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.deskBookingEnabled = !sitedefault.deskBookingEnabled, !sitedefault.deskBookingEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="deskBookingEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Book a Space - Rooms</Text>*/}
                                    {/*            <a data-for="roomBookingEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.roomBookingEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.roomBookingEnabled && organisationdefault.roomBookingEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.roomBookingEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "roomBookingEnabled" }) :*/}
                                    {/*                        (sitedefault.roomBookingEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.roomBookingEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.roomBookingEnabled && organisationdefault.roomBookingEnabled} disabled={!RB.isSuperUser() || !organisationdefault.roomBookingEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.roomBookingEnabled = !sitedefault.roomBookingEnabled, !sitedefault.roomBookingEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="roomBookingEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Chargifi</Text>*/}
                                    {/*            <a data-for="smartWirelessChargingEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.smartWirelessChargingEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.smartWirelessChargingEnabled && organisationdefault.smartWirelessChargingEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.smartWirelessChargingEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "smartWirelessChargingEnabled" }) :*/}
                                    {/*                        (sitedefault.smartWirelessChargingEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.smartWirelessChargingEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.smartWirelessChargingEnabled && organisationdefault.smartWirelessChargingEnabled} disabled={!RB.isSuperUser() || !organisationdefault.smartWirelessChargingEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.smartWirelessChargingEnabled = !sitedefault.smartWirelessChargingEnabled, !sitedefault.smartWirelessChargingEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="smartWirelessChargingEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Content</Text>*/}
                                    {/*            <a data-for="contentEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.contentEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.contentEnabled && organisationdefault.contentEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.contentEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "contentEnabled" }) :*/}
                                    {/*                        (sitedefault.contentEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.contentEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.contentEnabled && organisationdefault.contentEnabled} disabled={!RB.isSuperUser() || !organisationdefault.contentEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.contentEnabled = !sitedefault.contentEnabled, !sitedefault.contentEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="contentEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Events & News</Text>*/}
                                    {/*            <a data-for="eventsEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.eventsEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.eventsEnabled && organisationdefault.eventsEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.eventsEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "eventsEnabled" }) :*/}
                                    {/*                        (sitedefault.eventsEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.eventsEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.eventsEnabled && organisationdefault.eventsEnabled} disabled={!RB.isSuperUser() || !organisationdefault.eventsEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.eventsEnabled = !sitedefault.eventsEnabled, !sitedefault.eventsEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="eventsEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Locate People</Text>*/}
                                    {/*            <a data-for="findAFriendEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.findAFriendEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.findAFriendEnabled && organisationdefault.findAFriendEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.findAFriendEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "findAFriendEnabled" }) :*/}
                                    {/*                        (sitedefault.findAFriendEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.findAFriendEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.findAFriendEnabled && organisationdefault.findAFriendEnabled} disabled={!RB.isSuperUser() || !organisationdefault.findAFriendEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.findAFriendEnabled = !sitedefault.findAFriendEnabled, !sitedefault.findAFriendEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="findAFriendEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Meeting Services</Text>*/}
                                    {/*            <a data-for="meetingsEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.meetingsEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.meetingsEnabled && organisationdefault.meetingsEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.meetingsEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "meetingsEnabled" }) :*/}
                                    {/*                        (sitedefault.meetingsEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.meetingsEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.meetingsEnabled && organisationdefault.meetingsEnabled} disabled={!RB.isSuperUser() || !organisationdefault.meetingsEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.meetingsEnabled = !sitedefault.meetingsEnabled, !sitedefault.meetingsEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="meetingsEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Meridian Bluedot (Beacons & Wayfinding)</Text>*/}
                                    {/*            <a data-for="wayfindingEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.wayfindingEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.wayfindingEnabled && organisationdefault.wayfindingEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.wayfindingEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "wayfindingEnabled" }) :*/}
                                    {/*                        (sitedefault.wayfindingEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.wayfindingEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.wayfindingEnabled && organisationdefault.wayfindingEnabled} disabled={!RB.isSuperUser() || !organisationdefault.wayfindingEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.wayfindingEnabled = !sitedefault.wayfindingEnabled, !sitedefault.wayfindingEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="wayfindingEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Meridian Maps (Maps & Placemarks)</Text>*/}
                                    {/*            <a data-for="mapsEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.mapsEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.mapsEnabled && organisationdefault.mapsEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.mapsEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "mapsEnabled" }) :*/}
                                    {/*                        (sitedefault.mapsEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.mapsEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.mapsEnabled && organisationdefault.mapsEnabled} disabled={!RB.isSuperUser() || !organisationdefault.mapsEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.mapsEnabled = !sitedefault.mapsEnabled, !sitedefault.mapsEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="mapsEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Meridian Tags (Assets & Trackers)</Text>*/}
                                    {/*            <a data-for="assetTrackingEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.assetTrackingEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.assetTrackingEnabled && organisationdefault.assetTrackingEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.assetTrackingEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "assetTrackingEnabled" }) :*/}
                                    {/*                        (sitedefault.assetTrackingEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.assetTrackingEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.assetTrackingEnabled && organisationdefault.assetTrackingEnabled} disabled={!RB.isSuperUser() || !organisationdefault.assetTrackingEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.assetTrackingEnabled = !sitedefault.assetTrackingEnabled, !sitedefault.assetTrackingEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="assetTrackingEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Meridian Tags (Asset History)</Text>*/}
                                    {/*            <a data-for="assetTrackingHistoryEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.assetTrackingHistoryEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.assetTrackingHistoryEnabled && organisationdefault.assetTrackingHistoryEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.assetTrackingHistoryEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "assetTrackingHistoryEnabled" }) :*/}
                                    {/*                        (sitedefault.assetTrackingHistoryEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.assetTrackingHistoryEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.assetTrackingHistoryEnabled && organisationdefault.assetTrackingHistoryEnabled} disabled={!RB.isSuperUser() || !organisationdefault.assetTrackingHistoryEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.assetTrackingHistoryEnabled = !sitedefault.assetTrackingHistoryEnabled, !sitedefault.assetTrackingHistoryEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="assetTrackingHistoryEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Meridian Tags (People)</Text>*/}
                                    {/*            <a data-for="personTrackingEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.personTrackingEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.personTrackingEnabled && organisationdefault.personTrackingEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.personTrackingEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "personTrackingEnabled" }) :*/}
                                    {/*                        (sitedefault.personTrackingEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.personTrackingEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.personTrackingEnabled && organisationdefault.personTrackingEnabled} disabled={!RB.isSuperUser() || !organisationdefault.personTrackingEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.personTrackingEnabled = !sitedefault.personTrackingEnabled, !sitedefault.personTrackingEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="personTrackingEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Mobile Client</Text>*/}
                                    {/*            <a data-for="mobileAppEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.mobileAppEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.mobileAppEnabled && organisationdefault.mobileAppEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.mobileAppEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "mobileAppEnabled" }) :*/}
                                    {/*                        (sitedefault.mobileAppEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.mobileAppEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.mobileAppEnabled && organisationdefault.mobileAppEnabled} disabled={!RB.isSuperUser() || !organisationdefault.mobileAppEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.mobileAppEnabled = !sitedefault.mobileAppEnabled, !sitedefault.mobileAppEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="mobileAppEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Notifications</Text>*/}
                                    {/*            <a data-for="notificationsEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.notificationsEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.notificationsEnabled && organisationdefault.notificationsEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.notificationsEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "notificationsEnabled" }) :*/}
                                    {/*                        (sitedefault.notificationsEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.notificationsEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.notificationsEnabled && organisationdefault.notificationsEnabled} disabled={!RB.isSuperUser() || !organisationdefault.notificationsEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.notificationsEnabled = !sitedefault.notificationsEnabled, !sitedefault.notificationsEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="notificationsEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*        <Box direction="row" justify="between" pad={{ "vertical": "small" }}>*/}
                                    {/*            <Text size="large">Venue Management</Text>*/}
                                    {/*            <a data-for="venueManagementEnabled" data-tip="This feauture is turned off at org level." style={{ "height": "24px" }} data-tip-disable={organisationdefault.venueManagementEnabled}>*/}
                                    {/*                <CheckBox toggle checked={sitedefault.venueManagementEnabled && organisationdefault.venueManagementEnabled}*/}
                                    {/*                    onChange={evt => sitedefault.venueManagementEnabled ? this.setState({ openFeautresPopUp: true, updateFeature: "venueManagementEnabled" }) :*/}
                                    {/*                        (sitedefault.venueManagementEnabled = true, this.setState({ updated: true }))} disabled={!RB.isSuperUser() || !organisationdefault.venueManagementEnabled} />*/}
                                    {/*                <CheckBox toggle checked={sitedefault.venueManagementEnabled && organisationdefault.venueManagementEnabled} disabled={!RB.isSuperUser() || !organisationdefault.venueManagementEnabled}*/}
                                    {/*                    onChange={evt => (sitedefault.venueManagementEnabled = !sitedefault.venueManagementEnabled, !sitedefault.venueManagementEnabled ? this.setState({ featureChanged: true, updated: true }) : this.setState({ updated: true }))} />*/}
                                    {/*            </a>*/}
                                    {/*            <ReactTooltip id="venueManagementEnabled" place="right" type="dark" effect="solid" arrowColor="transparent" />*/}
                                    {/*        </Box>*/}
                                    {/*    </StyledBox>*/}
                                    {/*</Box>}*/}
                                </Box>}
                        </Box>
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const siteId = ownProps.match.params.id || '';
    if (state.selectedSiteReducer.sitedata && siteId == state.selectedSiteReducer.sitedata.id) {
        start = JSON.parse(JSON.stringify(state.selectedSiteReducer.sitedata));
        if (state.selectedSiteReducer.sitedata && state.selectedOrganisationReducer.organisationdata && state.selectedSiteReducer.sitedata.id == "0") {
            start.mobileAppEnabled = state.selectedOrganisationReducer.organisationdata.mobileAppEnabled;
            start.deskBookingEnabled = state.selectedOrganisationReducer.organisationdata.deskBookingEnabled;
            start.roomBookingEnabled = state.selectedOrganisationReducer.organisationdata.roomBookingEnabled;
            start.smartWirelessChargingEnabled = state.selectedOrganisationReducer.organisationdata.smartWirelessChargingEnabled;
            start.contentEnabled = state.selectedOrganisationReducer.organisationdata.contentEnabled;
            start.eventsEnabled = state.selectedOrganisationReducer.organisationdata.eventsEnabled;
            start.meetingsEnabled = state.selectedOrganisationReducer.organisationdata.meetingsEnabled;
            start.mapsEnabled = state.selectedOrganisationReducer.organisationdata.mapsEnabled;
            start.assetTrackingEnabled = state.selectedOrganisationReducer.organisationdata.assetTrackingEnabled;
            start.assetTrackingHistoryEnabled = state.selectedOrganisationReducer.organisationdata.assetTrackingHistoryEnabled;
            start.personTrackingEnabled = state.selectedOrganisationReducer.organisationdata.personTrackingEnabled;
            start.wayfindingEnabled = state.selectedOrganisationReducer.organisationdata.wayfindingEnabled;
            start.findAFriendEnabled = state.selectedOrganisationReducer.organisationdata.findAFriendEnabled;
            start.notificationsEnabled = state.selectedOrganisationReducer.organisationdata.notificationsEnabled;
            start.venueManagementEnabled = state.selectedOrganisationReducer.organisationdata.venueManagementEnabled;
            start.meetAndGreetEnabled = state.selectedOrganisationReducer.organisationdata.meetAndGreetEnabled;
        }
    }
    return {
        sitedata: state.selectedSiteReducer.sitedata,
        sitesdata: state.sitesReducer.sitesdata,
        organisationdata: state.selectedOrganisationReducer.organisationdata,
        organisationsdata: state.organisationsReducer.organisationsdata,
        timezonesdata: state.timeZonesReducer.timezonesdata
    };
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SiteAction, ...OrganisationAction, ...TimeZoneAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageSiteSettings));