import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, CheckBox, Text, Grommet, Stack } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, FormSearch, CircleInformation, Multiple } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
// import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
//import { PagingTable } from 'grommet-controls';
import constants from '../constants';
import { stat } from 'fs';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import ReactTooltip from "react-tooltip";
import LoadingIndicator from './common/LoadingIndicator';
import { Context } from './Context';

const PaginatedDataTable = withPagination(DataTable);
const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    checkBox: {
        color: {
            light: 'status-critical',
            dark: 'status-critical',
        }
    }
});

class CategoryListTable extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.state = {
            filter: false,
            search: "",
        }
    }

    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        let tid = elem.getElementsByTagName('th')[0].textContent;
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    render() {
        // const {filter} = this.state; 
        // console.log('Filter:', filter);
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Categories</Heading>
                    <Box direction="row" gap="small" >

                        <StyledButton typeSave label='Create New' style={{ visibility: constants.visible(RB.canEdit(constants.PERMS.LOC_CONTENT)) }} alignSelf="center" onClick={this.props.handleNew} />
                    </Box>
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium" }} fill="vertical">
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <StyledBox ref={this.dtwrapper}>
                            {this.renderCategoriesTable(this.props)}
                        </StyledBox>
                    </IconThemeContext.Extend>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }

    handleFilter() {
        this.setState({ filter: !this.state.filter });
        //console.log('New:', this.state.filter);
    }

    renderCategoriesTable(props) {
        const { filter } = this.state;
        //console.log('Render:', filter);
        const COLUMNS = [
            {
                property: 'lcname',
                header: 'Category Name',
                render: categorydata => <Text>{categorydata.name}</Text>
            },
            //{
            //    property: 'lcparent',
            //    header: 'Category Parent',
            //    render: categorydata => <Text>{categorydata.parent}</Text>
            //},
            //{
            //    property: 'lclocation',
            //    header: 'App Location',
            //    render: categorydata => <Text>{categorydata.location}</Text>
            //},
            {
                property: 'lcparentloc',
                header: 'App Location',
                render: categorydata => <Text>{categorydata.lcparentloc}</Text>
            },
            {
                property: 'lcquickaction',
                header: 'Quick Action',
                render: categorydata => <CheckBox checked={categorydata.lcquickaction} disabled />
            },
            {
                property: 'synced',
                header: 'Default',
                render: categorydata => <CheckBox checked={categorydata.synced} disabled />
            },
            {
                property: 'ccnumbers',
                header: 'Category Content',
                render: categorydata => {
                    let size = categorydata.content ? categorydata.content.length : 0;
                    return categorydata.synced ? null :
                        <Box direction="row" alignContent="center" >
                            <Box fill={true} align="end" alignContent="center" alignSelf="center" direction="row">
                                <Stack anchor="top-right" data-for={categorydata.id}
                                    data-tip>
                                    <Multiple
                                        size="large"
                                        style={{ height: "24px" }}
                                    />
                                    <Box
                                        background="brand"
                                        pad={{ horizontal: 'xsmall' }}
                                        round
                                    >
                                        {size}
                                </Box>
                                </Stack>
                                {size > 0 && <ReactTooltip id={categorydata.id} place="left" type="light" effect="solid" arrowColor="transparent" multiline >
                                    <StyledBox style1 border="all" gap="small" pad="small">
                                        {categorydata.content && categorydata.content.map((l, idx) => (
                                            <Text>
                                                {idx+1 + ". " +l.name}
                                            </Text>
                                        ))}
                                    </StyledBox>
                                </ReactTooltip>}
                            </Box>
                        </Box >
                }
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: categorydata => <Text hidden>{categorydata.id}</Text>,
            },
            {
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: categorydata => (categorydata.synced || !RB.canEdit(constants.PERMS.LOC_CONTENT)) ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, categorydata.id)} icon={<Blank />} /></Box>
                    :
                    <Box width="24px" direction='row' gap="xsmall">
                        {/*<Button plain disabled={props.deletedCategoryIds.includes(categorydata.id)} onClick={evt => props.handleEdit(evt, categorydata.id)} icon={<Edit />} />*/}
                        <Button plain disabled={props.deletedCategoryIds.includes(categorydata.id)} onClick={evt => props.handleDelete(evt, categorydata.id)} icon={<Trash />} />
                    </Box>,
            },
            {
                property: 'lcinfo',
                header: "",
                render: categorydata => categorydata.lctooltip ?
                    <Box fill={true} align="end">
                        <a data-for="CatInfo" style={{ "height": "24px" }}
                            data-tip="This category has no app location or quick action associated. It will not be displayed in the client app.">
                            <CircleInformation size="medium" color="#F04953" />
                        </a>
                        <ReactTooltip id="CatInfo" place="left" type="dark" effect="solid" arrowColor="transparent" multiline />
                    </Box> : null
            },
        ];

        function removeColumn(propertyName) {
            if (COLUMNS.findIndex(c => c.property == propertyName) != -1) {
                COLUMNS.splice(COLUMNS.findIndex(c => c.property == propertyName), 1);
            }
        }

        if (!(this.context.feature.find(f => f.name == "content").isEnabled && this.context.feature.find(f => f.name == "siteMobilClient").isEnabled)) {
            removeColumn("lcquickaction");
        }

        let DATA = [];
        let QuickActions = [];
        if (this.props.sitesdata && this.props.sitesdata.quickActions) {
            this.props.sitesdata.quickActions.forEach((qa) => {
                if (!qa.deleted && qa.type == "CATEGORY") {
                    QuickActions.push(
                        qa.foreignId,
                    );
                }
            });
        }
        var final = [];
        var filtered0 = props.categoriesdata.filter(d => d.level == 0);
        var filtered2 = props.categoriesdata.filter(d => d.level == 2);
        filtered2.forEach((item) => {
            var l = filtered0.find(e => item.name == e.name);
            if (l) {

            }
            else {
                final.push(item);
            }
        })
        filtered0.forEach((item) => {
            if (item.locationId == sessionStorage.getItem("locationId")) {
                //item.location = null;
                final.push(item);
            }
        })

        final = final.concat(props.categoriesdata.filter(d => d.level == 1));
        var ri = final.find(d => d.name == "Report Issue" && d.level == 1);
        var i = final.indexOf(ri);
        final.splice(i, 1);
        var info = final.find(d => d.name == "Info" && d.level == 1);
        var index = final.indexOf(info);
        final.splice(index, 1);
        var sp = final.find(d => d.name == "Spaces" && d.level == 1);
        var spi = final.indexOf(sp);
        final.splice(spi, 1);
        final.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });

        final.forEach((ev, id) => {

            let l = (ev.location) ? ev.location : '';
            let p = (ev.parent) ? ev.parent : '';
            let lp = constants.APP_LP(l, p);
            DATA.push({
                ...ev,
                lcname: (ev.name) ? ev.name.toLowerCase() : '',
                lcparent: p,
                lclocation: l,
                lcparentloc: lp,
                lcquickaction: QuickActions.includes(ev.id),
                lctooltip: !QuickActions.includes(ev.id) && constants.APP_LP(l, p) == null,
                ccnumbers: ev.synced ? -1 : ev.content ? ev.content.length : 0
            });
        });

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.name.toLowerCase().includes(search) ||
                constants.APP_LP(d.location, d.parent).toLowerCase().includes(search) ||
                ("quick action".includes(search) && d.lcquickaction) ||
                ("default".includes(search) && d.synced) ||
                (("error".includes(search) || "info".includes(search)) && d.lctooltip)
            );
        }

        if (!props.sitesdata.meridianEnabled) {
            DATA = DATA.filter(d => d.synced == false)
        }

        console.log('DATA:', DATA);
        const edit = RB.canEdit(constants.PERMS.LOC_CONTENT);
        // const pColumns = [
        //     {
        //         Header: p => (<Button plain icon={<FormSearch />} onClick={() => this.handleFilter()} />),
        //         accessor: 'id',
        //         filterable: false,
        //         sortable: false,
        //         minWidth: 100,
        //         decorations: {
        //             header: {
        //                 align: 'start',
        //             },
        //             cell: {
        //                 align: 'start',
        //             },
        //         },
        //         Cell: p => ((p.original.synced || !edit) ? <Box direction='row'><Button plain style={{ cursor: 'inherit' }} icon={<Blank />} /></Box>
        //             : <Box direction='row'><Button plain disabled={props.deletedCategoryId === p.original.id} onClick={evt => props.handleEdit(evt, p.original.id)} icon={<Edit />} /><Button plain disabled={props.deletedCategoryId === p.original.id} onClick={evt => props.handleDelete(evt, p.original.id)} icon={<Trash />} /></Box>
        //         ),
        //     }, {
        //         Header: 'Name',
        //         accessor: 'name',
        //         minWidth: 200,
        //     }, {
        //         Header: 'App Location',
        //         accessor: 'lcparentloc',
        //         minWidth: 325,
        //     }
        // ];

        if (props.loading) {
            return (
                <LoadingIndicator />
            );
        }

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "lcparent" || c.property === "lclocation" || c.property === "lcparentloc"
                }))}
                data={DATA}
                noLoadingMsg={DATA.length != 0}
                sortable
                //sortoptions={[
                //    {col: 'lcname', label: 'Category Name'},
                //    {col: 'lcparentloc', label: 'App Location'},
                //    ]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                onUpdated={this.onDTUpdated.bind(this)}
                styled={true}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
            // <PagingTable
            //     data={DATA}
            //     columns={pColumns}
            //     defaultPageSize={constants.PAGE_SIZE}
            //     filterable={filter}
            //     sortable={true}
            //     showPagination={true}
            //     resizable={false}
            //     loading={false}
            //     pageSizeOptions={[5, 10, 25, 50, 100]}
            //     showPageJump={false}
            //     decorations={{
            //         table: { elevation: 'none', /*border: 'all'*/ },
            //         header: { border: 'bottom', pad: { horizontal: 'none' } },
            //         //filter: { /*background: 'light-2',*/ border: 'all' },
            //         filterInput: { size: 'medium', placeholder: 'Filter' },
            //         body: { animation: { type: 'fadeIn', duration: 1000, size: 'large' } },
            //         row: { align: 'center', pad: 'none' },
            //         cell: { pad: 'none'},
            //         pagination: { border: 'top', pad: { vertical: 'small' } },
            //     }}
            // />
        );
    }
}

export default CategoryListTable;
