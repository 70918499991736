import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line
import { Grid, Box, Button, Text, Select, Layer, Image, Grommet, Stack } from 'grommet';
import { User, FormClose, Search, Refresh } from 'grommet-icons';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import * as MeridianSDK from "@meridian/web-sdk";
import * as LevelAction from '../action/LevelAction';
import * as BuildingAction from '../action/BuildingAction';
import * as MergedBuildingAction from '../action/MergedBuildingAction';
import * as MergedLevelAction from '../action/MergedLevelAction';
import * as SiteAction from '../action/SiteAction';
import * as PlacemarkAction from '../action/PlacemarkAction';
import { useMediaQuery } from 'react-responsive';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';


const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        }
    }
});

const customDateFormatter = new Intl.DateTimeFormat('en-US', {
    //weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    //second: 'numeric'
});

class MapCategory extends Component {
    constructor(props) {
        super(props);
        this.rotate = false;
        this.token = "";
        this.appID = "";
        this.lastUpdate = "";
        this.categoriesAll = [];
        this.state = {
            open: true,
            floors: [],
            selectedFloor: "",
            categories: [],
            //small width, landscape mobile : dekstop, large screen : portrait, mobile
            width: window.innerHeight < window.innerWidth ? window.innerWidth < 1024 ? (Math.round(window.innerWidth * 0.7) - 10 - 5 + "px") : (window.innerWidth - 10 - 5 - 400 + "px") : window.innerWidth - 10 + "px",
            //small width, landscape mobile : dekstop, large screen : portrait, mobile
            height: window.innerHeight < window.innerWidth ? window.innerWidth < 1024 ? (window.innerHeight - 10 - 40 + "px") : (window.innerHeight - 10 - 70 + "px") : window.innerHeight - 10 - 5 - 190 - 26 - 55 + "px",
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 1025 })
        const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })
        if (isDesktop || isLandscape) {
            const floorid = this.props.match.params.floorId;
            const locid = this.props.match.params.locId;
            var x = document.getElementsByClassName("css-qs9yr6-watermark");
            if (floorid && locid && document.getElementById("meridian-map") && x.length == 0 && this.props.sitesdata && this.props.mergedlevelsdata) {
                var location = this.props.sitesdata.find(s => s.id == locid);
                if (location && location.appToken && location.appID) {
                    this.token = location.appToken;
                    this.appID = location.appID;
                    if (this.lastUpdate == "") {
                        const floor = this.props.mergedlevelsdata.find(l => l.meridianId == floorid);
                        this.lastUpdate = floor ? floor.updatedAt : "";
                    }
                    this.renderMap(location.appID, floorid, location.appToken, "meridian-map");
                }
            }
        }
        return isDesktop || isLandscape ? children : null
    }

    Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 1024 })
        const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
        if (isMobile && isPortrait) {
            const floorid = this.props.match.params.floorId;
            const locid = this.props.match.params.locId;
            var x = document.getElementsByClassName("css-qs9yr6-watermark");
            if (floorid && locid && document.getElementById("meridian-map-mobile") && x.length == 0 && this.props.sitesdata && this.props.mergedlevelsdata) {
                var location = this.props.sitesdata.find(s => s.id == locid);
                if (location && location.appToken && location.appID) {
                    this.token = location.appToken;
                    this.appID = location.appID;
                    if (this.lastUpdate == "") {
                        const floor = this.props.mergedlevelsdata.find(l => l.meridianId == floorid);
                        this.lastUpdate = floor ? floor.updatedAt : "";
                    }
                    this.renderMap(location.appID, floorid, location.appToken, "meridian-map-mobile");
                }
            }
        }
        return isMobile && isPortrait ? children : null
    }
    componentWillMount() {
        sessionStorage.setItem("organisationId", "");
        sessionStorage.setItem("organisationCode", "");
    }
    componentDidMount() {
        const locid = this.props.match.params.locId;
        sessionStorage.setItem("locationId", locid);
        var delayInMilliseconds = 250;

        window.addEventListener("resize", evt => {
            if (evt.returnValue) {
                this.rotate = !this.rotate;
                const locid = this.props.match.params.locId;

                //small width, landscape mobile : dekstop, large screen : portrait, mobile
                var width = window.innerHeight < window.innerWidth ? window.innerWidth < 1024 ? (Math.round(window.innerWidth * 0.7) - 10 - 5 + "px") : (window.innerWidth - 10 - 5 - 400 + "px") : window.innerWidth - 10 + "px";
                //small width, landscape mobile : dekstop, large screen : portrait, mobile
                var height = window.innerHeight < window.innerWidth ? window.innerWidth < 1024 ? (window.innerHeight - 10 - 40 + "px") : (window.innerHeight - 10 - 70 + "px") : window.innerHeight - 10 - 5 - 190 - 26 - 55 + "px";
                const floorid = this.props.match.params.floorId;

                this.setState({ height: height, width: width });

                if (floorid && locid && (document.getElementById("meridian-map") || document.getElementById("meridian-map-mobile")) && this.props.sitesdata && this.props.mergedlevelsdata) {
                    var location = this.props.sitesdata.find(s => s.id == locid);
                    if (location && location.appToken && location.appID) {
                        this.token = location.appToken;
                        this.appID = location.appID;
                        if (this.lastUpdate == "") {
                            const floor = this.props.mergedlevelsdata.find(l => l.meridianId == floorid);
                            this.lastUpdate = floor ? floor.updatedAt : "";
                        }

                        const self = this;
                        setTimeout(function () {
                            return self.renderMap(location.appID, floorid, location.appToken, self.state.height > self.state.width ? "meridian-map-mobile" : "meridian-map");
                        }, delayInMilliseconds);
                        //this.renderMap(location.appID, floorid, location.appToken, this.state.height > this.state.width ? "meridian-map-mobile" : "meridian-map");
                    }
                }
            }
        }, false);

        this.props.action.getSitesAction(sessionStorage.getItem("locationId"))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Site List Error', error);
            });
        this.props.action.getMergedLevelsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getMergedBuildingsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
        this.props.action.getPlacemarksAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Placemarks List Error', error);
            });
    }
    componentWillUnmount() {
        if (this.map) {
            this.map.destroy();
        }
    }
    forceReloadMap() {
        window.dispatchEvent(new Event("resize"));
    }
    renderMap(locid, floorid, token, elementName) {
        if (locid == "" || floorid == "" || token == "") {
        }
        else {
            var array = []
            this.state.categories.filter(s => s.selected).forEach(item => array = array.concat(item.list));
            const macid = ["CC78ABC6CB63", "CC78AB9D9845"];
            const api = new MeridianSDK.API({
                environment: "production",
                token: token
            });
            var element = document.getElementById(elementName);
            if (element) {
                element.innerHTML = "";
                this.map = MeridianSDK.createMap(
                    element,
                    {
                        api,
                        locationID: locid,
                        floorID: floorid,
                        shouldMapPanZoom: function restrictedPanZoom(event) {
                            if (event.type === "wheel" && !event.altKey) {
                                return false;
                            } else if (event.type === "touchstart") {
                                return event.touches.length >= 2;
                            }
                            return true;
                        },
                        showFloorsControl: false,
                        showTagsControl: false,

                        width: this.state.width,
                        height: this.state.height,
                        placemarks: {
                            filter: p => {
                                if (array.length == 0) {
                                    return true;
                                }
                                else {
                                    if (array.includes(p.id)) {
                                        p.color = "01a982";
                                    }
                                    return array.includes(p.id);
                                }
                            },
                        },
                        tags: {
                            filter: t => {
                                return true;
                            },
                            showControlTags: false
                        },
                    },
                );
                element.style.visibility = "visible";
            }
            var x = document.getElementsByClassName("meridian--private--qs9yr6-watermark");
            if (x) {
                x[0].style.visibility = "hidden";
            }
            var y = document.getElementsByClassName("meridian-map-background");
            if (y) {
                y[0].style.background = "#ffffff";
            }
        }
    }
    componentWillReceiveProps(props) {
        if (props.placemarksdata && props.placemarksdata.length > 0 && this.state.categories.length == 0) {

            var list = [
            ];
            props.placemarksdata.filter(p => p.category && p.categoryid).forEach(element => {
                var existedElement = list.find(i => /*i.name == element.category.name ||*/ i.categoryId == element.categoryid);
                if (existedElement) {
                    existedElement.list.push(element.meridianId);
                }
                else {
                    list.push(
                        {
                            categoryId: element.categoryid,
                            name: element.category.name,
                            list: [element.meridianId],
                            selected: false,
                            filtered: true
                        });
                }
            });
            list.sort(constants.byPropertyCalled("name"));
            console.log("clist", list);
            this.categoriesAll = list;
            this.setState({ categories: list });
        }
        if (props.mergedlevelsdata && props.mergedlevelsdata.length > 0 && props.mergedbuildingsdata && props.mergedbuildingsdata.length > 0 && this.state.selectedFloor == "") {
            let level = props.mergedlevelsdata.find(l => l.meridianId == props.match.params.floorId);
            this.lastUpdate = level.updatedAt;
            let building = props.mergedbuildingsdata.find(b => b.id == level.buildingId);
            const floors = props.mergedlevelsdata.filter(f => f.buildingId == building.id && f.meridianId != "");
            this.setState({ selectedBuilding: building.name, selectedFloor: level.name, floors: floors, selectedFloorId: level.meridianId });
        }
    }
    //calculateIcon(capacity, occupancy) {
    //    var percentage = Math.floor(occupancy / capacity * 100);
    //    if (percentage <= 75) {
    //        return <Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} />
    //    }
    //    if (percentage > 75 && percentage < 100) {
    //        return <Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} />;
    //    }
    //    if (percentage >= 100) {
    //        return <Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} />;
    //    }
    //}

    render() {
        var Desktop = this.Desktop;
        var Mobile = this.Mobile;
        console.log("prop", this.props);

        var smallWidth = window.innerWidth < 1024 ? true : false;
        var smallHeight = window.innerHeight < 400 ? true : false;
        var smallHeightGap = smallHeight ? 0 : 5;
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        const { mergedbuildingsdata, mergedlevelsdata } = this.props;

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}
                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },
                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row" height="0px"
                    align="center"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                </Box>
                <Desktop>
                    <Box gridArea='main' fill="vertical" id="box12" height={this.windowHeight}>
                        <Layer id="layer1" position="center" full>
                            <Box id="full" direction="column" height={this.windowHeight} justify="start" pad="5px">
                                <Box id="boxin" direction="row" justify="start" height={this.state.height} gap="5px">
                                    <Box id="controls" width={smallWidth ? (Math.round(windowWidth * 0.3) + "px") : "400px"}
                                        height={this.state.height} gap={smallHeightGap + "px"}>
                                        <Box id="controls" direction="column" justify="between" height="190px">
                                            <Box direction="row" justify="between">
                                                <Text weight="bold" size="large">
                                                    Site Facilities
                                                </Text>
                                            </Box>
                                            <Box width="100%">
                                                <Select placeholder="Select Building" value={this.state.selectedBuilding} options={mergedbuildingsdata.map(b => b.name)}
                                                    onChange={o => {
                                                        const buildingid = mergedbuildingsdata[o.selected].id;
                                                        const floors = mergedlevelsdata.filter(f => f.buildingId == buildingid && f.meridianId != "");
                                                        console.log("list", floors);
                                                        this.setState({ selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "" });
                                                    }} />
                                            </Box>
                                            <Box width="100%">
                                                <Select disabled={this.state.floors.length == 0} placeholder="Select Floor" value={this.state.selectedFloor} options={this.state.floors.filter(d => d.meridianId).map(n => n.name)}
                                                    onChange={o => {
                                                        const floor = this.state.floors.filter(d => d.meridianId)[o.selected];
                                                        const floorid = floor.meridianId;
                                                        this.lastUpdate = floor.updatedAt;
                                                        this.setState({ selectedFloor: o.option, selectedFloorId: floorid }, () => {
                                                            this.renderMap(this.appID, floorid, this.token, "meridian-map");
                                                        });
                                                    }} />
                                            </Box>
                                            <Box width="100%">
                                                <Select disabled={!this.state.selectedFloorId} placeholder="Select Category" value={this.state.categories.filter(v => v.selected).map(c => c.name)} options={this.state.categories.filter(f => f.filtered).map(c => c.name)}
                                                    onChange={o => {
                                                        var categories = [...this.state.categories];
                                                        var element = categories.find(c => c.name == o.option);
                                                        element.selected = !element.selected;
                                                        this.setState({ categories }, () => {
                                                            const locid = this.props.match.params.locId;
                                                            this.renderMap(this.appID, this.state.selectedFloorId, this.token, "meridian-map");
                                                        });
                                                    }}
                                                    /*onSearch={(searchText) => {
                                                        var categories = [...this.state.categories];
                                                        const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                        categories.forEach(item => {
                                                            if (item.name.match(regexp)) {
                                                                item.filtered = true;
                                                            } else {
                                                                item.filtered = false;
                                                            }
                                                        });
                                                        this.setState({ categories: categories });
                                                    }}*/
                                                    onClose={() => {
                                                        var categories = [...this.state.categories];
                                                        categories.forEach(item => { item.filtered = true })
                                                        this.setState({ categories: categories });
                                                    }}
                                                    multiple
                                                    messages={{ "multiple": "Multiple selected" }} />
                                            </Box>
                                        </Box>
                                        <Grommet theme={customTheme}>
                                            <Box id="category-tags" direction="column-responsive" justify="start" wrap
                                                overflow={"auto"} /*style={{"overflow-x":"hidden", "overflow-y":"auto"}} */
                                                width={smallWidth ? (Math.round(windowWidth * 0.3) + "px") : "400px"}
                                                height={{ "max": smallWidth ? (window.innerHeight - 10 - 190 - 40 - smallHeightGap + "px") : (window.innerHeight - 10 - 190 - 70 - smallHeightGap + "px") }}>
                                                {this.state.categories.filter(v => v.selected).map((c, idx) =>
                                                    <Button style={{ width: "fit-content" }} id={idx} onClick={evt => {
                                                        var categories = [...this.state.categories];
                                                        var element = categories.find(cp => cp.name == c.name);
                                                        element.selected = !element.selected;
                                                        this.setState({ categories }, () => {
                                                            const locid = this.props.match.params.locId;
                                                            this.renderMap(this.appID, this.state.selectedFloorId, this.token, "meridian-map");
                                                        });
                                                    }}>
                                                        <Box
                                                            align="center"
                                                            background="brand"
                                                            pad={{ horizontal: '2px', vertical: '1px' }}
                                                            margin="2px"
                                                            round="medium"
                                                            direction="row"
                                                        >
                                                            <Text size="xsmall" margin={{ right: 'xxsmall' }}>
                                                                {c.name}
                                                            </Text>
                                                            <FormClose size="small" color="white" />
                                                        </Box>
                                                    </Button>
                                                )}
                                            </Box>
                                        </Grommet>
                                    </Box>
                                    <Grommet theme={customTheme}>
                                        <Box align="center" alignContent="center" justify="center" onClick={() => this.forceReloadMap()}
                                            height={this.state.height}
                                            width={this.state.width}>
                                            <Stack anchor="center" fill>
                                                <Box height="100%" width="100%" align="center" justify="center">
                                                    <Button icon={<Refresh />} plain onClick={() => this.forceReloadMap()} />
                                                </Box>
                                                <div id="meridian-map" />
                                            </Stack>
                                        </Box>
                                    </Grommet>
                                </Box>
                                <Box height={smallWidth ? "40px" : "70px"} width="100%" direction="row" justify="between">
                                    <Box justify="end" alignSelf="end">
                                        <Image height={smallWidth ? "35px" : "65px"} src="https://hpepngdemostor.blob.core.windows.net/images/hpeCitrixLogo.png" />
                                    </Box>
                                    <Box direction="column" align="end" justify="end" alignSelf="end">
                                        <Text size="small">Last Updated: {this.lastUpdate == "" ? "Unavailable" : customDateFormatter.format(new Date(this.lastUpdate))}</Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Layer>
                    </Box>
                </Desktop>

                <Mobile>
                    <Box gridArea='main' fill="vertical" id="box12" height={this.windowHeight}>
                        <Layer id="layer1" position="center" full>
                            <Box id="full" direction="column" height={this.windowHeight} justify="start" pad="5px">
                                <Box id="boxin" direction="column" justify="between" height="100%">
                                    <Box direction="column" gap="5px">
                                        <Box id="controls" direction="column" justify="between" height="190px">
                                            <Box direction="row" justify="between">
                                                <Text weight="bold" size="large">
                                                    Site Facilities
                                                </Text>
                                            </Box>
                                            <Box width="100%" direction="row-responsive">
                                                <Select placeholder="Select Building" value={this.state.selectedBuilding} options={mergedbuildingsdata.map(b => b.name)}
                                                    onChange={o => {
                                                        const buildingid = mergedbuildingsdata[o.selected].id;
                                                        const floors = mergedlevelsdata.filter(f => f.buildingId == buildingid && f.meridianId != "");
                                                        this.setState({ selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "" });
                                                    }} />
                                            </Box>

                                            <Box direction="row-responsive" width="100%">
                                                <Select disabled={this.state.floors.length == 0} placeholder="Select Floor" value={this.state.selectedFloor} options={this.state.floors.filter(d => d.meridianId).map(n => n.name)}
                                                    onChange={o => {
                                                        const floor = this.state.floors.filter(d => d.meridianId)[o.selected];
                                                        const floorid = floor.meridianId;
                                                        this.lastUpdate = floor.updatedAt;
                                                        this.setState({ selectedFloor: o.option, selectedFloorId: floorid });
                                                        this.renderMap(this.appID, floorid, this.token, "meridian-map-mobile");
                                                    }} />
                                            </Box>

                                            <Box direction="row-responsive" width="100%">
                                                <Select disabled={!this.state.selectedFloorId} placeholder="Select Category" value={this.state.categories.filter(v => v.selected).map(c => c.name)} options={this.state.categories.filter(f => f.filtered).map(c => c.name)}
                                                    onChange={o => {
                                                        var categories = [...this.state.categories];
                                                        var element = categories.find(c => c.name == o.option);
                                                        element.selected = !element.selected;

                                                        this.setState({ categories }, () => {
                                                            const locid = this.props.match.params.locId;
                                                            this.renderMap(this.appID, this.state.selectedFloorId, this.token, "meridian-map-mobile");
                                                        });
                                                    }}
                                                    /*onSearch={(searchText) => {
                                                        var categories = [...this.state.categories];
                                                        const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                        categories.forEach(item => {
                                                            if (item.name.match(regexp)) {
                                                                item.filtered = true;
                                                            } else {
                                                                item.filtered = false;
                                                            }
                                                        });
                                                        this.setState({ categories: categories });
                                                    }}*/
                                                    onClose={() => {
                                                        var categories = [...this.state.categories];
                                                        categories.forEach(item => { item.filtered = true })
                                                        this.setState({ categories: categories });
                                                    }}
                                                    multiple
                                                    messages={{ "multiple": "Multiple selected" }} />
                                            </Box>
                                        </Box>

                                        <Grommet theme={customTheme}>
                                            <Box id="category-tags" direction="column-responsive" height="26px" overflow="auto">
                                                {this.state.categories.filter(v => v.selected).map((c, idx) =>
                                                    <Button style={{ width: "fit-content" }} id={idx} onClick={evt => {
                                                        var categories = [...this.state.categories];
                                                        var element = categories.find(cp => cp.name == c.name);
                                                        element.selected = !element.selected;

                                                        this.setState({ categories }, () => {
                                                            const locid = this.props.match.params.locId;
                                                            this.renderMap(this.appID, this.state.selectedFloorId, this.token, "meridian-map-mobile");
                                                        });
                                                    }}>
                                                        <Box
                                                            align="center"
                                                            background="brand"
                                                            pad={{ horizontal: '2px', vertical: '1px' }}
                                                            margin="2px"
                                                            round="medium"
                                                            direction="row"
                                                        >
                                                            <Text size="xsmall" margin={{ right: 'xxsmall' }} truncate>
                                                                {c.name}
                                                            </Text>
                                                            <FormClose size="small" color="white" />
                                                        </Box>
                                                    </Button>
                                                )}
                                            </Box>
                                        </Grommet>
                                    </Box>
                                    <Grommet theme={customTheme}>
                                        <Box alignSelf="center" align="center" alignContent="center" width="100%" height={this.state.height} >
                                            <Stack anchor="center" fill>
                                                <Box height="100%" width="100%" align="center" justify="center">
                                                    <Button icon={<Refresh />} plain onClick={() => this.forceReloadMap()} />
                                                </Box>
                                                <div id="meridian-map-mobile" />
                                            </Stack>
                                        </Box>
                                    </Grommet>
                                </Box>

                                <Box height="55px" direction="row" justify="between">
                                    <Box justify="end">
                                        <Image height="45px" src="https://hpepngdemostor.blob.core.windows.net/images/hpeCitrixLogo.png" />
                                    </Box>
                                    <Box direction="column" justify="end" align="start" gap="xsmall">
                                        <Text size="small">Last Updated:</Text>
                                        <Text size="small">{this.lastUpdate == "" ? "Unavailable" : customDateFormatter.format(new Date(this.lastUpdate))}</Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Layer>
                    </Box>
                </Mobile>

                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        //levelsdata: state.levelsReducer.levelsdata,
        //buildingsdata: state.buildingsReducer.buildingsdata,
        mergedlevelsdata: state.mergedLevelsReducer.mergedlevelsdata,
        mergedbuildingsdata: state.mergedBuildingsReducer.mergedbuildingsdata,
        sitesdata: state.sitesReducer.sitesdata,
        placemarksdata: state.placemarksReducer.placemarksdata
    };
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...LevelAction, ...BuildingAction, ...MergedBuildingAction, ...MergedLevelAction, ...SiteAction, ...PlacemarkAction }, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(MapCategory);