import React, { Component } from 'react';
// eslint-disable-next-line
import { Box, Button, Grid, Select, Text, ThemeContext } from 'grommet';
import { Ascend, Descend, Download } from 'grommet-icons';
import Pagination from 'react-js-pagination';
import "./PaginatedDataTable.css";
import { Grommet } from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import styled from 'styled-components';
import constants from '../../constants';
import { CSVLink } from "react-csv";
import ReactTooltip from "react-tooltip";



class ServedDataTable extends Component {
    constructor(props) {
        super(props);
        console.log('********** new ServedDataTable instance created');
        this.state = {
            data: props.data.slice(),
            prevdata: props.data,
            page: 1,
            rows: props.displayrows,
            sortopt: props.sortoptions,
            sortlabel: '',
            sortcol: '',
            order: false,
            dtsearch: null,

            property: "",
            direction: "",
        };

    }

    Export_Data = "No Data Available";
    ExportFileName = "Exported Datatable";
    options = [5, 10, 20, 25, 50];

    static getDerivedStateFromProps(props, state) {
        //let m = props.data.length % state.rows;
        let newPage = Math.ceil(props.data.length / state.rows);
        if (state.prevdata !== props.data) {
            if (newPage < state.page) {
                newPage = 1;
            }
            else if (newPage == state.page) {
                //let is as it is
                newPage = state.page;
            }
            else {
                //let as it is
                newPage = state.page;
            }
            return { data: [...props.data], prevdata: props.data, page: newPage }
        }
        return null
    }

    componentDidUpdate() {
        if (this.props.onUpdated) {
            this.props.onUpdated();
        }
    }

    escapeRegexp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    dtSearch = search => {
        let fdata;
        if (search) {
            const searchExpressions = Object.keys(search).map(property => ({
                property,
                exp: new RegExp(this.escapeRegexp(search[property]), 'i')
            }));
            fdata = this.props.data.filter(
                d => !searchExpressions.some(e => !e.exp.test(d[e.property]))
            );
        } else {
            fdata = [...this.props.data];
        }
        // this.setState({ data: fdata, page: 1 });
        return fdata;
    }

    customSearch = search => this.setState({ dtsearch: search, page: 1 });


    handlePageChange(pageNumber) {
        if (this.props.count && this.props.getMore && this.props.data.length != this.props.count) {
            const skip = (pageNumber - 1) * this.state.rows;
            this.props.getMore(skip);
        }
        this.setState({ page: pageNumber });
    }

    updateRows(e) {
        //console.log('Update:', e);
        this.setState({ rows: e.option, page: 1 });
    }

    updateSort(e) {
        const col = this.state.sortopt[e.selected].col;
        const label = this.state.sortopt[e.selected].label;
        //console.log(e.option, col, label);
        this.setState({ sortcol: col, sortlabel: label, order: false });
    }

    changeSort(e) {
        this.setState({ order: !this.state.order });
    }

    render() {
        let { data, ...restprops } = this.props;
        let GrommetDataTable = this.props.grommetDataTable;
        // let GrommetDataTable = styled(this.props.grommetDataTable)`
        //     width: 100%;
        // `;
        let displayDATA = [];
        let a = this.state.page * this.state.rows - this.state.rows;
        let b = this.state.page * this.state.rows;
        let searchedData = this.dtSearch(this.state.dtsearch);
        if (this.state.sortcol) {
            console.log('Sorting Table:', this.state.sortcol, this.state.order);
            if (this.state.order) {
                searchedData.sort((a, b) => { if (a[this.state.sortcol] > b[this.state.sortcol]) return -1; return 1; });
            } else {
                searchedData.sort((a, b) => { if (a[this.state.sortcol] < b[this.state.sortcol]) return -1; return 1; });
            }
        }

        if (this.state.property) {
            if (this.state.direction == "asc") {
                searchedData.sort((a, b) => { if (a[this.state.property] < b[this.state.property]) return -1; return 1; });
            } else {
                searchedData.sort((a, b) => { if (a[this.state.property] > b[this.state.property]) return -1; return 1; });
            }
        }
        searchedData.forEach((ev, id) => {
            if (a <= id && id < b) {
                displayDATA.push({
                    ...ev,
                })
            }
        });
        let from = a + 1;
        let to = b;
        if (from > searchedData.length) { from = searchedData.length }
        if (to > searchedData.length) { to = searchedData.length }

        if (this.props.data.length != 0 && !this.props.exportdata) {
            this.Export_Data = this.props.data;
        }
        else if (this.props.exportdata && this.props.data.length != 0) {
            this.Export_Data = this.props.exportdata;
        }
        else if (this.props.data.length == 0) {
            this.Export_Data = "No Data Available";
        }
        if (this.props.csvfilename && this.props.csvfilename == "") {
            this.ExportFileName = this.props.filename;
        }
        
        return (
            <Grid
                fill={true}
                rows={['auto', 'auto', 'auto']}
                columns={['auto']}
                areas={[
                    { name: 'header', start: [0, 0], end: [0, 0] },
                    { name: 'data', start: [0, 1], end: [0, 1] },
                    { name: 'footer', start: [0, 2], end: [0, 2] },
                ]}
            >
                <Box gridArea='header' direction="row" align="center"
                    justify={this.props.renderExtraHeader ? "between" : "end"}
                    pad={{ vertical: "small", horizontal: "none" }} background={this.props.bg ? this.props.bg : constants.BACKGROUND_COLOR} gap="medium">
                    {this.props.renderExtraHeader && (
                        <Box align="center">
                            {this.props.renderExtraHeader()}
                        </Box>
                    )}
                    <Box direction="row">
                        <Box align="center" justify="center" width="30px">
                            {this.state.sortcol && (
                                this.state.order ?
                                    <Button plain onClick={e => this.changeSort(e)} icon={<Descend />} title="Descending order - Click to sort in ascending order" />
                                    :
                                    <Button plain onClick={e => this.changeSort(e)} icon={<Ascend />} title="Ascending order - Click to sort in descending order" />
                            )}
                        </Box>
                        <Box align="center" background={constants.BOX_BACKGROUND_COLOR_1} direction="row" gap="small">
                            {this.state.sortopt.length > 0 && (
                                <Box border="all">
                                    <Select
                                        placeholder="Select column to sort"
                                        value={this.state.sortlabel}
                                        options={this.state.sortopt.map((o) => o.label)}
                                        onChange={e => this.updateSort(e)}
                                        plain
                                    />
                                </Box>
                            )}
                            {this.props.downloadable &&
                                <CSVLink data={this.Export_Data ? this.Export_Data : []} filename={this.ExportFileName}>
                                    <a data-for="Download Info" data-tip="Export to CSV" >
                                        <Button plain icon={<Download color={constants.HPE_GREEN} />} alignSelf="center" />
                                    </a>
                                    <ReactTooltip id="Download Info" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                                </CSVLink>}
                        </Box>
                    </Box>
                </Box>
                {/* <Box gridArea='data' direction="row" justify="between" basis="full" align="center" > */}
                <Box gridArea='data' >
                    <Grommet theme={hpe} >
                        {/* <Box direction="row" justify="between" basis="full" > */}
                        {/* <ThemeContext.Extend value={{ global: { colors: { border: '#333333' } } }} > */}
                        <Box>
                            <GrommetDataTable data={displayDATA} onSearch={this.customSearch} {...restprops}
                                onSort={(params) => {
                                    this.setState({ property: params.property, direction: params.direction });
                                }} />
                        </Box>
                        {/* </ThemeContext.Extend> */}
                    </Grommet>
                </Box>
                {(this.props.noCounter && this.props.data.length <= this.state.rows) ?
                    <Box pad={{ "bottom": "medium" }}>
                    </Box>
                    :
                    <Box gridArea='footer' direction="row" justify="between" align="center" pad={{ vertical: "none", horizontal: "none" }} background={this.props.bg ? this.props.bg : constants.BACKGROUND_COLOR} gap='xsmall'>
                        {/* <Box align="center">
                        {this.state.page > 1 && (<StyledButton typeCancel label="Back" onClick={() => {
                            if (this.state.page > 1) {
                                this.setState({ page: this.state.page - 1 })
                            }
                        }} />)}
                    </Box>
                    <Box align="center">
                        {this.state.data.length / displayrows > this.state.page && (
                            <Button style={{ background: "white" }} label="Next" onClick={() => {
                                // console.log(this.state.page, this.props.categoriesdata.length / rows);
                                if (this.state.data.length / displayrows > this.state.page)
                                    this.setState({ page: this.state.page + 1 })
                            }} />)}
                    </Box> */}
                        <Box align="center" nowrap>
                            <Pagination
                                nextPageText='Next'
                                // nextPageText={<FormNext />}
                                prevPageText='Previous'
                                firstPageText='First'
                                lastPageText='Last'
                                activePage={this.state.page}
                                itemsCountPerPage={this.state.rows}
                                totalItemsCount={this.props.count ? this.props.count : searchedData.length}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </Box>
                        <Box align="center" width="100px" background={constants.BOX_BACKGROUND_COLOR_1} border="all">
                            <Select
                                //size="small"
                                value={this.state.rows}
                                options={this.options}
                                onChange={e => this.updateRows(e)}
                                plain
                            />
                        </Box>
                        <Box align="center">
                            <Text alignSelf="end">{`${from} - ${to} of ${this.props.count ? this.props.count : searchedData.length} items`}</Text>
                        </Box>
                    </Box>}
            </Grid>
        )
    }
}



function withstyledPagination(GrommetDataTable) {
    return class extends Component {

        render() {
            const { displayrows = 20, sortoptions = [], ...restprops } = this.props;

            return <ServedDataTable grommetDataTable={GrommetDataTable} displayrows={displayrows} sortoptions={sortoptions} {...restprops} />
        }
    }
};

export default withstyledPagination;




