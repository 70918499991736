import React from 'react';
//eslint-disable-next-line
import { TextInput } from 'grommet';

export default class TextInputWithState extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: props.value };
    }
    componentDidUpdate(prevProps) {
        if (this.props.value != prevProps.value) {
            this.setState({ value: this.props.value });
        }
    }
    onChange = event => {
        let value = event.target.value;
        //console.log(`Value: ${value}`);
        this.setState({ value: event.target.value });
    }
    render() {
        //console.log(`render TextInputWithState vith props: ${this.props.value}`);
        let { value } = this.state;
        const { value: pvalue, ...rest } = this.props;
        return (
            <TextInput {...rest} value={value} onChange={this.onChange} />
        )
    }
}