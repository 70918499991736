import * as ActionType from './ActionType';
import LevelApi from '../api/LevelApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';


export const getLevelsResponse = levelsdata => ({
    type: ActionType.GET_LEVELS_RESPONSE,
    levelsdata
});

export function getLevelsAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return LevelApi.getAllLevels()
            .then(levelsdata => {
                dispatch(getLevelsResponse(levelsdata));
                return levelsdata;
            }).catch(error => {
                throw error;
            });
    };
}