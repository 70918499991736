import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const mergedLevelsReducer = (state = initialState.mergedlevelsReducer, action) => {
    switch(action.type) {
        case ActionType.GET_MERGEDLEVELS_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                mergedlevelsdata: _.assign(action.mergedlevelsdata)
            };
        }


        default: { return state; }
    }
};



export default mergedLevelsReducer;