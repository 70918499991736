import * as ActionType from './ActionType';
import AppApi from '../api/AppApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getAppsResponse = appsdata => ({
    type: ActionType.GET_APPS_RESPONSE,
    appsdata
});

const queuedGetAllApps = queueApiCall(AppApi.getAllApps);

export function getAppsAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return AppApi.getAllApps()
        return queuedGetAllApps()
            .then(appsdata => {
                dispatch(getAppsResponse(appsdata));
                return appsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewAppResponse = () => ({
    type: ActionType.ADD_NEW_APP_RESPONSE
});

export const updateExistingAppResponse = () => ({
    type: ActionType.UPDATE_EXISTING_APP_RESPONSE
});


export function saveAppAction(appdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the app is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new app that is being added, therefore add it
        return AppApi.saveApp(appdraft)
            .then((response) => {
                if (appdraft.id) {
                    dispatch(updateExistingAppResponse());
                } else {
                    dispatch(addNewAppResponse());
                }
                return response;
            }).then((response) => {
                //if (appdraft.id == "0") {
                //    dispatch(getAppAction(appdraft.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}


export const getAppResponse = appdata => ({
    type: ActionType.GET_APP_RESPONSE,
    appdata
});


export function getAppAction(appId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return AppApi.getApp(appId)
            .then(appdata => {
                dispatch(getAppResponse(appdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}


export const deleteAppResponse = () => ({
    type: ActionType.DELETE_APP_RESPONSE
});

const queuedDeleteApp = queueApiCall(AppApi.deleteApp);

export function deleteAppAction(appId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return AppApi.deleteApp(appId)
        return queuedDeleteApp(appId)
            .then((response) => {
                dispatch(deleteAppResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getAppsAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}