
import { LogLevel } from '@azure/msal-browser';
import { map, transform, isEqual, isObject, difference } from 'lodash';

export default {

    //RELEASE_TYPE
    RELEASE_TYPE: "HPE-MTF", //"MTF" or "HPE-MTF" or "PROD" or "PROD-MTF" or "PROD-MTF-STG"
    VERSION: "3.3.2", //application version

    API_URL: "https://localhost:5001/api",

    //ADALCONFIG
    multiConfig: {
        HPE_ONLY: {
            tenant: "105b2061-b669-4b31-92ac-24d304d195dc",
            clientId: "a8924b94-789b-4afb-9c9c-03cb66a3cb1c",
            loginResource: "a8924b94-789b-4afb-9c9c-03cb66a3cb1c",
            endpoints: {
                api: "377b1853-9640-422d-9316-821b5c24e6e8"
            },
        },
        MULTI_TENANT: {
            tenant: "common",
            clientId: "d76e0dde-08fe-4c1b-887b-d1bbe8d8aec6",
            loginResource: "d76e0dde-08fe-4c1b-887b-d1bbe8d8aec6",
            endpoints: {
                api: "afae53b9-33f6-4bd6-9bb1-00c0e85d6ad8"
            },
        },
        PROD: {
            tenant: "105b2061-b669-4b31-92ac-24d304d195dc",
            clientId: "e36150b0-7727-4b4e-85a4-a8ed3991d3dc",
            loginResource: "e36150b0-7727-4b4e-85a4-a8ed3991d3dc",
            endpoints: {
                api: "380fbeeb-6af6-49d2-85b4-c5bee8650184"
            },
        },
        PROD_MULTI_TENANT: {
            tenant: "common",
            //clientId: "98c074a4-2025-41d1-9ed5-d7ca779a4f67", // in the HPE tenant
            clientId: "e49f26b3-0664-48ad-a93a-00352315fb94", // in the HPE Intelligent Spaces tenant
            //loginResource: "98c074a4-2025-41d1-9ed5-d7ca779a4f67",
            loginResource: "e49f26b3-0664-48ad-a93a-00352315fb94",
            endpoints: {
                //api: "df298ecf-1f2a-4d37-acfb-f8314bbec41d"
                api: "597b7b3a-9f06-4629-b2d6-f05fc5eca9cf"
            },
        },
    },
    //MSAL
    scopes: function () {
        if (this.RELEASE_TYPE === "MTF") {
            return ["afae53b9-33f6-4bd6-9bb1-00c0e85d6ad8/.default"];
        }
        else if (this.RELEASE_TYPE === "HPE-MTF") {
            return ["377b1853-9640-422d-9316-821b5c24e6e8/.default"];
        }
        else if (this.RELEASE_TYPE === "PROD") {
            return ["377b1853-33f6-4bd6-9bb1-00c0e85d6ad8/.default"];
        }
        else if (this.RELEASE_TYPE === "PROD-MTF") {
            //return ["df298ecf-1f2a-4d37-acfb-f8314bbec41d/.default"];
            return ["597b7b3a-9f06-4629-b2d6-f05fc5eca9cf/.default"];
        }
        else if (this.RELEASE_TYPE === "PROD-MTF-STG") {
            return ["597b7b3a-9f06-4629-b2d6-f05fc5eca9cf/.default"];
        }
    },

    MSALconfiguration: function () {
        if (this.RELEASE_TYPE === "MTF") {
            return {
                auth: {
                    clientId: "d76e0dde-08fe-4c1b-887b-d1bbe8d8aec6",
                    authority: "https://login.microsoftonline.com/common/",
                    redirectUri: window.location.origin,
                    postLogoutRedirectUri: window.location.origin,
                    //navigateToLoginRequestUrl: true
                },
                cache: {
                    cacheLocation: 'localStorage'
                },
                system: {
                    loggerOptions: {
                        loggerCallback: (level, message, containsPii) => {
                            if (containsPii) {
                                return;
                            }
                            switch (level) {
                                case LogLevel.Error:
                                    console.error(message);
                                    return;
                                case LogLevel.Info:
                                    console.info(message);
                                    return;
                                case LogLevel.Verbose:
                                    console.debug(message);
                                    return;
                                case LogLevel.Warning:
                                    console.warn(message);
                                    return;
                            }
                        },
                        piiLoggingEnabled: false
                    },
                }
            };
        }
        else if (this.RELEASE_TYPE === "HPE-MTF") {
            return {
                auth: {
                    clientId: "a8924b94-789b-4afb-9c9c-03cb66a3cb1c",
                    authority: "https://login.microsoftonline.com/105b2061-b669-4b31-92ac-24d304d195dc/",
                    redirectUri: window.location.origin,
                    postLogoutRedirectUri: window.location.origin,
                    //navigateToLoginRequestUrl: true
                },
                cache: {
                    cacheLocation: 'localStorage'
                },
            };
        }
        else if (this.RELEASE_TYPE === "PROD") {
            return {
                auth: {
                    clientId: "",
                    authority: "",
                    redirectUri: window.location.origin,
                    postLogoutRedirectUri: window.location.origin,
                    //navigateToLoginRequestUrl: true
                },
                cache: {
                    cacheLocation: 'localStorage'
                },
            };
        }
        else if (this.RELEASE_TYPE === "PROD-MTF") {
            //currently same as DEV
            return {
                auth: {
                    //clientId: "98c074a4-2025-41d1-9ed5-d7ca779a4f67",
                    clientId: "e49f26b3-0664-48ad-a93a-00352315fb94",
                    authority: "https://login.microsoftonline.com/common/",
                    redirectUri: window.location.origin,
                    postLogoutRedirectUri: window.location.origin,
                    navigateToLoginRequestUrl: true,
                    validateAuthority: true
                },
                cache: {
                    // cacheLocation: 'sessionStorage',
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: false
                },
                system: {
                    navigateFrameWait: 0,
                    logger: {
                        error: console.error,
                        errorPii: console.error,
                        info: console.log,
                        infoPii: console.log,
                        verbose: console.log,
                        verbosePii: console.log,
                        warning: console.warn,
                        warningPii: console.warn
                    }
                }
            };
        }
        else if (this.RELEASE_TYPE === "PROD-MTF-STG") {
            //currently same as DEV
            return {
                auth: {
                    //clientId: "98c074a4-2025-41d1-9ed5-d7ca779a4f67",
                    clientId: "e49f26b3-0664-48ad-a93a-00352315fb94",
                    authority: "https://login.microsoftonline.com/common/",
                    redirectUri: window.location.origin,
                    postLogoutRedirectUri: window.location.origin,
                    navigateToLoginRequestUrl: true,
                    validateAuthority: true
                },
                cache: {
                    // cacheLocation: 'sessionStorage',
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: false
                },
                system: {
                    navigateFrameWait: 0,
                    logger: {
                        error: console.error,
                        errorPii: console.error,
                        info: console.log,
                        infoPii: console.log,
                        verbose: console.log,
                        verbosePii: console.log,
                        warning: console.warn,
                        warningPii: console.warn
                    }
                }
            };
        }
    },
    //BOOKADESK
    BOOKVIAGRAPH: false,

    //BACKGROUND
    BACKGROUND_COLOR: "#FFFFFF",
    LAYOUT_COLOR: "#FFFFFF",
    HPE_GREEN: "#01A982",
    HEADER_COLOR: "#FAFAFA",

    //BUTTON COLORS
    BUTTON_LABEL_COLOR_1: "#000000",    //PURE BLACK
    BUTTON_LABEL_COLOR_2: "#FFFFFF",    //PURE WHITE
    BUTTON_LABEL_COLOR_3: "#01A982",    //HPE GREEN

    BUTTON_BACKGROUND_COLOR_1: "#01A982",   //HPE GREEN
    BUTTON_BACKGROUND_COLOR_2: "#FFFFFF",   //PURE WHITE
    BUTTON_BACKGROUND_COLOR_3: "#FF8D6D",   //ORANGE

    BUTTON_BORDER_COLOR_1: "#01A982",   //HPE GREEN
    BUTTON_BORDER_COLOR_2: "#FFFFFF",   //PURE WHITE
    BUTTON_BORDER_COLOR_3: "#FF8D6D",   //ORANGE

    //BOX COLORS
    BOX_BACKGROUND_COLOR_1: "#FFFFFF",   //PURE WHITE
    BOX_BACKGROUND_COLOR_2: "#000000",  //PURE BLACK
    BOX_BACKGROUND_COLOR_3: "",   //

    //SVG Labels:
    GREEN_LABEL: "OK",
    YELLOW_LABEL: "Warning",
    RED_LABEL: "Critical",

    // Error messages:
    DATA_ERROR: "Data saving error, please retry later!",
    FORM_ERROR: "This form contains field errors, please check and correct the data and retry saving!",
    DETAIL_HTTP_ERROR: "Detail contains unsecure hyperlinks, please ensure all links follow https formatting",
    DOMAIN_THIS_ORG_ERROR: "This domain is already assigned to this organization",
    DOMAIN_NOT_UNIQUE_ERROR: "This domain is already assigned to a different organization",
    DOMAIN_INVALID_ERROR: "Invalid domain",
    URL_OPTIONS: { protocols: ["http", "https"], require_protocol: true },
    URL_HTTPS_ONLY: { protocols: ["https"], require_protocol: true },
    NUMBER_ONLY_ERROR: "This field can contain numbers only",
    INT_ERROR: "This field can only contain numbers between -2147483648 and 2147483647",
    EMAIL_ERROR: "Invalid e-mail address",
    EMPTY_ERROR: "This field can not be left empty",
    URL_ERROR: "Please ensure the URL is properly formed (include http:// or https:// as required)",
    URL_ERROR_HTTPS_ONLY: "Please ensure the URL is properly formed (include https:// as required)",
    URI_ERROR: "Please ensure the URI is properly formed (must include ://)",
    PHONE_HELP: "Correct format: +<Prefix><Number>, where numbers can be separeted by space, + is optional, Prefix 1-4 digits and Number 5-10 digits",
    PHONE_ERROR: "Please ensure the Phone Number is properly formated",
    TIME_ERROR: "End hour must be greater than start hour",
    TIME_HELP: "Correct format: hh:mm - hh:mm, where hours and minutes are 2 digits",
    MODE: ["Open in App", "Open in Browser"],
    MOREINFO_LINES: 32,
    REGEX: {
        EMAIL: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/gm,
        URL: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
        URL_HTTPS: /^(?:(?:(?:https):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
        URI: /^.*\:\/\/.*$/,
        PHONE: /^\+?((\([0-9]{1,4}\))\s?|([0-9]{1,4})\s?){4}[0-9]{1,4}$/,
        PHONEOLD: /^\+([1-9][0-9]{0,3})(\d{5,10})$/,
        CODE: /^[a-zA-Z0-9_@#.:-]*$/,
        COORDINATES: /^([-+]?)([\d]{1,2})(((\.)(\d+)(, )))(([-+]?)([\d]{1,3})((\.)(\d+))?)$/, //53.2926521, -9.007333
        NUMBER: /^([1-9][0-9]*|0{1})$/,
        //COLLECTION: /^(\W{0,1}((\w){1,}\W{0,1}){1,})$/,
        COLLECTION: /^([^a-zA-Z0-9]{0,1}(([a-zA-Z0-9]){1,}[^a-zA-Z0-9]{0,1}){1,})$/,
        //COLLECTION: /^([\-|\_]{0,1}(([a-zA-Z0-9]){1,}[\-|\_]{0,1}){1,})$/,
        NONWORD: /[^a-zA-Z0-9]/
    },
    PAGE_SIZE: 25,
    PATH_TAB1: ['/home', '/event', '/eventstable', '/categories', '/category', '/pages', '/page', '/placemarks', '/placemark', '/locusers', '/locuser', '/locroles', '/configuration', '/integrations', '/sites', '/mapping', '/beacons', '/spots', '/actioncentre', '/quickaction', '/promotedaction', '/form', '/app', '/meridianmap', '/assets', '/asset', '/building', '/level', '/lochealthpass', '/tags', '/tag', '/createtag', '/edittags', '/tracker', '/link', '/trackers', '/label', '/departments', '/department', '/collections', '/collection', '/editbeacons', '/bookspacemap', '/mybookings', '/overview'],
    PATH_TAB2: ['/orglist', '/orgsettings', '/appdetails', '/apptheme', '/orgsites', '/orgsite', '/orgappintegration', '/privacy', '/apptheme', '/users', '/user', '/roles', '/profiles', '/noaccess', '/appanalytics', '/notifications', '/notification',
        '/orgintegrations', '/orgintegrations/oauth2', '/orgintegrations/clearpass', '/orgintegrations/meridian', '/orgintegrations/teem', '/orgintegrations/buildingmanagement', '/orgintegrations/chargifi', '/orgintegrations/microsoft365', '/healthpass', '/orgreport', '/orgtags', '/orgtag', '/orgcreatetag/', '/orgcreatemetadata', '/orgdepartments', '/orgdepartment', '/orgcollections', '/orgcollection', '/orglabel', '/orgtrackers', '/orgtracker', '/orgmetadata', '/orgassets', '/orgasset', '/orglink', '/orgedittags'],
    PATH_TAB3: ['/masterapps', '/masterapp'],

    API_VERSION: "ZUMO-API-VERSION=2.0.0",

    // custom toolbar options for html editor
    CUSTOM_TOOLBAR: "↲",
    HTML_EDITOR_TOOLBAR: {
        options: ["inline", "blockType", "textAlign", "image", "link", "list", "history"],
        textAlign: { inDropdown: false },
        blockType: { inDropdown: true },
        inline: { inDropdown: false },
        list: { inDropdown: true },
        link: { inDropdown: false },
        history: { inDropdown: false },
        image: {
            alt: { present: true, mandatory: false },
            urlEnabled: true,
            uploadEnabled: false,
        }
    },
    //organisation: {id: "0123456789abcdef0123456789abcdef", label: "HPE Demo", code: "HPE"}, //demo
    //organisation: {id: "4c5b5f821c2045d1bf2b2248b3167cd9", label: "Hewlett Packard Enterprise"}, //test
    //organisation: {id: "f810472372084ee3a6c0141cc3f427ce", label: "Hewlett Packard Enterprise", code: "HPE"}, //release

    COPYYEAR: new Date().getFullYear().toString(), //Copyright Year
    ANDROID_VERSION: "1.2",
    IOS_VERSION: "1.2",
    ASSET_STATUS: [
        { name: "Available for use", id: 1 },
        { name: "Booked", id: 2 },
        { name: "Cleaning", id: 5 },
        { name: "Cleaning required", id: 6 },
        { name: "Maintenance", id: 3 },
        { name: "Maintenance required", id: 4 },
        { name: "Unknown", id: 0 }
    ],

    //DEFAULT QUICK ACTIONS
    DEFAULT_QUICK_ACTIONS: ["BOOKSPACE", "LOCATEPEOPLE", "MAP", "REPORTISSUE", "FACILITIES", "INFO", "SERVICES", "SPACES", "ASSETS"],

    // Permission Types:
    PERMS: {
        SUP_SUPERUSER: "Superuser",
        // per Location
        LOC_ADMINISTRATION: "Administration",
        LOC_CONF_SITE: "SiteSettings",
        LOC_CONF_INTEGR: "Integration",
        LOC_CONF_MAPS: "Maps",
        LOC_CONTENT: "Content",
        LOC_NOTIFICATIONS: "Notifications",
        LOC_EVENTS: "Events",
        LOC_SERVICES: "LocationServices",
        // per Organisation
        ORG_ADMINISTRATION: "Org_Administration",
        ORG_CONF_SITE: "SiteSettings",
        ORG_CONF_INTEGR: "Integration",
        ORG_CONF_MAPS: "Maps",
        ORG_CONTENT: "Content",
    },

    // Role Levels:
    ROLE_LEVEL: {
        SUP: "Superuser",
        LOC: "Location",
        ORG: "Organization",
    },
    ISMERIDIANVISIBLE: true,
    SET_ISMERIDIANVISIBLE: function (param) {
        this.ISMERIDIANVISIBLE = param;
    },
    ISCHARGIFIVISIBLE: true,
    SET_ISCHARGIFIVISIBLE: function (param) {
        this.ISCHARGIFIVISIBLE = param;
    },
    quickActionWarning: function (type) {
        return `Quick Actions associated with this ${type} will no longer be visible in the app.`;
    },
    confirmMessage: function (type) {
        return `Please confirm that you want to delete this ${type}.`;
    },
    APP_LP: function (location, parent) {
        if (location == parent) {
            return location;
        }
        if (location == "" || location == null) {
            return "";
        }
        else {
            return location + " / " + parent;
        }
    },
    APP_LPN: function (location, parent, name) {
        if (location != "" && location != null) {
            var parentloc = this.APP_LP(location, parent);
            var lpn = this.APP_LP(parentloc, name);
        }
        else {
            var lpn = name;
        }
        return lpn;
    },
    visible: function (bool) {
        if (bool) {
            return "visible";
        }
        else {
            return "hidden";
        }
    },
    html_sanitize: function (input) {
        let html = input;
        html = html.replace(/<\s*span style=[^>]*>/g, "");
        html = html.replace(/<\/span>/g, "");
        return html;
    },
    release: function (release_type = this.RELEASE_TYPE) {
        if (release_type == "MTF") {
            return {
                adalConfig: {
                    tenant: sessionStorage.getItem("Login_Client_Type") == "HPE_ONLY" ? this.multiConfig.HPE_ONLY.tenant : this.multiConfig.MULTI_TENANT.tenant, //MTF HPE_ONLY : MULTI_TENANT
                    clientId: sessionStorage.getItem("Login_Client_Type") == "HPE_ONLY" ? this.multiConfig.HPE_ONLY.clientId : this.multiConfig.MULTI_TENANT.clientId, //MTF HPE_ONLY : MULTI_TENANT
                    endpoints: {
                        api: sessionStorage.getItem("Login_Client_Type") == "HPE_ONLY" ? this.multiConfig.HPE_ONLY.endpoints.api : this.multiConfig.MULTI_TENANT.endpoints.api, //MTF HPE_ONLY : MULTI_TENANT
                    },
                    cacheLocation: "sessionStorage",
                    redirectUri: window.location.origin,
                },
                Serverurl: "https://hpepngmywpdemo.azurewebsites.net",
                Insightsurl: "https://api.applicationinsights.io/v1/apps/6a1e2b81-230f-406a-a2dd-720d0dc7c87f/query?query=",
                Insightskey: "clqo683l7ofz2vgl0quzegoet7nh4ip8tmv2dols",
                Tenants: "Multi",
                Prod: false,
                Feature_assets: true,
                Feature_chargifi: true,
                Feature_citrix: true
            }
        }

        else if (release_type == "HPE-MTF") {
            return {
                adalConfig: {
                    tenant: this.multiConfig.HPE_ONLY.tenant, //itg HPE-MTF
                    clientId: this.multiConfig.HPE_ONLY.clientId, //itg HPE-MTF
                    endpoints: {
                        api: this.multiConfig.HPE_ONLY.endpoints.api, //itg HPE-MTF
                    },
                    cacheLocation: "sessionStorage",
                    redirectUri: window.location.origin,
                },
                //Serverurl: "https://myworkplace-itg.intelligentspaces.int.hpe.com/api",
                Serverurl: "https://myworkplace-itg.azurefd.net/api",
                Insightsurl: "https://api.applicationinsights.io/v1/apps/3f439d2f-f4b8-4668-a98e-1dfaa7a7046c/query?query=",
                Insightskey: "5r1nw1owccn4vieo2ea8tduvgx6aml7f8wozsrcb",
                Tenants: "Single",
                Prod: false,
                Feature_assets: true,
                Feature_chargifi: true,
                Feature_citrix: true
            };
        }

        else if (release_type == "PROD") {
            return {
                adalConfig: {
                    tenant: this.multiConfig.PROD.tenant, //PROD
                    clientId: this.multiConfig.PROD.clientId, //PROD
                    endpoints: {
                        api: this.multiConfig.PROD.endpoints.api, //PROD
                    },
                    cacheLocation: "sessionStorage",
                    redirectUri: window.location.origin,
                },
                Serverurl: "https://myworkplace.intelligentspaces.int.hpe.com/api",
                Insightsurl: "https://api.applicationinsights.io/v1/apps/cc1a64bd-eac0-4303-8aa2-ed3f13262aad/query?query=",
                Insightskey: "qjfkkoqhe21pknp4wv4do2buuka8qbeyfbcznmq0",
                Tenants: "Single",
                Prod: true,
                Feature_assets: true,
                Feature_chargifi: true,
                Feature_citrix: true
            };
        }

        else if (release_type == "PROD-MTF") {
            return {
                adalConfig: {
                    tenant: sessionStorage.getItem("Login_Client_Type") == "HPE_ONLY" ? this.multiConfig.HPE_ONLY.tenant : this.multiConfig.PROD_MULTI_TENANT.tenant, //MTF HPE_ONLY : MULTI_TENANT
                    clientId: sessionStorage.getItem("Login_Client_Type") == "HPE_ONLY" ? this.multiConfig.HPE_ONLY.clientId : this.multiConfig.PROD_MULTI_TENANT.clientId, //MTF HPE_ONLY : MULTI_TENANT
                    endpoints: {
                        api: sessionStorage.getItem("Login_Client_Type") == "HPE_ONLY" ? this.multiConfig.HPE_ONLY.endpoints.api : this.multiConfig.PROD_MULTI_TENANT.endpoints.api, //MTF HPE_ONLY : MULTI_TENANT
                    },
                    cacheLocation: "sessionStorage",
                    redirectUri: window.location.origin,
                },
                Serverurl: "https://intelligentspaces.hpe.com/api",
                Insightsurl: "https://api.applicationinsights.io/v1/apps/b7f8ebae-39ed-49a5-ac13-b42dc63433cb/query?query=",
                Insightskey: "5g32iyf51rayrq7eg11jb4b6anoplv08t6drq05z",
                Tenants: "Multi",
                Prod: true,
                Feature_assets: true,
                Feature_chargifi: true,
                Feature_citrix: true
            }
        }
        else if (release_type == "PROD-MTF-STG") {
            return {
                adalConfig: {
                    tenant: sessionStorage.getItem("Login_Client_Type") == "HPE_ONLY" ? this.multiConfig.HPE_ONLY.tenant : this.multiConfig.PROD_MULTI_TENANT.tenant, //MTF HPE_ONLY : MULTI_TENANT
                    clientId: sessionStorage.getItem("Login_Client_Type") == "HPE_ONLY" ? this.multiConfig.HPE_ONLY.clientId : this.multiConfig.PROD_MULTI_TENANT.clientId, //MTF HPE_ONLY : MULTI_TENANT
                    endpoints: {
                        api: sessionStorage.getItem("Login_Client_Type") == "HPE_ONLY" ? this.multiConfig.HPE_ONLY.endpoints.api : this.multiConfig.PROD_MULTI_TENANT.endpoints.api, //MTF HPE_ONLY : MULTI_TENANT
                    },
                    cacheLocation: "sessionStorage",
                    redirectUri: window.location.origin,
                },
                Serverurl:  "https://hpeintelligentspaces-us-stg.azurewebsites.net",
                Insightsurl: "https://api.applicationinsights.io/v1/apps/b7f8ebae-39ed-49a5-ac13-b42dc63433cb/query?query=",
                Insightskey: "5g32iyf51rayrq7eg11jb4b6anoplv08t6drq05z",
                Tenants: "Multi",
                Prod: true,
                Feature_assets: true,
                Feature_chargifi: true,
                Feature_citrix: true
            }
        }

        else {
            return {
                adalConfig: {
                    tenant: "", //
                    clientId: "", //
                    endpoints: {
                        api: "", //
                    },
                    cacheLocation: "sessionStorage",
                    redirectUri: window.location.origin,
                },
                Serverurl: "",
                Insightsurl: "",
                Insightskey: "",
                Tenants: "",
                Feature_assets: false,
                Feature_chargifi: false,
                Feature_citrix: false
            };
        }

    },
    byPropertyCalled: function (property = "name", order = "asc") {
        return function (a, b) {
            const alc = a[property] ? a[property].toLowerCase() : "";
            const blc = b[property] ? b[property].toLowerCase() : "";
            if (alc < blc) {
                return order == "asc" ? -1 : 1;
            }
            else if (alc > blc) {
                return order == "asc" ? 1 : -1;
            }
            else {
                return 0;
            }
        };
    },
    formatDate: function (utc) {
        var date = new Date(utc);
        var strDate =
            date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2)
            + "-" + ("00" + date.getDate()).slice(-2)
            + " "
            + ("00" + date.getHours()).slice(-2) + ":"
            + ("00" + date.getMinutes()).slice(-2)
            + ":" + ("00" + date.getSeconds()).slice(-2);
        return strDate;
    },
    removeText(type) {
        return `The previous ${type} will be removed and no ${type} will be assigned.`;
    },
    compareObjects(x, y) {
        if (_.isEqual(x, y)) {
            return "Equal"
        }
        else {
            return transform(x, (result, value, key) => {
                if (!isEqual(value, y[key])) {
                    result[key] = isObject(value) && isObject(y[key]) ? difference(value, y[key]) : value;
                }
            });
        }
    },
    filterActiveColumns(all, filteredColumns) {
        return all.filter(c => {
            let head = filteredColumns.find(i => i.property == c.property);
            if (head) {
                if (head.isActive)
                    return true;
                return false;
            }
            //init
            return true;
        });
    },
    createFilters(all) {
        let filteredColumns = all.filter(c => c.header);
        filteredColumns.map(i => {
            if (!i.hasOwnProperty("isActive"))
                i.isActive = true;
            //i.isFixed = false;
        });
        return filteredColumns;
    },
    getSiteLevelRoute() {
        if (sessionStorage.getItem("organisationId")) {
            if (sessionStorage.getItem("locationId")) {
                return `/${sessionStorage.getItem("organisationId")}/${sessionStorage.getItem("locationId")}`;
            }
        }
        return "";
    },
    getOrgLevelRoute() {
        if (sessionStorage.getItem("organisationId")) {
            return `/${sessionStorage.getItem("organisationId")}`;
        }
        return "";
    }
};
