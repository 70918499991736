import styled, { css  } from 'styled-components';
import { Tabs, Tab } from 'grommet';
import constants from '../../constants';

export const StyledTabs = styled(Tabs)`

      ${props => props.style1 && css`

      `}
`;

export const StyledTab = styled(Tab)`

      ${props => props.style1 && css`
        /*border-left: 5px solid transparent;
        border-right: 5px solid transparent;*/
        margin-left: 5px;
        margin-right: 5px;

        :hover {
            background: #DADADA;
            box-shadow: 0px 0px 0px 2px #333333;
        }
      `}


`;
