import * as ActionType from './ActionType';
import MasterAppApi from '../api/MasterAppApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getMasterAppsResponse = masterappsdata => ({
    type: ActionType.GET_MASTERAPPS_RESPONSE,
    masterappsdata
});

const queuedGetAllMasterApps = queueApiCall(MasterAppApi.getAllMasterApps);

export function getMasterAppsAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return AppApi.getAllApps()
        return queuedGetAllMasterApps()
            .then(masterappsdata => {
                dispatch(getMasterAppsResponse(masterappsdata));
                return masterappsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewMasterAppResponse = () => ({
    type: ActionType.ADD_NEW_MASTERAPP_RESPONSE
});

export const updateExistingMasterAppResponse = () => ({
    type: ActionType.UPDATE_EXISTING_MASTERAPP_RESPONSE
});

export function saveMasterAppAction(masterappdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the app is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new app that is being added, therefore add it
        return MasterAppApi.saveMasterApp(masterappdraft)
            .then((response) => {
                if (masterappdraft.id) {
                    dispatch(updateExistingMasterAppResponse());
                } else {
                    dispatch(addNewMasterAppResponse());
                }
                return response;
            }).then((response) => {
                //if (masterappdraft.id == "0") {
                //    dispatch(getMasterAppAction(masterappdraft.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getMasterAppResponse = masterappdata => ({
    type: ActionType.GET_MASTERAPP_RESPONSE,
    masterappdata
});

export function getMasterAppAction(masterappId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return MasterAppApi.getMasterApp(masterappId)
            .then(masterappdata => {
                dispatch(getMasterAppResponse(masterappdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteMasterAppResponse = () => ({
    type: ActionType.DELETE_MASTERAPP_RESPONSE
});

const queuedDeleteMasterApp = queueApiCall(MasterAppApi.deleteMasterApp);

export function deleteMasterAppAction(masterappId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return AppApi.deleteApp(appId)
        return queuedDeleteMasterApp(masterappId)
            .then((response) => {
                dispatch(deleteMasterAppResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getMasterAppsAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}