import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AssetAction from '../action/AssetAction';
import * as TrackerAction from '../action/TrackerAction';
import * as AssociationAction from '../action/AssociationAction';
import * as SiteAction from '../action/SiteAction';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';

var start = undefined;

export class OrgLinkEditContainer extends React.Component {
    constructor() {
        super();
        this.trackerName_err = "";
        this.tagName_err = "";
        this.siteName_err = "";

        this.state = {
            isSaved: false,
            open: false,
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            selectedTag: "",
            selectedTracker: "",
            isAlreadyLinked: false,
            warningText: "",
            apiErrorCounter: 0,
            selectedSite: ""

        };
    }
    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        this.props.action.getAssetsAction("", true)
            .catch(error => {
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1 })
                console.log('*DEBUG*- Assets List Error', error);
            });
        this.props.action.getTrackersAction()
            .catch(error => {
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1 })
                console.log('*DEBUG*- Tracker List Error', error);
            });
        this.props.action.getAssociationsAction()
            .catch(error => {
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1 })
                console.log('*DEBUG*- Tracker List Error', error);
            });
        this.props.action.getSitesAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1 })
                console.log('*DEBUG*- Sites List Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && (this.state.selectedTag != "" || this.state.selectedTracker != "") && !this.state.isAlreadyLinked) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }
    ClearErrorMessages() {
        this.trackerName_err = "";
        this.tagName_err = "";
        this.siteName_err = "";
    }
    handleSave() {
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ validating: false });
        if (this.state.selectedSite == "") {
            errorsno++;
            this.siteName_err = "Select a Site";
        }
        if (this.state.selectedTracker == "") {
            errorsno++;
            this.trackerName_err = "Select a Tracker";
        }
        if (this.state.selectedTag == "") {
            errorsno++;
            this.tagName_err = "Select a Tag";
        }

        if (errorsno == 0) {
            let isTrackerAlreadyAssigned = false;
            let isTagAlreadyAssigned = false;
            this.props.associationsdata.forEach((item, idx) => {
                if (item.assetId != "" && item.assetName != "" && item.beaconId != "" && item.beaconName != "") {
                    if (item.assetId == this.state.selectedTracker.id) {
                        isTrackerAlreadyAssigned = true;
                    }
                    if (item.beaconId == this.state.selectedTag.id) {
                        isTagAlreadyAssigned = true;
                    }
                    if (isTrackerAlreadyAssigned && isTagAlreadyAssigned) {
                        return;
                    }
                }
            });
            //open popup if at least one of the selected values is already assigned AND popup is not opened
            if ((isTrackerAlreadyAssigned || isTagAlreadyAssigned) && !this.state.isAlreadyLinked) {
                let text = "";
                if (isTrackerAlreadyAssigned && isTagAlreadyAssigned)
                    text = "The selected tracker and tag are already linked";
                else if (isTrackerAlreadyAssigned)
                    text = "The selected tracker is already linked";
                else
                    text = "The selected tag is already linked";

                this.setState({ warningText: text, isAlreadyLinked: true });
            }
            else {
                var association = {
                    assetId: this.state.selectedTracker.id,
                    assetName: this.state.selectedTracker.name,
                    beaconId: this.state.selectedTag.id,
                    beaconName: this.state.selectedTag.name
                };
                this.props.action.saveAssociationAction(association)
                    .then((response) => {
                        if (association.assetId != null) {
                            //it means we are creating a link, not deleting
                            this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                        }
                    })
                    .catch(error => {
                        console.log('*DEBUG*-Save Association Error', error);
                    });
            }
        }
        else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        if (!this.state.cancel && (this.state.selectedTag != "" || this.state.selectedTracker != "")) {
            this.setState({ cancel: true });
        }
        else {
            if (sessionStorage.getItem("organisationId"))
                this.props.history.push({
                    pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                    state: { index: 2 }
                });
            else
                this.props.history.push({
                    pathname: '/orgtrackers',
                    state: { index: 2 }
                });
        }
    }

    onClose() {
        this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            if (sessionStorage.getItem("organisationId"))
                this.props.history.push({
                    pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                    state: { index: 2 }
                });
            else
                this.props.history.push({
                    pathname: '/orgtrackers',
                    state: { index: 2 }
                });
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onX() {
        this.setState({ isAlreadyLinked: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        console.log("halo");
        this.setState({ cancel: false, isAlreadyLinked: false });
        this.handleSave();
    }

    render() {
        //Dont have any conditional for the Loading Indicator
        //if (false) {
        //    return (
        //        <LoadingIndicator error={this.state.apiErrorCounter}/>
        //    );
        //}
        const heading = this.props.match.params.linkid != 0 ? 'Edit' : 'Create';
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Link - {heading} </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={() => this.handleSave()} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ horizontal: "medium" }} fill="vertical" style={{ minHeight: "500px" }}>
                    <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={() => this.onSave()} onDiscard={() => this.onDiscard()} />
                    <PopUpCancel open={this.state.isAlreadyLinked} text={this.state.warningText} onCancel={() => this.onX()} onSave={() => this.onSave()}
                        onDiscard={() => {
                            if (sessionStorage.getItem("organisationId"))
                                this.props.history.push({
                                    pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                                    state: { index: 2 }
                                });
                            else
                                this.props.history.push({
                                    pathname: '/orgtrackers',
                                    state: { index: 2 }
                                });
                        }} />
                    <Box pad={{ vertical: "small" }}>
                        <Text>All fields marked with an * are mandatory.</Text>
                        {/*<Text size="large">Description</Text>*/}
                    </Box>


                    <Box gap="small" width="80%">
                        <StyledBox style1 pad={{ "horizontal": "small" }}>
                            <FormField label="Site *" error={this.siteName_err}>
                                <Select
                                    placeholder="Select Site"
                                    options={this.props.sitesdata}
                                    labelKey="name"
                                    value={this.state.selectedSite}
                                    onChange={evt => {
                                        this.siteName_err = "";
                                        this.setState({ selectedSite: evt.option });
                                    }} />
                            </FormField>
                        </StyledBox>
                        <Box direction="row" gap="large" /*width="80%"*/>
                            <StyledBox style1 pad={{ "horizontal": "small" }} fill="horizontal" height="fit-content" >
                                <FormField label="Tracker *" error={this.trackerName_err}>
                                    <Select
                                        placeholder="Select Tracker"
                                        options={this.props.trackersdata.filter(t => !t.hasOwnProperty("count") && t.locationId == this.state.selectedSite.id && (t.assetBeaconId == null || t.assetBeaconId == "")).sort(constants.byPropertyCalled("name"))}
                                        labelKey="name"
                                        value={this.state.selectedTracker}
                                        onChange={evt => {
                                            this.trackerName_err = "";
                                            this.setState({ selectedTracker: evt.option });
                                        }}
                                        disabled={!this.state.selectedSite}
                                    />
                                </FormField>
                            </StyledBox>
                            <StyledBox style1 pad={{ "horizontal": "small" }} fill="horizontal" height="fit-content">
                                <FormField label="Tag *" error={this.tagName_err}>
                                    <Select
                                        placeholder="Select Tag"
                                        options={this.props.assetsdata.filter(a => !a.hasOwnProperty("count") && a.locationId == this.state.selectedSite.id && this.props.associationsdata.filter(a => !(a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "") && a.locationId == this.state.selectedSite.id).map(i => i.beaconId).includes(a.id)).sort(constants.byPropertyCalled("name"))}
                                        labelKey="name"
                                        value={this.state.selectedTag}
                                        onChange={evt => {
                                            this.tagName_err = "";
                                            this.setState({ selectedTag: evt.option })
                                        }}
                                        disabled={!this.state.selectedSite}
                                    />
                                </FormField>
                            </StyledBox>
                        </Box>
                    </Box>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}

const mapStateToProps = state => ({
    assetsdata: state.assetsReducer.assetsdata,
    trackersdata: state.trackersReducer.trackersdata,
    associationsdata: state.associationsReducer.associationsdata,
    sitesdata: state.sitesReducer.sitesdata.sort(constants.byPropertyCalled())
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...TrackerAction, ...AssetAction, ...AssociationAction, ...SiteAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgLinkEditContainer));