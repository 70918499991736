import * as ActionType from './ActionType';
import FormApi from '../api/FormApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getFormsResponse = formsdata => ({
    type: ActionType.GET_FORMS_RESPONSE,
    formsdata
});

const queuedGetAllForms = queueApiCall(FormApi.getAllForms);

export function getFormsAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return FormApi.getAllForms()
        return queuedGetAllForms()
            .then(formsdata => {
                dispatch(getFormsResponse(formsdata));
                return formsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewFormResponse = () => ({
    type: ActionType.ADD_NEW_FORM_RESPONSE
});

export const updateExistingFormResponse = () => ({
    type: ActionType.UPDATE_EXISTING_FORM_RESPONSE
});

export function saveFormAction(formdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the form is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new form that is being added, therefore add it
        return FormApi.saveForm(formdraft)
            .then((response) => {
                if (formdraft.id) {
                    dispatch(updateExistingFormResponse());
                } else {
                    dispatch(addNewFormResponse());
                }
                return response;
            }).then((response) => {
                //if (formdraft.id == "0") {
                //    dispatch(getFormAction(formdraft.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getFormResponse = formdata => ({
    type: ActionType.GET_FORM_RESPONSE,
    formdata
});

export function getFormAction(formId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return FormApi.getForm(formId)
            .then(formdata => {
                dispatch(getFormResponse(formdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteFormResponse = () => ({
    type: ActionType.DELETE_FORM_RESPONSE
});

const queuedDeleteForm = queueApiCall(FormApi.deleteForm);

export function deleteFormAction(formId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return FormApi.deleteForm(formId)
        return queuedDeleteForm(formId)
            .then((response) => {
                dispatch(deleteFormResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getFormsAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}