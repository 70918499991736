import styled, { css  } from 'styled-components';
import { Box } from 'grommet';
import constants from '../../constants';

export const StyledBox = styled(Box)`

      ${props => props.thickborder && css` //set thickborder to true, and the border is going to be thicker
        border: 5px solid #111111;
      `}
      ${props => props.leftborderthick == true && css` //set testproperty to true, and the background and color is going to change to blue and white
        border-left: 5px solid #01a982;
      `}
      ${props => props.leftborderthick == false && css` //set testproperty to true, and the background and color is going to change to blue and white
        border-left: 5px solid transparent;
      `}

      ${props => props.style1 && css`
        background: ${constants.BOX_BACKGROUND_COLOR_1};
        border-radius: 5px 5px 5px 5px;
        /*box-shadow: 2px 2px 4px 2px rgba(100, 100, 100, 0.35);*/
        box-shadow: ${!props.focusIndicator ? "2px 2px 4px 2px rgba(100, 100, 100, 0.35)" : ""};
        /*box-shadow: 0px 2px 4px rgba(100, 100, 100, 0.35);*/
      `}

      ${props => props.style1black && css`
        background: ${constants.BOX_BACKGROUND_COLOR_2};
        border-radius: 5px 5px 5px 5px;
        box-shadow: 2px 2px 4px 2px rgba(100, 100, 100, 0.35);
        /*box-shadow: 0px 2px 4px rgba(100, 100, 100, 0.35);*/
      `}

      ${props => props.style1gray && css`
        background: #E6E6E6;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 2px 2px 4px 2px rgba(100, 100, 100, 0.35);
        /*box-shadow: 0px 2px 4px rgba(100, 100, 100, 0.35);*/
      `}

`;
