import React, { Component } from 'react';
import { DataTable, Box, Text } from 'grommet';
import { Alert } from 'grommet-icons';
import withstyledPagination from './StyledPaginatedDataTable'
import styled from 'styled-components';
import { StyledBox } from './StyledBox';
import PrimaryFilter from "../common/PrimaryFilter";

const PaginatedDataTable = withstyledPagination(styled(DataTable)`
     width: 100%;
     `);

function withPagination(DataTable) {
    return class extends Component {
        render() {
            if (this.props.data.length == 0 && !this.props.noLoadingMsg) {
                return (
                    <Box>
                        {this.props.styled &&
                            <StyledBox style1 margin={{ "top": "36px" }} pad="large" gap="small" direction="column" align="center">
                                <Alert color="#DADADA" size="100px" />
                                <Box align="center">
                                    <Text>{this.props.line1 || "There is no content at this time"}</Text>
                                    <Text>{this.props.line2 || "Please check back again soon"}</Text>
                                </Box>
                            </StyledBox>
                        }
                        {!this.props.styled &&
                            <StyledBox pad="large" gap="small" direction="column" align="center">
                                <Alert color="#DADADA" size="100px" />
                                <Box align="center">
                                    <Text>{this.props.line1 || "There is no content at this time"}</Text>
                                    <Text>{this.props.line2 || "Please check back again soon"}</Text>
                                </Box>
                            </StyledBox>
                        }
                    </Box>
                );
            }
            return (
                <Box>
                    {this.props.styled &&
                        <Box>
                            <PrimaryFilter
                                placeholder={this.props.PFplaceholder}
                                search={this.props.PFsearch}
                                onChange={this.props.PFonChange}
                                onClear={this.props.PFonClear}
                                onClick={this.props.PFonClick}
                                open={this.props.PFopen}
                                onClose={this.props.PFonClose}
                                onResetFilter={this.props.PFonResetFilter}
                                isClearVisible={this.props.PFisClearVisible}
                                csvdata={this.props.PFcsvdata}
                                filename={this.props.PFcsvfilename}
                                searchable={this.props.PFsearchable}
                                filterable={this.props.PFfilterable}
                                downloadable={this.props.PFdownloadable}
                                filterablecolumns={this.props.PFfilterablecolumns}
                                columns={this.props.PFcolumns}
                                onChangeColumns={this.props.PfonChangeColumns}
                            >
                                {this.props.PFFileters}
                            </PrimaryFilter>
                            <StyledBox style1 pad={{ "horizontal": "small" }} margin={{ "bottom": "medium" }}>
                                <PaginatedDataTable {...this.props} />
                            </StyledBox>
                        </Box>
                    }
                    {!this.props.styled &&
                        <PaginatedDataTable {...this.props} />}
                </Box>
            )
        }
    }
};

export default withPagination;