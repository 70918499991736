import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Text, TextInput, Image, Select } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, Connect, FormClose, Download } from 'grommet-icons';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import RB from './common/RBAC';
import { CSVLink } from "react-csv";
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import PrimaryFilter from "./common/PrimaryFilter";
import ReactTooltip from "react-tooltip";


const PaginatedDataTable = withPagination(DataTable);

class BeaconListTable extends Component {

    constructor(props) {
        super(props);
        this.count = 0;
        this.DATA = [];
        this.Exp_Data = [];
        this.state = {
            search: "",
            level: "",
            levelId: "",
            floors: [],
            selectedBuilding: "",
            selectedBuildingName: "",
            isFilterOpen: false,
            select: []
        }
    }

    renderFilters() {
        return (
            <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="medium">
                <Box direction="row" justify="between">
                    <StyledBox style1>
                        <Select placeholder="Select Building" value={this.state.selectedBuilding} options={this.props.buildingsdata.filter(m => m.meridianId).map(b => b.meridianName)}
                            onChange={o => {
                                const buildingid = this.props.buildingsdata[o.selected].mergedId;
                                const buildingmeridianid = this.props.buildingsdata[o.selected].mergedName;
                                const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == buildingid && f.meridianId != "");
                                this.setState({ selectedBuildingName: buildingmeridianid, selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "" });
                            }} />
                    </StyledBox>
                    {this.state.selectedBuilding && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ selectedBuildingName: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "", level: "", levelId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1>
                        <Select placeholder="Select Floor" value={this.state.level} options={this.state.floors} onChange={evt => this.setState({ level: evt.option, levelId: this.state.floors[evt.selected].meridianId })} labelKey="meridianName">
                            {option => {
                                if (this.props.beaconsdata.find(a => a.mapId == option.meridianId)) {
                                    return (
                                        <Box pad="small">
                                            <b>
                                                {option.meridianName}
                                            </b>
                                        </Box>);
                                }
                                return (
                                    <Box pad="small">
                                        {option.meridianName}
                                    </Box>);
                            }}
                        </Select>
                    </StyledBox>
                    {this.state.level && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ level: "", levelId: "" })}
                    />}
                </Box>
            </Box>
        )
    }
    getData() {
        var data = this.props.beaconsdata;

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            data = data.filter(d =>
                d.hwType.includes(search) ||
                d.name.toLowerCase().includes(search) ||
                d.id.toLowerCase().includes(search) ||
                this.address(d.id).toLowerCase().includes(search) ||
                d.apMac.toLowerCase().includes(search) ||
                d.type.toLowerCase().includes(search) ||
                d.map && d.map.group && d.map.group.name && d.map.group.name.toLowerCase().includes(search) ||
                d.map && d.map.name && d.map.name.toLowerCase().includes(search) ||
                d.timestamp.substr(0, 10).toLowerCase().includes(search) ||
                d.firmwareAVersion.toLowerCase().includes(search) ||
                d.firmwareBVersion.toLowerCase().includes(search) ||
                d.hwType.toLowerCase().includes(search) ||
                (d.batteryLevel && !d.powered && (d.batteryLevel.toString() + "%").includes(search))
            );
        }
        if (this.state.selectedBuildingName) {
            data = data.filter(d => d.map && d.map.group && d.map.group.name == this.state.selectedBuildingName);
        }
        if (this.state.levelId) {
            data = data.filter(d => d.mapId == this.state.levelId);
        }
        return data;
    }
    render() {
        const canEdit = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        this.DATA = [];
        const locationid = sessionStorage.getItem("locationId");
        //console.log(props.usersdata, locationid);
        if (this.getData()[0] && this.getData()[0].count != 0) {
            this.getData().forEach(ev => {
                this.DATA.push({
                    ...ev,
                    lcname: ev.name != null ? ev.name.toLowerCase() : "",
                    txtble: this.address(ev.id),
                    mapbuilding: ev.map && ev.map.group && (ev.map.group.name != null) ? ev.map.group.name : "",
                    lcbuilding: ev.map && ev.map.group && (ev.map.group.name != null) ? ev.map.group.name.toLowerCase() : "",
                    mapfloor: ev.map && (ev.map.name != null) ? ev.map.name : "",
                    lcfloor: ev.map && (ev.map.name != null) ? ev.map.name.toLowerCase() : "",
                    txtlast: ev.timestamp.substr(0, 10),
                    //txtcreated: ev.created.substr(0, 10),
                    //txtmodified: ev.modified.substr(0, 10),
                    firmware_a: ev.firmwareAVersion,
                    firmware_b: ev.firmwareBVersion,
                    hwtype: ev.hwType
                })
                this.Exp_Data.push({
                    "BLE MAC": ev.id,
                    "AP MAC": ev.apMac,
                    //"BLE": this.address(ev.id),
                    "Beacon Name": ev.name,
                    "Battery": ev.batteryLevel,
                    "Type": ev.type,
                    "Building": ev.map && ev.map.group && (ev.map.group.name != null) ? ev.map.group.name : "",
                    "Floor": ev.map && (ev.map.name != null) ? ev.map.name : "",
                    "Last Heard": ev.timestamp.substr(0, 10),
                    "Firmware_a": ev.firmwareAVersion,
                    "Firmware_b": ev.firmwareBVersion,
                    "HwType": ev.hwType
                })
            });
        }

        console.log("DAT_CK:", this.props.buildingsdata);
        this.count = this.DATA.length;
        this.DATA.sort(constants.byPropertyCalled());
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'flex', this.state.select.length > 0 ? '60px' : '5px']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                    { name: 'footer', start: [0, 2], end: [1, 2] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Beacons</Heading>
                    {/*<CSVLink data={this.downloadData} filename="Template.csv">
                                <StyledButton typeAdd label="Bulk Edit" onClick={() => this.props.history.push('/editbeacons')} />
                            </CSVLink>*/}
                    <Box direction="row" gap="small" >
                        {this.state.select.length > 0 &&
                            <CSVLink data={this.downloadData} filename="Template.csv">
                                <StyledButton typeAdd label="Bulk Edit" onClick={() => this.props.history.push(constants.getSiteLevelRoute() + '/editbeacons')} />
                            </CSVLink>
                        }
                    </Box>
                    {/*<CSVLink
                        data={this.DATA}
                        headers={[
                            { label: "Beacon Name", key: "name" },
                            { label: "BLE", key: "txtble" },
                            { label: "Battery", key: "batteryLevel" },
                            { label: "Type", key: "type" },
                            { label: "Building", key: "mapbuilding" },
                            { label: "Floor", key: "mapfloor" },
                            { label: "Last Heard", key: "txtlast" },
                            //{ label: "Created", key: "txtcreated" },
                            //{ label: "Modified", key: "txtmodified" },
                            { label: "Firmware_a", key: "firmware_a" },
                            { label: "Firmware_b", key: "firmware_b" },
                            { label: "HwType", key: "hwtype" },
                          ]}
                        filename="exported_data.csv"
                        ref={(r) => this.csvLink = r}
                        target="_blank"
                        hidden="hidden"
                        >Export to CSV
                        </CSVLink>
                        <StyledButton typeSave label='Export to CSV' alignSelf="center" onClick={evt => this.handleExport(evt)} />*/}
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium" }} fill="vertical">
                    {/*<Box width="100%" direction="row" justify="between" pad={{ bottom: "medium" }}>*/}
                    {/*    <StyledBox style1 width="90%">*/}
                    {/*        <TextInput placeholder="Search Beacons" value={this.state.search} onChange={e => this.setState({ search: e.target.value })} />*/}
                    {/*    </StyledBox>*/}
                    {/*    <Box width="100px" alignSelf="center">*/}
                    {/*        <StyledButton typeAdd width="100px" label="Filter" onClick={() => this.setState({ isFilterOpen: !this.state.isFilterOpen })} />*/}
                    {/*    </Box>*/}
                    {/*</Box>*/}
                    {/*{this.state.isFilterOpen &&*/}
                    {/*    <Box direction="row" justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="medium">*/}
                    {/*        <Box width="90%">*/}
                    {/*            <Grid columns={{ count: 5, size: "auto" }} gap="medium" >*/}
                    {/*                <StyledBox style1>*/}
                    {/*                    <Select placeholder="Select Building" value={this.state.selectedBuilding} options={this.props.buildingsdata.filter(m => m.meridianId).map(b => b.meridianName)}*/}
                    {/*                        onChange={o => {*/}
                    {/*                            const buildingid = this.props.buildingsdata[o.selected].mergedId;*/}
                    {/*                            const buildingmeridianid = this.props.buildingsdata[o.selected].mergedName;*/}
                    {/*                            const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == buildingid && f.meridianId != "");*/}
                    {/*                            this.setState({ selectedBuildingName: buildingmeridianid, selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "" });*/}
                    {/*                        }} />*/}
                    {/*                </StyledBox>*/}
                    {/*                <StyledBox style1>*/}
                    {/*                    <Select placeholder="Select Floor" value={this.state.level} options={this.state.floors} onChange={evt => this.setState({ level: evt.option, levelId: this.state.floors[evt.selected].meridianId })} labelKey="meridianName">*/}
                    {/*                        {option => {*/}
                    {/*                            if (this.props.beaconsdata.find(a => a.mapId == option.meridianId)) {*/}
                    {/*                                return (*/}
                    {/*                                    <Box pad="small">*/}
                    {/*                                        <b>*/}
                    {/*                                            {option.meridianName}*/}
                    {/*                                        </b>*/}
                    {/*                                    </Box>);*/}
                    {/*                            }*/}
                    {/*                            return (*/}
                    {/*                                <Box pad="small">*/}
                    {/*                                    {option.meridianName}*/}
                    {/*                                </Box>);*/}
                    {/*                        }}*/}
                    {/*                    </Select>*/}
                    {/*                </StyledBox>*/}
                    {/*            </Grid>*/}
                    {/*        </Box>*/}
                    {/*        <Box alignSelf="center" width="100px">*/}
                    {/*            <StyledButton typeCancel label="Clear" onClick={() => this.setState({ selectedBuilding: "", selectedBuildingName: "", level: "", levelId: "", floors: [] })}*/}
                    {/*                disabled={*/}
                    {/*                    this.state.selectedBuilding == "" &&*/}
                    {/*                    this.state.selectedBuildingName == "" &&*/}
                    {/*                    this.state.level == "" &&*/}
                    {/*                    this.state.levelId == ""*/}
                    {/*                } />*/}
                    {/*        </Box>*/}
                    {/*    </Box>*/}
                    {/*}*/}
                    {/*<PrimaryFilter*/}
                    {/*    placeholder="Search by Beacon name, BLE, type, building, floor, last heard, firmware or hwtype"*/}
                    {/*    search={this.state.search}*/}
                    {/*    onChange={e => this.setState({ search: e.target.value })}*/}
                    {/*    onClick={() => this.setState({ isFilterOpen: true })}*/}
                    {/*    open={this.state.isFilterOpen}*/}
                    {/*    onClose={() => this.setState({ isFilterOpen: false })}*/}
                    {/*    onResetFilter={() => this.setState({ selectedBuildingName: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "", selectedFloorId: "", level: "", levelId: "" })}*/}
                    {/*    isClearVisible={(this.state.selectedBuilding == "" &&*/}
                    {/*        this.state.selectedBuilding == "" &&*/}
                    {/*        this.state.level == "") ? false : true}*/}
                    {/*    csvdata={this.Exp_Data}*/}
                    {/*    downloadable*/}
                    {/*>*/}
                    {/*    <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="medium">*/}
                    {/*        <Box direction="row" justify="between">*/}
                    {/*            <StyledBox style1>*/}
                    {/*                <Select placeholder="Select Building" value={this.state.selectedBuilding} options={this.props.buildingsdata.filter(m => m.meridianId).map(b => b.meridianName)}*/}
                    {/*                    onChange={o => {*/}
                    {/*                        const buildingid = this.props.buildingsdata[o.selected].mergedId;*/}
                    {/*                        const buildingmeridianid = this.props.buildingsdata[o.selected].mergedName;*/}
                    {/*                        const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == buildingid && f.meridianId != "");*/}
                    {/*                        this.setState({ selectedBuildingName: buildingmeridianid, selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "" });*/}
                    {/*                    }} />*/}
                    {/*            </StyledBox>*/}
                    {/*            {this.state.selectedBuilding && <Button*/}
                    {/*                icon={<FormClose />}*/}
                    {/*                onClick={() => this.setState({ selectedBuildingName: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "", level: "", levelId: "" })}*/}
                    {/*            />}*/}
                    {/*        </Box>*/}
                    {/*        <Box direction="row" justify="between">*/}
                    {/*            <StyledBox style1>*/}
                    {/*                <Select placeholder="Select Floor" value={this.state.level} options={this.state.floors} onChange={evt => this.setState({ level: evt.option, levelId: this.state.floors[evt.selected].meridianId })} labelKey="meridianName">*/}
                    {/*                    {option => {*/}
                    {/*                        if (this.props.beaconsdata.find(a => a.mapId == option.meridianId)) {*/}
                    {/*                            return (*/}
                    {/*                                <Box pad="small">*/}
                    {/*                                    <b>*/}
                    {/*                                        {option.meridianName}*/}
                    {/*                                    </b>*/}
                    {/*                                </Box>);*/}
                    {/*                        }*/}
                    {/*                        return (*/}
                    {/*                            <Box pad="small">*/}
                    {/*                                {option.meridianName}*/}
                    {/*                            </Box>);*/}
                    {/*                    }}*/}
                    {/*                </Select>*/}
                    {/*            </StyledBox>*/}
                    {/*            {this.state.level && <Button*/}
                    {/*                icon={<FormClose />}*/}
                    {/*                onClick={() => this.setState({ level: "", levelId: "" })}*/}
                    {/*            />}*/}
                    {/*        </Box>*/}
                    {/*    </Box>*/}
                    {/*</PrimaryFilter>*/}
                    {/*<IconThemeContext.Extend value={galwayIconTheme}>*/}
                    <StyledBox style={{ "min-width": "1070px" }}>
                        {this.renderUsersTable(this.props, canEdit)}
                    </StyledBox>
                    {/*</IconThemeContext.Extend>*/}
                </Box>
                {/*<Box gridArea='side' background={constants.BACKGROUND_COLOR} />*/}
                <Box gridArea='footer' background={constants.BACKGROUND_COLOR} >
                    {this.state.select.length > 0 && <Box background="light-4" width="100%" justify="between" direction="row" pad="small" >
                        <Box direction="row" gap="medium">
                            <Box>
                                {this.state.select.length} of {this.count} selected
                    </Box>
                            <Box border={{ side: "bottom", size: "small", color: "black" }} onClick={() => this.setState({ select: [] })}>
                                Clear
                    </Box>
                        </Box>
                        {!RB.canEdit(constants.PERMS.LOC_CONTENT) ?
                            <Box direction='row'>
                                <Button plain style={{ cursor: 'inherit' }} icon={<Blank />} />
                            </Box>
                            : <Box direction='row' gap="10px">
                                <ReactTooltip id="download" place="top" type="dark" effect="solid" arrowColor="transparent" />
                                <CSVLink data={this.downloadData} filename="Template.csv" style={{ height: "26px" }}>
                                    <Button plain icon={<Download />} data-for="download" data-tip="Export to CSV" />
                                </CSVLink>
                            </Box>
                        }
                    </Box>}
                </Box>
            </Grid>

        );
    }

    handleExport(evt) {
        console.log('Export to CSV', this.csvLink);
        this.csvLink.link.click();
    };

    ArrayToString(arr) {
        var string = "";
        arr.forEach(function (item, idx) {
            if (idx == arr.length - 1) {
                string += item.role.name;
            }
            else {
                string += item.role.name;
                string += ", ";
            }

        })
        return string;
    }
    address(str) {
        var clist = [];
        var result = str[0];
        for (var i = 1; i < str.length; i++) {
            if (i % 2 == 0) {
                result += (":");
                result += (str[i]);
            }
            else {
                result += (str[i]);
            }
        }
        return result;
    }

    updateFilteredColumns = (data) => this.setState({ filteredColumns: data })

    renderUsersTable(props, canEdit) {

        let COLUMNS = [
            {
                property: 'id',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: s => <Text hidden>{s.id}</Text>
            },
            {
                property: 'batteryLevel',
                header: <Box justify="center" height="100%">Battery</Box>,
                //primary: true,
                render: s =>
                    <Box align='center' pad='none' direction='row'>
                        <Box alignContent="center">
                            <ReactTooltip id="usb" place="left" type="dark" effect="solid" arrowColor="transparent" />
                            <ReactTooltip id="green" place="left" type="dark" effect="solid" arrowColor="transparent" />
                            <ReactTooltip id="yellow" place="left" type="dark" effect="solid" arrowColor="transparent" />
                            <ReactTooltip id="red" place="left" type="dark" effect="solid" arrowColor="transparent" />
                            {(s.powered && <a data-for="usb" data-tip="USB Powered Beacon, does not require a battery"><Connect color="black" /></a>)}
                            {(s.batteryStatus === "G" && !s.powered && <a data-for="green" data-tip="Battery level is above 50%"><Image width="30" height="30" src="./images/green.svg" /></a>)}
                            {/*{(s.batteryStatus === "G" && s.powered && <Connect color="black" />)}*/}
                            {(s.batteryStatus === "A" && !s.powered && <a data-for="yellow" data-tip="Battery Level is below 50%, it should be monitored closely. Critical level is <20%"><Image width="30" height="30" src="./images/yellow.svg" /></a>)}
                            {/*{(s.batteryStatus === "A" && s.powered && <Connect color="black" />)}*/}
                            {(s.batteryStatus === "R" && !s.powered && <a data-for="red" data-tip="Battery Level is below 20%, consider changing battery now"><Image width="30" height="30" src="./images/red.svg" /></a>)}
                            {/*{(s.batteryStatus === "R" && s.powered && <Connect color="black" />)}*/}
                        </Box>
                        {!s.powered && <Text>
                            {s.batteryLevel}%
                    </Text>}
                    </Box>,
                isFixed: true
            },
            {
                property: 'lcname',
                header: 'Beacon name',
                render: s => <Text>{s.name}</Text>,
                isFixed: true
            },
            {
                property: 'txtble',
                header: 'BLE MAC',
                render: s => <Text>{s.txtble}</Text>,
                isFixed: true
            },
            {
                property: 'apMac',
                header: 'AP MAC',
                render: s => <Text>{s.apMac}</Text>
            },
            {
                property: 'type',
                header: <Box justify="center" height="100%">Type</Box>,
                render: s => <Text>{s.type}</Text>
            },
            {
                property: 'lcbuilding',
                header: <Box justify="center" height="100%">Building</Box>,
                render: s => <Text>{s.mapbuilding}</Text>
            },
            {
                property: 'lcfloor',
                header: <Box justify="center" height="100%">Floor</Box>,
                render: s => <Text>{s.mapfloor}</Text>
            },
            {
                property: 'txtlast',
                header: <Box justify="center" height="100%">Last Heard</Box>,
                render: s => <Text>{s.txtlast}</Text>
            },
            //{
            //    property: 'txtcreated',
            //    header: <Box justify="center" height="100%">Created',
            //    render: s => <Text>{s.txtcreated}</Text>
            //},
            //{
            //    property: 'txtmodified',
            //    header: <Box justify="center" height="100%">Modified',
            //    render: s => <Text>{s.txtmodified}</Text>
            //},
            {
                property: 'firmware_a',
                header: <Box justify="center" height="100%">Firmware_a</Box>,
                render: s => <Text>{s.firmware_a}</Text>
            },
            {
                property: 'firmware_b',
                header: <Box justify="center" height="100%">Firmware_b</Box>,
                render: s => <Text>{s.firmware_b}</Text>
            },
            {
                property: 'hwtype',
                header: <Box justify="center" height="100%">HwType</Box>,
                render: s => <Text>{s.hwtype}</Text>
            },

        ];

        // this.DATA = [];
        // const locationid = sessionStorage.getItem("locationId");
        // //console.log(props.usersdata, locationid);
        // props.beaconsdata.forEach(ev => {
        //         this.DATA.push({
        //             ...ev,
        //             lcname: ev.name != null ? ev.name.toLowerCase() : "",
        //             lcfloor: ev.map.name != null ? ev.map.name.toLowerCase(): "",
        //         })
        // });

        console.log('DATA:', this.DATA);

        if (this.state.filteredColumns)
            COLUMNS = constants.filterActiveColumns(COLUMNS, this.state.filteredColumns);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "txtble"
                }))}
                data={this.DATA}
                noLoadingMsg={this.props.beaconsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'batteryLevel', label: 'Battery' },
                //    { col: 'lcname', label: 'Beacon Name' },
                //    { col: 'txtble', label: 'BLE' },
                //    { col: 'type', label: 'Type' },
                //    { col: 'lcbuilding', label: 'Building' },
                //    { col: 'lcfloor', label: 'Floor' },
                //    { col: 'txtlast', label: 'Last Heard' },
                //    //{ col: 'txtcreated', label: 'Created' },
                //    //{ col: 'txtmodified', label: 'Modified' },
                //    { col: 'firmware_a', label: 'Firmware_a' },
                //    { col: 'firmware_b', label: 'Firmware_b' },
                //    { col: 'hwtype', label: 'HwType' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                styled={true}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => { this.setState({ search: e.target.value }); }}
                PFonClear={e => this.setState({ search: "" })}
                PFonClick={() => this.setState({ isFilterOpen: true })}
                PFopen={this.state.isFilterOpen}
                PFonClose={() => this.setState({ isFilterOpen: false })}
                PFonResetFilter={() => this.setState({ selectedBuildingName: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "", selectedFloorId: "", level: "", levelId: "" })}
                PFisClearVisible={(this.state.selectedBuilding == "" &&
                    this.state.selectedBuilding == "" &&
                    this.state.level == "") ? false : true}
                PFcsvdata={this.Exp_Data}
                PFcsvfilename="beacons-export"
                PFFileters={this.renderFilters()}
                PFsearchable={true}
                PFfilterable={true}
                PFdownloadable={this.DATA.length > 0 ? 1 : 2}
                getMore={this.props.getMore}
                count={this.state.search == "" ? this.props.count : this.DATA.length}
                select={this.state.select}
                onSelect={list => {
                    //Create download data
                    this.downloadData = [];
                    list.forEach(id => {
                        let beacon = this.props.beaconsdata.find(b => b.id == id);
                        this.downloadData.push({
                            "BLE MAC": beacon.id,
                            "AP MAC": beacon.apMac,
                            "Beacon Name": beacon.name
                        });
                    });
                    this.setState({ select: list })
                }}
                PFfilterablecolumns={true}
                PFcolumns={this.state.filteredColumns ? this.state.filteredColumns : constants.createFilters(COLUMNS)}
                PfonChangeColumns={this.updateFilteredColumns}
            />
        );
    }

}

export default BeaconListTable;
