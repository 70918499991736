import styled, { css } from 'styled-components';
import { Button } from 'grommet';
import constants from '../../constants';

export const StyledButton = styled(Button)`

      ${props => props.typeCancel && css` //Cancel button
        background: ${constants.BUTTON_BACKGROUND_COLOR_2};
        color: ${constants.BUTTON_LABEL_COLOR_1}; //Label color
        border-width: 2px;
        border-style: solid;
        border-color: ${constants.BUTTON_BORDER_COLOR_3};
        box-shadow: 0px 2px 4px rgba(100, 100, 100, 0.35);
      `}
      ${props => props.typeCancel && !props.disabled && css` //Cancel button hover
        :hover {
            box-shadow: 0px 0px 0px 2px ${constants.BUTTON_BORDER_COLOR_3};
        } 
      `}

      ${props => props.typeSave && css` //Save button
        background: ${constants.BUTTON_BACKGROUND_COLOR_1};
        color: ${constants.BUTTON_LABEL_COLOR_2}; //Label color
        border-width: 2px;
        border-style: solid;
        border-color: ${constants.BUTTON_BORDER_COLOR_1};
        box-shadow: 0px 2px 4px rgba(100, 100, 100, 0.35);
      `}
      ${props => props.typeSave && !props.disabled && css` //Save button hover
        :hover {
            box-shadow: 0px 0px 0px 2px ${constants.BUTTON_BORDER_COLOR_1};
        } 
      `}

      ${props => props.typeAdd && css` //Add button
        background: ${constants.BUTTON_BACKGROUND_COLOR_2};
        color: ${constants.BUTTON_LABEL_COLOR_1}; //Label color
        border-width: 2px;
        border-style: solid;
        border-color: ${constants.BUTTON_BORDER_COLOR_1};
        box-shadow: 0px 2px 4px rgba(100, 100, 100, 0.35);
      `}
      ${props => props.typeAdd && !props.disabled && css` //Add button hover
        :hover {
            box-shadow: 0px 0px 0px 2px ${constants.BUTTON_BORDER_COLOR_1};
        } 
      `}
`;
