import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image, Select, CheckBox, Stack } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, Connect, Tag, FormClose, CircleInformation, Aggregate, Download } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import RB from './common/RBAC';
import { CSVLink } from "react-csv";
import { Battery, BatteryAmber, BatteryEmpty, BatteryRed, BatteryUnknown } from '../grommet-extras/Battery';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import ReactTooltip from "react-tooltip";
import FilterLayer from "./common/FilterLayer";
import PrimaryFilter from "./common/PrimaryFilter";

const PaginatedDataTable = withPagination(DataTable);
class TagListTable extends Component {

    constructor(props) {
        super(props);
        this.count = 0;
        this.dtwrapper = React.createRef();
        this.DATA = [];
        this.Exp_Data = [];
        this.state = {
            flag: props.flag ? props.flag : "",
            search: "",
            status: "",
            statusId: "",
            level: "",
            levelId: "",
            control: props.flag ? "Tags":"",
            floors: [],
            selectedBuilding: "",
            selectedBuildingId: "",
            isFilterOpen: props.flag? true: false,
            select: [],
            collection: "",
            collectionId: "",
            department: "",
            departmentId: "",
            link: ""
        };
    }
    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }
    clicked = false;

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(id);
        }
    }

    onDTUpdated() {
        let self = this;
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (nro != 0) { //this is for the select function
                        if (elem.getElementsByTagName('th')[0]) {
                            let tid = elem.getElementsByTagName('th')[0].textContent;
                            //element.addEventListener("click", function (evt) {
                            //    if (self.clicked)
                            //        return
                            //    else
                            //        self.clicked = true;
                            //    evt.preventDefault();
                            //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                            //    handleEdit(tid);
                            //});
                            element.removeEventListener("click", this.externalFunc);
                            element.addEventListener("click", this.externalFunc);
                        }
                        element.addEventListener("mouseover", function (evt) {
                            evt.preventDefault();
                            elem.style.background = "#E6E6E6";
                            elem.style.cursor = 'pointer';
                        });
                        element.addEventListener("mouseout", function (evt) {
                            evt.preventDefault();
                            elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                            elem.style.cursor = 'default';
                        });
                    }
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }
    renderFilters() {
        const typeList = this.state.class == "" ? ["Employee", "Visitor", "Contractor", "Other", "Critical"] : this.state.class == "Person" ? ["Employee", "Visitor", "Contractor", "Other"] : ["Critical"];
        return (
            <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="medium">
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Building" value={this.state.selectedBuilding} options={this.props.buildingsdata.filter(m => m.meridianId).map(b => b.meridianName)}
                            onChange={o => {
                                const buildingid = this.props.buildingsdata[o.selected].mergedId;
                                const buildingmeridianid = this.props.buildingsdata[o.selected].meridianId;
                                const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == buildingid && f.meridianId != "");
                                this.setState({ selectedBuildingId: buildingmeridianid, selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "" });
                            }} />
                    </StyledBox>
                    {this.state.selectedBuilding && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ selectedBuilding: "", selectedBuildingId: "", floors: [], selectedFloorId: "", level: "", levelId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Floor" value={this.state.level} options={this.state.floors} onChange={evt => this.setState({ level: evt.option, levelId: this.state.floors[evt.selected].meridianId })} labelKey="meridianName">
                            {option => {
                                if (this.props.assetsdata.find(a => a.mapId == option.meridianId)) {
                                    return (
                                        <Box pad="small">
                                            <b>
                                                {option.meridianName}
                                            </b>
                                        </Box>);
                                }
                                return (
                                    <Box pad="small">
                                        {option.meridianName}
                                    </Box>);
                            }}
                        </Select>
                    </StyledBox>
                    {this.state.level && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ level: "", levelId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Collection" value={this.state.collection} options={this.props.collectionsdata} onChange={evt => this.setState({ collection: evt.option, collectionId: evt.option.id })} labelKey="name" />
                    </StyledBox>
                    {this.state.collection && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ collection: "", collectionId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Department" value={this.state.department} options={this.props.departmentsdata} onChange={evt => this.setState({ department: evt.option, departmentId: evt.option.id })} labelKey="name" />
                    </StyledBox>
                    {this.state.department && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ department: "", departmentId: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Control" value={this.state.control} options={["Control Tags", "Tags"]} onChange={o => { this.setState({ control: o.option }); }} />
                    </StyledBox>
                    {this.state.control != "" && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ control: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Link Status" value={this.state.link} options={["Linked", "Unlinked"]} onChange={o => { this.setState({ link: o.option }); }} />
                    </StyledBox>
                    {this.state.link != "" && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ link: "" })}
                    />}
                </Box>
                <Box direction="row" justify="between">
                    <StyledBox style1 width="80%">
                        <Select placeholder="Select Device Flag" value={this.state.flag} options={["Healthy", "Untraceable", "Low Charge", "Orphans"]} onChange={(opt) => { this.setState({ flag: opt.option }); }} />
                    </StyledBox>
                    {this.state.flag && <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ flag: "" })}
                    />}
                </Box>
            </Box>
        )
    }
    componentWillReceiveProps(props) {
        if (props.flag && !this.state.flag) {
            this.setState({ flag: props.flag, control: "Tags", isFilterOpen: true });
        }
    }
    getData(data) {
        //"Orphans", "Healthy", "Untracable", "Low Charge"
        //var data = this.props.assetsdata;
        //var flag = this.props.flag ? this.props.flag : this.state.flag;

        /*
            Healthy: All tags are linked and traceable
            Untraceable: Tags that are not transmitting a location
            Low Charge: Tags that have a battery level below 20%
            Orphans: Tags that are not currently linked
        */
        switch (this.state.flag) {
            case "Orphans":
                data = data.filter(d => /*d.assetBeaconTags.length == 0*/ !d.isAssigned);
                break;
            case "Healthy":
                data = data.filter(d => d.mapId && d.isAssigned);
                break;
            case "Untraceable":
                data = data.filter(d => d.mapId == "" || d.mapId == null);
                break;
            case "Low Charge":
                data = data.filter(d => d.batteryLevel < 20);
                break;
            default:
                break;
        }
        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            data = data.filter(d =>
                d.id.toLowerCase().includes(search) ||
                d.name.toLowerCase().includes(search) ||
                d.mapId !== null && d.map.name.toLowerCase().includes(search) ||
                d.mapId !== null && d.map.group.name.toLowerCase().includes(search) ||
                d.lcCollectionName.includes(search) ||
                d.lcDepartmentName.includes(search) ||
                d.externalId.toLowerCase().includes(search) ||
                ("control tag".includes(search) && d.isControlTag) ||
                ("linked".includes(search) && d.isAssigned) ||
                d.lcZone.includes(search)
            );
        }
        if (this.state.status) {
            data = data.filter(d => d.serviceStateId == this.state.statusId);
        }
        if (this.state.selectedBuildingId) {
            data = data.filter(d => d.map && d.map.group && d.map.group.id == this.state.selectedBuildingId);
        }
        if (this.state.levelId) {
            data = data.filter(d => d.mapId == this.state.levelId);
        }
        if (this.state.control === "Control Tags") {
            data = data.filter(d => d.isControlTag == true);
        }
        else if (this.state.control === "Tags") {
            data = data.filter(d => d.isControlTag == false);
        }
        if (this.state.collectionId) {
            data = data.filter(d => d.collectionId == this.state.collectionId);
        }
        if (this.state.departmentId) {
            data = data.filter(d => d.departmentId == this.state.departmentId);
        }
        if (this.state.link === "Linked") {
            data = data.filter(d => d.isAssigned == true);
        }
        else if (this.state.link === "Unlinked") {
            data = data.filter(d => d.isAssigned == false);
        }
        return data;
    }
    formatDate(utc) {
        var date = new Date(utc);
        var strDate =
            date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2)
            + "-" + ("00" + date.getDate()).slice(-2)
            + " "
            + ("00" + date.getHours()).slice(-2) + ":"
            + ("00" + date.getMinutes()).slice(-2)
            + ":" + ("00" + date.getSeconds()).slice(-2);
        return strDate;
    }

    render() {
        const canEdit = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        this.DATA = [];
        this.Exp_Data = [];
        const locationid = sessionStorage.getItem("locationId");
        //console.log(props.usersdata, locationid);
        if (this.props.assetsdata[0] && this.props.assetsdata[0].count != 0) {
            this.props.assetsdata.forEach(ev => {
                var statusName = constants.ASSET_STATUS.find(s => s.id == ev.serviceStateId);
                statusName = statusName ? statusName.name : "";

                let tracker = this.props.associationsdata && this.props.associationsdata[0] && this.props.associationsdata[0].count != 0 ? this.props.associationsdata.find(a => a.beaconId == ev.id) : false;
                let isAssigned = tracker ? true : false;
                let c = isAssigned && this.props.trackersdata && this.props.trackersdata[0] && this.props.trackersdata[0].count != 0 ? this.props.trackersdata.find(t => t.id == tracker.assetId) : "";
                let collectionId = c ? c.collectionId : "";
                let d = isAssigned && this.props.trackersdata && this.props.trackersdata[0] && this.props.trackersdata[0].count != 0 ? this.props.trackersdata.find(t => t.id == tracker.assetId) : "";
                let departmentId = d ? d.departmentId : "";
                let collection = collectionId && this.props.collectionsdata && this.props.collectionsdata[0] && this.props.collectionsdata[0].count != 0 ? this.props.collectionsdata.find(c => c.id == collectionId) : "";
                let collectionName = collection ? collection.name : "";
                let department = departmentId && this.props.departmentsdata && this.props.departmentsdata[0] && this.props.departmentsdata[0].count != 0 ? this.props.departmentsdata.find(d => d.id == departmentId) : "";
                let departmentName = department ? department.name : "";
                tracker = tracker ? tracker.assetName : "";
                this.DATA.push({
                    ...ev,
                    lcname: ev.name != null ? ev.name.toLowerCase() : "",
                    lcbuilding: ev.map.group != null ? ev.map.group.name.toLowerCase() : "",
                    lcfloor: ev.map.name != null ? ev.map.name.toLowerCase() : "",
                    st: statusName,
                    lastheard: ev.timestamp ? this.formatDate(ev.timestamp) : "",
                    isAssigned: isAssigned,
                    trackerName: tracker,
                    lcTrackerName: tracker.toLowerCase(),
                    Labels: ev.assetBeaconTags.length,
                    collectionId: collectionId,
                    departmentId: departmentId,
                    collectionName: collectionName,
                    lcCollectionName: collectionName.toLowerCase(),
                    departmentName: departmentName,
                    lcDepartmentName: departmentName.toLowerCase(),
                    no: ev.id,
                    lcZone: ev.zoneId && ev.zone ? ev.zone.name.toLowerCase() : ""
                    //txtble: this.address(ev.id),
                    //mapfloor: ev.map && (ev.map.name != null) ? ev.map.name : "",
                    //lcfloor: ev.map && (ev.map.name != null) ? ev.map.name.toLowerCase() : "",
                    //txtlast: ev.timestamp.substr(0, 10),
                    ////txtcreated: ev.created.substr(0, 10),
                    ////txtmodified: ev.modified.substr(0, 10),
                    //firmware_a: ev.firmwareAVersion,
                    //firmware_b: ev.firmwareBVersion,
                    //hwtype: ev.hwType
                })
                this.Exp_Data.push({
                    "Battery": ev.batteryLevel,
                    "Name": ev.name,
                    "ID": ev.id,
                    "External ID": ev.externalId,
                    "Last Heard At (UTC)": ev.timestamp ? (ev.timestamp) : "",
                    "Building": ev.map.group ? ev.map.group.name : "",
                    "Floor": ev.map.name,
                    "Zone": ev.zoneId && ev.zone ? ev.zone.name : "",
                    "Collection": collectionName,
                    "Department": departmentName,
                    "Control Tag": ev.isControlTag,
                    "Linked": isAssigned,
                    "Number of Labels": ev.assetBeaconTags.length,
                    "Labels": ev.assetBeaconTags.map(l => l.tag.name).toString()
                });
            });
        }
        this.DATA = this.getData(this.DATA);

        //this.DATA.sort((p1, p2) => {
        //    if (p1.lcbuilding == "aruba hq" || p2.lcbuilding == "aruba hq") {
        //        if (p2.lcbuilding == "aruba hq" && p1.lcbuilding == "aruba hq") {
        //            return !(p1.isControlTag || p2.isControlTag);
        //        }
        //        return -1;
        //    }
        //    return 1;
        //});
        this.DATA.sort((p1, p2) => {
            if (p1.lcbuilding > p2.lcbuilding)
                return -1;
            if (p1.lcbuilding == p2.lcbuilding) {
                //if (!p1.isControlTag && !p2.isControlTag) {
                //    console.log("hela",p1.lcname, p2.lcname);
                //    return p1.lcname > p2.lcname;
                //}
                return p1.isControlTag - p2.isControlTag;
            }
            return 1;
        });
        this.DATA.sort((i1, i2) => {
            if (i1.name < i2.name) { return -1; }
            if (i1.name == i2.name) { return 0; }
            else return 1;
        });
        this.Exp_Data.sort((i1, i2) => {
            if (i1.Name < i2.Name) { return -1; }
            if (i1.Name == i2.Name) { return 0; }
            else return 1;
        });
        this.count = this.DATA.length;
        console.log('DATA:', this.DATA, this.Exp_Data);
        //var sync = this.props.syncTime ? this.props.syncTime.replace("T", " ").replace("Z", "") : "";
        var sync = "";
        if (this.props.syncTime) {
            var date = new Date(this.props.syncTime);
            sync =
                ("00" + date.getDate()).slice(-2) + "-" +
                ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
                date.getFullYear() +
                " " +
                ("00" + date.getHours()).slice(-2) + ":" +
                ("00" + date.getMinutes()).slice(-2);
            //":" + ("00" + date.getSeconds()).slice(-2);
            sync.replace("T", " ").replace("Z", "")
        }
        return (
            <Box style={{ "min-width": "max-content" }}>
                {/*<PrimaryFilter*/}
                {/*    placeholder="Search by Tag name, ID or classification"*/}
                {/*    search={this.state.search}*/}
                {/*    onChange={e => this.setState({ search: e.target.value })}*/}
                {/*    onClick={() => this.setState({ isFilterOpen: true })}*/}
                {/*    open={this.state.isFilterOpen}*/}
                {/*    onClose={() => this.setState({ isFilterOpen: false })}*/}
                {/*    onResetFilter={() => this.setState({ flag: "", status: "", selectedBuilding: "", selectedBuildingId: "", level: "", levelId: "", control: "", floors: [], collectionId: "", departmentId: "", collection: "", department: "", link: "" })}*/}
                {/*    isClearVisible={(this.state.selectedBuilding == "" &&*/}
                {/*        this.state.selectedBuildingId == "" &&*/}
                {/*        this.state.level == "" &&*/}
                {/*        this.state.levelId == "" &&*/}
                {/*        this.state.control == "" &&*/}
                {/*        this.state.flag == "" &&*/}
                {/*        this.state.status == "" &&*/}
                {/*        this.state.collection == "" &&*/}
                {/*        this.state.collectionId == "" &&*/}
                {/*        this.state.department == "" &&*/}
                {/*        this.state.departmentId == "" &&*/}
                {/*        this.state.link == "") ? false : true}*/}
                {/*    csvdata={this.Exp_Data}*/}
                {/*    downloadable*/}
                {/*>*/}
                {/*    <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="medium">*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Building" value={this.state.selectedBuilding} options={this.props.buildingsdata.filter(m => m.meridianId).map(b => b.meridianName)}*/}
                {/*                    onChange={o => {*/}
                {/*                        const buildingid = this.props.buildingsdata[o.selected].mergedId;*/}
                {/*                        const buildingmeridianid = this.props.buildingsdata[o.selected].meridianId;*/}
                {/*                        const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == buildingid && f.meridianId != "");*/}
                {/*                        this.setState({ selectedBuildingId: buildingmeridianid, selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "" });*/}
                {/*                    }} />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.selectedBuilding && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ selectedBuilding: "", selectedBuildingId: "", floors: [], selectedFloorId: "", level: "", levelId: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Floor" value={this.state.level} options={this.state.floors} onChange={evt => this.setState({ level: evt.option, levelId: this.state.floors[evt.selected].meridianId })} labelKey="meridianName">*/}
                {/*                    {option => {*/}
                {/*                        if (this.props.assetsdata.find(a => a.mapId == option.meridianId)) {*/}
                {/*                            return (*/}
                {/*                                <Box pad="small">*/}
                {/*                                    <b>*/}
                {/*                                        {option.meridianName}*/}
                {/*                                    </b>*/}
                {/*                                </Box>);*/}
                {/*                        }*/}
                {/*                        return (*/}
                {/*                            <Box pad="small">*/}
                {/*                                {option.meridianName}*/}
                {/*                            </Box>);*/}
                {/*                    }}*/}
                {/*                </Select>*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.level && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ level: "", levelId: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Collection" value={this.state.collection} options={this.props.collectionsdata} onChange={evt => this.setState({ collection: evt.option, collectionId: evt.option.id })} labelKey="name" />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.collection && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ collection: "", collectionId: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Department" value={this.state.department} options={this.props.departmentsdata} onChange={evt => this.setState({ department: evt.option, departmentId: evt.option.id })} labelKey="name" />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.department && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ department: "", departmentId: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Control" value={this.state.control} options={["Control Tags", "Tags"]} onChange={o => { this.setState({ control: o.option }); }} />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.control != "" && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ control: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select" value={this.state.link} options={["Linked", "Unlinked"]} onChange={o => { this.setState({ link: o.option }); }} />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.link != "" && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ link: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        <Box direction="row" justify="between">*/}
                {/*            <StyledBox style1 width="80%">*/}
                {/*                <Select placeholder="Select Device Flag" value={this.state.flag} options={["Healthy", "Untraceable", "Low Charge", "Orphans"]} onChange={(opt) => { this.setState({ flag: opt.option }); }} />*/}
                {/*            </StyledBox>*/}
                {/*            {this.state.flag && <Button*/}
                {/*                icon={<FormClose />}*/}
                {/*                onClick={() => this.setState({ flag: "" })}*/}
                {/*            />}*/}
                {/*        </Box>*/}
                {/*        */}{/*<Box direction="row" justify="between">*/}{/*
                */}{/*                    <StyledBox style1 width="80%">*/}{/*
                */}{/*                    <Select placeholder="Select Status" value={this.state.status} options={constants.ASSET_STATUS} onChange={evt => { this.setState({ status: evt.option, statusId: evt.option.id }); console.log("status", evt.option) }} labelKey="name">*/}{/*
                */}{/*                        {option =>*/}{/*
                */}{/*                            <Box pad="small">*/}{/*
                */}{/*                                {option.name}*/}{/*
                */}{/*                            </Box>*/}{/*
                */}{/*                        }*/}{/*
                */}{/*                    </Select>*/}{/*
                */}{/*                    </StyledBox>*/}{/*
                */}{/*                    {this.state.status && <Button*/}{/*
                */}{/*                        icon={<FormClose />}*/}{/*
                */}{/*                        onClick={() => this.setState({ status: "", statusId: "" })}*/}{/*
                */}{/*                    />}*/}{/*
                */}{/*                </Box>*/}
                {/*    </Box>*/}
                {/*</PrimaryFilter>*/}


                {/*<Box width="100%" direction="row"  pad={{ bottom: "medium", "top": "small" }} gap="medium">
                    <StyledBox style1 width="100%">
                        <TextInput placeholder="Search by Tag name, ID, External ID, Collection, Department" value={this.state.search} onChange={e => this.setState({ search: e.target.value })} />
                    </StyledBox>
                    <Box direction="row" justify="between" width="50%">
                        <FilterLayer
                            onClick={() => this.setState({ isFilterOpen: true })}
                            open={this.state.isFilterOpen}
                            onClose={() => this.setState({ isFilterOpen: false })}
                            onResetFilter={() => this.setState({ flag: "", status: "", selectedBuilding: "", selectedBuildingId: "", level: "", levelId: "", control: "", floors: [], collectionId: "", departmentId: "", collection: "", department: "", link: "" })}
                            isClearVisible={(this.state.selectedBuilding == "" &&
                                this.state.selectedBuildingId == "" &&
                                this.state.level == "" &&
                                this.state.levelId == "" &&
                                this.state.control == "" &&
                                this.state.flag == "" &&
                                this.state.status == "" &&
                                this.state.collection == "" &&
                                this.state.collectionId == "" &&
                                this.state.department == "" &&
                                this.state.departmentId == "" &&
                                this.state.link == "") ? false : true}
                        >
                            <Box justify="between" background={constants.BACKGROUND_COLOR} pad={{ bottom: "medium" }} gap="medium">
                                <Box direction="row" justify="between">
                                    <StyledBox style1 width="80%">
                                    <Select placeholder="Select Building" value={this.state.selectedBuilding} options={this.props.buildingsdata.filter(m => m.meridianId).map(b => b.meridianName)}
                                        onChange={o => {
                                            const buildingid = this.props.buildingsdata[o.selected].mergedId;
                                            const buildingmeridianid = this.props.buildingsdata[o.selected].meridianId;
                                            const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == buildingid && f.meridianId != "");
                                            this.setState({ selectedBuildingId: buildingmeridianid, selectedBuilding: o.option, floors: floors, selectedFloor: "", selectedFloorId: "" });
                                        }} />
                                    </StyledBox>
                                    {this.state.selectedBuilding && <Button
                                        icon={<FormClose />}
                                        onClick={() => this.setState({ selectedBuilding: "", selectedBuildingId: "", floors: [], selectedFloorId: ""})}
                                    />}
                                </Box>
                                <Box direction="row" justify="between">
                                    <StyledBox style1 width="80%">
                                    <Select placeholder="Select Floor" value={this.state.level} options={this.state.floors} onChange={evt => this.setState({ level: evt.option, levelId: this.state.floors[evt.selected].meridianId })} labelKey="meridianName">
                                        {option => {
                                            if (this.props.assetsdata.find(a => a.mapId == option.meridianId)) {
                                                return (
                                                    <Box pad="small">
                                                        <b>
                                                            {option.meridianName}
                                                        </b>
                                                    </Box>);
                                            }
                                            return (
                                                <Box pad="small">
                                                    {option.meridianName}
                                                </Box>);
                                        }}
                                    </Select>
                                    </StyledBox>
                                    {this.state.level && <Button
                                        icon={<FormClose />}
                                        onClick={() => this.setState({ level: "", levelId: "" })}
                                    />}
                                </Box>
                                <Box direction="row" justify="between">
                                    <StyledBox style1 width="80%">
                                    <Select placeholder="Select Collection" value={this.state.collection} options={this.props.collectionsdata} onChange={evt => this.setState({ collection: evt.option, collectionId: evt.option.id })} labelKey="name" />
                                    </StyledBox>
                                    {this.state.collection && <Button
                                        icon={<FormClose />}
                                        onClick={() => this.setState({ collection: "", collectionId: "" })}
                                    />}
                                </Box>
                                <Box direction="row" justify="between">
                                    <StyledBox style1 width="80%">
                                    <Select placeholder="Select Department" value={this.state.department} options={this.props.departmentsdata} onChange={evt => this.setState({ department: evt.option, departmentId: evt.option.id })} labelKey="name" />
                                    </StyledBox>
                                    {this.state.department && <Button
                                        icon={<FormClose />}
                                        onClick={() => this.setState({ department: "", departmentId: "" })}
                                    />}
                                </Box>
                                <Box direction="row" justify="between">
                                    <StyledBox style1 width="80%">
                                    <Select placeholder="Select Control" value={this.state.control} options={["", "Control Tags", "Tags"]} onChange={o => { this.setState({ control: o.option }); }} />
                                    </StyledBox>
                                    {this.state.control != "" && <Button
                                        icon={<FormClose />}
                                        onClick={() => this.setState({ control: "" })}
                                    />}
                                </Box>
                                <Box direction="row" justify="between">
                                    <StyledBox style1 width="80%">
                                    <Select placeholder="Select" value={this.state.link} options={["", "Linked", "Unlinked"]} onChange={o => { this.setState({ link: o.option }); }} />
                                    </StyledBox>
                                    {this.state.link != "" && <Button
                                        icon={<FormClose />}
                                        onClick={() => this.setState({ link: "" })}
                                    />}
                                </Box>
                                <Box direction="row" justify="between">
                                    <StyledBox style1 width="80%">
                                    <Select placeholder="Select Device Flag" value={this.state.flag} options={["Healthy", "Untraceable", "Low Charge", "Orphans"]} onChange={(opt) => { this.setState({ flag: opt.option }); }} />
                                    </StyledBox>
                                    {this.state.flag && <Button
                                        icon={<FormClose />}
                                        onClick={() => this.setState({ flag: "" })}
                                    />}
                                </Box>
                                {/*<Box direction="row" justify="between">
                                    <StyledBox style1 width="80%">
                                    <Select placeholder="Select Status" value={this.state.status} options={constants.ASSET_STATUS} onChange={evt => { this.setState({ status: evt.option, statusId: evt.option.id }); console.log("status", evt.option) }} labelKey="name">
                                        {option =>
                                            <Box pad="small">
                                                {option.name}
                                            </Box>
                                        }
                                    </Select>
                                    </StyledBox>
                                    {this.state.status && <Button
                                        icon={<FormClose />}
                                        onClick={() => this.setState({ status: "", statusId: "" })}
                                    />}
                                </Box>*
                            </Box>
                        </FilterLayer>
                        <Box width="100px" alignSelf="center" align="center">
                            <CSVLink data={this.Exp_Data} filename="Exported Datatable.csv">
                                <a data-for="Download Info" data-tip="Export to CSV" >
                                    <Button plain icon={<Download color={constants.HPE_GREEN} />} alignSelf="center" />
                                </a>
                                <ReactTooltip id="Download Info" place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                            </CSVLink>
                        </Box>
                    </Box>
                </Box> */}
                <StyledBox ref={this.dtwrapper} /*style={{ "min-width": "1650px" }}*/>
                    {this.renderAssetsTable(this.props, canEdit, sync)}
                </StyledBox>
                <Text>Last updated at: {sync}</Text>
                {false && <Box /*style1gray*/ background="light-4" width="100%" justify="between" direction="row" pad="small" style={{ borderRadius: "5px 5px 5px 5px", boxShadow: "2px 2px 4px 2px rgba(100, 100, 100, 0.35)" }}>
                    <Box direction="row" gap="medium">
                        <Box>
                            {this.state.select.length} of {this.count} selected
                    </Box>
                        <Box border={{ side: "bottom", size: "small", color: "black" }} onClick={() => this.setState({ select: [] })}>
                            Clear
                    </Box>
                    </Box>
                    {!RB.canEdit(constants.PERMS.LOC_CONTENT) ?
                        <Box direction='row'>
                            <Button plain style={{ cursor: 'inherit' }} icon={<Blank />} />
                        </Box>
                        : <Box direction='row' gap="xsmall">
                            <ReactTooltip id="link" place="top" type="dark" effect="solid" arrowColor="transparent" />
                            <Button plain /*disabled={props.deletedTrackerIds.includes(t.id)}*/ onClick={() => this.props.handleBulkAssign(this.state.select)} icon={<Aggregate />} data-for="link" data-tip="Create Tracker and Link to Tag" />
                            {/*<Button plain /*disabled={props.deletedTrackerIds.includes(t.id)}*/ /*onClick={() => this.props.handleBulkAssign(this.state.select)} icon={<Edit />} /> */}
                            <Button plain /*disabled={props.deletedTrackerIds.includes(t.id)}*/ /*onClick={() => this.props.handleDelete("trackers", this.state.select)}*/ icon={<Trash />} />
                        </Box>
                    }
                </Box>}
            </Box>
        );
    }

    handleExport(evt) {
        console.log('Export to CSV', this.csvLink);
        this.csvLink.link.click();
    };
    renderBatteryIcon(level) {
        if (level < 20) {
            return (
                <Box direction="row" gap="xsmall">
                    <BatteryRed style={{ width: "34.365", height: "18" }} color="#FF4040" />
                </Box>);
        }
        else if (20 <= level && level <= 60) {
            return (
                <Box direction="row" gap="xsmall">
                    <BatteryAmber style={{ width: "34.365", height: "18" }} color="#FFAA15" />
                </Box>);
        }
        else if (60 < level && level <= 100) {
            return (
                <Box direction="row" gap="xsmall">
                    <Battery style={{ width: "34.365", height: "18" }} color="#01a982" />
                </Box>);
        }
        else {
            return (
                <Box direction="row">
                    <BatteryUnknown style={{ width: "34.365", height: "18" }} color="#CCCCCC" />
                </Box>);
        }

    }
    updateFilteredColumns = (data) =>  this.setState({ filteredColumns: data })

    renderAssetsTable(props, canEdit, sync) {

        let COLUMNS = [
            //{
            //    property: 'imageUrl',
            //    header: 'Image',
            //    render: s => (s.imageUrl != "" ?
            //        <Image width="30" height="30" src={`${s.imageUrl}`} /> :
            //        <Tag />
            //    )
            //},
            //{
            //    property: 'id',
            //    header: '',
            //    primary: true,
            //    render: t => (!RB.canEdit(constants.PERMS.LOC_CONTENT)) ? <Box direction='row'><Button plain style={{ cursor: 'inherit' }} icon={<Blank />} /></Box>
            //        : <Box direction='row' gap="xsmall">
            //            <Button plain disabled={props.deletedAssetIds.includes(t.id)}  onClick={evt => props.handleEdit(t.id)} icon={<Edit />} />
            //            <Button plain disabled={props.deletedAssetIds.includes(t.id)} onClick={evt => props.handleDelete("asset", t.id)} icon={<Trash />} />
            //        </Box>,
            //},
            {
                property: 'batteryLevel',
                header: 'Battery',
                //primary: true,
                size: "60px",
                render: s => this.renderBatteryIcon(s.batteryLevel),
                isFixed: true
            },
            {
                property: 'lcname',
                header: 'Name',
                render: s => <Text>{s.name}</Text>,
                isFixed: true
            },
            {
                property: 'no',
                header: 'ID',
                render: s => <Text>{s.id}</Text>,
                isFixed: true
            },
            {
                property: 'externalId',
                header: 'External ID',
                render: s => <Text>{s.externalId}</Text>,
                isActive: false,
            },
            //{
            //    property: 'id',
            //    header: 'Tracker Name',
            //    render: t => constants.ASSOCIATIONS.find(a => a.tagId == t.id) ? <Text> {constants.ASSOCIATIONS.find(a => a.tagId == t.id).trackerName}</Text> : <Text></Text>
            //},
            {
                property: 'lastheard',
                header: 'Last Heard',
                render: s => <Text>{s.timestamp ? this.formatDate(s.timestamp) : ""}</Text>
            },
            {
                property: 'lcbuilding',
                header: 'Building',
                render: s => <Text>{s.map.group ? s.map.group.name : ""}</Text>
            },
            {
                property: 'lcfloor',
                header: 'Floor',
                render: s => <Text>{s.map.name}</Text>
            },
            {
                property: 'lcZone',
                header: 'Zone',
                render: s => <Text>{s.zoneId && s.zone ? s.zone.name : ""}</Text>
            },
            {
                property: 'lcCollectionName',
                header: 'Collection',
                render: s => <Text >{s.collectionName}</Text>
            },
            {
                property: 'lcDepartmentName',
                header: 'Department',
                render: s => <Text >{s.departmentName}</Text>
            },
            //{
            //    property: 'serviceStateId',
            //    header: 'Status',
            //    render: s =>
            //        < Text > {s.st}</Text >
            //},
            {
                property: 'isControlTag',
                header: 'Control Tag',
                render: s => <CheckBox checked={s.isControlTag} disabled />
            },
            {
                property: 'isAssigned',
                header: 'Linked',
                size: "55px",
                render: t => <CheckBox checked={t.isAssigned} disabled />
            },
            {
                property: 'Labels',
                header: <Box justify="center" height="100%">Labels</Box>,
                size: "60px",
                render: s =>
                    s.assetBeaconTags.length > 0 ?
                        < Box direction="row" /*border="all"*/ /*pad={{ vertical: "small" }}*/ alignContent="center" >
                            <Box fill={true} align="end" alignContent="center" alignSelf="center" direction="row">
                                <Stack anchor="top-right" data-for={s.id}
                                    data-tip>
                                    <Tag
                                        size="large"
                                        //color="#F04953"
                                        style={{ height: "24px" }}
                                    />
                                    <Box
                                        background="brand"
                                        pad={{ horizontal: 'xsmall' }}
                                        round
                                    >
                                        {s.assetBeaconTags.length}
                                    </Box>
                                </Stack>
                                <ReactTooltip id={s.id} place="left" type="light" effect="solid" arrowColor="transparent" multiline >
                                    <StyledBox style1 border="all" gap="small" pad="small">
                                        {s.assetBeaconTags && s.assetBeaconTags.map((l) => (
                                            <Text>
                                                {l.tag.name}
                                            </Text>
                                        ))}
                                    </StyledBox>
                                </ReactTooltip>

                            </Box>
                        </Box >
                        :
                        <Box />

            },
            {
                property: 'id',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: s => <Text hidden>{s.id}</Text>
            },
            {
                property: 'delete',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: t => <Box width="24px"><Button plain disabled={props.deletedAssetIds.includes(t.id)} onClick={evt => props.handleDelete("asset", t.id)} icon={<Trash />} /></Box>
            }
        ];

        let init = [];
        if (this.state.filteredColumns)
            COLUMNS = constants.filterActiveColumns(COLUMNS, this.state.filteredColumns);
        else {
            init = constants.createFilters(COLUMNS);
            COLUMNS = constants.filterActiveColumns(COLUMNS, init);
        }

        // this.DATA = [];
        // const locationid = sessionStorage.getItem("locationId");
        // //console.log(props.usersdata, locationid);
        // props.beaconsdata.forEach(ev => {
        //         this.DATA.push({
        //             ...ev,
        //             lcname: ev.name != null ? ev.name.toLowerCase() : "",
        //             lcfloor: ev.map.name != null ? ev.map.name.toLowerCase(): "",
        //         })
        // });
        //if (this.DATA.length == 0 && this.getData()[0] && this.getData()[0].count != 0) {
        //    return (
        //        <LoadingIndicator/>
        //    );
        //}

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "batteryLevel"
                }))}
                data={this.DATA}
                noLoadingMsg={this.props.assetsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'batteryLevel', label: 'Battery Level' },
                //    { col: 'lcname', label: 'Name' },
                //    { col: 'id', label: 'ID' },
                //    { col: 'timestamp', label: 'Last Heard' },
                //    { col: 'lcbuilding', label: 'Building' },
                //    { col: 'lcfloor', label: 'Floor' },
                //    { col: 'isControlTag', label: 'Control Tag' },
                //    { col: 'isAssigned', label: 'Linked' }
                //    //{ col: 'st', label: 'Status' },                   
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                //renderExtraHeader={() => <Text>Last updated at: {sync}</Text>}
                //downloadable={true}
                //exportdata={this.Exp_Data}
                select={this.props.select}
                onSelect={list => { this.props.onChangeSelect(list, this.count), this.setState({ select: list }) }}
                onUpdated={this.onDTUpdated.bind(this)}
                styled={true}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}
                PFonClick={() => this.setState({ isFilterOpen: true })}
                PFopen={this.state.isFilterOpen}
                PFonClose={() => this.setState({ isFilterOpen: false })}
                PFonResetFilter={() => this.setState({ flag: "", status: "", selectedBuilding: "", selectedBuildingId: "", level: "", levelId: "", control: "", floors: [], collectionId: "", departmentId: "", collection: "", department: "", link: "" })}
                PFisClearVisible={(this.state.selectedBuilding == "" &&
                    this.state.selectedBuildingId == "" &&
                    this.state.level == "" &&
                    this.state.levelId == "" &&
                    this.state.control == "" &&
                    this.state.flag == "" &&
                    this.state.status == "" &&
                    this.state.collection == "" &&
                    this.state.collectionId == "" &&
                    this.state.department == "" &&
                    this.state.departmentId == "" &&
                    this.state.link == "") ? false : true}
                PFcsvdata={this.Exp_Data}
                PFcsvfilename="sitetags-export"
                PFFileters={this.renderFilters()}
                PFsearchable={true}
                PFfilterable={true}
                PFdownloadable={this.DATA.length > 0 ? 1 : 2}
                PFfilterablecolumns={true}
                PFcolumns={this.state.filteredColumns ? this.state.filteredColumns : init}
                PfonChangeColumns={this.updateFilteredColumns}
            //onClickRow={event => props.handleEdit(event.datum.id)}
            />
        );
    }

}

export default TagListTable;