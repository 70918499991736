import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import validator from 'validator';
import constants from '../constants';
//eslint-disable-next-line
import { Add, Android, Apple, Edit, Trash, Checkmark, Star } from 'grommet-icons';
//eslint-disable-next-line
import { Grid, Box, Heading, List, Paragraph, Split, Button, Form, FormField, TextInput, Select, CheckBox, Text, Calendar, Image, TextArea, TableBody, TableRow, TableCell, Table, TableHeader, Tab, Tabs, Layer, Grommet } from 'grommet';
import TextInputWithState from './common/TextInputWithState';
import { ThemeContext as IconThemeContext } from 'grommet';
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Youtube, Radial, StatusGoodSmall, Upload, Resources, CircleInformation } from 'grommet-icons';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import PopUp from './common/PopUp';
import PopUpConfirm from './common/PopUpConfirm';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { SketchPicker, HuePicker, TwitterPicker, CirclePicker } from 'react-color';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import CustomOption from './common/CustomOption';
import ImageApi from '../api/ImageApi';
import { imageCheck } from './common/ImageCheck';
import { StyledFormField } from './common/StyledFormField';
import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import { ThemeContext } from 'styled-components';
import RB from './common/RBAC';
import { Context } from './Context';
import LoadingIndicator from './common/LoadingIndicator';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
Quill.register(Quill.import('attributors/style/align'), true);
import ReactTooltip from "react-tooltip";

var start = undefined;
var base64 = null;
var base64Background = null;
var base64DarkMode = null;
var base64BackgroundDarkMode = null;

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    formField: {
        border: {
            color: "transparent",
        },
        margin: "none",
        pad: "none"
    }
});

export class AppTheme extends React.Component {
    static contextType = Context;
    constructor() {
        super();
        this.stamp = Date.now().toString();
        this.state = {
            validating: false,
            isSaved: false,
            cancel: false,
            disabledButton: false,
            isSaveSuccessful: false,
            showPickColor: false,
            bg: '#9900EF',
            font: '#FFFFFF',
            type: "",
            newimage: false,
            newBackgroundImage: false,
            imagechanged: false,
            imagechangedBackground: false,
            newimageDarkMode: false,
            newBackgroundImageDarkMode: false,
            imagechangedDarkMode: false,
            imagechangedBackgroundDarkMode: false,
            index: 0,
            removeIconOpen: false,
            removeBackgroundOpen: false,
            removeIconOpenDarkMode: false,
            removeBackgroundOpenDarkMode: false,
            organisationdataLoading: true,
            HTMLTextLenght: 0,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    editorURLs = [];
    //Tab 0
    primaryColor_err = "";
    primaryFontColor_err = "";
    secondaryColor_err = "";
    secondaryFontColor_err = "";
    tertiaryColor_err = "";
    tertiaryFontColor_err = "";
    image_err = "";
    imageBackground_err = "";

    primaryColorDarkMode_err = "";
    primaryFontColorDarkMode_err = "";
    secondaryColorDarkMode_err = "";
    secondaryFontColorDarkMode_err = "";
    tertiaryColorDarkMode_err = "";
    tertiaryFontColorDarkMode_err = "";
    imageDarkMode_err = "";
    imageBackgroundDarkMode_err = "";

    //Tab 1
    aligment_err = "";
    eventsSectionName_err = "";
    actionsSectionName_err = "";
    landingScrTitle_err = "";
    landingScrSummary_err = "";
    landingScrButton_err = "";

    //Tab 2
    mapColorDarkMode_err = "";
    mapColor_err = "";

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        //const orgid = constants.organisation.id;
        const orgid = sessionStorage.getItem("organisationId");
        this.props.action.getOrganisationAction(orgid)
            .then(response => this.setState({ organisationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Organisation Error', error);
            });
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    componentWillReceiveProps() {
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.organisationdata && !_.isEqual(start, this.props.organisationdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    clearErrorMessages() {
        //Tab 0
        this.primaryColor_err = "";
        this.primaryFontColor_err = "";
        this.secondaryColor_err = "";
        this.secondaryFontColor_err = "";
        this.tertiaryColor_err = "";
        this.tertiaryFontColor_err = "";
        this.image_err = "";
        this.imageBackground_err = "";

        this.primaryColorDarkMode_err = "";
        this.primaryFontColorDarkMode_err = "";
        this.secondaryColorDarkMode_err = "";
        this.secondaryFontColorDarkMode_err = "";
        this.tertiaryColorDarkMode_err = "";
        this.tertiaryFontColorDarkMode_err = "";
        this.imageDarkMode_err = "";
        this.imageBackgroundDarkMode_err = "";

        //Tab 1
        this.aligment_err = "";
        this.eventsSectionName_err = "";
        this.actionsSectionName_err = "";
        this.landingScrTitle_err = "";
        this.landingScrSummary_err = "";
        this.landingScrButton_err = "";

        //Tab 2
        this.mapColorDarkMode_err = "";
        this.mapColor_err = "";
    }

    //uploadImageCallBack(file) {
    //    return imageCheck(file)
    //        .then(cf => ImageApi.uploadImage(cf))
    //        .catch(err => {
    //            if (typeof err === 'string') {
    //                this.uploadErrorText = err;
    //            } else {
    //                this.uploadErrorText = err.message;
    //            }
    //            this.setState({ isUploadError: true });
    //        });
    //}

    handleSave() {
        const organisationdefault = this.props.organisationdata;

        var errortab = -1;
        let errorsno = 0;

        this.setState({ validating: false });

        //App Theme
        if (this.state.newimage) {
            this.props.organisationdata.logo = base64;
            base64 = null;
        }
        if (this.state.newBackgroundImage) {
            this.props.organisationdata.imageContent = base64Background;
            base64 = null;
        }
        if (this.state.newimageDarkMode) {
            this.props.organisationdata.logoDarkMode = base64DarkMode;
            base64DarkMode = null;
        }
        if (this.state.newBackgroundImageDarkMode) {
            this.props.organisationdata.imageContentDarkMode = base64BackgroundDarkMode;
            base64 = null;
        }
        if (organisationdefault.primaryColor == null || organisationdefault.primaryColor.length == 0) {
            this.primaryColor_err = "Select Primary Color";
            errorsno++;
            errortab = 0;
        }
        if (organisationdefault.primaryFontColor == null || organisationdefault.primaryFontColor.length == 0) {
            this.primaryFontColor_err = "Select Primary Font Color";
            errorsno++;
            errortab = 0;
        }
        if (organisationdefault.secondaryColor == null || organisationdefault.secondaryColor.length == 0) {
            this.secondaryColor_err = "Select Secondary Color";
            errorsno++;
            errortab = 0;
        }
        if (organisationdefault.secondaryFontColor == null || organisationdefault.secondaryFontColor.length == 0) {
            this.secondaryFontColor_err = "Select Secondary Font Color";
            errorsno++;
            errortab = 0;
        }
        if (organisationdefault.tertiaryColor == null || organisationdefault.tertiaryColor.length == 0) {
            this.tertiaryColor_err = "Select Tertiary Color";
            errorsno++;
            errortab = 0;
        }
        if (organisationdefault.tertiaryFontColor == null || organisationdefault.tertiaryFontColor.length == 0) {
            this.tertiaryFontColor_err = "Select Tertiary Font Color";
            errorsno++;
            errortab = 0;
        }

        if (organisationdefault.primaryColorDarkMode == null || organisationdefault.primaryColorDarkMode.length == 0) {
            this.primaryColorDarkMode_err = "Select Primary Color";
            errorsno++;
            errortab = 0;
        }
        if (organisationdefault.primaryFontColorDarkMode == null || organisationdefault.primaryFontColorDarkMode.length == 0) {
            this.primaryFontColorDarkMode_err = "Select Primary Font Color";
            errorsno++;
            errortab = 0;
        }
        if (organisationdefault.secondaryColorDarkMode == null || organisationdefault.secondaryColorDarkMode.length == 0) {
            this.secondaryColorDarkMode_err = "Select Secondary Color";
            errorsno++;
            errortab = 0;
        }
        if (organisationdefault.secondaryFontColorDarkMode == null || organisationdefault.secondaryFontColorDarkMode.length == 0) {
            this.secondaryFontColorDarkMode_err = "Select Secondary Font Color";
            errorsno++;
            errortab = 0;
        }
        if (organisationdefault.tertiaryColorDarkMode == null || organisationdefault.tertiaryColorDarkMode.length == 0) {
            this.tertiaryColorDarkMode_err = "Select Tertiary Color";
            errorsno++;
            errortab = 0;
        }
        if (organisationdefault.tertiaryFontColorDarkMode == null || organisationdefault.tertiaryFontColorDarkMode.length == 0) {
            this.tertiaryFontColorDarkMode_err = "Select Tertiary Font Color";
            errorsno++;
            errortab = 0;
        }
        //App Screens
        if (!organisationdefault.logoHorizontalPosition) {
            errorsno++;
            errortab = 1;
            this.aligment_err = "Must be selected";
        }
        if (organisationdefault.eventsSectionName) {
            if (organisationdefault.eventsSectionName.length > 30) {
                errorsno++;
                errortab = 1;
                this.eventsSectionName_err = "Title can't be longer than 30 characters";
            }
        }
        if (organisationdefault.actionsSectionName) {
            if (organisationdefault.actionsSectionName.length > 30) {
                errorsno++;
                errortab = 1;
                this.actionsSectionName_err = "Title can't be longer than 30 characters";
            }
        }
        if (organisationdefault.landingScreenTitle) {
            if (organisationdefault.landingScreenTitle.length > 100) {
                errorsno++;
                errortab = 1;
                this.landingScrTitle_err = "Landing Screen Title can't be longer than 100 characters";
            }
        }
        if (organisationdefault.landingScreenSummary.includes("http://")) {
            errorsno++;
            errortab = 1;
            this.landingScrSummary_err = constants.DETAIL_HTTP_ERROR;
        }
        if (this.state.HTMLTextLenght > 5000) {
            errorsno++;
            errortab = 1;
            this.landingScrSummary_err = "Detail can't be longer than 5000 character";
        }
        for (var i = 0; i < this.editorURLs.length; i++) {
            if (!validator.isURL(this.editorURLs[i], constants.URL_HTTPS_ONLY)) {
                errorsno++;
                errortab = 1;
                this.landingScrSummary_err = constants.DETAIL_HTTP_ERROR;
            }
        }
        if (organisationdefault.landingScreenButtonText) {
            if (organisationdefault.landingScreenButtonText.length > 50) {
                errorsno++;
                errortab = 1;
                this.landingScrButton_err = "Custom Button Text can't be longer than 50 characters";
            }
        }

        if (errorsno === 0) {
            this.setState({ disabledButton: true });
            console.log('Saving: ', organisationdefault);
            this.props.action.saveOrganisationAction(organisationdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok, imagechanged: false });
                    //this.props.history.push('/orgsites');
                }).catch(error => {
                    console.log('*DEBUG*-Save App Theme Error', error);
                    this.setState({ disabledButton: false });
                });

        }
        else {
            this.setState({ validating: true, newimage: false, newBackgroundImage: false, imagechanged: false, imagechangedBackground: false, newimageDarkMode: false, newBackgroundImageDarkMode: false, imagechangedDarkMode: false, imagechangedBackgroundDarkMode: false });
        }
    }

    handleCancel() {
        // window.location.reload();
        if (!this.state.cancel && (!_.isEqual(start, this.props.organisationdata) || this.state.imagechanged)) {
            this.setState({ cancel: true });
        }
        else {
            this.props.action.getOrganisationAction(sessionStorage.getItem("organisationId"))
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getOrgLevelRoute() + '/apptheme');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-Organisation Error', error);
                    this.props.history.push(constants.getOrgLevelRoute() + '/apptheme');
                });
        }
    }

    updateAppType(option) {
        this.props.organisationdata.appType = option.option;
        this.setState({ updated: true });
    };
    appLogoErrorMsg() {
        if (this.props.organisationdata.showAppLogo) {
            if ((this.props.organisationdata.logoUrl == null || this.props.organisationdata.logoUrl == "") && (this.props.organisationdata.logoUrlDarkMode == null || this.props.organisationdata.logoUrlDarkMode == "")) {
                return "App Logo has not yet been configured, please upload this via App Theme."
            }
            else if (this.props.organisationdata.logoUrl == null || this.props.organisationdata.logoUrl == "") {
                return "App Logo for light mode has not yet been configured, please upload this via App Theme."
            }
            else if (this.props.organisationdata.logoUrlDarkMode == null || this.props.organisationdata.logoUrlDarkMode == "") {
                return "App Logo for dark mode has not yet been configured, please upload this via App Theme."
            }
        }

        return ""
    };
    appBackgroundErrorMsg() {
        if (this.props.organisationdata.showAppImage) {
            if ((this.props.organisationdata.imageUrl == null || this.props.organisationdata.imageUrl == "") && (this.props.organisationdata.imageUrlDarkMode == null || this.props.organisationdata.imageUrlDarkMode == "")) {
                return "App Background Image has not yet been configured, please upload this via App Theme."
            }
            else if (this.props.organisationdata.imageUrl == null || this.props.organisationdata.imageUrl == "") {
                return "App Background Image for light mode has not yet been configured, please upload this via App Theme."
            }
            else if (this.props.organisationdata.imageUrlDarkMode == null || this.props.organisationdata.imageUrlDarkMode == "") {
                return "App Background Image for dark mode has not yet been configured, please upload this via App Theme."
            }
        }

        return ""
    };
    getLogoHorizontalPositionValue(value) {
        if (value == "StartAndExpand") {
            return "Left";
        }
        else if (value == "CenterAndExpand") {
            return "Center";
        }
        else if (value == "EndAndExpand") {
            return "Right";
        }
        else {
            return "";
        }
    }
    updateLogoHorizontalPosition(option) {
        if (option.option == "Left") {
            this.props.organisationdata.logoHorizontalPosition = "StartAndExpand";
            this.aligment_err = "";
        }
        else if (option.option == "Center") {
            this.props.organisationdata.logoHorizontalPosition = "CenterAndExpand";
            this.aligment_err = "";
        }
        else if (option.option == "Right") {
            this.props.organisationdata.logoHorizontalPosition = "EndAndExpand";
            this.aligment_err = "";
        }
        this.setState({ updated: true });
    }
    updateEventsText(evt) {
        this.props.organisationdata.eventsSectionName = evt.target.value;
        this.setState({ update: true });
        if (this.props.organisationdata.eventsSectionName.length > 30) {
            this.eventsSectionName_err = "Title can't be longer than 30 characters";
        }
        else {
            this.eventsSectionName_err = "";
        }
    }
    updateActionCenterText(evt) {
        this.props.organisationdata.actionsSectionName = evt.target.value;
        this.setState({ update: true });
        if (this.props.organisationdata.actionsSectionName.length > 30) {
            this.actionsSectionName_err = "Title can't be longer than 30 characters";
        }
        else {
            this.actionsSectionName_err = "";
        }
    }
    updateLandingScreenEnabled(evt) {
        this.props.organisationdata.landingScreenEnabled = !this.props.organisationdata.landingScreenEnabled;
        this.setState({ update: true });
    }
    updateLandingScreenTitle(evt) {
        this.props.organisationdata.landingScreenTitle = evt.target.value;
        this.setState({ update: true });
    }
    handleEditorChange = (content, delta, source, editor) => {
        this.props.organisationdata.landingScreenSummary = editor.getHTML();
        if (editor.getLength() - 1 > 5000) {
            this.landingScrSummary_err = "Detail can't be longer than 5000 character";
        }
        else {
            this.landingScrSummary_err = "";
        }
        this.setState({ HTMLTextLenght: editor.getLength() - 1 });
        //console.log("HTML_EDITOR11", content, delta, source, editor.getHTML()); // rich text
        //console.log("HTML_EDITOR22", editor.getHTML()); // rich text
        //console.log("HTML_EDITOR33", editor.getText()); // plain text
        //console.log("HTML_EDITOR44", editor.getLength()); // number of characters

        var str = editor.getHTML();
        var searchKeyword = "<a href=";

        var indexes = [];
        this.editorURLs = [];
        var firstIndex = str.toLowerCase().indexOf(searchKeyword, 0);

        while (firstIndex >= 0) {
            if (!str.substr(firstIndex).match(/href=(["'])(.*?)\1/)[2].startsWith("mailto:")) { //skip if it is an e-mail address
                this.editorURLs.push(str.substr(firstIndex).match(/href=(["'])(.*?)\1/)[2]);
            }

            indexes.push(firstIndex);
            firstIndex = str.toLowerCase().indexOf(searchKeyword, firstIndex + 1);
        }
    }
    updateLandingScreenButtonText(evt) {
        this.props.organisationdata.landingScreenButtonText = evt.target.value;
        this.setState({ update: true });
    }

    handleChangeSComplete = (color) => {
        if (this.state.type === "primary") {
            this.props.organisationdata.primaryColor = color.hex;
            this.primaryColor_err = "";
        }
        else if (this.state.type === "secondary") {
            this.props.organisationdata.secondaryColor = color.hex;
            this.secondaryColor_err = "";
        }
        else if (this.state.type === "tertiary") {
            this.props.organisationdata.tertiaryColor = color.hex;
            this.tertiaryColor_err = "";
        }
        else if (this.state.type === "primaryDarkMode") {
            this.props.organisationdata.primaryColorDarkMode = color.hex;
            this.primaryColorDarkMode_err = "";
        }
        else if (this.state.type === "secondaryDarkMode") {
            this.props.organisationdata.secondaryColorDarkMode = color.hex;
            this.secondaryColorDarkMode_err = "";
        }
        else if (this.state.type === "tertiaryDarkMode") {
            this.props.organisationdata.tertiaryColorDarkMode = color.hex;
            this.tertiaryColorDarkMode_err = "";
        }
        else if (this.state.type === "mapColor") {
            this.props.organisationdata.mapColor = color.hex;
            this.tertiaryColorDarkMode_err = "";
        }
        else if (this.state.type === "mapColorDarkMode") {
            this.props.organisationdata.mapColorDarkMode = color.hex;
            this.tertiaryColorDarkMode_err = "";
        }
        //this.props.organisationdata.secondaryColor = color.hex;
        this.setState({ bg: color.hex });
    }
    handlePicker = (type, close) => {
        if (close) {
            this.setState({ showPickColor: false });
        }
        else {
            this.setState({ showPickColor: !this.state.showPickColor, type: type });
        }

    }
    updatePrimaryFontColor = (option) => {
        if (option.value == "Dark") {
            this.props.organisationdata.primaryFontColor = "#333333"
        }
        if (option.value == "Light") {
            this.props.organisationdata.primaryFontColor = "#FFFFFF"
        }
        this.primaryFontColor_err = "";
        this.setState({ update: true });
    }
    updateSecondaryFontColor = (option) => {
        if (option.value == "Dark") {
            this.props.organisationdata.secondaryFontColor = "#333333"
        }
        if (option.value == "Light") {
            this.props.organisationdata.secondaryFontColor = "#FFFFFF"
        }
        this.secondaryFontColor_err = "";
        this.setState({ update: true });
    }
    updateTertiaryFontColor = (option) => {
        if (option.value == "Dark") {
            this.props.organisationdata.tertiaryFontColor = "#333333"
        }
        if (option.value == "Light") {
            this.props.organisationdata.tertiaryFontColor = "#FFFFFF"
        }
        this.tertiaryFontColor_err = "";
        this.setState({ update: true });
    }
    updatePrimaryFontColorDarkMode = (option) => {
        if (option.value == "Dark") {
            this.props.organisationdata.primaryFontColorDarkMode = "#333333"
        }
        if (option.value == "Light") {
            this.props.organisationdata.primaryFontColorDarkMode = "#FFFFFF"
        }
        this.primaryFontColorDarkMode_err = "";
        this.setState({ update: true });
    }
    updateSecondaryFontColorDarkMode = (option) => {
        if (option.value == "Dark") {
            this.props.organisationdata.secondaryFontColorDarkMode = "#333333"
        }
        if (option.value == "Light") {
            this.props.organisationdata.secondaryFontColorDarkMode = "#FFFFFF"
        }
        this.secondaryFontColorDarkMode_err = "";
        this.setState({ update: true });
    }
    updateTertiaryFontColorDarkMode = (option) => {
        if (option.value == "Dark") {
            this.props.organisationdata.tertiaryFontColorDarkMode = "#333333"
        }
        if (option.value == "Light") {
            this.props.organisationdata.tertiaryFontColorDarkMode = "#FFFFFF"
        }
        this.tertiaryFontColorDarkMode_err = "";
        this.setState({ update: true });
    }
    colorPickerBackGround() {
        if (this.state.type === "primary") {
            return this.props.organisationdata.primaryColor;
            //this.primaryColor_err = "";
        }
        else if (this.state.type === "secondary") {
            return this.props.organisationdata.secondaryColor;
            //this.secondaryColor_err = "";
        }
        else if (this.state.type === "tertiary") {
            return this.props.organisationdata.tertiaryColor;
            //this.tertiaryColor_err = "";
        }
        else if (this.state.type === "primaryDarkMode") {
            return this.props.organisationdata.primaryColorDarkMode;
            //this.primaryColorDarkMode_err = "";
        }
        else if (this.state.type === "secondaryDarkMode") {
            return this.props.organisationdata.secondaryColorDarkMode;
            //this.secondaryColorDarkMode_err = "";
        }
        else if (this.state.type === "tertiaryDarkMode") {
            return this.props.organisationdata.tertiaryColorDarkMode;
            //this.tertiaryColorDarkMode_err = "";
        }
        else if (this.state.type === "mapColor") {
            return this.props.organisationdata.mapColor;
            //this.mapColor_err = "";
        }
        else if (this.state.type === "mapColorDarkMode") {
            return this.props.organisationdata.mapColorDarkMode;
            //this.mapColorDarkMode_err = "";
        }
        else {
            return "#FFF333";
        }
        //this.setState({validating: true});
    }
    readFile(f) {
        if (f) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const data = event.target.result;
                console.log('File', data.length);
                const base64data = btoa(data);
                base64 = base64data;
            };
            reader.readAsBinaryString(f);
        }
    }
    readFileBackground(f) {
        if (f) {
            const readerBackground = new FileReader();
            readerBackground.onload = function (event) {
                const dataBackground = event.target.result;
                console.log('File', dataBackground.length);
                const base64dataBackground = btoa(dataBackground);
                base64Background = base64dataBackground;
            };
            readerBackground.readAsBinaryString(f);
        }
    }
    fileChangedHandler = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file != undefined) {
            if (file.size > 1024 * 1024 * 2) {
                this.image_err = "Too large file: " + Math.round((file.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.image_err = "";
                this.readFile(file);
                this.setState({ file: file, newimage: true, imagechanged: true });
            }
        }
        else { }
    }
    fileChangedHandlerBackground = (event) => {
        const fileBackground = event.target.files[0];
        console.log(fileBackground);
        if (fileBackground != undefined) {
            if (fileBackground.size > 1024 * 1024 * 2) {
                this.imageBackground_err = "Too large file: " + Math.round((fileBackground.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.imageBackground_err = "";
                this.readFileBackground(fileBackground);
                this.setState({ fileBackground: fileBackground, newBackgroundImage: true, imagechangedBackground: true });
            }
        }
        else { }
    }
    removeIcon() {
        this.props.organisationdata.logoUrl = null;
        this.props.organisationdata.logo = null;
        base64 = null;
        this.setState({ removeIconOpen: false, newimage: false });
    }
    removeBackground() {
        this.props.organisationdata.imageUrl = null;
        this.props.organisationdata.imageContent = null;
        base64 = null;
        this.setState({ removeBackgroundOpen: false, newBackgroundImage: false });
    }
    shortcut() {
        if (this.state.file == "") {
            return null;
        }
        else if (this.state.file != null) {
            var Data = [];
            Data.push(this.state.file);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/x-png,image/jpeg" }))
            return (
                <Box direction="row">
                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                    <Image style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("real");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }
    shortcutBackground() {
        if (this.state.fileBackground == "") {
            return null;
        }
        else if (this.state.fileBackground != null) {
            var Data = [];
            Data.push(this.state.fileBackground);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/x-png,image/jpeg" }))
            return (
                <Box direction="row">
                    <input type="file" id="realBackground" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                    <Image style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("realBackground");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }
    fileChangedHandlerDarkMode = (event) => {
        //console.log(event.target.files[0]);
        const fileDarkMode = event.target.files[0];
        console.log(fileDarkMode);
        if (fileDarkMode != undefined) {
            if (fileDarkMode.size > 1024 * 1024 * 2) {
                this.imageDarkMode_err = "Too large file: " + Math.round((fileDarkMode.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.imageDarkMode_err = "";
                this.readFileDarkMode(fileDarkMode);
                this.setState({ fileDarkMode: fileDarkMode, newimageDarkMode: true, imagechangedDarkMode: true });
            }
        }
        else { }
    }
    fileChangedHandlerBackgroundDarkMode = (event) => {
        const fileBackgroundDarkMode = event.target.files[0];
        console.log(fileBackgroundDarkMode);
        if (fileBackgroundDarkMode != undefined) {
            if (fileBackgroundDarkMode.size > 1024 * 1024 * 2) {
                this.imageBackgroundDarkMode_err = "Too large file: " + Math.round((fileBackgroundDarkMode.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.imageBackgroundDarkMode_err = "";
                this.readFileBackgroundDarkMode(fileBackgroundDarkMode);
                this.setState({ fileBackgroundDarkMode: fileBackgroundDarkMode, newBackgroundImageDarkMode: true, imagechangedBackgroundDarkmode: true });
            }
        }
        else { }
    }
    removeIconDarkMode() {
        this.props.organisationdata.logoUrlDarkMode = null;
        this.props.organisationdata.logoDarkMode = null;
        base64 = null;
        this.setState({ removeIconOpenDarkMode: false, newimageDarkMode: false });
    }
    removeBackgroundDarkMode() {
        this.props.organisationdata.imageUrlDarkMode = null;
        this.props.organisationdata.imageContentDarkMode = null;
        base64 = null;
        this.setState({ removeBackgroundOpenDarkMode: false, newBackgroundImageDarkMode: false });
    }
    shortcutDarkMode() {
        if (this.state.fileDarkMode == "") {
            return null;
        }
        else if (this.state.fileDarkMode != null) {
            var Data = [];
            Data.push(this.state.fileDarkMode);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/x-png,image/jpeg" }))
            return (
                <Box direction="row">
                    <input type="file" id="realDarkMode" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandlerDarkMode} />
                    <Image style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("realDarkMode");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }
    shortcutBackgroundDarkMode() {
        if (this.state.fileBackgroundDarkMode == "") {
            return null;
        }
        else if (this.state.fileBackgroundDarkMode != null) {
            var Data = [];
            Data.push(this.state.fileBackgroundDarkMode);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/x-png,image/jpeg" }))
            return (
                <Box direction="row">
                    <input type="file" id="realBackgroundDarkMode" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                    <Image style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("realBackgroundDarkMode");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }
    readFileDarkMode(f) {
        if (f) {
            const readerDarkMode = new FileReader();
            readerDarkMode.onload = function (event) {
                const dataDarkMode = event.target.result;
                const base64dataDarkMode = btoa(dataDarkMode);
                base64DarkMode = base64dataDarkMode;
            };
            readerDarkMode.readAsBinaryString(f);
        }
    }
    readFileBackgroundDarkMode(f) {
        if (f) {
            const readerBackgroundDarkMode = new FileReader();
            readerBackgroundDarkMode.onload = function (event) {
                const dataBackgroundDarkMode = event.target.result;
                const base64dataBackgroundDarkMode = btoa(dataBackgroundDarkMode);
                base64BackgroundDarkMode = base64dataBackgroundDarkMode;
            };
            readerBackgroundDarkMode.readAsBinaryString(f);
        }
    }
    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            //this.props.history.push('/orgsites');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    onActive(index) {
        this.setState({ index: index });
    }
    render() {
        const organisationdefault = this.props.organisationdata;
        const { organisationdataLoading } = this.state;

        if (organisationdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }

        var color = this.colorPickerBackGround();

        //console.log("org:", organisationdefault);

        return (

            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >App Theme</Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpConfirm open={this.state.removeIconOpen} text={"Please confirm that you want to delete this image. The light mode logo associated with this organization will no longer be visible in the app."}
                        onClose={() => this.setState({ removeIconOpen: false })} onDelete={() => this.removeIcon()} />
                    <PopUpConfirm open={this.state.removeBackgroundOpen} text={"Please confirm that you want to delete this image. The light mode background associated with this organization will no longer be visible in the app."}
                        onClose={() => this.setState({ removeBackgroundOpen: false })} onDelete={() => this.removeBackground()} />
                    <PopUpConfirm open={this.state.removeIconOpenDarkMode} text={"Please confirm that you want to delete this image. The dark mode logo associated with this organization will no longer be visible in the app."}
                        onClose={() => this.setState({ removeIconOpenDarkMode: false })} onDelete={() => this.removeIconDarkMode()} />
                    <PopUpConfirm open={this.state.removeBackgroundOpenDarkMode} text={"Please confirm that you want to delete this image. The dark mode background associated with this organization will no longer be visible in the app."}
                        onClose={() => this.setState({ removeBackgroundOpenDarkMode: false })} onDelete={() => this.removeBackgroundDarkMode()} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <form>
                        <Tabs justify="start" activeIndex={this.state.index} onActive={this.onActive.bind(this)}>
                            <Tab title="App Theme">
                                <Box gap="small" pad={{ "bottom": "small" }}>
                                    <Box pad={{ "top": "small" }}>
                                        <Text size="normal">Configure your theme for both Light Mode and Dark Mode.
                                            The client app will display both themes dynamically when a user switches the mode on their device.</Text>
                                        <Text size="large" weight="bold" margin={{ "top": "small" }}>App Logo, Typography & Pallette</Text>
                                        <Text size="medium">The theme you choose here will be applied in the client app.
                                            A preview of the colours in each mode is displayed to help you visualise how they will look.</Text>
                                    </Box>
                                    <Box direction="row" gap="small" align="center" margin={{ "top": "large" }}>
                                        <Text size="large" margin={{ "bottom": "small" }}>Organization Logo</Text>
                                        <Box data-for="img_info" data-tip margin={{ "bottom": "small" }}>
                                            <CircleInformation />
                                            <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                <span>Provide your logo in positive format. Recommended Image is transparent <br />
                                                    a PNG or JPEG in Landscape format, no larger than 2MB</span>
                                            </ReactTooltip>
                                        </Box>
                                    </Box>
                                    <Box direction="row" gap="small">
                                        <Box width={{ "min": "635px" }}>
                                            <Box>
                                                <Text size="large" weight="bold" margin={{ "bottom": "small" }}>Light Mode</Text>
                                            </Box>
                                            <Box direction="row" gap="small">
                                                <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }} width={{ "min": "101px" }} fill={false}>
                                                    <FormField label="" error={this.image_err}>
                                                        <Box direction="row" justify="center">
                                                            {this.state.newimage ?
                                                                <Box alignSelf="center">
                                                                    {this.shortcut()}
                                                                </Box>
                                                                :
                                                                (organisationdefault.logoUrl == "" || organisationdefault.logoUrl == null) ?
                                                                    <Box height="75px" justify="center">
                                                                        <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                        <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} onClick={e => {
                                                                            const c = document.getElementById("real");
                                                                            c.click();
                                                                        }} />
                                                                    </Box>
                                                                    :
                                                                    <Box justify="center">
                                                                        <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                        <Image height="75" src={`${organisationdefault.logoUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                            onClick={e => {
                                                                                const c = document.getElementById("real");
                                                                                c.click();
                                                                            }} />
                                                                    </Box>
                                                            }
                                                        </Box>
                                                    </FormField>
                                                </StyledBox>
                                                <Box justify="center">
                                                    <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={(organisationdefault.logoUrl == "" || organisationdefault.logoUrl == null) && !this.state.newimage}>
                                                        <Trash />
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box>
                                                <Text size="large" weight="bold" margin={{ "bottom": "small" }}>Dark Mode</Text>
                                            </Box>
                                            <Box direction="row" gap="small">
                                                <StyledBox style1black pad={{ "horizontal": "small", "top": "small" }} width={{ "min": "101px" }} fill={false}>
                                                    <FormField label="" error={this.imageDarkMode_err}>
                                                        <Box direction="row" justify="center">
                                                            {this.state.newimageDarkMode ?
                                                                <Box alignSelf="center">
                                                                    {this.shortcutDarkMode()}
                                                                </Box>
                                                                :
                                                                (organisationdefault.logoUrlDarkMode == "" || organisationdefault.logoUrlDarkMode == null) ?
                                                                    <Box height="75px" justify="center">
                                                                        <input type="file" id="realDarkMode" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandlerDarkMode} />
                                                                        <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} onClick={e => {
                                                                            const c = document.getElementById("realDarkMode");
                                                                            c.click();
                                                                        }} />
                                                                    </Box>
                                                                    :
                                                                    <Box justify="center">
                                                                        <input type="file" id="realDarkMode" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandlerDarkMode} />
                                                                        <Image height="75" src={`${organisationdefault.logoUrlDarkMode}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                            onClick={e => {
                                                                                const c = document.getElementById("realDarkMode");
                                                                                c.click();
                                                                            }} />
                                                                    </Box>
                                                            }
                                                        </Box>
                                                    </FormField>
                                                </StyledBox>
                                                <Box justify="center">
                                                    <Button onClick={() => this.setState({ removeIconOpenDarkMode: true })} disabled={(organisationdefault.logoUrlDarkMode == "" || organisationdefault.logoUrlDarkMode == null) && !this.state.newimageDarkMode}>
                                                        <Trash />
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/*<Box width="small">*/}
                                    {/*    <input type="file" id="real" hidden="hidden" accept="image/*" onChange={this.fileChangedHandler} />*/}
                                    {/*    <StyledButton typeAdd label="Choose a file" onClick={e => {*/}
                                    {/*        const c = document.getElementById("real");*/}
                                    {/*        c.click();*/}
                                    {/*    }} />*/}
                                    {/*</Box>*/}
                                    <Box direction="row" gap="small" align="center" margin={{ "top": "large" }}>
                                        <Text size="large" margin={{ "bottom": "small" }}>Background Image</Text>
                                        <Box data-for="bg_img_info" data-tip margin={{ "bottom": "small" }}>
                                            <CircleInformation />
                                            <ReactTooltip id="bg_img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                <span>Provide your background image in positive format. Recommended Image is transparent <br />
                                                    a PNG or JPEG in Landscape format, no larger than 2MB</span>
                                            </ReactTooltip>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box direction="row" gap="small">
                                            <Box width={{ "min": "635px" }}>
                                                <Box>
                                                    <Text size="large" weight="bold" margin={{ "bottom": "small" }}>Light Mode</Text>
                                                </Box>
                                                <Box direction="row" gap="small">
                                                    <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }} width={{ "min": "101px" }} fill={false}>
                                                        <FormField label="" error={this.imageBackground_err}>
                                                            <Box direction="row" justify="center">
                                                                {this.state.newBackgroundImage ?
                                                                    <Box alignSelf="center">
                                                                        {this.shortcutBackground()}
                                                                    </Box>
                                                                    :
                                                                    (organisationdefault.imageUrl == "" || organisationdefault.imageUrl == null) ?
                                                                        <Box height="75px" justify="center">
                                                                            <input type="file" id="realBackground" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandlerBackground} />
                                                                            <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} onClick={e => {
                                                                                const c = document.getElementById("realBackground");
                                                                                c.click();
                                                                            }} />
                                                                        </Box>
                                                                        :
                                                                        <Box justify="center">
                                                                            <input type="file" id="realBackground" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandlerBackground} />
                                                                            <Image height="75" src={`${organisationdefault.imageUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                                onClick={e => {
                                                                                    const c = document.getElementById("realBackground");
                                                                                    c.click();
                                                                                }} />
                                                                        </Box>
                                                                }
                                                            </Box>
                                                        </FormField>
                                                    </StyledBox>
                                                    <Box justify="center">
                                                        <Button onClick={() => this.setState({ removeBackgroundOpen: true })} disabled={(organisationdefault.imageUrl == "" || organisationdefault.imageUrl == null) && !this.state.newBackgroundImage}>
                                                            <Trash />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Box>
                                                    <Text size="large" weight="bold" margin={{ "bottom": "small" }}>Dark Mode</Text>
                                                </Box>
                                                <Box direction="row" gap="small">
                                                    <StyledBox style1black pad={{ "horizontal": "small", "top": "small" }} width={{ "min": "101px" }} fill={false}>
                                                        <FormField label="" error={this.imageBackgroundDarkMode_err}>
                                                            <Box direction="row" justify="center">
                                                                {this.state.newBackgroundImageDarkMode ?
                                                                    <Box alignSelf="center">
                                                                        {this.shortcutBackgroundDarkMode()}
                                                                    </Box>
                                                                    :
                                                                    (organisationdefault.imageUrlDarkMode == "" || organisationdefault.imageUrlDarkMode == null) ?
                                                                        <Box height="75px" justify="center">
                                                                            <input type="file" id="realBackgroundDarkMode" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandlerBackgroundDarkMode} />
                                                                            <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} onClick={e => {
                                                                                const c = document.getElementById("realBackgroundDarkMode");
                                                                                c.click();
                                                                            }} />
                                                                        </Box>
                                                                        :
                                                                        <Box justify="center">
                                                                            <input type="file" id="realBackgroundDarkMode" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandlerBackgroundDarkMode} />
                                                                            <Image height="75" src={`${organisationdefault.imageUrlDarkMode}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                                onClick={e => {
                                                                                    const c = document.getElementById("realBackgroundDarkMode");
                                                                                    c.click();
                                                                                }} />
                                                                        </Box>
                                                                }
                                                            </Box>
                                                        </FormField>
                                                    </StyledBox>
                                                    <Box justify="center">
                                                        <Button onClick={() => this.setState({ removeBackgroundOpenDarkMode: true })} disabled={(organisationdefault.imageUrlDarkMode == "" || organisationdefault.imageUrlDarkMode == null) && !this.state.newBackgroundImageDarkMode}>
                                                            <Trash />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box direction="row" gap="small" align="center" margin={{ "top": "large" }}>
                                        <Text size="large" margin={{ "bottom": "small" }}>Colour Pallette & Typography</Text>
                                        <Box data-for="pallette_info" data-tip margin={{ "bottom": "small" }}>
                                            <CircleInformation />
                                            <ReactTooltip id="pallette_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                                <span>
                                                    Apply your app theme using the colour and font settings below. <br />
                                                    Primary - Used by all main actions in the app <br />
                                                    Secondary - Used by informational badges displayed in the app <br />
                                                    Tertiary - Used by all floating action buttons (FABs)
                                                </span>
                                            </ReactTooltip>
                                        </Box>
                                    </Box>
                                    <Box direction="row" gap="medium">
                                        <Box direction="row" gap="small">
                                            <Box>
                                                <Box>
                                                    <Text>Primary</Text>
                                                </Box>
                                                <Box direction="row" justify="start" gap="large">
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Background" error={this.primaryColor_err} width="150px">
                                                            <TextInput readOnly={true} value={organisationdefault.primaryColor} onClick={() => this.handlePicker("primary")} style={{ cursor: "pointer" }} />
                                                        </FormField>
                                                    </StyledBox>
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Font" error={this.primaryFontColor_err} width="150px">
                                                            <Select options={["Dark", "Light"]} value={organisationdefault.primaryFontColor == "" ? ("") : (organisationdefault.primaryFontColor == "#333333" ? "Dark" : "Light")}
                                                                onChange={this.updatePrimaryFontColor} />
                                                        </FormField>
                                                    </StyledBox>
                                                </Box>

                                                <Box margin={{ "top": "small" }}>
                                                    <Text>Secondary</Text>
                                                </Box>
                                                <Box direction="row" justify="start" gap="large">
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Background" error={this.secondaryColor_err} width="150px">
                                                            <TextInput readOnly={true} value={organisationdefault.secondaryColor} onClick={() => this.handlePicker("secondary")} style={{ cursor: "pointer" }} />
                                                        </FormField>
                                                    </StyledBox>
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Font" error={this.secondaryFontColor_err} width="150px">
                                                            <Select options={["Dark", "Light"]} value={organisationdefault.secondaryFontColor == "" ? ("") : (organisationdefault.secondaryFontColor == "#333333" ? "Dark" : "Light")}
                                                                onChange={this.updateSecondaryFontColor} />
                                                        </FormField>
                                                    </StyledBox>
                                                </Box>

                                                <Box margin={{ "top": "small" }}>
                                                    <Text>Tertiary</Text>
                                                </Box>
                                                <Box direction="row" justify="start" gap="large">
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Background" error={this.tertiaryColor_err} width="150px">
                                                            <TextInput readOnly={true} value={organisationdefault.tertiaryColor} onClick={() => this.handlePicker("tertiary")} style={{ cursor: "pointer" }} />
                                                        </FormField>
                                                    </StyledBox>
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Font" error={this.tertiaryFontColor_err} width="150px">
                                                            <Select options={["Dark", "Light"]} value={organisationdefault.tertiaryFontColor == "" ? ("") : (organisationdefault.tertiaryFontColor == "#333333" ? "Dark" : "Light")}
                                                                onChange={this.updateTertiaryFontColor} />
                                                        </FormField>
                                                    </StyledBox>
                                                </Box>
                                            </Box>

                                            <Box gap="medium" justify="center" pad={{ "top": "medium" }}>
                                                <StyledBox thickborder="true" background="transparent" border="true" round="medium" height="350px" width="190px" minWidth="200px" justify="center" > {/* Phone */}
                                                    <Box height="12.4%" justify="center" gap="small" direction="column"> {/* Speaker */}
                                                        <Box justify="center" align="center" >
                                                            <StatusGoodSmall size="small" color="black" />
                                                        </Box>
                                                        <Box alignSelf="center" round="xlarge" height="18px" width="45px" background="black" margin={{ "bottom": "small" }} />
                                                    </Box>
                                                    <Box justify="center" align="center" background="white" height="75.1%" gap="medium" pad={{ "bottom": "medium" }}>   {/* Display */}
                                                        <Box align="center" justify="center" height="45px" width="full" style={{ background: organisationdefault.primaryColor, color: organisationdefault.primaryFontColor }}>
                                                            <Text textAlign="center" size="22px">Primary </Text>
                                                        </Box>

                                                        <Box align="center" justify="center" height="45px" width="full" style={{ background: organisationdefault.secondaryColor, color: organisationdefault.secondaryFontColor }}>
                                                            <Text textAlign="center" size="22px">Secondary </Text>
                                                        </Box>

                                                        <Box align="center" justify="center" height="45px" width="full" style={{ background: organisationdefault.tertiaryColor, color: organisationdefault.tertiaryFontColor }}>
                                                            <Text textAlign="center" size="22px">Tertiary </Text>
                                                        </Box>
                                                    </Box>
                                                    <Box height="12.4%" justify="center" align="center" direction="row"> {/* Home button */}
                                                        <Radial size="35px" color="black" />
                                                    </Box>
                                                </StyledBox>
                                            </Box>

                                        </Box>
                                        <Box />
                                        {/*<Box width="small">*/}
                                        {/*    <input type="file" id="realDarkMode" hidden="hidden" accept="image/*" onChange={this.fileChangedHandlerDarkMode} />*/}
                                        {/*    <StyledButton typeAdd label="Choose a file" onClick={e => {*/}
                                        {/*        const c = document.getElementById("realDarkMode");*/}
                                        {/*        c.click();*/}
                                        {/*    }} />*/}
                                        {/*</Box>*/}

                                        <Box direction="row" gap="small">
                                            <Box>
                                                <Box>
                                                    <Text>Primary</Text>
                                                </Box>
                                                <Box direction="row" justify="start" gap="large">
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Background" error={this.primaryColorDarkMode_err} width="150px">
                                                            <TextInput readOnly={true} value={organisationdefault.primaryColorDarkMode} onClick={() => this.handlePicker("primaryDarkMode")} style={{ cursor: "pointer" }} />
                                                        </FormField>
                                                    </StyledBox>
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Font" error={this.primaryFontColorDarkMode_err} width="150px">
                                                            <Select options={["Dark", "Light"]} value={organisationdefault.primaryFontColorDarkMode == "" ? ("") : (organisationdefault.primaryFontColorDarkMode == "#333333" ? "Dark" : "Light")}
                                                                onChange={this.updatePrimaryFontColorDarkMode} />
                                                        </FormField>
                                                    </StyledBox>
                                                </Box>

                                                <Box margin={{ "top": "small" }}>
                                                    <Text>Secondary</Text>
                                                </Box>
                                                <Box direction="row" justify="start" gap="large">
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Background" error={this.secondaryColorDarkMode_err} width="150px">
                                                            <TextInput readOnly={true} value={organisationdefault.secondaryColorDarkMode} onClick={() => this.handlePicker("secondaryDarkMode")} style={{ cursor: "pointer" }} />
                                                        </FormField>
                                                    </StyledBox>
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Font" error={this.secondaryFontColorDarkMode_err} width="150px">
                                                            <Select options={["Dark", "Light"]} value={(organisationdefault.secondaryFontColorDarkMode == "" ? ("") : (organisationdefault.secondaryFontColorDarkMode == "#333333" ? "Dark" : "Light"))}
                                                                onChange={this.updateSecondaryFontColorDarkMode} />
                                                        </FormField>
                                                    </StyledBox>
                                                </Box>

                                                <Box margin={{ "top": "small" }}>
                                                    <Text>Tertiary</Text>
                                                </Box>
                                                <Box direction="row" justify="start" gap="large">
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Background" error={this.tertiaryColorDarkMode_err} width="150px">
                                                            <TextInput readOnly={true} value={organisationdefault.tertiaryColorDarkMode} onClick={() => this.handlePicker("tertiaryDarkMode")} style={{ cursor: "pointer" }} />
                                                        </FormField>
                                                    </StyledBox>
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Font" error={this.tertiaryFontColorDarkMode_err} width="150px">
                                                            <Select options={["Dark", "Light"]} value={organisationdefault.tertiaryFontColorDarkMode == "" ? ("") : (organisationdefault.tertiaryFontColorDarkMode == "#333333" ? "Dark" : "Light")}
                                                                onChange={this.updateTertiaryFontColorDarkMode} />
                                                        </FormField>
                                                    </StyledBox>
                                                </Box>
                                            </Box>

                                            <Box gap="medium" justify="center" pad={{ "top": "medium" }}>
                                                <StyledBox thickborder="true" background="transparent" border="true" round="medium" height="350px" width="190px" minWidth="200px" justify="center" > {/* Phone */}
                                                    <Box height="12.4%" justify="center" gap="small" direction="column"> {/* Speaker */}
                                                        <Box justify="center" align="center" >
                                                            <StatusGoodSmall size="small" color="black" />
                                                        </Box>
                                                        <Box alignSelf="center" round="xlarge" height="18px" width="45px" background="black" margin={{ "bottom": "small" }} />
                                                    </Box>
                                                    <Box justify="center" align="center" background="black" height="75.1%" gap="medium" pad={{ "bottom": "medium" }}>   {/* Display */}
                                                        <Box align="center" justify="center" height="45px" width="full" style={{ background: organisationdefault.primaryColorDarkMode, color: organisationdefault.primaryFontColorDarkMode }}>
                                                            <Text textAlign="center" size="22px">Primary </Text>
                                                        </Box>

                                                        <Box align="center" justify="center" height="45px" width="full" style={{ background: organisationdefault.secondaryColorDarkMode, color: organisationdefault.secondaryFontColorDarkMode }}>
                                                            <Text textAlign="center" size="22px">Secondary </Text>
                                                        </Box>

                                                        <Box align="center" justify="center" height="45px" width="full" style={{ background: organisationdefault.tertiaryColorDarkMode, color: organisationdefault.tertiaryFontColorDarkMode }}>
                                                            <Text textAlign="center" size="22px">Tertiary </Text>
                                                        </Box>
                                                    </Box>
                                                    <Box height="12.4%" justify="center" align="center" direction="row"> {/* Home button */}
                                                        <Radial size="35px" color="black" />
                                                    </Box>
                                                </StyledBox>
                                            </Box>

                                        </Box>
                                    </Box>

                                    <Box /*pad={{ horizontal: "small", }} gap="small" align="end"*/>
                                        {this.state.showPickColor && (
                                            <Layer
                                                onClickOutside={() => this.handlePicker(null, true)} onEsc={() => this.handlePicker(null, true)} plain={false}>
                                                <SketchPicker
                                                    //presetColors={colors}
                                                    color={color}
                                                    disableAlpha={true}
                                                    onChangeComplete={this.handleChangeSComplete} />
                                            </Layer>)}
                                    </Box>
                                </Box>
                            </Tab>
                            <Tab title="App Screens">
                                <Box gap="large" pad={{ "top": "small", "bottom": "medium" }}>
                                    {/*<Text size="normal">Configure your application with Landing screen and Home screen choices.</Text>*/}
                                    <Box gap="medium">
                                        <Box>
                                            <Text size="large">Home Screen</Text>
                                            <Text>Configure your home screen to use one of the style templates below. <br />
                                                    All content, imagery and icons can be added once the app has been configured at org level.</Text>
                                        </Box>
                                        <Box gap="xsmall" width="250px">
                                            <Text size="medium">App Type</Text>
                                            <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }}>
                                                <FormField>
                                                    <Select
                                                        placeholder="Select App Type"
                                                        options={["Campus", "Social", "Tabbed"]}
                                                        disabled={[2]}
                                                        value={organisationdefault.appType}
                                                        onChange={event => this.updateAppType(event)} />
                                                </FormField>
                                            </StyledBox>
                                        </Box>

                                        <Box direction="row">
                                            <Box gap="xsmall">
                                                <Box>
                                                    <Text>Screen Components</Text>
                                                </Box>
                                                <Box direction="row" gap="large" align="start">
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        {organisationdefault.appType == "Social" &&
                                                            <Box>
                                                                <Box direction="row" gap="medium">
                                                                    <FormField label="App Logo" error={this.appLogoErrorMsg()}>
                                                                        <Box direction="row" gap="small" margin="small">
                                                                            <CheckBox checked={organisationdefault.showAppLogo} onChange={evt => { organisationdefault.showAppLogo = !organisationdefault.showAppLogo; this.setState({ updated: true }); }} />
                                                                            <Text>Display</Text>
                                                                        </Box>
                                                                    </FormField>
                                                                    <Box width="150px" >
                                                                        <FormField label="Alignment" error={this.aligment_err}>
                                                                            <Select style={{ "height": "48px" }}
                                                                                options={["Left", "Center", "Right"]}
                                                                                value={this.getLogoHorizontalPositionValue(organisationdefault.logoHorizontalPosition)}
                                                                                onChange={event => this.updateLogoHorizontalPosition(event)} />
                                                                        </FormField>
                                                                    </Box>
                                                                </Box>
                                                                <Box direction="row">
                                                                    <FormField label="Background Image" error={this.appBackgroundErrorMsg()}>
                                                                        <Box direction="row" gap="small" margin="small">
                                                                            <CheckBox checked={organisationdefault.showAppImage} onChange={evt => { organisationdefault.showAppImage = !organisationdefault.showAppImage; this.setState({ updated: true }); }} />
                                                                            <Text>Display</Text>
                                                                        </Box>
                                                                    </FormField>
                                                                </Box>
                                                            </Box>}
                                                        <Box direction="row">
                                                            <FormField label="Org Name">
                                                                <Box direction="row" gap="small" margin="small">
                                                                    <CheckBox checked={organisationdefault.showOrgName} onChange={evt => { organisationdefault.showOrgName = !organisationdefault.showOrgName; this.setState({ updated: true }); }} />
                                                                    <Text>Display</Text>
                                                                </Box>
                                                            </FormField>
                                                        </Box>
                                                        <Box direction="row">
                                                            <FormField label="Image Overlay">
                                                                <Box direction="row" gap="small" margin="small">
                                                                    <CheckBox checked={organisationdefault.showImageOverlay} onChange={evt => { organisationdefault.showImageOverlay = !organisationdefault.showImageOverlay; this.setState({ updated: true }); }} />
                                                                    <Text>Display</Text>
                                                                </Box>
                                                            </FormField>
                                                        </Box>
                                                        <Box direction="row">
                                                            <FormField label="Site Name">
                                                                <Box direction="row" gap="small" margin="small">
                                                                    <CheckBox checked={organisationdefault.showSiteName} onChange={evt => { organisationdefault.showSiteName = !organisationdefault.showSiteName; this.setState({ updated: true }); }} />
                                                                    <Text>Display</Text>
                                                                </Box>
                                                            </FormField>
                                                        </Box>
                                                        <Box direction="row">
                                                            <FormField label="Date">
                                                                <Box direction="row" gap="small" margin="small">
                                                                    <CheckBox checked={organisationdefault.showDate} onChange={evt => { organisationdefault.showDate = !organisationdefault.showDate; this.setState({ updated: true }); }} />
                                                                    <Text>Display</Text>
                                                                </Box>
                                                            </FormField>
                                                        </Box>
                                                        <Box direction="row">
                                                            <FormField label="Brand Color Line">
                                                                <Box direction="row" gap="small" margin="small">
                                                                    <CheckBox checked={organisationdefault.showBrandBar} onChange={evt => { organisationdefault.showBrandBar = !organisationdefault.showBrandBar; this.setState({ updated: true }); }} />
                                                                    <Text>Display</Text>
                                                                </Box>
                                                            </FormField>
                                                        </Box>
                                                        <Box direction="row" gap="medium">
                                                            <FormField label="Events & News" readOnly={organisationdefault.appType == "Campus"}>
                                                                <Box direction="row" gap="small" align="center" pad="small">
                                                                    <CheckBox checked={organisationdefault.appType == "Campus" ? true : organisationdefault.showEvents} disabled={organisationdefault.appType == "Campus"} onChange={evt => { organisationdefault.showEvents = !organisationdefault.showEvents; this.setState({ updated: true }); }} />
                                                                    <Text>Display</Text>
                                                                </Box>
                                                            </FormField>
                                                            <Grommet theme={customTheme}>
                                                                <a data-for="EN_Info" data-tip="This field can be renamed to suit your business needs. Default values will apply if no change is made.">
                                                                    <FormField label="Section Header" error={this.eventsSectionName_err}>
                                                                        <Box direction="row" gap="medium" width="350px">
                                                                            <TextInput placeholder="Events & News" defaultValue={organisationdefault.eventsSectionName} onChange={evt => this.updateEventsText(evt)} />
                                                                        </Box>
                                                                    </FormField>
                                                                </a>
                                                                <ReactTooltip id="EN_Info" place="bottom" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                            </Grommet>
                                                        </Box>
                                                        <Box direction="row" gap="medium">
                                                            <FormField label={window.navigator.language.toLowerCase() === "en-us" ? "Action Center" : "Action Centre"} readOnly={organisationdefault.appType == "Campus"}>
                                                                <Box direction="row" gap="small" align="center" pad="small">
                                                                    <CheckBox checked={organisationdefault.appType == "Campus" ? true : organisationdefault.showActions} disabled={organisationdefault.appType == "Campus"} onChange={evt => { organisationdefault.showActions = !organisationdefault.showActions; this.setState({ updated: true }); }} />
                                                                    <Text>Display</Text>
                                                                </Box>
                                                            </FormField>
                                                            <Grommet theme={customTheme}>
                                                                <a data-for="AC_Info" data-tip="This field can be renamed to suit your business needs. Default values will apply if no change is made.">
                                                                    <FormField label="Section Header" error={this.actionsSectionName_err}>
                                                                        <Box direction="row" gap="medium" width="350px">
                                                                            <TextInput placeholder={window.navigator.language.toLowerCase() === "en-us" ? "Action Center" : "Action Centre"}
                                                                                defaultValue={organisationdefault.actionsSectionName} onChange={evt => this.updateActionCenterText(evt)} />
                                                                        </Box>
                                                                    </FormField>
                                                                </a>
                                                                <ReactTooltip id="AC_Info" place="bottom" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                            </Grommet>
                                                        </Box>
                                                        {organisationdefault.appType == "Social" &&
                                                            <Box direction="row">
                                                                <a data-for="PA_Info" data-tip="The promoted action bar will only appear in the client if there is at least one active promoted action configured for a site. <br/>
                                                                                                A maximum of 3 promoted actions can be active. This can be configured via the action center at site level.">
                                                                    <FormField label="Promoted Action Bar">
                                                                        <Box direction="row" gap="small" margin="small">
                                                                            <CheckBox checked={organisationdefault.showPromotedActions} onChange={evt => { organisationdefault.showPromotedActions = !organisationdefault.showPromotedActions; this.setState({ updated: true }); }} />
                                                                            <Text>Display</Text>
                                                                        </Box>
                                                                    </FormField>
                                                                </a>
                                                                <ReactTooltip id="PA_Info" place="bottom" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                            </Box>}
                                                    </StyledBox>
                                                    <Box direction="row" fill="vertical" align="end">
                                                        {organisationdefault.appType == "Campus" && window.navigator.language.toLowerCase() === "en-us" &&
                                                            <Image src="./images/configurable_app/type_campus_US.jpg" />}
                                                        {organisationdefault.appType == "Campus" && window.navigator.language.toLowerCase() !== "en-us" &&
                                                            <Image src="./images/configurable_app/type_campus_UK.jpg" />}
                                                        {organisationdefault.appType == "Social" && window.navigator.language.toLowerCase() === "en-us" &&
                                                            <Image src="./images/configurable_app/type_social_US.jpg" />}
                                                        {organisationdefault.appType == "Social" && window.navigator.language.toLowerCase() !== "en-us" &&
                                                            <Image src="./images/configurable_app/type_social_UK.jpg" />}
                                                        {organisationdefault.appType == "Tabbed" && window.navigator.language.toLowerCase() === "en-us" &&
                                                            <Image src="./images/configurable_app/type_tabbed_US.jpg" />}
                                                        {organisationdefault.appType == "Tabbed" && window.navigator.language.toLowerCase() !== "en-us" &&
                                                            <Image src="./images/configurable_app/type_tabbed_UK.jpg" />}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box gap="xsmall">
                                        <Text size="large">Landing Screen</Text>
                                        <Text>Configure your landing screen and customize it for your users. It can be used as a welcome message, privacy policy or app overview.<br />
                                                Activated landing screens will appear before login.</Text>
                                        <Box direction="row">
                                            <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                <Box direction="row" gap="small" margin={{ "vertical": "small" }}>
                                                    <CheckBox checked={organisationdefault.landingScreenEnabled} onChange={evt => this.updateLandingScreenEnabled(evt)} />
                                                    <Text>Landing Screen enabled</Text>
                                                </Box>
                                                <FormField label='Landing Screen Title' error={this.landingScrTitle_err}>
                                                    <TextInput defaultValue={organisationdefault.landingScreenTitle} onChange={evt => this.updateLandingScreenTitle(evt)} />
                                                </FormField>
                                                <Grommet theme={customTheme}>
                                                    <FormField label='Landing Screen Summary' error={this.landingScrSummary_err} data-bounds="html_editor">
                                                        <ReactQuill
                                                            theme="snow"
                                                            bounds={`[data-bounds="html_editor"]`}
                                                            scrollingContainer="html"
                                                            modules={{
                                                                toolbar: [
                                                                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                                                    ['blockquote', 'code-block'],
                                                                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                    [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
                                                                    [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
                                                                    [{ 'direction': 'rtl' }],                         // text direction
                                                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                                                    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                                                                    ['link'],
                                                                    ['clean']                                         // remove formatting button
                                                                ],
                                                                clipboard: {
                                                                    matchVisual: false,
                                                                }
                                                            }}
                                                            onChange={this.handleEditorChange}
                                                            value={organisationdefault.landingScreenSummary}
                                                        />
                                                    </FormField>
                                                </Grommet>
                                                <Box margin={{ "top": "small" }}>
                                                    <FormField label={<Text size="xsmall">Custom Button Text<br />
                                                    The landing screen contains a button in order for the user to proceed to login. You can customize the text as required depending on it's use.</Text>} error={this.landingScrButton_err}>
                                                        <TextInput defaultValue={organisationdefault.landingScreenButtonText} onChange={evt => this.updateLandingScreenButtonText(evt)} />
                                                    </FormField>
                                                </Box>
                                            </StyledBox>
                                        </Box>
                                    </Box>
                                </Box>
                            </Tab>
                            {this.context.feature.find(f => f.name == "map").isEnabled &&
                                <Tab title="Map Theme">
                                    <Box gap="medium" pad={{ "top": "small", "bottom": "medium" }}>
                                        <Box>
                                            <Text size="large">Map Theme</Text>
                                            <Text>The map theme you choose here will be applied in the client app.<br />
                                            A preview of the colours in each mode is displayed to help you visualise how they will look. The default colour displayed will be used unless you change it for each mode.</Text>
                                        </Box>

                                        <Box gap="large">
                                            <Box direction="row" gap="large">
                                                <Box direction="column" gap="medium">
                                                    <Text size="large">Light Mode</Text>
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Map Background" error={this.mapColor_err} width="200px">
                                                            <TextInput readOnly={true} value={organisationdefault.mapColor} onClick={() => this.handlePicker("mapColor")} style={{ cursor: "pointer" }} />
                                                        </FormField>
                                                    </StyledBox>
                                                </Box>
                                                <Box gap="medium" justify="center">
                                                    <StyledBox thickborder="true" background="transparent" border="true" round="medium" height="350px" width="190px" minWidth="200px" justify="center" > {/* Phone */}
                                                        <Box height="12.4%" justify="center" gap="small" direction="column"> {/* Speaker */}
                                                            <Box justify="center" align="center" >
                                                                <StatusGoodSmall size="small" color="black" />
                                                            </Box>
                                                            <Box alignSelf="center" round="xlarge" height="18px" width="45px" background="black" margin={{ "bottom": "small" }} />
                                                        </Box>
                                                        <Box justify="center" align="center" background={organisationdefault.mapColor} height="75.1%" gap="medium" pad={{ "bottom": "medium" }}>   {/* Display */}
                                                            <Box direction="column" justify="between" pad="small" height="110px" width="110px" background="white">
                                                                <Resources style={{ "align-self": "flex-end" }} />
                                                                <Text textAlign="center" size="20px">Map</Text>
                                                            </Box>
                                                        </Box>
                                                        <Box height="12.4%" justify="center" align="center" direction="row"> {/* Home button */}
                                                            <Radial size="35px" color="black" />
                                                        </Box>
                                                    </StyledBox>
                                                </Box>
                                            </Box>

                                            <Box direction="row" gap="large">
                                                <Box direction="column" gap="medium">
                                                    <Text size="large">Dark Mode</Text>
                                                    <StyledBox style1 pad={{ "horizontal": "small" }}>
                                                        <FormField label="Map Background" error={this.mapColorDarkMode_err} width="200px">
                                                            <TextInput readOnly={true} value={organisationdefault.mapColorDarkMode} onClick={() => this.handlePicker("mapColorDarkMode")} style={{ cursor: "pointer" }} />
                                                        </FormField>
                                                    </StyledBox>
                                                </Box>
                                                <Box gap="medium" justify="center">
                                                    <StyledBox thickborder="true" background="transparent" border="true" round="medium" height="350px" width="190px" minWidth="200px" justify="center" > {/* Phone */}
                                                        <Box height="12.4%" justify="center" gap="small" direction="column"> {/* Speaker */}
                                                            <Box justify="center" align="center" >
                                                                <StatusGoodSmall size="small" color="black" />
                                                            </Box>
                                                            <Box alignSelf="center" round="xlarge" height="18px" width="45px" background="black" margin={{ "bottom": "small" }} />
                                                        </Box>
                                                        <Box justify="center" align="center" background={organisationdefault.mapColorDarkMode} height="75.1%" gap="medium" pad={{ "bottom": "medium" }}>   {/* Display */}
                                                            <Box direction="column" justify="between" pad="small" height="110px" width="110px" background="white">
                                                                <Resources style={{ "align-self": "flex-end" }} />
                                                                <Text textAlign="center" size="20px">Map</Text>
                                                            </Box>
                                                        </Box>
                                                        <Box height="12.4%" justify="center" align="center" direction="row"> {/* Home button */}
                                                            <Radial size="35px" color="black" />
                                                        </Box>
                                                    </StyledBox>
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Box>
                                            {this.state.showPickColor &&
                                                <Layer
                                                    onClickOutside={() => this.handlePicker(null, true)} onEsc={() => this.handlePicker(null, true)} plain={false}>
                                                    <SketchPicker
                                                        color={color}
                                                        disableAlpha={true}
                                                        onChangeComplete={this.handleChangeSComplete} />
                                                </Layer>}
                                        </Box>
                                    </Box>
                                </Tab>}
                        </Tabs>
                    </form>
                </Box>

            </Grid>

        );
    }
}



const mapStateToProps = (state, ownProps) => {
    //const organisationId = constants.organisation.id;
    const organisationId = sessionStorage.getItem("organisationId");
    if (state.selectedOrganisationReducer.organisationdata && organisationId === state.selectedOrganisationReducer.organisationdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedOrganisationReducer.organisationdata));
        return {
            organisationdata: state.selectedOrganisationReducer.organisationdata
        };
    } else {
        return {};
    }
};


const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OrganisationAction }, dispatch),
    action: bindActionCreators(OrganisationAction, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppTheme));
