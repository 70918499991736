import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedMergedLevelReducer = (state = initialState.selectedMergedLevelReducer, action) => {
    switch(action.type) {

        case ActionType.GET_MERGEDLEVEL_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                mergedleveldata: _.assign(action.mergedleveldata)
            };
        }

        default: { return state; }
    }
};


export default selectedMergedLevelReducer;