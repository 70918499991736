import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CategoryAction from '../action/CategoryAction';
import * as LocationAction from '../action/LocationAction';
// eslint-disable-next-line
import {
    Achievement, Action, Actions, Aed, Aid, Alarm, Alert, Analytics, Announce, Apps, Archive, Article, Atm, Attachment, Attraction, Baby, Bar, BarChart, Basket, Bike, Book, Bookmark, Briefcase, Bug, Bus, Cafeteria,
    Calendar, Camera, Car, Cart, Catalog, Chat, ChatOption, CircleInformation, CircleQuestion, Clear, Clipboard, Clock, Cloud, Compliance, CoatCheck, Configure, Connect, Connectivity, ContactInfo, Contact, CreditCard, Currency,
    Dashboard, Deliver, Deploy, Desktop, Directions, DocumentCloud, DocumentText, Download, Edit, Emergency, Elevator, Escalator, Favorite, Filter, Gallery, Gamepad, Gift, Group, Grow, Help, Home, Image,
    InProgress, Inbox, Info, Java, Launch, Layer, Like, Link, List, Location, Login, Logout, Lounge, MailOption, Map, MapLocation, Microphone, Money, Monitor, More, Multimedia, Music, Navigate, Note, Notification,
    Organization, Overview, Package, PersonalComputer, Phone, PieChart, Pin, Plan, Print, Projects, Refresh, Restaurant, Restroom, RestroomMen, RestroomWomen, Run, Schedule, Scorecard, Secure, Shield, StatusWarning, Steps, Sun,
    Sync, Tag, Task, Template, Train, Trash, TreeOption, Trophy, User, Validate, Video, Vend, View, Waypoint, Wheelchair, Wifi, Workshop, Yoga, Blank, FormUp, FormDown, Mail, FormClose, Upload
} from 'grommet-icons';
import * as Icons from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Grommet, InfiniteScroll, RadioButtonGroup, RadioButton, CheckBox } from 'grommet';
import { Image as ImageComp } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import ReactTooltip from "react-tooltip";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const noBorderTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    formField: {
        border: {
            color: "transparent",
        }
    }
});

const OPTIONS = [
    { parent: "Food & Drink", location: "Services" },
    { parent: "Spaces", location: "Spaces" },
    { parent: "Map", location: "Info" },
    { parent: "Transport", location: "Services" },
    { parent: "Exit", location: "Facilities" },
    { parent: "Recreational", location: "Services" },
    { parent: "Restrooms", location: "Facilities" },
    { parent: "", location: "" }
];

var start = undefined;
var base64 = null;

var icondata = [
    "Achievement", "Action", "Actions", "Aed", "Aid", "Alarm", "Alert", "Analytics", "Announce", "Apps", "Archive", "Article", "Atm", "Attachment", "Attraction", "Baby", "Bar", "BarChart", "Basket", "Bike", "Book",
    "Bookmark", "Briefcase", "Bug", "Bus", "Cafeteria", "Calendar", "Camera", "Car", "Cart", "Catalog", "Chat", "ChatOption", "CircleInformation", "CircleQuestion", "Clear", "Clipboard", "Clock", "Cloud",
    "Compliance", "CoatCheck", "Configure", "Connect", "Connectivity", "ContactInfo", "Contact", "CreditCard", "Currency", "Dashboard", "Deliver", "Deploy", "Desktop", "Directions", "DocumentCloud", "DocumentText",
    "Download", "Edit", "Emergency", "Elevator", "Escalator", "Favorite", "Filter", "Gallery", "Gamepad", "Gift", "Group", "Grow", "Help", "Home", "Image", "InProgress", "Inbox", "Info", "Java", "Launch", "Layer", "Like",
    "Link", "List", "Location", "Login", "Logout", "Lounge", "MailOption", "Map", "MapLocation", "Microphone", "Money", "Monitor", "More", "Multimedia", "Music", "Navigate", "Note", "Notification", "Organization",
    "Overview", "Package", "PersonalComputer", "Phone", "PieChart", "Pin", "Plan", "Print", "Projects", "Refresh", "Restaurant", "Restroom", "RestroomMen", "RestroomWomen", "Run", "Schedule", "Scorecard", "Secure", "Shield",
    "StatusWarning", "Steps", "Sun", "Sync", "Tag", "Task", "Template", "Train", "Trash", "TreeOption", "Trophy", "User", "Validate", "Video", "Vend", "View", "Waypoint", "Wheelchair", "Wifi", "Workshop", "Yoga"]

const customTheme1 = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        },
    }
});

const customTheme2 = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        },
    },
    formField: {
        border: {
            color: "transparent",
        },
        pad: "none",
    }
});

const customTheme3 = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        },
    },
});

export class CategoryEditContainer extends React.Component {
    constructor() {
        super();
        this.parentfull = [];
        this.stamp = Date.now().toString();
        this.state = {
            options: [],
            selected: "",
            location: "",
            isSaved: false,
            open: false,
            notification: '',
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            TileList: icondata,
            openIconlist: false,
            search: "",
            file: "",
            newimage: false,
            imagechanged: false,
            removeIconOpen: false,
            letValidate: false,
            categorydataLoading: true,
            locationsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
            isManual: true
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    //Validating errors
    categoryName_err = "";
    categoryParent_err = "";
    tile_err = "";
    displaytype_err = "";

    ClearErrorMessages() {
        this.categoryName_err = "";
        this.categoryParent_err = "";
        this.tile_err = "";
        this.displaytype_err = "";
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.locationsdataLoading && this.props.locationsdata[0] && this.props.locationsdata[0].count != 0 && this.props.locationsdata.length > 0 && this.parentfull.length == 0) {
            //this.parentfull = this.props.locationsdata.filter((item) => item.location != "").slice();
            this.parentfull = this.props.locationsdata;

            //this.parentfull = this.props.locationsdata.slice();
            this.parentfull.push({
                parent: "",
                location: ""
            });
            this.parentfull.forEach(function (item) {
                if (item.parent == "" && item.location == "") {
                    item.parentloc = "";
                }
                else if (item.parent != "" && item.location == "") {
                    item.parentloc = item.parent;
                    item.location = item.parent;
                }
                else if (item.parent == item.location) {
                    item.parentloc = item.location;
                }
                else {
                    item.parentloc = item.location + " / " + item.parent;
                }
            })
            this.parentfull.sort((p1, p2) => { if (p1.parentloc.toLowerCase() < p2.parentloc.toLowerCase()) return -1; return 1; });
            this.setState({ options: this.parentfull });
        }
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.categoryId;
        this.props.action.getLocationsAction()
            .then(response => this.setState({ locationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-CategoryEdit/Parent Error', error);
            });
        this.props.action.getCategoryAction(this.props.match.params.categoryId)
            .then(response => this.setState({ categorydataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log("*DEBUG*-CategoryEdit Error", error);
            });

    }

    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.categorydata && !_.isEqual(start, this.props.categorydata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    handleSave() {
        const categorydefault = this.props.categorydata;
        categorydefault.location = categorydefault.location == "" ? categorydefault.name : categorydefault.location;
        categorydefault.parent = categorydefault.parent == "" ? categorydefault.name : categorydefault.parent;
        let errorsno = 0;
        this.ClearErrorMessages();
        this.setState({ validating: false, notification: "" });
        //categorydefault.synced = false;
        if (categorydefault.name.trim().length == 0) {
            errorsno++;
            this.categoryName_err = "Category Name is required";
        }
        if (categorydefault.name.length > 30) {
            errorsno++;
            this.categoryName_err = "Category Name can't be longer than 30 character";
        }
        //if (typeof (categorydefault.tileName) === 'undefined' || categorydefault.tileName.length === 0) {
        //    errorsno++;
        //    this.tile_err = "Select an Icon";
        //}
        //if (categorydefault.parent.trim().length == 0) {
        //    errorsno++;
        //    this.categoryParent_err = "Select an option";
        //}
        if (this.validationDisplayMode()) {
            errorsno++;
        }
        if (errorsno == 0) {
            // Ok, saving data
            //if (this.state.newimage) {
            //    categorydefault.imageContent = base64;
            //    base64 = null;
            //}
            this.setState({ disabledButton: true });
            let locId = '';
            if (sessionStorage.getItem('locationId')) {
                locId = sessionStorage.getItem('locationId');
            }
            categorydefault.locationId = locId;
            console.log('Save: OK', categorydefault);
            this.props.action.saveCategoryAction(categorydefault)
                .then((response) => {
                    this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                    //this.props.history.push('/categories');
                }).catch(error => {
                    console.log('*DEBUG*-Save CategoryEdit Error', error);
                    this.setState({ disabledButton: false, notification: constants.DATA_ERROR });
                });
        }
        else {
            this.setState({ validating: true, notification: constants.FORM_ERROR });
        }
    }

    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.categorydata)) {
            this.setState({ cancel: true });
        }
        else {
            const categoryId = this.props.categorydata.id;
            this.props.action.getCategoryAction(categoryId)
                .then(() => {
                    if (this.nextLoc) {
                        base64 = null;
                        this.props.history.push(this.nextLoc);
                    } else {
                        base64 = null;
                        this.props.history.push(constants.getSiteLevelRoute() + '/categories');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-CategoryEdit Error', error);
                    this.props.history.push(constants.getSiteLevelRoute() + '/categories');
                });
        }

    }
    updateNameValue(evt) {

        this.props.categorydata.name = evt.target.value;
        if (evt.target.value.length > 30) {
            this.categoryName_err = "Category Name can't be longer than 30 character";
        }
        else {
            this.categoryName_err = "";
        }
        this.setState({ validating: !this.state.validating });
    };
    updateParentValue(evt) {
        this.setState({
            selected: evt.option,
            options: this.parentfull
        });
        if (evt.option == "No match found") {
            evt.option = "";
        }
        //this.props.categorydata.parent = evt.option;
        this.props.categorydata.parent = (this.parentfull.find((option) => option.parentloc == evt.option)).parent;
        console.log("SELECTED: ", evt.option)
        const loc = (this.parentfull.find((option) => option.parentloc == evt.option)).location;
        const id = (this.parentfull.find((option) => option.parentloc == evt.option)).id;
        this.props.categorydata.parentId = id;
        //this.props.categorydata.location = loc;
        this.props.categorydata.location = loc;
        this.categoryParent_err = "";
        //if (evt.option.trim().length == 0) {
        //    this.categoryParent_err = "Select an option";
        //}
    };
    clearSearch() {
        this.setState({ search: "" });
        this.setState({ TileList: icondata });
    }
    selectedIcon(name, iconColor = "") {
        //Map is treated differently as its not part of the grommet-icons
        if (name == "MapCustom")
            return <MapCustom color={iconColor} />
        var IconName = Icons[name];
        //Check if that icon exists. If grommet-icons remove icon(s) that we are using, make sure it would not crash the page
        if (IconName)
            return <IconName color={iconColor} />
        if (Blank)
            return <Blank color={iconColor} />
        return <div />
    }
    //selectIcon(name) {
    //    if (name === "Achievement") {
    //        return (<Achievement />);
    //    }
    //    if (name === "Action") {
    //        return (<Action />);
    //    }
    //    if (name === "Actions") {
    //        return (<Actions />);
    //    }
    //    if (name === "Aed") {
    //        return (<Aed />);
    //    }
    //    if (name === "Aid") {
    //        return (<Aid />);
    //    }
    //    if (name === "Alarm") {
    //        return (<Alarm />);
    //    }
    //    if (name === "Alert") {
    //        return (<Alert />);
    //    }
    //    if (name === "Analytics") {
    //        return (<Analytics />);
    //    }
    //    if (name === "Announce") {
    //        return (<Announce />);
    //    }
    //    if (name === "Apps") {
    //        return (<Apps />);
    //    }
    //    if (name === "Archive") {
    //        return (<Archive />);
    //    }
    //    if (name === "Article") {
    //        return (<Article />);
    //    }
    //    if (name === "Atm") {
    //        return (<Atm />);
    //    }
    //    if (name === "Attachment") {
    //        return (<Attachment />);
    //    }
    //    if (name === "Attraction") {
    //        return (<Attraction />);
    //    }
    //    if (name === "Baby") {
    //        return (<Baby />);
    //    }
    //    if (name === "Bar") {
    //        return (<Bar />);
    //    }
    //    if (name === "BarChart") {
    //        return (<BarChart />);
    //    }
    //    if (name === "Basket") {
    //        return (<Basket />);
    //    }
    //    if (name === "Bike") {
    //        return (<Bike />);
    //    }
    //    if (name === "Book") {
    //        return (<Book />);
    //    }
    //    if (name === "Bookmark") {
    //        return (<Bookmark />);
    //    }
    //    if (name === "Briefcase") {
    //        return (<Briefcase />);
    //    }
    //    if (name === "Bus") {
    //        return (<Bus />);
    //    }
    //    if (name === "Bug") {
    //        return (<Bug />);
    //    }
    //    if (name === "Cafeteria") {
    //        return (<Cafeteria />);
    //    }
    //    if (name === "Calendar") {
    //        return (<Calendar />);
    //    }
    //    if (name === "Camera") {
    //        return (<Camera />);
    //    }
    //    if (name === "Car") {
    //        return (<Car />);
    //    }
    //    if (name === "Cart") {
    //        return (<Cart />);
    //    }
    //    if (name === "Catalog") {
    //        return (<Catalog />);
    //    }
    //    if (name === "Chat") {
    //        return (<Chat />);
    //    }
    //    if (name === "ChatOption") {
    //        return (<ChatOption />);
    //    }
    //    if (name === "CircleInformation") {
    //        return (<CircleInformation />);
    //    }
    //    if (name === "CircleQuestion") {
    //        return (<CircleQuestion />);
    //    }
    //    if (name === "Clear") {
    //        return (<Clear />);
    //    }
    //    if (name === "Clipboard") {
    //        return (<Clipboard />);
    //    }
    //    if (name === "Clock") {
    //        return (<Clock />);
    //    }
    //    if (name === "Cloud") {
    //        return (<Cloud />);
    //    }
    //    if (name === "Compliance") {
    //        return (<Compliance />);
    //    }
    //    if (name === "CoatCheck") {
    //        return (<CoatCheck />);
    //    }
    //    if (name === "Configure") {
    //        return (<Configure />);
    //    }
    //    if (name === "Connect") {
    //        return (<Connect />);
    //    }
    //    if (name === "Connectivity") {
    //        return (<Connectivity />);
    //    }
    //    if (name === "ContactInfo") {
    //        return (<ContactInfo />);
    //    }
    //    if (name === "Contact") {
    //        return (<Contact />);
    //    }
    //    if (name === "CreditCard") {
    //        return (<CreditCard />);
    //    }
    //    if (name === "Currency") {
    //        return (<Currency />);
    //    }
    //    if (name === "Dashboard") {
    //        return (<Dashboard />);
    //    }
    //    if (name === "Deliver") {
    //        return (<Deliver />);
    //    }
    //    if (name === "Deploy") {
    //        return (<Deploy />);
    //    }
    //    if (name === "Desktop") {
    //        return (<Desktop />);
    //    }
    //    if (name === "Directions") {
    //        return (<Directions />);
    //    }
    //    if (name === "DocumentCloud") {
    //        return (<DocumentCloud />);
    //    }
    //    if (name === "DocumentText") {
    //        return (<DocumentText />);
    //    }
    //    if (name === "Download") {
    //        return (<Download />);
    //    }
    //    if (name === "Edit") {
    //        return (<Edit />);
    //    }
    //    if (name === "Emergency") {
    //        return (<Emergency />);
    //    }
    //    if (name === "Elevator") {
    //        return (<Elevator />);
    //    }
    //    if (name === "Escalator") {
    //        return (<Escalator />);
    //    }
    //    if (name === "Favorite") {
    //        return (<Favorite />);
    //    }
    //    if (name === "Filter") {
    //        return (<Filter />);
    //    }
    //    if (name === "Gallery") {
    //        return (<Gallery />);
    //    }
    //    if (name === "Gamepad") {
    //        return (<Gamepad />);
    //    }
    //    if (name === "Gift") {
    //        return (<Gift />);
    //    }
    //    if (name === "Group") {
    //        return (<Group />);
    //    }
    //    if (name === "Grow") {
    //        return (<Grow />);
    //    }
    //    if (name === "Help") {
    //        return (<Help />);
    //    }
    //    if (name === "Home") {
    //        return (<Home />);
    //    }
    //    if (name === "Image") {
    //        return (<Image />);
    //    }
    //    if (name === "InProgress") {
    //        return (<InProgress />);
    //    }
    //    if (name === "Inbox") {
    //        return (<Inbox />);
    //    }
    //    if (name === "Info") {
    //        return (<Info />);
    //    }
    //    if (name === "Java") {
    //        return (<Java />);
    //    }
    //    if (name === "Launch") {
    //        return (<Launch />);
    //    }
    //    if (name === "Layer") {
    //        return (<Layer />);
    //    }
    //    if (name === "Like") {
    //        return (<Like />);
    //    }
    //    if (name === "Link") {
    //        return (<Link />);
    //    }
    //    if (name === "List") {
    //        return (<List />);
    //    }
    //    if (name === "Location") {
    //        return (<Location />);
    //    }
    //    if (name === "Login") {
    //        return (<Login />);
    //    }
    //    if (name === "Logout") {
    //        return (<Logout />);
    //    }
    //    if (name === "Lounge") {
    //        return (<Lounge />);
    //    }
    //    if (name === "Mail") {
    //        return (<Mail />);
    //    }
    //    if (name === "MailOption") {
    //        return (<MailOption />);
    //    }
    //    if (name === "Map") {
    //        return (<Map />);
    //    }
    //    if (name === "MapCustom") {
    //        return (<MapCustom />);
    //    }
    //    if (name === "MapLocation") {
    //        return (<MapLocation />);
    //    }
    //    if (name === "Microphone") {
    //        return (<Microphone />);
    //    }
    //    if (name === "Money") {
    //        return (<Money />);
    //    }
    //    if (name === "Monitor") {
    //        return (<Monitor />);
    //    }
    //    if (name === "More") {
    //        return (<More />);
    //    }
    //    if (name === "Multimedia") {
    //        return (<Multimedia />);
    //    }
    //    if (name === "Music") {
    //        return (<Music />);
    //    }
    //    if (name === "Navigate") {
    //        return (<Navigate />);
    //    }
    //    if (name === "Note") {
    //        return (<Note />);
    //    }
    //    if (name === "Notification") {
    //        return (<Notification />);
    //    }
    //    if (name === "Organization") {
    //        return (<Organization />);
    //    }
    //    if (name === "Overview") {
    //        return (<Overview />);
    //    }
    //    if (name === "PersonalComputer") {
    //        return (<PersonalComputer />);
    //    }
    //    if (name === "Phone") {
    //        return (<Phone />);
    //    }
    //    if (name === "PieChart") {
    //        return (<PieChart />);
    //    }
    //    if (name === "Pin") {
    //        return (<Pin />);
    //    }
    //    if (name === "Plan") {
    //        return (<Plan />);
    //    }
    //    if (name === "Print") {
    //        return (<Print />);
    //    }
    //    if (name === "Projects") {
    //        return (<Projects />);
    //    }
    //    if (name === "Refresh") {
    //        return (<Refresh />);
    //    }
    //    if (name === "Restaurant") {
    //        return (<Restaurant />);
    //    }
    //    if (name === "Restroom") {
    //        return (<Restroom />);
    //    }
    //    if (name === "RestroomMen") {
    //        return (<RestroomMen />);
    //    }
    //    if (name === "RestroomWomen") {
    //        return (<RestroomWomen />);
    //    }
    //    if (name === "Run") {
    //        return (<Run />);
    //    }
    //    if (name === "Schedule") {
    //        return (<Schedule />);
    //    }
    //    if (name === "Scorecard") {
    //        return (<Scorecard />);
    //    }
    //    if (name === "Secure") {
    //        return (<Secure />);
    //    }
    //    if (name === "Shield") {
    //        return (<Shield />);
    //    }
    //    if (name === "StatusWarning") {
    //        return (<StatusWarning />);
    //    }
    //    if (name === "Steps") {
    //        return (<Steps />);
    //    }
    //    if (name === "Sync") {
    //        return (<Sync />);
    //    }
    //    if (name === "Tag") {
    //        return (<Tag />);
    //    }
    //    if (name === "Task") {
    //        return (<Task />);
    //    }
    //    if (name === "Template") {
    //        return (<Template />);
    //    }
    //    if (name === "Train") {
    //        return (<Train />);
    //    }
    //    if (name === "Trash") {
    //        return (<Trash />);
    //    }
    //    if (name === "Trophy") {
    //        return (<Trophy />);
    //    }
    //    if (name === "TreeOption") {
    //        return (<TreeOption />);
    //    }
    //    if (name === "User") {
    //        return (<User />);
    //    }
    //    if (name === "Validate") {
    //        return (<Validate />);
    //    }
    //    if (name === "Video") {
    //        return (<Video />);
    //    }
    //    if (name === "Vend") {
    //        return (<Vend />);
    //    }
    //    if (name === "View") {
    //        return (<View />);
    //    }
    //    if (name === "Waypoint") {
    //        return (<Waypoint />);
    //    }
    //    if (name === "Wheelchair") {
    //        return (<Wheelchair />);
    //    }
    //    if (name === "Wifi") {
    //        return (<Wifi />);
    //    }
    //    if (name === "Workshop") {
    //        return (<Workshop />);
    //    }
    //    if (name === "Yoga") {
    //        return (<Yoga />);
    //    }
    //    else {
    //        return (<Blank />);
    //    }
    //}
    renderValue(name) {
        //console.log(name);
        var Ico = Icons[name];
        return (
            <Box gap="small" align="center" pad="small" border={this.props.categorydata.tileName == name ? { color: "brand", size: "medium" } : { color: "white", size: "medium" }} onClick={evt => this.onSelectIcons(name)}>
                {/*this.selectIcon(name)*/}
                <Ico color="#444444"/*{this.props.quickactiondata.tileName == name ? "#01a982" : "#444444"}*/ />
                <Text color="#444444"/*{this.props.quickactiondata.tileName == name ? "#01a982" : "#444444"}*/>
                    {name}
                </Text>
            </Box>);
    }
    onSelectIcons(name) {
        this.props.categorydata.tileName = name;
        this.setState({ openIconlist: false, letValidate: true });
    }
    // updateLocationValue(evt) {

    //    // this.props.categorydata.location = evt.target.value
    //     //console.log('data: ', this.props.categorydata);
    // };
    readFile(f) {
        if (f) {
            const reader = new FileReader();
            const self = this;
            reader.onload = function () {
                const data = reader.result;
                console.log('File', data.length);
                //const base64data = btoa(unescape(encodeURIComponent(data)));
                const base64data = btoa(data);
                //console.log('File Base64:', base64data);
                base64 = base64data;
                self.props.categorydata.imageContent = base64;
                base64 = null;
                //ToDo call API with file.name, and content
                //this.BinaryData = data; 
            };
            reader.readAsBinaryString(f);
        }
    }
    fileChangedHandler = (event) => {
        //console.log(event.target.files[0]);
        const file = event.target.files[0];
        console.log(file);
        if (file != undefined) {
            if (file.size > 1024 * 1024 * 2) {
                this.image_err = "File too large: " + Math.round((file.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.image_err = "";
                this.readFile(file);
                this.setState({ file: file, newimage: true, imagechanged: true, letValidate: true });
            }
        }
        else {

        }
    }
    removeIcon() {
        this.props.categorydata.imageUrl = null;
        this.props.categorydata.imageContent = null;
        base64 = null;
        this.setState({ removeIconOpen: false, newimage: false });
    }
    shortcut() {
        if (this.state.file == "") {
            return null;
        }
        else if (this.state.file != null) {
            var Data = [];
            Data.push(this.state.file);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/x-png,image/jpeg" }))
            return (
                <Box direction="row">
                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                    <ImageComp style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("real");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return null;
            //return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }
    updateDisplayMode() {
        const { categorydata } = this.props;
        categorydata.useImage = !categorydata.useImage;
        this.setState({ updated: true });
    }
    validationDisplayMode() {
        const { categorydata } = this.props;
        let error = true;
        if (categorydata.useImage && ((categorydata.imageUrl == "" || categorydata.imageUrl == null) && !this.state.newimage)) { //Display Mode == Image
            this.displaytype_err = "An Image has not been added for this category, please upload one now";
            error = true;
        }
        else if (!categorydata.useImage && (categorydata.tileName == "" || categorydata.tileName == null)) { //Display Mode == Icon
            this.displaytype_err = "An Icon has not been added for this category, please choose one from the available icons";
            error = true;
        }
        else {
            this.displaytype_err = "";
            error = false;
        }
        return error;
    }
    onClose() {
        this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push(constants.getSiteLevelRoute() + '/categories');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const [removed] = this.props.categorydata.content.splice(result.source.index, 1);
        this.props.categorydata.content.splice(result.destination.index, 0, removed);
        //Set Priority
        this.props.categorydata.content = this.props.categorydata.content.map((item, index) => { item.priority = index + 1; return item; });
        this.setState({ update: true });
    }

    changeManual = () => {
        this.props.categorydata.ordered = !this.props.categorydata.ordered;
        this.setState({ isManual: !this.state.isManual });
    }

    render() {
        const { options, open, notification, categorydataLoading, locationsdataLoading } = this.state;
        const categorydefault = this.props.categorydata;
        const { locationsdata } = this.props;
        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONTENT);

        // const location = (options.find((option) => option.parent == selected)).location;

        if (categorydataLoading || locationsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        if (this.state.letValidate || categorydefault.id != "0") {
            this.validationDisplayMode();
        }

        console.log("IMDT_CHK_", categorydefault)
        const heading = categorydefault && (categorydefault.id != "0") ? 'Edit' : 'Create';
        if (categorydefault) {
            categorydefault.location = categorydefault.location == "" ? categorydefault.parent : categorydefault.location;
        }
        const vparentloc = this.parentfull.find(opt => opt.parent == categorydefault.parent && opt.location == categorydefault.location);
        const parentloc = vparentloc ? vparentloc.parentloc : '';

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Category - {heading} </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label={categorydefault.synced ? "Back" : "Cancel"} onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        {!categorydefault.synced && <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />}
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <form>
                        <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                        <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                        <PopUpConfirm open={this.state.removeIconOpen} text={"Please confirm that you want to delete this image. The image associated with this category will no longer be visible in the app."}
                            onClose={() => this.setState({ removeIconOpen: false })} onDelete={() => this.removeIcon()} />
                        {/* <form> */}
                        <Box pad={{ vertical: "small" }}> <Text>All fields marked with an * are mandatory.</Text>
                            <Text size="large">Description</Text>
                        </Box>
                        {/* <form> */}
                        <StyledBox style1 pad={{ "horizontal": "small" }} margin={{ "bottom": "medium" }} /*comment margin out cc*/>
                            <FormField label="Category Name *" error={this.categoryName_err}>
                                <TextInput defaultValue={categorydefault.name} placeholder="Category Name" onChange={evt => this.updateNameValue(evt)} disabled={categorydefault.synced} />
                            </FormField>
                            <FormField label="App Location" error={this.categoryParent_err}>
                                <Select
                                    placeholder="App Location"
                                    //value={categorydefault.parent}
                                    value={parentloc}
                                    //options={options.map((option) => option.parent).sort((p1, p2) => {if (p1.toLowerCase() < p2.toLowerCase()) return -1; return 1;})}
                                    options={[...new Set(
                                        options
                                        .filter(o => !(o.parent == categorydefault.name) && !(o.location == categorydefault.name)) //Filter out parent & location
                                        .map((option) => option.parentloc))]}
                                    onChange={evt => this.updateParentValue(evt)}
                                    onClose={() => this.setState({ options: this.parentfull })}
                                    onSearch={(searchText) => {
                                        const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                        const filtered = this.parentfull.filter(o => o.parentloc.match(regexp));
                                        if (filtered.length > 0) {
                                            this.setState({ options: filtered })
                                            console.log(filtered)
                                        }
                                        else {
                                            this.setState({ options: [{ parent: "No match found", location: "", parentloc: "No match found" }] })
                                        }
                                    }}
                                    replace={false}
                                    disabled={categorydefault.synced}
                                />
                            </FormField>
                            {/*<FormField label="App Location" help="Automatically populated">
                                <TextInput value={categorydefault.location} readOnly={true} />
                            </FormField>*/}
                            {categorydefault.synced ?
                                <Box direction="row">
                                    <FormField label="Icon" error={this.tile_err}>
                                        <Box justify="between" height="50px" direction="row" align="center" >
                                            <Box margin="small" align="center" justify="center" direction="row" gap="small" flex="shrink">
                                                {this.selectedIcon(categorydefault.tileName, "#ADADAD")}
                                                <Text color="#ADADAD">
                                                    {categorydefault.tileName}
                                                </Text>
                                            </Box>
                                        </Box>
                                    </FormField>
                                </Box>
                                :
                                <Box>
                                    <FormField label="Icon" error={this.tile_err}>
                                        <Box justify="between" height="50px" direction="row" align="center" onClick={() => this.setState({ openIconlist: !this.state.openIconlist })}>
                                            {categorydefault.tileName != "" &&
                                                <Box margin="small" align="center" justify="center" direction="row" gap="small">
                                                    {this.selectedIcon(categorydefault.tileName)}
                                                    <Text>
                                                        {categorydefault.tileName}
                                                    </Text>
                                                </Box>}
                                            {categorydefault.tileName == "" &&
                                                <Box margin="small" align="center" justify="center" direction="row" gap="small">
                                                    <Text color="#999999">Select an Icon</Text>
                                                </Box>}

                                            <Box pad={{ "right": "small" }} align="center" justify="center">
                                                {!this.state.openIconlist && <FormDown />}
                                                {this.state.openIconlist && <FormUp />}
                                            </Box>
                                        </Box>

                                        {this.state.openIconlist &&
                                            <Box>
                                                <Box margin="small" direction="row" gap="small">
                                                    <TextInput placeholder="Search" value={this.state.search} onChange={evt => {
                                                        this.setState({ search: evt.target.value })
                                                        const regexp = new RegExp(evt.target.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                        const filtered = icondata.filter(o => o.match(regexp));
                                                        this.setState({ TileList: filtered });
                                                    }}
                                                    />
                                                    <Button icon={<FormClose />} plain onClick={() => this.clearSearch()} />
                                                </Box>

                                                <Box style={{ minHeight: '80vh' }}>
                                                    <Grommet theme={customTheme1}>
                                                        <Grid columns="small" rows="xsmall">
                                                            <InfiniteScroll step={this.state.TileList.length} items={this.state.TileList} >
                                                                {icon => this.renderValue(icon)}
                                                            </InfiniteScroll>
                                                        </Grid>
                                                    </Grommet>
                                                </Box>
                                            </Box>}

                                    </FormField>
                                    <Box direction="row">
                                        <Box flex="shrink">
                                            <Grommet theme={customTheme2}>
                                                <FormField label="Image" error={this.image_err} label={
                                                    <Box direction="row" gap="xsmall">
                                                        <Box>Image</Box>
                                                        <Box justify="center" data-for="img_info" data-tip="Please select a brand approved image to display as your category image.<br />
                                                                        Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.">
                                                            <CircleInformation size="small" />
                                                            <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline />
                                                        </Box>
                                                    </Box>}>
                                                    <Grommet theme={customTheme3}>
                                                        <Box direction="row" gap="small">
                                                            <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }} width={{ "min": "101px" }} fill={false}>
                                                                <FormField label="">
                                                                    <Box direction="row" justify="center">
                                                                        {this.state.newimage ?
                                                                            <Box alignSelf="center">
                                                                                {this.shortcut()}
                                                                            </Box>
                                                                            :
                                                                            (categorydefault.imageUrl == "" || categorydefault.imageUrl == null) ?
                                                                                <Box height="75px" justify="center">
                                                                                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                                    <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />} disabled={!canedit1}
                                                                                        onClick={e => {
                                                                                            const c = document.getElementById("real");
                                                                                            c.click();
                                                                                        }} />
                                                                                </Box>
                                                                                :
                                                                                <Box justify="center">
                                                                                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                                                    <ImageComp height="75" src={`${categorydefault.imageUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                                                        onClick={e => {
                                                                                            const c = document.getElementById("real");
                                                                                            c.click();
                                                                                        }} />
                                                                                </Box>
                                                                        }
                                                                    </Box>
                                                                </FormField>
                                                            </StyledBox>
                                                            <Box justify="center">
                                                                <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={!canedit1 || ((categorydefault.imageUrl == "" || categorydefault.imageUrl == null) && !this.state.newimage)}>
                                                                    <Trash />
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Grommet>
                                                </FormField>
                                            </Grommet>
                                        </Box>
                                    </Box>
                                    <Grommet theme={customTheme2}>
                                        <Box direction="row">
                                            <Box flex="shrink">
                                                <FormField label={
                                                    <Box direction="row" gap="xsmall">
                                                        <Box>Display Mode</Box>
                                                        <Box justify="center" data-for="auth_info" data-tip="Choose a display mode to show either an icon or image with this category in the client.">
                                                            <CircleInformation size="small" />
                                                            <ReactTooltip id="auth_info" place="right" type="dark" effect="solid" arrowColor="transparent" />
                                                        </Box>
                                                    </Box>} error={this.displaytype_err}>
                                                    <Box direction="row" gap="medium" pad={{ "bottom": "xsmall" }}>
                                                        <Box gap="xsmall" align="center">
                                                            Icon
                                                            <RadioButton checked={!categorydefault.useImage} disabled={!canedit1} onChange={() => this.updateDisplayMode()} />
                                                        </Box>
                                                        <Box gap="xsmall" align="center">
                                                            Image
                                                            <RadioButton checked={categorydefault.useImage} disabled={!canedit1} onChange={() => this.updateDisplayMode()} />
                                                        </Box>
                                                    </Box>
                                                </FormField>
                                            </Box>
                                        </Box>
                                    </Grommet>
                                </Box>}
                        </StyledBox>
                        {!categorydefault.synced && <Box id="CategoryContentBox" pad="small" gap="small">
                            <Text size="medium">Category Content</Text>
                            <Box direction="row" gap="50px">
                                {categorydefault.content && categorydefault.content.length > 0 ? <Box data-for="content_info" data-tip >
                                    <CheckBox toggle label="Show Content" checked={this.state.isShowContent} onChange={evt => this.setState({ isShowContent: !this.state.isShowContent }, () => {
                                        //Auto scroll down
                                        let main = document.getElementById("main");
                                        let box = document.getElementById("CategoryContentBox");
                                        let topy = box.offsetTop - main.offsetTop/*- window.offset.top*/;
                                        main.scrollTo({
                                            top: topy,
                                            left: box.offsetLeft,
                                            behavior: 'smooth'
                                        });
                                    })} />
                                </Box>
                                    :
                                <Text>This category has no items currently assigned to it</Text>
                                } 
                                {this.state.isShowContent &&
                                    <Box data-for="manual_info" data-tip >
                                        <RadioButton label="Order content manually" /*disabled={categorydefault.content.length < 2}*/ checked={categorydefault.ordered /*&& categorydefault.content.length > 1*/} onChange={this.changeManual} />
                                    </Box>
                                }
                                {this.state.isShowContent &&
                                    <Box data-for="grouping_info" data-tip >
                                        <RadioButton label="Apply content grouping" checked={!categorydefault.ordered /*|| categorydefault.content.length < 2*/} onChange={this.changeManual} />
                                    </Box>
                                }
                            </Box>
                            <ReactTooltip id="content_info" place="bottom" type="dark" effect="solid" arrowColor="transparent">
                                <div>
                                    Any item that has been assinged this category is shown here. This could be another category, placemark, content page etc.
                                    <br />
                                    You can change the order of the list display by showing content and using the drag and drop feature.
                                </div>
                            </ReactTooltip>
                            {this.state.isShowContent && <ReactTooltip id="manual_info" place="right" type="dark" effect="solid" arrowColor="transparent">
                                To change the order of your content in the client, drag and drop the items below. The order applied here will be reflected in the mobile client for this category.
                            </ReactTooltip>}
                            <ReactTooltip id="grouping_info" place="bottom" type="dark" effect="solid" arrowColor="transparent">
                                <div>
                                    When active any published placemarks contained on multiple floors on the map will be displayed by their floor grouping. 
                                    <br/>
                                    Mixed use list will display content in their own group.
                                    <br />
                                    Content will appear at the top of the list. You will be unable to use the drag and drop feature using this setting.
                                </div>
                            </ReactTooltip>

                            {this.state.isShowContent &&
                                <Box pad={{ "bottom": "small" }} >
                                    <DragDropContext onDragEnd={this.onDragEnd} >
                                        <Droppable droppableId="droppable">
                                            {(provided) =>
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef} >
                                                {categorydefault.content.map((item, index) =>
                                                    <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!categorydefault.ordered || categorydefault.content.length < 2}>
                                                            {(provided, snapshot) =>
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <StyledBox style1={(snapshot.isDragging) ? false : true} style1gray={(snapshot.isDragging) ? true : false}
                                                                        pad={{ "horizontal": "small", "top": "small" }} margin={{ "bottom": "small" }}>
                                                                        <Grommet theme={noBorderTheme} >
                                                                            <FormField key={index} >
                                                                                <Box pad="small" direction="row" justify="between">
                                                                                    <Box>
                                                                                        <Text>{item.name}</Text>
                                                                                    </Box>
                                                                                    <Box alignSelf="center" align="end">
                                                                                        <Text size="xsmall">{item.type}</Text>
                                                                                    </Box>
                                                                                </Box>
                                                                            </FormField>
                                                                        </Grommet>
                                                                    </StyledBox>
                                                                </div>
                                                            }
                                                        </Draggable>
                                                    )}
                                                </div>
                                            }
                                        </Droppable>
                                    </DragDropContext>
                                </Box>
                            }

                        </Box>
                        }
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>
            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const categoryId = ownProps.match.params.categoryId || ''; //from the path '/category/:categoryId'
    if (state.selectedCategoryReducer.categorydata && categoryId == state.selectedCategoryReducer.categorydata.id) {
        start = { ...state.selectedCategoryReducer.categorydata };
        return {
            categorydata: state.selectedCategoryReducer.categorydata,
            locationsdata: state.locationsReducer.locationsdata
        };
    } else {
        return {
            locationsdata: state.locationsReducer.locationsdata
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...CategoryAction, ...LocationAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CategoryEditContainer));