import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const metadatasReducer = (state = initialState.metadatasReducer, action) => {
    switch(action.type) {
        case ActionType.GET_METADATAS_RESPONSE: {
            return {
                ...state, 
                metadatas: _.assign(action.metadatas)
            };
        }

        default: { return state; }
    }
};


export default metadatasReducer;