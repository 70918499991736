import constants from '../constants';

//const baseUrl = "https://api.applicationinsights.io/v1/apps/70d5549c-61c9-4481-a017-2a76bf9fe2a1/query?query="; //itg MTF HPE-MTF
//const baseUrl = "https://api.applicationinsights.io/v1/apps/cc1a64bd-eac0-4303-8aa2-ed3f13262aad/query?query="; //prod
const baseUrl = constants.release().Insightsurl;

const options =
{
    method: "get",
    headers:
    {
        //"x-api-key": "j4uepuugt6mrb7wg3ugvg6gzg8ebpd6xlrh2hnry"  //itg MTF HPE-MTF
        //"x-api-key": "qjfkkoqhe21pknp4wv4do2buuka8qbeyfbcznmq0" //prod
        "x-api-key": constants.release().Insightskey
    }
}

export default class AppInsightsApi {
    static trackKingExtension(orgcode) {
        if (orgcode == "TrackKing") {
            return "| where customDimensions.AppNamespace=='com.mytrackking.app'";
        }
        return "";
    }
    static async getLocations(timespan, orgcode) {
        const locationsTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | extend value = extractjson("$.Project", tostring(customDimensions.Properties))\
        | distinct value`;
        return fetch(`${baseUrl}${encodeURIComponent(locationsTemplate)}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getVersions(timespan, orgcode) {
        const versionsTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | distinct application_Version`;
        return fetch(`${baseUrl}${encodeURIComponent(versionsTemplate)}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getWorldUsers(timespan, version, os, orgcode) {
        version = version !== "All" ? `| where application_Version == "${version}"` : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const worldUsersTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize value = dcount(user_Id) by client_CountryOrRegion`;
        return fetch(`${baseUrl}${encodeURIComponent(worldUsersTemplate)}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getUsersCount(timespan, version, os, orgcode) {
        version = version !== "All" ? `| where application_Version == "${version}"` : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const usersCountTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize value = dcount(user_Id) by bin(timestamp, 1d)\
        | order by timestamp asc`;
        return fetch(`${baseUrl}${encodeURIComponent(usersCountTemplate)}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getAvarageSessionsCount(timespan, version, os, orgcode) {
        version = version !== "All" ? `| where application_Version == "${version}"` : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        const avgSessionsTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize value = round(todouble(dcount(session_Id)) / todouble(dcount(user_Id)), 3) by bin(timestamp, 1d)\
        | order by timestamp asc`;
        return fetch(`${baseUrl}${encodeURIComponent(avgSessionsTemplate)}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getEventsTable(timespan, version, os, location, orgcode) {
        version = version !== "All" ? `| where application_Version == "${version}"` : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        location = location !== "All" ? location : "";
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Project\\":\\"${location}"\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        ${version}\
        ${os}\
        | summarize value = count() by name`;
        return fetch(`${baseUrl}${encodeURIComponent(eventsTableTemplate)}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getEventsTableExtended(timespan, version, os, location, orgcode) {
        version = version !== "All" ? `| where application_Version == "${version}"` : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        location = location !== "All" ? location : "";
        const eventsTableTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Project\\":\\"${location}"\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | where name!='SiteSelection'
        ${version}\
        ${os}\
        | extend event=extract('"Event":"([^"]+)"', 1, tostring(customDimensions.Properties))
        | summarize value = count() by event,name
        | sort by value`;
        return fetch(`${baseUrl}${encodeURIComponent(eventsTableTemplate)}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getIndividualEvent(timespan, version, os, eventName, eventType, location, orgcode) {
        version = version !== "All" ? `| where application_Version == "${version}"` : "";
        os = os !== "All" ? `| where client_OS startswith "${os}"` : "";
        location = location !== "All" ? location : "";
        const individualEventTemplate = `customEvents\
        | where timestamp > ago(${timespan})\
        ${this.trackKingExtension(orgcode)}
        | extend event=extract('"Event":"([^"]+)"', 1, tostring(customDimensions.Properties))
        | where event == "${eventName}"\
        | where name == "${eventType}"\
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""\
        | where customDimensions.Properties contains "\\"Project\\":\\"${location}"\
        ${version}\
        ${os}\
        | summarize value = count() by  location = extractjson("$.Project", tostring(customDimensions.Properties)),  bin(timestamp, 1d)`;
        return fetch(`${baseUrl}${encodeURIComponent(individualEventTemplate)}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }

    static async getAllData(orgcode) {
        const query = `customEvents\
        ${this.trackKingExtension(orgcode)}
        | where customDimensions.Properties contains "\\"Organization\\":\\"${orgcode}\\""`;
        return fetch(`${baseUrl}${encodeURIComponent(query)}`, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }
}
