import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as OrganisationAction from '../action/OrganisationAction';
import * as LevelAction from '../action/LevelAction';
import * as BuildingAction from '../action/BuildingAction';
import * as SiteAction from '../action/SiteAction';
import * as AssetAction from '../action/AssetAction';
import * as CollectionAction from '../action/CollectionAction';
import * as DepartmentAction from '../action/DepartmentAction';
import * as TrackerAction from '../action/TrackerAction';
import * as AssociationAction from '../action/AssociationAction';
import * as PlacemarkAction from '../action/PlacemarkAction';
import * as ManufacturerAction from '../action/ManufacturerAction';
import * as ModelAction from '../action/ModelAction';
import * as TypeAction from '../action/TypeAction';
import validator from 'validator';
import constants from '../constants';
//eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Youtube, Close, FormClose, StatusGoodSmall, Filter, Expand, Contract, Technology } from 'grommet-icons';
//eslint-disable-next-line
import { Grid, Box, Heading, List, Paragraph, Split, Button, Form, FormField, TextInput, Select, CheckBox, Text, Calendar, Image, TextArea, Anchor, Header, Layer, Grommet } from 'grommet';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import * as MeridianSDK from "@meridian/web-sdk";
import './Meridian.css';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import RB from './common/RBAC';
import styled, { css } from 'styled-components';
import ReactTooltip from "react-tooltip";
import { Context } from './Context';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';

const FilterButton = styled(Button)`
        height: 48px;
        border-radius: 5px 5px 5px 5px;`;


export class MeridianMap extends React.Component {

    static contextType = Context;
    constructor() {
        super();
        this.mix = [];
        this.target = React.createRef();
        this.HeaderInfo = "";
        this.state = {
            validating: false, selimage: 0, value: "", isSaved: false, cancel: false, disabledButton: false, isSaveSuccessful: false,
            selectedBuilding: "",
            selectedFloor: "",
            floors: [],
            showPlacemarks: true,
            showTags: true,
            showControlTags: RB.canEdit(constants.PERMS.LOC_ADMINISTRATION),
            selectedFloorId: "",
            selectedAsset: "",
            assetid: "",
            assetList: [],
            control: "All",

            mapVisible: false,
            levelsdataLoading: true,
            buildingsdataLoading: true,
            sitedataLoading: true,
            assetsdataLoading: true,
            placemarksdataLoading: true,
            selectedCollections: [],
            showUnlinkedTags: RB.canEdit(constants.PERMS.LOC_ADMINISTRATION),
            assetsOnMap: [],
            selectedDepartment: "",
            isFullScreen: false,

            isDestroyed: false,
            selectedStatus: "",
            unlinks: [],

            placemarkid: "",
            apiErrorCounter: 0,
            apiErrorList: [],

            selectedManufacturer: "",
            selectedModel: "",
            selectedDeviceType: ""
        };
    }

    emptyBuildingArr = [];
    headerHeight = 0;
    //Validating fields error handling
    summary_err = '';
    text_err = '';
    url_err = '';
    componentWillReceiveProps({ assetsdata, placemarksdata, levelsdata, sitedata }) {
        //if (assetsdata && assetsdata.length > 0) {
        //    assetsdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
        //}
        //console.log("nct", assetsdata);
        //this.setState({ assetList: assetsdata });

        if (sitedata && !this.state.sitedataLoading && !this.isRendered) {
            if (sitedata.mapID) {
                this.isRendered = true;
                this.setState({ selectedFloorId: sitedata.mapID }, () => {
                    if (this.state.selectedFloorId) {
                        setTimeout(() => this.renderMap(this.state.selectedFloorId), 3000);
                    }
                });
            } else if (levelsdata && !this.state.levelsdataLoading) {
                this.isRendered = true;
                let level = levelsdata.find(l => l.meridianId && l.published == true);
                if (level) {
                    this.setState({ selectedFloorId: level.meridianId }, () => {
                        if (this.state.selectedFloorId) {
                            setTimeout(() => this.renderMap(this.state.selectedFloorId), 3000);
                        }
                    });
                } else {
                    //No available floor
                    this.HeaderInfo = "There are no floors published. Please contact your site admin.";
                }
            }
        }

        if (this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
            (assetsdata && placemarksdata) && ((assetsdata.length > 0 && !(assetsdata[0].hasOwnProperty("count"))) && (placemarksdata.length > 0 && !(placemarksdata[0].hasOwnProperty("count"))) && (levelsdata.length > 0 && !(levelsdata[0].hasOwnProperty("count"))))) {
            console.log("cwrp", assetsdata, placemarksdata);
            let filteredPlacemarks = placemarksdata.filter(m => {
                let level = levelsdata.find(l => l.mergedId == m.levelId);
                if (level && level.meridianId && level.published)
                    return true;
                return false;
            });
            const mix = assetsdata.concat(filteredPlacemarks).sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            this.mix = mix;
            this.setState({ assetList: mix });
        }
        else if (this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
            assetsdata && assetsdata.length > 0 && !(assetsdata[0].hasOwnProperty("count"))) {
            const mix = assetsdata.sort(constants.byPropertyCalled("name"));
            this.mix = mix;
            this.setState({ assetList: mix });
        }
        else if (placemarksdata && placemarksdata.length > 0 && !(placemarksdata[0].hasOwnProperty("count")) && (levelsdata.length > 0 && !(levelsdata[0].hasOwnProperty("count")))) {
            const mix = placemarksdata.filter(p => p.levelId && p.levelId != "" && p.levelId != null).sort(constants.byPropertyCalled("name"));
            this.mix = mix.filter(m => {
                let level = levelsdata.find(l => l.mergedId == m.levelId);
                if (level && level.meridianId && level.published)
                    return true;
                return false;
            });
            this.setState({ assetList: this.mix });
        }
        //paramID
        //if (this.mix.length > 0 && this.props.match.params.id) {
        //    const element = this.mix.find(e => e.id == this.props.match.params.id)
        //    if (element) {
        //        if (element.hasOwnProperty("isControlTag")) {
        //            //Tag or Asset
        //            const assetid = element.id;
        //            const mapid = element.map.id;
        //            this.setState({ selectedFloorId: mapid, assetid: assetid, placemarkid: "", selectedAsset: { name: element.name } }, () => this.renderMap(mapid));
        //        }
        //        else {
        //            //Placemark
        //            const placemarkid = element.meridianId;
        //            const floor = this.props.levelsdata.find(l => l.mergedId == element.levelId);
        //            const mapid = floor.meridianId;
        //            this.setState({ selectedFloorId: mapid, placemarkid: placemarkid, assetid: "", selectedAsset: { name: element.name } }, () => this.renderMap(mapid));
        //        }
        //    }
        //}

        let withNoBuilding = this.props.levelsdata.filter(l => (l.mergedBuildingId == null || l.mergedBuildingName == "") && l.published);
        if (withNoBuilding.length > 0) {
            var obj = Object.assign({}, this.props.buildingsdata[0]);
            for (var key of Object.keys(obj)) {
                obj[key] = null;
            }
            obj.meridianName = "Unassigned";
            this.emptyBuildingArr = [obj];

            //const floors = this.props.levelsdata.filter(f => f.mergedBuildingId == null);
            //this.setState({ floors: floors });
        }
    }
    componentDidMount() {
        //this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        this.props.action.getLevelsAction()
            .then(response => this.setState({ levelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Levels List Error', error);
            });
        this.props.action.getBuildingsAction()
            .then(response => this.setState({ buildingsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Buildings List Error', error);
            });
        this.props.action.getSiteAction(sessionStorage.getItem("locationId"))
            .then(response => this.setState({ sitedataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Site Edit Error', error);
            });
        let isControlTagsIncluded = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) ? "" : false;
        this.props.action.getMappedAssetsAction(isControlTagsIncluded, RB.canEdit(constants.PERMS.LOC_ADMINISTRATION))
            .then(response => this.setState({ assetsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- MappedAssets List Error', error);
            });
        this.props.action.getCollectionsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Collection List Error', error);
            });
        this.props.action.getAssociationsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Assocation List Error', error);
            });
        this.props.action.getDepartmentsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Department List Error', error);
            });
        this.props.action.getTrackersAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Tracker List Error', error);
            });
        this.props.action.getPlacemarksAction()
            .then(response => this.setState({ placemarksdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Placemarks List Error', error);
            });
        this.props.action.getManufacturersAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Manufacturer List Error', error);
            });
        this.props.action.getModelsAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Model List Error', error);
            });
        this.props.action.getTypesAction()
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- DeviceType List Error', error);
            });
    }

    componentWillUnmount() {
        //this.historyUnblock();
        if (this.map) {
            this.map.destroy();
        }
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    renderMap(floorid) {
        let id = this.state.isFullScreen ? "meridian-map2" : "meridian-map1";
        if (this.map && !this.map.isDestroyed) {
            this.map.update({ floorID: floorid });
        }
        else {
            console.log("propi", floorid);
            const macid = ["CC78ABC6CB63", "CC78AB9D9845"];
            MeridianSDK.init({
                api: new MeridianSDK.API({
                    environment: this.props.sitedata.eUorNon ? "eu":"production",
                    //token: "6c09d265b1413fc768f4e08447ea95c8923a9c78",
                    token: this.props.sitedata.appToken
                })
            });
            var element = document.getElementById(id);
            if (element) {
                element.innerHTML = "";
            //console.log("element", element);
            //console.log("mapelement", this.props.sitedata.appID, floorid);
            this.map = MeridianSDK.createMap(
                //document.getElementById("meridian-map"),
                element,
                {
                    //locationID: "5198682008846336",
                    //loorID: "5755685136498688"
                    locationID: this.props.sitedata.appID,
                    floorID: floorid,
                    shouldMapPanZoom: function restrictedPanZoom(event) {
                        if (event.type === "wheel" && !event.altKey) {
                            return false;
                        } else if (event.type === "touchstart") {
                            return event.touches.length >= 2;
                        }
                        return true;
                    },
                    onFloorChange: floor => {
                        this.setState({ selectedFloorId: floor.id });
                    },
                    showFloorsControl: true, //this.state.showMapControls,
                    showTagsControl: false, //this.state.showMapControls,
                    showSearchControl: false,


                    //height: "650px", //650px for Keith's DEMO                       
                    height: this.state.isFullScreen ? "calc(100vh - 100px - 66px)" : "calc(100vh - 300px - 66px)",
                    //height: this.state.isFullScreen ? (window.innerHeight - this.headerHeight - 100) + "px" : "450px",
                    placemarks: {
                        filter: p => {
                            if (this.state.showPlacemarks) {
                                if (this.state.assetid)
                                    return false;
                                if (this.state.placemarkid)
                                    return p.id == this.state.placemarkid;
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                    },

                    tags: {
                        filter: t => {
                            if (!this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled) {
                                //if its not enabled then we dont show anything.
                                return false;
                            }
                            //console.log("Tags", t);
                            //t.image_url = "https://hpepngdemostor.blob.core.windows.net/images/00FFFF.png";
                            //return this.state.tags.includes(t.mac);

                            //if assetsOnMap.length == 0 && selectedDepartment then it means that we dont have asset(s) with that department, therefore array should be empty.
                            //let assets = this.state.assetsOnMap.length > 0 ? this.state.assetsOnMap : this.state.selectedDepartment ? [] : this.props.assetsdata;
                            let assets = [];
                            let unl = this.state.unlinks.length > 0 ? this.state.unlinks : this.props.assetsdata.map(a => a.id);

                            if (this.state.selectedDepartment && this.state.selectedStatus)
                                assets = this.state.departments.filter(d => this.state.statuses.includes(d));
                            else if (this.state.selectedStatus && !this.showUnlinkedTags)
                                assets = this.state.statuses.filter(d => unl.includes(d));
                            else if (this.state.selectedStatus && this.showUnlinkedTags)
                                assets = this.state.statuses.filter(d => unl.includes(d));
                            else if (this.state.selectedDepartment)
                                assets = this.state.departments;
                            else {
                                if (!this.state.showUnlinkedTags && this.state.unlinks.length == 0 && RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) {
                                    //Dont have links && only show links
                                    assets = [];
                                }
                                else {
                                    assets = unl;
                                }
                            }


                            assets = this.filterDataBySelections();
                            if (!assets/*.map(a => a.id)*/.includes(t.mac)) return false;
                            if (!t.is_control_tag) {
                                if (this.state.showTags) {
                                    if (this.state.placemarkid)
                                        return false;
                                    if (this.state.assetid) {
                                        let labels = t.tags.map(l => l.name);
                                        let value = false;
                                        if (this.state.selectedCollections.length == 0) {
                                            value = true;
                                        }
                                        else {
                                            this.state.selectedCollections.forEach(e => {
                                                if (labels.includes(e.labelName)) {
                                                    value = true;
                                                    return true;
                                                }
                                            });
                                        }
                                        return t.mac == this.state.assetid && value;
                                    } else {
                                        if (this.state.selectedCollections.length == 0) {
                                            return true;
                                        }
                                        else {
                                            let labels = t.tags.map(l => l.name);
                                            let value = false;
                                            this.state.selectedCollections.forEach(e => {
                                                if (labels.includes(e.labelName)) {
                                                    value = true;
                                                    return true;
                                                }
                                            });
                                            return value;
                                        }
                                    }
                                } else {
                                    return false;
                                }
                            }
                            else {
                                if (this.state.showControlTags) {
                                    if (this.state.placemarkid)
                                        return false;
                                    if (this.state.assetid) {
                                        return t.mac == this.state.assetid;
                                    } else {
                                        if (this.state.selectedCollections.length == 0) {
                                            return true;
                                        }
                                        else {
                                            let labels = t.tags.map(l => l.name);
                                            let value = false;
                                            this.state.selectedCollections.forEach(e => {
                                                if (labels.includes(e.name)) {
                                                    value = true;
                                                    return true;
                                                }
                                            });
                                            return value;
                                        }
                                    }
                                } else {
                                    return false;
                                }

                            }
                            //if (this.state.selectedCollections.length == 0) {
                            //    return true;
                            //}
                            //else {
                            //    let labels = t.tags.map(l => l.name);
                            //    let value = false;
                            //    this.state.selectedCollections.forEach(e => {
                            //        if (labels.includes(e.name)) {
                            //            value = true;
                            //            return true;
                            //        }
                            //    });
                            //    return value;
                            //}

                            //if (this.state.assetid && this.state.showTags && !t.is_control_tag) {
                            //    return t.mac == this.state.assetid;
                            //}
                            //else if (this.state.assetid && this.state.showControlTags && t.is_control_tag) {
                            //    return t.mac == this.state.assetid;
                            //}
                            //else if (this.state.showTags && /*this.state.control == "All"*/ this.state.showControlTags) {
                            //    return true;
                            //}
                            //else if (/*this.state.showTags && this.state.control == "Control Tags"*/this.state.showControlTags) {
                            //    return t.is_control_tag;
                            //}
                            //else if (/*this.state.showTags && this.state.control == "Control Tags"*/this.state.showTags) {
                            //    return !t.is_control_tag;
                            //}
                            //return this.state.showTags;
                        },
                        showControlTags: this.state.showControlTags/*this.state.control !== "Tags"*/
                    }
                }
            );
            element.style.visibility = "visible";
            //$('.meridian-tag-label-Chair').css('background', 'yellow');
            //const collectionsdata = [{ id: "ffa500C", name: "Chair", hexColor: "#ffa500" }, { id: "ff0000CT", name: "CT-Human", hexColor: "#ff0000" }, /*{ id: "ff0000C-T", name: "Control Tag", hexColor: "#0000ff" }*/];
            if (this.props.collectionsdata && this.props.collectionsdata[0] && !this.props.collectionsdata[0].hasOwnProperty("count")) {
                let rules = [];
                this.props.collectionsdata.forEach(item => {
                    //this.addCSSclass([
                    //    {
                    //        selector: `.meridian-tag-label-${item.name.replace(" ", "-")}`,
                    //        rule: `background-color:${item.hexColor};background-image:none`
                    //    }
                    //]);
                    //this.toggleClass(element, `meridian-tag-label-${item.name.replace(" ", "-")}`, false);
                    rules.push(
                        {
                            selector: `.meridian-tag-label-${item.labelName.replace(" ", "-")}`,
                            rule: `background-color:${item.hexColor};background-image:none`
                        }
                    );
                });
                this.addCSSclass2(rules);
            }
            this.setState({ mapVisible: true });
            }
        }
    }
    getOption(token, method = 'get') {
        //let token = "6c09d265b1413fc768f4e08447ea95c8923a9c78"
        let bearer = `Token ${token}`;
        let headers = new Headers();
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        var option = {
            method: method,
            headers: headers
        };
        return option;
    }
    toggleClass(elem, theClass, newState) {
        var matchRegExp = new RegExp('(?:^|\\s)' + theClass + '(?!\\S)', 'g');
        var add = (arguments.length > 2 ? newState : (elem.className.match(matchRegExp) === null));

        elem.className = elem.className.replace(matchRegExp, ''); // clear all
        if (add) elem.className += ' ' + theClass;
    }

    addCSSclass(rules) {
        var style = document.createElement("style");
        style.setAttribute(`${rules[0].selector}`, `${rules[0].selector}`);
        style.appendChild(document.createTextNode("")); // WebKit hack :(
        document.head.appendChild(style);
        var sheet = style.sheet;

        rules.forEach((rule, index) => {
            try {
                if ("insertRule" in sheet) {
                    sheet.insertRule(rule.selector + "{" + rule.rule + "}", index);
                } else if ("addRule" in sheet) {
                    sheet.addRule(rule.selector, rule.rule, index);
                }
            } catch (e) {
                // firefox can break here          
            }

        });
    }
    addCSSclass2(rules) {
        var style = document.createElement("style");
        style.setAttribute('id', "coloured");
        let innerHTML = '';
        rules.forEach((rule, index) => {
            innerHTML += `${rule.selector} {
                ${rule.rule}
            }`;
        });
        style.innerHTML = innerHTML;
        document.head.appendChild(style);
    }
    componentWillUnmount() {
        let cs = document.querySelectorAll("[id='coloured']");
        cs.forEach(item => item.remove());
    }
    renderValue() {
        return this.state.selectedCollections.length == 1 ?
            <Box pad="small" direction="row" gap="small">
                {/*<StatusGoodSmall color={this.state.selectedCollections[0].hexColor} />*/}
                <Box round height="23px" background={this.state.selectedCollections[0].hexColor} width="23px" border style={{ /*"border-color": "red",*/ "border-width": "2px" }} />
                {this.state.selectedCollections[0].name}
            </Box>
            :
            this.state.selectedCollections.length == 0 ?
                <Box pad="small">All Collections</Box>
                :
                <Box pad="small">Multiple Collections</Box>;
    }
    resetFilter = () => {
        this.setState({
            showPlacemarks: true,
            showTags: true,
            showControlTags: RB.canEdit(constants.PERMS.LOC_ADMINISTRATION),
            //control: "All",
            //mapVisible: true,
            selectedCollections: [],
            assetsOnMap: [],
            showUnlinkedTags: RB.canEdit(constants.PERMS.LOC_ADMINISTRATION),
            selectedDepartment: "",
            selectedStatus: "",
            statuses: [],
            departments: [],
            unlinks: [],
            selectedManufacturer: "",
            selectedModel: "",
            selectedDeviceType: ""
        }, () => {
            if (this.state.selectedFloorId)
                this.renderMap(this.state.selectedFloorId);
        });
    }

    filterDataBySelections() {
        const { selectedDepartment, selectedManufacturer, selectedModel, selectedDeviceType, selectedStatus } = this.state;
        let assets = [];
        let unl = this.state.unlinks.length > 0 ? this.state.unlinks : this.props.assetsdata.map(a => a.id);
        if (!(selectedDepartment == "" && selectedManufacturer == "" && selectedModel == "" && selectedDeviceType == "" && selectedStatus == "")) {
            let trackers = this.props.trackersdata.filter(t =>
                ((selectedDepartment && selectedDepartment.id == t.departmentId) || selectedDepartment == "") &&
                ((selectedManufacturer && selectedManufacturer.id == t.manufacturerId) || selectedManufacturer == "") &&
                ((selectedModel && selectedModel.id == t.modelId) || selectedModel == "") &&
                ((selectedDeviceType && selectedDeviceType.id == t.deviceTypeId) || selectedDeviceType == "")
            )
            console.log("dirr", trackers)
            if (trackers) {
                //check if we have linked tag(s) for that tracker(s)

                //assets = trackers.map(t => t.assetBeaconId);

                //let links = this.props.associationsdata.filter(a => trackers.map(t => t.id).includes(a.assetId) && a.beaconId);
                //if (links) {
                //    assets = [];
                //    links.forEach(l => {
                //        l.id = l.beaconId;
                //        assets.push(l.beaconId);
                //    });
                //}

                let links = this.props.assetsdata.filter(a => trackers.map(t => t.assetBeaconId).includes(a.id));
                if (selectedStatus != "") {
                    assets = links.filter(a => a.serviceStateId == selectedStatus.id).map(a => a.id);
                } else {
                    assets = links.map(a => a.id);
                }
            }
        }
        else {
            if (!this.state.showUnlinkedTags && this.state.unlinks.length == 0 && RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) {
                //Dont have links && only show links
                assets = [];
            }
            else {
                assets = unl;
            }
        }

        return assets;
    }

    renderActionBar(toFullScreen = true) {
        let isClearVisible =
            this.state.showPlacemarks == true &&
                this.state.showTags == true &&
                this.state.showControlTags == RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) &&
                this.state.selectedCollections.length == 0 &&
                this.state.assetsOnMap.length == 0 &&
                this.state.showUnlinkedTags == RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) &&
                this.state.selectedDepartment == "" &&
                this.state.selectedStatus == "" &&
                this.state.selectedManufacturer == "" &&
                this.state.selectedModel == "" &&
                this.state.selectedDeviceType == ""
                ? false : true;

        return (
            <Box id="actionbar" direction="row" >
                <StyledBox id="search" style1 width="70%" direction="row" focusIndicator={true} style={{ border: "rgba(100, 100, 100, 0.35) 1px solid", height: "48px" }} /*onClick={() => { }}*/ ref={this.target}>
                    <TextInput
                        plain
                        focusIndicator={false}
                        placeholder="Search"
                        dropTarget={this.target.current}
                        onClose={() => this.setState({ assetList: this.mix })}
                        value={this.state.selectedAsset ? this.state.selectedAsset.name : ""}
                        onChange={evt => {
                            //console.log("rara", evt.target.value);
                            let searchText = evt.target.value;
                            const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                            const filtered = this.mix.filter(o => o.name.match(regexp));
                            this.setState({ assetList: filtered, selectedAsset: { name: evt.target.value } });
                        }}
                        onSuggestionSelect={evt => {
                            this.setState({ selectedAsset: evt.suggestion.option });
                            console.log("raraS", evt);
                            let element = evt.suggestion.option;
                            if (element) {
                                if (element.hasOwnProperty("isControlTag")) {
                                    //Tag or Asset
                                    const assetid = element.id;
                                    const buildingid = element.map.group.id;
                                    const mapid = element.map.id;
                                    const buildingName = this.props.buildingsdata.find(b => b.meridianId == buildingid);
                                    const floorName = this.props.levelsdata.find(l => l.meridianId == mapid);
                                    const floors = this.props.levelsdata.filter(f => /*f.mergedBuildingId == buildingid &&*/ f.meridianId != "");
                                    console.log("floors", floors);
                                    this.setState({ selectedFloorId: mapid, selectedFloor: floorName, selectedBuilding: buildingName, floors: floors, assetid: assetid, placemarkid: "" }, () => this.renderMap(mapid));
                                }
                                else {
                                    //Placemark
                                    const placemarkid = element.meridianId;
                                    //const buildingid = element.map.group.id;
                                    //const mapid = element.meridianId;
                                    //const buildingName = this.props.buildingsdata.find(b => b.meridianId == buildingid);
                                    const floor = this.props.levelsdata.find(l => l.mergedId == element.levelId);
                                    const mapid = floor.meridianId;
                                    //const buildingName = floor.mergedBuildingName;
                                    const buildingName = this.emptyBuildingArr.concat(this.props.buildingsdata).find(b => b.mergedId == floor.mergedBuildingId);
                                    const floors = this.props.levelsdata.filter(f => /*f.mergedBuildingId == buildingid &&*/ f.meridianId != "");
                                    console.log("floors", floors);
                                    this.setState({ selectedFloorId: mapid, selectedFloor: floor, selectedBuilding: buildingName, floors: floors, placemarkid: placemarkid, assetid: "" }, () => this.renderMap(mapid));
                                }
                            }
                        }}
                        suggestions={this.state.assetList.map((option, index, list) => {
                            let info = "";
                            let name = "";
                            if (option.hasOwnProperty("isControlTag")) {
                                //Tag or Asset
                                if (RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) {
                                    if (this.props.associationsdata && this.props.associationsdata.length > 0 && !this.props.associationsdata[0].hasOwnProperty("count") &&
                                        this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId).includes(option.id)) {
                                        //Linked
                                        info = "Tracker";
                                        //Get the Tracker Name
                                        let association = this.props.associationsdata.find(a => a.beaconId == option.id);
                                        let tracker = this.props.trackersdata.find(t => t.id == association.assetId);
                                        name = tracker ? tracker.name : option.name;
                                    }
                                    else {
                                        //Not Linked, isControlTag will determine it
                                        info = option.isControlTag ? "Control Tag" : "Unlinked Tag";
                                        name = option.name;
                                    }
                                }
                                else {
                                    //Only Linked
                                    info = "Tracker";
                                    //Get the Tracker Name
                                    let association = this.props.associationsdata.find(a => a.beaconId == option.id);
                                    let tracker = this.props.association ? this.props.trackersdata.find(t => t.id == association.assetId) : "";
                                    name = tracker ? tracker.name : option.name;
                                }
                            } else {
                                //Placemark
                                info = "Placemark";
                                if (option.placemarktype == "Desk" || option.teemType == "desk")
                                    info = "Desk";
                                else if (option.placemarktype == "Conference Room" || option.teemType == "room")
                                    info = "Room";
                                name = option.name;
                            }
                            return {
                                label: (
                                    <Box pad="small" justify="between" direction="row">
                                        {name}
                                        <Box>{info}</Box>
                                    </Box>),
                                value: name,
                                option: option

                            };
                        })
                        }
                    />
                    <Button
                        icon={this.state.selectedAsset && this.state.selectedAsset.name ? < FormClose color={constants.HPE_GREEN} /> : <Blank />}
                        disabled={this.state.selectedAsset ? false : true}
                        onClick={() => this.setState({ selectedAsset: "", assetid: "", placemarkid: "", assetList: this.mix }, () => {
                            if (this.state.selectedFloorId) {
                                this.renderMap(this.state.selectedFloorId);
                            }
                        })}
                    />
                </StyledBox>

                <Box justify="between" direction="row" width="100%">
                    <Box id="filter" direction="row" gap="small" height="48px">
                        <StyledBox style1 alignSelf="center" focusIndicator={true} style={{ "margin-left": "5px", border: "rgba(100, 100, 100, 0.35) 1px solid" }}>
                            <FilterButton icon={<Filter />} onClick={() => this.setState({ showFilter: true })} />
                        </StyledBox>
                        <Box style={{ visibility: isClearVisible ? "visible" : "hidden" }} alignSelf="center">
                            <Anchor label={<Text size="xsmall">Clear filters</Text>} onClick={this.resetFilter} />
                        </Box>
                    </Box>
                    <Box id={toFullScreen ? "expand" : "close"} alignSelf="center" alignContent="end" data-for={toFullScreen ? "seefull" : "closefull"} data-tip={toFullScreen ? "See Fullscreen" : "Close Fullscreen"}>
                        <Button icon={toFullScreen ? <Expand /> : <Contract />}
                            focusIndicator={this.state.isFullScreen}
                            onClick={() => this.setState({ isFullScreen: toFullScreen }, () => {
                                if (this.map) {
                                    this.map.destroy();
                                    if (this.state.selectedFloorId) {
                                        this.setState({ isDestroyed: true }, () =>
                                            this.renderMap(this.state.selectedFloorId));
                                    }
                                }
                            })}
                            plain />
                        <ReactTooltip id={toFullScreen ? "seefull" : "closefull"} place="bottom" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>
                </Box>
            </Box>
        );
    }

    render() {
        const { assetsdata, /*collectionsdata,*/ departmentsdata, trackersdata, associationsdata, placemarksdata } = this.props;
        //const { buildingsdata, levelsdata } = this.props;
        const { levelsdataLoading, buildingsdataLoading, sitedataLoading, assetsdataLoading, placemarksdataLoading } = this.state;

        if (levelsdataLoading || buildingsdataLoading || sitedataLoading || assetsdataLoading || placemarksdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        //e = document.getElementsByClassName('meridian-tag-label-CT-Human');
        //if (e && e.length > 0) {
        //    console.log("halo1", e, e[0]);
        //    for (let i = 0; i < e.length; i++) {
        //        e[i].style.backgroundColor = "red";
        //        e[i].style.backgroundImage = "none";
        //    }
        //}
        //const collectionsdata = [{ id: "ffa500C", name: "Chair", hexColor: "#ffa500" }, { id: "ff0000CT", name: "CT-Human", hexColor: "#ff0000" }, /*{ id: "ff0000C-T", name: "Control Tag", hexColor: "#0000ff" }*/];

        if (document.getElementById('header')) {
            this.headerHeight = document.getElementById('header').offsetHeight;
        }

        let collectionsdata = this.props.collectionsdata.filter(c => !c.hasOwnProperty("count"));
        let activeCollections = this.state.selectedCollections.length != 0 ? this.state.selectedCollections : collectionsdata;
        activeCollections.sort(constants.byPropertyCalled("name"));
        const isSiteAdmin = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        console.log("propi", this.props);
        this.props.buildingsdata.sort((p1, p2) => { if (p1.meridianName.toLowerCase() < p2.meridianName.toLowerCase()) return -1; return 1; });
        this.props.levelsdata.sort((p1, p2) => { if (p1.meridianName.toLowerCase() < p2.meridianName.toLowerCase()) return -1; return 1; });

        let isClearVisible =
            this.state.showPlacemarks == true &&
                this.state.showTags == true &&
                this.state.showControlTags == RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) &&
                this.state.selectedCollections.length == 0 &&
                this.state.assetsOnMap.length == 0 &&
                this.state.showUnlinkedTags == RB.canEdit(constants.PERMS.LOC_ADMINISTRATION) &&
                this.state.selectedDepartment == "" &&
                this.state.selectedStatus == "" &&
                this.state.selectedManufacturer == "" &&
                this.state.selectedModel == "" &&
                this.state.selectedDeviceType == ""
                ? false : true;

        return (
            <Box width="100%">
                <Grid fill={true}
                    rows={['xsmall', "auto"]}
                    columns={['100%', 'flex']}
                    areas={[
                        { name: 'header', start: [0, 0], end: [1, 0] },

                        { name: 'main', start: [0, 1], end: [1, 1] }, ,
                        { name: 'side', start: [1, 0], end: [1, 1] },
                    ]}
                >
                    <Box gridArea='header' direction="row"
                        align="center"
                        pad="medium"
                        justify="between"
                        background={constants.BACKGROUND_COLOR}>
                        <Heading level='2' >Live Map</Heading>
                        {!this.state.selectedBuilding && <ReactTooltip id="selectbuilding" place="bottom" type="dark" effect="solid" arrowColor="transparent" />}
                    </Box>
                    <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad={{ "horizontal": "medium" }} margin={this.state.mapVisible ? { "bottom": "157px" } : ""} height="100%" fill="vertical">
                        {this.renderActionBar(true)}
                        
                        <Box direction="row" margin={{ "top": "small", "bottom": "medium" }}>
                            <Box width="100%">
                                <div id="meridian-map1" className="map" style={{ visibility: "hidden" }} />
                                {!this.state.mapVisible &&
                                    <Box background="#fafafa" /*height="450px"*/ align="center">
                                        <Heading>
                                        {this.HeaderInfo == "" ?
                                            (<LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />)
                                            :
                                            (this.HeaderInfo)
                                        }
                                    </Heading>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                {
                    this.state.isFullScreen &&
                    <Layer
                        //position={"right"/*size !== 'small' ? 'right' : undefined*/}
                        margin={{ "top": this.headerHeight + "px" }}
                        full
                        modal
                        onClickOutside={() => this.setState({ isFullScreen: false })}
                        onEsc={() => this.setState({ isFullScreen: false })}
                    >
                        <Box /*gridArea='main'*/ background={constants.BACKGROUND_COLOR} pad="medium" /*margin={this.state.mapVisible ? { "bottom": "157px" } : ""}*/ height="100%" fill="vertical">
                            {this.renderActionBar(false)}
                            <Box direction="row" margin={{ "top": "small", "bottom": "medium" }}>
                                <Box width="100%">
                                    <div id="meridian-map2" className="map" style={{ visibility: "hidden" }} />
                                    {!this.state.mapVisible &&
                                        <Box background="#fafafa" /*height="450px"*/ align="center">
                                            <Heading>
                                            {this.HeaderInfo == "" ?
                                                (<LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />)
                                                :
                                                (this.HeaderInfo)
                                            }
                                            </Heading>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        </Box>
                        {!this.state.selectedBuilding && <ReactTooltip id="selectbuildingfull" place="bottom" type="dark" effect="solid" arrowColor="transparent" />}
                    </Layer>
                }



                {this.state.showFilter && (
                    <Layer margin={{ "top": this.headerHeight + "px" }}
                        position={"right"/*size !== 'small' ? 'right' : undefined*/}
                        full={/*size !== 'small' ? 'vertical' : true*/"vertical"}
                        modal
                        onClickOutside={() => this.setState({ showFilter: false })}
                        onEsc={() => this.setState({ showFilter: false })}
                    >
                        <Box
                            flex
                            overflow="auto"
                            width={{ min: 'medium' }}
                            pad={{ horizontal: 'medium', vertical: 'medium' }}
                            gap="medium"
                            fill="vertical"
                        >
                            <Header>
                                <Heading as="p" color="text-strong" margin="none">
                                    Filters
                                </Heading>
                                <Box>
                                    <Button
                                        icon={<FormClose />}
                                        onClick={() => this.setState({ showFilter: false })}
                                    />
                                </Box>
                            </Header>
                            {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                <StyledBox flex="false">
                                    <Box direction="row" justify="between">
                                        <StyledBox style1 width="80%">
                                            <Select placeholder="Select Department" options={departmentsdata.filter(d => !d.hasOwnProperty("count"))} labelKey="name" value={this.state.selectedDepartment}
                                                onChange={evt => {
                                                    let trackers = trackersdata.filter(t => t.departmentId == evt.option.id);
                                                    if (trackers) {
                                                        //check if we have linked tag(s) for that tracker(s)
                                                        let links = associationsdata.filter(a => trackers.map(t => t.id).includes(a.assetId));
                                                        if (links) {
                                                            links.forEach(l => l.id = l.beaconId);
                                                            console.log("departm", links);
                                                            this.setState({ assetsOnMap: links, departments: links.map(l => l.id) }, () => {
                                                                if (this.state.selectedFloorId) {
                                                                    this.renderMap(this.state.selectedFloorId);
                                                                }
                                                            });
                                                        } else {
                                                            this.setState({ departments: [] });
                                                        }
                                                    } else {
                                                        this.setState({ departments: [] });
                                                    }
                                                    this.setState({ selectedDepartment: evt.option });
                                                }} />
                                        </StyledBox>
                                        {this.state.selectedDepartment && <Button
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedDepartment: "", departments: [] }, () => {
                                                if (this.state.selectedFloorId) {
                                                    this.renderMap(this.state.selectedFloorId);
                                                }
                                            })}
                                        />}
                                    </Box>
                                </StyledBox>
                            }
                            {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                <StyledBox flex="false">
                                    <Box direction="row" justify="between">
                                        <StyledBox style1 width="80%">
                                            <Select
                                                placeholder="Select Collection"
                                                //value={this.state.selectedCollections}
                                                value={this.renderValue()}
                                                options={collectionsdata}
                                                //labelKey="name"
                                                multiple
                                                onChange={o => {
                                                    let tmp = [...this.state.selectedCollections];
                                                    if (tmp.map(c => c.id).includes(o.option.id)) {
                                                        tmp = tmp.filter(t => t.id != o.option.id);
                                                    } else {
                                                        tmp.push(o.option);
                                                    }
                                                    this.setState({ selectedCollections: tmp }, () => {
                                                        if (this.state.selectedFloorId) {
                                                            this.renderMap(this.state.selectedFloorId);
                                                        }
                                                    });
                                                }}
                                            >
                                                {option => {
                                                    return (
                                                        <Box pad="small" direction="row" gap="small">
                                                            {/*<StatusGoodSmall color={option.hexColor} />*/}
                                                            <Box round height="23px" background={option.hexColor} width="23px" border style={{ /*"border-color": "red",*/ "border-width": "2px" }} />
                                                            {option.name}
                                                        </Box>);
                                                }}
                                            </Select>
                                        </StyledBox>
                                        {!this.state.selectedCollections.length == 0 && <Button
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedCollections: [] })}
                                        />}
                                    </Box>
                                    {activeCollections.length > 0 && <Box width="100%" gap="medium" align="start" pad={{ top: "small" }}>
                                        {activeCollections.map(c => (
                                            <Box justify="between" direction="row" gap="small" pad={{ horizontal: "small" }}>
                                                <Box round height="23px" background={c.hexColor} width="23px" border style={{ "border-width": "2px" }} />
                                                <Text>{c.name}</Text>
                                            </Box>
                                        ))}
                                    </Box>}
                                </StyledBox>
                            }


                            {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                <StyledBox flex="false">
                                    <Box direction="row" justify="between">
                                        <StyledBox style1 width="80%">
                                            <Select
                                                placeholder="Select Manufacturer"
                                                value={this.state.selectedManufacturer}
                                                options={this.props.manufacturersdata}
                                                onChange={evt => {
                                                    this.setState({ selectedManufacturer: evt.option });
                                                }}
                                                labelKey="name">
                                            </Select>
                                        </StyledBox>
                                        {this.state.selectedManufacturer && <Button
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedManufacturer: "" })}
                                        />}
                                    </Box>
                                </StyledBox>
                            }
                            {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                <StyledBox flex="false">
                                    <Box direction="row" justify="between">
                                        <StyledBox style1 width="80%">
                                            <Select
                                                placeholder="Select Model"
                                                value={this.state.selectedModel}
                                                options={this.props.modelsdata}
                                                onChange={evt => {
                                                    this.setState({ selectedModel: evt.option });
                                                }}
                                                labelKey="name">
                                            </Select>
                                        </StyledBox>
                                        {this.state.selectedModel && <Button
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedModel: "" })}
                                        />}
                                    </Box>
                                </StyledBox>
                            }
                            {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled &&
                                <StyledBox flex="false">
                                    <Box direction="row" justify="between">
                                        <StyledBox style1 width="80%">
                                            <Select
                                                placeholder="Select Model Type"
                                                value={this.state.selectedDeviceType}
                                                options={this.props.typesdata}
                                                onChange={evt => {
                                                    this.setState({ selectedDeviceType: evt.option });
                                                }}
                                                labelKey="name">
                                            </Select>
                                        </StyledBox>
                                        {this.state.selectedDeviceType && <Button
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedDeviceType: "" })}
                                        />}
                                    </Box>
                                </StyledBox>
                            }

                            {this.context.feature.find(f => f.name == "siteWellness").isEnabled &&
                                <StyledBox flex="false">
                                    <Box direction="row" justify="between">
                                        <StyledBox style1 width="80%">
                                            <Select
                                                placeholder="Select Status"
                                                value={this.state.selectedStatus} options={constants.ASSET_STATUS}
                                                onChange={evt => {
                                                    let list = this.props.assetsdata.filter(a => a.serviceStateId == evt.option.id);
                                                    this.setState({ selectedStatus: evt.option, statusId: evt.option.id, assetsOnMap: list, statuses: list.map(l => l.id) }, () => {
                                                        if (this.state.selectedFloorId) {
                                                            this.renderMap(this.state.selectedFloorId);
                                                        }
                                                    });
                                                }}
                                                labelKey="name">
                                                {option =>
                                                    <Box pad="small">
                                                        {option.name}
                                                    </Box>
                                                }
                                            </Select>
                                        </StyledBox>
                                        {this.state.selectedStatus && <Button
                                            icon={<FormClose />}
                                            onClick={() => this.setState({ selectedStatus: "", statuses: [] }, () => {
                                                if (this.state.selectedFloorId) {
                                                    this.renderMap(this.state.selectedFloorId);
                                                }
                                            })}
                                        />}
                                    </Box>
                                </StyledBox>
                            }
                            <StyledBox style1 pad="small" width="80%" flex="false">
                                {/*this.state.selectedFloorId*/true &&
                                    <Box gap="medium" align="start" /*border="all"*//* width="20%"*/>
                                        <Box>
                                            <CheckBox /*reverse*/ checked={this.state.showPlacemarks} toggle label="Placemarks" onChange={() =>
                                                this.setState({ showPlacemarks: !this.state.showPlacemarks },
                                                    () => {
                                                        if (this.state.selectedFloorId)
                                                            this.renderMap(this.state.selectedFloorId);
                                                    }
                                                )} />
                                        </Box>
                                        {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled && <Box>
                                            <CheckBox /*reverse*/ checked={this.state.showTags} toggle label="Tags" onChange={() => this.setState({ showTags: !this.state.showTags }, () => {
                                                if (this.state.selectedFloorId)
                                                    this.renderMap(this.state.selectedFloorId);
                                            })} />
                                        </Box>}
                                        {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled && isSiteAdmin &&
                                            <Box>
                                                <CheckBox /*reverse*/ checked={this.state.showControlTags} toggle label="Control Tags" onChange={() => this.setState({ showControlTags: !this.state.showControlTags }, () => {
                                                    if (this.state.selectedFloorId)
                                                        this.renderMap(this.state.selectedFloorId);
                                                })} />
                                            </Box>
                                        }
                                        {this.context.feature.find(f => f.name == "siteAssetTracking").isEnabled && isSiteAdmin &&
                                            <Box>
                                                <CheckBox /*reverse*/ checked={this.state.showUnlinkedTags} toggle label="Unlinked Tags"
                                                    //onChange={() => this.setState({ showControlTags: !this.state.showControlTags }, () => {
                                                    //    if (this.state.selectedFloorId)
                                                    //        this.renderMap(this.state.selectedFloorId);
                                                    //})}
                                                    onChange={(evt) => {
                                                        console.log("Unlink", evt.target.checked);
                                                        //let includeControlTags = this.state.showControlTags ? "" : false;
                                                        //this.props.action.getMappedAssetsAction(includeControlTags, !this.state.showUnlinkedTags)
                                                        //    .catch(error => {
                                                        //        console.log('*DEBUG*- MappedAssets List Error', error);
                                                        //    });
                                                        let list = [];
                                                        if (evt.target.checked) {
                                                            //if (this.state.selectedDepartment) {
                                                            //    list = this.state.assetsOnMap;
                                                            //} else {
                                                            //    list = this.props.assetsdata;
                                                            //}
                                                            list = this.props.assetsdata;
                                                        }
                                                        else {
                                                            //if (this.state.selectedDepartment) {
                                                            //    list = this.state.assetsOnMap;
                                                            //} else {
                                                            //    list = this.props.assetsdata.filter(at => this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId).includes(at.id));
                                                            //}
                                                            list = this.props.assetsdata.filter(at => this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId).includes(at.id));
                                                        }
                                                        console.log("unlink", list, this.props.associationsdata.filter(a => a.assetId != "" && a.assetName != "" && a.beaconId != "" && a.beaconName != "").map(i => i.beaconId));
                                                        this.setState({ showUnlinkedTags: !this.state.showUnlinkedTags, assetsOnMap: list, unlinks: list.map(l => l.id) }, () => {
                                                            if (this.state.selectedFloorId)
                                                                this.renderMap(this.state.selectedFloorId);
                                                        });
                                                    }}
                                                />
                                            </Box>
                                        }
                                    </Box>}
                            </StyledBox>
                            <Box justify="between" direction="row" flex="false">
                                <StyledButton typeAdd label="Reset Filter" onClick={this.resetFilter} />
                            </Box>
                        </Box>
                    </Layer>
                )}
            </Box>
        );
    }
}

const mapStateToProps = state => ({
    levelsdata: state.levelsReducer.levelsdata,
    buildingsdata: state.buildingsReducer.buildingsdata,
    sitedata: state.selectedSiteReducer.sitedata,
    assetsdata: state.assetsReducer.assetsdata,
    collectionsdata: state.collectionsReducer.collectionsdata,
    associationsdata: state.associationsReducer.associationsdata,
    departmentsdata: state.departmentsReducer.departmentsdata,
    trackersdata: state.trackersReducer.trackersdata,
    placemarksdata: state.placemarksReducer.placemarksdata,
    manufacturersdata: state.manufacturersReducer.manufacturersdata,
    modelsdata: state.modelsReducer.modelsdata,
    typesdata: state.typesReducer.typesdata
});

const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...OrganisationAction, ...LevelAction, ...BuildingAction, ...SiteAction, ...AssetAction, ...CollectionAction, ...AssociationAction, ...DepartmentAction, ...TrackerAction, ...PlacemarkAction, ...ManufacturerAction, ...ModelAction, ...TypeAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeridianMap));