import styled, { css  } from 'styled-components';
import { FormField } from 'grommet';

export const StyledFormField = styled(FormField)`
        //background: blue;
        //font-family: Impact;
        //border: 2px solid ${props => props.theme.main};
        //border: 2px solid #111111;
        //border-radius: 3px;
        //padding: 10px;
        //color: red;

      ${props => props.testproperty && css` //set testproperty to true, and the background and color is going to change to blue and white
        background: blue;
        color: white;
      `}               
`;
