import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AssetAction from '../action/AssetAction';
import * as AssetLabelsAction from '../action/AssetLabelsAction';
import AssetApi from '../api/AssetApi';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Add, OrderedList, FormClose, FormClock, Download, Dashboard, Upload, DocumentVerified, DocumentCsv, CircleInformation } from 'grommet-icons';
import { Grommet, Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, CheckBox, Meter } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import Notification from './common/Notification';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import ReactTooltip from "react-tooltip";
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import { CSVLink } from "react-csv";
import './TagCreateContainer.css';

export class TagBulkEditContainer extends React.Component {
    constructor() {
        super();
        this.selectedLabels = [];
        this.assetBeaconTags = [];
        this.state = {
            options: [],
            selected: "",
            location: "",
            isSaved: false,
            open: false,
            notification: '',
            disabledButton: true,
            cancel: false,
            isSaveSuccessful: false,
            isNewLabel: false,

            newLabel: "",
            data: [],
            progressValue: 0,
            assetlabelsdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Validating errors
    tagName_err = '';
    externalID_err = '';
    MAC_err = '';
    categoryid_err = '';
    newname_err = "";
    parent_err = "";

    ClearErrorMessages() {
        this.tagName_err = '';
        this.externalID_err = '';
        this.MAC_err = '';
        this.categoryid_err = '';
        this.newname_err = "";
        this.parent_err = "";
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        this.props.action.getAssetLabelsAction()
            .then(response => this.setState({ assetlabelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- AssetLabelsAction List Error', error);
            });
    }
    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.state.data.length > 0) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }
    handleCancel() {
        if (!this.state.cancel && this.state.data > 0) {
            this.setState({ cancel: true });
        }
        else {
            if (this.nextLoc) {
                this.props.history.push(this.nextLoc);
            } else {
                if (sessionStorage.getItem("organisationId")) {
                    if (sessionStorage.getItem("locationId"))
                        this.props.history.push({
                            pathname: '/' + sessionStorage.getItem("organisationId") + '/' + sessionStorage.getItem("locationId") + '/tags',
                            state: { index: 0 }
                        });
                    else { }
                }
                else
                    this.props.history.push({
                        pathname: '/tags',
                        state: { index: 0 }
                    });
            }
        }
    }

    onClose() {
        //this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            if (sessionStorage.getItem("organisationId")) {
                if (sessionStorage.getItem("locationId"))
                    this.props.history.push({
                        pathname: '/' + sessionStorage.getItem("organisationId") + '/' + sessionStorage.getItem("locationId") + '/tags',
                        state: { index: 0 }
                    });
                else { }
            }
            else
                this.props.history.push({
                    pathname: '/tags',
                    state: { index: 0 }
                });
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    renderValue() {
        if (this.selectedLabels.length == 0) return < Box pad="small" > Select Label</Box >;
        if (this.selectedLabels.length == 1) return < Box pad="small" > {this.selectedLabels[0].name}</Box >;
        if (this.selectedLabels.length > 1) return < Box pad="small" > Multiple Labels</Box >;
    }
    renderTable(data) {
        if (data.length != 0) {
            return (
                <Box>
                    <table>
                        <thead>
                            <tr>
                                {Object.keys(data[0]).map((k, index) => (
                                    <th>
                                        {k}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((info, idx) => (
                                <tr>
                                    {Object.keys(info).map((key, index) => (
                                        <td class="upload">
                                            {info[key]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            );
        }
        return;
    }
    handleSave() {
        this.setState({ disabledButton: true });
        this.state.data.forEach((row, idx) => {
            //let tag = assestdata.find(a => a.id == row["MAC"]);
            AssetApi.getAsset(row["MAC"])
                .then(tag => {
                    if (tag) {
                        tag.name = row["Name"];
                        //tag.externalId = row["External Ref ID"];
                        tag.assetBeaconTags = this.assetBeaconTags;
                        this.props.action.saveAssetAction(tag)
                            .then((response) => {
                                if (idx == this.state.data.length - 1)
                                    this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                            }).catch(error => {
                                console.log('*DEBUG*-Save AssetEdit Error', error);
                                //this.setState({ disabledButton: false });
                                this.setState({ isSaved: true, isSaveSuccessful: false });
                            });
                    } else {
                        this.setState({ isSaved: true, isSaveSuccessful: false });
                    }
                })
                .catch(error => {
                    console.log('*DEBUG*-Save Get Asset Error', error);
                    //this.setState({ disabledButton: false });
                    this.setState({ isSaved: true, isSaveSuccessful: false });
                });
        });
    }

    render() {
        const { assetlabelsdata, departmentsdata } = this.props;
        const { parent, parents, options, isNewLabel, isNewCategory, isNewDepartment, assetlabelsdataLoading } = this.state;

        console.log("kra asset", this.props, this.selectedLabels);
        if (assetlabelsdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }
        const heading = 'Bulk Edit Tags';
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Box direction="row" gap="small">
                        <Heading level='2' >{heading}</Heading>
                        <CircleInformation style={{ alignSelf: "center" }} data-for="bulk" data-tip="Edit tags in bulk using downloaded files with completed tags information." />
                        <ReactTooltip id="bulk" place="right" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>
                    <Box direction="row" pad={{ horizontal: "small", }} gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} /*disabled={this.state.disabledButton}*/ />
                        <StyledButton typeSave label="Save" onClick={() => this.handleSave()} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" style={{ minHeight: "500px" }}>
                    <form>
                        <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Warning, unable to complete link due to a configuration issue, please contact your admin."} error={!this.state.isSaveSuccessful} />
                        <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                        <Box>
                            <Box pad={{ bottom: "small" }}>
                                <Text>To bulk edit tags, upload a local CSV file and select "Save".</Text>
                                <br />
                                <Text>A CSV file can be generated by selecting tags in the Tags table, and selecting the download icon on the action bar that appears at the foot of the table.  Once downloaded the CSV can be edited in bulk before being uploaded here.</Text>
                            </Box>
                            <Text size="large">File Upload</Text>
                            <Box /*border="all"*/ style={{ maxWidth: "600px" }}>
                                {!this.state.file &&
                                    <Dropzone /*accept=".csv"*/ maxFiles={1} onDrop={acceptedFiles => {
                                        console.log(acceptedFiles);
                                        ///reader start
                                        acceptedFiles.forEach((file) => {
                                            const reader = new FileReader();
                                            reader.onprogress = (evt) => {
                                                this.setState({ progressValue: parseInt(((evt.loaded / evt.total) * 100), 10) });
                                            };
                                            reader.onabort = () => console.log('file reading was aborted');
                                            reader.onerror = () => console.log('file reading has failed');
                                            reader.onload = () => {
                                                // Do whatever you want with the file contents
                                                const binaryStr = reader.result;
                                                console.log(binaryStr);
                                            }
                                            reader.readAsArrayBuffer(file);
                                        })
                                        ///reader end


                                        this.setState({ file: acceptedFiles[0] });
                                        if (acceptedFiles[0].type == "application/vnd.ms-excel" || acceptedFiles[0].type == "text/csv") {
                                            Papa.parse(acceptedFiles[0], {
                                                delimiter: "",
                                                header: true,
                                                skipEmptyLines: "greedy",
                                                //step: (row, parser) => {
                                                //    console.log("Row data:", row.data);
                                                //    console.log("Row errors:", row.errors);
                                                //    this.setState(prevState => ({
                                                //        data: [...prevState.data, row.data]
                                                //    }));
                                                //},
                                                complete: (results) => {
                                                    console.log(results);
                                                    this.setState({ data: results.data, disabledButton: false });
                                                }
                                            });
                                        }
                                    }}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="upload-container">
                                                <div {...getRootProps()} className="drop-zone">
                                                    <Box align="center" margin={{ top: "10px" }}>
                                                        <Upload />
                                                    </Box>
                                                    <input {...getInputProps()} />
                                                    <p>Drag and drop or browse to your files</p>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                }
                                {this.state.file &&
                                    <Box style={{ maxWidth: "600px" }} /*align="center" border="all"*/ className="upload-container">
                                        {this.state.progressValue == 100 ?
                                            <DocumentCsv size="medium" />
                                            :
                                            <LoadingIndicator />
                                        }
                                        <Text>{this.state.file.name}</Text>
                                        <Box>
                                            <Meter type="bar" round background="light-3" max={100} values={[{ value: this.state.progressValue, color: "brand" }]} />
                                            <Box justify="end" direction="row">
                                                {/*<Text>30byte of 60byte</Text>*/}
                                                <Text size="small">Upload{this.state.progressValue == 100 ? "ed" : "ing"} {this.state.progressValue}%</Text>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                            {this.state.data.length > 0 &&
                                <Box>
                                    <Text>Select the labels to be assigned to all tags.</Text>
                                    <Box pad={{ vertical: "small" }}>
                                        <Text size="large">Label</Text>
                                        <Text size="medium">Warning, Tag labels will be replaced with new selection made.</Text>
                                    </Box>
                                    <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                        {!isNewLabel ? (
                                            <Box direction="row" gap="small">
                                                <Box fill>
                                                    <FormField label="Label Name" >
                                                        <Select width="large"
                                                            placeholder="Select a Label"
                                                            //onClose={() => this.setState({ options: this.categoryfull })}
                                                            //onSearch={(searchText) => {
                                                            //    const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                            //    const filtered = this.categoryfull.filter(o => o.lpn.match(regexp));
                                                            //    if (filtered.length > 0) {
                                                            //        this.setState({ options: filtered });
                                                            //    }
                                                            //    else {
                                                            //        this.setState({ options: [{ id: "0", name: "No match found", parent: "", location: "", lpn: "No match found" }] });
                                                            //    }
                                                            //}}
                                                            value=""
                                                            multiple
                                                            onChange={event => {
                                                                if (this.assetBeaconTags) {
                                                                    if (this.assetBeaconTags.map(l => l.assetLabelId).includes(event.option.id)) {
                                                                        this.assetBeaconTags = this.assetBeaconTags.filter(l => l.assetLabelId != event.option.id);
                                                                        this.selectedLabels = this.selectedLabels.filter(l => l.id != event.option.id);
                                                                    } else {
                                                                        this.assetBeaconTags.push({ "assetLabelId": event.option.id });
                                                                        this.selectedLabels.push(event.option);
                                                                    }
                                                                } else {
                                                                    this.assetBeaconTags = [{ "assetLabelId": event.option.id }];
                                                                    this.selectedLabels.push(event.option);
                                                                }
                                                                this.setState({ label: event.option });
                                                            }}
                                                            options={assetlabelsdata.sort(constants.byPropertyCalled("name"))}
                                                            labelKey="name"
                                                        />
                                                    </FormField>
                                                </Box>
                                                <Box justify="end" /*pad={{ "top": "45px" }} height="95px"*/ margin={{ "top": "32px", "bottom": "12px" }} width="100px" direction="row" >
                                                    <Button style={{ alignSelf: "center" }} plain icon={<Add />} onClick={() => { this.setState({ isNewLabel: !isNewLabel }) }} />
                                                </Box>
                                            </Box>
                                        ) : (
                                                //New Label
                                                <Box>
                                                    <Box fill direction="row" gap="small">
                                                        <Box fill>
                                                            <FormField label="Label Name">
                                                                <TextInput placeholder="New Label Name" onChange={evt => this.setState({ newLabel: evt.target.value.trim() })} />
                                                            </FormField>
                                                        </Box>
                                                        <Box /*height="50px"*/ margin={{ "top": "32px", "bottom": "12px" }} width="100px" direction="row" align="center" >
                                                            <Button plain icon={<FormClose />} onClick={() => { this.setState({ isNewLabel: !isNewLabel }) }} />
                                                            <StyledButton disabled={this.selectedLabels.some(l => l.name.toLowerCase() == this.state.newLabel.toLowerCase()) || this.state.newLabel.trim() == ""} typeAdd label="Add"
                                                                onClick={() => {
                                                                    this.selectedLabels.push({ id: "0", name: this.state.newLabel });
                                                                    if (this.assetBeaconTags) {
                                                                        this.assetBeaconTags.push({ "assetLabelId": "0", "tag": { "name": this.state.newLabel } });
                                                                    } else {
                                                                        this.assetBeaconTags = [{ "assetLabelId": "0", "tag": { "name": this.state.newLabel } }];
                                                                    }
                                                                    this.setState({ label: event.target.value });
                                                                }} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )}
                                    </StyledBox>
                                    <Box id="tags" direction="column-responsive" pad={{ top: "small" }}>
                                        {this.selectedLabels.map((c, idx) =>
                                            <Button style={{ width: "fit-content" }} id={idx}
                                                onClick={evt => {
                                                    this.assetBeaconTags = this.assetBeaconTags.filter(l => l.assetLabelId != c.id);
                                                    this.selectedLabels = this.selectedLabels.filter(l => l.id != c.id);
                                                    this.setState({ label: event.option });
                                                }}>
                                                <Box
                                                    align="center"
                                                    background="brand"
                                                    pad={{ horizontal: '2px', vertical: '1px' }}
                                                    margin="2px"
                                                    round="medium"
                                                    direction="row"
                                                >
                                                    <Text size="xsmall" margin={{ right: 'xxsmall' }} truncate>
                                                        {c.name}
                                                    </Text>
                                                    <FormClose size="small" color="white" />
                                                </Box>
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                            }
                            {this.renderTable(this.state.data)}
                        </Box>
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}

const mapStateToProps = (state) => ({
    assetlabelsdata: state.assetLabelsReducer.assetlabelsdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...AssetLabelsAction, ...AssetAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TagBulkEditContainer));