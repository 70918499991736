import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image, Layer, CheckBox } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, CircleInformation, FormView } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import LoadingIndicator from './common/LoadingIndicator';


//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class PlacemarkHistoryListTable extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }} margin={{ "top": "small", "bottom": "medium" }}>
                {renderPagesTable(this.props.placemarkhistorydata)}
            </StyledBox>
        );
    }
}
function renderNote(status) {
    var note = "";
    switch (status) {
        case "Maintenance":
            note = "Issue resolved";
            break;
        case "Maintenance required":
            note = "Seems to have issues powering on";
            break;
        case "Cleaning required":
            note = "Cleaning as per covid policy";
            break;
        default:
            break;
    }
    return note;
}
function renderPagesTable(placemarkhistorydata) {
    const COLUMNS = [
        {
            property: 'cleaningStatus',
            header: 'Status',
            render: s => <Text>{s.cleaningStatus}</Text>
        },
        {
            property: 'updatedBy',
            header: 'User',
            render: s => <Text>{s.updatedBy}</Text>
        },
        //{
        //    property: 'startTime',
        //    header: 'Start Time',
        //    render: s => <Text>{s.start}</Text>
        //},
        {
            property: 'date',
            header: 'Start Date',
            render: s => <Text style={{ width: "max-content" }}>{s.date}</Text>
        },
        {
            property: 'time',
            header: 'Start Time',
            render: s => <Text style={{ width: "max-content" }}>{s.time}</Text>
        },
        {
            property: 'cleaningNotes',
            header: 'Notes',
            render: s => <Text>{s.cleaningNotes}</Text>
        },
        {
            property: 'id',
            header: 'Action',
            render: s =>
                <Box>
                    <FormView data-for={s.id} data-tip="true" />
                    <ReactTooltip id={s.id} place="left" type="light" effect="solid" border={true}>
                        <Box gap="small">
                            <CheckBox label="Empty all rubish bins and replace liners" checked={s.c1_emptyTrash == "true" ? true : false} />
                            <CheckBox label="Vacuum carpeting" checked={s.c2_vacuumCarpeting == "true" ? true : false} />
                            <CheckBox label="Wipe surface" checked={s.c3_wipeSurfaces == "true" ? true : false} />
                            <CheckBox label="Dust equipment" checked={s.c4_dustEquipment == "true" ? true : false} />
                            <CheckBox label="Thoroughly dust and clean surfaces" checked={s.c5_thoroughlyDustAndCleanSurfaces == "true" ? true : false} />
                            <CheckBox label="Dust mop floors" checked={s.c6_dustMopFloors == "true" ? true : false} />
                            <CheckBox label="Damp mop floors" checked={s.c7_dampMopFloors == "true" ? true : false} />
                            <CheckBox label="Damp wipe entryway" checked={s.c8_dampWipeEntryway == "true" ? true : false} />
                            <CheckBox label="Clean glass areas" checked={s.c9_cleanGlassAreas == "true" ? true : false} />
                        </Box>
                    </ReactTooltip>
                </Box>
        },
        {
            property: 'id',
            primary: true,
            size: "0px",
            plain: true,
            header: '',
            render: s => <Text hidden>{s.id}</Text>
        }
    ];

    let DATA = [];

    if (placemarkhistorydata[0] && placemarkhistorydata[0].count != 0) {
        placemarkhistorydata.forEach((ev, index) => {
            var fulltime = ev.timestamp.split("T");
            var time = "";
            var date = "";
            if (fulltime.length == 2) {
                date = fulltime[0];
                time = fulltime[1].replace("Z", "");
            }
            var dot = time.indexOf(".");
            dot = dot > 0 ? dot : time.length;
            //var statusName = constants.ASSET_STATUS.find(s => s.id == ev.serviceStateId);
            //statusName = statusName ? statusName.name : "";
            DATA.push({
                ...ev,
                //name: statusName,
                start: ev.timestamp.replace("T", " ").replace("Z", ""),
                //date: date,
                //time: time.slice(0,dot),
                date: new Date(ev.timestamp).toLocaleDateString('fr-CA'),
                time: new Date(ev.timestamp).toLocaleTimeString(),
                //id: index,
            })
        });
    }

    //DATA.sort(constants.byPropertyCalled("timestamp"));
    DATA.sort((p1, p2) => {
        if (p1.timestamp > p2.timestamp) return -1;
        if (p1.timestamp < p2.timestamp) return 1;
        return 0;
    });

    console.log('DATA:', DATA);

    return (
        <PaginatedDataTable
            bg={constants.BOX_BACKGROUND_COLOR_1}
            columns={COLUMNS.map(c => ({
                ...c,
                //search: c.property === "lcname" || c.property === "lccategoryname" || c.property === "lccategoryparent" || c.property === "lccategorylocation" || c.property ==="lcparentloc"
                search: c.property === "updatedBy"
            }))}
            data={DATA}
            noLoadingMsg={placemarkhistorydata.length != 0}
            //sortable
            sortoptions={[
                { col: 'timestamp', label: 'Date Time' },
                { col: 'cleaningStatus', label: 'Status' },
                { col: 'updatedBy', label: 'User' },
                //{ col: 'date', label: 'Start Date' },
                //{ col: 'time', label: 'Start Time' },
            ]}
            resizeable={false}
            displayrows={constants.PAGE_SIZE}
        />
    );
}

export default PlacemarkHistoryListTable;
