import * as ActionType from './ActionType';
import TypeApi from '../api/TypeApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getTypesResponse = typesdata => ({
    type: ActionType.GET_TYPES_RESPONSE,
    typesdata
});

const queuedGetAllTypes = queueApiCall(TypeApi.getAllTypes);

export function getTypesAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedGetAllTypes()
            .then(typesdata => {
                dispatch(getTypesResponse(typesdata));
                return typesdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewTypeResponse = () => ({
    type: ActionType.ADD_NEW_TYPE_RESPONSE
});

export const updateExistingTypeResponse = () => ({
    type: ActionType.UPDATE_EXISTING_TYPE_RESPONSE
});

export function saveTypeAction(typedata) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return TypeApi.saveType(typedata)
            .then((response) => {
                if (typedata.id) {
                    dispatch(updateExistingTypeResponse());
                } else {
                    dispatch(addNewTypeResponse());
                }
                return response;
            }).then((response) => {
                if (typedata.id == "0") {
                    dispatch(getTypeAction(typedata.id));
                }
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getTypeResponse = typedata => ({
    type: ActionType.GET_TYPE_RESPONSE,
    typedata
});

export function getTypeAction(typeId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return TypeApi.saveType(typeId)
            .then(typedata => {
                dispatch(getTypeResponse(typedata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteTypeResponse = () => ({
    type: ActionType.DELETE_TYPE_RESPONSE
});

const queuedDeleteType = queueApiCall(TypeApi.deleteType);

export function deleteTypeAction(typeId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return queuedDeleteType(typeId)
            .then((response) => {
                dispatch(deleteTypeResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getTypeAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}