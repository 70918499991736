import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as FormAction from '../action/FormAction';
import * as CategoryAction from '../action/CategoryAction';
import * as LocationAction from '../action/LocationAction';
import * as SiteAction from '../action/SiteAction';
import validator from 'validator';
import constants from '../constants';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Add, OrderedList, Android, Apple, Cafeteria, FormClose, Trash } from 'grommet-icons';
import { Phone } from '../grommet-extras/PhoneIcon';
import { Yammer } from '../grommet-extras/YammerIcon';
// eslint-disable-next-line
import { Grid, Box, Heading, List, Paragraph, Split, Button, Form, FormField, TextInput, Select, CheckBox, Text, Calendar, Image, TextArea } from 'grommet';
import TextInputWithState from './common/TextInputWithState';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';

var start = undefined;
export class FormEditContainer extends React.Component {


    constructor() {
        super();
        this.parentfull = [];
        this.categoryfull = [];
        this.newname = "";
        this.newparent = "";
        this.newparentId = "";
        this.newlocation = "";

        this.locations = [
            { id: "No", name: "No" },
            { id: "GPSLocation", name: "GPS Location" },
            { id: "IndoorLocation", name: "Indoor Location" },
        ];
        this.moreinfo = [
            { display: "Choice", label: "Meal Type", opt: ["Break", "Lunch"] },
            { display: "Text", label: "Participants", opt: [] },
            { display: "TextArea", label: "Notes", opt: [] }
        ];
        this.moreinfo2 = [{ display: "Menu", url: "https://www.insel.ch/fileadmin/Inselspital/Dokumente/Gastronomie/Bankettangebot_Inselspital.pdf" }];
        this.moreinfo_err = [];
        for (let i = 0; i <= constants.MOREINFO_LINES; i += 1) this.moreinfo_err.push({ display: "", url: "" });
        this.state = {
            options: [],
            parents: [],
            value: "",
            parent: "",
            isNewCategory: false,
            isSaved: false,
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            categoriesdataLoading: true,
            locationsdataLoading: true,
            formdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Validating fields error handling
    newname_err = "";
    parent_err = "";
    name_err = "";
    description_err = "";
    categoryid_err = "";
    email_err = '';
    clearErrorMessages() {
        this.newname_err = "";
        this.parent_err = "";
        this.name_err = "";
        this.description_err = "";
        this.categoryid_err = "";
        this.email_err = '';
    }

    componentDidUpdate(prevProps, prevState) {
        //Load categories
        if (this.props.categoriesdata && this.props.sitesdata && this.props.categoriesdata.length > 0 && this.categoryfull.length == 0) {
            //console.log(this.props.categoriesdata);
            this.categoryfull = this.props.categoriesdata.slice();
            //this.categoryfull = this.categoryfull.filter(item => item.level == 2);
            this.categoryfull.push({
                id: "0",
                name: "",
                parent: "",
                location: "",
                lpn: "",
                synced: false
            });
            var sp = this.categoryfull.find(d => d.name == "Spaces" && d.level == 0);
            var spi = this.categoryfull.indexOf(sp);
            this.categoryfull.splice(spi, 1);
            var ri = this.categoryfull.find(d => d.name == "Report Issue" && d.level == 0);
            var i = this.categoryfull.indexOf(ri);
            this.categoryfull.splice(i, 1);
            var info = this.categoryfull.find(d => d.name == "Info" && d.level == 0);
            var index = this.categoryfull.indexOf(info);
            this.categoryfull.splice(index, 1);
            var ri2 = this.categoryfull.find(d => d.name == "Report Issue" && d.level == 2);
            var i2 = this.categoryfull.indexOf(ri2);
            this.categoryfull.splice(i2, 1);
            var info2 = this.categoryfull.find(d => d.name == "Info" && d.level == 2);
            var index2 = this.categoryfull.indexOf(info2);
            this.categoryfull.splice(index2, 1);
            this.categoryfull.forEach(item => item.lpn = constants.APP_LPN(item.location, item.parent, item.name));
            this.categoryfull.sort((p1, p2) => { if (p1.lpn.toLowerCase() < p2.lpn.toLowerCase()) return -1; return 1; });
            if (!this.props.sitesdata.meridianEnabled) {
                this.categoryfull = this.categoryfull.filter(c => c.synced == false);
            }
            //this.categoryfull.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });
            this.setState({ options: this.categoryfull });
        }
        //Load parent-location dictionary
        if (this.props.locationsdata[0] && this.props.locationsdata[0].count != 0 && this.props.locationsdata.length > 0 && this.parentfull.length == 0) {
            this.parentfull = this.props.locationsdata.filter((item) => item.location != "").slice();
            this.parentfull.push({
                parent: "",
                location: ""
            });
            this.parentfull.forEach(function (item) {
                if (item.parent == "" && item.location == "") {
                    item.parentloc = "";
                }
                else if (item.parent != "" && item.location == "") {
                    item.parentloc = item.parent;
                }
                else if (item.parent == item.location) {
                    item.parentloc = item.location;
                }
                else {
                    item.parentloc = item.location + " / " + item.parent;
                }
            })
            this.parentfull.sort((p1, p2) => { if (p1.parentloc.toLowerCase() < p2.parentloc.toLowerCase()) return -1; return 1; });
            this.setState({ parents: this.parentfull });
            //console.log("DidUpdate:", this.parentfull);
        }

    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.formId;
        this.props.action.getCategoriesAction()
            .then(response => this.setState({ categoriesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-FormEdit/Category Error', error);
            });
        this.props.action.getLocationsAction()
            .then(response => this.setState({ locationsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-FormEdit/Parent Error', error);
            });
        this.props.action.getFormAction(id)
            .then(response => this.setState({ formdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*-Form Error', error);
            });
        this.moreinfo = id == "f2bd4578b20549208844721fb83c52c9" ? this.moreinfo : [];
        this.moreinfo2 = id == "f2bd4578b20549208844721fb83c52c9" ? this.moreinfo2 : [];
    }

    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.formdata && !_.isEqual(start, this.props.formdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }
    updateDescription(v) {
        this.props.formdata.description = v.target.value;
        this.setState({ validating: !this.state.validating });
    }
    updateFormName(v) {
        this.props.formdata.name = v.target.value;
        if (v.target.value.length > 50) {
            this.name_err = "Name can't be longer than 50 characters";
        }
        else {
            this.name_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }
    updateEmail(v) {
        this.props.formdata.email = v.target.value;
        this.setState({ validating: !this.state.validating });
    }
    updateDate(v) {
        this.props.formdata.configureDate = v.target.checked;
        this.setState({ validating: !this.state.validating });
    }
    updateTime(v) {
        this.props.formdata.configureTime = v.target.checked;
        this.setState({ validating: !this.state.validating });
    }
    updateCategoryName(event) {
        this.props.formdata.categoryid = this.state.options[event.selected].id;
        this.setState({
            value: event.option,
            selected: event.selected,
            options: this.categoryfull
        });
        if (event.option == "No match found") {
            event.option = "";
        }
        //this.props.placemarkdata.categoryid = (this.categoryfull.find((option) => option.name == event.option)).id;
        if (this.props.formdata.category) {
            this.props.formdata.category.id = this.props.formdata.categoryid;
        };
        this.categoryid_err = "";
        //if (event.option.length == 0) {
        //    this.categoryid_err = "Select an Option";
        //}
    }
    updateNewCategoryName(event) {
        this.newname = event.target.value;
        if (this.newname.trim().length == 0) {
            this.newname_err = "Category Name is required";
        } else {
            this.newname_err = "";
        }
        this.setState({ validating: !this.state.validating });
    }

    updateCategoryParent(event) {
        if (event.option == "No match found") {
            event.option = "";
        }
        this.newparent = this.parentfull[event.selected].parent;
        this.newparentId = this.parentfull[event.selected].id;
        this.newlocation = this.parentfull[event.selected].location;
        this.setState({
            parent: event.option,
            parents: this.parentfull
        });
        //this.newparent = (this.parentfull.find((option) => option.parentloc == event.option)).parent;
        //this.newparentId = (this.parentfull.find((option) => option.parentloc == event.option)).id;
        //this.newlocation = (this.parentfull.find((option) => option.parentloc == event.option)).location;
        this.parent_err = "";
        //if (event.option.trim().length == 0) {
        //    this.parent_err = "Select an Option";
        //}
    }
    updateLocationValue(event) {
        this.props.formdata.useLocation = this.locations[event.selected].id;
        //console.log('Location:', event, this.props.formdata.useLocation);
        this.setState({ validating: !this.state.validating });
    }


    handleCancel() {
        this.saveNewCategory(false);
        if (!this.state.cancel && !_.isEqual(start, this.props.formdata)) {
            this.setState({ cancel: true });
        }
        else {
            const formId = this.props.formdata.id;
            this.props.action.getFormAction(formId)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        this.props.history.push(constants.getSiteLevelRoute() + '/pages/2');
                    }
                }).catch(error => {
                    console.log('*DEBUG*-FormEdit Error', error);
                    this.props.history.push(constants.getSiteLevelRoute() + '/pages/2');
                });
        }

    }

    handleSave() {
        const formdefault = this.props.formdata;
        //console.log('*DEBUG*-Started saving:', formdefault);
        //Validating fields
        let errorsno = 0;
        this.clearErrorMessages();
        this.setState({ validating: false });

        if (formdefault.name.trim().length == 0) {
            errorsno++;
            this.name_err = "Name is required";
        }
        if (formdefault.name.length > 50) {
            errorsno++;
            this.name_err = "Name can't be longer than 50 characters";
        }
        if (formdefault.email.trim().length == 0) {
            errorsno++;
            this.email_err = "E-mail is required";
        }
        if (formdefault.email.trim().length != 0 && !(validator.isEmail(formdefault.email))) {
            errorsno++;
            this.email_err = "E-mail is not correct";
        }
        //newCategory
        if (this.state.isNewCategory) {
            if (this.newname.trim().length == 0) {
                errorsno++;
                this.newname_err = "Category Name is required";
            }
            if (this.props.categoriesdata.find(o => o.name.trim().toLowerCase() == this.newname.trim().toLowerCase())) {
                errorsno++;
                this.newname_err = "Category Name already exists";
            }
            //if (this.newparent.trim().length == 0) {
            //    errorsno++;
            //    this.parent_err = "Select an Option";
            //}
        }
        else {
            //if (formdefault.categoryid == 0 || !this.categoryfull.find((opt) => opt.id == formdefault.categoryid)) {
            //    errorsno++;
            //    this.categoryid_err = "Select an Option";
            //}
        }
        if (errorsno == 0) {
            //Ok, saving data
            this.setState({ disabledButton: true });
            this.saveNewCategory(true);
            let locId = '';
            if (sessionStorage.getItem('locationId')) {
                locId = sessionStorage.getItem('locationId');
            }
            formdefault.locationId = locId;
            console.log('Saving:', formdefault);
            this.props.action.saveFormAction(formdefault)
                .then((response) => {
                    this.setState({ isSaved: true, isSaveSuccessful: response.ok });
                    this.categoryfull = [];
                    //this.props.action.getCategoriesAction().then(() => { /*this.props.history.push('/placemarks')*/ });
                }).catch(error => {
                    console.log('*DEBUG*-Save FormEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        } else {
            this.setState({ validating: true });
        }
    }
    saveNewCategory(isFromHandleSave) {
        const formdefault = this.props.formdata;
        if (this.state.isNewCategory) {
            // new category
            formdefault.category = {};
            formdefault.categoryid = "0";
            formdefault.category.id = null;
            formdefault.category.name = this.newname;
            formdefault.category.parent = this.newparent;
            formdefault.category.parentId = this.newparentId;
            formdefault.category.location = this.newlocation;
            formdefault.category.synced = false;
            formdefault.category.deleted = false;
            formdefault.category.locationId = sessionStorage.getItem("locationId");
            formdefault.category.organizationId = sessionStorage.getItem("organisationId");
        } else {
            // selected category
            if (isFromHandleSave) {
                formdefault.category = null;
            }

        }
    }
    onClose() {
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push(constants.getSiteLevelRoute() + '/pages/2');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }
    handleAddItem(element) {
        if (!this.moreinfo) {
            this.moreinfo = [];
        }
        this.moreinfo.push({ display: element, label: "", opt: [] });
        this.setState({ validating: !this.state.validating });
    }
    handleDelete(event, id) {
        this.moreinfo.splice(id, 1);
        this.setState({ validating: !this.state.validating });
    }
    handleDeleteOption(idx, id) {
        this.moreinfo[idx].opt.splice(id, 1);
        this.setState({ validating: !this.state.validating });
    }
    renderForm(infos) {
        return (
            <Box gap="small" >
                {infos && infos.map((info, idx) => (
                    <Box direction="row" gap="small">
                        <StyledBox style1 key={idx} direction="row" gap="medium" justify="between" pad={{ "horizontal": "small", "vertical": "xsmall" }} width="700px">
                            <Box width="100%">
                                <Box direction="row" align="start">

                                    <Box width="100%">
                                        <FormField label="Type">
                                            <TextInput id={`Info${idx}`} value={info.display} readonly/* onInput={evt => this.updateMoreInfoDisplayValue(evt, idx)}*/ />
                                        </FormField>
                                    </Box>
                                </Box>
                            </Box>
                            <Box width="100%">
                                <Box direction="row" align="start">

                                    <Box width="100%">
                                        <FormField label="Label">
                                            <TextInput id={`Label${idx}`} defaultValue={info.label} placeholder={`Label of the ${info.display}`} /*onInput={evt => this.updateMoreInfoUrlValue(evt, idx)}*/ />
                                        </FormField>
                                    </Box>
                                </Box>
                            </Box>
                            <Box width="100%">
                                {info.display == "Choice" &&

                                    <Box align="start" gap="small">
                                        <FormField label="Options">
                                            <StyledButton typeAdd label="Add option" onClick={() => { info.opt.push(/*`option ${info.opt.length+1}`*/""); this.setState({ validating: !this.state.validating }); }} />
                                        </FormField>
                                        {/*info.display == "Choice" &&*/ info.opt.map((o, idx1) => (
                                            <Box direction="row" gap="small">
                                                <TextInput placeholder={`option ${idx1 + 1}`} defaultValue={o} />
                                                <FormClose style={{ alignSelf: "center" }} onClick={evt => this.handleDeleteOption(idx, idx1)} />
                                            </Box>
                                        ))}

                                    </Box>

                                }
                            </Box>
                        </StyledBox>
                        <Box>
                            <Box /*width="xsmall"*/ >
                                <StyledButton icon={<Trash />} /*typeCancel label="Delete" */ onClick={evt => this.handleDelete(evt, idx)} />
                            </Box>
                        </Box>
                    </Box>
                ))}
                <Box direction="row" gap="small">
                    <Box /*width="medium"*/ direction="row" gap="small">
                        <StyledButton alignSelf="center" typeAdd label="Add Choice" onClick={() => this.handleAddItem("Choice")} /*disabled={this.moreinfo && this.moreinfo.length > 1}*/ />
                        <StyledButton alignSelf="center" typeAdd label="Add Text" onClick={() => this.handleAddItem("Text")} /*disabled={this.moreinfo && this.moreinfo.length > 1}*/ />
                        <StyledButton alignSelf="center" typeAdd label="Add TextArea" onClick={() => this.handleAddItem("TextArea")} /*disabled={this.moreinfo && this.moreinfo.length > 1}*/ />
                        {/*<Select placeholder="Select" options={["Text", "TextArea", "Choice"]} value={this.state.element} onChange={option => this.setState({ element: option.option })} plain/> */}
                    </Box>
                </Box>
            </Box>
        )
    }

    handleAddItem2() {
        if (!this.moreinfo2) {
            this.moreinfo2 = [];
        }
        this.moreinfo2.push({ display: "", url: "", });
        this.setState({ validating: !this.state.validating });
    }
    handleDelete2(event, id) {
        this.moreinfo2.splice(id, 1);
        this.setState({ validating: !this.state.validating });
    }
    renderMoreInfo(infos) {

        return (
            <Box gap="small" >
                {infos && infos.map((info, idx) => (
                    <Box direction="row" gap="small">
                        <StyledBox style1 key={idx} direction="row" gap="medium" justify="between" pad={{ "horizontal": "small" }} width="700px">
                            <Box width="100%">
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <DocumentText />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.moreinfo_err[idx].display} label="Display Text">
                                            <TextInputWithState id={`Info${idx}`} value={info.display} onInput={evt => info.display = evt.target.value} />
                                        </FormField>
                                    </Box>
                                </Box>
                            </Box>
                            <Box width="100%">
                                <Box direction="row" align="start">
                                    <Box pad={{ horizontal: "small", top: "xsmall" }}>
                                        <Link />
                                    </Box>
                                    <Box width="100%">
                                        <FormField error={this.moreinfo_err[idx].url} label="URL">
                                            <TextInputWithState id={`Url${idx}`} value={info.url} onInput={evt => info.url = evt.target.value} />
                                        </FormField>
                                    </Box>
                                </Box>
                            </Box>
                        </StyledBox>
                        <Box>
                            <Box width="xsmall" >
                                <StyledButton typeCancel label="Delete" onClick={evt => this.handleDelete2(evt, idx)} />
                            </Box>
                        </Box>
                    </Box>
                ))}
                <Box direction="row" gap="small">
                    <Box width="medium">
                        <StyledButton typeAdd label="Add Item" alignSelf="start" onClick={() => this.handleAddItem2()} />
                    </Box>
                </Box>
            </Box>
        )
    }

    render() {
        const { parent, parents, options, isNewCategory, categoriesdataLoading, locationsdataLoading, formdataLoading } = this.state;
        const formdefault = this.props.formdata;
        const categoriesdefault = this.props.categoriesdata;
        const sitesdata = this.props.sitesdata;

        const canedit1 = RB.canEdit(constants.PERMS.LOC_CONTENT);

        if (categoriesdataLoading || locationsdataLoading || formdataLoading || !sitesdata) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }
        const heading = formdefault && (formdefault.id != "0") ? 'Edit' : 'Create';
        const vcategory = this.categoryfull.find((opt) => opt.id == formdefault.categoryid);
        const vcategoryname = vcategory ? vcategory.name : "";
        const vcategoryparent = vcategory ? vcategory.parent : "";
        const vcategorylocation = vcategory ? vcategory.location : "";
        const vcategoryparentpluslocation = constants.APP_LPN(vcategorylocation, vcategoryparent, vcategoryname);
        const location = this.locations.find((opt) => opt.id == formdefault.useLocation);
        const vlocation = location ? location.name : "No";
        //console.log('EDIT:', pagedefault.categoryid, JSON.stringify(vcategory));
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2'>Form - {heading} </Heading>
                    <Box direction="row" gap="small">
                        <StyledButton typeCancel label="Cancel" label={constants.visible(canedit1) == "visible" ? "Cancel" : "Back"} onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        {constants.visible(canedit1) == "visible" &&
                            <StyledButton typeSave label="Save" style={{ visibility: constants.visible(canedit1) }} onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />}
                    </Box>
                </Box>
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                    <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                    <form>
                        <Box gap="medium" pad={{ "bottom": "medium" }}>
                            {/*Description*/}
                            <Box>
                                <Box>
                                    <Text>All fields marked with an * are mandatory.</Text>
                                    <Text size="large">Description</Text>
                                </Box>
                                <StyledBox style1 pad={{ "horizontal": "small" }}>
                                    <FormField label="Name *" error={this.name_err}>
                                        <TextInput defaultValue={formdefault.name} onChange={v => this.updateFormName(v)} disabled={!canedit1} />
                                    </FormField>
                                    <FormField label="Description" error={this.description_err}>
                                        <TextArea defaultValue={formdefault.description} onChange={v => this.updateDescription(v)} disabled={!canedit1} />
                                    </FormField>
                                    <FormField label="Email *" error={this.email_err}>
                                        <TextInput defaultValue={formdefault.email} onChange={v => this.updateEmail(v)} disabled={!canedit1} />
                                    </FormField>
                                    {/*<FormField label="Group">
                                    </FormField>*/}
                                </StyledBox>
                            </Box>
                            <Box>
                                <Box>
                                    <Text size="large">Categorise</Text>
                                </Box>
                                <StyledBox style1 pad={{ "horizontal": "small" }} width="600px">
                                    {!isNewCategory ? (
                                        <Box direction="row" gap="small">
                                            <Box fill>
                                                <FormField label="Category Name" error={this.categoryid_err}>
                                                    <Select width="large"
                                                        placeholder="Select a Category"
                                                        onClose={() => this.setState({ options: this.categoryfull })}
                                                        onSearch={(searchText) => {
                                                            const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                            const filtered = this.categoryfull.filter(o => o.lpn.match(regexp));
                                                            if (filtered.length > 0) {
                                                                this.setState({ options: filtered });
                                                            }
                                                            else {
                                                                this.setState({ options: [{ id: "0", name: "No match found", parent: "", location: "", lpn: "No match found" }] });
                                                            }
                                                        }}
                                                        value={vcategoryparentpluslocation}
                                                        onChange={event => this.updateCategoryName(event)}
                                                        options={options.map((option) => option.lpn)}
                                                        replace={false}
                                                        disabled={!canedit1}
                                                    />
                                                </FormField>
                                            </Box>
                                            <Box justify="start" pad={{ "top": "45px" }} height="95px">
                                                <Button plain icon={<Add />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} disabled={!canedit1} />
                                            </Box>
                                        </Box>
                                    ) : (
                                            //New Category
                                            <Box>
                                                <Box fill direction="row" border="bottom" gap="small">
                                                    <Box fill>
                                                        <FormField label="Category Name *" error={this.newname_err}>
                                                            <TextInput placeholder="New Category Name" onChange={evt => this.updateNewCategoryName(evt)} />
                                                        </FormField>
                                                    </Box>
                                                    <Box justify="start" pad={{ "top": "45px" }} height="95px">
                                                        <Button plain icon={<OrderedList />} onClick={() => { this.setState({ isNewCategory: !isNewCategory }) }} />
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <FormField label="App Location" error={this.parent_err}>
                                                        <Select
                                                            placeholder="App Location"
                                                            onClose={() => this.setState({ parents: this.parentfull })}
                                                            onSearch={(searchText) => {
                                                                const regexp = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
                                                                const filtered = this.parentfull.filter(o => o.parentloc.match(regexp))
                                                                if (filtered.length > 0) {
                                                                    this.setState({ parents: filtered });
                                                                }
                                                                else {
                                                                    this.setState({ parents: [{ parent: "No match found", location: "", parentloc: "No match found" }] });
                                                                }
                                                            }}
                                                            value={parent}
                                                            onChange={event => this.updateCategoryParent(event)}
                                                            options={parents.map((option) => option.parentloc)}
                                                            replace={false}
                                                        />
                                                    </FormField>
                                                </Box>
                                            </Box>
                                        )}
                                </StyledBox>
                            </Box>
                            <Box>
                                <Box>
                                    <Text size="large">Configure</Text>
                                </Box>
                                <StyledBox style1 pad={{ "horizontal": "small" }} direction="row" gap="small" width="140px">
                                    <Box width="50px">
                                        <FormField label="Date">
                                            <Box pad="small">
                                                <CheckBox checked={formdefault.configureDate} onChange={v => this.updateDate(v)} disabled={!canedit1} />
                                            </Box>
                                        </FormField>
                                    </Box>
                                    <Box width="50px">
                                        <FormField label="Time">
                                            <Box pad="small">
                                                <CheckBox checked={formdefault.configureTime} onChange={v => this.updateTime(v)} disabled={!canedit1} />
                                            </Box>
                                        </FormField>
                                    </Box>
                                </StyledBox>
                            </Box>
                            <Box>
                                <Box>
                                    <Text>GPS is switched off by default. If enabled, this will check for the users location when using the client app</Text>
                                </Box>
                                <StyledBox style1 pad={{ "horizontal": "small" }} width="325px">
                                    <Box width="300px">
                                        <FormField label="User Location">
                                            <Select
                                                dropAlign={{ bottom: "top" }}
                                                //options={["No", "GPS Location", "Indoor Location"]}
                                                options={this.locations.map((l) => l.name)}
                                                //value={formdefault.useLocation}
                                                onChange={v => this.updateLocationValue(v)}
                                                value={vlocation}
                                                disabled={!canedit1}
                                            />
                                        </FormField>
                                    </Box>
                                </StyledBox>
                            </Box>
                            {/*Add addtional form items*/}
                            {false && <Box>
                                <Box>
                                    <Text size="large">Add addtional form items</Text>
                                    {this.renderForm(this.moreinfo || [])}
                                </Box>
                            </Box>}
                            {/*More Information*/}
                            {false && <Box>
                                <Box>
                                    <Text size="large">More Information</Text>
                                    {this.renderMoreInfo(this.moreinfo2 || [])}
                                </Box>
                            </Box>}
                        </Box>
                    </form>
                </Box>
                {/*<Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>*/}

            </Grid >
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    const formId = ownProps.match.params.formId || ''; //from the path '/event/:eventId'
    if (state.selectedFormReducer.formdata && formId == state.selectedFormReducer.formdata.id) {
        start = JSON.parse(JSON.stringify(state.selectedFormReducer.formdata));
        return {
            categoriesdata: state.categoriesReducer.categoriesdata,
            locationsdata: state.locationsReducer.locationsdata,
            formdata: state.selectedFormReducer.formdata,
            sitesdata: state.selectedSiteReducer.sitedata
        };
    }

    else {
        return {
            /*only create test*/
            //formdata: state.selectedFormReducer.formdata,
            /*only create test*/
            categoriesdata: state.categoriesReducer.categoriesdata,
            locationsdata: state.locationsReducer.locationsdata,
            sitesdata: state.selectedSiteReducer.sitedata
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...CategoryAction, ...FormAction, ...LocationAction, ...SiteAction }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FormEditContainer));