import { SERVER } from './serverUrl';
import constants from '../constants';
import { myHeadersWithToken } from './ApiUtils';

const API_VERSION = constants.API_VERSION;
const SERVER_URL = `${SERVER}/api/image`;
/*const API_NAME = "ImageApi";*/
/*handleFetchErrors.API_NAME = API_NAME;*/

class ImageApi {

    static async uploadImage(imagefile) {
        let formData = new FormData();
        formData.append('image', imagefile);
        let headers = await myHeadersWithToken();
        headers.delete('Content-Type');
        const options = {
            method: 'post',
            mode: 'cors',
            body: formData,
            headers
        };

        return fetch(SERVER_URL, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (!response.ok) {
                    throw { status: response.status, statusText: response.statusText, url: response.url };
                }
            })
            .catch(error => {
                throw error;
            });
    }
}

//function handleFetchErrors(response) {
//    if (!response.ok) {
//        throw new Error(`${API_NAME}: status code ${response.status} - ${response.statusText}`);
//    }
//    return response;
//}


export default ImageApi;