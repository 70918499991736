import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const collectionsReducer = (state = initialState.collectionsReducer, action) => {
    switch(action.type) {
        case ActionType.GET_COLLECTIONS_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state, 
                collectionsdata: _.assign(action.collectionsdata)
            };
        }


        default: { return state; }
    }
};



export default collectionsReducer;