import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Text, TextInput, Image } from 'grommet';
import { Edit, Blank, CircleInformation, StatusDisabled, StatusDisabledSmall, StatusGoodSmall, StatusCritical } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
// import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
//import { PagingTable } from 'grommet-controls/components/PagingTable';
import constants from '../constants';
import { stat } from 'fs';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import { Context } from './Context';

const PaginatedDataTable = withPagination(DataTable);

class BuildingListTable extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.state = {
            search: "",
        };
    }

    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(constants.getSiteLevelRoute() + "/building", id);
        }
    }

    onDTUpdated() {
        if (this.context.feature.find(f => f.name == "siteWellness").isEnabled) {
            let node = this.dtwrapper.current;
            if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
                let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
                let handleEdit = this.props.handleEdit;
                [...trs].forEach((elem, idx) => {
                    let insideElements = trs[idx].getElementsByTagName('td');
                    [...insideElements].forEach((element, nro) => {
                        if (elem.getElementsByTagName('th')[0]) {
                            let tid = elem.getElementsByTagName('th')[0].textContent;
                            //element.addEventListener("click", function (evt) {
                            //    evt.preventDefault();
                            //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                            //    handleEdit("building", trs[idx].getElementsByTagName('th')[0].textContent);
                            //});
                            element.removeEventListener("click", this.externalFunc);
                            element.addEventListener("click", this.externalFunc);
                        }
                        element.addEventListener("mouseover", function (evt) {
                            evt.preventDefault();
                            elem.style.background = "#E6E6E6";
                            elem.style.cursor = 'pointer';
                        });
                        element.addEventListener("mouseout", function (evt) {
                            evt.preventDefault();
                            elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                            elem.style.cursor = 'default';
                        });
                    });
                    let insideElementsTH = trs[idx].getElementsByTagName('th');
                    [...insideElementsTH].forEach((element, nro) => {
                        element.addEventListener("mouseover", function (evt) {
                            evt.preventDefault();
                            elem.style.background = "#E6E6E6";
                            elem.style.cursor = 'pointer';
                        });
                        element.addEventListener("mouseout", function (evt) {
                            evt.preventDefault();
                            elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                            elem.style.cursor = 'pointer';
                        });
                    });
                });
            }
        }
    }
    convertCleaning(option) {
        let value = "";
        switch (option) {
            case "HOURS1":
                value = "Every 1 hour";
                break;
            case "HOURS2":
                value = "Every 2 hours";
                break;
            case "HOURS3":
                value = "Every 3 hours";
                break;
            case "HOURS4":
                value = "Every 4 hours";
                break;
            case "HOURS5":
                value = "Every 5 hours";
                break;
            case "HOURS6":
                value = "Every 6 hours";
                break;
            case "HOURS7":
                value = "Every 7 hours";
                break;
            case "HOURS8":
                value = "Every 8 hours";
                break;
            case "HOURS9":
                value = "Every 9 hours";
                break;
            case "HOURS10":
                value = "Every 10 hours";
                break;
            case "HOURS11":
                value = "Every 11 hours";
                break;
            case "HOURS12":
                value = "Every 12 hours";
                break;
            case "HOURS24":
                value = "Everyday";
                break;
        }
        return value;
    }
    calculateIcon(capacity, occupancy, percentage) {
        //var percentage = Math.floor(occupancy / capacity * 100);
        if (capacity == 0 && occupancy == 0) {
            return <Box direction="row" gap="xsmall" align="center">
                <Box align="center" justify="center" width="30px" height="30px">
                    <StatusGoodSmall color="grey" />
                </Box>
                <Text>0%</Text>
            </Box>
        }
        if (capacity == 0 && occupancy > 0) {
            return <Box direction="row" gap="xsmall" align="center">
                <Box align="center" justify="center" width="30px" height="30px">
                    <StatusCritical color="status-critical" />
                </Box>
            </Box>
        }
        if (percentage > -1 && percentage <= 75) {
            return <Box margin={{ "right": "xsmall" }}><Image width="30" height="30" src="./images/green.svg" title={constants.GREEN_LABEL} /></Box>;
        }
        if (percentage > 75 && percentage < 100) {
            return <Box margin={{ "right": "xsmall" }}><Image width="30" height="30" src="./images/yellow.svg" title={constants.YELLOW_LABEL} /></Box>;
        }
        if (percentage >= 100) {
            return <Box margin={{ "right": "xsmall" }}><Image width="30" height="30" src="./images/red.svg" title={constants.RED_LABEL} /></Box>;
        }
        else {
            return <Box direction="row" gap="xsmall" align="center">
                <Box align="center" justify="center" width="30px" height="30px">
                    <StatusGoodSmall color="grey" />
                </Box>
                <Text>0%</Text>
            </Box>
        }
    }
    renderBuildingTable(props) {
        const COLUMNS = [
            {
                property: 'lcname',
                header: 'Building',
                render: b => <Text>{b.name}</Text>
            },
            {
                property: 'capacity',
                header: 'Building Capacity',
                render: b => <Text>{b.capacity}</Text>
            },
            {
                property: 'occupancy',
                header: 'Building Occupancy',
                render: b => <Text>{b.occupancy}</Text>
            },
            {
                property: 'occupancyperc',
                header: 'Occupancy %',
                render: b => < Box direction="row" align="center">{this.calculateIcon(b.capacity, b.occupancy, b.percentage)} <Text>{b.occupancyperc}</Text></Box>
            },
            {
                property: 'schedule',
                header: 'Cleaning Schedule',
                render: b => <Text>{b.schedule}</Text>
            },
            {
                property: 'info',
                header: '',
                render: b => b.publishedfloors == 0 ?
                    <Box fill={true} align="end" alignContent="center" alignSelf="center" direction="row" justify="between">
                        <a data-for="Location" data-tip="This building contains no published floors" style={{ "height": "24px" }}>
                            <CircleInformation size="medium" color="#F04953" />
                        </a>
                        <ReactTooltip id="Location" place="left" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>
                    : (b.capacity <= b.occupancy && this.context.feature.find(f => f.name == "siteWellness").isEnabled) ?
                        <Box fill={true} align="end" alignContent="center" alignSelf="center" direction="row" justify="between">
                            <a data-for="Location" data-tip="Based on capacity, this building is now fully occupied, please restrict further access" style={{ "height": "24px" }}>
                                <CircleInformation size="medium" color="#F04953" />
                            </a>
                            <ReactTooltip id="Location" place="left" type="dark" effect="solid" arrowColor="transparent" />
                        </Box>
                        :
                        ""
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: b => <Text hidden>{b.id}</Text>
            },
            //{
            //    property: 'id',
            //    header: '',
            //    primary: true,
            //    render: b => (!RB.canEdit(constants.PERMS.LOC_ADMINISTRATION)) || b.publishedfloors.length == 0 ? <Box direction='row'><Button disabled plain style={{ cursor: 'inherit' }} icon={<Blank />} /></Box>
            //        : b.capacity <= b.occupancy ?
            //            <Box direction='row' gap="xsmall"><Button plain onClick={() => this.props.handleEdit("building", b.id)} icon={<Edit color="#f04953" />} /></Box> 
            //            :
            //            <Box direction='row' gap="xsmall"><Button plain onClick={() => this.props.handleEdit("building", b.id)} icon={<Edit />} /></Box>
            //}
        ];

        function removeColumn(propertyName) {
            if (COLUMNS.findIndex(c => c.property == propertyName) != -1) {
                COLUMNS.splice(COLUMNS.findIndex(c => c.property == propertyName), 1);
            }
        }

        if (!(this.context.feature.find(f => f.name == "siteWellness").isEnabled)) {
            removeColumn("capacity");
            removeColumn("occupancy");
            removeColumn("occupancyperc");
            removeColumn("schedule");
        }

        let DATA = [];
        //props.buildingsdata.sort((p1, p2) => { if (p1.name.toLowerCase() < p2.name.toLowerCase()) return -1; return 1; });

        if (props.buildingsdata[0] && props.buildingsdata[0].count != 0) {
            props.buildingsdata.forEach((ev, id) => {
                let frPublished = [];
                let frUnpublished = [];

                props.levelsdata.forEach((lv, id) => {
                    if ((ev.id == lv.mergedBuildingId || ev.meridianId == lv.meridianId) && lv.published) {
                        frPublished.push({
                            ...lv
                        });
                    }

                    if ((ev.id == lv.mergedBuildingId || ev.meridianId == lv.meridianId) && !lv.published) {
                        frUnpublished.push({
                            ...lv
                        });
                    }
                });

                var perc = ev.occupancy / ev.capacity * 100;
                DATA.push({
                    ...ev,
                    lcname: ev.name ? ev.name.toLowerCase() : '',
                    occupancyperc: isNaN(perc) ? "" : isFinite(perc) ? Math.floor(perc) + "%" : "100%+",
                    schedule: this.convertCleaning(ev.cleaningSchedule),
                    percentage: isNaN(perc) ? -1 : Math.floor(perc),
                    unpublishedfloors: frUnpublished,
                    publishedfloors: frPublished
                });
            });
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.name.toLowerCase().includes(search) ||
                d.capacity.toString().toLowerCase().includes(search) ||
                d.occupancy.toString().toLowerCase().includes(search) ||
                d.occupancyperc.toString().toLowerCase().includes(search) ||
                d.schedule.toLowerCase().includes(search) ||
                (("error".includes(search) || "info".includes(search)) && d.capacity <= d.occupancy)
            );
        }

        console.log('DATA:', DATA, props.levelsdata);

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lcname" || c.property === "capacity" || c.property === "occupancy" || c.property === "occupancyperc" || c.property === "schedule"
                }))}
                data={DATA}
                noLoadingMsg={this.props.buildingsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'lcname', label: 'Building' },
                //    { col: 'capacity', label: 'Building Capacity' },
                //    { col: 'occupancy', label: 'Building Occupancy' },
                //    { col: 'occupancyperc', label: 'Occupancy %' },
                //    { col: 'schedule', label: 'Cleaning Schedule' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                onUpdated={this.onDTUpdated.bind(this)}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }


    render() {
        return (
            <StyledBox ref={this.dtwrapper} style={{ "min-width": "950px" }}>
                {this.renderBuildingTable(this.props)}
            </StyledBox>
        );
    }
}

export default BuildingListTable;