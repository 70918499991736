import * as ActionType from './ActionType';
import SpotsApi from '../api/SpotsApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';


export const getSpotsResponse = spotsdata => ({
    type: ActionType.GET_SPOTS_RESPONSE,
    spotsdata
});

export function getSpotsAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return SpotsApi.getAllSpots()
            .then(spotsdata => {
                dispatch(getSpotsResponse(spotsdata));
                return spotsdata;
            }).catch(error => {
                throw error;
            });
    };
}