import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image } from 'grommet';
import { Filter, StatusGoodSmall } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import RB from './common/RBAC';
import { CSVLink } from "react-csv";
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";

const PaginatedDataTable = withPagination(DataTable);

class SpotsListTable extends Component {

    constructor(props) {
        super(props);
        this.DATA = [];
    }

    render() {
        const canEdit = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        this.DATA = [];
        //this.props.spotsdata.sort((p1, p2) => { if (p1.state.id < p2.state.id) return -1; return 1; });

        if (this.props.spotsdata[0] && this.props.spotsdata[0].count != 0) {
            this.props.spotsdata.forEach(ev => {
                var sync = "No Data";
                if (ev.updatedAt) {
                    var date = new Date(ev.updatedAt);
                    sync =
                        ("00" + date.getDate()).slice(-2) + "-" +
                        ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
                        date.getFullYear() +
                        " " +
                        ("00" + date.getHours()).slice(-2) + ":" +
                        ("00" + date.getMinutes()).slice(-2);
                        sync.replace("T", " ").replace("Z", "")
                }
                this.DATA.push({
                    ...ev,
                    lcstatus: ev.state.id == 1 ? 2 : ev.state.id == 2 ? 3 : ev.state.id == 3 ? 1 : ev.state.id == 4 ? 4 : ev.state.id == 5 ? 5 : 999,
                    lcname: ev.name != null ? ev.name.toLowerCase() : "",
                    lcsmartspacename: ev.surface.name != null ? ev.surface.name.toLowerCase() : "",
                    lcsmartzonelevel: ev.surface.zone.name != null ? ev.surface.zone.name.toLowerCase() : "",
                    lcsmartvenue: ev.surface.zone.venue.name != null ? ev.surface.zone.venue.name.toLowerCase() : "",
                    lclastheard: sync
                })
            });
        }

        this.DATA.sort((p1, p2) => {
            if (p1.lcstatus < p2.lcstatus) return -1;
            if (p1.name < p2.name) return -1;
            return 0;
        });
        console.log('DATA:', this.DATA);

        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={
                    [
                        { name: 'header', start: [0, 0], end: [1, 0] },
                        { name: 'main', start: [0, 1], end: [0, 1] },
                        { name: 'side', start: [1, 0], end: [1, 1] },
                    ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Spots</Heading>

                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" gap="small">
                    <Box>
                        Lists of all spots associated with this site, including Zones, Surfaces and Spots.<br />
                        Online/Offline status shows the current state of each spot. For any spots displaying an offline status you must inspect the physical spot to diagnose the issue.
                    </Box>
                    <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }}>
                        {this.renderUsersTable(this.props, canEdit)}
                    </StyledBox>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }

    renderUsersTable(props, canEdit) {

        const COLUMNS = [
            {
                property: 'id',
                header: <Box justify="center" height="100%">Status</Box>,
                //primary: true,
                render: s =>
                    <Box justify="center">
                        {(s.state.id == 1 &&
                            <a data-for="Online" data-tip={"Online (and available for use)"}>
                                <Box>
                                    <StatusGoodSmall color="#17EBA0"/>
                                </Box>
                            </a>
                        )}

                        {(s.state.id == 2 &&
                            //<a data-for="red" data-tip={"Offline - Last Heard " + s.lclastheard}>
                            <a data-for="Offline" data-tip={"Offline (may be available for use if an internet connectivity outage)"}>
                                <Box>
                                    <StatusGoodSmall color="#FFEB59"/>
                                </Box>
                            </a>
                        )}

                        {(s.state.id == 3 &&
                            <a data-for="InUse" data-tip={"In Use (currently in-use)"}>
                                <Box>
                                    <StatusGoodSmall color="#00C8FF"/>
                                </Box>
                            </a>
                        )}

                        {(s.state.id == 4 &&
                            <a data-for="LostPower" data-tip={"Lost power (not available for use)"}>
                                <Box>
                                    <StatusGoodSmall color="#FC6161"/>
                                </Box>
                            </a>
                        )}

                        {(s.state.id == 5 &&
                            <a data-for="Uncofigured" data-tip={"Unconfigured (not setup on the Chargifi Platform yet, may be available for use)"}>
                                <Box>
                                    <StatusGoodSmall color="#82FFF2"/>
                                </Box>
                            </a>
                        )}
                        <ReactTooltip id="Online" place="left" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="Offline" place="left" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="InUse" place="left" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="LostPower" place="left" type="dark" effect="solid" arrowColor="transparent" />
                        <ReactTooltip id="Uncofigured" place="left" type="dark" effect="solid" arrowColor="transparent" />
                    </Box>,
            },
            {
                property: 'lcname',
                header: 'Spot Name',
                render: s => <Text>{s.name}</Text>
            },
            {
                property: 'lcsmartspacename',
                header: 'Spot Surface',
                render: s => <Text>{s.surface.name}</Text>
            },
            {
                property: 'lcsmartzonelevel',
                header: 'Spot Level',
                render: s => <Text>{s.surface.zone.name}</Text>
            },
            {
                property: 'lcsmartvenue',
                header: 'Spot Venue',
                render: s => <Text>{s.surface.zone.venue.name}</Text>
            },
        ];

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    search: c.property === "lcname" || c.property === "lcsmartspacename" || c.property === "lcsmartzonelevel" || c.property === "lcsmartvenue"
                }))}
                data={this.DATA}
                noLoadingMsg={this.props.spotsdata.length != 0}
                //sortable
                sortoptions={[
                    { col: 'lcstatus', label: 'Status' },
                    { col: 'lcname', label: 'Spot Name' },
                    { col: 'lcsmartspacename', label: 'Spot Surface' },
                    { col: 'lcsmartzonelevel', label: 'Spot Level' },
                    { col: 'lcsmartvenue', label: 'Spot Venue' },
                ]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
            />
        );
    }

}

export default SpotsListTable;
