import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image, Select, CheckBox } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, Connect, Tag, FormClose } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import constants from '../constants';
import RB from './common/RBAC';
import { CSVLink } from "react-csv";
import { Battery, BatteryAmber, BatteryEmpty, BatteryRed, BatteryUnknown } from '../grommet-extras/Battery';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import PrimaryFilter from "./common/PrimaryFilter";


const PaginatedDataTable = withPagination(DataTable);
class OrgAssociationListTable extends Component {

    constructor(props) {
        super(props);
        this.dtwrapper = React.createRef();
        this.DATA = [];
        this.Exp_Data = [];
        this.state = {
            search: "",
            isFilterOpen: false,
            selectedTag: "",
            selectedTracker: ""
        };
    }
    filterData(data) {
        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            data = data.filter(d =>
                d.assetId.toLowerCase().includes(search) ||
                d.beaconId.toLowerCase().includes(search) ||
                d.lcAssetName.includes(search) ||
                d.lcBeaconName.includes(search) ||
                d.created.includes(search)
            );
        }
        return data;
    }
    render() {
        const canEdit = RB.canEdit(constants.PERMS.LOC_ADMINISTRATION);
        this.DATA = [];
        this.Exp_Data = [];
        if (this.props.associationsdata[0] && this.props.associationsdata[0].count != 0) {
            this.props.associationsdata.forEach(ev => {
                this.DATA.push({
                    ...ev,
                    lcAssetName: ev.assetName ? ev.assetName.toLowerCase() : "",
                    lcBeaconName: ev.beaconName ? ev.beaconName.toLowerCase() : "",
                    created: ev.mergedAt ? constants.formatDate(ev.mergedAt) : ""
                    //lastheard: ev.timestamp ? this.formatDate(ev.timestamp) : "",
                })
                this.Exp_Data.push({
                    //"ID": ev.id,
                    "Tracker ID": ev.assetId,
                    "Tracker Name": ev.assetName,
                    "Tag Name": ev.beaconName,
                    "Tag ID": ev.beaconId,
                    "Created At (UTC)": ev.mergedAt
                    //"Last Heard": ev.timestamp ? this.formatDate(ev.timestamp) : "",
                });
            });
        }
        this.DATA = this.filterData(this.DATA);
        this.DATA.sort(constants.byPropertyCalled("assetName"));
        this.Exp_Data.sort(constants.byPropertyCalled("Tracker Name"));

        return (
            <Box /*gap="medium" pad={{ "top": "small" }}*/>
                {/*<PrimaryFilter*/}
                {/*    placeholder="Search by Tracker ID, name, Tag ID, name"*/}
                {/*    search={this.state.search}*/}
                {/*    onChange={e => this.setState({ search: e.target.value })}*/}
                {/*    csvdata={this.Exp_Data}*/}
                {/*    downloadable*/}
                {/*>*/}
                {/*</PrimaryFilter>*/}
                <StyledBox style={{ "min-width": "990px" }}>
                    {this.renderAssetsTable(this.props, canEdit, "")}
                </StyledBox>
            </Box>
        );
    }

    handleExport(evt) {
        console.log('Export to CSV', this.csvLink);
        this.csvLink.link.click();
    }
    renderAssetsTable(props, canEdit, sync) {

        const COLUMNS = [
            //{
            //    property: 'id',
            //    header: '',
            //    primary: true,
            //    render: t => {
            //        let deleteItem = {
            //            assetId: t.assetId,
            //            assetName: t.assetName,
            //            beaconId: null,
            //            beaconName: null
            //        };
            //        return !RB.canEdit(constants.PERMS.LOC_CONTENT) ?
            //            <Box direction='row'><Button plain style={{ cursor: 'inherit' }} icon={<Blank />} /></Box>
            //            : <Box direction='row' gap="xsmall">
            //                <Button plain /*disabled={props.deletedLabelIds.includes(labeldata.id)}*/ onClick={evt => props.handleDelete("association", deleteItem)} icon={<Trash />} />
            //            </Box>
            //    },
            //},
            {
                property: 'assetId',
                header: 'Tracker ID',
                render: t => <Text>{t.assetId}</Text>
            },
            {
                property: 'lcAssetName',
                header: 'Tracker Name',
                render: t => <Text>{t.assetName}</Text>
            },
            {
                property: 'lcBeaconName',
                header: 'Tag Name',
                render: t => <Text>{t.beaconName}</Text>
            },
            {
                property: 'beaconId',
                header: 'Tag ID',
                render: t => <Text>{t.beaconId}</Text>
            },
            {
                property: 'mergedAt',
                header: 'Date Created',
                render: t => <Text>{t.created}</Text>
            },
            {
                property: 'id',
                header: '',
                primary: true,
                plain: true,
                render: t => {
                    let deleteItem = {
                        assetId: t.assetId,
                        assetName: t.assetName,
                        beaconId: null,
                        beaconName: null
                    };
                    return !RB.canEdit(constants.PERMS.ORG_ADMINISTRATION) ?
                        <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, t.id)} icon={<Blank />} /></Box>
                        :
                        <Box width="24px" direction='row' gap="xsmall">
                            <Button plain disabled={props.deletedAssociationIds.includes(t.assetId)} onClick={evt => props.handleDelete("association", deleteItem)} icon={<Trash />} />
                        </Box>
                },
            },

        ];

        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "assetId" || c.property === "lcAssetName" || c.property === "beaconId" || c.property === "lcBeaconName"
                }))}
                data={this.DATA}
                noLoadingMsg={this.props.associationsdata.length != 0}
                sortable
                //sortoptions={[
                //    { col: 'assetId', label: 'Tracker ID' },
                //    { col: 'lcAssetName', label: 'Tracker Name' },
                //    { col: 'lcBeaconName', label: 'Tag Name' },
                //    { col: 'beaconId', label: 'Tag ID' },
                //    { col: 'mergedAt', label: 'Date Created' },
                //]}
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                //renderExtraHeader={() => <Text>Last updated at: {sync}</Text>}
                //downloadable={true}
                //exportdata={this.Exp_Data}
                styled={true}

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}
                //PFonClick={() => this.setState({ isFilterOpen: true })}
                //PFopen={this.state.isFilterOpen}
                //PFonClose={() => this.setState({ isFilterOpen: false })}
                //PFonResetFilter={() => this.setState({
                //    selectedBuildingId: "", selectedBuilding: "", floors: [], selectedFloor: "", selectedFloorId: "", level: "", levelId: "",
                //    collection: "", collectionId: "", departmentId: "", department: "", status: "", statusId: ""
                //})}
                //PFisClearVisible={(
                //    this.state.selectedBuilding == "" &&
                //    this.state.level == "" &&
                //    this.state.collection == "" &&
                //    this.state.department == "" &&
                //    this.state.status == "") ? false : true}
                PFcsvdata={this.Exp_Data}
                //PFFileters={this.renderFilters()}
                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={this.DATA.length > 0 ? 1 : 2}
            />
        );
    }

}

export default OrgAssociationListTable;