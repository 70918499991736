import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedPlacemarkHistoryReducer = (state = initialState.selectedPlacemarkHistoryReducer, action) => {
    switch (action.type) {

        case ActionType.GET_PLACEMARK_HISTORY_RESPONSE: {
            // '...' spread operator clones the state
            return {
                ...state,
                placemarkhistorydata: _.assign(action.placemarkhistorydata)
            };
        }

        default: { return state; }
    }
};


export default selectedPlacemarkHistoryReducer;