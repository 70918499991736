import React from "react";
export const Context = React.createContext({
    setPlacemark: () => { },

    mapsEnabled: true,
    wayfindingEnabled: true,
    assetTrackingEnabled: true,
    personTrackingEnabled: true,
    findAFriendEnabled: true,
    chargifiEnabled: true,
    buildingManagementEnabled: true,
    contentEnabled: true,
    eventsEnabled: true,
    bookADeskEnabled: true,
    bookARoomEnabled: true,
    meetingsEnabled: true,
    notificationsEnabled: true,

});