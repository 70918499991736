import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';



const manufacturersReducer = (state = initialState.manufacturersReducer, action) => {
    switch(action.type) {
        case ActionType.GET_MANUFACTURERS_RESPONSE: {
            return {
                ...state, 
                manufacturersdata: _.assign(action.manufacturersdata)
            };
        }


        default: { return state; }
    }
};



export default manufacturersReducer;