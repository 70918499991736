import * as ActionType from './ActionType';
import SpaceApi from '../api/SpaceApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';


export const getSpacesResponse = spacesdata => ({
    type: ActionType.GET_SPACES_RESPONSE,
    spacesdata
});

export function getSpacesAction() {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return SpaceApi.getAllSpaces()
            .then(spacesdata => {
                dispatch(getSpacesResponse(spacesdata));
                return spacesdata;
            }).catch(error => {
                throw error;
            });
    };
}