import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Text } from 'grommet';
import { Filter, Edit, Close, Search, Trash, ThemeContext as IconThemeContext} from 'grommet-icons';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class ProfileListTable extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <StyledBox style1 pad={{"horizontal":"small"}}>
                {renderProfilesTable(this.props)}
            </StyledBox>
        );
    }
}

function renderProfilesTable(props) {

    const COLUMNS = [
        {
            property: 'id',
            header: '',
            primary: true,
            plain: true,
            render: users => <Box direction='row'>
            <Button plain disabled={props.deletedProfileIds.includes(users.id)} onClick={evt => props.handleDelete(evt, users.id)} icon={<Trash />} />
            </Box>,
        },
        //{
        //    property: 'lcusername',
        //    header: 'Username',
        //    render: users => <Text>{users.username}</Text>
        //},
        {
            property: 'lcemail',
            header: 'Email',
            render: users => <Text>{users.fullName}</Text>
        },
    ];

    let DATA = [];

    if (props.profilesdata[0] && props.profilesdata[0].count != 0) {
        props.profilesdata.forEach(ev => {
            DATA.push({ ...ev, /*lcusername: ev.username.toLowerCase(),*/ lcemail: ev.fullName.toLowerCase()})
        });
    }

    //console.log('DATA:',DATA);

    return (
        <PaginatedDataTable
            bg = {constants.BOX_BACKGROUND_COLOR_1}
            columns={COLUMNS.map(c => ({
                ...c,
                search: c.property === "lcusername" || c.property === "lcemail"
            }))}
            data={DATA}
            noLoadingMsg={props.profilesdata.length != 0}
            //sortable
            sortoptions={[
                { col: 'lcemail', label: 'Email' },
            ]}
            resizeable={false}
            displayrows={constants.PAGE_SIZE}
        />
    );
}

export default ProfileListTable;
