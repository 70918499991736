import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import { Grid, Box, Button, CheckBox, Heading, DataTable, Text, TextInput } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, FormSearch } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
// import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
//import { PagingTable } from 'grommet-controls/components/PagingTable';
import constants from '../constants';
import { stat } from 'fs';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactiveCheckBoxes from './common/CheckBoxFilters';

const PaginatedDataTable = withPagination(DataTable);

class NotificationListTable extends Component {
    constructor(props) {
        super(props);
        this.timeRanges = {
            boxes: ['Sent', 'Scheduled',],
            default: ['Sent', 'Scheduled'],
        };
        this.dtwrapper = React.createRef();
        this.state = {
            filter: false,
            showSent: true,
            showScheduled: true,
            timeFilters: this.timeRanges.default,
            search: "",
        }
    }

    onCheckBoxUpdated = filters => {
        if (this.state.timeFilters.length != filters.length || !filters.every(e => this.state.timeFilters.includes(e))) {
            this.setState({ timeFilters: filters });
        }
    }
    timeFilterEvent(event) {
        if (this.state.timeFilters.length === 3) {
            return true;
        }
        const now = new Date();
        const notidate = new Date(event.schedule);
        const filters = this.state.timeFilters;
        let validDate = false;
        if (filters.includes("Sent")) {
            validDate = validDate || notidate < now;
        }
        if (filters.includes("Scheduled")) {
            validDate = validDate || now < notidate;
        }
        return validDate;
    }
    componentDidMount() {
        this.onDTUpdated();
    }
    componentDidUpdate() {
        this.onDTUpdated();
    }

    externalFunc = (evt) => {
        let element = evt.target;
        //Search for the TR (table-row) in the hierarchy to get the item's ID
        while (element.nodeName != "TR") {
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                //we have problem
                break;
            }
        }
        let id = element.getElementsByTagName('th')[0] ? element.getElementsByTagName('th')[0].textContent : null;
        if (id != null) {
            let hE = this.props.handleEdit;
            hE(evt, id);
        }
    }

    onDTUpdated() {
        let node = this.dtwrapper.current;
        if (node.getElementsByTagName && node.getElementsByTagName('tbody')[0]) {
            let trs = node.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
            let handleEdit = this.props.handleEdit;
            [...trs].forEach((elem, idx) => {
                let insideElements = trs[idx].getElementsByTagName('td');
                [...insideElements].forEach((element, nro) => {
                    if (elem.getElementsByTagName('th')[0]) {
                        let tid = elem.getElementsByTagName('th')[0].textContent;
                        //element.addEventListener("click", function (evt) {
                        //    evt.preventDefault();
                        //    console.log(`****** record ${idx} clicked, siteID: ${tid} ******`);
                        //    handleEdit(evt, trs[idx].getElementsByTagName('th')[0].textContent);
                        //});
                        element.removeEventListener("click", this.externalFunc);
                        element.addEventListener("click", this.externalFunc);
                    }
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'default';
                    });
                });
                let insideElementsTH = trs[idx].getElementsByTagName('th');
                [...insideElementsTH].forEach((element, nro) => {
                    element.addEventListener("mouseover", function (evt) {
                        evt.preventDefault();
                        elem.style.background = "#E6E6E6";
                        elem.style.cursor = 'pointer';
                    });
                    element.addEventListener("mouseout", function (evt) {
                        evt.preventDefault();
                        elem.style.background = constants.BOX_BACKGROUND_COLOR_1;
                        elem.style.cursor = 'pointer';
                    });
                });
            });
        }
    }

    convertDateToUTC2(date) {
        let utcDate = new Date(date);
        return new Date(
            utcDate.getUTCFullYear(),
            utcDate.getUTCMonth(),
            utcDate.getUTCDate(),
            utcDate.getUTCHours(),
            utcDate.getUTCMinutes(),
            utcDate.getUTCSeconds(),
            utcDate.getUTCMilliseconds(),
        );
    }

    render() {
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>

                    <Heading level='2' >Notifications</Heading>
                    <Box direction="row" gap="small" >

                        <StyledButton typeSave label='Create New' style={{ visibility: constants.visible(RB.canEdit(constants.PERMS.ORG_ADMINISTRATION)) }} alignSelf="center" onClick={this.props.handleNew} />
                    </Box>
                </Box>


                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <IconThemeContext.Extend value={galwayIconTheme}>
                        <StyledBox ref={this.dtwrapper} style={{ "min-width": "1090px" }}>
                            {this.renderNotificationsTable(this.props, this.timeRanges, this.onCheckBoxUpdated, this.timeFilterEvent.bind(this))}
                        </StyledBox>
                    </IconThemeContext.Extend>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR} />
            </Grid>

        );
    }

    renderNotificationsTable(props, timeRanges, onCheckBoxUpdated, timeFilterEvent) {
        const customDateFormatter = new Intl.DateTimeFormat('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        const COLUMNS = [
            {
                property: 'lctitle',
                header: <Box justify="center" height="100%">Title</Box>,
                render: notification => <Text>{notification.title}</Text>
            },
            {
                property: 'lcsubtitle',
                header: <Box justify="center" height="100%">Subtitle</Box>,
                render: notification => <Text>{notification.subtitle}</Text>
            },
            {
                property: 'typeName',
                header: <Box justify="center" height="100%">Type</Box>,
                render: notification => <Text>{notification.typeName}</Text>
            },
            //{
            //    property: 'lcdate',
            //    header: <Box justify="center" height="100%">Date</Box>,
            //    render: notification => <Text>{notification.schedule}</Text>
            //},
            {
                property: 'date',
                header: <Box justify="center" height="100%">Date & Time (UTC)</Box>,
                render: notification => customDateFormatter.format(Date.parse(this.convertDateToUTC2(notification.date))),
            },
            {
                property: 'idtag',
                header: '',
                primary: true,
                size: "0px",
                plain: true,
                render: notification => <Text hidden>{notification.id}</Text>,
            },
            {
                property: 'id',
                header: '',
                primary: true,
                size: "24px",
                plain: true,
                render: notification => (notification.synced || !RB.canEdit(constants.PERMS.LOC_CONTENT)) ?
                    <Box width="24px"><Button plain onClick={evt => props.handleEdit(evt, notification.id)} icon={<Blank />} /></Box>
                    :
                    <Box direction='row' gap="xsmall" width="24px">
                        {/*<Button plain disabled={props.deletedNotificationIds.includes(notification.id)} onClick={evt => props.handleEdit(evt, notification.id)} icon={<Edit />} />*/}
                        <Button plain disabled={props.deletedNotificationIds.includes(notification.id)} onClick={evt => props.handleDelete(evt, notification.id)} icon={<Trash />} />
                    </Box>,
            },
        ];


        let DATA = [];

        if (props.notificationsdata[0] && props.notificationsdata[0].count != 0) {
            props.notificationsdata.filter(timeFilterEvent).forEach(ev => {
                DATA.push({
                    ...ev,
                    lctitle: ev.title ? ev.title.toLowerCase() : '',
                    lcsubtitle: ev.subtitle ? ev.subtitle.toLowerCase() : '',
                    typeName: ["Notification", "News", "Security", "Meet And Greet", "Space"][ev.type - 1],
                    date: ev.schedule ? ev.schedule : ev.createdAt
                })
            });
            DATA.sort(constants.byPropertyCalled("date", "desc"));
        }

        if (this.state.search) {
            var search = this.state.search.toLowerCase();
            DATA = DATA.filter(d =>
                d.title.toLowerCase().includes(search) ||
                d.subtitle.toLowerCase().includes(search) ||
                d.typeName.toLowerCase().includes(search) ||
                customDateFormatter.format(this.convertDateToUTC2(Date.parse(d.date))).toString().toLowerCase().includes(search)
            );
        }

        console.log('DATA:', DATA);
        return (
            <PaginatedDataTable
                bg={constants.BOX_BACKGROUND_COLOR_1}
                columns={COLUMNS.map(c => ({
                    ...c,
                    //search: c.property === "lctitle" || c.property === "lcsubtitle" || c.property === "type"
                }))}
                data={DATA}
                noLoadingMsg={this.props.notificationsdata.length != 0}
                //sortoptions={[
                //    {col: 'lctitle', label: 'Title'},
                //    { col: 'lcsubtitle', label: 'Subtitle' },
                //    { col: 'typeName', label: 'Type' },
                //    { col: 'date', label: 'Date' },
                //    ]}
                sortable
                resizeable={false}
                displayrows={constants.PAGE_SIZE}
                //renderExtraHeader={() => 
                //    <Box direction="row" justify="between" gap="medium">
                //        <Box direction="row" align="center" gap="xsmall">
                //            <CheckBox checked={this.state.showSent && this.state.showScheduled} onChange />
                //            <Text>All</Text>
                //        </Box>
                //        <Box direction="row" align="center" gap="xsmall">
                //            <CheckBox checked={this.state.showSent} onChange/>
                //            <Text>Sent</Text>
                //        </Box>
                //        <Box direction="row" align="center" gap="xsmall">
                //            <CheckBox checked={this.state.showScheduled} onChange/>
                //            <Text>Scheduled</Text>
                //        </Box>
                //    </Box>}
                renderExtraHeader={() => <ReactiveCheckBoxes checkboxes={timeRanges} onUpdate={onCheckBoxUpdated} />}
                onUpdated={this.onDTUpdated.bind(this)}
                styled

                PFplaceholder="Search"
                PFsearch={this.state.search}
                PFonChange={e => this.setState({ search: e.target.value })}
                PFonClear={e => this.setState({ search: "" })}

                PFcsvdata={[]}

                PFsearchable={true}
                PFfilterable={false}
                PFdownloadable={0}
            />
        );
    }
}

export default NotificationListTable;
