import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CategoryAction from '../action/CategoryAction';
import * as LocationAction from '../action/LocationAction';
import * as NotificationAction from '../action/NotificationAction';
import * as SiteAction from '../action/SiteAction';
// eslint-disable-next-line
import validator from 'validator';
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, CircleInformation, Trash, Upload } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, DateInput, Select, Image, CheckBox, Grommet } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import PopUpConfirm from './common/PopUpConfirm';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import CustomOption from './common/CustomOption';
import ImageApi from '../api/ImageApi';
import { imageCheck } from './common/ImageCheck';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import ReactTooltip from "react-tooltip";
import LoadingIndicator from './common/LoadingIndicator';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
Quill.register(Quill.import('attributors/style/align'), true);


var start = undefined;
var base64 = null;

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={customTheme}> 
    formField: {
        border: {
            color: "transparent",
        },
        pad: "none"
    }
});

export class NotificationEditContainer extends React.Component {
    constructor() {
        super();
        this.stamp = Date.now().toString();
        this.d = new Date();
        this.d.setMinutes(this.d.getMinutes() + 1, 0, 0);
        this.lnow = this.d.toISOString();
        this.whenFormCreated = this.convertDateFromUTC(this.lnow);
        this.confirmMessage = "";
        this.isTagsCopied = false;
        this.state = {
            file: "",
            newimage: false,
            imagechanged: false,
            removeIconOpen: false,
            options: [],
            selected: "",
            location: "",
            isSaved: false,
            open: false,
            notification: '',
            disabledButton: false,
            cancel: true,
            isSaveSuccessful: false,
            showDate: false,
            showPopUpSent: false,
            showPopUpScheduled: false,
            scheduleValue: "Send Now",
            scheduleId: "",
            typeValue: "",
            showConfirmPopUp: false,
            selectedSites: [],
            isDateTimeUpdated: false,
            saveButtonText: "Send",
            notificationdataLoading: true,
            sitesdataLoading: true,
            gotProps: false,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    editorURLs = [];
    //Validating errors
    categoryName_err = "";
    categoryParent_err = "";
    schedule_err = "";
    date_err = "";
    time_err = "";
    type_err = "";
    title_err = "";
    subtitle_err = "";
    detail_err = "";
    image_err = "";

    ClearErrorMessages() {
        this.categoryName_err = "";
        this.categoryParent_err = "";
        this.schedule_err = "";
        this.date_err = "";
        this.time_err = "";
        this.type_err = "";
        this.title_err = "";
        this.subtitle_err = "";
        this.detail_err = "";
        this.image_err = "";
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.notificationId;
        this.props.action.getSitesAction()
            .then(response => this.setState({ sitesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getNotificationAction(id)
            .then(response => this.setState({ notificationdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Notification Error', error);
            });
    }

    componentWillReceiveProps(props) {
        console.log("cwrp", props);
        const { notificationdata, sitesdata } = props;
        //if (props.notificationdata && !this.state.utcdate) {
        //    // convert date from UTC
        //    if (props.notificationdata.id === "0") {
        //        props.notificationdata.schedule = /*this.convertDateFromUTC(this.now)*/this.now;
        //        //this.props.notificationdata.date = this.now;
        //    }
        //    this.setState({ utcdate: true });
        //}
        if (notificationdata && notificationdata.schedule) {
            if (!this.gotProps) {
                this.gotProps = true;
                this.value = this.convertDateFromUTC(notificationdata.schedule);
            }
            notificationdata.schedule = this.value;
            this.setState({ showDate: true, scheduleValue: "Schedule for another time", saveButtonText: "Schedule" });
        }
        if (notificationdata && sitesdata && !this.isTagsCopied) {
            let list = [];
            sitesdata.forEach(s => {
                if (notificationdata.tags.includes(`loc:${s.code.toLowerCase()}`)) {
                    list.push(s);
                }
            });
            this.setState({ selectedSites: list });
            this.isTagsCopied = true;
        }
        console.log("DATE1: ", this.state.scheduleValue);
    }

    componentWillUnmount() {
        this.historyUnblock();
    }

    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && this.props.notificationdata && !_.isEqual(start, this.props.notificationdata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    handleValidation() {
        let errorsno = 0;
        const notificationdefault = this.props.notificationdata;
        this.ClearErrorMessages();
        this.setState({ validating: false, notification: "" });
        let selected = new Date(this.props.notificationdata.schedule ? this.props.notificationdata.schedule : this.whenFormCreated);
        let current = new Date(this.whenFormCreated/*.slice(0, -1)*/);
        selected = this.convertDateToUTC(selected);
        current = this.convertDateToUTC(current);
        console.log("datecmp", current, "\n", selected);
        if (this.state.scheduleValue == "") {
            errorsno++;
            this.schedule_err = "Schedule is required";
        }
        if (this.state.scheduleId == 1 && notificationdefault.schedule.substr(11, 5) === "aaaaa") {
            errorsno++;
            this.date_err = "Date is required";
        }
        if (this.state.showDate && this.state.isDateTimeUpdated && selected < current) {
            //issue with the selected date
            this.date_err = "Can't select date from past";
            this.time_err = "Can't select time from past";
        }
        //} else if (selected < current) {
        //    this.date_err = "Can't select date from past";
        //}
        if (this.state.scheduleId == 1 && notificationdefault.schedule.substr(0, 10) === "aaaaaaaaaa") {
            errorsno++;
            this.time_err = "Time is required";
        }
        //} else if (selected < current) {
        //    this.time_err = "Can't select date from past";
        //}
        if (!(notificationdefault.type == 1 || notificationdefault.type == 2 || notificationdefault.type == 3 || notificationdefault.type == 4 || notificationdefault.type == 5)) {
            errorsno++;
            this.type_err = "Type is required";
        }
        if (notificationdefault.title.trim().length == 0) {
            errorsno++;
            this.title_err = "Title is required";
        }
        if (notificationdefault.title.length > 30) {
            errorsno++;
            this.title_err = "Title can't be longer than 30 character";
        }
        if (notificationdefault.subtitle.trim().length == 0) {
            errorsno++;
            this.subtitle_err = "Subtitle is required";
        }
        if (notificationdefault.subtitle.length > 50) {
            errorsno++;
            this.subtitle_err = "Subtitle can't be longer than 50 character";
        }
        if (notificationdefault.message.trim().length == 0) {
            errorsno++;
            this.detail_err = "Detail is required";
        }
        if (notificationdefault.message.includes("http://")) {
            errorsno++;
            this.detail_err = constants.DETAIL_HTTP_ERROR;
        }
        for (var i = 0; i < this.editorURLs.length; i++) {
            if (!validator.isURL(this.editorURLs[i], constants.URL_HTTPS_ONLY)) {
                errorsno++;
                this.detail_err = constants.DETAIL_HTTP_ERROR;
            }
        }
        if (notificationdefault.message.length > 4000) {
            errorsno++;
            this.detail_err = "Detail can't be longer than 4000 character";
        }
        if (errorsno == 0) {
            // Ok, saving data
            this.confirmMessage = this.getPopUpMessage();
            //notificationdefault.schedule = this.state.showDate ? selected : null;
            this.setState({ showConfirmPopUp: true });
            //this.setState({ disabledButton: true });
            //let locId = '';
            //if (sessionStorage.getItem('locationId')) {
            //    locId = sessionStorage.getItem('locationId');
            //}
            //categorydefault.locationId = locId;
            //console.log('Save: OK', categorydefault);
            //this.props.action.saveCategoryAction(categorydefault)
            //    .then((response) => {
            //        this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
            //        //this.props.history.push('/categories');
            //    }).catch(error => {
            //        console.log('*DEBUG*-Save CategoryEdit Error', error);
            //        this.setState({ disabledButton: false, notification: constants.DATA_ERROR });
            //    });
        }
        else {
            this.setState({ validating: true, /*notification: constants.FORM_ERROR*/ });
        }
    }
    handleSave() {
        //this.onClose();
        var notificationdefault = this.props.notificationdata;

        if (this.state.newimage) {
            notificationdefault.imageContent = base64;
            base64 = null;
        }

        let orgId = '';
        if (sessionStorage.getItem('organisationId')) {
            orgId = sessionStorage.getItem('organisationId');
        }
        //notificationdefault.locationId = orgId;
        var tags = "";
        this.state.selectedSites.forEach(item => {
            tags += `loc:${item.code} || `;
        });
        tags = tags.slice(0, -4);
        notificationdefault.tags = tags;
        let selected;
        if (notificationdefault.schedule) {
            selected = this.convertDateToUTC(notificationdefault.schedule);
        }

        notificationdefault.schedule = this.state.showDate ? selected : null;

        console.log('Save: OK', notificationdefault);
        this.props.action.saveNotificationAction(notificationdefault)
            .then((response) => {
                //this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                //this.props.history.push('/categories');
                if (response.ok == false) {
                    this.setState({ showConfirmPopUp: false, isSaved: true, isSaveSuccessful: false });
                } else {
                    this.onClose();
                }
            }).catch(error => {
                console.log('*DEBUG*-Save NotificationEdit Error', error);
                this.setState({ disabledButton: false, /*notification: constants.DATA_ERROR*/ });
            });
    }
    readFile(f) {
        if (f) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const data = event.target.result;
                console.log('File', data.length);
                //const base64data = btoa(unescape(encodeURIComponent(data)));
                const base64data = btoa(data);
                //console.log('File Base64:', base64data);
                base64 = base64data;
                //ToDo call API with file.name, and content
                //this.BinaryData = data; 
            };
            reader.readAsBinaryString(f);
        }
    }
    fileChangedHandler = (event) => {
        //console.log(event.target.files[0]);
        const file = event.target.files[0];
        console.log(file);
        if (file != undefined) {
            if (file.size > 1024 * 1024 * 2) {
                this.image_err = "File too large: " + Math.round((file.size / 1024 / 1024) * 100) / 100 + "MB";
                this.setState({ updated: true });
            }
            else {
                this.image_err = "";
                this.readFile(file);
                this.setState({ file: file, newimage: true, imagechanged: true });
            }
        }
        else {

        }

    }
    removeIcon() {
        this.props.notificationdata.imageUrl = null;
        this.props.notificationdata.imageContent = null;
        base64 = null;
        this.setState({ removeIconOpen: false, newimage: false });
    }
    shortcut() {
        if (this.state.file == "") {
            return null;
        }
        else if (this.state.file != null) {
            var Data = [];
            Data.push(this.state.file);
            var url = window.URL.createObjectURL(new Blob(Data, { type: "image/x-png,image/jpeg" }))
            return (
                <Box direction="row">
                    <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                    <Image style={{ "cursor": "pointer" }} height="75" src={url} onClick={e => {
                        const c = document.getElementById("real");
                        c.click();
                    }} />
                </Box>);
        }
        else {
            return null;
            //return (<Text color="red">Can't select file where size is over 2 MB or it's not an Image File</Text>);
        }
    }
    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.notificationdata) /*&& !(!this.state.isDateTimeUpdated && start.schedule != this.props.notificationdata.schedule)*/) {
            this.setState({ cancel: true });
        }
        else {
            //const Id = this.props.notificationdata.id;
            //console.log("Restoring:", Id);
            if (this.nextLoc) {
                this.props.history.push(this.nextLoc);
            } else {
                this.props.history.push(constants.getOrgLevelRoute() + '/notifications');
            }
            //this.props.action.getNotificationAction(Id)
            //    .then(() => {
            //        if (this.nextLoc) {
            //            this.props.history.push(this.nextLoc);
            //        } else {
            //            this.props.history.push('/notifications');
            //        }
            //    }).catch(error => {
            //        console.log("*DEBUG*-NotificationEdit Error", error);
            //        this.props.history.push('/notifications');
            //    });
        }
    }
    updateSchedule(option) {
        console.log("updateSchedule", option.selected);
        let toShowDate = option.selected == 1 ? true : false;
        if (toShowDate) {
            //If send now we leave it as it is (null)
            this.props.notificationdata.schedule = this.whenFormCreated;
            this.setState({ saveButtonText: "Schedule" });
        } else {
            this.props.notificationdata.schedule = null;
            this.setState({ saveButtonText: "Send" });
        }

        this.schedule_err = "";
        this.date_err = "";
        this.time_err = "";
        this.setState({ showDate: toShowDate, scheduleValue: option.option, scheduleId: option.selected });
    }
    updateType(option) {
        this.props.notificationdata.type = option.selected + 1;
        this.setState({ typeValue: option.selected });
    }
    updateTitle(e) {
        this.title_err = "";
        this.props.notificationdata.title = e.target.value;
        if (e.target.value.length > 30) {
            this.title_err = "Title can't be longer than 30 character";
        }
        this.setState({ valdiation: true });
    }
    updateSubtitle(e) {
        this.subtitle_err = "";
        this.props.notificationdata.subtitle = e.target.value;
        if (e.target.value.length > 50) {
            this.subtitle_err = "Subtitle can't be longer than 50 character";
        }
        this.setState({ valdiation: true });
    }
    convertDateToUTC(date) {
        const cdate = new Date(date);
        const ucdate = new Date(cdate.toUTCString());
        const milli = new Date(ucdate.getTime() + (ucdate.getTimezoneOffset() * 60000));
        const udate = milli.toISOString();
        //console.log("To UTC F:", date, "T:", udate);
        return udate;
    }
    createDateTime(date, time) {
        if (date.length != 10) {
            date = "aaaaaaaaaa";
        }
        if (time.length != 5) {
            time = "aaaaa";
        }
        // const newDateTime = date + "T" + time + ":00.000Z";
        const newDateTime = date + "T" + time + ":00.000Z";
        return newDateTime;
    }
    //updateDateValue(evt) {
    //    this.date_err = "";

    //    this.props.notificationdata.schedule = this.createDateTime(evt.target.value, this.props.notificationdata.schedule.substr(11, 5));
    //    console.log("DATE: 2B", evt.target.value," ||| " , this.props.notificationdata.schedule);
    //    let selected = new Date(this.props.notificationdata.schedule);
    //    let current = new Date(this.whenFormCreated/*.slice(0, -1)*/);
    //    selected = this.convertDateToUTC(selected);
    //    current = this.convertDateToUTC(current);
    //    console.log("datecmp", current, "\n", selected);
    //    if (selected < current) {
    //        this.date_err = "Can't select date from past";
    //    } else {
    //        this.time_err = "";
    //    }
    //    this.setState({ isDateTimeUpdated: true });
    //}
    updateDate(evt) {
        this.props.notificationdata.schedule = this.createDateTime(evt.value.substr(0, 10), this.props.notificationdata.schedule.substr(11, 5));
        console.log("DATE: 1A", evt.value.substr(0, 10), " ||| ", this.props.notificationdata.schedule);
        let selected = new Date(this.props.notificationdata.schedule);
        let current = new Date(this.whenFormCreated/*.slice(0, -1)*/);
        selected = this.convertDateToUTC(selected);
        current = this.convertDateToUTC(current);
        console.log("datecmp", current, "\n", selected);
        if (selected < current) {
            this.date_err = "Can't select date from past";
        } else {
            this.date_err = "";
        }
        this.setState({ isDateTimeUpdated: true });

    }
    updateTimeValue(evt) {
        this.time_err = "";
        this.props.notificationdata.schedule = this.createDateTime(this.props.notificationdata.schedule.substr(0, 10), evt.target.value);
        let selected = new Date(this.props.notificationdata.schedule);
        let current = new Date(this.whenFormCreated/*.slice(0, -1)*/);
        selected = this.convertDateToUTC(selected);
        current = this.convertDateToUTC(current);
        //console.log("datecmp", this.props.notificationdata.schedule, "\n", this.whenFormCreated.slice(0, -1));
        console.log("datecmp", current, "\n", selected);
        if (selected < current) {
            this.time_err = "Can't select time from past";
        } else {
            this.date_err = "";
        }
        this.setState({ isDateTimeUpdated: true });
    }
    getPopUpMessage() {
        console.log("getm");
        if (!this.props.notificationdata.schedule) {
            this.props.notificationdata.schedule = "";
        }
        const date = this.props.notificationdata.schedule.substr(0, 10);
        const time = this.props.notificationdata.schedule.substr(11, 5);
        const title = this.props.notificationdata.title;
        //const date = "<Date>";
        //const time = "<Time>";
        //const title = "<Title>";
        if (this.state.showDate) {
            //return `Schedule this notification ${title} to be sent on ${date} at ${time}. This notification will be sent to all employees.`;
            return `Schedule this notification "${title}" to be sent on ${date} at ${time}. This notification will be sent to ${this.state.selectedSites.length == 0 ? "ALL users" : `all users in ${this.state.selectedSites.map(s => { return " " + s.name; })}`}.`;
        } else {
            return `This notification "${title}" will be sent now. This notification will be sent to ${this.state.selectedSites.length == 0 ? "ALL users" : `all users in ${this.state.selectedSites.map(s => { return " " + s.name; })}`}.`;
        }
    }
    convertDateFromUTC(date) {
        const cdate = new Date(date);
        const ucdate = new Date(cdate.toUTCString());
        const milli = new Date(ucdate.getTime() - (ucdate.getTimezoneOffset() * 60000));
        const udate = milli.toISOString();
        //console.log("From UTC F:", date, "T:", udate);
        return udate;
    }

    uploadImageCallBack(file) {
        return imageCheck(file)
            .then(cf => ImageApi.uploadImage(cf))
            .catch(err => {
                if (typeof err === "string") {
                    this.uploadErrorText = err;
                } else {
                    this.uploadErrorText = err.message;
                }
                this.setState({ isUploadError: true });
            });
    }

    onClose() {
        //this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            this.props.history.push(constants.getOrgLevelRoute() + '/notifications');
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleValidation();
    }
    handleEditorChange = (content, delta, source, editor) => {
        this.props.notificationdata.message = editor.getHTML();
        if (editor.getHTML().length > 2000) {
            this.detail_err = "Details can't be longer than 2000 character";
        }
        else {
            this.detail_err = "";
        }
        this.setState({ validating: !this.state.validating });
        //console.log("HTML_EDITOR", content, delta, source, editor.getHTML()); // rich text
        //console.log("HTML_EDITOR", editor.getHTML()); // rich text
        //console.log("HTML_EDITOR", editor.getText()); // plain text
        //console.log("HTML_EDITOR", editor.getLength()); // number of characters

        var str = editor.getHTML();
        var searchKeyword = "<a href=";

        var indexes = [];
        this.editorURLs = [];
        var firstIndex = str.toLowerCase().indexOf(searchKeyword, 0);

        while (firstIndex >= 0) {
            if (!str.substr(firstIndex).match(/href=(["'])(.*?)\1/)[2].startsWith("mailto:")) { //skip if it is an e-mail address
                this.editorURLs.push(str.substr(firstIndex).match(/href=(["'])(.*?)\1/)[2]);
            }

            indexes.push(firstIndex);
            firstIndex = str.toLowerCase().indexOf(searchKeyword, firstIndex + 1);
        }
    }

    render() {
        const { options, open, notification, sitesdataLoading, notificationdataLoading } = this.state;
        const { sitesdata, notificationdata } = this.props;
        //notificationdata = this.props.notificationsdata.find(n => n.id == this.props.match.params.notificationId);
        if (sitesdataLoading || notificationdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }
        let selected = this.props.notificationdata.schedule ? new Date(this.props.notificationdata.schedule) : new Date();
        selected = this.convertDateToUTC(selected);
        console.log("yayan", this.props, "\n UTC", this.lnow, "\n FROM UTC=Currenty", this.whenFormCreated, "\n Current TimeZone", new Date(this.whenFormCreated), "\n Prop", this.props.notificationdata.schedule, "\n Send", selected);
        const heading = notificationdata && (notificationdata.id != "0") ? 'Edit' : 'Create';
        //const list = sitesdata.concat(
        //    [
        //        { id: "289h", get name() { return "Site-" + this.id; } },
        //        { id: "843hig", get name() { return "Site-" + this.id; } },
        //        { id: "émdsf", get name() { return "Site-" + this.id; } },
        //        { id: "ö9hwdg", get name() { return "Site-" + this.id; } },
        //        { id: "wen", get name() { return "Site-" + this.id; } }
        //    ]);
        const list = sitesdata;

        const sdate = notificationdata.schedule ? notificationdata.schedule.substr(0, 10) : "";
        const stime = notificationdata.schedule ? notificationdata.schedule.substr(11, 5) : "";
        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}
                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Notification - {heading} </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label={this.state.saveButtonText} onClick={this.handleValidation.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" style={{ minHeight: "500px" }}>
                    <form>
                        {/*<Notification message={notification} />*/}
                        <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                        <PopUpConfirm open={this.state.removeIconOpen} text={"Please confirm that you want to delete this image. The master image associated with this site will no longer be visible in the app."}
                            onClose={() => this.setState({ removeIconOpen: false })} onDelete={() => this.removeIcon()} />
                        {/*220218<PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />*/}
                        {/*
                        <PopUpConfirm open={this.state.showPopUpScheduled} onClose={() => this.onClose()} header="Confirm Notification" text="Schedule this notification <Notification Title> to be sent on <Date> at <Time>. This notification will be sent to all employees." />
                        <PopUpConfirm open={this.state.showPopUpSent} onClose={() => this.onClose()} header="Confirm Notification" text="This notification <Notification Title> will be sent now. This notification will be sent to all employees." />
                        */}
                        <PopUpConfirm open={this.state.showConfirmPopUp} onClose={() => this.setState({ showConfirmPopUp: false })} header="Confirm Notification" text={this.confirmMessage} onDelete={() => {/*this.setState({ showConfirmPopUp: false });*/ this.handleSave(); }} clickonce={true} />

                        <Box pad={{ vertical: "small" }}>
                            <Text size="large">Description</Text>
                            <Text>All fields marked with an * are mandatory. When scheduling notifications, please select an appropriate time in UTC for all.</Text>
                        </Box>
                        <StyledBox style1 pad={{ "horizontal": "small" }} margin={{ "bottom": "medium" }}>
                            <Box direction="row-responsive" gap="large">
                                <Box width="300px">
                                    <FormField label="Schedule *" error={this.schedule_err}>
                                        <Select options={["Send Now", "Schedule for another time"]} value={this.state.scheduleValue} onChange={option => this.updateSchedule(option)} />
                                    </FormField>
                                </Box>

                                {this.state.showDate &&
                                    <Box direction="row-responsive" gap="small">
                                        <FormField label="Date" error={this.date_err}>
                                            {/*<TextInput type="date" onChange={e => this.updateDateValue(e)} defaultValue={sdate} />*/}
                                            <DateInput calendarProps={{ "daysOfWeek": true, "firstDayOfWeek": 1 }} onChange={e => this.updateDate(e)} value={sdate} readOnly format="dd/mm/yyyy" />
                                        </FormField>
                                        <FormField label="Time" error={this.time_err}>
                                            <TextInput type="time" onChange={e => this.updateTimeValue(e)} defaultValue={stime} />
                                        </FormField>
                                        <FormField label="UTC">
                                            <TextInput value={this.convertDateToUTC(this.props.notificationdata.schedule).substr(0, 16).replace("T", " ")} readOnly />
                                    </FormField>
                                    <Box margin={{ "top": "medium" }} justify="center" align="center">
                                        <a data-for="info" data-tip>
                                            <CircleInformation />
                                        </a>
                                        <ReactTooltip id="info" place="top" type="dark" effect="solid" arrowColor="transparent">
                                            <span>Scheduled notifications will be sent to all relevant locations at this date/time in the UTC timezone</span>
                                        </ReactTooltip>
                                    </Box>
                                    </Box>
                                }
                            </Box>

                            <Box width="300px">
                                <FormField label="Type *" error={this.type_err}>
                                    <Select options={["Notification", "News", "Security", "Meet And Greet", "Space"]} disabled={[3]} value={["Notification", "News", "Security", "Meet And Greet", "Space"][notificationdata.type - 1]} onChange={option => this.updateType(option)} />
                                </FormField>
                            </Box>

                            <Box>
                                <FormField label="Title *" error={this.title_err}>
                                    <TextInput onChange={e => this.updateTitle(e)} defaultValue={notificationdata.title} />
                                </FormField>
                            </Box>

                            <Box >
                                <FormField label="Subtitle *" error={this.subtitle_err}>
                                    <TextInput onChange={e => this.updateSubtitle(e)} defaultValue={notificationdata.subtitle} />
                                </FormField>
                            </Box>
                            <Grommet theme={customTheme}>
                                <FormField label="Details *" error={this.detail_err} data-bounds="html_editor">
                                    <ReactQuill
                                        theme="snow"
                                        bounds={`[data-bounds="html_editor"]`}
                                        scrollingContainer="html"
                                        modules={{
                                            toolbar: [
                                                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                                ['blockquote', 'code-block'],
                                                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
                                                [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
                                                [{ 'direction': 'rtl' }],                         // text direction
                                                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                                                ['link'],
                                                ['clean']                                         // remove formatting button
                                            ],
                                            clipboard: {
                                                matchVisual: false,
                                            }
                                        }}
                                        onChange={this.handleEditorChange}
                                        value={notificationdata.message} />
                                </FormField>
                            </Grommet>
                        </StyledBox>
                        <Box gap="small">
                            <Box direction="row" gap="small" align="center">
                                <Text size="large">Image</Text>
                                <Box data-for="img_info" data-tip>
                                    <CircleInformation />
                                    <ReactTooltip id="img_info" place="right" type="dark" effect="solid" arrowColor="transparent" multiline >
                                        <span>Please select a brand approved image to display with your notification. <br />
                                                            Recommended Image type is JPEG in Landscape format (16:9). This can be no larger than 2MB.</span>
                                    </ReactTooltip>
                                </Box>
                            </Box>
                            <Box direction="row" gap="small">
                                <StyledBox style1 pad={{ "horizontal": "small", "top": "small" }} width={{ "min": "101px" }} fill={false} margin={{ "bottom": "small" }}>
                                    <FormField label="" error={this.image_err}>
                                        <Box direction="row" justify="center" >
                                            {this.state.newimage ?
                                                <Box alignSelf="center">
                                                    {this.shortcut()}
                                                </Box>
                                                :
                                                (notificationdata.imageUrl == "" || notificationdata.imageUrl == null) ?
                                                    <Box height="75px" justify="center">
                                                        <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                        <StyledButton icon={<Upload color={constants.BUTTON_BACKGROUND_COLOR_1} />}
                                                            onClick={e => {
                                                                const c = document.getElementById("real");
                                                                c.click();
                                                            }} />
                                                    </Box>
                                                    :
                                                    <Box justify="center">
                                                        <input type="file" id="real" hidden="hidden" accept="image/x-png,image/jpeg" onChange={this.fileChangedHandler} />
                                                        <Image height="75" src={`${notificationdata.imageUrl}?${this.stamp}`} style={{ "cursor": "pointer" }}
                                                            onClick={e => {
                                                                const c = document.getElementById("real");
                                                                c.click();
                                                            }} />
                                                    </Box>
                                            }
                                        </Box>
                                    </FormField>
                                </StyledBox>
                                <Box justify="center" margin={{ "bottom": "small" }}>
                                    <Button onClick={() => this.setState({ removeIconOpen: true })} disabled={((notificationdata.imageUrl == "" || notificationdata.imageUrl == null) && !this.state.newimage)}>
                                        <Trash />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box pad={{ vertical: "small" }}>
                            <Text size="large">Target Audience</Text>
                            <Text>Target your notification to audiences that need to receive them using the tag options listed.</Text>
                            {/*<Text>If you don't specify a selection below, the notification will be sent to all client users.</Text> */}
                        </Box>
                        <StyledBox style1 pad={{ "horizontal": "small" }} margin={{ "bottom": "medium" }}>
                            <Box>
                                <FormField label="Site">
                                    <Box direction="row">
                                        <Grid columns={{ count: 5, size: "auto" }}>
                                            {list.filter(f => f.notificationsEnabled).map(s =>
                                                <Box pad="small">
                                                    <CheckBox label={s.name} checked={this.state.selectedSites.find(item => item.id == s.id)} onChange={evt => {
                                                        var copy = [...this.state.selectedSites];
                                                        if (this.state.selectedSites.find(item => item.id == s.id)) {
                                                            copy = copy.filter(i => i.id !== s.id);
                                                        } else {
                                                            copy.push(s);
                                                        }
                                                        this.setState({ selectedSites: copy });
                                                    }} />
                                                </Box>)}
                                        </Grid>
                                    </Box>
                                </FormField>
                            </Box>
                        </StyledBox>
                        {/*<StyledBox style1 pad={{ "horizontal": "small" }} margin={{ "bottom": "medium" }} width="320px">*/}
                        {/*    <Box width="300px">*/}
                        {/*        <FormField label="Site">*/}
                        {/*            <CheckBox checked={true} label="Global" disabled />*/}
                        {/*        </FormField>*/}
                        {/*    </Box>*/}
                        {/*</StyledBox>*/}
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>

            </Grid >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const notificationId = ownProps.match.params.notificationId || ''; //from the path '/category/:categoryId'
    if (state.selectedNotificationReducer.notificationdata && notificationId == state.selectedNotificationReducer.notificationdata.id) {
        start = { ...state.selectedNotificationReducer.notificationdata };
        return {
            sitesdata: state.sitesReducer.sitesdata,
            notificationdata: state.selectedNotificationReducer.notificationdata,
            //notificationsdata: state.notificationsReducer.notificationsdata,
        };
    } else {
        return {
        };
    }
};



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...SiteAction, ...NotificationAction }, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationEditContainer));