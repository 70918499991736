import React, { Component } from 'react';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
//import { Glyphicon, Nav, Navbar, NavItem } from 'react-bootstrap';
//import { LinkContainer } from 'react-router-bootstrap';
//import './NavMenu.css';
// eslint-disable-next-line
import { withRouter } from 'react-router-dom';
import { Box, Grid, RoutedAnchor, Anchor, Text, Grommet } from 'grommet';
import { Filter, Edit, Close, Table, Calendar, Analytics, Iteration, Blank, Overview, Configure, Notification, SettingsOption, Location, Apps, Organization, BarChart, Tag, Brush, AppsRounded } from 'grommet-icons';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import RB from '../components/common/RBAC';
import constants from '../constants';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';

import { Context } from '../components/Context';

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    global: {
        colors: {
            focus: "transparent",
        }
    }
});

class SensitiveRoutedAnchor extends Component {
    render() {
        const { match, location, history, path, label, icon, disabled, sub, subsub } = this.props;
        let isBold = this.props.checkpath(path, location.pathname) ? "bold" : "normal";
        let isActive = this.props.checkpath(path, location.pathname);
        let padd = subsub ? "large" : "medium";
        return (
            <StyledBox direction="row" justify="between" pad={{ "left": padd, "top": "xsmall" }} leftborderthick={isActive}>
                <Box width="91%" margin={sub ? { left: "small" } : ""} justify="evenly">
                    <RoutedAnchor path={path}
                        label={<Text weight={isBold}>{label}</Text>}
                        icon={icon}
                        disabled={disabled}
                    />
                </Box>
            </StyledBox>
        );
    }
};

class SensitiveAnchor extends Component {
    render() {
        const { location, label, boldpathes, open, sub, subsub, ...rest } = this.props;
        let isBold = boldpathes.some(pe => location.pathname.startsWith(pe) || location.pathname.includes(pe)) ? "bold" : "normal";
        let padd = subsub ? "large" : "medium";
        return (
            <StyledBox pad={{ "left": padd, "top": "xsmall" }} leftborderthick={isBold == "bold" ? (!open ? true : false) : false} justify="center">
                <Box margin={sub ? { "left": "small" } : ""} justify="center" width="91%">
                    <Anchor
                        label={<Text weight={isBold}>{label}</Text>}
                        {...rest}
                    />
                </Box>
            </StyledBox>
        )
    }
}

const LocationSensitiveRoutedAnchor = withRouter(SensitiveRoutedAnchor);
const LocationSensitiveAnchor = withRouter(SensitiveAnchor);

class NavMenuOrg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openContent: false,
            openAdmin: false,
            openServices: false,
            open: false,
            openWellness: false,
            openAppContent: false
        };
        //not working
        this.state.openAdmin = this.props.history && ['/users', '/user', '/profiles', '/orgtags', '/orgtag', '/orgcreatetag', '/orglabel', '/orgdepartments', '/orgdepartment', '/orgtrackers', '/orgtracker', '/orgcollections', '/orgcollection', '/orglink', '/orgedittags', '/orgcreatemetadata', '/orgmetadata'].some(pe => this.props.history.location.pathname.startsWith(pe) || /*this.props.history.location.pathname.endsWith(pe)*/this.props.history.location.pathname.includes(pe));
        this.state.openAppContent = this.props.history && ['/appdetails', '/apptheme'].some(pe => this.props.history.location.pathname.startsWith(pe));
    };

    //    state = { openContent: false, openAdmin: false, openServices: false, open: false }
    ClickContent = () => this.setState({ openContent: !this.state.openContent });
    ClickAdmin = () => this.setState({ openAdmin: !this.state.openAdmin });
    ClickServices = () => this.setState({ openServices: !this.state.openServices });
    clickWellness = () => this.setState({ openWellness: !this.state.openWellness });
    ClickAppContent = () => this.setState({ openAppContent: !this.state.openAppContent });

    render() {
        const { open, openContent, openAdmin, openServices, openWellness, openAppContent } = this.state;
        const orgid = sessionStorage.getItem("organisationId");
        return (
            <Context.Consumer>
                {values => {
                    console.log("navo", values);
                    return (
                        <Grommet theme={customTheme}>
                            <Box fill={true} pad='none' background="transparent" >
                                <Box width="100%">
                                    {values.feature.find(f => f.name == "mobilClient").isEnabled &&
                                        <Box pad={{ "vertical": "xsmall" }} border="bottom">
                                            <LocationSensitiveRoutedAnchor path={`/${orgid}/appanalytics`} label='Dashboard' icon={<BarChart />}
                                                checkpath={(path, location) => path === location} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />
                                        </Box>}
                                    {/*------------*/}
                                    {values.feature.find(f => f.name == "assetTracking").isEnabled && <Box pad={{ "vertical": "xsmall" }}>
                                        <LocationSensitiveRoutedAnchor path={`/${orgid}/orgassets`} label='Assets' icon={<Tag />}
                                            checkpath={(path, location) => path === location || location.includes("/orgasset/") || location.startsWith('/orgasset') || location.endsWith('/orgasset')} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />
                                    </Box>}
                                    {values.feature.find(f => f.name == "notification").isEnabled && <Box pad={{ "vertical": "xsmall" }} border="bottom">
                                        <LocationSensitiveRoutedAnchor path={`/${orgid}/notifications`} label='Notifications' icon={<Notification />}
                                            checkpath={(path, location) => path === location || location.includes('/notification')} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />
                                    </Box>
                                    }


                                    {(values.feature.find(f => f.name == "mobilClient").isEnabled ||
                                        values.feature.find(f => f.name == "mobilClient").isEnabled) &&
                                        <Box border="bottom" pad={{ "vertical": "xsmall" }}>
                                            <LocationSensitiveAnchor
                                                boldpathes={['/appdetails', '/apptheme']} open={openAppContent}
                                                label='App Content' icon={<AppsRounded />} onClick={this.ClickAppContent} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)}/>
                                            {openAppContent && (
                                                <Box>
                                                    {values.feature.find(f => f.name == "mobilClient").isEnabled && <Box pad={{ "vertical": "xsmall" }}>
                                                    <LocationSensitiveRoutedAnchor sub path={`/${orgid}/appdetails`} label='App Config' icon={<Apps />}
                                                            checkpath={(path, location) => path === location} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />
                                                    </Box>}
                                                    {values.feature.find(f => f.name == "mobilClient").isEnabled && <Box pad={{ "vertical": "xsmall" }}>
                                                    <LocationSensitiveRoutedAnchor sub path={`/${orgid}/apptheme`} label='App Theme' icon={<Brush />}
                                                            checkpath={(path, location) => path === location} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />
                                                    </Box>}
                                                </Box>)}
                                        </Box>}



                                    {/*------------*/}
                                    <Box pad={{ "vertical": "xsmall" }} border="bottom">
                                        <LocationSensitiveAnchor
                                            boldpathes={['/users', '/user', '/roles', '/profiles', '/orgintegration', '/healthpass', '/orgreport', '/orgtags', '/orgtag', '/orgsites/', '/orgsite/', '/orgcreatetag', '/orgcreatemetadata',  '/orgmetadata', '/orgdepartments', '/orgdepartment', '/orgcollections', '/orgcollection', '/orgtrackers', '/orgtracker', '/orglabel', '/orglink', '/orgedittags']} open={openAdmin}
                                            label='Manage' icon={<SettingsOption />} onClick={this.ClickAdmin} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />
                                        {openAdmin && (
                                            <Box>
                                                {values.feature.find(f => f.name == "mobilClient").isEnabled &&
                                                    <LocationSensitiveRoutedAnchor sub path={`/${orgid}/profiles`} label='App Profiles' icon={<Blank />}
                                                        checkpath={(path, location) => path === location} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />}
                                                {values.feature.find(f => f.name == "assetTracking").isEnabled &&
                                                    <LocationSensitiveRoutedAnchor sub path={`/${orgid}/orgcollections`} label='Collections' icon={<Blank />}
                                                    checkpath={(path, location) => path === location || location.includes('/orgcollection') /*|| location.endsWith('/orgcollection')*/ || location.includes('/orgcollection')} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />}
                                                {values.feature.find(f => f.name == "assetTracking").isEnabled &&
                                                    <LocationSensitiveRoutedAnchor sub path={`/${orgid}/orgdepartments`} label='Departments' icon={<Blank />}
                                                    checkpath={(path, location) => path === location || location.includes('/orgdepartment') /*|| location.endsWith('/orgdepartment')*/ || location.includes('/orgdepartment')} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />}
                                                <LocationSensitiveRoutedAnchor sub path={`/${orgid}/orgintegrations`} label='Integrations' icon={<Blank />}
                                                    checkpath={(path, location) => path === location || location.includes('/orgintegrations')} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />
                                                <LocationSensitiveRoutedAnchor sub path={`/${orgid}/orgsites`} label='Sites' icon={<Blank />}
                                                    checkpath={(path, location) => path === location || location.includes('/orgsite/')} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />
                                                {values.feature.find(f => f.name == "assetTracking").isEnabled &&
                                                    <LocationSensitiveRoutedAnchor sub path={`/${orgid}/orgtags`} label='Tags' icon={<Blank />}
                                                    checkpath={(path, location) => path === location || location.includes('/orgtag') || location.includes('/orgcreatetag') || location.includes('/orgedittags') || location.includes('/orglabel')} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />}
                                                {values.feature.find(f => f.name == "assetTracking").isEnabled &&
                                                    <LocationSensitiveRoutedAnchor sub path={`/${orgid}/orgtrackers`} label='Trackers' icon={<Blank />}
                                                    checkpath={(path, location) => path === location || location.includes('/orgtracker') || location.includes('/orglink') || location.includes('/orgcreatemetadata') || location.includes('/orgmetadata')} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />}
                                                <LocationSensitiveRoutedAnchor sub path={`/${orgid}/users`} label='Users' icon={<Blank />}
                                                    checkpath={(path, location) => path === location || location.includes('/user')} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />
                                                {values.feature.find(f => f.name == "wellness").isEnabled && constants.release().Feature_citrix &&
                                                    <LocationSensitiveAnchor
                                                        boldpathes={['/healthpass', '/orgreport']} open={openWellness} sub
                                                        label='Wellness' icon={<Blank />} onClick={this.clickWellness} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />}
                                                {openWellness && constants.release().Feature_citrix  && (
                                                    <Box>
                                                        <LocationSensitiveRoutedAnchor subsub path={`/${orgid}/healthpass`} label='Health Pass' icon={<Blank />}
                                                            checkpath={(path, location) => path === location || location.includes('/healthpass')} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />
                                                        <LocationSensitiveRoutedAnchor subsub path={`/${orgid}/orgreport`} label='Reports' icon={<Blank />}
                                                            checkpath={(path, location) => path === location || location.includes('/orgreport')} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />
                                                    </Box>)}
                                            </Box>
                                        )}
                                    </Box>
                                    {/*------------*/}
                                    <Box pad={{ "vertical": "xsmall" }}>
                                        <LocationSensitiveRoutedAnchor path={`${constants.getOrgLevelRoute()}/orgsettings`} label='Config' icon={<Configure />}
                                            checkpath={(path, location) => path === location} disabled={!RB.canRead(constants.PERMS.ORG_ADMINISTRATION)} />
                                    </Box>
                                </Box>
                            </Box>
                        </Grommet>
                    );
                }}
            </Context.Consumer>

        );
    }
}
export default withRouter(NavMenuOrg);
