import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import constants from '../constants';
import { Grid, Box, Button, RoutedAnchor, Heading, DataTable, Table, TableBody, TableCell, TableFooter, TableHeader, TableRow, Text, TextInput, Image } from 'grommet';
import { Filter, Edit, Close, Search, Trash, Blank, CircleInformation } from 'grommet-icons';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
//import withCorrectedSearch from './common/CorrectedSearchHOC';
import withPagination from './common/PaginatedDataTable';
import RB from './common/RBAC';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';

//const CorrectedDataTable = withCorrectedSearch(DataTable);
const PaginatedDataTable = withPagination(DataTable);

class AssetStatusHistoryListTable extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
                <StyledBox style1 pad={{"horizontal":"small", "top":"small"}} margin={{"top":"small", "bottom":"medium"}}>
                    {renderPagesTable(this.props.historydata)}
                </StyledBox>
        );
    }
}
function renderNote(status) {
    var note = "";
    switch (status) {
        case "Maintenance":
            note = "Issue resolved";
            break;
        case "Maintenance required":
            note = "Seems to have issues powering on";
            break;
        case "Cleaning required":
            note = "Cleaning as per covid policy";
            break;
        default:
            break;
    }
    return note;
}
function renderPagesTable(historydata) {
    console.log("hali", historydata);

    const COLUMNS = [
        {
            property: 'serviceStateId',
            header: 'Status',
            render: s => <Text>{s.name}</Text>
        },
        {
            property: 'servicedBy',
            header: 'User',
            render: s => <Text>{s.servicedBy}</Text>
        },
        //{
        //    property: 'startTime',
        //    header: 'Start Time',
        //    render: s => <Text>{s.start}</Text>
        //},
        {
            property: 'date',
            header: 'Start Date',
            render: s => <Text>{s.date}</Text>
        },
        {
            property: 'time',
            header: 'Start Time',
            render: s => <Text>{s.time}</Text>
        },
        {
            property: 'notes',
            header: 'Notes',
            render: s => <Text>{renderNote(s.name)}</Text>
        },
        {
            property: 'id',
            primary: true,
            size: "0px",
            plain: true,
            header: '',
            render: s => <Text hidden>{s.id}</Text>
        }
    ];

    let DATA = [];
    //historydata.sort((p1, p2) => { if (p1.servicedBy.toLowerCase() < p2.servicedBy.toLowerCase()) return -1; return 1; });
    if (historydata[0] && historydata[0].count != 0){
        historydata.forEach((ev, index) => {
            //let p = (ev.category && ev.category.parent && !ev.category.deleted) ? ev.category.parent.toLowerCase() : '';
            //let l = (ev.category && ev.category.location && !ev.category.deleted) ? ev.category.location.toLowerCase() : '';
            //let n = (ev.category && ev.category.name && !ev.category.deleted) ? ev.category.name.toLowerCase() : '';
            //let lpn = constants.APP_LPN(l,p,n);
            var fulltime = ev.startTime.split("T");
            var time = "";
            var date = "";
            if (fulltime.length == 2) {
                date = fulltime[0];
                time = fulltime[1].replace("Z","");
            }
            var dot = time.indexOf(".");
            dot = dot > 0 ? dot : time.length;
            var statusName = constants.ASSET_STATUS.find(s => s.id == ev.serviceStateId);
            statusName = statusName ? statusName.name : "";
            DATA.push({ ...ev,
                name: statusName,
                start: ev.startTime.replace("T", " ").replace("Z", ""),
                date: date,
                time: time.slice(0,dot),
                //id: index,
                //categoryname: (ev.category && ev.category.name && !ev.category.deleted) ? ev.category.name : '',
                //lccategoryname: n,
                //categoryparent: (ev.category && ev.category.parent && !ev.category.deleted) ? ev.category.parent : '',
                //lccategoryparent: p,
                //categorylocation: (ev.category && ev.category.location && !ev.category.deleted) ? ev.category.location : '',
                //lccategorylocation: l,
                //lcparentloc: lpn,
                //orphaned: ev.category && ev.category.deleted
            })
        });
    }

    DATA.sort((p1, p2) => { if (p1.startTime.toLowerCase() > p2.startTime.toLowerCase()) return -1; return 1; });

    console.log('DATA:', DATA);

    return (
            <PaginatedDataTable
            bg = {constants.BOX_BACKGROUND_COLOR_1}
            columns={COLUMNS.map(c => ({
                ...c,
                //search: c.property === "lcname" || c.property === "lccategoryname" || c.property === "lccategoryparent" || c.property === "lccategorylocation" || c.property ==="lcparentloc"
                search: c.property === "servicedBy"
            }))}
            data={DATA}
            noLoadingMsg={historydata.length != 0}
            //sortable
            sortoptions={[
                { col: 'startTime', label: 'Date Time' },
                { col: 'name', label: 'Status' },
                { col: 'servicedBy', label: 'User' },
                //{ col: 'date', label: 'Start Date' },
                //{ col: 'time', label: 'Start Time' },
                ]}
            resizeable={false}
            displayrows={constants.PAGE_SIZE}
        />
    );
}

export default AssetStatusHistoryListTable;
