import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';


const selectedModelReducer = (state = initialState.selectedModelReducer, action) => {
    switch(action.type) {

        case ActionType.GET_MODEL_RESPONSE: {
            if (action.modeldata) {
                return {
                    ...state,
                    modeldata: _.assign(action.modeldata)
                };
            }
            else {
                return {
                    ...state,
                    modeldata: initialState.selectedModelReducer.modeldata
                };
            }

        }

        default: { return state; }
    }
};


export default selectedModelReducer;