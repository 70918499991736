import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as DepartmentAction from '../action/DepartmentAction';
import * as TrackerAction from '../action/TrackerAction';
import * as CollectionAction from '../action/CollectionAction';
import * as SiteAction from '../action/SiteAction';
import * as AssociationAction from '../action/AssociationAction';
import * as MetaDataAction from '../action/MetaDataAction';
import * as ModelAction from '../action/ModelAction';
import * as TypeAction from '../action/TypeAction';
import * as ManufacturerAction from '../action/ManufacturerAction';
import AssociationApi from '../api/AssociationApi';
// eslint-disable-next-line
import { Mail, Twitter, LinkedinOption, FacebookOption, Link, Instagram, DocumentText, Blank, Add, OrderedList, FormClose } from 'grommet-icons';
import { Grid, Box, Heading, Button, Text, FormField, TextInput, Select, Image, Grommet, CheckBox } from 'grommet';
import RB from './common/RBAC';
import PopUp from './common/PopUp';
import PopUpCancel from './common/PopUpCancel';
import constants from '../constants';
import _ from 'lodash';
import { Prompt, withRouter } from 'react-router-dom';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import LoadingIndicator from './common/LoadingIndicator';
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';
import ReactTooltip from "react-tooltip";
import { Context } from './Context';

const customTheme = deepMerge(hpe, { //define custom theme, then use it with <Grommet theme={theme}> 
    formField: {
        border: {
            color: "none",
            error: {
                color: "none"
            }
        }
    }
});

var start = undefined;

export class OrgMetadataEditContainer extends React.Component {
    static contextType = Context;
    constructor() {
        super();
        this.state = {
            isSaved: false,
            open: false,
            disabledButton: false,
            cancel: false,
            isSaveSuccessful: false,
            metadataLoading: true,
            modelsdataLoading: true,
            typesdataLoading: true,
            manufacturersdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };
    }

    //Validating errors
    manufacturer_err = "";
    model_err = "";
    modelType_err = "";

    ClearErrorMessages() {
        this.manufacturer_err = "";
        this.model_err = "";
        this.modelType_err = "";
    }

    componentDidMount() {
        this.historyUnblock = this.props.history.block(this.historyBlocker.bind(this));
        const id = this.props.match.params.metaid;

        this.props.action.getMetadataAction(id)
            .then(response => this.setState({ metadataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Asset Edit Error', error);
            });
        this.props.action.getModelsAction()
            .then(response => this.setState({ modelsdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getTypesAction()
            .then(response => this.setState({ typesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
        this.props.action.getManufacturersAction()
            .then(response => this.setState({ manufacturersdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Sites List Error', error);
            });
    }

    componentWillUnmount() {
        this.historyUnblock();
    }


    historyBlocker(location, action) {
        if (action === 'POP' || this.state.isSaved) {
            return true;
        }
        if (!this.state.cancel && !_.isEqual(start, this.props.metadata)) {
            this.nextLoc = location.pathname;
            this.setState({ cancel: true });
            return false;
        }
    }

    handleSave() {
        const metadata = this.props.metadata;
        let errorsno = 0;
        //this.ClearErrorMessages();
        //this.setState({ validating: false });
        if (this.manufacturer_err != "") {
            errorsno++;
        }
        if (this.model_err != "") {
            errorsno++;
        }
        if (this.modelType_err != "") {
            errorsno++;
        }
        //if (metadata.manufacturer && metadata.manufacturer.name.length > 50) {
        //    errorsno++;
        //    this.manufacturer_err = "Manufacturer's name can't be longer than 50 character";
        //}
        //if (metadata.model && metadata.model.name.length > 50) {
        //    errorsno++;
        //    this.model_err = "Model's name can't be longer than 50 character";
        //}
        //if (metadata.deviceType && metadata.deviceType.name.length > 50) {
        //    errorsno++;
        //    this.modelType_err = "Type's name can't be longer than 50 character";
        //}
        if (errorsno == 0) {
            // Ok, saving data
            this.setState({ disabledButton: true });
            console.log('Save: OK', metadata);
            //trim
            if (metadata.manufacturerId != null)
                metadata.manufacturer.name = metadata.manufacturer.name.trim();
            if (metadata.modelId != null)
                metadata.model.name = metadata.model.name.trim();
            if (metadata.deviceTypeId != null)
                metadata.deviceType.name = metadata.deviceType.name.trim();

            this.props.action.saveMetadataAction(metadata)
                .then((response) => {
                    this.setState({ isSaved: true, open: true, isSaveSuccessful: response.ok });
                    return response.json();
                })
                .catch(error => {
                    console.log('*DEBUG*-Save MetadataEdit Error', error);
                    this.setState({ disabledButton: false });
                });
        }
        else {
            this.setState({ validating: true });
        }
    }

    handleCancel() {
        if (!this.state.cancel && !_.isEqual(start, this.props.metadata)) {
            this.setState({ cancel: true });
        }
        else {
            const id = this.props.metadata.id;
            this.props.action.getMetadataAction(id)
                .then(() => {
                    if (this.nextLoc) {
                        this.props.history.push(this.nextLoc);
                    } else {
                        if (sessionStorage.getItem("organisationId"))
                            this.props.history.push({
                                pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                                state: { index: 1 }
                            });
                        else
                            this.props.history.push({
                                pathname: '/orgtrackers',
                                state: { index: 1 }
                            });
                    }
                }).catch(error => {
                    console.log('*DEBUG*-TrackerEdit Error', error);
                    if (sessionStorage.getItem("organisationId"))
                        this.props.history.push({
                            pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                            state: { index: 1 }
                        });
                    else
                        this.props.history.push({
                            pathname: '/orgtrackers',
                            state: { index: 1 }
                        });
                });
        }
    }

    onClose() {
        this.setState({ open: false });
        this.setState({ isSaved: false, disabledButton: false });
        if (this.nextLoc) {
            this.props.history.push(this.nextLoc);
        } else {
            if (sessionStorage.getItem("organisationId"))
                this.props.history.push({
                    pathname: '/' + sessionStorage.getItem("organisationId") + '/orgtrackers',
                    state: { index: 1 }
                });
            else
                this.props.history.push({
                    pathname: '/orgtrackers',
                    state: { index: 1 }
                });
        }
    }
    onCancel() {
        this.nextLoc = undefined;
        this.setState({ cancel: false });
    }
    onDiscard() {
        this.handleCancel();
    }
    onSave() {
        this.setState({ cancel: false });
        this.handleSave();
    }

    updateManufacturerValue(name) {
        const { metadata } = this.props;
        metadata.manufacturer.name = name;

        if (this.isExistingManufacturer(name.trim())) {
            this.manufacturer_err = "Name already exists";
        }
        else if (name.length > 50) {
            this.manufacturer_err = "Manufacturer's name can't be longer than 50 character";
        }
        else {
            this.manufacturer_err = "";
        }

        this.setState({ update: true });

    }
    updateModelValue(name) {
        const { metadata } = this.props;
        metadata.model.name = name;

        if (this.isExistingModel(name.trim())) {
            this.model_err = "Name already exists";
        }
        else if (name.length > 50) {
            this.model_err = "Model's name can't be longer than 50 character";
        }
        else {
            this.model_err = "";
        }

        this.setState({ update: true });
    }
    updateModelTypeValue(name) {
        const { metadata } = this.props;

        if (metadata.deviceTypeId == null) {//New
            metadata.deviceType = { "name": name.trim(), "id": 0, "organizationId": sessionStorage.getItem("organisationId") };
            metadata.deviceTypeId = 0;
        }
        //else if (metadata.deviceTypeId != null) {//Existing
        //    metadata.deviceType = { "name": name };
        //}
        metadata.deviceType.name = name;
        if (this.isExistingType(name)) {
            this.modelType_err = "Name already exists";
        }
        else if (name.length > 50) {
            this.modelType_err = "Type's name can't be longer than 50 character";
        }
        else {
            this.modelType_err = "";
        }

        this.setState({ update: true });
    }

    isExistingManufacturer(name) {
        let manu = this.props.manufacturersdata.find(m => m.name.toLowerCase() == name.toLowerCase());
        if (manu) {
            return manu.id != this.props.metadata.manufacturerId;
        }
        else
            return false;
    }
    isExistingModel(name) {
        let model = this.props.modelsdata.find(m => m.name.toLowerCase() == name.toLowerCase());
        if (model) {
            return model.id != this.props.metadata.modelId;
        }
        else
            return false;
    }
    isExistingType(name) {
        let type = this.props.typesdata.find(t => t.name.toLowerCase() == name.toLowerCase());
        if (type) {
            return type.id != this.props.metadata.deviceTypeId;
        }
        else
            return false;
    }
    render() {
        const { metadataLoading, modelsdataLoading, typesdataLoading, manufacturersdataLoading } = this.state;
        const { metadata, modelsdata, typesdata, manufacturersdata } = this.props;

        if (metadataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList} />
            );
        }

        const heading = metadata && this.props.match.params.metaid != 0 ? 'Edit' : 'Create';

        return (
            <Grid fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' /*background='brand'*/ direction="row"
                    align="center"
                    //pad={{ vertical: "medium" }}
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >Metadata - {heading} </Heading>
                    <Box direction="row" gap="small" >
                        <StyledButton typeCancel label="Cancel" onClick={this.handleCancel.bind(this)} disabled={this.state.disabledButton} />
                        <StyledButton typeSave label="Save" onClick={this.handleSave.bind(this)} disabled={this.state.disabledButton} />
                    </Box>
                </Box>

                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical" style={{ minHeight: "500px" }}>
                    <form>
                        <PopUp open={this.state.isSaved} onClose={() => this.onClose()} text={this.state.isSaveSuccessful ? "Save was successful." : "Something went wrong."} error={!this.state.isSaveSuccessful} />
                        <PopUpCancel open={this.state.cancel} onCancel={() => this.onCancel()} onSave={evt => this.onSave(evt)} onDiscard={() => this.onDiscard()} />
                        {/* <form> */}
                        <StyledBox style1 pad={{ "horizontal": "small" }} width="700px">
                            <FormField label="Manufacturer *" error={this.manufacturer_err}>
                                <TextInput value={metadata.manufacturer ? metadata.manufacturer.name : ""} onChange={evt => this.updateManufacturerValue(evt.target.value)} disabled={!metadata.manufacturerId} />
                            </FormField>
                            <FormField label="Model" error={this.model_err}>
                                <TextInput value={metadata.model ? metadata.model.name : ""} onChange={evt => this.updateModelValue(evt.target.value)} disabled={!metadata.modelId} />
                            </FormField>
                            <FormField label="Model Type" error={this.modelType_err}>
                                <TextInput value={metadata.deviceType ? metadata.deviceType.name : ""} onChange={evt => this.updateModelTypeValue(evt.target.value)} disabled={!metadata.deviceTypeId} />
                            </FormField>
                        </StyledBox>
                    </form>
                </Box>
                <Box gridArea='side' background={constants.BACKGROUND_COLOR}>
                </Box>
            </Grid >
        );
    }
}



const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.metaid || '';
    if (state.selectedMetadataReducer.metadata && id == state.selectedMetadataReducer.metadata.id) {
        start = { ...state.selectedMetadataReducer.metadata };
        return {
            modelsdata: state.modelsReducer.modelsdata,
            typesdata: state.typesReducer.typesdata,
            manufacturersdata: state.manufacturersReducer.manufacturersdata,
            metadata: state.selectedMetadataReducer.metadata
        };
    }
    else {
        return {
        };
    }
};

const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...ModelAction, ...TypeAction, ...ManufacturerAction, ...MetaDataAction }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgMetadataEditContainer));