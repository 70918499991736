import * as ActionType from './ActionType';
import PageApi from '../api/PageApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';
import { queueApiCall } from '../api/ApiUtils';


export const getPagesResponse = pagesdata => ({
    type: ActionType.GET_PAGES_RESPONSE,
    pagesdata
});

const queuedGetAllPages = queueApiCall(PageApi.getAllPages);

export function getPagesAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return PageApi.getAllPages()
        return queuedGetAllPages()
            .then(pagesdata => {
                dispatch(getPagesResponse(pagesdata));
                return pagesdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewPageResponse = () => ({
    type: ActionType.ADD_NEW_PAGE_RESPONSE
});

export const updateExistingPageResponse = () => ({
    type: ActionType.UPDATE_EXISTING_PAGE_RESPONSE
});

export function savePageAction(pagedraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the page is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new page that is being added, therefore add it
        return PageApi.savePage(pagedraft)
            .then((response) => {
                if (pagedraft.id) {
                    dispatch(updateExistingPageResponse());
                } else {
                    dispatch(addNewPageResponse());
                }
                return response;
            }).then((response) => {
                //if (pagedraft.id == "0") {
                //    dispatch(getPageAction(pagedraft.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getPageResponse = pagedata => ({
    type: ActionType.GET_PAGE_RESPONSE,
    pagedata
});

export function getPageAction(pageId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return PageApi.getPage(pageId)
            .then(pagedata => {
                dispatch(getPageResponse(pagedata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deletePageResponse = () => ({
    type: ActionType.DELETE_PAGE_RESPONSE
});

const queuedDeletePage = queueApiCall(PageApi.deletePage);

export function deletePageAction(pageId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        //return PageApi.deletePage(pageId)
        return queuedDeletePage(pageId)
            .then((response) => {
                dispatch(deletePageResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getPagesAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}