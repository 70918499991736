import * as ActionType from './ActionType';
import OrganisationApi from '../api/OrganisationApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';


export const getOrganisationsResponse = organisationsdata => ({
    type: ActionType.GET_ORGANISATIONS_RESPONSE,
    organisationsdata
});

export function getOrganisationsAction(deleteStore = true) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return OrganisationApi.getAllOrganisations()
            .then(organisationsdata => {
                dispatch(getOrganisationsResponse(organisationsdata));
                return organisationsdata;
            }).catch(error => {
                throw error;
            });
    };
}

export const addNewOrganisationResponse = () => ({
    type: ActionType.ADD_NEW_ORGANISATION_RESPONSE
});

export const updateExistingOrganisationResponse = () => ({
    type: ActionType.UPDATE_EXISTING_ORGANISATION_RESPONSE
});

export function saveOrganisationAction(organisationdraft) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        //if Id exists, it means that the organisation is being edited, therefore update it.
        //if Id doesn't exist, it must therefore be new organisation that is being added, therefore add it
        return OrganisationApi.saveOrganisation(organisationdraft)
            .then((response) => {
                if (organisationdraft.id) {
                    dispatch(updateExistingOrganisationResponse());
                } else {
                    dispatch(addNewOrganisationResponse());
                }
                return response;
            }).then((response) => {
                //if (organisationdraft.id == "0") {
                //    dispatch(getOrganisationAction(organisationdraft.id));
                //}
                return response;
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getOrganisationResponse = organisationdata => ({
    type: ActionType.GET_ORGANISATION_RESPONSE,
    organisationdata
});

export function getOrganisationAction(organisationId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return OrganisationApi.getOrganisation(organisationId)
            .then(organisationdata => {
                dispatch(getOrganisationResponse(organisationdata));
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}

export const deleteOrganisationResponse = () => ({
    type: ActionType.DELETE_ORGANISATION_RESPONSE
});

export function deleteOrganisationAction(organisationId) {
    return (dispatch) => {

        dispatch(ApiCallBeginAction());

        return OrganisationApi.deleteOrganisation(organisationId)
            .then((response) => {
                dispatch(deleteOrganisationResponse());
                return response;
            }).then((response) => {
                return [response, dispatch(getOrganisationsAction(false))];
            }).catch(error => {
                console.log('Action error:', error);
                throw error;
            });
    };
}