import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserAction from '../action/UserAction';
import * as RoleAction from '../action/RoleAction';
import LocUserListTable from './LocUserListTable';
import LocUserRolesListTable from './LocUserRolesListTable';
// eslint-disable-next-line
import { Box, Heading, Label, List, Paragraph, Split, Button, CheckBox, Layer, Text, Grid, Tabs, Tab } from 'grommet';
import { ThemeContext as IconThemeContext } from 'grommet';
import { galwayIconTheme } from '../grommet-extras/GalwayTheme';
import constants from '../constants';
import { StyledBox } from './common/StyledBox';
import { StyledButton } from './common/StyledButton';
import PopUpConfirm from './common/PopUpConfirm';
import LoadingIndicator from './common/LoadingIndicator';


export class LocUserListContainer extends React.Component {

    constructor() {
        super();
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.eventDelete = this.eventDelete.bind(this);
        this.state = {
            selectedUser: 0,
            deletedUserIds: [],
            open: undefined,
            tabindex: 0,
            usersdataLoading: true,
            rolesdataLoading: true,
            apiErrorCounter: 0,
            apiErrorList: [],
        };

    }

    onActive(idx) {
        this.setState({ tabindex: idx });
    }

    componentDidMount() {
        this.props.action.getUsersAction()
            .then(response => this.setState({ usersdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Users List Error', error);
            });
        this.props.action.getRolesAction()
            .then(response => this.setState({ rolesdataLoading: false }))
            .catch(error => {
                var errorArray = this.state.apiErrorList.concat(error);
                this.setState({ apiErrorCounter: this.state.apiErrorCounter + 1, apiErrorList: errorArray });
                console.log('*DEBUG*- Roles List Error', error);
            });
    }

    handleDelete(event, nro) {
        event.preventDefault();
        console.log('*Delete* started on ', nro);
        this.setState({ selectedUser: nro });
        this.onOpen();
    }

    eventDelete(event) {
        const selectedId = this.state.selectedUser;

        if (selectedId) {
            //this.setState({ selectedUser: 0, open: undefined, deletedUserId: selectedId});
            this.setState({ selectedUser: 0, open: undefined, deletedUserIds: this.state.deletedUserIds.concat([selectedId]) });
            this.props.action.deleteUserAction(selectedId)
                .catch(error => {
                    console.log('*DEBUG*- User List Delete Error', error);
                });
        }
        this.onClose();
    }

    onOpen = () => this.setState({ open: true });
    onClose = () => this.setState({ open: undefined });

    handleNew(event) {
        event.preventDefault();
        this.props.history.push(constants.getSiteLevelRoute() + '/locuser/0');
    }

    handleEdit(event, nro) {
        event.preventDefault();
        console.log('*Edit* started on ', nro);
        this.props.history.push(constants.getSiteLevelRoute() + '/locuser/' + nro);
    }

    render() {
        const { usersdata, rolesdata } = this.props;
        const { open, selectedUser, deletedUserIds, tabindex, usersdataLoading, rolesdataLoading } = this.state;

        if (usersdataLoading || rolesdataLoading) {
            return (
                <LoadingIndicator error={this.state.apiErrorCounter} errorList={this.state.apiErrorList}/>
            );
        }
        return (
            <Grid
                fill={true}
                rows={['xsmall', 'auto']}
                columns={['100%', 'flex']}

                areas={[
                    { name: 'header', start: [0, 0], end: [1, 0] },

                    { name: 'main', start: [0, 1], end: [0, 1] },
                    { name: 'side', start: [1, 0], end: [1, 1] },
                ]}
            >
                <Box gridArea='header' direction="row"
                    align="center"
                    pad="medium"
                    justify="between"
                    background={constants.BACKGROUND_COLOR}>
                    <Heading level='2' >User Administration</Heading>
                    <Box direction="row" gap="small" >
                        {tabindex == 0 && (
                            <StyledButton typeSave label='Create New' alignSelf="center" onClick={this.handleNew} />
                        )}
                    </Box>
                </Box>
                <PopUpConfirm open={open} text={constants.confirmMessage("user")} onClose={() => this.onClose()} onDelete={() => this.eventDelete()} />
                <Box gridArea='main' background={constants.BACKGROUND_COLOR} pad="medium" fill="vertical">
                    <Tabs justify="start" activeIndex={tabindex} onActive={(i) => this.onActive(i)}>
                        <Tab title="Users">
                            <Box margin={{ "bottom": "medium" }}>
                                <IconThemeContext.Extend value={galwayIconTheme}>
                                    <LocUserListTable usersdata={usersdata} rolesdata={rolesdata} handleNew={this.handleNew} handleDelete={this.handleDelete} handleEdit={this.handleEdit} deletedUserIds={deletedUserIds} />
                                </IconThemeContext.Extend>
                            </Box>
                        </Tab>
                        <Tab title="Roles">
                            <Box margin={{ "bottom": "medium" }}>
                                <IconThemeContext.Extend value={galwayIconTheme}>
                                    <LocUserRolesListTable usersdata={usersdata} rolesdata={rolesdata} handleNew={this.handleNew} handleDelete={this.handleDelete} handleEdit={this.handleEdit} deletedUserIds={deletedUserIds} />
                                </IconThemeContext.Extend>
                            </Box>
                        </Tab>
                    </Tabs>
                </Box>
            </Grid>
        );
    }
}



const mapStateToProps = state => ({
    usersdata: state.usersReducer.usersdata,
    rolesdata: state.rolesReducer.rolesdata
});



const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...UserAction, ...RoleAction }, dispatch)

});



export default connect(mapStateToProps, mapDispatchToProps)(LocUserListContainer);
